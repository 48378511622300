@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.page_ttl--bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.page_ttl-jp {
  font-size: rem(48);
  color: var(--clr-body);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: calc-fz(16);
  color: var(--clr-body);
  @include mq(sp) {
    font-size: sprem(14);
  }
}

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
  padding-top: rem(32);
  @include mq(sp) {
    padding-top: sprem(24);
  }
  .breadcrumbs {
    text-align: center;
  }
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  height: rem(480);
  @include mq(sp) {
    height: sprem(200);
  }
  & > .inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .breadcrumbs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .breadcrumbs span span:not(:last-child)::after {
    background-color: var(--bg-wht);
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  font-family: var(--font-secondary);
  &:where(:not([class*="mgn-btm"]):not(:last-child)) {
    margin-bottom: 1em;
  }
}
.ttl-01 {
  margin-top: rem(40);
  font-size: rem(48);
  color: var(--clr-main);
  letter-spacing: .1em;
  line-height: 1.25;
  &::before {
    content: "";
    position: absolute;
    bottom: calc(100% + rem(19));
    left: 50%;
    transform: translateX(-50%);
    width: rem(72);
    aspect-ratio: 213/150;
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/flower.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  @include mq(sp) {
    font-family: var(--font-secondary-b);
    font-size: sprem(28);
  }
  & + .ttl-01-sub {
    margin-top: rem(-30);
    @include mq(sp) {
      margin-top: sprem(-16);
    }
  }
  &.txt-wh + .ttl-01-sub {
    color: var(--clr-wht);
    &::after {
      background-color: var(--bg-wht);
    }
  }
}
.ttl-01-sub {
  display: block;
  color: var(--clr-gray);
  font-size: calc-fz(16);
  font-family: var(--font-en);
  letter-spacing: .12em;
  @include mq(sp) {
    font-size: calc-fz(13);
  }
  &:where(:not([class*="mgn-btm"]):not(:last-child)) {
    margin-bottom: perc(48, 16, em);
  }
}
.ttl-02 {
  font-size: rem(40);
  @include mq(sp) {
    font-family: var(--font-secondary-b);
    font-size: sprem(24);
  }
}
.ttl-03 {
  font-size: calc-fz(24);
  color: var(--clr-brown);
  @include mq(sp) {
    font-family: var(--font-secondary-b);
    font-size: sprem(20);
  }
  &.txt-wh.dotted {
    text-decoration-color: var(--clr-wht-42);
  }
  &.dotted {
    // text-decoration: underline dotted var(--clr-brown);
    // text-decoration-thickness: .2em;
    // text-underline-offset: 0.4em;
    line-height: 1.94;
    & > span {
      display: inline-block;
      height: 2em;
      background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line.svg);
      background-repeat: repeat-x;
      background-size: 13px;
      background-position: bottom left;
    }
    & > span.wh {
      background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line-wh.svg);
    }
    & > span.wh-42 {
      background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line-wh-42.svg);
    }
  }
}

.ttl-03-sub {
  display: block;
  color: var(--clr-gray);
  font-size: calc-fz(16);
  font-family: var(--font-en);
  letter-spacing: .12em;
  @include mq(sp) {
    font-size: calc-fz(13);
  }
  &.txt-wh,
  &.txt-wh * {
    color: var(--clr-wht-42);
  }
}
.lps_parts--img_text .ttl-03 {
  text-decoration: underline dotted var(--clr-brown);
  text-decoration-thickness: .2em;
  text-underline-offset: 0.3em;
  line-height: 1.94;
}
.ttl-04 {
  font-size: calc-fz(24);
  @include mq(sp) {
    font-size: sprem(20);
  }
}

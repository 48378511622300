@use '../abstracts' as *;

/* ##############################################################################

    PAGE

############################################################################## */

/* related_page
********************************************** */
.related_page--list {
  border-radius: rem(10);
  overflow: hidden;
  @include mq(sp, min, ps) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(100% / 3), 1fr));
  }
}
.related_page--list li {
  &:nth-child(9n-1) a::before,
  &:nth-child(9n-3) a::before,
  &:nth-child(9n-8) a::before {
    filter: brightness(1.1);
  }
  &:nth-child(9n-2) a::before,
  &:nth-child(9n-4) a::before,
  &:nth-child(9n-6) a::before {
    filter: brightness(.9);
  }
}
.related_page--list a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(64) rem(88) rem(64) rem(64);
  line-height: var(--line-height-hdr);
  color: var(--clr-wht);
  @include mq(sp, min, ps) {
    height: rem(280);
  }
  @include mq(sp) {
    padding: sprem(24) sprem(32) sprem(24) sprem(24);
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
  }
  &::before {
    inset: 0;
    pointer-events: none;
    z-index: 0;
    background-color: var(--clr-yellow);
  }
  &::after {
    top: 0;
    bottom: 0;
    right: rem(64);
    margin: auto;
    @include rect(24);
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    @include mq(sp) {
      right: sprem(24);
      @include sprect(12);
    }
  }
  &:hover {
    opacity: .7;
  }
}
.related_page--list .font-jp {
  margin-bottom: .25em;
  z-index: 2;
  @include mq(sp) {
    font-size: sprem(18);
  }
}
.related_page--list .font-en {
  opacity: .5;
  text-transform: uppercase;
  font-weight: 600;
  @include mq(sp) {
    font-size: sprem(10);
  }
}
.related_page--bg {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: .2;
  z-index: 0;
}
@use "sass:math";
@use "../abstracts" as *;

/* --- inner --- */
.inner {
  width: 90%;
  max-width: rem(1200);
  margin-left: auto;
  margin-right: auto;
  @include mq(sp) {
    width: 86%;
  }
}
.inner-sm {
  max-width: rem(720);
}
.inner-lg {
  max-width: rem(1456);
}
.inner-xl {
  max-width: rem(1760);
}

@for $i from 700 through 1920 {
  .inner-#{$i} {
    max-width: rem($i);
  }
}


@use "sass:math";
@use '../abstracts' as *;

.grid {
  display: grid;
}

[class*="grid-"]{
  display: grid;
}
@for $i from 1 through 8 {
  .grid-col#{$i} {
    // minmaxをつけるのは、子要素にwhite-space: nowrap;があると延びてしまう為（例：スマホ時横スクロールのテーブル）
    grid-template-columns: repeat($i,minmax(0,1fr));
  }
}
@for $i from 1 through 600 {
  .grid-rgt#{$i} {
    grid-template-columns: auto rem($i);
  }
}
@for $i from 1 through 600 {
  .grid-lft#{$i} {
    grid-template-columns: rem($i) auto;
  }
}
@for $i from 1 through 150 {
  .gap#{$i} {
    gap: rem($i);
  }
}

@include mq(sp) {
  .grid-sp-block {
    grid-template-columns: repeat(1,minmax(0,1fr));
    &[class*="gap"] {
      gap: sprem(24);
    }
  }
  @for $i from 1 through 8 {
    .grid-sp-col#{$i} {
      grid-template-columns: repeat($i,minmax(0,1fr));
    }
  }
  @for $i from 0 through 150 {
    .gap-sp#{$i} {
      gap: sprem($i) !important;
    }
  }
}
@use '../abstracts' as *;

/* page-contact
********************************************** */
.page-contact .lps_sec:nth-child(odd) {
  background-color: var(--clr-beige);
  padding-bottom: 0;
}
.page-contact .lps_sec.section_pdg {
  padding-top: 6rem;
  @include mq(sp) {
    padding-top: 3rem;
  }
}
.page-contact .footer {
  background-color: var(--bg-off_wht);
}
/* section-contact
********************************************** */
.section-contact {
  .cta_tel {
    width: 100%;
    max-width: rem(400);
    @include auto-margin;
  }
  .cta_tel.mgn-btm48 {
    @include mq(sp) {
      margin-bottom: 3rem;
    }
  }
  .cta_tel a {
    padding: rem(24) rem(40);
    @include mq(sp) {
      display: block;
      width: auto;
      padding: sprem(16);
    }
  }
}
@use "../abstracts" as *;

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: rem(10) rem(56) rem(10) rem(32);
  text-align: center;
  border: 2px solid var(--bg-cta);
  border-radius: rem(8);
  width: fit-content;
  min-width: min(rem(280), 100%);
  font-family: var(--font-secondary);
  color: var(--clr-wht);
  background-color: var(--bg-cta);
  @include mq(sp) {
    min-width: 100%;
  }
  @media (hover: hover) {
    &:hover {
      background-color: inherit;
      color: var(--bg-cta);
    }
    &:hover svg {
      fill: var(--bg-cta);
    }
  }
  &:where(:not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: rem(36);
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    transition: var(--transit-default);
  }
  svg {
    right: rem(24);
    fill: var(--clr-wht);
  }
}
.btn.btn-ctr a {
  @include auto-margin;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-main);
  background-color: var(--bg-wht);
  border-color: var(--bg-wht);
  @media (hover: hover) {
    &:hover {
      color: var(--clr-wht);
      background-color: var(--bg-cta);
      border-color: var(--clr-cta);
    }
    svg {
      fill: var(--clr-main);
    }
    &:hover svg {
      fill: var(--clr-wht);
    }
  }
}

/* --- btn-yellow --- */
.btn.btn-yellow a {
  @media(hover: hover) {
    &:hover {
      background-color: var(--clr-yellow-07);
      border-color: var(--clr-yellow-07);
    }
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--bg-cta);
  &:hover {
    background-color: var(--bg-cta-hvr);
  }
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: rem(10);
  margin-inline: -1em;
  padding: 1em;
  &:hover {
    color: var(--clr-main);
  }
  &::after {
    content: "";
    @include rect(8);
    color: var(--clr-main);
    transform: rotate(45deg);
    border-top: 2px solid;
    border-right: 2px solid;
  }
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }
  &:only-child {
    @include mq(sp, min, ps) {
      width: fit-content;
      min-width: min(rem(280),100%);
    }
  }
  a {
    min-width: 100%;
  }
}

/* btn-fan_shape */
.btn-fan_shape {
  position: absolute;
  bottom: rem(-100);
  right: rem(-100);
  width: rem(200);
  aspect-ratio: 1/1;
  background-color: var(--bg-cta);
  border-radius: 50vw;
  transition: var(--transit-default);
  @include mq(sp) {
    bottom: sprem(-60);
    right: sprem(-60);
    width: sprem(120);
  }
  &::before {
    content: "";
    position: absolute;
    top: 28%;
    left: 25%;
    display: block;
    width: .875rem;
    height: .875rem;
    transform: translateY(-50%) rotate(45deg);
    border-top: 3px solid var(--clr-wht);
    border-right: 3px solid var(--clr-wht);
  }
}

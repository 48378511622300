@use "../abstracts" as *;

/* ##############################################################################

    FOOTER

############################################################################## */

.footer {
  background-color: var(--bg-primary);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/footer_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq(sp) {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/footer_bg_sp.png);
  }
}
.footer .inner.section_pdg {
  padding-bottom: 5.75rem;
  @include mq(sp) {
    padding-top: sprem(74);
  }
}

/* footer_top
********************************************** */
/* ---fnav --- */
.fnav--menu a {
  display: block;
  padding: 0.5em 1em;
  &:hover {
    opacity: 0.7;
  }
  @include mq(sp) {
    position: relative;
    padding: 0.5em;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.5em;
      margin: auto;
      @include sprect(6);
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
  }
}
.fnav--menu .sub-menu {
  font-size: calc-fz(15);
  line-height: 1;
  opacity: 0.75;
}

/* ---sns_area --- */
.sns_area a {
  display: grid;
  place-items: center;
  @include rect(40);
  &:hover {
    opacity: 0.7;
  }
}
.sns_area svg {
  @include rect(24);
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: rem(8) 0;
}
.footer_btm:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.privacy {
  @include mq(sp) {
    margin-bottom: 0.5em;
  }
}
.privacy a:hover {
  opacity: 0.7;
}
.copyright {
  gap: 1em;
  justify-content: center;
  @include mq(sp) {
    gap: 0.5em;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

/* ##############################################################################

    スタンダード用

############################################################################## */

/* footer-simple
*************************************************** */
.footer-simple {
  padding: rem(8) 0;
}
.footer-simple:has(.privacy) {
  @include mq(sp, min, ps) {
    & > .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

// お問い合わせシンプル「以外」のとき
/* footer-01
  *************************************************** */
.footer-01 {
  position: relative;
  overflow: hidden;
  & > * {
    font-family: var(--font-secondary);
  }
  .inner {
    max-width: rem(1360);
  }
  .footer--logo {
    margin-bottom: rem(32);
    @include mq(sp) {
      margin-bottom: sprem(14);
    }
  }
  .footer--logo span {
    top: calc(-1em - rem(7));
    left: 0;
    font-size: rem(13);
    letter-spacing: .1em;
    color: var(--bg-main-hvr);
    white-space: nowrap;
  }
  .footer--logo img {
    @include mq(sp) {
      max-width: 80%;
    }
  }
  .footer--company {
    color: #9e6c00;
  }
  .footer--address {
    margin-bottom: rem(140);
    font-size: calc-fz(15);
    @include mq(lg, min, ps) {
      margin-bottom: rem(180);
    }
    @include mq(sp) {
      margin-bottom: rem(75);
    }
  }
  .footer--address-company {
    position: relative;
    margin-bottom: rem(24);
    line-height: 1.5;
    color: #9e6c00;
    @include mq(sp) {
      margin-bottom: sprem(12);
    }
    &::before {
      content: "";
      position: absolute;
      bottom: rem(-12);
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 1px;
      background-color: #c48400;
      border-radius: .5px;
      opacity: .25;
    }
  }
  .footer--address-address {
    display: flex;
    align-items: center;
    gap: rem(10);
    font-size: rem(15);
    color: rgba(158, 108, 0, 0.75);
    line-height: 2.4;
    letter-spacing: .1em;
    svg {
      fill: var(--clr-orange);
    }
  }
  .footer--address-tel a {
    color: #9e6c00;
    font-size: rem(15);
    font-family: var(--font-en);
    font-weight: bold;
    letter-spacing: .1em;
    line-height: 1.64;
  }
  .footer--address-tel a svg {
    width: rem(12);
    aspect-ratio: 12/16;
  }
  .fnav {
    margin-bottom: rem(40);
    @include mq(sp) {
      margin-bottom: sprem(14);
    }
  }
  .footer--cta {
    margin-bottom: rem(48);
  }
  .footer--cta a {
    margin-inline: auto;
    text-align: center;
  }
  .fnav--menu {
    justify-content: space-between;
    line-height: 1;
    @include mq(sp, min, ps) {
      gap: 1.5em;
    }
    @include mq(sp) {
      padding-top: sprem(40);
    }
  }
  .fnav--menu a {
    padding: 1.2em 0;
    color: var(--clr-wht);
    letter-spacing: .1em;
    font-size: rem(18);
    @include mq(sp, min, ps) {
      margin-block: -1em;
    }
    @include mq(sp) {
      padding: .5em 0;
    }
  }
  .fnav--menu .sub-menu a {
    font-size: rem(16);
    color: rgba(255, 255, 255, 0.75);
    @include mq(sp) {
      padding-left: 1em;
      font-size: sprem(16);
    }
  }
  .sns_area ul {
    justify-content: center;
  }
  .copyright {
    justify-content: flex-start;
    margin-top: sprem(14);
    font-size: rem(12);
    color: rgba(255, 255, 255, 0.25);
  }
  .copyright small {
    font-size: rem(12);
  }
  .privacy a {
    font-size: rem(13);
    letter-spacing: .05em;
    color: rgba(255, 255, 255, 0.5);
  }
}

/* --- position --- */
.pos-rel {
  position: relative;
}
.pos-ab {
  position: absolute;
}
.pos-fx {
  position: fixed;
}

/* --- float --- */
.flt-rgt {
  float: right;
}
.flt-lft {
  float: left;
}
@use "../abstracts" as *;

/* icon
**************************************** */
  .icon-mail {
    width: rem(21);
    aspect-ratio: 21/16;
    fill: var(--bg-wht);
    + * {
      margin-left: rem(8);
    }
  }
  .cls-10 {
    fill: #fff;
  }
  
  .cls-20 {
    fill: #d63e48;
  }

/* cloud
********************************************** */
  .deco-cloud-01 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/cloud01.png);
    aspect-ratio: 396/239;
  }
  .deco-cloud-02 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/cloud02.png);
    aspect-ratio: 186/192;
  }
  .deco-cloud-03 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/cloud03.png);
    aspect-ratio: 396/239;
  }
  .deco-cloud-04 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/cloud04.png);
    aspect-ratio: 324/183;
  }
  .deco-cloud-05 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/cloud05.png);
    aspect-ratio: 210/103;
  }

/* tree
********************************************** */
  .deco {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .deco-round_tree {
    background-image: url(/wp/wp-content/themes/standard_sass/images/round_tree.svg);
    aspect-ratio: 106/125;
  }
  .deco-triangle_tree-two {
    background-image: url(/wp/wp-content/themes/standard_sass/images/triangle-tree01.svg);
    aspect-ratio: 2/3;
  }
  .deco-triangle_tree-two-reverse {
    background-image: url(/wp/wp-content/themes/standard_sass/images/triangle-tree02.svg);
    aspect-ratio: 2/3;
  }
  .deco-triangle_tree {
    background-image: url(/wp/wp-content/themes/standard_sass/images/triangle-tree03.svg);
    aspect-ratio: 61/150;

  }

/* people
********************************************** */
  .deco-people-01 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people01.png);
    aspect-ratio: 210/288;
  }
  .deco-people-02 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people02.png);
    aspect-ratio: 514/287;
  }
  .deco-people-02-2 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people02-2.png);
    aspect-ratio: 336/260;
  }
  .deco-people-03 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people03.png);
    aspect-ratio: 326/290;
  }
  .deco-people-03-3 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people03-3.png);
    aspect-ratio: 323/285;
  }
  .deco-people-04 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people04.png);
    aspect-ratio: 215/286;
  }
  .deco-people-05 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people05.png);
    aspect-ratio: 396/286;
  }
  .deco-people-06 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people06.png);
    aspect-ratio: 223/286;
  }
  .deco-people-07 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people07.png);
    aspect-ratio: 183/287;
  }
  .deco-people-08 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people08.png);
    aspect-ratio: 459/283;
  }
  .deco-people-09 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people09.png);
    aspect-ratio: 151/380;
  }
  .deco-people-10 {
    background-image: url(/wp/wp-content/themes/standard_sass/images/people10.png);
    aspect-ratio: 236/286;
  }

/* deco-speaking
********************************************** */
  .deco-speaking--txt {
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: rem(16);
    line-height: 1.5;
    letter-spacing: .1em;
    white-space: nowrap;
    @include mq(sp) {
      font-size: sprem(10);
    }
  }
  .deco-speaking-about {
    @include deco-pc (149) {
      bottom: vw(93);
      right: vw(96);
    }
    @include deco-sp (114) {
    }
    color: var(--clr-wht);
    background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-about_us.png);
    aspect-ratio: 149/106;
  }
  .deco-speaking-feature {
    @include mq(2000, min, ps) {
      bottom: 802px;
    }
    @include deco-pc (155) {
      bottom: max(340px, vw(419));
      left: min(vw(430), 430px);
    }
    color: var(--clr-wht);
    background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-feature.png);
    aspect-ratio: 155/104;
    @include deco-sp (86) {
      bottom: 238px;
      left: spvw(315);
    }
  }
  .deco-speaking-subaru {
    @include deco-pc (206) {
      bottom: vw(93);
      left: vw(144);
    }
    @include deco-sp (143) {
      bottom: 50%;
      transform: translateY(50%);
    }
    z-index: 3;
    background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-subaru.png);
    aspect-ratio: 206/139;
    .deco-speaking--txt span {
      color: var(--clr-yellow-03);
    }
  }
  .deco-speaking-subaru-02 {
    @include deco-pc (206) {
      bottom: vw(93);
      @include mq(sp, min, ps) {
        right: vw(765);
      }
    }
    @include deco-sp (143) {
      bottom: spvw(4);
      left: spvw(40);
    }
    z-index: 3;
    background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-subaru02.png);
    aspect-ratio: 206/140;
    .deco-speaking--txt {
      top: 40%;
    }
    .deco-speaking--txt span {
      color: var(--clr-green-11);
    }
  }
  .deco-speaking-recruit {
    @include deco-pc (174) {
      top: clamp(410px, vw(540), 540px);
      left: vw(208);
    }
    @include deco-sp (120) {
      top: 90%;
      left: spvw(24);
    }
    color: var(--clr-wht);
    background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-recruit.png);
    aspect-ratio: 174/110;
  }

/* dot-circle
********************************************** */
  .deco-dot_circle {
    background-image: url(/wp/wp-content/themes/standard_sass/images/dot_circle.png);
    aspect-ratio: 569/573;
  }

/* hero
********************************************** */
  .hero_deco-recruit {
    @include deco-pc (142) {
      bottom: vw(59);
      right: vw(60);
      z-index: 10;
    }
    @include deco-sp (84) {}
    background-image: url(/wp/wp-content/themes/standard_sass/images/recruit-icn.png);
    aspect-ratio: 142/157;
    @media(hover: hover) {
      &:hover .hero_deco-recruit--txt {
        color: var(--clr-green-08);
      }
    }
  }
  .hero_deco-recruit--txt {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: rem(20);
    line-height: 1.4;
    letter-spacing: .14em;
    color: var(--clr-wht);
    white-space: nowrap;
    transition: var(--transit-default);
    @include mq(sp) {
      font-size: sprem(14);
    }
    & > .fz28 {
      @include mq(sp) {
        font-size: sprem(18);
      }
    }
  }

/* hero
********************************************** */
  .hero {
    .deco-cloud-01 {
      @include deco-pc (396) {
        top: vw(0);
        left: vw(-159);
      }
    }
    .deco-cloud-02 {
      @include deco-pc (186) {
        top: vw(234);
        right: 0;
      }
    }
  }

/* home_intro
********************************************** */
  .home_intro {
    .deco-cloud-03 {
      @include deco-pc (260) {
        bottom: vw(-53);
        right: vw(-42);
      }
    }
  }

/* home_news
********************************************** */
  .home_news {
    .deco-round_tree-01 {
      @include deco-pc (94) {
        top: vw(-220);
        left: vw(25);
      }
      @include deco-sp (35);
    }
    .deco-round_tree-02 {
      @include deco-pc (118) {
        bottom: vw(-250);
        left: vw(414);
      }
      @include deco-sp (54) {
        bottom: spvw(-102);
      }
    }
    .deco-round_tree-03 {
      @include deco-pc (220) {
        bottom: vw(-474);
        right: vw(-32);
      }
      @include deco-sp (73) {
        bottom: spvw(-211);

      }
    }
    .deco-triangle_tree-two-01 {
      @include deco-pc (96) {
        top: vw(-71);
        left: vw(494);
      }
      @include deco-sp (40) {
        top: spvw(-36);
      }
    }
    .deco-triangle_tree-two-02 {
      @include deco-pc (200) {
        top: vw(48);
        right: calc(100% + vw(-56));
      }
      @include deco-sp (70);
    }
    .deco-triangle_tree-two-03 {
      @include deco-pc (139) {
        bottom: vw(-252);
        right: vw(445);
      }
      @include deco-sp (55) {
        bottom: spvw(-101);
      }
    }
    .deco-triangle_tree-two-04 {
      @include deco-pc (110) {
        top: vw(-33);
        right: vw(-17);
      }
      @include deco-sp (64);
    }
    .deco-triangle_tree-two-05 {
      @include deco-pc (200) {
        bottom: vw(-438);
        left: vw(-24);
      }
      @include deco-sp (75) {
        bottom: spvw(-207);
      }
    }
    .deco-people-01 {
      @include deco-pc (121) {
        top: vw(-282);
        left: vw(401);
      }
      @include deco-sp (56) {
        top: spvw(-19);
        left: spvw(18);
        z-index: 2;
      }
    }
    .deco-people-02 {
      @include deco-pc (514) {
        bottom: vw(-528);
        left: 50%;
        transform: translateX(-50%);
      }
      @include deco-sp (178) {
        bottom: spvw(-233);
      }

    }
    .deco-people-03 {
      @include deco-pc (214) {
        bottom: vw(-115);
        right: vw(80);
        z-index: 2;
      }
      @include deco-sp (80);
    }
  }

/* home_about
********************************************** */
  .home_about {
    .deco-people-04 {
      @include deco-pc (113) {
        bottom: vw(-58);
        right: 0;
      }
      @include deco-sp (61) {
        bottom: spvw(-91);
        right: spvw(-10);
      }
    }
    .deco-speaking-about {
      bottom: vw(54);
      @include mq(sp) {
        bottom: spvw(-21);
      }
    }
    .deco-speaking--txt {
      top: 40%;
    }
    .deco_img {
      bottom: vw(-173);
      left: vw(-108);
      border-radius: rem(40);
      @include mq(sp) {
        bottom: spvw(-115);
      }
    }
    .deco_img img {
      @include mq(sp) {
        width: spvw(115);
        aspect-ratio: 240/305;
      }
    }
    .deco-cloud-03 {
      @include deco-pc (300) {
        top: vw(225);
        left: vw(-30);
      }
    }
  }

/* home_service
********************************************** */
  .home_service {
    .deco {
      z-index: 1;
    }
    .deco-cloud-03 {
      @include deco-pc (230) {
        bottom: vw(98);
        right: vw(-20);
        z-index: 0;
      }
      @include deco-sp (70);
    }
    .deco-people-02-2 {
      @include deco-pc (336) {
        bottom: vw(175);
        right: 0;
      }
      @include mq(sp) {
        display: none;
      }
    }
    .deco-people-05 {
      @include deco-pc (280) {
        bottom: vw(156);
        right: vw(-9);
      }
      @include deco-sp (147) {
        bottom: spvw(116);
        right: spvw(-14);
      }
    }
    .deco-people-03-3 {
      @include deco-pc (322) {
        bottom: vw(72);
        right: vw(42);
      }
      @include deco-sp (143) {
        bottom: spvw(111);
        right: spvw(-10);
      }
    }
    .deco-people-06 {
      @include deco-pc (219) {
        bottom: vw(50);
        right: vw(54);
      }
      @include deco-sp (93) {
        bottom: spvw(132);
        right: spvw(10);
      }
    }
    .home_service--contents .deco-dot_circle {
      z-index: 0;
    }
    .home_service--contents-home .deco-dot_circle {
      @include deco-pc (569) {
        top: vw(-21);
        right: vw(-51);
      }
      @include deco-sp (140) {
      }
    }
    .home_service--contents-support .deco-dot_circle {
      @include deco-pc (441) {
        top: vw(55);
        right: vw(-224);
      }
      @include deco-sp (140) {
        top: spvw(11);
      }
      
    }
    .home_service--contents-short .deco-dot_circle {
      @include deco-pc (569) {
        top: vw(-40);
        right: vw(-185);
      }
    }
    .home_service--contents-day .deco-dot_circle {
      @include deco-pc (569) {
        top: vw(-40);
        right: vw(-185);
      }
    }
  }

/* home_feature
********************************************** */
  .home_feature {
    .deco {
      z-index: 20;
    }
    .deco-cloud-03 {
      @include deco-pc (290) {
        top: vw(88);
        left: vw(-24);
      }
      @include deco-sp (100) {
        top: spvw(-18);
      }
    }
    .deco-cloud-05 {
      @include deco-pc (210) {
        top: vw(88);
        right: 47%;
      }
      @include deco-sp (80);
    }
    .home_feature_deco-subaru {
      @include deco-pc (1108) {
        top: vw(-181);
        right: vw(-49);
      }
      z-index: 0;
      background-image: url(/wp/wp-content/themes/standard_sass/images/feature_subaru.png);
      aspect-ratio: 1108/283;
    }
    .deco-round_tree-01 {
      @include deco-pc (90) {
        top: vw(122);
        right: vw(-49);
      }
      @include deco-sp (26) {
        top: spvw(34);
      }
    }
    .deco-round_tree-02 {
      @include deco-pc (81) {
        top: vw(369);
        right: vw(706);
      }
      @include deco-sp (26) {
        top: spvw(91);
        right: spvw(123);
      }
    }
    .deco-round_tree-03 {
      @include deco-pc (118) {
        top: min(vw(1868), 1868px);
        right: vw(550);
      }
      @include deco-sp (50) {
        top: spvw(870);
      }
    }
    .deco-round_tree-04 {
      @include deco-pc (117) {
        bottom: vw(192);
        left: vw(442);
      }
      @include deco-sp (50) {
        bottom: spvw(111);
        left: spvw(33);
      }
    }
    .deco-triangle_tree-01 {
      @include deco-pc (33) {
        top: vw(309);
        left: vw(453);
      }
      @include deco-sp (13) {
        top: spvw(83);
        left: spvw(64);
      }
    }
    .deco-triangle_tree-02 {
      @include deco-pc (40) {
        top: vw(190);
        right: vw(291);
      }
      @include deco-sp (13) {
        top: spvw(45);
      }
    }
    .deco-triangle_tree-two-01 {
      @include deco-pc (71) {
        top: vw(283);
        left: vw(370);
      }
      @include deco-sp (25) {
        top: spvw(77);
        left: spvw(34);
      }
    }
    .deco-triangle_tree-two-02 {
      @include deco-pc (99) {
        top: vw(206);
        left: vw(843);
      }
      @include deco-sp (25);
    }
    .deco-triangle_tree-two-03 {
      @include deco-pc (103) {
        top: min(vw(512), 512px);
        left: vw(268);
      }
      @include deco-sp (30) {
        top: spvw(131);
      }
    }
    .deco-triangle_tree-two-04 {
      @include deco-pc (113) {
        top: vw(1060);
        left: vw(341);
      }
      @include deco-sp (40) {
        top: spvw(224);
        left: spvw(98);
      } 
    }
    .deco-triangle_tree-two-05 {
      @include deco-pc (128) {
        bottom: 55%;
        left: vw(-47);
      }
      @include deco-sp (50) {
        bottom: 66%;
        left: spvw(126);
      }
    }
    .deco-triangle_tree-two-06 {
      @include deco-pc (128) {
        bottom: vw(257);
        left: vw(863);
      }
      @include deco-sp (50) {
        bottom: spvw(1100);
        left: spvw(119);
      }
    }
    .deco-triangle_tree-two-07 {
      @include deco-pc (110) {
        bottom: min(vw(1400), 1400px);
        left: vw(826);
      }
      @include mq(sp) {
        display: none;
      }
    }
    .deco-triangle_tree-two-08 {
      @include deco-pc (146) {
        bottom: vw(40);
        right: vw(405);
      }
      @include deco-sp (45) {
        bottom: spvw(37);
      }
      
    }
    .deco-people-06 {
      @include deco-pc (167) {
        top: min(vw(1066), 1066px);
        left: min(vw(103), 103px);
      }
      @include deco-sp (60);
    }
    .deco-people-07 {
      @include deco-pc (97) {
        top: min(vw(474), 474px);
        left: vw(521);
      }
      @include deco-sp (30);
    }
    .deco-people-08 {
      @include deco-pc (328) {
        top: min(vw(1813), 1813px);
        right: vw(168);
      }
      @include deco-sp (120) {
        top: spvw(1111);
      }
    }
    .deco-people-03-3 {
      @include mq(2000, min, ps) {
        bottom: 570px;
      }
      @include deco-pc (343) {
        bottom: max(200px, vw(256));
        left: vw(83);
      }
      @include mq(sp) {
        bottom: 150px;
        left: spvw(249);
        width: min(150px, spvw(100)); 
      }
    }
  }

/* home_facility
********************************************** */
  .home_facility {
    .deco-people-05 {
      @include deco-pc (226) {
        bottom: vw(-69);
        z-index: 2;
        @include mq(sp, min, ps) {
          right: vw(745);
        }
      }
      @include deco-sp (100) {
        bottom: spvw(-80);
        left: spvw(40);
      }
    }
    .deco-people-10 {
      @include deco-pc (145) {
        bottom: vw(-69);
        left: vw(144);
        z-index: 2;
      }
      @include deco-sp (80) {
        bottom: 40%;
      }
    }
  }

/* home_voice
********************************************** */
  .home_voice {
    .deco-triangle_tree-two-01 {
      @include deco-pc (195) {
        bottom: vw(-102);
        right: vw(243);
      }
      @include deco-sp (70) {
        bottom: spvw(-60);
      }
    }
    .deco-triangle_tree-two-02 {
      @include deco-pc (194) {
        bottom: vw(-260);
        right: vw(-5);
      }
      @include deco-sp (70) {
        bottom: spvw(-140);
      }
    }
    .deco-people-08 {
      @include deco-pc (380) {
        top: vw(-95);
        left: vw(175);
        z-index: 2;
      }
      @include deco-sp (120) {
        top: 110%;
      }
    }
  }

/* cta
********************************************** */
  .cta {
    .deco-people-03-3 {
      @include deco-pc (300) {
        bottom: vw(-5);
        right: vw(44);
      }
      @include deco-sp (90);
    }
    .deco-people-09 {
      @include deco-pc (150) {
        bottom: vw(-100);
        right: vw(111);
      }
      @include deco-sp (50);
    }
  }

/* footer
********************************************** */
  .footer-01 {
    .deco-round_tree-01 {
      @include deco-pc (106) {
        top: vw(48);
        left: vw(25);
      }
      @include deco-sp (50) {
        top: 31%;
        left: spvw(26);
      }
    }
    .deco-round_tree-02 {
      @include deco-pc (211) {
        bottom: vw(-175);
        left: vw(403);
      }
      @include deco-sp (65);
    }
    .deco-triangle_tree-two-01 {
      @include deco-pc (109) {
        top: vw(164);
        right: vw(187);
      }
      @include deco-sp (40) {
        top: spvw(-2);
        right: spvw(11);
      }
    }
    .deco-triangle_tree-two-02 {
      @include deco-pc (221) {
        bottom: vw(92);
        right: vw(-43);
      }
      @include deco-sp (65);
    }
    .deco-triangle_tree-two-03 {
      @include deco-pc (200) {
        bottom: 0;
        left: vw(-37);
      }
      @include deco-sp (65);
    }
    .deco-triangle_tree-two-04 {
      @include deco-pc (184) {
        bottom: vw(-135);
        right: vw(577);
      }
      @include deco-sp (50);
    }
    .deco-people-08 {
      @include deco-pc (239) {
        top: vw(181);
        right: vw(435);
      }
      @include deco-sp (100);
    }
    .deco-people-10 {
      @include deco-pc (132) {
        bottom: vw(371);
        left: vw(116);
      }
      @include deco-sp (60) {
        bottom: 64%;
        left: spvw(302);
      }
    }
  }


@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: rem(32);
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: rem(24);
    background-color: #fafafa;
  }
  .ez-toc-title-container {
    margin-bottom: rem(10);
  }
  div#ez-toc-container p.ez-toc-title {
    color: var(--clr-body);
    font-weight: bold;
    font-size: calc-fz(20);
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: calc-fz(17);
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: rem(8);
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: rem(16);
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: rem(16);
    &::before {
      content: "";
      position: absolute;
      top: rem(10);
      left: 0;
      width: rem(8);
      height: 0;
      border-bottom: 1px solid;
    }
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: rem(5);
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: rem(4) rem(6);
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: rem(4);
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* share
**************************************** */
  .share--list a {
    @include center-flex;
    height: rem(40);
    padding: .5em;
    line-height: 1;
    font-size: calc-fz(12);
    font-weight: bold;
    border: 1px solid #ddd;
  }
  .share--list li {
    &.x a { color: #000; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .share--list img {
    margin-right: rem(10);
  }
  .share--list a:hover {
    opacity: .5;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: calc-clamp(4, $wid-var);
    white-space: nowrap;
  }
  .wp-pagenavi > * {
    @include center-flex;
  }
  .wp-pagenavi .pages {
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: 0 1em;
    height: calc-clamp(40, $wid-var);
    background-color: var(--bg-main);
    color: var(--clr-wht);
    border-radius: rem(8);
    &:hover {
      background-color: var(--bg-main-hvr);
    }
  }
  .archive .wp-pagenavi > *:not(.pages) {
    width: calc-clamp(40, $wid-var);
    aspect-ratio: 1/1;
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    aspect-ratio: 1/1;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    border-top: 2px solid;
    border-right: 2px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    width: .5em;
    aspect-ratio: 1/1;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: rem(-6);
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: rem(-6);
  }

/* single-news
********************************************** */
  .single-news .container {
    background-color: var(--clr-beige);
  }
  .post--pdf.btn {
    margin-bottom: rem(40);
  }
  .cat-item a {
    @media(hover: hover) {
      &:hover {
        color: var(--clr-orange);
      }
    }
  }

/* single-recruit-guide
********************************************** */
  .single-recruit-guide .container {
    background-color: var(--clr-beige);
  }
  .recruit-guide--info {
    .ttl-02.mgn-btm64 {
      @include mq(sp) {
        text-align: center;
      }
    }
  }
  .recruit-guide--wrapper {
    padding: rem(40) rem(56);
    background-color: var(--clr-wht);
    border-radius: rem(40);
    @include mq(sp) {
      padding: sprem(24) sprem(16);
    }
  }
  .recruit-guide_table {
    width: 100%;
    a:not([href*="tel"]) {
      text-decoration: underline;
      transition: var(--transit-default);
      @media(hover: hover) {
        &:hover {
          color: var(--clr-orange);
        }
      }
    }
  }
  .recruit-guide_table:not(:last-child) {
    margin-bottom: rem(54);
    @include mq(sp) {
      margin-bottom: sprem(24);
    }
  }
  .recruit-guide_table th {
    vertical-align: middle;
  }
  .single-recruit-guide .btn a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: rem(36);
    @include rect(8);
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg);
    transition: var(--transit-default);
  }
  .single-recruit-guide .btn:not(:last-child) {
    margin-bottom: rem(64);
  }

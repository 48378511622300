@use "../abstracts" as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

.main_side {
  display: grid;
  grid-template-columns: auto rem(264);
  gap: rem(80);
  @include mq(med) {
    grid-template-columns: repeat(1,minmax(0,1fr));
    gap: sprem(40);
  }
}

/* main_column
**************************************** */

/* --- post --- */
.post {
  position: relative;
  border-radius: rem(40);
}
.post--img {
  background-color: var(--bg-wht);
  border-top-left-radius: rem(40);
  border-top-right-radius: rem(40);
}
.post--img .img-contain {
  max-width: 80%;
}
.post--img .img-radius {
  border-top-left-radius: rem(40);
  border-top-right-radius: rem(40);
}
.post--link {
  &:not([href="javascript:void(0);"]):hover {
    color: var(--clr-main);
  }
  &:hover svg {
    fill: var(--clr-main);
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 100;
  }
}
.post--info.flex .post--date {
  @include mq(sp) {
    margin-bottom: sprem(8);
  }
}
.post--info.flex .post--date + .cat_list {
  @include mq(sp, min, ps) {
    margin-left: auto;
  }
}
.cat_list {
  position: relative;
  z-index: 300;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: rem(2);
}
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: calc-fz(13);
  background-color: var(--clr-main);
  color: var(--clr-wht);
  padding-inline: 1em;
  border-radius: rem(4);
  @media(hover: hover) {
    &:hover {
      background-color: var(--clr-orange);
    }
  }
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: rem(64);
}
.side--ttl {
  letter-spacing: .2em;
  line-height: 1;
  margin-bottom: rem(16);
}
.side--ttl span {
  display: block;
  opacity: .5;
  font-family: var(--font-en);
  letter-spacing: .15em;
  line-height: 1;
  margin-top: rem(8);
}

/* --- list --- */
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: rem(16);
}
.posts-side .post--img {
  margin-right: rem(16);
  width: rem(64);
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  transition: var(--transit-default);
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
  @media(hover: hover) {
    &:hover {
      color: var(--clr-orange);
    }
  }
}
.archive_month {
  display: none;
}
.archive_month li a {
  @media(hover: hover) {
    &:hover {
      color: var(--clr-orange);
    }
  }
}
/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
  &:not(:last-child) {
    margin-right: rem(24);
  }
}
.archive-pulldown .archive_list a {
  display: block;
  padding: .25em 1em;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid var(--clr-beige);
  }
  &:first-child {
    border-radius: rem(8) rem(8) 0 0;
  }
  &:last-child {
    border-radius: 0 0 rem(8) rem(8);
  }
  &:hover {
    background-color: var(--clr-beige);
  }
}
.archive-pulldown .archive_list--label {
  margin-right: rem(8);
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: .25em 4em .25em 2em;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  border-radius: rem(8);
  color: inherit;
  font-family: var(--font-primary);
  font-size: calc-fz(16);
  line-height: var(--line-height-default);
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: .4em;
    right: .8em;
    margin: auto;
    width: .6em;
    height: .6em;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    transition: var(--transit-default);
  }
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  border-radius: rem(8);
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: var(--transit-default);
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* blog
**************************************** */
.posts-blog .post {
  padding-bottom: rem(48);
  padding-top: rem(48);
  border-bottom: 1px solid #e5e5e5;
  &:first-child {
    border-top: 1px solid #e5e5e5;
  }
}
.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-blog .post--ttl {
  font-size: calc-fz(18);
}
.posts-blog .post--img {
  width: rem(280);
  margin-right: rem(40);
}

@include mq(sp) {
  .posts-blog .post {
    display: block;
    padding-top: sprem(24);
    padding-bottom: sprem(24);
  }
  .posts-blog .post--img {
    padding-left: 0;
    @include auto-margin;
    margin-bottom: sprem(16);
    text-align: center;
  }
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}
/* recruit-guide
********************************************** */
.archive-recruit-guide .container {
  background-color: var(--clr-beige);
}
.archive-recruit-guide .post.no_recruit {
  pointer-events: none;
  opacity: .5;
}
.posts-recruit-guide .post {
  box-shadow: 0px 0px 15px 2px var(--clr-beige-02);
  @media (hover:hover) {
    &:hover .post--img img {
      scale: 1.1;
    }
    &:hover .post--ttl a {
      color: var(--clr-orange);
    }
  }
}
.posts-recruit-guide .post--txtarea {
  width: 100%;
  height: 100%;
  padding: rem(24) rem(40) rem(40);
  @include mq(sp) {
    padding: sprem(24);
  }
}
.posts-recruit-guide .post--txtarea {
  flex: 1;
  background-color: var(--clr-wht);
  border-bottom-right-radius: rem(40);
  border-bottom-left-radius: rem(40);
}
.posts-recruit-guide .post_recruit {
  position: absolute;
  top: 0;
  right: 0;
  padding: rem(4) rem(12);
  font-size: rem(16);
  background-color: var(--clr-brown);
  color: var(--clr-wht);
  border-bottom-left-radius: rem(8);
  @include mq(sp) {
    font-size: sprem(12);
  }
}
.posts-recruit-guide .post--img {
  overflow: hidden;
  @include mq(sp) {
    aspect-ratio: 16/9;
  }
}
.posts-recruit-guide .post--img img {
  transition: var(--transit-default);
}
.posts-recruit-guide .post--ttl {
  max-width: 80%;
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(17);
  }
}

/* news
**************************************** */
.archive-news .container {
  background-color: var(--clr-beige);
}

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--clr-wht);
}
.arc_news--cat--container.flex.gap120 {
  @include mq(sp) {
    gap: sprem(44);
  }
}
.arc_news--cat--wrap {
  margin-bottom: rem(32);
  padding: 1em 2em;
  background-color: var(--clr-wht-42);
  border-radius: rem(16);
  box-shadow: 0 0 3px var(--clr-beige-02);
}
.arc_news--cat--list a {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: .1em;
  text-decoration: underline dotted var(--clr-brown);
  text-decoration-thickness: .15em;
  text-underline-offset: 0.4em;
  @media(hover: hover) {
    &:hover {
      color: var(--clr-orange);
      text-decoration-color: var(--clr-orange);
    }
  }
}
.news_category-update .arc_news--cat--list .update,
.news_category-public-relations .arc_news--cat--list .public-relations,
.news_category-disclosure .arc_news--cat--list .disclosure {
  pointer-events: none;
}
.news_category-update .arc_news--cat--list .public-relations,
.news_category-update .arc_news--cat--list .disclosure {
  opacity: .5;
  @media(hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
}
.news_category-public-relations .arc_news--cat--list .update,
.news_category-public-relations .arc_news--cat--list .disclosure {
  opacity: .5;
  @media(hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
}
.news_category-disclosure .arc_news--cat--list .public-relations,
.news_category-disclosure .arc_news--cat--list .update {
  opacity: .5;
  @media(hover: hover) {
    &:hover {
      opacity: 1;
    }
  }
}

/* --- posts-news --- */
.posts-news .post {
  padding-bottom: rem(16);
  padding-top: rem(16);
  margin-bottom: 0;
  @include mq(sp) {
    display: block;
  }
  @media(hover: hover) {
    &:hover .post--img img {
      scale: 1.1;
    }
    &:hover .post--ttl a {
      color: var(--clr-orange);
    }
  }
}
.news--info .post--img,
.news--info .post--img img {
  border-radius: rem(16);
}
.post--info.mgn-btm8 {
  @include mq(sp) {
    margin-bottom: sprem(14);
  }
}
.posts-news .post--img {
  width: 100%;
  border-radius: rem(16);
  overflow: hidden;
  @include mq(sp, min, ps) {
    max-width: rem(150);
  }
  @include mq(sp) {
    aspect-ratio: 16/9;
    margin-bottom: sprem(16);
  }
}
.posts-news .post--img img {
  transition: var(--transit-default);
}
.posts-news .post--info {
  @include mq(sp, min, ps) {
    margin-right: rem(32);
  }
  @include mq(sp) {
    margin-bottom: sprem(8);
  }
}
.posts-news .post--ttl {
  font-size: calc-fz(18);
}
.posts-news .post--info.flex .post--date + .cat_list {
  margin-left: rem(16);
}

@use "../abstracts" as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  background-color: var(--bg-primary);
  z-index: z(header);
  transition: var(--transit-default);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: rem(80);
    padding-right: rem(80);
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    & > .inner {
      padding-left: rem(30);
    }
  }
}
.page-contact-simple .header {
  position: absolute;
}
.header-sm {
  @include mq(med, min, ps) {
    background-color: var(--bg-wht);
    height: rem($header-hgt-fx);
  }
}

/* --- logo -- */
.header--logo {
  transition: var(--transit-default);
  @include mq(med, min, ps) {
    transform: translateY(10px);
  }
  @include mq(med) {
    padding-top: sprem(8);
    transform: translateY(4px);
  }
}
.header--logo a,
.footer--logo a {
  display: inline-block;
  &:hover {
    opacity: 0.7;
  }
}
.header--logo img {
  @include mq(med, min, ps) {
    width: rem(120) !important;
  }
  @include mq(med) {
    width: auto;
    max-height: rem(64);
  }
}
.header--logo span {
  top: calc(-1em - .4375rem);
  left: 0;
  font-size: rem(13);
  font-family: var(--font-secondary);
  letter-spacing: .1em;
  color: var(--clr-link);
  white-space: nowrap;
  @include mq(med) {
    top: calc(-.5em - .4375rem);
  }
}

/* gnav
********************************************** */
.gnav,
.gnav ul,
.gnav li,
.gnav a {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
  }
  @include mq(med) {
    position: fixed;
    top: sprem($header-hgt-sp);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - sprem($header-hgt-sp));
    margin: 0;
    padding: rem(24) 7%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--bg-primary);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
    }
  }
}
.gnav a {
  @include center-flex;
  font-size: rem(17);
  font-family: var(--font-secondary);
  letter-spacing: .1em;
  @include mq(med) {
    justify-content: flex-start;
  }
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px dotted var(--clr-main);
    }
  }
}
.gnav--link {
  padding: 0 rem(24);
  @include mq(med) {
    display: block;
    padding: rem(18) rem(16);
  }
  @include mq(med, min, ps) {
    text-align: center;
  }
}
.gnav--menu li:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: sprem(10);
    margin: auto;
    @include sprect(7);
    border-top: 1px solid var(--clr-main);
    border-right: 1px solid var(--clr-main);
    transform: rotate(45deg);
  }
}
.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  color: var(--clr-orange);
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 100;
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
  @include mq(med) {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    box-shadow: 0 0 4px #bcbcbc;
    border-radius: rem(10);
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav a {
  display: block;
  padding: rem(16) rem(24);
  @include mq(med, min, ps) {
    background-color: var(--bg-wht);
  }
  &:hover {
    @include mq(med, min, ps) {
      color: var(--clr-main);
    }
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: .6em;
    right: rem(-8);
    @include sprect(40);
    transition: var(--transit-default);
    color: var(--clr-wht);
    background-color: var(--clr-orange);
    border-radius: 50vw;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 1px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}

/* ---btn --- */
.gnav_btn,
.tel_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    @include sprect(54);
    margin-top: sprem(12);
    margin-right: sprem(12);
    cursor: pointer;
    z-index: 9999;
    border-radius: 50vw;
  }
}
.gnav_btn {
  @include mq(med) {
    right: 0;
    transition: var(--transit-default);
    background-color: var(--bg-main-hvr);
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(24, 16);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-wht);
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(7)) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(sprem(-7)) rotate(45deg);
    }
  }
}
.tel_btn {
  @include mq(med) {
    right: sprem(64);
  }
  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    @include mq(med) {
      @include sprect(54);
      fill: var(--clr-main);
    }
  }
}
.gnav--cta {
  text-align: center;
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--tel,
.gnav--contact {
  @include mq(med, min, ps) {
    margin-left: rem(8);
  }
}
.gnav--tel a {
  display: inline-flex !important;
  align-items: flex-end;
}
.gnav--tel_phone {
  width: rem(40);
  aspect-ratio: 1/1;
  border-radius: 50vw;
  svg {
    fill: var(--clr-wht);
    width: 100%;
  }
}
.header-01 .tel_btn,
.header-01 .gnav--tel_phone {
  path {
    fill: #30cf6f;
  }
}
.gnav--tel .tel_txt {
  font-size: rem(16);
  letter-spacing: .1em;
}
.gnav--tel .tel {
  font-family: var(--font-en);
  font-weight: bold;
  font-size: calc-fz(27);
  line-height: 1;
  letter-spacing: .1em;
}
.gnav--tel .num {
  font-size: calc-fz(24);
}
.gnav--tel .hours {
  font-size: max(10px, rem(14));
}
.gnav--contact a {
  padding: rem(24);
  @include mq(med, min, ps) {
    border-radius: 0;
  }
  @include mq(med) {
    padding: sprem(10) sprem(16);
    margin-left: auto;
    margin-right: auto;
  }
}

/* header 01
********************************************** */
.header-01 {
  @include mq(med, min, ps) {
    .gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
    .gnav--menu > li.current-menu-item > .gnav--link {
      color: var(--clr-orange);
    }
  }
  .head_sub_nav--wrap {
    @include mq(med, min, ps) {
      width: max-content;
    }
  }
  .head_sub_nav li:first-child a {
    @include mq(med, min, ps) {
      border-top-left-radius: rem(8);
      border-top-right-radius: rem(8);
    }
  }
  .head_sub_nav li:last-child a {
    @include mq(med, min, ps) {
      border-bottom-left-radius: rem(8);
      border-bottom-right-radius: rem(8);
    }
  }
  .head_sub_nav a {
    line-height: 1.5;
    @include mq(med, min, ps) {
      background-color: var(--clr-beige);
    }
    &:hover {
      color: var(--clr-orange);
    }
  }
}
.header-01.header-sm {
  box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
}

/* header 02
********************************************** */
.header-02 {
  @include mq(med, min, ps) {
    padding-right: rem(32);
    box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    .gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
    .gnav--menu > li.current-menu-item > .gnav--link {
      color: var(--clr-main);
      background-color: var(--bg-off_wht);
    }
  }
  .head_sub_nav--wrap {
    left: 50%;
    translate: -50% 0;
    min-width: rem(248);
  }
  .head_sub_nav {
    position: relative;
    min-width: rem(248);
    background-color: var(--bg-wht);
    @include mq(med, min, ps) {
      padding: rem(20);
      margin-top: rem(20);
      border-radius: rem(8);
      box-shadow: 0 0 rem(32) rgb(0 0 0 / 15%);
    }
    @include mq(sp) {
      padding: sprem(10) sprem(16);
    }
    @include mq(med, min, ps) {
      &::before {
        content: "";
        position: absolute;
        top: rem(-12);
        left: calc(50% - rem(24) / 2);
        width: rem(24);
        height: rem(12);
        background-color: var(--bg-wht);
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
      }
    }
  }
  .head_sub_nav a {
    position: relative;
    padding: rem(10);
    text-align: left;
    line-height: 1.5;
    @include mq(sp) {
      padding: 1em 1.5em;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
      @include rect(8);
      border-top: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      transition: var(--transit-default);
    }
  }
  .gnav--contact a {
    gap: rem(8);
    @include mq(med, min, ps) {
      height: 130%;
      border-radius: 0 0 rem(24) rem(24);
      flex-direction: column;
    }
  }
  .icon-mail {
    @include mq(med, min, ps) {
      width: rem(32);
    }
  }
}

@use './variables' as *;
@use './functions' as *;
@use './mixins' as *;
@use 'sass:math';

@mixin deco-pc ($width) {
  @content;
  width: min(vw($width), rem($width));
}
@mixin deco-sp ($width) {
  @include mq(sp) {
    @content;
    width: spvw($width);
  }
}
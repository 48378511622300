@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$wid-var: 1700; // フォントサイズは画面幅がこの値～$wid-pcで可変する
$header-hgt: 130;
$header-hgt-sp: 84;
$header-hgt-fx: 114;

// 色
$clr-main: #faf4eb;
$bg-main: #faf4eb;

// CSS変数
:root {
  // typography
  --ltr-space-default: 0.08em;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: "Shuei KakuGo Kin M";
  --font-secondary: "TsukuARdGothicStd-D";
  --font-secondary-b: "TsukuARdGothicStd-B";
  --font-jp: "Shuei KakuGo Kin M" , sans-serif;
  --font-jp-l: "Shuei KakuGo Kin B" , sans-serif;
  --font-en: 'Ubuntu', sans-serif;

  // text colors
  --clr-main: #4f473d;
  --clr-sub: #bdbbb9;
  --clr-body: #4f473d;
  --clr-link: #4f473d;
  --clr-beige: #faf4eb;
  --clr-beige-02: #e7e3dc;
  --clr-beige-03: #fff8eb;
  --clr-brown: #a26803;
  --clr-brown-02: #9b5600;
  --clr-gray: #bdbbb9;
  --clr-wht: #fff;
  --clr-wht-42: rgba(255,255,255, .42);
  --clr-blk: #000;
  --clr-orange: #eb9d0c;

  --clr-yellow: #f09f0a;
  --clr-yellow-02: #fdcf5b;
  --clr-yellow-03: #fa9e00;
  --clr-yellow-04: #ffe310;
  --clr-yellow-05: #f8aa15;
  --clr-yellow-06: #f8a915;
  --clr-yellow-07: #fca919;
  --clr-yellow-08: #ffe9cf;
  --clr-yellow-09: #ffdc9b;
  --clr-yellow-10: #fceeca;

  --clr-green: #30cf6f;
  --clr-green-02: #cbfbdc;
  --clr-green-03: #329b5c;
  --clr-green-04: #2a9153;
  --clr-green-05: #d4ffe5;
  --clr-green-06: #34a361;
  --clr-green-07: #177d40;
  --clr-green-08: #228f4e;
  --clr-green-09: #b7e2c8;
  --clr-green-10: #066729;
  --clr-green-11: #0f6531;
  --clr-green-12: #13773b;
  --clr-green-13: #a4cf42;
  
  --clr-pink: #ed6b73;
  --clr-pink-02: #ffe0e2;
  --clr-pink-03: #b01a24;
  
  --clr-blue: #51a7dd;
  --clr-blue-02: #cfebfc;
  --clr-blue-03: #51a7dd;
  --clr-blue-04: #3e9cd6;
  
  --clr-purple: #8d64ff;
  --clr-purple-02: #9873ff;
  --clr-purple-03: #ebe4ff;
  --clr-purple-04: #5d469c;

  --clr-coral: #ff8b62;
  --clr-coral-02: #ffab8d;
  --clr-coral-03: #b33306;

  --clr-cta: #105ba9;
  --clr-cta-phone: #D73C46;

  // background colors
  --bg-main: #4f473d;
  --bg-main-hvr: #eb9d0c;
  --bg-primary: #faf8f5;
  --bg-sub: #f8f8f8;
  --bg-wht: #fff;
  --bg-off_wht: #faf4eb;
  --bg-blk: #000;
  --bg-yellow: #ffdc91;
  --bg-cta: #105ba9;
  --bg-cta-hvr: #dd6b2a;

  // border colors
  --border-main: #404040;

  // animation
  --transit-default: all 0.4s ease-out;

  // formy settings
}

// SASS Maps

$z-index: (
  "header": 9999,
  "top": 9990,
  "aside": 75,
  "middle": 50,
  "main": 1,
  "base": 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160,
  // 2560px
  lg: 120,
  // 1920px
  pc: 85.375,
  // 1366px
  med: 67.5,
  // 1080px
  sp: 47.9375,
  // 767px
  sm: 40,
  // 640px
  xs: 34.375,
  // 500px
  se: 21.25,
  // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  "blue": (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  "red": (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  "green": (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  ),
);

$aspect-ratios: (
  widescreen: "3/2",
  square: "1/1",
  portrait: "2/3",
);

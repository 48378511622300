@use "sass:math";
@use '../abstracts' as *;

/* flex
**************************************** */

/* --- ブロック要素 --- */
.flex {
  display: flex;
  @for $n from 1 through 300 {
    &.gap#{$n} {
      gap: rem($n);
    }
  }
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}
.flex-j-end {
  justify-content: flex-end;
}
.flex-j-ctr {
  justify-content: center;
}
.flex-j-between {
  justify-content: space-between;
}
.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}
.flex-a-end {
  align-items: flex-end;
}
.flex-a-ctr {
  align-items: center;
}
.flex-a-baseline {
  align-items: baseline;
}
.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}
.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}
.flex-c-start {
  align-content: flex-start;
}
.flex-c-start {
  align-content: flex-start;
}
.flex-c-end {
  align-content: flex-end;
}
.flex-c-ctr {
  align-content: center;
}
.flex-c-baseline {
  align-content: baseline;
}
.flex-c-stretch {
  align-content: stretch;
}

@include mq(sp) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}

@for $i from 1 through 8 {
  .flex-col#{$i} {
    flex-wrap: wrap;
    & > * {
      width: calc(100% / $i);
    }
    @for $n from 1 through 150 {
      &.gap#{$n} > * {
        width: calc((100% / $i) - (rem($n) / $i * ($i - 1)));
      }
      &.gap#{$n} {
        gap: rem($n);
      }
    }
  }
}

@include mq(sp) {
  @for $i from 1 through 8 {
    .flex-sp-col#{$i} {
      flex-wrap: wrap;
      & > * {
        width: calc(100% / $i);
      }
      @for $n from 1 through 150 {
        &.gap#{$n} > * {
          width: calc((100% / $i) - (rem($n) / $i * ($i - 1)));
        }
      }
      @for $n from 1 through 150 {
        &.gap-sp#{$n} > * {
          width: calc((100% / $i) - (sprem($n) / $i * ($i - 1)));
        }
      }
    }
  }
  .flex-sp-block {
    &[class*="gap"] {
      gap: sprem(24);
    }
    & > * {
      width: 100% !important;
    }
  }
}
@charset "UTF-8";
:root {
  --ltr-space-default: 0.08em;
  --line-height-default: 1.8;
  --line-height-hdr: 1.4;
  --line-height-none: 1;
  --font-primary: "Shuei KakuGo Kin M";
  --font-secondary: "TsukuARdGothicStd-D";
  --font-secondary-b: "TsukuARdGothicStd-B";
  --font-jp: "Shuei KakuGo Kin M" , sans-serif;
  --font-jp-l: "Shuei KakuGo Kin B" , sans-serif;
  --font-en: "Ubuntu", sans-serif;
  --clr-main: #4f473d;
  --clr-sub: #bdbbb9;
  --clr-body: #4f473d;
  --clr-link: #4f473d;
  --clr-beige: #faf4eb;
  --clr-beige-02: #e7e3dc;
  --clr-beige-03: #fff8eb;
  --clr-brown: #a26803;
  --clr-brown-02: #9b5600;
  --clr-gray: #bdbbb9;
  --clr-wht: #fff;
  --clr-wht-42: rgba(255,255,255, .42);
  --clr-blk: #000;
  --clr-orange: #eb9d0c;
  --clr-yellow: #f09f0a;
  --clr-yellow-02: #fdcf5b;
  --clr-yellow-03: #fa9e00;
  --clr-yellow-04: #ffe310;
  --clr-yellow-05: #f8aa15;
  --clr-yellow-06: #f8a915;
  --clr-yellow-07: #fca919;
  --clr-yellow-08: #ffe9cf;
  --clr-yellow-09: #ffdc9b;
  --clr-yellow-10: #fceeca;
  --clr-green: #30cf6f;
  --clr-green-02: #cbfbdc;
  --clr-green-03: #329b5c;
  --clr-green-04: #2a9153;
  --clr-green-05: #d4ffe5;
  --clr-green-06: #34a361;
  --clr-green-07: #177d40;
  --clr-green-08: #228f4e;
  --clr-green-09: #b7e2c8;
  --clr-green-10: #066729;
  --clr-green-11: #0f6531;
  --clr-green-12: #13773b;
  --clr-green-13: #a4cf42;
  --clr-pink: #ed6b73;
  --clr-pink-02: #ffe0e2;
  --clr-pink-03: #b01a24;
  --clr-blue: #51a7dd;
  --clr-blue-02: #cfebfc;
  --clr-blue-03: #51a7dd;
  --clr-blue-04: #3e9cd6;
  --clr-purple: #8d64ff;
  --clr-purple-02: #9873ff;
  --clr-purple-03: #ebe4ff;
  --clr-purple-04: #5d469c;
  --clr-coral: #ff8b62;
  --clr-coral-02: #ffab8d;
  --clr-coral-03: #b33306;
  --clr-cta: #105ba9;
  --clr-cta-phone: #D73C46;
  --bg-main: #4f473d;
  --bg-main-hvr: #eb9d0c;
  --bg-primary: #faf8f5;
  --bg-sub: #f8f8f8;
  --bg-wht: #fff;
  --bg-off_wht: #faf4eb;
  --bg-blk: #000;
  --bg-yellow: #ffdc91;
  --bg-cta: #105ba9;
  --bg-cta-hvr: #dd6b2a;
  --border-main: #404040;
  --transit-default: all 0.4s ease-out;
}

/* ##############################################################################

    KEYFRAMES

############################################################################## */
/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  background: #fff;
  color: #333;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ins {
  background-color: #ff9;
  color: #333;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #333;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*-- ClearFix --*/
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

/*隙間消し*/
li img {
  vertical-align: bottom;
}

/* Reset input[type="search"] */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

input[type=search]:focus {
  outline-offset: -2px;
}

input[type=search]::-webkit-search-decoration {
  display: none;
}

/* iOS reset */
input[type=submit] {
  -webkit-appearance: none;
}

@media print, screen and (min-width: 768px) {
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @-moz-document url-prefix() {
    * {
      font-feature-settings: "palt";
    }
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  * {
    font-feature-settings: "palt";
  }
}
/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: clamp(9px, 0.8333333333vw, 1rem);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  html {
    font-size: 0.875rem;
    overflow-y: auto;
    overflow-x: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  html {
    font-size: min(3.3816425121vw, 0.875rem);
  }
}

body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  color: var(--clr-body);
  height: 100%;
  word-break: break-word;
}
@media print, screen and (min-width: 48em) {
  body {
    padding-top: 8.125rem;
    font-size: clamp(15.9375px, 0.9375vw, 1.125rem);
  }
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  body:not(.mce-content-body) {
    min-width: 67.5rem;
  }
}
@media screen and (max-width: 67.5em) {
  body {
    padding-top: 6rem;
  }
}
@media screen and (max-width: 47.9375em) {
  body {
    -webkit-text-size-adjust: none;
  }
}

* {
  letter-spacing: var(--ltr-space-default);
}

ul,
ol {
  list-style: none;
}

small {
  font-size: max(12px, 0.875rem);
}
@media screen and (max-width: 47.9375em) {
  small {
    font-size: 0.9285714286rem;
  }
}

a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link);
  text-decoration: none;
  transition: var(--transit-default);
  outline: none;
}

a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus {
  text-decoration: none !important;
}

@media print, screen and (min-width: 48em) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

img {
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  img {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr);
}

svg {
  transition: var(--transit-default);
}
svg[preserveAspectRatio=none] {
  height: auto;
}

/* txt
**************************************** */
.txt-lg {
  font-size: max(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .txt-lg {
    font-size: 1.0714285714rem;
  }
}

.txt-sm {
  font-size: max(12px, 0.875rem);
}
@media screen and (max-width: 47.9375em) {
  .txt-sm {
    font-size: 0.9285714286rem;
  }
}

.txt-wh,
.txt-wh * {
  color: var(--clr-wht);
}

.txt-main,
.txt-main * {
  color: var(--clr-main);
}

.txt-sub,
.txt-sub * {
  color: var(--clr-sub);
}

/* --- font-size --- */
.fz8 {
  font-size: max(6px, 0.5rem);
}
@media screen and (max-width: 47.9375em) {
  .fz8 {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp8 {
    font-size: 0.5714285714rem !important;
  }
}

.fz9 {
  font-size: max(7px, 0.5625rem);
}
@media screen and (max-width: 47.9375em) {
  .fz9 {
    font-size: 0.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp9 {
    font-size: 0.6428571429rem !important;
  }
}

.fz10 {
  font-size: max(8px, 0.625rem);
}
@media screen and (max-width: 47.9375em) {
  .fz10 {
    font-size: 0.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp10 {
    font-size: 0.7142857143rem !important;
  }
}

.fz11 {
  font-size: max(9px, 0.6875rem);
}
@media screen and (max-width: 47.9375em) {
  .fz11 {
    font-size: 0.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp11 {
    font-size: 0.7857142857rem !important;
  }
}

.fz12 {
  font-size: max(10px, 0.75rem);
}
@media screen and (max-width: 47.9375em) {
  .fz12 {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp12 {
    font-size: 0.8571428571rem !important;
  }
}

.fz13 {
  font-size: max(11px, 0.8125rem);
}
@media screen and (max-width: 47.9375em) {
  .fz13 {
    font-size: 0.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp13 {
    font-size: 0.9285714286rem !important;
  }
}

.fz14 {
  font-size: max(12px, 0.875rem);
}
@media screen and (max-width: 47.9375em) {
  .fz14 {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp14 {
    font-size: 1rem !important;
  }
}

.fz15 {
  font-size: max(13px, 0.9375rem);
}
@media screen and (max-width: 47.9375em) {
  .fz15 {
    font-size: 0.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp15 {
    font-size: 1.0714285714rem !important;
  }
}

.fz16 {
  font-size: max(14px, 1rem);
}
@media screen and (max-width: 47.9375em) {
  .fz16 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp16 {
    font-size: 1.1428571429rem !important;
  }
}

.fz17 {
  font-size: max(15px, 1.0625rem);
}
@media screen and (max-width: 47.9375em) {
  .fz17 {
    font-size: 1.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp17 {
    font-size: 1.2142857143rem !important;
  }
}

.fz18 {
  font-size: max(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .fz18 {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp18 {
    font-size: 1.2857142857rem !important;
  }
}

.fz19 {
  font-size: max(17px, 1.1875rem);
}
@media screen and (max-width: 47.9375em) {
  .fz19 {
    font-size: 1.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp19 {
    font-size: 1.3571428571rem !important;
  }
}

.fz20 {
  font-size: max(18px, 1.25rem);
}
@media screen and (max-width: 47.9375em) {
  .fz20 {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp20 {
    font-size: 1.4285714286rem !important;
  }
}

.fz21 {
  font-size: max(19px, 1.3125rem);
}
@media screen and (max-width: 47.9375em) {
  .fz21 {
    font-size: 1.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp21 {
    font-size: 1.5rem !important;
  }
}

.fz22 {
  font-size: max(20px, 1.375rem);
}
@media screen and (max-width: 47.9375em) {
  .fz22 {
    font-size: 1.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp22 {
    font-size: 1.5714285714rem !important;
  }
}

.fz23 {
  font-size: max(21px, 1.4375rem);
}
@media screen and (max-width: 47.9375em) {
  .fz23 {
    font-size: 1.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp23 {
    font-size: 1.6428571429rem !important;
  }
}

.fz24 {
  font-size: max(22px, 1.5rem);
}
@media screen and (max-width: 47.9375em) {
  .fz24 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp24 {
    font-size: 1.7142857143rem !important;
  }
}

.fz25 {
  font-size: 1.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz25 {
    font-size: 1.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp25 {
    font-size: 1.7857142857rem !important;
  }
}

.fz26 {
  font-size: 1.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz26 {
    font-size: 1.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp26 {
    font-size: 1.8571428571rem !important;
  }
}

.fz27 {
  font-size: 1.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz27 {
    font-size: 1.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp27 {
    font-size: 1.9285714286rem !important;
  }
}

.fz28 {
  font-size: 1.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz28 {
    font-size: 1.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp28 {
    font-size: 2rem !important;
  }
}

.fz29 {
  font-size: 1.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz29 {
    font-size: 1.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp29 {
    font-size: 2.0714285714rem !important;
  }
}

.fz30 {
  font-size: 1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz30 {
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp30 {
    font-size: 2.1428571429rem !important;
  }
}

.fz31 {
  font-size: 1.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz31 {
    font-size: 1.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp31 {
    font-size: 2.2142857143rem !important;
  }
}

.fz32 {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .fz32 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp32 {
    font-size: 2.2857142857rem !important;
  }
}

.fz33 {
  font-size: 2.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz33 {
    font-size: 2.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp33 {
    font-size: 2.3571428571rem !important;
  }
}

.fz34 {
  font-size: 2.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz34 {
    font-size: 2.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp34 {
    font-size: 2.4285714286rem !important;
  }
}

.fz35 {
  font-size: 2.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz35 {
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp35 {
    font-size: 2.5rem !important;
  }
}

.fz36 {
  font-size: 2.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz36 {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp36 {
    font-size: 2.5714285714rem !important;
  }
}

.fz37 {
  font-size: 2.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz37 {
    font-size: 2.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp37 {
    font-size: 2.6428571429rem !important;
  }
}

.fz38 {
  font-size: 2.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz38 {
    font-size: 2.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp38 {
    font-size: 2.7142857143rem !important;
  }
}

.fz39 {
  font-size: 2.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz39 {
    font-size: 2.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp39 {
    font-size: 2.7857142857rem !important;
  }
}

.fz40 {
  font-size: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz40 {
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp40 {
    font-size: 2.8571428571rem !important;
  }
}

.fz41 {
  font-size: 2.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz41 {
    font-size: 2.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp41 {
    font-size: 2.9285714286rem !important;
  }
}

.fz42 {
  font-size: 2.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz42 {
    font-size: 2.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp42 {
    font-size: 3rem !important;
  }
}

.fz43 {
  font-size: 2.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz43 {
    font-size: 2.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp43 {
    font-size: 3.0714285714rem !important;
  }
}

.fz44 {
  font-size: 2.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz44 {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp44 {
    font-size: 3.1428571429rem !important;
  }
}

.fz45 {
  font-size: 2.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz45 {
    font-size: 2.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp45 {
    font-size: 3.2142857143rem !important;
  }
}

.fz46 {
  font-size: 2.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz46 {
    font-size: 2.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp46 {
    font-size: 3.2857142857rem !important;
  }
}

.fz47 {
  font-size: 2.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz47 {
    font-size: 2.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp47 {
    font-size: 3.3571428571rem !important;
  }
}

.fz48 {
  font-size: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .fz48 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp48 {
    font-size: 3.4285714286rem !important;
  }
}

.fz49 {
  font-size: 3.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz49 {
    font-size: 3.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp49 {
    font-size: 3.5rem !important;
  }
}

.fz50 {
  font-size: 3.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz50 {
    font-size: 3.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp50 {
    font-size: 3.5714285714rem !important;
  }
}

.fz51 {
  font-size: 3.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz51 {
    font-size: 3.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp51 {
    font-size: 3.6428571429rem !important;
  }
}

.fz52 {
  font-size: 3.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz52 {
    font-size: 3.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp52 {
    font-size: 3.7142857143rem !important;
  }
}

.fz53 {
  font-size: 3.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz53 {
    font-size: 3.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp53 {
    font-size: 3.7857142857rem !important;
  }
}

.fz54 {
  font-size: 3.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz54 {
    font-size: 3.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp54 {
    font-size: 3.8571428571rem !important;
  }
}

.fz55 {
  font-size: 3.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz55 {
    font-size: 3.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp55 {
    font-size: 3.9285714286rem !important;
  }
}

.fz56 {
  font-size: 3.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz56 {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp56 {
    font-size: 4rem !important;
  }
}

.fz57 {
  font-size: 3.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz57 {
    font-size: 3.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp57 {
    font-size: 4.0714285714rem !important;
  }
}

.fz58 {
  font-size: 3.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz58 {
    font-size: 3.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp58 {
    font-size: 4.1428571429rem !important;
  }
}

.fz59 {
  font-size: 3.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz59 {
    font-size: 3.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp59 {
    font-size: 4.2142857143rem !important;
  }
}

.fz60 {
  font-size: 3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz60 {
    font-size: 3.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp60 {
    font-size: 4.2857142857rem !important;
  }
}

.fz61 {
  font-size: 3.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz61 {
    font-size: 3.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp61 {
    font-size: 4.3571428571rem !important;
  }
}

.fz62 {
  font-size: 3.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz62 {
    font-size: 3.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp62 {
    font-size: 4.4285714286rem !important;
  }
}

.fz63 {
  font-size: 3.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz63 {
    font-size: 3.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp63 {
    font-size: 4.5rem !important;
  }
}

.fz64 {
  font-size: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .fz64 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp64 {
    font-size: 4.5714285714rem !important;
  }
}

.fz65 {
  font-size: 4.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz65 {
    font-size: 4.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp65 {
    font-size: 4.6428571429rem !important;
  }
}

.fz66 {
  font-size: 4.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz66 {
    font-size: 4.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp66 {
    font-size: 4.7142857143rem !important;
  }
}

.fz67 {
  font-size: 4.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz67 {
    font-size: 4.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp67 {
    font-size: 4.7857142857rem !important;
  }
}

.fz68 {
  font-size: 4.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz68 {
    font-size: 4.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp68 {
    font-size: 4.8571428571rem !important;
  }
}

.fz69 {
  font-size: 4.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz69 {
    font-size: 4.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp69 {
    font-size: 4.9285714286rem !important;
  }
}

.fz70 {
  font-size: 4.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz70 {
    font-size: 4.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp70 {
    font-size: 5rem !important;
  }
}

.fz71 {
  font-size: 4.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz71 {
    font-size: 4.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp71 {
    font-size: 5.0714285714rem !important;
  }
}

.fz72 {
  font-size: 4.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz72 {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp72 {
    font-size: 5.1428571429rem !important;
  }
}

.fz73 {
  font-size: 4.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz73 {
    font-size: 4.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp73 {
    font-size: 5.2142857143rem !important;
  }
}

.fz74 {
  font-size: 4.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz74 {
    font-size: 4.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp74 {
    font-size: 5.2857142857rem !important;
  }
}

.fz75 {
  font-size: 4.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz75 {
    font-size: 4.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp75 {
    font-size: 5.3571428571rem !important;
  }
}

.fz76 {
  font-size: 4.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz76 {
    font-size: 4.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp76 {
    font-size: 5.4285714286rem !important;
  }
}

.fz77 {
  font-size: 4.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz77 {
    font-size: 4.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp77 {
    font-size: 5.5rem !important;
  }
}

.fz78 {
  font-size: 4.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz78 {
    font-size: 4.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp78 {
    font-size: 5.5714285714rem !important;
  }
}

.fz79 {
  font-size: 4.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz79 {
    font-size: 4.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp79 {
    font-size: 5.6428571429rem !important;
  }
}

.fz80 {
  font-size: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz80 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp80 {
    font-size: 5.7142857143rem !important;
  }
}

.fz81 {
  font-size: 5.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz81 {
    font-size: 5.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp81 {
    font-size: 5.7857142857rem !important;
  }
}

.fz82 {
  font-size: 5.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz82 {
    font-size: 5.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp82 {
    font-size: 5.8571428571rem !important;
  }
}

.fz83 {
  font-size: 5.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz83 {
    font-size: 5.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp83 {
    font-size: 5.9285714286rem !important;
  }
}

.fz84 {
  font-size: 5.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz84 {
    font-size: 5.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp84 {
    font-size: 6rem !important;
  }
}

.fz85 {
  font-size: 5.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz85 {
    font-size: 5.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp85 {
    font-size: 6.0714285714rem !important;
  }
}

.fz86 {
  font-size: 5.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz86 {
    font-size: 5.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp86 {
    font-size: 6.1428571429rem !important;
  }
}

.fz87 {
  font-size: 5.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz87 {
    font-size: 5.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp87 {
    font-size: 6.2142857143rem !important;
  }
}

.fz88 {
  font-size: 5.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz88 {
    font-size: 5.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp88 {
    font-size: 6.2857142857rem !important;
  }
}

.fz89 {
  font-size: 5.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz89 {
    font-size: 5.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp89 {
    font-size: 6.3571428571rem !important;
  }
}

.fz90 {
  font-size: 5.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz90 {
    font-size: 5.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp90 {
    font-size: 6.4285714286rem !important;
  }
}

.fz91 {
  font-size: 5.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz91 {
    font-size: 5.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp91 {
    font-size: 6.5rem !important;
  }
}

.fz92 {
  font-size: 5.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz92 {
    font-size: 5.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp92 {
    font-size: 6.5714285714rem !important;
  }
}

.fz93 {
  font-size: 5.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz93 {
    font-size: 5.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp93 {
    font-size: 6.6428571429rem !important;
  }
}

.fz94 {
  font-size: 5.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz94 {
    font-size: 5.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp94 {
    font-size: 6.7142857143rem !important;
  }
}

.fz95 {
  font-size: 5.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz95 {
    font-size: 5.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp95 {
    font-size: 6.7857142857rem !important;
  }
}

.fz96 {
  font-size: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .fz96 {
    font-size: 6rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp96 {
    font-size: 6.8571428571rem !important;
  }
}

.fz97 {
  font-size: 6.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz97 {
    font-size: 6.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp97 {
    font-size: 6.9285714286rem !important;
  }
}

.fz98 {
  font-size: 6.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz98 {
    font-size: 6.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp98 {
    font-size: 7rem !important;
  }
}

.fz99 {
  font-size: 6.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz99 {
    font-size: 6.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp99 {
    font-size: 7.0714285714rem !important;
  }
}

.fz100 {
  font-size: 6.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz100 {
    font-size: 6.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp100 {
    font-size: 7.1428571429rem !important;
  }
}

.fz101 {
  font-size: 6.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz101 {
    font-size: 6.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp101 {
    font-size: 7.2142857143rem !important;
  }
}

.fz102 {
  font-size: 6.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz102 {
    font-size: 6.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp102 {
    font-size: 7.2857142857rem !important;
  }
}

.fz103 {
  font-size: 6.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz103 {
    font-size: 6.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp103 {
    font-size: 7.3571428571rem !important;
  }
}

.fz104 {
  font-size: 6.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz104 {
    font-size: 6.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp104 {
    font-size: 7.4285714286rem !important;
  }
}

.fz105 {
  font-size: 6.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz105 {
    font-size: 6.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp105 {
    font-size: 7.5rem !important;
  }
}

.fz106 {
  font-size: 6.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz106 {
    font-size: 6.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp106 {
    font-size: 7.5714285714rem !important;
  }
}

.fz107 {
  font-size: 6.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz107 {
    font-size: 6.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp107 {
    font-size: 7.6428571429rem !important;
  }
}

.fz108 {
  font-size: 6.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz108 {
    font-size: 6.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp108 {
    font-size: 7.7142857143rem !important;
  }
}

.fz109 {
  font-size: 6.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz109 {
    font-size: 6.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp109 {
    font-size: 7.7857142857rem !important;
  }
}

.fz110 {
  font-size: 6.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz110 {
    font-size: 6.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp110 {
    font-size: 7.8571428571rem !important;
  }
}

.fz111 {
  font-size: 6.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz111 {
    font-size: 6.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp111 {
    font-size: 7.9285714286rem !important;
  }
}

.fz112 {
  font-size: 7rem;
}
@media screen and (max-width: 47.9375em) {
  .fz112 {
    font-size: 7rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp112 {
    font-size: 8rem !important;
  }
}

.fz113 {
  font-size: 7.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz113 {
    font-size: 7.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp113 {
    font-size: 8.0714285714rem !important;
  }
}

.fz114 {
  font-size: 7.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz114 {
    font-size: 7.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp114 {
    font-size: 8.1428571429rem !important;
  }
}

.fz115 {
  font-size: 7.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz115 {
    font-size: 7.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp115 {
    font-size: 8.2142857143rem !important;
  }
}

.fz116 {
  font-size: 7.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz116 {
    font-size: 7.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp116 {
    font-size: 8.2857142857rem !important;
  }
}

.fz117 {
  font-size: 7.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz117 {
    font-size: 7.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp117 {
    font-size: 8.3571428571rem !important;
  }
}

.fz118 {
  font-size: 7.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz118 {
    font-size: 7.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp118 {
    font-size: 8.4285714286rem !important;
  }
}

.fz119 {
  font-size: 7.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz119 {
    font-size: 7.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp119 {
    font-size: 8.5rem !important;
  }
}

.fz120 {
  font-size: 7.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz120 {
    font-size: 7.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp120 {
    font-size: 8.5714285714rem !important;
  }
}

.fz121 {
  font-size: 7.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz121 {
    font-size: 7.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp121 {
    font-size: 8.6428571429rem !important;
  }
}

.fz122 {
  font-size: 7.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz122 {
    font-size: 7.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp122 {
    font-size: 8.7142857143rem !important;
  }
}

.fz123 {
  font-size: 7.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz123 {
    font-size: 7.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp123 {
    font-size: 8.7857142857rem !important;
  }
}

.fz124 {
  font-size: 7.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz124 {
    font-size: 7.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp124 {
    font-size: 8.8571428571rem !important;
  }
}

.fz125 {
  font-size: 7.8125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz125 {
    font-size: 7.8125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp125 {
    font-size: 8.9285714286rem !important;
  }
}

.fz126 {
  font-size: 7.875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz126 {
    font-size: 7.875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp126 {
    font-size: 9rem !important;
  }
}

.fz127 {
  font-size: 7.9375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz127 {
    font-size: 7.9375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp127 {
    font-size: 9.0714285714rem !important;
  }
}

.fz128 {
  font-size: 8rem;
}
@media screen and (max-width: 47.9375em) {
  .fz128 {
    font-size: 8rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp128 {
    font-size: 9.1428571429rem !important;
  }
}

.fz129 {
  font-size: 8.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz129 {
    font-size: 8.0625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp129 {
    font-size: 9.2142857143rem !important;
  }
}

.fz130 {
  font-size: 8.125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz130 {
    font-size: 8.125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp130 {
    font-size: 9.2857142857rem !important;
  }
}

.fz131 {
  font-size: 8.1875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz131 {
    font-size: 8.1875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp131 {
    font-size: 9.3571428571rem !important;
  }
}

.fz132 {
  font-size: 8.25rem;
}
@media screen and (max-width: 47.9375em) {
  .fz132 {
    font-size: 8.25rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp132 {
    font-size: 9.4285714286rem !important;
  }
}

.fz133 {
  font-size: 8.3125rem;
}
@media screen and (max-width: 47.9375em) {
  .fz133 {
    font-size: 8.3125rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp133 {
    font-size: 9.5rem !important;
  }
}

.fz134 {
  font-size: 8.375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz134 {
    font-size: 8.375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp134 {
    font-size: 9.5714285714rem !important;
  }
}

.fz135 {
  font-size: 8.4375rem;
}
@media screen and (max-width: 47.9375em) {
  .fz135 {
    font-size: 8.4375rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp135 {
    font-size: 9.6428571429rem !important;
  }
}

.fz136 {
  font-size: 8.5rem;
}
@media screen and (max-width: 47.9375em) {
  .fz136 {
    font-size: 8.5rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp136 {
    font-size: 9.7142857143rem !important;
  }
}

.fz137 {
  font-size: 8.5625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz137 {
    font-size: 8.5625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp137 {
    font-size: 9.7857142857rem !important;
  }
}

.fz138 {
  font-size: 8.625rem;
}
@media screen and (max-width: 47.9375em) {
  .fz138 {
    font-size: 8.625rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp138 {
    font-size: 9.8571428571rem !important;
  }
}

.fz139 {
  font-size: 8.6875rem;
}
@media screen and (max-width: 47.9375em) {
  .fz139 {
    font-size: 8.6875rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp139 {
    font-size: 9.9285714286rem !important;
  }
}

.fz140 {
  font-size: 8.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fz140 {
    font-size: 8.75rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .fz-sp140 {
    font-size: 10rem !important;
  }
}

/* --- font-family --- */
.font-jp {
  font-family: var(--font-jp);
}

.font-jp-ttl {
  font-family: var(--font-secondary);
}

.font-en {
  font-family: var(--font-en);
  font-weight: bold;
}

/* --- clip-txt --- */
.clip-txt1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clip-txt2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.clip-txt3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.clip-txt4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@media screen and (max-width: 47.9375em) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
}
/* Wysiwyg editor
 **************************************** */
.mce-content-body::before, .mce-content-body::after {
  content: "";
  display: table;
}
.mce-content-body::after {
  clear: both;
}

.mce-content-body ol {
  list-style: decimal;
}

.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin-left: 2.5em;
  padding-top: 0.5em;
}
.mce-content-body ul li::marker,
.mce-content-body ol li::marker {
  color: var(--clr-main);
  font-weight: 600;
  letter-spacing: 0.2em;
}

.mce-content-body ul li {
  list-style: none;
}
.mce-content-body ul li::before {
  content: "";
  position: absolute;
  top: 1.0625em;
  left: -2.125em;
  width: 0.625em;
  aspect-ratio: 1/1;
  border: 1px solid var(--clr-main);
  background-color: var(--clr-main);
}

.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--bg-wht);
}

.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}

.mce-content-body ol {
  list-style-type: decimal-leading-zero;
}

.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}

.mce-content-body hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-width: 1px;
  border-color: rgba(175, 163, 147, 0.4901960784);
}

.mce-content-body a img {
  transition: var(--transit-default);
}

.mce-content-body a:hover img {
  opacity: 0.8;
}

.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.mce-content-body img.aligncenter {
  display: block;
}

.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}

.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body strong {
  font-weight: bold;
}

.mce-content-body blockquote {
  margin: 1em 0;
  padding: 1rem 2.5rem;
  border-radius: 0.1875rem;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: var(--bg-sub);
}
.mce-content-body blockquote::before, .mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}
.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 0.875rem;
  left: 0.75rem;
}
.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 0.875rem;
  right: 0.75rem;
}

.mce-content-body blockquote p {
  margin-bottom: 0;
}

.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}

@media print, screen and (min-width: 48em) {
  .mce-content-body a[href*=tel] {
    text-decoration: none;
  }
}

.mce-content-body table {
  border: none;
  height: auto !important;
}
.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body .table2 {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 1.5rem;
}

.mce-content-body .table2 th,
.mce-content-body .table2 td {
  border-right: 1px solid #D3D3D3;
  border-bottom: 1px solid #D3D3D3;
}

.mce-content-body .table2 tr:first-child th {
  border-radius: 1.25rem 0 0 0;
}

.mce-content-body .table2 tr:first-child td {
  border-radius: 0 1.25rem 0 0;
}

.mce-content-body .table2 tr:last-child th {
  border-radius: 0 0 0 1.25rem;
}

.mce-content-body .table2 tr:last-child td {
  border-radius: 0 0 1.25rem 0;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body .table2.table-sp-block tr:first-child td {
    border-radius: 0;
  }
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body .table2.table-sp-block tr:last-child th {
    border-radius: 0;
  }
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body .table2.table-sp-block tr:first-child th {
    border-radius: 1.25rem 1.25rem 0 0;
  }
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body .table2.table-sp-block tr:last-child td {
    border-radius: 0 0 1.25rem 1.25rem;
  }
}

.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}

.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #D3D3D3;
  vertical-align: top;
}

.mce-content-body th {
  min-width: 7.5rem;
  text-align: left;
}

.mce-content-body sup {
  font-size: max(10px, 0.75rem);
  color: #555;
}

.bold {
  font-weight: bold;
  font-family: var(--font-jp-b);
  color: var(--clr-orange);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}
.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 1rem;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
  /* table scroll */
  .table-sp-scroll {
    width: 50rem;
  }
  .scroll {
    overflow-x: scroll;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
  }
  .scroll table {
    margin: 0;
  }
  .scroll--cap {
    text-align: right;
    margin: 1em 0 0 !important;
    opacity: 0.25;
    font-size: max(12px, 0.875rem);
  }
}
/* AddQuicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  font-family: var(--font-secondary);
}
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 2em;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl:not(:first-child),
  .editor-ttl2:not(:first-child),
  .editor-ttl3:not(:first-child) {
    margin-top: 1.5em;
  }
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 1em;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl:not(:last-child),
  .editor-ttl2:not(:last-child),
  .editor-ttl3:not(:last-child) {
    margin-bottom: 0.75em;
  }
}

.editor-ttl {
  font-size: 2rem;
  color: var(--clr-brown);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl {
    font-size: 1.4285714286rem;
  }
}

.editor-ttl2 {
  font-size: max(22px, 1.5rem);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl2 {
    font-size: 1.2857142857rem;
  }
}

.editor-ttl3 {
  font-size: max(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl3 {
    font-size: 1.1428571429rem;
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: max(12px, 0.875rem);
  opacity: 0.6;
}
@media screen and (max-width: 47.9375em) {
  .txt-attention {
    font-size: 0.9285714286rem;
  }
}
.txt-attention::before {
  content: "※";
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}
.table1 th::before,
.table1 td::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 0.1875rem);
}

.table1 th {
  color: var(--clr-main);
}
.table1 th::before {
  left: 0;
  border-bottom: 2px solid var(--clr-main);
}

.table1 td::before {
  right: 0;
  height: 1px;
  background-color: #D3D3D3;
}

.table2 {
  background-color: var(--bg-wht);
}
@media print, screen and (min-width: 48em) {
  .table2 th:not(:last-child) {
    border-right: 0;
    border-bottom: 1px solid var(--bg-off_wht);
  }
  .table2 td:not(:first-child) {
    border-left: 0;
  }
}

.table2 th {
  color: var(--clr-wht);
  background-color: var(--bg-main);
}

/* ボックス */
.box {
  padding: 1.5rem 2rem;
  background-color: var(--bg-off_wht);
  border-radius: 0.5rem;
}
@media screen and (max-width: 47.9375em) {
  .box {
    padding: 1.5rem;
  }
}
.box:not(:first-child) {
  margin-top: 2em;
}
.box:not(:last-child) {
  margin-bottom: 2em;
}

.box * {
  color: var(--clr-body);
}

.lps_sec:nth-child(odd) {
  background-color: var(--bg-wht);
}

.lps_sec:nth-child(even) .faq_item .box {
  background-color: var(--bg-off_wht);
}
.lps_sec:nth-child(even) .box {
  background-color: var(--bg-wht);
}

.lps_sec[style*=background-color] *:not(.bg-wh) .box {
  background-color: var(--bg-wht);
}

/* リスト */
ul.check--list li::before, ul.check--list li::after {
  content: "";
  position: absolute;
}
ul.check--list li::before {
  top: 0.625em;
  left: -2.5em;
  width: 1.5em;
  aspect-ratio: 1/1;
  background-color: var(--clr-main);
  border-radius: 50%;
}
ul.check--list li::after {
  top: 1.0625em;
  left: -2.0625em;
  width: 0.625em;
  height: 0.4375em;
  border-left: 2px solid var(--clr-wht);
  border-bottom: 2px solid var(--clr-wht);
  transform: rotate(-45deg);
}

@media screen and (max-width: 47.9375em) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}

.mce-content-body.wp-editor {
  font-size: 16px;
  padding: 1.5rem !important;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* bg
********************************************** */
.bg-main {
  background-color: var(--bg-main);
}

.bg-sub {
  background-color: var(--bg-sub);
}

.bg-wh {
  background-color: var(--bg-wht);
}

.bg-off_wht {
  background-color: var(--bg-off_wht);
}

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}

.sp-none {
  display: block;
}

.sp-none-inline {
  display: inline;
}

.sp-none-table {
  display: table;
}

.sp-none-flex {
  display: flex;
}

@media screen and (max-width: 47.9375em) {
  /* --- pc or sp --- */
  .sp-none,
  .sp-none-inline,
  .sp-none-table,
  .sp-none-flex {
    display: none;
  }
  .pc-none {
    display: block;
  }
  .pc-none-inline {
    display: inline;
  }
  .pc-none-table {
    display: table;
  }
  .pc-none-flex {
    display: flex;
  }
}
/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trim-widescreen {
  aspect-ratio: 3/2;
}

.trim-square {
  aspect-ratio: 1/1;
}

.trim-portrait {
  aspect-ratio: 2/3;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.mgn0 {
  margin: 0rem;
}

.mgn-top0 {
  margin-top: 0rem;
}

.mgn-rgt0 {
  margin-right: 0rem;
}

.mgn-btm0 {
  margin-bottom: 0rem;
}

.mgn-lft0 {
  margin-left: 0rem;
}

.mgn1 {
  margin: 0.0625rem;
}

.mgn-top1 {
  margin-top: 0.0625rem;
}

.mgn-rgt1 {
  margin-right: 0.0625rem;
}

.mgn-btm1 {
  margin-bottom: 0.0625rem;
}

.mgn-lft1 {
  margin-left: 0.0625rem;
}

.mgn2 {
  margin: 0.125rem;
}

.mgn-top2 {
  margin-top: 0.125rem;
}

.mgn-rgt2 {
  margin-right: 0.125rem;
}

.mgn-btm2 {
  margin-bottom: 0.125rem;
}

.mgn-lft2 {
  margin-left: 0.125rem;
}

.mgn3 {
  margin: 0.1875rem;
}

.mgn-top3 {
  margin-top: 0.1875rem;
}

.mgn-rgt3 {
  margin-right: 0.1875rem;
}

.mgn-btm3 {
  margin-bottom: 0.1875rem;
}

.mgn-lft3 {
  margin-left: 0.1875rem;
}

.mgn4 {
  margin: 0.25rem;
}

.mgn-top4 {
  margin-top: 0.25rem;
}

.mgn-rgt4 {
  margin-right: 0.25rem;
}

.mgn-btm4 {
  margin-bottom: 0.25rem;
}

.mgn-lft4 {
  margin-left: 0.25rem;
}

.mgn5 {
  margin: 0.3125rem;
}

.mgn-top5 {
  margin-top: 0.3125rem;
}

.mgn-rgt5 {
  margin-right: 0.3125rem;
}

.mgn-btm5 {
  margin-bottom: 0.3125rem;
}

.mgn-lft5 {
  margin-left: 0.3125rem;
}

.mgn6 {
  margin: 0.375rem;
}

.mgn-top6 {
  margin-top: 0.375rem;
}

.mgn-rgt6 {
  margin-right: 0.375rem;
}

.mgn-btm6 {
  margin-bottom: 0.375rem;
}

.mgn-lft6 {
  margin-left: 0.375rem;
}

.mgn7 {
  margin: 0.4375rem;
}

.mgn-top7 {
  margin-top: 0.4375rem;
}

.mgn-rgt7 {
  margin-right: 0.4375rem;
}

.mgn-btm7 {
  margin-bottom: 0.4375rem;
}

.mgn-lft7 {
  margin-left: 0.4375rem;
}

.mgn8 {
  margin: 0.5rem;
}

.mgn-top8 {
  margin-top: 0.5rem;
}

.mgn-rgt8 {
  margin-right: 0.5rem;
}

.mgn-btm8 {
  margin-bottom: 0.5rem;
}

.mgn-lft8 {
  margin-left: 0.5rem;
}

.mgn9 {
  margin: 0.5625rem;
}

.mgn-top9 {
  margin-top: 0.5625rem;
}

.mgn-rgt9 {
  margin-right: 0.5625rem;
}

.mgn-btm9 {
  margin-bottom: 0.5625rem;
}

.mgn-lft9 {
  margin-left: 0.5625rem;
}

.mgn10 {
  margin: 0.625rem;
}

.mgn-top10 {
  margin-top: 0.625rem;
}

.mgn-rgt10 {
  margin-right: 0.625rem;
}

.mgn-btm10 {
  margin-bottom: 0.625rem;
}

.mgn-lft10 {
  margin-left: 0.625rem;
}

.mgn11 {
  margin: 0.6875rem;
}

.mgn-top11 {
  margin-top: 0.6875rem;
}

.mgn-rgt11 {
  margin-right: 0.6875rem;
}

.mgn-btm11 {
  margin-bottom: 0.6875rem;
}

.mgn-lft11 {
  margin-left: 0.6875rem;
}

.mgn12 {
  margin: 0.75rem;
}

.mgn-top12 {
  margin-top: 0.75rem;
}

.mgn-rgt12 {
  margin-right: 0.75rem;
}

.mgn-btm12 {
  margin-bottom: 0.75rem;
}

.mgn-lft12 {
  margin-left: 0.75rem;
}

.mgn13 {
  margin: 0.8125rem;
}

.mgn-top13 {
  margin-top: 0.8125rem;
}

.mgn-rgt13 {
  margin-right: 0.8125rem;
}

.mgn-btm13 {
  margin-bottom: 0.8125rem;
}

.mgn-lft13 {
  margin-left: 0.8125rem;
}

.mgn14 {
  margin: 0.875rem;
}

.mgn-top14 {
  margin-top: 0.875rem;
}

.mgn-rgt14 {
  margin-right: 0.875rem;
}

.mgn-btm14 {
  margin-bottom: 0.875rem;
}

.mgn-lft14 {
  margin-left: 0.875rem;
}

.mgn15 {
  margin: 0.9375rem;
}

.mgn-top15 {
  margin-top: 0.9375rem;
}

.mgn-rgt15 {
  margin-right: 0.9375rem;
}

.mgn-btm15 {
  margin-bottom: 0.9375rem;
}

.mgn-lft15 {
  margin-left: 0.9375rem;
}

.mgn16 {
  margin: 1rem;
}

.mgn-top16 {
  margin-top: 1rem;
}

.mgn-rgt16 {
  margin-right: 1rem;
}

.mgn-btm16 {
  margin-bottom: 1rem;
}

.mgn-lft16 {
  margin-left: 1rem;
}

.mgn17 {
  margin: 1.0625rem;
}

.mgn-top17 {
  margin-top: 1.0625rem;
}

.mgn-rgt17 {
  margin-right: 1.0625rem;
}

.mgn-btm17 {
  margin-bottom: 1.0625rem;
}

.mgn-lft17 {
  margin-left: 1.0625rem;
}

.mgn18 {
  margin: 1.125rem;
}

.mgn-top18 {
  margin-top: 1.125rem;
}

.mgn-rgt18 {
  margin-right: 1.125rem;
}

.mgn-btm18 {
  margin-bottom: 1.125rem;
}

.mgn-lft18 {
  margin-left: 1.125rem;
}

.mgn19 {
  margin: 1.1875rem;
}

.mgn-top19 {
  margin-top: 1.1875rem;
}

.mgn-rgt19 {
  margin-right: 1.1875rem;
}

.mgn-btm19 {
  margin-bottom: 1.1875rem;
}

.mgn-lft19 {
  margin-left: 1.1875rem;
}

.mgn20 {
  margin: 1.25rem;
}

.mgn-top20 {
  margin-top: 1.25rem;
}

.mgn-rgt20 {
  margin-right: 1.25rem;
}

.mgn-btm20 {
  margin-bottom: 1.25rem;
}

.mgn-lft20 {
  margin-left: 1.25rem;
}

.mgn21 {
  margin: 1.3125rem;
}

.mgn-top21 {
  margin-top: 1.3125rem;
}

.mgn-rgt21 {
  margin-right: 1.3125rem;
}

.mgn-btm21 {
  margin-bottom: 1.3125rem;
}

.mgn-lft21 {
  margin-left: 1.3125rem;
}

.mgn22 {
  margin: 1.375rem;
}

.mgn-top22 {
  margin-top: 1.375rem;
}

.mgn-rgt22 {
  margin-right: 1.375rem;
}

.mgn-btm22 {
  margin-bottom: 1.375rem;
}

.mgn-lft22 {
  margin-left: 1.375rem;
}

.mgn23 {
  margin: 1.4375rem;
}

.mgn-top23 {
  margin-top: 1.4375rem;
}

.mgn-rgt23 {
  margin-right: 1.4375rem;
}

.mgn-btm23 {
  margin-bottom: 1.4375rem;
}

.mgn-lft23 {
  margin-left: 1.4375rem;
}

.mgn24 {
  margin: 1.5rem;
}

.mgn-top24 {
  margin-top: 1.5rem;
}

.mgn-rgt24 {
  margin-right: 1.5rem;
}

.mgn-btm24 {
  margin-bottom: 1.5rem;
}

.mgn-lft24 {
  margin-left: 1.5rem;
}

.mgn25 {
  margin: 1.5625rem;
}

.mgn-top25 {
  margin-top: 1.5625rem;
}

.mgn-rgt25 {
  margin-right: 1.5625rem;
}

.mgn-btm25 {
  margin-bottom: 1.5625rem;
}

.mgn-lft25 {
  margin-left: 1.5625rem;
}

.mgn26 {
  margin: 1.625rem;
}

.mgn-top26 {
  margin-top: 1.625rem;
}

.mgn-rgt26 {
  margin-right: 1.625rem;
}

.mgn-btm26 {
  margin-bottom: 1.625rem;
}

.mgn-lft26 {
  margin-left: 1.625rem;
}

.mgn27 {
  margin: 1.6875rem;
}

.mgn-top27 {
  margin-top: 1.6875rem;
}

.mgn-rgt27 {
  margin-right: 1.6875rem;
}

.mgn-btm27 {
  margin-bottom: 1.6875rem;
}

.mgn-lft27 {
  margin-left: 1.6875rem;
}

.mgn28 {
  margin: 1.75rem;
}

.mgn-top28 {
  margin-top: 1.75rem;
}

.mgn-rgt28 {
  margin-right: 1.75rem;
}

.mgn-btm28 {
  margin-bottom: 1.75rem;
}

.mgn-lft28 {
  margin-left: 1.75rem;
}

.mgn29 {
  margin: 1.8125rem;
}

.mgn-top29 {
  margin-top: 1.8125rem;
}

.mgn-rgt29 {
  margin-right: 1.8125rem;
}

.mgn-btm29 {
  margin-bottom: 1.8125rem;
}

.mgn-lft29 {
  margin-left: 1.8125rem;
}

.mgn30 {
  margin: 1.875rem;
}

.mgn-top30 {
  margin-top: 1.875rem;
}

.mgn-rgt30 {
  margin-right: 1.875rem;
}

.mgn-btm30 {
  margin-bottom: 1.875rem;
}

.mgn-lft30 {
  margin-left: 1.875rem;
}

.mgn31 {
  margin: 1.9375rem;
}

.mgn-top31 {
  margin-top: 1.9375rem;
}

.mgn-rgt31 {
  margin-right: 1.9375rem;
}

.mgn-btm31 {
  margin-bottom: 1.9375rem;
}

.mgn-lft31 {
  margin-left: 1.9375rem;
}

.mgn32 {
  margin: 2rem;
}

.mgn-top32 {
  margin-top: 2rem;
}

.mgn-rgt32 {
  margin-right: 2rem;
}

.mgn-btm32 {
  margin-bottom: 2rem;
}

.mgn-lft32 {
  margin-left: 2rem;
}

.mgn33 {
  margin: 2.0625rem;
}

.mgn-top33 {
  margin-top: 2.0625rem;
}

.mgn-rgt33 {
  margin-right: 2.0625rem;
}

.mgn-btm33 {
  margin-bottom: 2.0625rem;
}

.mgn-lft33 {
  margin-left: 2.0625rem;
}

.mgn34 {
  margin: 2.125rem;
}

.mgn-top34 {
  margin-top: 2.125rem;
}

.mgn-rgt34 {
  margin-right: 2.125rem;
}

.mgn-btm34 {
  margin-bottom: 2.125rem;
}

.mgn-lft34 {
  margin-left: 2.125rem;
}

.mgn35 {
  margin: 2.1875rem;
}

.mgn-top35 {
  margin-top: 2.1875rem;
}

.mgn-rgt35 {
  margin-right: 2.1875rem;
}

.mgn-btm35 {
  margin-bottom: 2.1875rem;
}

.mgn-lft35 {
  margin-left: 2.1875rem;
}

.mgn36 {
  margin: 2.25rem;
}

.mgn-top36 {
  margin-top: 2.25rem;
}

.mgn-rgt36 {
  margin-right: 2.25rem;
}

.mgn-btm36 {
  margin-bottom: 2.25rem;
}

.mgn-lft36 {
  margin-left: 2.25rem;
}

.mgn37 {
  margin: 2.3125rem;
}

.mgn-top37 {
  margin-top: 2.3125rem;
}

.mgn-rgt37 {
  margin-right: 2.3125rem;
}

.mgn-btm37 {
  margin-bottom: 2.3125rem;
}

.mgn-lft37 {
  margin-left: 2.3125rem;
}

.mgn38 {
  margin: 2.375rem;
}

.mgn-top38 {
  margin-top: 2.375rem;
}

.mgn-rgt38 {
  margin-right: 2.375rem;
}

.mgn-btm38 {
  margin-bottom: 2.375rem;
}

.mgn-lft38 {
  margin-left: 2.375rem;
}

.mgn39 {
  margin: 2.4375rem;
}

.mgn-top39 {
  margin-top: 2.4375rem;
}

.mgn-rgt39 {
  margin-right: 2.4375rem;
}

.mgn-btm39 {
  margin-bottom: 2.4375rem;
}

.mgn-lft39 {
  margin-left: 2.4375rem;
}

.mgn40 {
  margin: 2.5rem;
}

.mgn-top40 {
  margin-top: 2.5rem;
}

.mgn-rgt40 {
  margin-right: 2.5rem;
}

.mgn-btm40 {
  margin-bottom: 2.5rem;
}

.mgn-lft40 {
  margin-left: 2.5rem;
}

.mgn41 {
  margin: 2.5625rem;
}

.mgn-top41 {
  margin-top: 2.5625rem;
}

.mgn-rgt41 {
  margin-right: 2.5625rem;
}

.mgn-btm41 {
  margin-bottom: 2.5625rem;
}

.mgn-lft41 {
  margin-left: 2.5625rem;
}

.mgn42 {
  margin: 2.625rem;
}

.mgn-top42 {
  margin-top: 2.625rem;
}

.mgn-rgt42 {
  margin-right: 2.625rem;
}

.mgn-btm42 {
  margin-bottom: 2.625rem;
}

.mgn-lft42 {
  margin-left: 2.625rem;
}

.mgn43 {
  margin: 2.6875rem;
}

.mgn-top43 {
  margin-top: 2.6875rem;
}

.mgn-rgt43 {
  margin-right: 2.6875rem;
}

.mgn-btm43 {
  margin-bottom: 2.6875rem;
}

.mgn-lft43 {
  margin-left: 2.6875rem;
}

.mgn44 {
  margin: 2.75rem;
}

.mgn-top44 {
  margin-top: 2.75rem;
}

.mgn-rgt44 {
  margin-right: 2.75rem;
}

.mgn-btm44 {
  margin-bottom: 2.75rem;
}

.mgn-lft44 {
  margin-left: 2.75rem;
}

.mgn45 {
  margin: 2.8125rem;
}

.mgn-top45 {
  margin-top: 2.8125rem;
}

.mgn-rgt45 {
  margin-right: 2.8125rem;
}

.mgn-btm45 {
  margin-bottom: 2.8125rem;
}

.mgn-lft45 {
  margin-left: 2.8125rem;
}

.mgn46 {
  margin: 2.875rem;
}

.mgn-top46 {
  margin-top: 2.875rem;
}

.mgn-rgt46 {
  margin-right: 2.875rem;
}

.mgn-btm46 {
  margin-bottom: 2.875rem;
}

.mgn-lft46 {
  margin-left: 2.875rem;
}

.mgn47 {
  margin: 2.9375rem;
}

.mgn-top47 {
  margin-top: 2.9375rem;
}

.mgn-rgt47 {
  margin-right: 2.9375rem;
}

.mgn-btm47 {
  margin-bottom: 2.9375rem;
}

.mgn-lft47 {
  margin-left: 2.9375rem;
}

.mgn48 {
  margin: 3rem;
}

.mgn-top48 {
  margin-top: 3rem;
}

.mgn-rgt48 {
  margin-right: 3rem;
}

.mgn-btm48 {
  margin-bottom: 3rem;
}

.mgn-lft48 {
  margin-left: 3rem;
}

.mgn49 {
  margin: 3.0625rem;
}

.mgn-top49 {
  margin-top: 3.0625rem;
}

.mgn-rgt49 {
  margin-right: 3.0625rem;
}

.mgn-btm49 {
  margin-bottom: 3.0625rem;
}

.mgn-lft49 {
  margin-left: 3.0625rem;
}

.mgn50 {
  margin: 3.125rem;
}

.mgn-top50 {
  margin-top: 3.125rem;
}

.mgn-rgt50 {
  margin-right: 3.125rem;
}

.mgn-btm50 {
  margin-bottom: 3.125rem;
}

.mgn-lft50 {
  margin-left: 3.125rem;
}

.mgn51 {
  margin: 3.1875rem;
}

.mgn-top51 {
  margin-top: 3.1875rem;
}

.mgn-rgt51 {
  margin-right: 3.1875rem;
}

.mgn-btm51 {
  margin-bottom: 3.1875rem;
}

.mgn-lft51 {
  margin-left: 3.1875rem;
}

.mgn52 {
  margin: 3.25rem;
}

.mgn-top52 {
  margin-top: 3.25rem;
}

.mgn-rgt52 {
  margin-right: 3.25rem;
}

.mgn-btm52 {
  margin-bottom: 3.25rem;
}

.mgn-lft52 {
  margin-left: 3.25rem;
}

.mgn53 {
  margin: 3.3125rem;
}

.mgn-top53 {
  margin-top: 3.3125rem;
}

.mgn-rgt53 {
  margin-right: 3.3125rem;
}

.mgn-btm53 {
  margin-bottom: 3.3125rem;
}

.mgn-lft53 {
  margin-left: 3.3125rem;
}

.mgn54 {
  margin: 3.375rem;
}

.mgn-top54 {
  margin-top: 3.375rem;
}

.mgn-rgt54 {
  margin-right: 3.375rem;
}

.mgn-btm54 {
  margin-bottom: 3.375rem;
}

.mgn-lft54 {
  margin-left: 3.375rem;
}

.mgn55 {
  margin: 3.4375rem;
}

.mgn-top55 {
  margin-top: 3.4375rem;
}

.mgn-rgt55 {
  margin-right: 3.4375rem;
}

.mgn-btm55 {
  margin-bottom: 3.4375rem;
}

.mgn-lft55 {
  margin-left: 3.4375rem;
}

.mgn56 {
  margin: 3.5rem;
}

.mgn-top56 {
  margin-top: 3.5rem;
}

.mgn-rgt56 {
  margin-right: 3.5rem;
}

.mgn-btm56 {
  margin-bottom: 3.5rem;
}

.mgn-lft56 {
  margin-left: 3.5rem;
}

.mgn57 {
  margin: 3.5625rem;
}

.mgn-top57 {
  margin-top: 3.5625rem;
}

.mgn-rgt57 {
  margin-right: 3.5625rem;
}

.mgn-btm57 {
  margin-bottom: 3.5625rem;
}

.mgn-lft57 {
  margin-left: 3.5625rem;
}

.mgn58 {
  margin: 3.625rem;
}

.mgn-top58 {
  margin-top: 3.625rem;
}

.mgn-rgt58 {
  margin-right: 3.625rem;
}

.mgn-btm58 {
  margin-bottom: 3.625rem;
}

.mgn-lft58 {
  margin-left: 3.625rem;
}

.mgn59 {
  margin: 3.6875rem;
}

.mgn-top59 {
  margin-top: 3.6875rem;
}

.mgn-rgt59 {
  margin-right: 3.6875rem;
}

.mgn-btm59 {
  margin-bottom: 3.6875rem;
}

.mgn-lft59 {
  margin-left: 3.6875rem;
}

.mgn60 {
  margin: 3.75rem;
}

.mgn-top60 {
  margin-top: 3.75rem;
}

.mgn-rgt60 {
  margin-right: 3.75rem;
}

.mgn-btm60 {
  margin-bottom: 3.75rem;
}

.mgn-lft60 {
  margin-left: 3.75rem;
}

.mgn61 {
  margin: 3.8125rem;
}

.mgn-top61 {
  margin-top: 3.8125rem;
}

.mgn-rgt61 {
  margin-right: 3.8125rem;
}

.mgn-btm61 {
  margin-bottom: 3.8125rem;
}

.mgn-lft61 {
  margin-left: 3.8125rem;
}

.mgn62 {
  margin: 3.875rem;
}

.mgn-top62 {
  margin-top: 3.875rem;
}

.mgn-rgt62 {
  margin-right: 3.875rem;
}

.mgn-btm62 {
  margin-bottom: 3.875rem;
}

.mgn-lft62 {
  margin-left: 3.875rem;
}

.mgn63 {
  margin: 3.9375rem;
}

.mgn-top63 {
  margin-top: 3.9375rem;
}

.mgn-rgt63 {
  margin-right: 3.9375rem;
}

.mgn-btm63 {
  margin-bottom: 3.9375rem;
}

.mgn-lft63 {
  margin-left: 3.9375rem;
}

.mgn64 {
  margin: 4rem;
}

.mgn-top64 {
  margin-top: 4rem;
}

.mgn-rgt64 {
  margin-right: 4rem;
}

.mgn-btm64 {
  margin-bottom: 4rem;
}

.mgn-lft64 {
  margin-left: 4rem;
}

.mgn65 {
  margin: 4.0625rem;
}

.mgn-top65 {
  margin-top: 4.0625rem;
}

.mgn-rgt65 {
  margin-right: 4.0625rem;
}

.mgn-btm65 {
  margin-bottom: 4.0625rem;
}

.mgn-lft65 {
  margin-left: 4.0625rem;
}

.mgn66 {
  margin: 4.125rem;
}

.mgn-top66 {
  margin-top: 4.125rem;
}

.mgn-rgt66 {
  margin-right: 4.125rem;
}

.mgn-btm66 {
  margin-bottom: 4.125rem;
}

.mgn-lft66 {
  margin-left: 4.125rem;
}

.mgn67 {
  margin: 4.1875rem;
}

.mgn-top67 {
  margin-top: 4.1875rem;
}

.mgn-rgt67 {
  margin-right: 4.1875rem;
}

.mgn-btm67 {
  margin-bottom: 4.1875rem;
}

.mgn-lft67 {
  margin-left: 4.1875rem;
}

.mgn68 {
  margin: 4.25rem;
}

.mgn-top68 {
  margin-top: 4.25rem;
}

.mgn-rgt68 {
  margin-right: 4.25rem;
}

.mgn-btm68 {
  margin-bottom: 4.25rem;
}

.mgn-lft68 {
  margin-left: 4.25rem;
}

.mgn69 {
  margin: 4.3125rem;
}

.mgn-top69 {
  margin-top: 4.3125rem;
}

.mgn-rgt69 {
  margin-right: 4.3125rem;
}

.mgn-btm69 {
  margin-bottom: 4.3125rem;
}

.mgn-lft69 {
  margin-left: 4.3125rem;
}

.mgn70 {
  margin: 4.375rem;
}

.mgn-top70 {
  margin-top: 4.375rem;
}

.mgn-rgt70 {
  margin-right: 4.375rem;
}

.mgn-btm70 {
  margin-bottom: 4.375rem;
}

.mgn-lft70 {
  margin-left: 4.375rem;
}

.mgn71 {
  margin: 4.4375rem;
}

.mgn-top71 {
  margin-top: 4.4375rem;
}

.mgn-rgt71 {
  margin-right: 4.4375rem;
}

.mgn-btm71 {
  margin-bottom: 4.4375rem;
}

.mgn-lft71 {
  margin-left: 4.4375rem;
}

.mgn72 {
  margin: 4.5rem;
}

.mgn-top72 {
  margin-top: 4.5rem;
}

.mgn-rgt72 {
  margin-right: 4.5rem;
}

.mgn-btm72 {
  margin-bottom: 4.5rem;
}

.mgn-lft72 {
  margin-left: 4.5rem;
}

.mgn73 {
  margin: 4.5625rem;
}

.mgn-top73 {
  margin-top: 4.5625rem;
}

.mgn-rgt73 {
  margin-right: 4.5625rem;
}

.mgn-btm73 {
  margin-bottom: 4.5625rem;
}

.mgn-lft73 {
  margin-left: 4.5625rem;
}

.mgn74 {
  margin: 4.625rem;
}

.mgn-top74 {
  margin-top: 4.625rem;
}

.mgn-rgt74 {
  margin-right: 4.625rem;
}

.mgn-btm74 {
  margin-bottom: 4.625rem;
}

.mgn-lft74 {
  margin-left: 4.625rem;
}

.mgn75 {
  margin: 4.6875rem;
}

.mgn-top75 {
  margin-top: 4.6875rem;
}

.mgn-rgt75 {
  margin-right: 4.6875rem;
}

.mgn-btm75 {
  margin-bottom: 4.6875rem;
}

.mgn-lft75 {
  margin-left: 4.6875rem;
}

.mgn76 {
  margin: 4.75rem;
}

.mgn-top76 {
  margin-top: 4.75rem;
}

.mgn-rgt76 {
  margin-right: 4.75rem;
}

.mgn-btm76 {
  margin-bottom: 4.75rem;
}

.mgn-lft76 {
  margin-left: 4.75rem;
}

.mgn77 {
  margin: 4.8125rem;
}

.mgn-top77 {
  margin-top: 4.8125rem;
}

.mgn-rgt77 {
  margin-right: 4.8125rem;
}

.mgn-btm77 {
  margin-bottom: 4.8125rem;
}

.mgn-lft77 {
  margin-left: 4.8125rem;
}

.mgn78 {
  margin: 4.875rem;
}

.mgn-top78 {
  margin-top: 4.875rem;
}

.mgn-rgt78 {
  margin-right: 4.875rem;
}

.mgn-btm78 {
  margin-bottom: 4.875rem;
}

.mgn-lft78 {
  margin-left: 4.875rem;
}

.mgn79 {
  margin: 4.9375rem;
}

.mgn-top79 {
  margin-top: 4.9375rem;
}

.mgn-rgt79 {
  margin-right: 4.9375rem;
}

.mgn-btm79 {
  margin-bottom: 4.9375rem;
}

.mgn-lft79 {
  margin-left: 4.9375rem;
}

.mgn80 {
  margin: 5rem;
}

.mgn-top80 {
  margin-top: 5rem;
}

.mgn-rgt80 {
  margin-right: 5rem;
}

.mgn-btm80 {
  margin-bottom: 5rem;
}

.mgn-lft80 {
  margin-left: 5rem;
}

.mgn81 {
  margin: 5.0625rem;
}

.mgn-top81 {
  margin-top: 5.0625rem;
}

.mgn-rgt81 {
  margin-right: 5.0625rem;
}

.mgn-btm81 {
  margin-bottom: 5.0625rem;
}

.mgn-lft81 {
  margin-left: 5.0625rem;
}

.mgn82 {
  margin: 5.125rem;
}

.mgn-top82 {
  margin-top: 5.125rem;
}

.mgn-rgt82 {
  margin-right: 5.125rem;
}

.mgn-btm82 {
  margin-bottom: 5.125rem;
}

.mgn-lft82 {
  margin-left: 5.125rem;
}

.mgn83 {
  margin: 5.1875rem;
}

.mgn-top83 {
  margin-top: 5.1875rem;
}

.mgn-rgt83 {
  margin-right: 5.1875rem;
}

.mgn-btm83 {
  margin-bottom: 5.1875rem;
}

.mgn-lft83 {
  margin-left: 5.1875rem;
}

.mgn84 {
  margin: 5.25rem;
}

.mgn-top84 {
  margin-top: 5.25rem;
}

.mgn-rgt84 {
  margin-right: 5.25rem;
}

.mgn-btm84 {
  margin-bottom: 5.25rem;
}

.mgn-lft84 {
  margin-left: 5.25rem;
}

.mgn85 {
  margin: 5.3125rem;
}

.mgn-top85 {
  margin-top: 5.3125rem;
}

.mgn-rgt85 {
  margin-right: 5.3125rem;
}

.mgn-btm85 {
  margin-bottom: 5.3125rem;
}

.mgn-lft85 {
  margin-left: 5.3125rem;
}

.mgn86 {
  margin: 5.375rem;
}

.mgn-top86 {
  margin-top: 5.375rem;
}

.mgn-rgt86 {
  margin-right: 5.375rem;
}

.mgn-btm86 {
  margin-bottom: 5.375rem;
}

.mgn-lft86 {
  margin-left: 5.375rem;
}

.mgn87 {
  margin: 5.4375rem;
}

.mgn-top87 {
  margin-top: 5.4375rem;
}

.mgn-rgt87 {
  margin-right: 5.4375rem;
}

.mgn-btm87 {
  margin-bottom: 5.4375rem;
}

.mgn-lft87 {
  margin-left: 5.4375rem;
}

.mgn88 {
  margin: 5.5rem;
}

.mgn-top88 {
  margin-top: 5.5rem;
}

.mgn-rgt88 {
  margin-right: 5.5rem;
}

.mgn-btm88 {
  margin-bottom: 5.5rem;
}

.mgn-lft88 {
  margin-left: 5.5rem;
}

.mgn89 {
  margin: 5.5625rem;
}

.mgn-top89 {
  margin-top: 5.5625rem;
}

.mgn-rgt89 {
  margin-right: 5.5625rem;
}

.mgn-btm89 {
  margin-bottom: 5.5625rem;
}

.mgn-lft89 {
  margin-left: 5.5625rem;
}

.mgn90 {
  margin: 5.625rem;
}

.mgn-top90 {
  margin-top: 5.625rem;
}

.mgn-rgt90 {
  margin-right: 5.625rem;
}

.mgn-btm90 {
  margin-bottom: 5.625rem;
}

.mgn-lft90 {
  margin-left: 5.625rem;
}

.mgn91 {
  margin: 5.6875rem;
}

.mgn-top91 {
  margin-top: 5.6875rem;
}

.mgn-rgt91 {
  margin-right: 5.6875rem;
}

.mgn-btm91 {
  margin-bottom: 5.6875rem;
}

.mgn-lft91 {
  margin-left: 5.6875rem;
}

.mgn92 {
  margin: 5.75rem;
}

.mgn-top92 {
  margin-top: 5.75rem;
}

.mgn-rgt92 {
  margin-right: 5.75rem;
}

.mgn-btm92 {
  margin-bottom: 5.75rem;
}

.mgn-lft92 {
  margin-left: 5.75rem;
}

.mgn93 {
  margin: 5.8125rem;
}

.mgn-top93 {
  margin-top: 5.8125rem;
}

.mgn-rgt93 {
  margin-right: 5.8125rem;
}

.mgn-btm93 {
  margin-bottom: 5.8125rem;
}

.mgn-lft93 {
  margin-left: 5.8125rem;
}

.mgn94 {
  margin: 5.875rem;
}

.mgn-top94 {
  margin-top: 5.875rem;
}

.mgn-rgt94 {
  margin-right: 5.875rem;
}

.mgn-btm94 {
  margin-bottom: 5.875rem;
}

.mgn-lft94 {
  margin-left: 5.875rem;
}

.mgn95 {
  margin: 5.9375rem;
}

.mgn-top95 {
  margin-top: 5.9375rem;
}

.mgn-rgt95 {
  margin-right: 5.9375rem;
}

.mgn-btm95 {
  margin-bottom: 5.9375rem;
}

.mgn-lft95 {
  margin-left: 5.9375rem;
}

.mgn96 {
  margin: 6rem;
}

.mgn-top96 {
  margin-top: 6rem;
}

.mgn-rgt96 {
  margin-right: 6rem;
}

.mgn-btm96 {
  margin-bottom: 6rem;
}

.mgn-lft96 {
  margin-left: 6rem;
}

.mgn97 {
  margin: 6.0625rem;
}

.mgn-top97 {
  margin-top: 6.0625rem;
}

.mgn-rgt97 {
  margin-right: 6.0625rem;
}

.mgn-btm97 {
  margin-bottom: 6.0625rem;
}

.mgn-lft97 {
  margin-left: 6.0625rem;
}

.mgn98 {
  margin: 6.125rem;
}

.mgn-top98 {
  margin-top: 6.125rem;
}

.mgn-rgt98 {
  margin-right: 6.125rem;
}

.mgn-btm98 {
  margin-bottom: 6.125rem;
}

.mgn-lft98 {
  margin-left: 6.125rem;
}

.mgn99 {
  margin: 6.1875rem;
}

.mgn-top99 {
  margin-top: 6.1875rem;
}

.mgn-rgt99 {
  margin-right: 6.1875rem;
}

.mgn-btm99 {
  margin-bottom: 6.1875rem;
}

.mgn-lft99 {
  margin-left: 6.1875rem;
}

.mgn100 {
  margin: 6.25rem;
}

.mgn-top100 {
  margin-top: 6.25rem;
}

.mgn-rgt100 {
  margin-right: 6.25rem;
}

.mgn-btm100 {
  margin-bottom: 6.25rem;
}

.mgn-lft100 {
  margin-left: 6.25rem;
}

.mgn101 {
  margin: 6.3125rem;
}

.mgn-top101 {
  margin-top: 6.3125rem;
}

.mgn-rgt101 {
  margin-right: 6.3125rem;
}

.mgn-btm101 {
  margin-bottom: 6.3125rem;
}

.mgn-lft101 {
  margin-left: 6.3125rem;
}

.mgn102 {
  margin: 6.375rem;
}

.mgn-top102 {
  margin-top: 6.375rem;
}

.mgn-rgt102 {
  margin-right: 6.375rem;
}

.mgn-btm102 {
  margin-bottom: 6.375rem;
}

.mgn-lft102 {
  margin-left: 6.375rem;
}

.mgn103 {
  margin: 6.4375rem;
}

.mgn-top103 {
  margin-top: 6.4375rem;
}

.mgn-rgt103 {
  margin-right: 6.4375rem;
}

.mgn-btm103 {
  margin-bottom: 6.4375rem;
}

.mgn-lft103 {
  margin-left: 6.4375rem;
}

.mgn104 {
  margin: 6.5rem;
}

.mgn-top104 {
  margin-top: 6.5rem;
}

.mgn-rgt104 {
  margin-right: 6.5rem;
}

.mgn-btm104 {
  margin-bottom: 6.5rem;
}

.mgn-lft104 {
  margin-left: 6.5rem;
}

.mgn105 {
  margin: 6.5625rem;
}

.mgn-top105 {
  margin-top: 6.5625rem;
}

.mgn-rgt105 {
  margin-right: 6.5625rem;
}

.mgn-btm105 {
  margin-bottom: 6.5625rem;
}

.mgn-lft105 {
  margin-left: 6.5625rem;
}

.mgn106 {
  margin: 6.625rem;
}

.mgn-top106 {
  margin-top: 6.625rem;
}

.mgn-rgt106 {
  margin-right: 6.625rem;
}

.mgn-btm106 {
  margin-bottom: 6.625rem;
}

.mgn-lft106 {
  margin-left: 6.625rem;
}

.mgn107 {
  margin: 6.6875rem;
}

.mgn-top107 {
  margin-top: 6.6875rem;
}

.mgn-rgt107 {
  margin-right: 6.6875rem;
}

.mgn-btm107 {
  margin-bottom: 6.6875rem;
}

.mgn-lft107 {
  margin-left: 6.6875rem;
}

.mgn108 {
  margin: 6.75rem;
}

.mgn-top108 {
  margin-top: 6.75rem;
}

.mgn-rgt108 {
  margin-right: 6.75rem;
}

.mgn-btm108 {
  margin-bottom: 6.75rem;
}

.mgn-lft108 {
  margin-left: 6.75rem;
}

.mgn109 {
  margin: 6.8125rem;
}

.mgn-top109 {
  margin-top: 6.8125rem;
}

.mgn-rgt109 {
  margin-right: 6.8125rem;
}

.mgn-btm109 {
  margin-bottom: 6.8125rem;
}

.mgn-lft109 {
  margin-left: 6.8125rem;
}

.mgn110 {
  margin: 6.875rem;
}

.mgn-top110 {
  margin-top: 6.875rem;
}

.mgn-rgt110 {
  margin-right: 6.875rem;
}

.mgn-btm110 {
  margin-bottom: 6.875rem;
}

.mgn-lft110 {
  margin-left: 6.875rem;
}

.mgn111 {
  margin: 6.9375rem;
}

.mgn-top111 {
  margin-top: 6.9375rem;
}

.mgn-rgt111 {
  margin-right: 6.9375rem;
}

.mgn-btm111 {
  margin-bottom: 6.9375rem;
}

.mgn-lft111 {
  margin-left: 6.9375rem;
}

.mgn112 {
  margin: 7rem;
}

.mgn-top112 {
  margin-top: 7rem;
}

.mgn-rgt112 {
  margin-right: 7rem;
}

.mgn-btm112 {
  margin-bottom: 7rem;
}

.mgn-lft112 {
  margin-left: 7rem;
}

.mgn113 {
  margin: 7.0625rem;
}

.mgn-top113 {
  margin-top: 7.0625rem;
}

.mgn-rgt113 {
  margin-right: 7.0625rem;
}

.mgn-btm113 {
  margin-bottom: 7.0625rem;
}

.mgn-lft113 {
  margin-left: 7.0625rem;
}

.mgn114 {
  margin: 7.125rem;
}

.mgn-top114 {
  margin-top: 7.125rem;
}

.mgn-rgt114 {
  margin-right: 7.125rem;
}

.mgn-btm114 {
  margin-bottom: 7.125rem;
}

.mgn-lft114 {
  margin-left: 7.125rem;
}

.mgn115 {
  margin: 7.1875rem;
}

.mgn-top115 {
  margin-top: 7.1875rem;
}

.mgn-rgt115 {
  margin-right: 7.1875rem;
}

.mgn-btm115 {
  margin-bottom: 7.1875rem;
}

.mgn-lft115 {
  margin-left: 7.1875rem;
}

.mgn116 {
  margin: 7.25rem;
}

.mgn-top116 {
  margin-top: 7.25rem;
}

.mgn-rgt116 {
  margin-right: 7.25rem;
}

.mgn-btm116 {
  margin-bottom: 7.25rem;
}

.mgn-lft116 {
  margin-left: 7.25rem;
}

.mgn117 {
  margin: 7.3125rem;
}

.mgn-top117 {
  margin-top: 7.3125rem;
}

.mgn-rgt117 {
  margin-right: 7.3125rem;
}

.mgn-btm117 {
  margin-bottom: 7.3125rem;
}

.mgn-lft117 {
  margin-left: 7.3125rem;
}

.mgn118 {
  margin: 7.375rem;
}

.mgn-top118 {
  margin-top: 7.375rem;
}

.mgn-rgt118 {
  margin-right: 7.375rem;
}

.mgn-btm118 {
  margin-bottom: 7.375rem;
}

.mgn-lft118 {
  margin-left: 7.375rem;
}

.mgn119 {
  margin: 7.4375rem;
}

.mgn-top119 {
  margin-top: 7.4375rem;
}

.mgn-rgt119 {
  margin-right: 7.4375rem;
}

.mgn-btm119 {
  margin-bottom: 7.4375rem;
}

.mgn-lft119 {
  margin-left: 7.4375rem;
}

.mgn120 {
  margin: 7.5rem;
}

.mgn-top120 {
  margin-top: 7.5rem;
}

.mgn-rgt120 {
  margin-right: 7.5rem;
}

.mgn-btm120 {
  margin-bottom: 7.5rem;
}

.mgn-lft120 {
  margin-left: 7.5rem;
}

.mgn121 {
  margin: 7.5625rem;
}

.mgn-top121 {
  margin-top: 7.5625rem;
}

.mgn-rgt121 {
  margin-right: 7.5625rem;
}

.mgn-btm121 {
  margin-bottom: 7.5625rem;
}

.mgn-lft121 {
  margin-left: 7.5625rem;
}

.mgn122 {
  margin: 7.625rem;
}

.mgn-top122 {
  margin-top: 7.625rem;
}

.mgn-rgt122 {
  margin-right: 7.625rem;
}

.mgn-btm122 {
  margin-bottom: 7.625rem;
}

.mgn-lft122 {
  margin-left: 7.625rem;
}

.mgn123 {
  margin: 7.6875rem;
}

.mgn-top123 {
  margin-top: 7.6875rem;
}

.mgn-rgt123 {
  margin-right: 7.6875rem;
}

.mgn-btm123 {
  margin-bottom: 7.6875rem;
}

.mgn-lft123 {
  margin-left: 7.6875rem;
}

.mgn124 {
  margin: 7.75rem;
}

.mgn-top124 {
  margin-top: 7.75rem;
}

.mgn-rgt124 {
  margin-right: 7.75rem;
}

.mgn-btm124 {
  margin-bottom: 7.75rem;
}

.mgn-lft124 {
  margin-left: 7.75rem;
}

.mgn125 {
  margin: 7.8125rem;
}

.mgn-top125 {
  margin-top: 7.8125rem;
}

.mgn-rgt125 {
  margin-right: 7.8125rem;
}

.mgn-btm125 {
  margin-bottom: 7.8125rem;
}

.mgn-lft125 {
  margin-left: 7.8125rem;
}

.mgn126 {
  margin: 7.875rem;
}

.mgn-top126 {
  margin-top: 7.875rem;
}

.mgn-rgt126 {
  margin-right: 7.875rem;
}

.mgn-btm126 {
  margin-bottom: 7.875rem;
}

.mgn-lft126 {
  margin-left: 7.875rem;
}

.mgn127 {
  margin: 7.9375rem;
}

.mgn-top127 {
  margin-top: 7.9375rem;
}

.mgn-rgt127 {
  margin-right: 7.9375rem;
}

.mgn-btm127 {
  margin-bottom: 7.9375rem;
}

.mgn-lft127 {
  margin-left: 7.9375rem;
}

.mgn128 {
  margin: 8rem;
}

.mgn-top128 {
  margin-top: 8rem;
}

.mgn-rgt128 {
  margin-right: 8rem;
}

.mgn-btm128 {
  margin-bottom: 8rem;
}

.mgn-lft128 {
  margin-left: 8rem;
}

.mgn129 {
  margin: 8.0625rem;
}

.mgn-top129 {
  margin-top: 8.0625rem;
}

.mgn-rgt129 {
  margin-right: 8.0625rem;
}

.mgn-btm129 {
  margin-bottom: 8.0625rem;
}

.mgn-lft129 {
  margin-left: 8.0625rem;
}

.mgn130 {
  margin: 8.125rem;
}

.mgn-top130 {
  margin-top: 8.125rem;
}

.mgn-rgt130 {
  margin-right: 8.125rem;
}

.mgn-btm130 {
  margin-bottom: 8.125rem;
}

.mgn-lft130 {
  margin-left: 8.125rem;
}

.mgn131 {
  margin: 8.1875rem;
}

.mgn-top131 {
  margin-top: 8.1875rem;
}

.mgn-rgt131 {
  margin-right: 8.1875rem;
}

.mgn-btm131 {
  margin-bottom: 8.1875rem;
}

.mgn-lft131 {
  margin-left: 8.1875rem;
}

.mgn132 {
  margin: 8.25rem;
}

.mgn-top132 {
  margin-top: 8.25rem;
}

.mgn-rgt132 {
  margin-right: 8.25rem;
}

.mgn-btm132 {
  margin-bottom: 8.25rem;
}

.mgn-lft132 {
  margin-left: 8.25rem;
}

.mgn133 {
  margin: 8.3125rem;
}

.mgn-top133 {
  margin-top: 8.3125rem;
}

.mgn-rgt133 {
  margin-right: 8.3125rem;
}

.mgn-btm133 {
  margin-bottom: 8.3125rem;
}

.mgn-lft133 {
  margin-left: 8.3125rem;
}

.mgn134 {
  margin: 8.375rem;
}

.mgn-top134 {
  margin-top: 8.375rem;
}

.mgn-rgt134 {
  margin-right: 8.375rem;
}

.mgn-btm134 {
  margin-bottom: 8.375rem;
}

.mgn-lft134 {
  margin-left: 8.375rem;
}

.mgn135 {
  margin: 8.4375rem;
}

.mgn-top135 {
  margin-top: 8.4375rem;
}

.mgn-rgt135 {
  margin-right: 8.4375rem;
}

.mgn-btm135 {
  margin-bottom: 8.4375rem;
}

.mgn-lft135 {
  margin-left: 8.4375rem;
}

.mgn136 {
  margin: 8.5rem;
}

.mgn-top136 {
  margin-top: 8.5rem;
}

.mgn-rgt136 {
  margin-right: 8.5rem;
}

.mgn-btm136 {
  margin-bottom: 8.5rem;
}

.mgn-lft136 {
  margin-left: 8.5rem;
}

.mgn137 {
  margin: 8.5625rem;
}

.mgn-top137 {
  margin-top: 8.5625rem;
}

.mgn-rgt137 {
  margin-right: 8.5625rem;
}

.mgn-btm137 {
  margin-bottom: 8.5625rem;
}

.mgn-lft137 {
  margin-left: 8.5625rem;
}

.mgn138 {
  margin: 8.625rem;
}

.mgn-top138 {
  margin-top: 8.625rem;
}

.mgn-rgt138 {
  margin-right: 8.625rem;
}

.mgn-btm138 {
  margin-bottom: 8.625rem;
}

.mgn-lft138 {
  margin-left: 8.625rem;
}

.mgn139 {
  margin: 8.6875rem;
}

.mgn-top139 {
  margin-top: 8.6875rem;
}

.mgn-rgt139 {
  margin-right: 8.6875rem;
}

.mgn-btm139 {
  margin-bottom: 8.6875rem;
}

.mgn-lft139 {
  margin-left: 8.6875rem;
}

.mgn140 {
  margin: 8.75rem;
}

.mgn-top140 {
  margin-top: 8.75rem;
}

.mgn-rgt140 {
  margin-right: 8.75rem;
}

.mgn-btm140 {
  margin-bottom: 8.75rem;
}

.mgn-lft140 {
  margin-left: 8.75rem;
}

.mgn141 {
  margin: 8.8125rem;
}

.mgn-top141 {
  margin-top: 8.8125rem;
}

.mgn-rgt141 {
  margin-right: 8.8125rem;
}

.mgn-btm141 {
  margin-bottom: 8.8125rem;
}

.mgn-lft141 {
  margin-left: 8.8125rem;
}

.mgn142 {
  margin: 8.875rem;
}

.mgn-top142 {
  margin-top: 8.875rem;
}

.mgn-rgt142 {
  margin-right: 8.875rem;
}

.mgn-btm142 {
  margin-bottom: 8.875rem;
}

.mgn-lft142 {
  margin-left: 8.875rem;
}

.mgn143 {
  margin: 8.9375rem;
}

.mgn-top143 {
  margin-top: 8.9375rem;
}

.mgn-rgt143 {
  margin-right: 8.9375rem;
}

.mgn-btm143 {
  margin-bottom: 8.9375rem;
}

.mgn-lft143 {
  margin-left: 8.9375rem;
}

.mgn144 {
  margin: 9rem;
}

.mgn-top144 {
  margin-top: 9rem;
}

.mgn-rgt144 {
  margin-right: 9rem;
}

.mgn-btm144 {
  margin-bottom: 9rem;
}

.mgn-lft144 {
  margin-left: 9rem;
}

.mgn145 {
  margin: 9.0625rem;
}

.mgn-top145 {
  margin-top: 9.0625rem;
}

.mgn-rgt145 {
  margin-right: 9.0625rem;
}

.mgn-btm145 {
  margin-bottom: 9.0625rem;
}

.mgn-lft145 {
  margin-left: 9.0625rem;
}

.mgn146 {
  margin: 9.125rem;
}

.mgn-top146 {
  margin-top: 9.125rem;
}

.mgn-rgt146 {
  margin-right: 9.125rem;
}

.mgn-btm146 {
  margin-bottom: 9.125rem;
}

.mgn-lft146 {
  margin-left: 9.125rem;
}

.mgn147 {
  margin: 9.1875rem;
}

.mgn-top147 {
  margin-top: 9.1875rem;
}

.mgn-rgt147 {
  margin-right: 9.1875rem;
}

.mgn-btm147 {
  margin-bottom: 9.1875rem;
}

.mgn-lft147 {
  margin-left: 9.1875rem;
}

.mgn148 {
  margin: 9.25rem;
}

.mgn-top148 {
  margin-top: 9.25rem;
}

.mgn-rgt148 {
  margin-right: 9.25rem;
}

.mgn-btm148 {
  margin-bottom: 9.25rem;
}

.mgn-lft148 {
  margin-left: 9.25rem;
}

.mgn149 {
  margin: 9.3125rem;
}

.mgn-top149 {
  margin-top: 9.3125rem;
}

.mgn-rgt149 {
  margin-right: 9.3125rem;
}

.mgn-btm149 {
  margin-bottom: 9.3125rem;
}

.mgn-lft149 {
  margin-left: 9.3125rem;
}

.mgn150 {
  margin: 9.375rem;
}

.mgn-top150 {
  margin-top: 9.375rem;
}

.mgn-rgt150 {
  margin-right: 9.375rem;
}

.mgn-btm150 {
  margin-bottom: 9.375rem;
}

.mgn-lft150 {
  margin-left: 9.375rem;
}

@media screen and (max-width: 47.9375em) {
  .mgn0 {
    margin: 0rem;
  }
  .mgn-top0 {
    margin-top: 0rem;
  }
  .mgn-rgt0 {
    margin-right: 0rem;
  }
  .mgn-btm0 {
    margin-bottom: 0rem;
  }
  .mgn-lft0 {
    margin-left: 0rem;
  }
  .mgn1 {
    margin: 0.0357142857rem;
  }
  .mgn-top1 {
    margin-top: 0.0357142857rem;
  }
  .mgn-rgt1 {
    margin-right: 0.0357142857rem;
  }
  .mgn-btm1 {
    margin-bottom: 0.0357142857rem;
  }
  .mgn-lft1 {
    margin-left: 0.0357142857rem;
  }
  .mgn2 {
    margin: 0.0714285714rem;
  }
  .mgn-top2 {
    margin-top: 0.0714285714rem;
  }
  .mgn-rgt2 {
    margin-right: 0.0714285714rem;
  }
  .mgn-btm2 {
    margin-bottom: 0.0714285714rem;
  }
  .mgn-lft2 {
    margin-left: 0.0714285714rem;
  }
  .mgn3 {
    margin: 0.1071428571rem;
  }
  .mgn-top3 {
    margin-top: 0.1071428571rem;
  }
  .mgn-rgt3 {
    margin-right: 0.1071428571rem;
  }
  .mgn-btm3 {
    margin-bottom: 0.1071428571rem;
  }
  .mgn-lft3 {
    margin-left: 0.1071428571rem;
  }
  .mgn4 {
    margin: 0.1428571429rem;
  }
  .mgn-top4 {
    margin-top: 0.1428571429rem;
  }
  .mgn-rgt4 {
    margin-right: 0.1428571429rem;
  }
  .mgn-btm4 {
    margin-bottom: 0.1428571429rem;
  }
  .mgn-lft4 {
    margin-left: 0.1428571429rem;
  }
  .mgn5 {
    margin: 0.1785714286rem;
  }
  .mgn-top5 {
    margin-top: 0.1785714286rem;
  }
  .mgn-rgt5 {
    margin-right: 0.1785714286rem;
  }
  .mgn-btm5 {
    margin-bottom: 0.1785714286rem;
  }
  .mgn-lft5 {
    margin-left: 0.1785714286rem;
  }
  .mgn6 {
    margin: 0.2142857143rem;
  }
  .mgn-top6 {
    margin-top: 0.2142857143rem;
  }
  .mgn-rgt6 {
    margin-right: 0.2142857143rem;
  }
  .mgn-btm6 {
    margin-bottom: 0.2142857143rem;
  }
  .mgn-lft6 {
    margin-left: 0.2142857143rem;
  }
  .mgn7 {
    margin: 0.25rem;
  }
  .mgn-top7 {
    margin-top: 0.25rem;
  }
  .mgn-rgt7 {
    margin-right: 0.25rem;
  }
  .mgn-btm7 {
    margin-bottom: 0.25rem;
  }
  .mgn-lft7 {
    margin-left: 0.25rem;
  }
  .mgn8 {
    margin: 0.2857142857rem;
  }
  .mgn-top8 {
    margin-top: 0.2857142857rem;
  }
  .mgn-rgt8 {
    margin-right: 0.2857142857rem;
  }
  .mgn-btm8 {
    margin-bottom: 0.2857142857rem;
  }
  .mgn-lft8 {
    margin-left: 0.2857142857rem;
  }
  .mgn9 {
    margin: 0.3214285714rem;
  }
  .mgn-top9 {
    margin-top: 0.3214285714rem;
  }
  .mgn-rgt9 {
    margin-right: 0.3214285714rem;
  }
  .mgn-btm9 {
    margin-bottom: 0.3214285714rem;
  }
  .mgn-lft9 {
    margin-left: 0.3214285714rem;
  }
  .mgn10 {
    margin: 0.3571428571rem;
  }
  .mgn-top10 {
    margin-top: 0.3571428571rem;
  }
  .mgn-rgt10 {
    margin-right: 0.3571428571rem;
  }
  .mgn-btm10 {
    margin-bottom: 0.3571428571rem;
  }
  .mgn-lft10 {
    margin-left: 0.3571428571rem;
  }
  .mgn11 {
    margin: 0.3928571429rem;
  }
  .mgn-top11 {
    margin-top: 0.3928571429rem;
  }
  .mgn-rgt11 {
    margin-right: 0.3928571429rem;
  }
  .mgn-btm11 {
    margin-bottom: 0.3928571429rem;
  }
  .mgn-lft11 {
    margin-left: 0.3928571429rem;
  }
  .mgn12 {
    margin: 0.4285714286rem;
  }
  .mgn-top12 {
    margin-top: 0.4285714286rem;
  }
  .mgn-rgt12 {
    margin-right: 0.4285714286rem;
  }
  .mgn-btm12 {
    margin-bottom: 0.4285714286rem;
  }
  .mgn-lft12 {
    margin-left: 0.4285714286rem;
  }
  .mgn13 {
    margin: 0.4642857143rem;
  }
  .mgn-top13 {
    margin-top: 0.4642857143rem;
  }
  .mgn-rgt13 {
    margin-right: 0.4642857143rem;
  }
  .mgn-btm13 {
    margin-bottom: 0.4642857143rem;
  }
  .mgn-lft13 {
    margin-left: 0.4642857143rem;
  }
  .mgn14 {
    margin: 0.5rem;
  }
  .mgn-top14 {
    margin-top: 0.5rem;
  }
  .mgn-rgt14 {
    margin-right: 0.5rem;
  }
  .mgn-btm14 {
    margin-bottom: 0.5rem;
  }
  .mgn-lft14 {
    margin-left: 0.5rem;
  }
  .mgn15 {
    margin: 0.5357142857rem;
  }
  .mgn-top15 {
    margin-top: 0.5357142857rem;
  }
  .mgn-rgt15 {
    margin-right: 0.5357142857rem;
  }
  .mgn-btm15 {
    margin-bottom: 0.5357142857rem;
  }
  .mgn-lft15 {
    margin-left: 0.5357142857rem;
  }
  .mgn16 {
    margin: 0.5714285714rem;
  }
  .mgn-top16 {
    margin-top: 0.5714285714rem;
  }
  .mgn-rgt16 {
    margin-right: 0.5714285714rem;
  }
  .mgn-btm16 {
    margin-bottom: 0.5714285714rem;
  }
  .mgn-lft16 {
    margin-left: 0.5714285714rem;
  }
  .mgn17 {
    margin: 0.6071428571rem;
  }
  .mgn-top17 {
    margin-top: 0.6071428571rem;
  }
  .mgn-rgt17 {
    margin-right: 0.6071428571rem;
  }
  .mgn-btm17 {
    margin-bottom: 0.6071428571rem;
  }
  .mgn-lft17 {
    margin-left: 0.6071428571rem;
  }
  .mgn18 {
    margin: 0.6428571429rem;
  }
  .mgn-top18 {
    margin-top: 0.6428571429rem;
  }
  .mgn-rgt18 {
    margin-right: 0.6428571429rem;
  }
  .mgn-btm18 {
    margin-bottom: 0.6428571429rem;
  }
  .mgn-lft18 {
    margin-left: 0.6428571429rem;
  }
  .mgn19 {
    margin: 0.6785714286rem;
  }
  .mgn-top19 {
    margin-top: 0.6785714286rem;
  }
  .mgn-rgt19 {
    margin-right: 0.6785714286rem;
  }
  .mgn-btm19 {
    margin-bottom: 0.6785714286rem;
  }
  .mgn-lft19 {
    margin-left: 0.6785714286rem;
  }
  .mgn20 {
    margin: 0.7142857143rem;
  }
  .mgn-top20 {
    margin-top: 0.7142857143rem;
  }
  .mgn-rgt20 {
    margin-right: 0.7142857143rem;
  }
  .mgn-btm20 {
    margin-bottom: 0.7142857143rem;
  }
  .mgn-lft20 {
    margin-left: 0.7142857143rem;
  }
  .mgn21 {
    margin: 0.75rem;
  }
  .mgn-top21 {
    margin-top: 0.75rem;
  }
  .mgn-rgt21 {
    margin-right: 0.75rem;
  }
  .mgn-btm21 {
    margin-bottom: 0.75rem;
  }
  .mgn-lft21 {
    margin-left: 0.75rem;
  }
  .mgn22 {
    margin: 0.7857142857rem;
  }
  .mgn-top22 {
    margin-top: 0.7857142857rem;
  }
  .mgn-rgt22 {
    margin-right: 0.7857142857rem;
  }
  .mgn-btm22 {
    margin-bottom: 0.7857142857rem;
  }
  .mgn-lft22 {
    margin-left: 0.7857142857rem;
  }
  .mgn23 {
    margin: 0.8214285714rem;
  }
  .mgn-top23 {
    margin-top: 0.8214285714rem;
  }
  .mgn-rgt23 {
    margin-right: 0.8214285714rem;
  }
  .mgn-btm23 {
    margin-bottom: 0.8214285714rem;
  }
  .mgn-lft23 {
    margin-left: 0.8214285714rem;
  }
  .mgn24 {
    margin: 0.8571428571rem;
  }
  .mgn-top24 {
    margin-top: 0.8571428571rem;
  }
  .mgn-rgt24 {
    margin-right: 0.8571428571rem;
  }
  .mgn-btm24 {
    margin-bottom: 0.8571428571rem;
  }
  .mgn-lft24 {
    margin-left: 0.8571428571rem;
  }
  .mgn25 {
    margin: 0.8928571429rem;
  }
  .mgn-top25 {
    margin-top: 0.8928571429rem;
  }
  .mgn-rgt25 {
    margin-right: 0.8928571429rem;
  }
  .mgn-btm25 {
    margin-bottom: 0.8928571429rem;
  }
  .mgn-lft25 {
    margin-left: 0.8928571429rem;
  }
  .mgn26 {
    margin: 0.9285714286rem;
  }
  .mgn-top26 {
    margin-top: 0.9285714286rem;
  }
  .mgn-rgt26 {
    margin-right: 0.9285714286rem;
  }
  .mgn-btm26 {
    margin-bottom: 0.9285714286rem;
  }
  .mgn-lft26 {
    margin-left: 0.9285714286rem;
  }
  .mgn27 {
    margin: 0.9642857143rem;
  }
  .mgn-top27 {
    margin-top: 0.9642857143rem;
  }
  .mgn-rgt27 {
    margin-right: 0.9642857143rem;
  }
  .mgn-btm27 {
    margin-bottom: 0.9642857143rem;
  }
  .mgn-lft27 {
    margin-left: 0.9642857143rem;
  }
  .mgn28 {
    margin: 1rem;
  }
  .mgn-top28 {
    margin-top: 1rem;
  }
  .mgn-rgt28 {
    margin-right: 1rem;
  }
  .mgn-btm28 {
    margin-bottom: 1rem;
  }
  .mgn-lft28 {
    margin-left: 1rem;
  }
  .mgn29 {
    margin: 1.0357142857rem;
  }
  .mgn-top29 {
    margin-top: 1.0357142857rem;
  }
  .mgn-rgt29 {
    margin-right: 1.0357142857rem;
  }
  .mgn-btm29 {
    margin-bottom: 1.0357142857rem;
  }
  .mgn-lft29 {
    margin-left: 1.0357142857rem;
  }
  .mgn30 {
    margin: 1.0714285714rem;
  }
  .mgn-top30 {
    margin-top: 1.0714285714rem;
  }
  .mgn-rgt30 {
    margin-right: 1.0714285714rem;
  }
  .mgn-btm30 {
    margin-bottom: 1.0714285714rem;
  }
  .mgn-lft30 {
    margin-left: 1.0714285714rem;
  }
  .mgn31 {
    margin: 1.1071428571rem;
  }
  .mgn-top31 {
    margin-top: 1.1071428571rem;
  }
  .mgn-rgt31 {
    margin-right: 1.1071428571rem;
  }
  .mgn-btm31 {
    margin-bottom: 1.1071428571rem;
  }
  .mgn-lft31 {
    margin-left: 1.1071428571rem;
  }
  .mgn32 {
    margin: 1.1428571429rem;
  }
  .mgn-top32 {
    margin-top: 1.1428571429rem;
  }
  .mgn-rgt32 {
    margin-right: 1.1428571429rem;
  }
  .mgn-btm32 {
    margin-bottom: 1.1428571429rem;
  }
  .mgn-lft32 {
    margin-left: 1.1428571429rem;
  }
  .mgn33 {
    margin: 1.1785714286rem;
  }
  .mgn-top33 {
    margin-top: 1.1785714286rem;
  }
  .mgn-rgt33 {
    margin-right: 1.1785714286rem;
  }
  .mgn-btm33 {
    margin-bottom: 1.1785714286rem;
  }
  .mgn-lft33 {
    margin-left: 1.1785714286rem;
  }
  .mgn34 {
    margin: 1.2142857143rem;
  }
  .mgn-top34 {
    margin-top: 1.2142857143rem;
  }
  .mgn-rgt34 {
    margin-right: 1.2142857143rem;
  }
  .mgn-btm34 {
    margin-bottom: 1.2142857143rem;
  }
  .mgn-lft34 {
    margin-left: 1.2142857143rem;
  }
  .mgn35 {
    margin: 1.25rem;
  }
  .mgn-top35 {
    margin-top: 1.25rem;
  }
  .mgn-rgt35 {
    margin-right: 1.25rem;
  }
  .mgn-btm35 {
    margin-bottom: 1.25rem;
  }
  .mgn-lft35 {
    margin-left: 1.25rem;
  }
  .mgn36 {
    margin: 1.2857142857rem;
  }
  .mgn-top36 {
    margin-top: 1.2857142857rem;
  }
  .mgn-rgt36 {
    margin-right: 1.2857142857rem;
  }
  .mgn-btm36 {
    margin-bottom: 1.2857142857rem;
  }
  .mgn-lft36 {
    margin-left: 1.2857142857rem;
  }
  .mgn37 {
    margin: 1.3214285714rem;
  }
  .mgn-top37 {
    margin-top: 1.3214285714rem;
  }
  .mgn-rgt37 {
    margin-right: 1.3214285714rem;
  }
  .mgn-btm37 {
    margin-bottom: 1.3214285714rem;
  }
  .mgn-lft37 {
    margin-left: 1.3214285714rem;
  }
  .mgn38 {
    margin: 1.3571428571rem;
  }
  .mgn-top38 {
    margin-top: 1.3571428571rem;
  }
  .mgn-rgt38 {
    margin-right: 1.3571428571rem;
  }
  .mgn-btm38 {
    margin-bottom: 1.3571428571rem;
  }
  .mgn-lft38 {
    margin-left: 1.3571428571rem;
  }
  .mgn39 {
    margin: 1.3928571429rem;
  }
  .mgn-top39 {
    margin-top: 1.3928571429rem;
  }
  .mgn-rgt39 {
    margin-right: 1.3928571429rem;
  }
  .mgn-btm39 {
    margin-bottom: 1.3928571429rem;
  }
  .mgn-lft39 {
    margin-left: 1.3928571429rem;
  }
  .mgn40 {
    margin: 1.4285714286rem;
  }
  .mgn-top40 {
    margin-top: 1.4285714286rem;
  }
  .mgn-rgt40 {
    margin-right: 1.4285714286rem;
  }
  .mgn-btm40 {
    margin-bottom: 1.4285714286rem;
  }
  .mgn-lft40 {
    margin-left: 1.4285714286rem;
  }
  .mgn41 {
    margin: 1.4642857143rem;
  }
  .mgn-top41 {
    margin-top: 1.4642857143rem;
  }
  .mgn-rgt41 {
    margin-right: 1.4642857143rem;
  }
  .mgn-btm41 {
    margin-bottom: 1.4642857143rem;
  }
  .mgn-lft41 {
    margin-left: 1.4642857143rem;
  }
  .mgn42 {
    margin: 1.5rem;
  }
  .mgn-top42 {
    margin-top: 1.5rem;
  }
  .mgn-rgt42 {
    margin-right: 1.5rem;
  }
  .mgn-btm42 {
    margin-bottom: 1.5rem;
  }
  .mgn-lft42 {
    margin-left: 1.5rem;
  }
  .mgn43 {
    margin: 1.5357142857rem;
  }
  .mgn-top43 {
    margin-top: 1.5357142857rem;
  }
  .mgn-rgt43 {
    margin-right: 1.5357142857rem;
  }
  .mgn-btm43 {
    margin-bottom: 1.5357142857rem;
  }
  .mgn-lft43 {
    margin-left: 1.5357142857rem;
  }
  .mgn44 {
    margin: 1.5714285714rem;
  }
  .mgn-top44 {
    margin-top: 1.5714285714rem;
  }
  .mgn-rgt44 {
    margin-right: 1.5714285714rem;
  }
  .mgn-btm44 {
    margin-bottom: 1.5714285714rem;
  }
  .mgn-lft44 {
    margin-left: 1.5714285714rem;
  }
  .mgn45 {
    margin: 1.6071428571rem;
  }
  .mgn-top45 {
    margin-top: 1.6071428571rem;
  }
  .mgn-rgt45 {
    margin-right: 1.6071428571rem;
  }
  .mgn-btm45 {
    margin-bottom: 1.6071428571rem;
  }
  .mgn-lft45 {
    margin-left: 1.6071428571rem;
  }
  .mgn46 {
    margin: 1.6428571429rem;
  }
  .mgn-top46 {
    margin-top: 1.6428571429rem;
  }
  .mgn-rgt46 {
    margin-right: 1.6428571429rem;
  }
  .mgn-btm46 {
    margin-bottom: 1.6428571429rem;
  }
  .mgn-lft46 {
    margin-left: 1.6428571429rem;
  }
  .mgn47 {
    margin: 1.6785714286rem;
  }
  .mgn-top47 {
    margin-top: 1.6785714286rem;
  }
  .mgn-rgt47 {
    margin-right: 1.6785714286rem;
  }
  .mgn-btm47 {
    margin-bottom: 1.6785714286rem;
  }
  .mgn-lft47 {
    margin-left: 1.6785714286rem;
  }
  .mgn48 {
    margin: 1.7142857143rem;
  }
  .mgn-top48 {
    margin-top: 1.7142857143rem;
  }
  .mgn-rgt48 {
    margin-right: 1.7142857143rem;
  }
  .mgn-btm48 {
    margin-bottom: 1.7142857143rem;
  }
  .mgn-lft48 {
    margin-left: 1.7142857143rem;
  }
  .mgn49 {
    margin: 1.75rem;
  }
  .mgn-top49 {
    margin-top: 1.75rem;
  }
  .mgn-rgt49 {
    margin-right: 1.75rem;
  }
  .mgn-btm49 {
    margin-bottom: 1.75rem;
  }
  .mgn-lft49 {
    margin-left: 1.75rem;
  }
  .mgn50 {
    margin: 1.7857142857rem;
  }
  .mgn-top50 {
    margin-top: 1.7857142857rem;
  }
  .mgn-rgt50 {
    margin-right: 1.7857142857rem;
  }
  .mgn-btm50 {
    margin-bottom: 1.7857142857rem;
  }
  .mgn-lft50 {
    margin-left: 1.7857142857rem;
  }
  .mgn51 {
    margin: 1.8214285714rem;
  }
  .mgn-top51 {
    margin-top: 1.8214285714rem;
  }
  .mgn-rgt51 {
    margin-right: 1.8214285714rem;
  }
  .mgn-btm51 {
    margin-bottom: 1.8214285714rem;
  }
  .mgn-lft51 {
    margin-left: 1.8214285714rem;
  }
  .mgn52 {
    margin: 1.8571428571rem;
  }
  .mgn-top52 {
    margin-top: 1.8571428571rem;
  }
  .mgn-rgt52 {
    margin-right: 1.8571428571rem;
  }
  .mgn-btm52 {
    margin-bottom: 1.8571428571rem;
  }
  .mgn-lft52 {
    margin-left: 1.8571428571rem;
  }
  .mgn53 {
    margin: 1.8928571429rem;
  }
  .mgn-top53 {
    margin-top: 1.8928571429rem;
  }
  .mgn-rgt53 {
    margin-right: 1.8928571429rem;
  }
  .mgn-btm53 {
    margin-bottom: 1.8928571429rem;
  }
  .mgn-lft53 {
    margin-left: 1.8928571429rem;
  }
  .mgn54 {
    margin: 1.9285714286rem;
  }
  .mgn-top54 {
    margin-top: 1.9285714286rem;
  }
  .mgn-rgt54 {
    margin-right: 1.9285714286rem;
  }
  .mgn-btm54 {
    margin-bottom: 1.9285714286rem;
  }
  .mgn-lft54 {
    margin-left: 1.9285714286rem;
  }
  .mgn55 {
    margin: 1.9642857143rem;
  }
  .mgn-top55 {
    margin-top: 1.9642857143rem;
  }
  .mgn-rgt55 {
    margin-right: 1.9642857143rem;
  }
  .mgn-btm55 {
    margin-bottom: 1.9642857143rem;
  }
  .mgn-lft55 {
    margin-left: 1.9642857143rem;
  }
  .mgn56 {
    margin: 2rem;
  }
  .mgn-top56 {
    margin-top: 2rem;
  }
  .mgn-rgt56 {
    margin-right: 2rem;
  }
  .mgn-btm56 {
    margin-bottom: 2rem;
  }
  .mgn-lft56 {
    margin-left: 2rem;
  }
  .mgn57 {
    margin: 2.0357142857rem;
  }
  .mgn-top57 {
    margin-top: 2.0357142857rem;
  }
  .mgn-rgt57 {
    margin-right: 2.0357142857rem;
  }
  .mgn-btm57 {
    margin-bottom: 2.0357142857rem;
  }
  .mgn-lft57 {
    margin-left: 2.0357142857rem;
  }
  .mgn58 {
    margin: 2.0714285714rem;
  }
  .mgn-top58 {
    margin-top: 2.0714285714rem;
  }
  .mgn-rgt58 {
    margin-right: 2.0714285714rem;
  }
  .mgn-btm58 {
    margin-bottom: 2.0714285714rem;
  }
  .mgn-lft58 {
    margin-left: 2.0714285714rem;
  }
  .mgn59 {
    margin: 2.1071428571rem;
  }
  .mgn-top59 {
    margin-top: 2.1071428571rem;
  }
  .mgn-rgt59 {
    margin-right: 2.1071428571rem;
  }
  .mgn-btm59 {
    margin-bottom: 2.1071428571rem;
  }
  .mgn-lft59 {
    margin-left: 2.1071428571rem;
  }
  .mgn60 {
    margin: 2.1428571429rem;
  }
  .mgn-top60 {
    margin-top: 2.1428571429rem;
  }
  .mgn-rgt60 {
    margin-right: 2.1428571429rem;
  }
  .mgn-btm60 {
    margin-bottom: 2.1428571429rem;
  }
  .mgn-lft60 {
    margin-left: 2.1428571429rem;
  }
  .mgn61 {
    margin: 2.1785714286rem;
  }
  .mgn-top61 {
    margin-top: 2.1785714286rem;
  }
  .mgn-rgt61 {
    margin-right: 2.1785714286rem;
  }
  .mgn-btm61 {
    margin-bottom: 2.1785714286rem;
  }
  .mgn-lft61 {
    margin-left: 2.1785714286rem;
  }
  .mgn62 {
    margin: 2.2142857143rem;
  }
  .mgn-top62 {
    margin-top: 2.2142857143rem;
  }
  .mgn-rgt62 {
    margin-right: 2.2142857143rem;
  }
  .mgn-btm62 {
    margin-bottom: 2.2142857143rem;
  }
  .mgn-lft62 {
    margin-left: 2.2142857143rem;
  }
  .mgn63 {
    margin: 2.25rem;
  }
  .mgn-top63 {
    margin-top: 2.25rem;
  }
  .mgn-rgt63 {
    margin-right: 2.25rem;
  }
  .mgn-btm63 {
    margin-bottom: 2.25rem;
  }
  .mgn-lft63 {
    margin-left: 2.25rem;
  }
  .mgn64 {
    margin: 2.2857142857rem;
  }
  .mgn-top64 {
    margin-top: 2.2857142857rem;
  }
  .mgn-rgt64 {
    margin-right: 2.2857142857rem;
  }
  .mgn-btm64 {
    margin-bottom: 2.2857142857rem;
  }
  .mgn-lft64 {
    margin-left: 2.2857142857rem;
  }
  .mgn65 {
    margin: 2.3214285714rem;
  }
  .mgn-top65 {
    margin-top: 2.3214285714rem;
  }
  .mgn-rgt65 {
    margin-right: 2.3214285714rem;
  }
  .mgn-btm65 {
    margin-bottom: 2.3214285714rem;
  }
  .mgn-lft65 {
    margin-left: 2.3214285714rem;
  }
  .mgn66 {
    margin: 2.3571428571rem;
  }
  .mgn-top66 {
    margin-top: 2.3571428571rem;
  }
  .mgn-rgt66 {
    margin-right: 2.3571428571rem;
  }
  .mgn-btm66 {
    margin-bottom: 2.3571428571rem;
  }
  .mgn-lft66 {
    margin-left: 2.3571428571rem;
  }
  .mgn67 {
    margin: 2.3928571429rem;
  }
  .mgn-top67 {
    margin-top: 2.3928571429rem;
  }
  .mgn-rgt67 {
    margin-right: 2.3928571429rem;
  }
  .mgn-btm67 {
    margin-bottom: 2.3928571429rem;
  }
  .mgn-lft67 {
    margin-left: 2.3928571429rem;
  }
  .mgn68 {
    margin: 2.4285714286rem;
  }
  .mgn-top68 {
    margin-top: 2.4285714286rem;
  }
  .mgn-rgt68 {
    margin-right: 2.4285714286rem;
  }
  .mgn-btm68 {
    margin-bottom: 2.4285714286rem;
  }
  .mgn-lft68 {
    margin-left: 2.4285714286rem;
  }
  .mgn69 {
    margin: 2.4642857143rem;
  }
  .mgn-top69 {
    margin-top: 2.4642857143rem;
  }
  .mgn-rgt69 {
    margin-right: 2.4642857143rem;
  }
  .mgn-btm69 {
    margin-bottom: 2.4642857143rem;
  }
  .mgn-lft69 {
    margin-left: 2.4642857143rem;
  }
  .mgn70 {
    margin: 2.5rem;
  }
  .mgn-top70 {
    margin-top: 2.5rem;
  }
  .mgn-rgt70 {
    margin-right: 2.5rem;
  }
  .mgn-btm70 {
    margin-bottom: 2.5rem;
  }
  .mgn-lft70 {
    margin-left: 2.5rem;
  }
  .mgn71 {
    margin: 2.5357142857rem;
  }
  .mgn-top71 {
    margin-top: 2.5357142857rem;
  }
  .mgn-rgt71 {
    margin-right: 2.5357142857rem;
  }
  .mgn-btm71 {
    margin-bottom: 2.5357142857rem;
  }
  .mgn-lft71 {
    margin-left: 2.5357142857rem;
  }
  .mgn72 {
    margin: 2.5714285714rem;
  }
  .mgn-top72 {
    margin-top: 2.5714285714rem;
  }
  .mgn-rgt72 {
    margin-right: 2.5714285714rem;
  }
  .mgn-btm72 {
    margin-bottom: 2.5714285714rem;
  }
  .mgn-lft72 {
    margin-left: 2.5714285714rem;
  }
  .mgn73 {
    margin: 2.6071428571rem;
  }
  .mgn-top73 {
    margin-top: 2.6071428571rem;
  }
  .mgn-rgt73 {
    margin-right: 2.6071428571rem;
  }
  .mgn-btm73 {
    margin-bottom: 2.6071428571rem;
  }
  .mgn-lft73 {
    margin-left: 2.6071428571rem;
  }
  .mgn74 {
    margin: 2.6428571429rem;
  }
  .mgn-top74 {
    margin-top: 2.6428571429rem;
  }
  .mgn-rgt74 {
    margin-right: 2.6428571429rem;
  }
  .mgn-btm74 {
    margin-bottom: 2.6428571429rem;
  }
  .mgn-lft74 {
    margin-left: 2.6428571429rem;
  }
  .mgn75 {
    margin: 2.6785714286rem;
  }
  .mgn-top75 {
    margin-top: 2.6785714286rem;
  }
  .mgn-rgt75 {
    margin-right: 2.6785714286rem;
  }
  .mgn-btm75 {
    margin-bottom: 2.6785714286rem;
  }
  .mgn-lft75 {
    margin-left: 2.6785714286rem;
  }
  .mgn76 {
    margin: 2.7142857143rem;
  }
  .mgn-top76 {
    margin-top: 2.7142857143rem;
  }
  .mgn-rgt76 {
    margin-right: 2.7142857143rem;
  }
  .mgn-btm76 {
    margin-bottom: 2.7142857143rem;
  }
  .mgn-lft76 {
    margin-left: 2.7142857143rem;
  }
  .mgn77 {
    margin: 2.75rem;
  }
  .mgn-top77 {
    margin-top: 2.75rem;
  }
  .mgn-rgt77 {
    margin-right: 2.75rem;
  }
  .mgn-btm77 {
    margin-bottom: 2.75rem;
  }
  .mgn-lft77 {
    margin-left: 2.75rem;
  }
  .mgn78 {
    margin: 2.7857142857rem;
  }
  .mgn-top78 {
    margin-top: 2.7857142857rem;
  }
  .mgn-rgt78 {
    margin-right: 2.7857142857rem;
  }
  .mgn-btm78 {
    margin-bottom: 2.7857142857rem;
  }
  .mgn-lft78 {
    margin-left: 2.7857142857rem;
  }
  .mgn79 {
    margin: 2.8214285714rem;
  }
  .mgn-top79 {
    margin-top: 2.8214285714rem;
  }
  .mgn-rgt79 {
    margin-right: 2.8214285714rem;
  }
  .mgn-btm79 {
    margin-bottom: 2.8214285714rem;
  }
  .mgn-lft79 {
    margin-left: 2.8214285714rem;
  }
  .mgn80 {
    margin: 2.8571428571rem;
  }
  .mgn-top80 {
    margin-top: 2.8571428571rem;
  }
  .mgn-rgt80 {
    margin-right: 2.8571428571rem;
  }
  .mgn-btm80 {
    margin-bottom: 2.8571428571rem;
  }
  .mgn-lft80 {
    margin-left: 2.8571428571rem;
  }
  .mgn81 {
    margin: 2.8928571429rem;
  }
  .mgn-top81 {
    margin-top: 2.8928571429rem;
  }
  .mgn-rgt81 {
    margin-right: 2.8928571429rem;
  }
  .mgn-btm81 {
    margin-bottom: 2.8928571429rem;
  }
  .mgn-lft81 {
    margin-left: 2.8928571429rem;
  }
  .mgn82 {
    margin: 2.9285714286rem;
  }
  .mgn-top82 {
    margin-top: 2.9285714286rem;
  }
  .mgn-rgt82 {
    margin-right: 2.9285714286rem;
  }
  .mgn-btm82 {
    margin-bottom: 2.9285714286rem;
  }
  .mgn-lft82 {
    margin-left: 2.9285714286rem;
  }
  .mgn83 {
    margin: 2.9642857143rem;
  }
  .mgn-top83 {
    margin-top: 2.9642857143rem;
  }
  .mgn-rgt83 {
    margin-right: 2.9642857143rem;
  }
  .mgn-btm83 {
    margin-bottom: 2.9642857143rem;
  }
  .mgn-lft83 {
    margin-left: 2.9642857143rem;
  }
  .mgn84 {
    margin: 3rem;
  }
  .mgn-top84 {
    margin-top: 3rem;
  }
  .mgn-rgt84 {
    margin-right: 3rem;
  }
  .mgn-btm84 {
    margin-bottom: 3rem;
  }
  .mgn-lft84 {
    margin-left: 3rem;
  }
  .mgn85 {
    margin: 3.0357142857rem;
  }
  .mgn-top85 {
    margin-top: 3.0357142857rem;
  }
  .mgn-rgt85 {
    margin-right: 3.0357142857rem;
  }
  .mgn-btm85 {
    margin-bottom: 3.0357142857rem;
  }
  .mgn-lft85 {
    margin-left: 3.0357142857rem;
  }
  .mgn86 {
    margin: 3.0714285714rem;
  }
  .mgn-top86 {
    margin-top: 3.0714285714rem;
  }
  .mgn-rgt86 {
    margin-right: 3.0714285714rem;
  }
  .mgn-btm86 {
    margin-bottom: 3.0714285714rem;
  }
  .mgn-lft86 {
    margin-left: 3.0714285714rem;
  }
  .mgn87 {
    margin: 3.1071428571rem;
  }
  .mgn-top87 {
    margin-top: 3.1071428571rem;
  }
  .mgn-rgt87 {
    margin-right: 3.1071428571rem;
  }
  .mgn-btm87 {
    margin-bottom: 3.1071428571rem;
  }
  .mgn-lft87 {
    margin-left: 3.1071428571rem;
  }
  .mgn88 {
    margin: 3.1428571429rem;
  }
  .mgn-top88 {
    margin-top: 3.1428571429rem;
  }
  .mgn-rgt88 {
    margin-right: 3.1428571429rem;
  }
  .mgn-btm88 {
    margin-bottom: 3.1428571429rem;
  }
  .mgn-lft88 {
    margin-left: 3.1428571429rem;
  }
  .mgn89 {
    margin: 3.1785714286rem;
  }
  .mgn-top89 {
    margin-top: 3.1785714286rem;
  }
  .mgn-rgt89 {
    margin-right: 3.1785714286rem;
  }
  .mgn-btm89 {
    margin-bottom: 3.1785714286rem;
  }
  .mgn-lft89 {
    margin-left: 3.1785714286rem;
  }
  .mgn90 {
    margin: 3.2142857143rem;
  }
  .mgn-top90 {
    margin-top: 3.2142857143rem;
  }
  .mgn-rgt90 {
    margin-right: 3.2142857143rem;
  }
  .mgn-btm90 {
    margin-bottom: 3.2142857143rem;
  }
  .mgn-lft90 {
    margin-left: 3.2142857143rem;
  }
  .mgn91 {
    margin: 3.25rem;
  }
  .mgn-top91 {
    margin-top: 3.25rem;
  }
  .mgn-rgt91 {
    margin-right: 3.25rem;
  }
  .mgn-btm91 {
    margin-bottom: 3.25rem;
  }
  .mgn-lft91 {
    margin-left: 3.25rem;
  }
  .mgn92 {
    margin: 3.2857142857rem;
  }
  .mgn-top92 {
    margin-top: 3.2857142857rem;
  }
  .mgn-rgt92 {
    margin-right: 3.2857142857rem;
  }
  .mgn-btm92 {
    margin-bottom: 3.2857142857rem;
  }
  .mgn-lft92 {
    margin-left: 3.2857142857rem;
  }
  .mgn93 {
    margin: 3.3214285714rem;
  }
  .mgn-top93 {
    margin-top: 3.3214285714rem;
  }
  .mgn-rgt93 {
    margin-right: 3.3214285714rem;
  }
  .mgn-btm93 {
    margin-bottom: 3.3214285714rem;
  }
  .mgn-lft93 {
    margin-left: 3.3214285714rem;
  }
  .mgn94 {
    margin: 3.3571428571rem;
  }
  .mgn-top94 {
    margin-top: 3.3571428571rem;
  }
  .mgn-rgt94 {
    margin-right: 3.3571428571rem;
  }
  .mgn-btm94 {
    margin-bottom: 3.3571428571rem;
  }
  .mgn-lft94 {
    margin-left: 3.3571428571rem;
  }
  .mgn95 {
    margin: 3.3928571429rem;
  }
  .mgn-top95 {
    margin-top: 3.3928571429rem;
  }
  .mgn-rgt95 {
    margin-right: 3.3928571429rem;
  }
  .mgn-btm95 {
    margin-bottom: 3.3928571429rem;
  }
  .mgn-lft95 {
    margin-left: 3.3928571429rem;
  }
  .mgn96 {
    margin: 3.4285714286rem;
  }
  .mgn-top96 {
    margin-top: 3.4285714286rem;
  }
  .mgn-rgt96 {
    margin-right: 3.4285714286rem;
  }
  .mgn-btm96 {
    margin-bottom: 3.4285714286rem;
  }
  .mgn-lft96 {
    margin-left: 3.4285714286rem;
  }
  .mgn97 {
    margin: 3.4642857143rem;
  }
  .mgn-top97 {
    margin-top: 3.4642857143rem;
  }
  .mgn-rgt97 {
    margin-right: 3.4642857143rem;
  }
  .mgn-btm97 {
    margin-bottom: 3.4642857143rem;
  }
  .mgn-lft97 {
    margin-left: 3.4642857143rem;
  }
  .mgn98 {
    margin: 3.5rem;
  }
  .mgn-top98 {
    margin-top: 3.5rem;
  }
  .mgn-rgt98 {
    margin-right: 3.5rem;
  }
  .mgn-btm98 {
    margin-bottom: 3.5rem;
  }
  .mgn-lft98 {
    margin-left: 3.5rem;
  }
  .mgn99 {
    margin: 3.5357142857rem;
  }
  .mgn-top99 {
    margin-top: 3.5357142857rem;
  }
  .mgn-rgt99 {
    margin-right: 3.5357142857rem;
  }
  .mgn-btm99 {
    margin-bottom: 3.5357142857rem;
  }
  .mgn-lft99 {
    margin-left: 3.5357142857rem;
  }
  .mgn100 {
    margin: 3.5714285714rem;
  }
  .mgn-top100 {
    margin-top: 3.5714285714rem;
  }
  .mgn-rgt100 {
    margin-right: 3.5714285714rem;
  }
  .mgn-btm100 {
    margin-bottom: 3.5714285714rem;
  }
  .mgn-lft100 {
    margin-left: 3.5714285714rem;
  }
  .mgn101 {
    margin: 3.6071428571rem;
  }
  .mgn-top101 {
    margin-top: 3.6071428571rem;
  }
  .mgn-rgt101 {
    margin-right: 3.6071428571rem;
  }
  .mgn-btm101 {
    margin-bottom: 3.6071428571rem;
  }
  .mgn-lft101 {
    margin-left: 3.6071428571rem;
  }
  .mgn102 {
    margin: 3.6428571429rem;
  }
  .mgn-top102 {
    margin-top: 3.6428571429rem;
  }
  .mgn-rgt102 {
    margin-right: 3.6428571429rem;
  }
  .mgn-btm102 {
    margin-bottom: 3.6428571429rem;
  }
  .mgn-lft102 {
    margin-left: 3.6428571429rem;
  }
  .mgn103 {
    margin: 3.6785714286rem;
  }
  .mgn-top103 {
    margin-top: 3.6785714286rem;
  }
  .mgn-rgt103 {
    margin-right: 3.6785714286rem;
  }
  .mgn-btm103 {
    margin-bottom: 3.6785714286rem;
  }
  .mgn-lft103 {
    margin-left: 3.6785714286rem;
  }
  .mgn104 {
    margin: 3.7142857143rem;
  }
  .mgn-top104 {
    margin-top: 3.7142857143rem;
  }
  .mgn-rgt104 {
    margin-right: 3.7142857143rem;
  }
  .mgn-btm104 {
    margin-bottom: 3.7142857143rem;
  }
  .mgn-lft104 {
    margin-left: 3.7142857143rem;
  }
  .mgn105 {
    margin: 3.75rem;
  }
  .mgn-top105 {
    margin-top: 3.75rem;
  }
  .mgn-rgt105 {
    margin-right: 3.75rem;
  }
  .mgn-btm105 {
    margin-bottom: 3.75rem;
  }
  .mgn-lft105 {
    margin-left: 3.75rem;
  }
  .mgn106 {
    margin: 3.7857142857rem;
  }
  .mgn-top106 {
    margin-top: 3.7857142857rem;
  }
  .mgn-rgt106 {
    margin-right: 3.7857142857rem;
  }
  .mgn-btm106 {
    margin-bottom: 3.7857142857rem;
  }
  .mgn-lft106 {
    margin-left: 3.7857142857rem;
  }
  .mgn107 {
    margin: 3.8214285714rem;
  }
  .mgn-top107 {
    margin-top: 3.8214285714rem;
  }
  .mgn-rgt107 {
    margin-right: 3.8214285714rem;
  }
  .mgn-btm107 {
    margin-bottom: 3.8214285714rem;
  }
  .mgn-lft107 {
    margin-left: 3.8214285714rem;
  }
  .mgn108 {
    margin: 3.8571428571rem;
  }
  .mgn-top108 {
    margin-top: 3.8571428571rem;
  }
  .mgn-rgt108 {
    margin-right: 3.8571428571rem;
  }
  .mgn-btm108 {
    margin-bottom: 3.8571428571rem;
  }
  .mgn-lft108 {
    margin-left: 3.8571428571rem;
  }
  .mgn109 {
    margin: 3.8928571429rem;
  }
  .mgn-top109 {
    margin-top: 3.8928571429rem;
  }
  .mgn-rgt109 {
    margin-right: 3.8928571429rem;
  }
  .mgn-btm109 {
    margin-bottom: 3.8928571429rem;
  }
  .mgn-lft109 {
    margin-left: 3.8928571429rem;
  }
  .mgn110 {
    margin: 3.9285714286rem;
  }
  .mgn-top110 {
    margin-top: 3.9285714286rem;
  }
  .mgn-rgt110 {
    margin-right: 3.9285714286rem;
  }
  .mgn-btm110 {
    margin-bottom: 3.9285714286rem;
  }
  .mgn-lft110 {
    margin-left: 3.9285714286rem;
  }
  .mgn111 {
    margin: 3.9642857143rem;
  }
  .mgn-top111 {
    margin-top: 3.9642857143rem;
  }
  .mgn-rgt111 {
    margin-right: 3.9642857143rem;
  }
  .mgn-btm111 {
    margin-bottom: 3.9642857143rem;
  }
  .mgn-lft111 {
    margin-left: 3.9642857143rem;
  }
  .mgn112 {
    margin: 4rem;
  }
  .mgn-top112 {
    margin-top: 4rem;
  }
  .mgn-rgt112 {
    margin-right: 4rem;
  }
  .mgn-btm112 {
    margin-bottom: 4rem;
  }
  .mgn-lft112 {
    margin-left: 4rem;
  }
  .mgn113 {
    margin: 4.0357142857rem;
  }
  .mgn-top113 {
    margin-top: 4.0357142857rem;
  }
  .mgn-rgt113 {
    margin-right: 4.0357142857rem;
  }
  .mgn-btm113 {
    margin-bottom: 4.0357142857rem;
  }
  .mgn-lft113 {
    margin-left: 4.0357142857rem;
  }
  .mgn114 {
    margin: 4.0714285714rem;
  }
  .mgn-top114 {
    margin-top: 4.0714285714rem;
  }
  .mgn-rgt114 {
    margin-right: 4.0714285714rem;
  }
  .mgn-btm114 {
    margin-bottom: 4.0714285714rem;
  }
  .mgn-lft114 {
    margin-left: 4.0714285714rem;
  }
  .mgn115 {
    margin: 4.1071428571rem;
  }
  .mgn-top115 {
    margin-top: 4.1071428571rem;
  }
  .mgn-rgt115 {
    margin-right: 4.1071428571rem;
  }
  .mgn-btm115 {
    margin-bottom: 4.1071428571rem;
  }
  .mgn-lft115 {
    margin-left: 4.1071428571rem;
  }
  .mgn116 {
    margin: 4.1428571429rem;
  }
  .mgn-top116 {
    margin-top: 4.1428571429rem;
  }
  .mgn-rgt116 {
    margin-right: 4.1428571429rem;
  }
  .mgn-btm116 {
    margin-bottom: 4.1428571429rem;
  }
  .mgn-lft116 {
    margin-left: 4.1428571429rem;
  }
  .mgn117 {
    margin: 4.1785714286rem;
  }
  .mgn-top117 {
    margin-top: 4.1785714286rem;
  }
  .mgn-rgt117 {
    margin-right: 4.1785714286rem;
  }
  .mgn-btm117 {
    margin-bottom: 4.1785714286rem;
  }
  .mgn-lft117 {
    margin-left: 4.1785714286rem;
  }
  .mgn118 {
    margin: 4.2142857143rem;
  }
  .mgn-top118 {
    margin-top: 4.2142857143rem;
  }
  .mgn-rgt118 {
    margin-right: 4.2142857143rem;
  }
  .mgn-btm118 {
    margin-bottom: 4.2142857143rem;
  }
  .mgn-lft118 {
    margin-left: 4.2142857143rem;
  }
  .mgn119 {
    margin: 4.25rem;
  }
  .mgn-top119 {
    margin-top: 4.25rem;
  }
  .mgn-rgt119 {
    margin-right: 4.25rem;
  }
  .mgn-btm119 {
    margin-bottom: 4.25rem;
  }
  .mgn-lft119 {
    margin-left: 4.25rem;
  }
  .mgn120 {
    margin: 4.2857142857rem;
  }
  .mgn-top120 {
    margin-top: 4.2857142857rem;
  }
  .mgn-rgt120 {
    margin-right: 4.2857142857rem;
  }
  .mgn-btm120 {
    margin-bottom: 4.2857142857rem;
  }
  .mgn-lft120 {
    margin-left: 4.2857142857rem;
  }
  .mgn121 {
    margin: 4.3214285714rem;
  }
  .mgn-top121 {
    margin-top: 4.3214285714rem;
  }
  .mgn-rgt121 {
    margin-right: 4.3214285714rem;
  }
  .mgn-btm121 {
    margin-bottom: 4.3214285714rem;
  }
  .mgn-lft121 {
    margin-left: 4.3214285714rem;
  }
  .mgn122 {
    margin: 4.3571428571rem;
  }
  .mgn-top122 {
    margin-top: 4.3571428571rem;
  }
  .mgn-rgt122 {
    margin-right: 4.3571428571rem;
  }
  .mgn-btm122 {
    margin-bottom: 4.3571428571rem;
  }
  .mgn-lft122 {
    margin-left: 4.3571428571rem;
  }
  .mgn123 {
    margin: 4.3928571429rem;
  }
  .mgn-top123 {
    margin-top: 4.3928571429rem;
  }
  .mgn-rgt123 {
    margin-right: 4.3928571429rem;
  }
  .mgn-btm123 {
    margin-bottom: 4.3928571429rem;
  }
  .mgn-lft123 {
    margin-left: 4.3928571429rem;
  }
  .mgn124 {
    margin: 4.4285714286rem;
  }
  .mgn-top124 {
    margin-top: 4.4285714286rem;
  }
  .mgn-rgt124 {
    margin-right: 4.4285714286rem;
  }
  .mgn-btm124 {
    margin-bottom: 4.4285714286rem;
  }
  .mgn-lft124 {
    margin-left: 4.4285714286rem;
  }
  .mgn125 {
    margin: 4.4642857143rem;
  }
  .mgn-top125 {
    margin-top: 4.4642857143rem;
  }
  .mgn-rgt125 {
    margin-right: 4.4642857143rem;
  }
  .mgn-btm125 {
    margin-bottom: 4.4642857143rem;
  }
  .mgn-lft125 {
    margin-left: 4.4642857143rem;
  }
  .mgn126 {
    margin: 4.5rem;
  }
  .mgn-top126 {
    margin-top: 4.5rem;
  }
  .mgn-rgt126 {
    margin-right: 4.5rem;
  }
  .mgn-btm126 {
    margin-bottom: 4.5rem;
  }
  .mgn-lft126 {
    margin-left: 4.5rem;
  }
  .mgn127 {
    margin: 4.5357142857rem;
  }
  .mgn-top127 {
    margin-top: 4.5357142857rem;
  }
  .mgn-rgt127 {
    margin-right: 4.5357142857rem;
  }
  .mgn-btm127 {
    margin-bottom: 4.5357142857rem;
  }
  .mgn-lft127 {
    margin-left: 4.5357142857rem;
  }
  .mgn128 {
    margin: 4.5714285714rem;
  }
  .mgn-top128 {
    margin-top: 4.5714285714rem;
  }
  .mgn-rgt128 {
    margin-right: 4.5714285714rem;
  }
  .mgn-btm128 {
    margin-bottom: 4.5714285714rem;
  }
  .mgn-lft128 {
    margin-left: 4.5714285714rem;
  }
  .mgn129 {
    margin: 4.6071428571rem;
  }
  .mgn-top129 {
    margin-top: 4.6071428571rem;
  }
  .mgn-rgt129 {
    margin-right: 4.6071428571rem;
  }
  .mgn-btm129 {
    margin-bottom: 4.6071428571rem;
  }
  .mgn-lft129 {
    margin-left: 4.6071428571rem;
  }
  .mgn130 {
    margin: 4.6428571429rem;
  }
  .mgn-top130 {
    margin-top: 4.6428571429rem;
  }
  .mgn-rgt130 {
    margin-right: 4.6428571429rem;
  }
  .mgn-btm130 {
    margin-bottom: 4.6428571429rem;
  }
  .mgn-lft130 {
    margin-left: 4.6428571429rem;
  }
  .mgn131 {
    margin: 4.6785714286rem;
  }
  .mgn-top131 {
    margin-top: 4.6785714286rem;
  }
  .mgn-rgt131 {
    margin-right: 4.6785714286rem;
  }
  .mgn-btm131 {
    margin-bottom: 4.6785714286rem;
  }
  .mgn-lft131 {
    margin-left: 4.6785714286rem;
  }
  .mgn132 {
    margin: 4.7142857143rem;
  }
  .mgn-top132 {
    margin-top: 4.7142857143rem;
  }
  .mgn-rgt132 {
    margin-right: 4.7142857143rem;
  }
  .mgn-btm132 {
    margin-bottom: 4.7142857143rem;
  }
  .mgn-lft132 {
    margin-left: 4.7142857143rem;
  }
  .mgn133 {
    margin: 4.75rem;
  }
  .mgn-top133 {
    margin-top: 4.75rem;
  }
  .mgn-rgt133 {
    margin-right: 4.75rem;
  }
  .mgn-btm133 {
    margin-bottom: 4.75rem;
  }
  .mgn-lft133 {
    margin-left: 4.75rem;
  }
  .mgn134 {
    margin: 4.7857142857rem;
  }
  .mgn-top134 {
    margin-top: 4.7857142857rem;
  }
  .mgn-rgt134 {
    margin-right: 4.7857142857rem;
  }
  .mgn-btm134 {
    margin-bottom: 4.7857142857rem;
  }
  .mgn-lft134 {
    margin-left: 4.7857142857rem;
  }
  .mgn135 {
    margin: 4.8214285714rem;
  }
  .mgn-top135 {
    margin-top: 4.8214285714rem;
  }
  .mgn-rgt135 {
    margin-right: 4.8214285714rem;
  }
  .mgn-btm135 {
    margin-bottom: 4.8214285714rem;
  }
  .mgn-lft135 {
    margin-left: 4.8214285714rem;
  }
  .mgn136 {
    margin: 4.8571428571rem;
  }
  .mgn-top136 {
    margin-top: 4.8571428571rem;
  }
  .mgn-rgt136 {
    margin-right: 4.8571428571rem;
  }
  .mgn-btm136 {
    margin-bottom: 4.8571428571rem;
  }
  .mgn-lft136 {
    margin-left: 4.8571428571rem;
  }
  .mgn137 {
    margin: 4.8928571429rem;
  }
  .mgn-top137 {
    margin-top: 4.8928571429rem;
  }
  .mgn-rgt137 {
    margin-right: 4.8928571429rem;
  }
  .mgn-btm137 {
    margin-bottom: 4.8928571429rem;
  }
  .mgn-lft137 {
    margin-left: 4.8928571429rem;
  }
  .mgn138 {
    margin: 4.9285714286rem;
  }
  .mgn-top138 {
    margin-top: 4.9285714286rem;
  }
  .mgn-rgt138 {
    margin-right: 4.9285714286rem;
  }
  .mgn-btm138 {
    margin-bottom: 4.9285714286rem;
  }
  .mgn-lft138 {
    margin-left: 4.9285714286rem;
  }
  .mgn139 {
    margin: 4.9642857143rem;
  }
  .mgn-top139 {
    margin-top: 4.9642857143rem;
  }
  .mgn-rgt139 {
    margin-right: 4.9642857143rem;
  }
  .mgn-btm139 {
    margin-bottom: 4.9642857143rem;
  }
  .mgn-lft139 {
    margin-left: 4.9642857143rem;
  }
  .mgn140 {
    margin: 5rem;
  }
  .mgn-top140 {
    margin-top: 5rem;
  }
  .mgn-rgt140 {
    margin-right: 5rem;
  }
  .mgn-btm140 {
    margin-bottom: 5rem;
  }
  .mgn-lft140 {
    margin-left: 5rem;
  }
  .mgn141 {
    margin: 5.0357142857rem;
  }
  .mgn-top141 {
    margin-top: 5.0357142857rem;
  }
  .mgn-rgt141 {
    margin-right: 5.0357142857rem;
  }
  .mgn-btm141 {
    margin-bottom: 5.0357142857rem;
  }
  .mgn-lft141 {
    margin-left: 5.0357142857rem;
  }
  .mgn142 {
    margin: 5.0714285714rem;
  }
  .mgn-top142 {
    margin-top: 5.0714285714rem;
  }
  .mgn-rgt142 {
    margin-right: 5.0714285714rem;
  }
  .mgn-btm142 {
    margin-bottom: 5.0714285714rem;
  }
  .mgn-lft142 {
    margin-left: 5.0714285714rem;
  }
  .mgn143 {
    margin: 5.1071428571rem;
  }
  .mgn-top143 {
    margin-top: 5.1071428571rem;
  }
  .mgn-rgt143 {
    margin-right: 5.1071428571rem;
  }
  .mgn-btm143 {
    margin-bottom: 5.1071428571rem;
  }
  .mgn-lft143 {
    margin-left: 5.1071428571rem;
  }
  .mgn144 {
    margin: 5.1428571429rem;
  }
  .mgn-top144 {
    margin-top: 5.1428571429rem;
  }
  .mgn-rgt144 {
    margin-right: 5.1428571429rem;
  }
  .mgn-btm144 {
    margin-bottom: 5.1428571429rem;
  }
  .mgn-lft144 {
    margin-left: 5.1428571429rem;
  }
  .mgn145 {
    margin: 5.1785714286rem;
  }
  .mgn-top145 {
    margin-top: 5.1785714286rem;
  }
  .mgn-rgt145 {
    margin-right: 5.1785714286rem;
  }
  .mgn-btm145 {
    margin-bottom: 5.1785714286rem;
  }
  .mgn-lft145 {
    margin-left: 5.1785714286rem;
  }
  .mgn146 {
    margin: 5.2142857143rem;
  }
  .mgn-top146 {
    margin-top: 5.2142857143rem;
  }
  .mgn-rgt146 {
    margin-right: 5.2142857143rem;
  }
  .mgn-btm146 {
    margin-bottom: 5.2142857143rem;
  }
  .mgn-lft146 {
    margin-left: 5.2142857143rem;
  }
  .mgn147 {
    margin: 5.25rem;
  }
  .mgn-top147 {
    margin-top: 5.25rem;
  }
  .mgn-rgt147 {
    margin-right: 5.25rem;
  }
  .mgn-btm147 {
    margin-bottom: 5.25rem;
  }
  .mgn-lft147 {
    margin-left: 5.25rem;
  }
  .mgn148 {
    margin: 5.2857142857rem;
  }
  .mgn-top148 {
    margin-top: 5.2857142857rem;
  }
  .mgn-rgt148 {
    margin-right: 5.2857142857rem;
  }
  .mgn-btm148 {
    margin-bottom: 5.2857142857rem;
  }
  .mgn-lft148 {
    margin-left: 5.2857142857rem;
  }
  .mgn149 {
    margin: 5.3214285714rem;
  }
  .mgn-top149 {
    margin-top: 5.3214285714rem;
  }
  .mgn-rgt149 {
    margin-right: 5.3214285714rem;
  }
  .mgn-btm149 {
    margin-bottom: 5.3214285714rem;
  }
  .mgn-lft149 {
    margin-left: 5.3214285714rem;
  }
  .mgn150 {
    margin: 5.3571428571rem;
  }
  .mgn-top150 {
    margin-top: 5.3571428571rem;
  }
  .mgn-rgt150 {
    margin-right: 5.3571428571rem;
  }
  .mgn-btm150 {
    margin-bottom: 5.3571428571rem;
  }
  .mgn-lft150 {
    margin-left: 5.3571428571rem;
  }
}
.mgn0em {
  margin: 0em;
}

.mgn-top0em {
  margin-top: 0em;
}

.mgn-rgt0em {
  margin-right: 0em;
}

.mgn-btm0em {
  margin-bottom: 0em;
}

.mgn-lft0em {
  margin-left: 0em;
}

.mgn1em {
  margin: 1em;
}

.mgn-top1em {
  margin-top: 1em;
}

.mgn-rgt1em {
  margin-right: 1em;
}

.mgn-btm1em {
  margin-bottom: 1em;
}

.mgn-lft1em {
  margin-left: 1em;
}

.mgn2em {
  margin: 2em;
}

.mgn-top2em {
  margin-top: 2em;
}

.mgn-rgt2em {
  margin-right: 2em;
}

.mgn-btm2em {
  margin-bottom: 2em;
}

.mgn-lft2em {
  margin-left: 2em;
}

.mgn3em {
  margin: 3em;
}

.mgn-top3em {
  margin-top: 3em;
}

.mgn-rgt3em {
  margin-right: 3em;
}

.mgn-btm3em {
  margin-bottom: 3em;
}

.mgn-lft3em {
  margin-left: 3em;
}

.mgn4em {
  margin: 4em;
}

.mgn-top4em {
  margin-top: 4em;
}

.mgn-rgt4em {
  margin-right: 4em;
}

.mgn-btm4em {
  margin-bottom: 4em;
}

.mgn-lft4em {
  margin-left: 4em;
}

.mgn5em {
  margin: 5em;
}

.mgn-top5em {
  margin-top: 5em;
}

.mgn-rgt5em {
  margin-right: 5em;
}

.mgn-btm5em {
  margin-bottom: 5em;
}

.mgn-lft5em {
  margin-left: 5em;
}

.mgn6em {
  margin: 6em;
}

.mgn-top6em {
  margin-top: 6em;
}

.mgn-rgt6em {
  margin-right: 6em;
}

.mgn-btm6em {
  margin-bottom: 6em;
}

.mgn-lft6em {
  margin-left: 6em;
}

.mgn7em {
  margin: 7em;
}

.mgn-top7em {
  margin-top: 7em;
}

.mgn-rgt7em {
  margin-right: 7em;
}

.mgn-btm7em {
  margin-bottom: 7em;
}

.mgn-lft7em {
  margin-left: 7em;
}

.mgn8em {
  margin: 8em;
}

.mgn-top8em {
  margin-top: 8em;
}

.mgn-rgt8em {
  margin-right: 8em;
}

.mgn-btm8em {
  margin-bottom: 8em;
}

.mgn-lft8em {
  margin-left: 8em;
}

.mgn9em {
  margin: 9em;
}

.mgn-top9em {
  margin-top: 9em;
}

.mgn-rgt9em {
  margin-right: 9em;
}

.mgn-btm9em {
  margin-bottom: 9em;
}

.mgn-lft9em {
  margin-left: 9em;
}

.mgn10em {
  margin: 10em;
}

.mgn-top10em {
  margin-top: 10em;
}

.mgn-rgt10em {
  margin-right: 10em;
}

.mgn-btm10em {
  margin-bottom: 10em;
}

.mgn-lft10em {
  margin-left: 10em;
}

.mgn11em {
  margin: 11em;
}

.mgn-top11em {
  margin-top: 11em;
}

.mgn-rgt11em {
  margin-right: 11em;
}

.mgn-btm11em {
  margin-bottom: 11em;
}

.mgn-lft11em {
  margin-left: 11em;
}

.mgn12em {
  margin: 12em;
}

.mgn-top12em {
  margin-top: 12em;
}

.mgn-rgt12em {
  margin-right: 12em;
}

.mgn-btm12em {
  margin-bottom: 12em;
}

.mgn-lft12em {
  margin-left: 12em;
}

.mgn13em {
  margin: 13em;
}

.mgn-top13em {
  margin-top: 13em;
}

.mgn-rgt13em {
  margin-right: 13em;
}

.mgn-btm13em {
  margin-bottom: 13em;
}

.mgn-lft13em {
  margin-left: 13em;
}

.mgn14em {
  margin: 14em;
}

.mgn-top14em {
  margin-top: 14em;
}

.mgn-rgt14em {
  margin-right: 14em;
}

.mgn-btm14em {
  margin-bottom: 14em;
}

.mgn-lft14em {
  margin-left: 14em;
}

.mgn15em {
  margin: 15em;
}

.mgn-top15em {
  margin-top: 15em;
}

.mgn-rgt15em {
  margin-right: 15em;
}

.mgn-btm15em {
  margin-bottom: 15em;
}

.mgn-lft15em {
  margin-left: 15em;
}

.pdg0 {
  padding: 0rem;
}

.pdg-top0 {
  padding-top: 0rem;
}

.pdg-rgt0 {
  padding-right: 0rem;
}

.pdg-btm0 {
  padding-bottom: 0rem;
}

.pdg-lft0 {
  padding-left: 0rem;
}

.pdg1 {
  padding: 0.0625rem;
}

.pdg-top1 {
  padding-top: 0.0625rem;
}

.pdg-rgt1 {
  padding-right: 0.0625rem;
}

.pdg-btm1 {
  padding-bottom: 0.0625rem;
}

.pdg-lft1 {
  padding-left: 0.0625rem;
}

.pdg2 {
  padding: 0.125rem;
}

.pdg-top2 {
  padding-top: 0.125rem;
}

.pdg-rgt2 {
  padding-right: 0.125rem;
}

.pdg-btm2 {
  padding-bottom: 0.125rem;
}

.pdg-lft2 {
  padding-left: 0.125rem;
}

.pdg3 {
  padding: 0.1875rem;
}

.pdg-top3 {
  padding-top: 0.1875rem;
}

.pdg-rgt3 {
  padding-right: 0.1875rem;
}

.pdg-btm3 {
  padding-bottom: 0.1875rem;
}

.pdg-lft3 {
  padding-left: 0.1875rem;
}

.pdg4 {
  padding: 0.25rem;
}

.pdg-top4 {
  padding-top: 0.25rem;
}

.pdg-rgt4 {
  padding-right: 0.25rem;
}

.pdg-btm4 {
  padding-bottom: 0.25rem;
}

.pdg-lft4 {
  padding-left: 0.25rem;
}

.pdg5 {
  padding: 0.3125rem;
}

.pdg-top5 {
  padding-top: 0.3125rem;
}

.pdg-rgt5 {
  padding-right: 0.3125rem;
}

.pdg-btm5 {
  padding-bottom: 0.3125rem;
}

.pdg-lft5 {
  padding-left: 0.3125rem;
}

.pdg6 {
  padding: 0.375rem;
}

.pdg-top6 {
  padding-top: 0.375rem;
}

.pdg-rgt6 {
  padding-right: 0.375rem;
}

.pdg-btm6 {
  padding-bottom: 0.375rem;
}

.pdg-lft6 {
  padding-left: 0.375rem;
}

.pdg7 {
  padding: 0.4375rem;
}

.pdg-top7 {
  padding-top: 0.4375rem;
}

.pdg-rgt7 {
  padding-right: 0.4375rem;
}

.pdg-btm7 {
  padding-bottom: 0.4375rem;
}

.pdg-lft7 {
  padding-left: 0.4375rem;
}

.pdg8 {
  padding: 0.5rem;
}

.pdg-top8 {
  padding-top: 0.5rem;
}

.pdg-rgt8 {
  padding-right: 0.5rem;
}

.pdg-btm8 {
  padding-bottom: 0.5rem;
}

.pdg-lft8 {
  padding-left: 0.5rem;
}

.pdg9 {
  padding: 0.5625rem;
}

.pdg-top9 {
  padding-top: 0.5625rem;
}

.pdg-rgt9 {
  padding-right: 0.5625rem;
}

.pdg-btm9 {
  padding-bottom: 0.5625rem;
}

.pdg-lft9 {
  padding-left: 0.5625rem;
}

.pdg10 {
  padding: 0.625rem;
}

.pdg-top10 {
  padding-top: 0.625rem;
}

.pdg-rgt10 {
  padding-right: 0.625rem;
}

.pdg-btm10 {
  padding-bottom: 0.625rem;
}

.pdg-lft10 {
  padding-left: 0.625rem;
}

.pdg11 {
  padding: 0.6875rem;
}

.pdg-top11 {
  padding-top: 0.6875rem;
}

.pdg-rgt11 {
  padding-right: 0.6875rem;
}

.pdg-btm11 {
  padding-bottom: 0.6875rem;
}

.pdg-lft11 {
  padding-left: 0.6875rem;
}

.pdg12 {
  padding: 0.75rem;
}

.pdg-top12 {
  padding-top: 0.75rem;
}

.pdg-rgt12 {
  padding-right: 0.75rem;
}

.pdg-btm12 {
  padding-bottom: 0.75rem;
}

.pdg-lft12 {
  padding-left: 0.75rem;
}

.pdg13 {
  padding: 0.8125rem;
}

.pdg-top13 {
  padding-top: 0.8125rem;
}

.pdg-rgt13 {
  padding-right: 0.8125rem;
}

.pdg-btm13 {
  padding-bottom: 0.8125rem;
}

.pdg-lft13 {
  padding-left: 0.8125rem;
}

.pdg14 {
  padding: 0.875rem;
}

.pdg-top14 {
  padding-top: 0.875rem;
}

.pdg-rgt14 {
  padding-right: 0.875rem;
}

.pdg-btm14 {
  padding-bottom: 0.875rem;
}

.pdg-lft14 {
  padding-left: 0.875rem;
}

.pdg15 {
  padding: 0.9375rem;
}

.pdg-top15 {
  padding-top: 0.9375rem;
}

.pdg-rgt15 {
  padding-right: 0.9375rem;
}

.pdg-btm15 {
  padding-bottom: 0.9375rem;
}

.pdg-lft15 {
  padding-left: 0.9375rem;
}

.pdg16 {
  padding: 1rem;
}

.pdg-top16 {
  padding-top: 1rem;
}

.pdg-rgt16 {
  padding-right: 1rem;
}

.pdg-btm16 {
  padding-bottom: 1rem;
}

.pdg-lft16 {
  padding-left: 1rem;
}

.pdg17 {
  padding: 1.0625rem;
}

.pdg-top17 {
  padding-top: 1.0625rem;
}

.pdg-rgt17 {
  padding-right: 1.0625rem;
}

.pdg-btm17 {
  padding-bottom: 1.0625rem;
}

.pdg-lft17 {
  padding-left: 1.0625rem;
}

.pdg18 {
  padding: 1.125rem;
}

.pdg-top18 {
  padding-top: 1.125rem;
}

.pdg-rgt18 {
  padding-right: 1.125rem;
}

.pdg-btm18 {
  padding-bottom: 1.125rem;
}

.pdg-lft18 {
  padding-left: 1.125rem;
}

.pdg19 {
  padding: 1.1875rem;
}

.pdg-top19 {
  padding-top: 1.1875rem;
}

.pdg-rgt19 {
  padding-right: 1.1875rem;
}

.pdg-btm19 {
  padding-bottom: 1.1875rem;
}

.pdg-lft19 {
  padding-left: 1.1875rem;
}

.pdg20 {
  padding: 1.25rem;
}

.pdg-top20 {
  padding-top: 1.25rem;
}

.pdg-rgt20 {
  padding-right: 1.25rem;
}

.pdg-btm20 {
  padding-bottom: 1.25rem;
}

.pdg-lft20 {
  padding-left: 1.25rem;
}

.pdg21 {
  padding: 1.3125rem;
}

.pdg-top21 {
  padding-top: 1.3125rem;
}

.pdg-rgt21 {
  padding-right: 1.3125rem;
}

.pdg-btm21 {
  padding-bottom: 1.3125rem;
}

.pdg-lft21 {
  padding-left: 1.3125rem;
}

.pdg22 {
  padding: 1.375rem;
}

.pdg-top22 {
  padding-top: 1.375rem;
}

.pdg-rgt22 {
  padding-right: 1.375rem;
}

.pdg-btm22 {
  padding-bottom: 1.375rem;
}

.pdg-lft22 {
  padding-left: 1.375rem;
}

.pdg23 {
  padding: 1.4375rem;
}

.pdg-top23 {
  padding-top: 1.4375rem;
}

.pdg-rgt23 {
  padding-right: 1.4375rem;
}

.pdg-btm23 {
  padding-bottom: 1.4375rem;
}

.pdg-lft23 {
  padding-left: 1.4375rem;
}

.pdg24 {
  padding: 1.5rem;
}

.pdg-top24 {
  padding-top: 1.5rem;
}

.pdg-rgt24 {
  padding-right: 1.5rem;
}

.pdg-btm24 {
  padding-bottom: 1.5rem;
}

.pdg-lft24 {
  padding-left: 1.5rem;
}

.pdg25 {
  padding: 1.5625rem;
}

.pdg-top25 {
  padding-top: 1.5625rem;
}

.pdg-rgt25 {
  padding-right: 1.5625rem;
}

.pdg-btm25 {
  padding-bottom: 1.5625rem;
}

.pdg-lft25 {
  padding-left: 1.5625rem;
}

.pdg26 {
  padding: 1.625rem;
}

.pdg-top26 {
  padding-top: 1.625rem;
}

.pdg-rgt26 {
  padding-right: 1.625rem;
}

.pdg-btm26 {
  padding-bottom: 1.625rem;
}

.pdg-lft26 {
  padding-left: 1.625rem;
}

.pdg27 {
  padding: 1.6875rem;
}

.pdg-top27 {
  padding-top: 1.6875rem;
}

.pdg-rgt27 {
  padding-right: 1.6875rem;
}

.pdg-btm27 {
  padding-bottom: 1.6875rem;
}

.pdg-lft27 {
  padding-left: 1.6875rem;
}

.pdg28 {
  padding: 1.75rem;
}

.pdg-top28 {
  padding-top: 1.75rem;
}

.pdg-rgt28 {
  padding-right: 1.75rem;
}

.pdg-btm28 {
  padding-bottom: 1.75rem;
}

.pdg-lft28 {
  padding-left: 1.75rem;
}

.pdg29 {
  padding: 1.8125rem;
}

.pdg-top29 {
  padding-top: 1.8125rem;
}

.pdg-rgt29 {
  padding-right: 1.8125rem;
}

.pdg-btm29 {
  padding-bottom: 1.8125rem;
}

.pdg-lft29 {
  padding-left: 1.8125rem;
}

.pdg30 {
  padding: 1.875rem;
}

.pdg-top30 {
  padding-top: 1.875rem;
}

.pdg-rgt30 {
  padding-right: 1.875rem;
}

.pdg-btm30 {
  padding-bottom: 1.875rem;
}

.pdg-lft30 {
  padding-left: 1.875rem;
}

.pdg31 {
  padding: 1.9375rem;
}

.pdg-top31 {
  padding-top: 1.9375rem;
}

.pdg-rgt31 {
  padding-right: 1.9375rem;
}

.pdg-btm31 {
  padding-bottom: 1.9375rem;
}

.pdg-lft31 {
  padding-left: 1.9375rem;
}

.pdg32 {
  padding: 2rem;
}

.pdg-top32 {
  padding-top: 2rem;
}

.pdg-rgt32 {
  padding-right: 2rem;
}

.pdg-btm32 {
  padding-bottom: 2rem;
}

.pdg-lft32 {
  padding-left: 2rem;
}

.pdg33 {
  padding: 2.0625rem;
}

.pdg-top33 {
  padding-top: 2.0625rem;
}

.pdg-rgt33 {
  padding-right: 2.0625rem;
}

.pdg-btm33 {
  padding-bottom: 2.0625rem;
}

.pdg-lft33 {
  padding-left: 2.0625rem;
}

.pdg34 {
  padding: 2.125rem;
}

.pdg-top34 {
  padding-top: 2.125rem;
}

.pdg-rgt34 {
  padding-right: 2.125rem;
}

.pdg-btm34 {
  padding-bottom: 2.125rem;
}

.pdg-lft34 {
  padding-left: 2.125rem;
}

.pdg35 {
  padding: 2.1875rem;
}

.pdg-top35 {
  padding-top: 2.1875rem;
}

.pdg-rgt35 {
  padding-right: 2.1875rem;
}

.pdg-btm35 {
  padding-bottom: 2.1875rem;
}

.pdg-lft35 {
  padding-left: 2.1875rem;
}

.pdg36 {
  padding: 2.25rem;
}

.pdg-top36 {
  padding-top: 2.25rem;
}

.pdg-rgt36 {
  padding-right: 2.25rem;
}

.pdg-btm36 {
  padding-bottom: 2.25rem;
}

.pdg-lft36 {
  padding-left: 2.25rem;
}

.pdg37 {
  padding: 2.3125rem;
}

.pdg-top37 {
  padding-top: 2.3125rem;
}

.pdg-rgt37 {
  padding-right: 2.3125rem;
}

.pdg-btm37 {
  padding-bottom: 2.3125rem;
}

.pdg-lft37 {
  padding-left: 2.3125rem;
}

.pdg38 {
  padding: 2.375rem;
}

.pdg-top38 {
  padding-top: 2.375rem;
}

.pdg-rgt38 {
  padding-right: 2.375rem;
}

.pdg-btm38 {
  padding-bottom: 2.375rem;
}

.pdg-lft38 {
  padding-left: 2.375rem;
}

.pdg39 {
  padding: 2.4375rem;
}

.pdg-top39 {
  padding-top: 2.4375rem;
}

.pdg-rgt39 {
  padding-right: 2.4375rem;
}

.pdg-btm39 {
  padding-bottom: 2.4375rem;
}

.pdg-lft39 {
  padding-left: 2.4375rem;
}

.pdg40 {
  padding: 2.5rem;
}

.pdg-top40 {
  padding-top: 2.5rem;
}

.pdg-rgt40 {
  padding-right: 2.5rem;
}

.pdg-btm40 {
  padding-bottom: 2.5rem;
}

.pdg-lft40 {
  padding-left: 2.5rem;
}

.pdg41 {
  padding: 2.5625rem;
}

.pdg-top41 {
  padding-top: 2.5625rem;
}

.pdg-rgt41 {
  padding-right: 2.5625rem;
}

.pdg-btm41 {
  padding-bottom: 2.5625rem;
}

.pdg-lft41 {
  padding-left: 2.5625rem;
}

.pdg42 {
  padding: 2.625rem;
}

.pdg-top42 {
  padding-top: 2.625rem;
}

.pdg-rgt42 {
  padding-right: 2.625rem;
}

.pdg-btm42 {
  padding-bottom: 2.625rem;
}

.pdg-lft42 {
  padding-left: 2.625rem;
}

.pdg43 {
  padding: 2.6875rem;
}

.pdg-top43 {
  padding-top: 2.6875rem;
}

.pdg-rgt43 {
  padding-right: 2.6875rem;
}

.pdg-btm43 {
  padding-bottom: 2.6875rem;
}

.pdg-lft43 {
  padding-left: 2.6875rem;
}

.pdg44 {
  padding: 2.75rem;
}

.pdg-top44 {
  padding-top: 2.75rem;
}

.pdg-rgt44 {
  padding-right: 2.75rem;
}

.pdg-btm44 {
  padding-bottom: 2.75rem;
}

.pdg-lft44 {
  padding-left: 2.75rem;
}

.pdg45 {
  padding: 2.8125rem;
}

.pdg-top45 {
  padding-top: 2.8125rem;
}

.pdg-rgt45 {
  padding-right: 2.8125rem;
}

.pdg-btm45 {
  padding-bottom: 2.8125rem;
}

.pdg-lft45 {
  padding-left: 2.8125rem;
}

.pdg46 {
  padding: 2.875rem;
}

.pdg-top46 {
  padding-top: 2.875rem;
}

.pdg-rgt46 {
  padding-right: 2.875rem;
}

.pdg-btm46 {
  padding-bottom: 2.875rem;
}

.pdg-lft46 {
  padding-left: 2.875rem;
}

.pdg47 {
  padding: 2.9375rem;
}

.pdg-top47 {
  padding-top: 2.9375rem;
}

.pdg-rgt47 {
  padding-right: 2.9375rem;
}

.pdg-btm47 {
  padding-bottom: 2.9375rem;
}

.pdg-lft47 {
  padding-left: 2.9375rem;
}

.pdg48 {
  padding: 3rem;
}

.pdg-top48 {
  padding-top: 3rem;
}

.pdg-rgt48 {
  padding-right: 3rem;
}

.pdg-btm48 {
  padding-bottom: 3rem;
}

.pdg-lft48 {
  padding-left: 3rem;
}

.pdg49 {
  padding: 3.0625rem;
}

.pdg-top49 {
  padding-top: 3.0625rem;
}

.pdg-rgt49 {
  padding-right: 3.0625rem;
}

.pdg-btm49 {
  padding-bottom: 3.0625rem;
}

.pdg-lft49 {
  padding-left: 3.0625rem;
}

.pdg50 {
  padding: 3.125rem;
}

.pdg-top50 {
  padding-top: 3.125rem;
}

.pdg-rgt50 {
  padding-right: 3.125rem;
}

.pdg-btm50 {
  padding-bottom: 3.125rem;
}

.pdg-lft50 {
  padding-left: 3.125rem;
}

.pdg51 {
  padding: 3.1875rem;
}

.pdg-top51 {
  padding-top: 3.1875rem;
}

.pdg-rgt51 {
  padding-right: 3.1875rem;
}

.pdg-btm51 {
  padding-bottom: 3.1875rem;
}

.pdg-lft51 {
  padding-left: 3.1875rem;
}

.pdg52 {
  padding: 3.25rem;
}

.pdg-top52 {
  padding-top: 3.25rem;
}

.pdg-rgt52 {
  padding-right: 3.25rem;
}

.pdg-btm52 {
  padding-bottom: 3.25rem;
}

.pdg-lft52 {
  padding-left: 3.25rem;
}

.pdg53 {
  padding: 3.3125rem;
}

.pdg-top53 {
  padding-top: 3.3125rem;
}

.pdg-rgt53 {
  padding-right: 3.3125rem;
}

.pdg-btm53 {
  padding-bottom: 3.3125rem;
}

.pdg-lft53 {
  padding-left: 3.3125rem;
}

.pdg54 {
  padding: 3.375rem;
}

.pdg-top54 {
  padding-top: 3.375rem;
}

.pdg-rgt54 {
  padding-right: 3.375rem;
}

.pdg-btm54 {
  padding-bottom: 3.375rem;
}

.pdg-lft54 {
  padding-left: 3.375rem;
}

.pdg55 {
  padding: 3.4375rem;
}

.pdg-top55 {
  padding-top: 3.4375rem;
}

.pdg-rgt55 {
  padding-right: 3.4375rem;
}

.pdg-btm55 {
  padding-bottom: 3.4375rem;
}

.pdg-lft55 {
  padding-left: 3.4375rem;
}

.pdg56 {
  padding: 3.5rem;
}

.pdg-top56 {
  padding-top: 3.5rem;
}

.pdg-rgt56 {
  padding-right: 3.5rem;
}

.pdg-btm56 {
  padding-bottom: 3.5rem;
}

.pdg-lft56 {
  padding-left: 3.5rem;
}

.pdg57 {
  padding: 3.5625rem;
}

.pdg-top57 {
  padding-top: 3.5625rem;
}

.pdg-rgt57 {
  padding-right: 3.5625rem;
}

.pdg-btm57 {
  padding-bottom: 3.5625rem;
}

.pdg-lft57 {
  padding-left: 3.5625rem;
}

.pdg58 {
  padding: 3.625rem;
}

.pdg-top58 {
  padding-top: 3.625rem;
}

.pdg-rgt58 {
  padding-right: 3.625rem;
}

.pdg-btm58 {
  padding-bottom: 3.625rem;
}

.pdg-lft58 {
  padding-left: 3.625rem;
}

.pdg59 {
  padding: 3.6875rem;
}

.pdg-top59 {
  padding-top: 3.6875rem;
}

.pdg-rgt59 {
  padding-right: 3.6875rem;
}

.pdg-btm59 {
  padding-bottom: 3.6875rem;
}

.pdg-lft59 {
  padding-left: 3.6875rem;
}

.pdg60 {
  padding: 3.75rem;
}

.pdg-top60 {
  padding-top: 3.75rem;
}

.pdg-rgt60 {
  padding-right: 3.75rem;
}

.pdg-btm60 {
  padding-bottom: 3.75rem;
}

.pdg-lft60 {
  padding-left: 3.75rem;
}

.pdg61 {
  padding: 3.8125rem;
}

.pdg-top61 {
  padding-top: 3.8125rem;
}

.pdg-rgt61 {
  padding-right: 3.8125rem;
}

.pdg-btm61 {
  padding-bottom: 3.8125rem;
}

.pdg-lft61 {
  padding-left: 3.8125rem;
}

.pdg62 {
  padding: 3.875rem;
}

.pdg-top62 {
  padding-top: 3.875rem;
}

.pdg-rgt62 {
  padding-right: 3.875rem;
}

.pdg-btm62 {
  padding-bottom: 3.875rem;
}

.pdg-lft62 {
  padding-left: 3.875rem;
}

.pdg63 {
  padding: 3.9375rem;
}

.pdg-top63 {
  padding-top: 3.9375rem;
}

.pdg-rgt63 {
  padding-right: 3.9375rem;
}

.pdg-btm63 {
  padding-bottom: 3.9375rem;
}

.pdg-lft63 {
  padding-left: 3.9375rem;
}

.pdg64 {
  padding: 4rem;
}

.pdg-top64 {
  padding-top: 4rem;
}

.pdg-rgt64 {
  padding-right: 4rem;
}

.pdg-btm64 {
  padding-bottom: 4rem;
}

.pdg-lft64 {
  padding-left: 4rem;
}

.pdg65 {
  padding: 4.0625rem;
}

.pdg-top65 {
  padding-top: 4.0625rem;
}

.pdg-rgt65 {
  padding-right: 4.0625rem;
}

.pdg-btm65 {
  padding-bottom: 4.0625rem;
}

.pdg-lft65 {
  padding-left: 4.0625rem;
}

.pdg66 {
  padding: 4.125rem;
}

.pdg-top66 {
  padding-top: 4.125rem;
}

.pdg-rgt66 {
  padding-right: 4.125rem;
}

.pdg-btm66 {
  padding-bottom: 4.125rem;
}

.pdg-lft66 {
  padding-left: 4.125rem;
}

.pdg67 {
  padding: 4.1875rem;
}

.pdg-top67 {
  padding-top: 4.1875rem;
}

.pdg-rgt67 {
  padding-right: 4.1875rem;
}

.pdg-btm67 {
  padding-bottom: 4.1875rem;
}

.pdg-lft67 {
  padding-left: 4.1875rem;
}

.pdg68 {
  padding: 4.25rem;
}

.pdg-top68 {
  padding-top: 4.25rem;
}

.pdg-rgt68 {
  padding-right: 4.25rem;
}

.pdg-btm68 {
  padding-bottom: 4.25rem;
}

.pdg-lft68 {
  padding-left: 4.25rem;
}

.pdg69 {
  padding: 4.3125rem;
}

.pdg-top69 {
  padding-top: 4.3125rem;
}

.pdg-rgt69 {
  padding-right: 4.3125rem;
}

.pdg-btm69 {
  padding-bottom: 4.3125rem;
}

.pdg-lft69 {
  padding-left: 4.3125rem;
}

.pdg70 {
  padding: 4.375rem;
}

.pdg-top70 {
  padding-top: 4.375rem;
}

.pdg-rgt70 {
  padding-right: 4.375rem;
}

.pdg-btm70 {
  padding-bottom: 4.375rem;
}

.pdg-lft70 {
  padding-left: 4.375rem;
}

.pdg71 {
  padding: 4.4375rem;
}

.pdg-top71 {
  padding-top: 4.4375rem;
}

.pdg-rgt71 {
  padding-right: 4.4375rem;
}

.pdg-btm71 {
  padding-bottom: 4.4375rem;
}

.pdg-lft71 {
  padding-left: 4.4375rem;
}

.pdg72 {
  padding: 4.5rem;
}

.pdg-top72 {
  padding-top: 4.5rem;
}

.pdg-rgt72 {
  padding-right: 4.5rem;
}

.pdg-btm72 {
  padding-bottom: 4.5rem;
}

.pdg-lft72 {
  padding-left: 4.5rem;
}

.pdg73 {
  padding: 4.5625rem;
}

.pdg-top73 {
  padding-top: 4.5625rem;
}

.pdg-rgt73 {
  padding-right: 4.5625rem;
}

.pdg-btm73 {
  padding-bottom: 4.5625rem;
}

.pdg-lft73 {
  padding-left: 4.5625rem;
}

.pdg74 {
  padding: 4.625rem;
}

.pdg-top74 {
  padding-top: 4.625rem;
}

.pdg-rgt74 {
  padding-right: 4.625rem;
}

.pdg-btm74 {
  padding-bottom: 4.625rem;
}

.pdg-lft74 {
  padding-left: 4.625rem;
}

.pdg75 {
  padding: 4.6875rem;
}

.pdg-top75 {
  padding-top: 4.6875rem;
}

.pdg-rgt75 {
  padding-right: 4.6875rem;
}

.pdg-btm75 {
  padding-bottom: 4.6875rem;
}

.pdg-lft75 {
  padding-left: 4.6875rem;
}

.pdg76 {
  padding: 4.75rem;
}

.pdg-top76 {
  padding-top: 4.75rem;
}

.pdg-rgt76 {
  padding-right: 4.75rem;
}

.pdg-btm76 {
  padding-bottom: 4.75rem;
}

.pdg-lft76 {
  padding-left: 4.75rem;
}

.pdg77 {
  padding: 4.8125rem;
}

.pdg-top77 {
  padding-top: 4.8125rem;
}

.pdg-rgt77 {
  padding-right: 4.8125rem;
}

.pdg-btm77 {
  padding-bottom: 4.8125rem;
}

.pdg-lft77 {
  padding-left: 4.8125rem;
}

.pdg78 {
  padding: 4.875rem;
}

.pdg-top78 {
  padding-top: 4.875rem;
}

.pdg-rgt78 {
  padding-right: 4.875rem;
}

.pdg-btm78 {
  padding-bottom: 4.875rem;
}

.pdg-lft78 {
  padding-left: 4.875rem;
}

.pdg79 {
  padding: 4.9375rem;
}

.pdg-top79 {
  padding-top: 4.9375rem;
}

.pdg-rgt79 {
  padding-right: 4.9375rem;
}

.pdg-btm79 {
  padding-bottom: 4.9375rem;
}

.pdg-lft79 {
  padding-left: 4.9375rem;
}

.pdg80 {
  padding: 5rem;
}

.pdg-top80 {
  padding-top: 5rem;
}

.pdg-rgt80 {
  padding-right: 5rem;
}

.pdg-btm80 {
  padding-bottom: 5rem;
}

.pdg-lft80 {
  padding-left: 5rem;
}

.pdg81 {
  padding: 5.0625rem;
}

.pdg-top81 {
  padding-top: 5.0625rem;
}

.pdg-rgt81 {
  padding-right: 5.0625rem;
}

.pdg-btm81 {
  padding-bottom: 5.0625rem;
}

.pdg-lft81 {
  padding-left: 5.0625rem;
}

.pdg82 {
  padding: 5.125rem;
}

.pdg-top82 {
  padding-top: 5.125rem;
}

.pdg-rgt82 {
  padding-right: 5.125rem;
}

.pdg-btm82 {
  padding-bottom: 5.125rem;
}

.pdg-lft82 {
  padding-left: 5.125rem;
}

.pdg83 {
  padding: 5.1875rem;
}

.pdg-top83 {
  padding-top: 5.1875rem;
}

.pdg-rgt83 {
  padding-right: 5.1875rem;
}

.pdg-btm83 {
  padding-bottom: 5.1875rem;
}

.pdg-lft83 {
  padding-left: 5.1875rem;
}

.pdg84 {
  padding: 5.25rem;
}

.pdg-top84 {
  padding-top: 5.25rem;
}

.pdg-rgt84 {
  padding-right: 5.25rem;
}

.pdg-btm84 {
  padding-bottom: 5.25rem;
}

.pdg-lft84 {
  padding-left: 5.25rem;
}

.pdg85 {
  padding: 5.3125rem;
}

.pdg-top85 {
  padding-top: 5.3125rem;
}

.pdg-rgt85 {
  padding-right: 5.3125rem;
}

.pdg-btm85 {
  padding-bottom: 5.3125rem;
}

.pdg-lft85 {
  padding-left: 5.3125rem;
}

.pdg86 {
  padding: 5.375rem;
}

.pdg-top86 {
  padding-top: 5.375rem;
}

.pdg-rgt86 {
  padding-right: 5.375rem;
}

.pdg-btm86 {
  padding-bottom: 5.375rem;
}

.pdg-lft86 {
  padding-left: 5.375rem;
}

.pdg87 {
  padding: 5.4375rem;
}

.pdg-top87 {
  padding-top: 5.4375rem;
}

.pdg-rgt87 {
  padding-right: 5.4375rem;
}

.pdg-btm87 {
  padding-bottom: 5.4375rem;
}

.pdg-lft87 {
  padding-left: 5.4375rem;
}

.pdg88 {
  padding: 5.5rem;
}

.pdg-top88 {
  padding-top: 5.5rem;
}

.pdg-rgt88 {
  padding-right: 5.5rem;
}

.pdg-btm88 {
  padding-bottom: 5.5rem;
}

.pdg-lft88 {
  padding-left: 5.5rem;
}

.pdg89 {
  padding: 5.5625rem;
}

.pdg-top89 {
  padding-top: 5.5625rem;
}

.pdg-rgt89 {
  padding-right: 5.5625rem;
}

.pdg-btm89 {
  padding-bottom: 5.5625rem;
}

.pdg-lft89 {
  padding-left: 5.5625rem;
}

.pdg90 {
  padding: 5.625rem;
}

.pdg-top90 {
  padding-top: 5.625rem;
}

.pdg-rgt90 {
  padding-right: 5.625rem;
}

.pdg-btm90 {
  padding-bottom: 5.625rem;
}

.pdg-lft90 {
  padding-left: 5.625rem;
}

.pdg91 {
  padding: 5.6875rem;
}

.pdg-top91 {
  padding-top: 5.6875rem;
}

.pdg-rgt91 {
  padding-right: 5.6875rem;
}

.pdg-btm91 {
  padding-bottom: 5.6875rem;
}

.pdg-lft91 {
  padding-left: 5.6875rem;
}

.pdg92 {
  padding: 5.75rem;
}

.pdg-top92 {
  padding-top: 5.75rem;
}

.pdg-rgt92 {
  padding-right: 5.75rem;
}

.pdg-btm92 {
  padding-bottom: 5.75rem;
}

.pdg-lft92 {
  padding-left: 5.75rem;
}

.pdg93 {
  padding: 5.8125rem;
}

.pdg-top93 {
  padding-top: 5.8125rem;
}

.pdg-rgt93 {
  padding-right: 5.8125rem;
}

.pdg-btm93 {
  padding-bottom: 5.8125rem;
}

.pdg-lft93 {
  padding-left: 5.8125rem;
}

.pdg94 {
  padding: 5.875rem;
}

.pdg-top94 {
  padding-top: 5.875rem;
}

.pdg-rgt94 {
  padding-right: 5.875rem;
}

.pdg-btm94 {
  padding-bottom: 5.875rem;
}

.pdg-lft94 {
  padding-left: 5.875rem;
}

.pdg95 {
  padding: 5.9375rem;
}

.pdg-top95 {
  padding-top: 5.9375rem;
}

.pdg-rgt95 {
  padding-right: 5.9375rem;
}

.pdg-btm95 {
  padding-bottom: 5.9375rem;
}

.pdg-lft95 {
  padding-left: 5.9375rem;
}

.pdg96 {
  padding: 6rem;
}

.pdg-top96 {
  padding-top: 6rem;
}

.pdg-rgt96 {
  padding-right: 6rem;
}

.pdg-btm96 {
  padding-bottom: 6rem;
}

.pdg-lft96 {
  padding-left: 6rem;
}

.pdg97 {
  padding: 6.0625rem;
}

.pdg-top97 {
  padding-top: 6.0625rem;
}

.pdg-rgt97 {
  padding-right: 6.0625rem;
}

.pdg-btm97 {
  padding-bottom: 6.0625rem;
}

.pdg-lft97 {
  padding-left: 6.0625rem;
}

.pdg98 {
  padding: 6.125rem;
}

.pdg-top98 {
  padding-top: 6.125rem;
}

.pdg-rgt98 {
  padding-right: 6.125rem;
}

.pdg-btm98 {
  padding-bottom: 6.125rem;
}

.pdg-lft98 {
  padding-left: 6.125rem;
}

.pdg99 {
  padding: 6.1875rem;
}

.pdg-top99 {
  padding-top: 6.1875rem;
}

.pdg-rgt99 {
  padding-right: 6.1875rem;
}

.pdg-btm99 {
  padding-bottom: 6.1875rem;
}

.pdg-lft99 {
  padding-left: 6.1875rem;
}

.pdg100 {
  padding: 6.25rem;
}

.pdg-top100 {
  padding-top: 6.25rem;
}

.pdg-rgt100 {
  padding-right: 6.25rem;
}

.pdg-btm100 {
  padding-bottom: 6.25rem;
}

.pdg-lft100 {
  padding-left: 6.25rem;
}

.pdg101 {
  padding: 6.3125rem;
}

.pdg-top101 {
  padding-top: 6.3125rem;
}

.pdg-rgt101 {
  padding-right: 6.3125rem;
}

.pdg-btm101 {
  padding-bottom: 6.3125rem;
}

.pdg-lft101 {
  padding-left: 6.3125rem;
}

.pdg102 {
  padding: 6.375rem;
}

.pdg-top102 {
  padding-top: 6.375rem;
}

.pdg-rgt102 {
  padding-right: 6.375rem;
}

.pdg-btm102 {
  padding-bottom: 6.375rem;
}

.pdg-lft102 {
  padding-left: 6.375rem;
}

.pdg103 {
  padding: 6.4375rem;
}

.pdg-top103 {
  padding-top: 6.4375rem;
}

.pdg-rgt103 {
  padding-right: 6.4375rem;
}

.pdg-btm103 {
  padding-bottom: 6.4375rem;
}

.pdg-lft103 {
  padding-left: 6.4375rem;
}

.pdg104 {
  padding: 6.5rem;
}

.pdg-top104 {
  padding-top: 6.5rem;
}

.pdg-rgt104 {
  padding-right: 6.5rem;
}

.pdg-btm104 {
  padding-bottom: 6.5rem;
}

.pdg-lft104 {
  padding-left: 6.5rem;
}

.pdg105 {
  padding: 6.5625rem;
}

.pdg-top105 {
  padding-top: 6.5625rem;
}

.pdg-rgt105 {
  padding-right: 6.5625rem;
}

.pdg-btm105 {
  padding-bottom: 6.5625rem;
}

.pdg-lft105 {
  padding-left: 6.5625rem;
}

.pdg106 {
  padding: 6.625rem;
}

.pdg-top106 {
  padding-top: 6.625rem;
}

.pdg-rgt106 {
  padding-right: 6.625rem;
}

.pdg-btm106 {
  padding-bottom: 6.625rem;
}

.pdg-lft106 {
  padding-left: 6.625rem;
}

.pdg107 {
  padding: 6.6875rem;
}

.pdg-top107 {
  padding-top: 6.6875rem;
}

.pdg-rgt107 {
  padding-right: 6.6875rem;
}

.pdg-btm107 {
  padding-bottom: 6.6875rem;
}

.pdg-lft107 {
  padding-left: 6.6875rem;
}

.pdg108 {
  padding: 6.75rem;
}

.pdg-top108 {
  padding-top: 6.75rem;
}

.pdg-rgt108 {
  padding-right: 6.75rem;
}

.pdg-btm108 {
  padding-bottom: 6.75rem;
}

.pdg-lft108 {
  padding-left: 6.75rem;
}

.pdg109 {
  padding: 6.8125rem;
}

.pdg-top109 {
  padding-top: 6.8125rem;
}

.pdg-rgt109 {
  padding-right: 6.8125rem;
}

.pdg-btm109 {
  padding-bottom: 6.8125rem;
}

.pdg-lft109 {
  padding-left: 6.8125rem;
}

.pdg110 {
  padding: 6.875rem;
}

.pdg-top110 {
  padding-top: 6.875rem;
}

.pdg-rgt110 {
  padding-right: 6.875rem;
}

.pdg-btm110 {
  padding-bottom: 6.875rem;
}

.pdg-lft110 {
  padding-left: 6.875rem;
}

.pdg111 {
  padding: 6.9375rem;
}

.pdg-top111 {
  padding-top: 6.9375rem;
}

.pdg-rgt111 {
  padding-right: 6.9375rem;
}

.pdg-btm111 {
  padding-bottom: 6.9375rem;
}

.pdg-lft111 {
  padding-left: 6.9375rem;
}

.pdg112 {
  padding: 7rem;
}

.pdg-top112 {
  padding-top: 7rem;
}

.pdg-rgt112 {
  padding-right: 7rem;
}

.pdg-btm112 {
  padding-bottom: 7rem;
}

.pdg-lft112 {
  padding-left: 7rem;
}

.pdg113 {
  padding: 7.0625rem;
}

.pdg-top113 {
  padding-top: 7.0625rem;
}

.pdg-rgt113 {
  padding-right: 7.0625rem;
}

.pdg-btm113 {
  padding-bottom: 7.0625rem;
}

.pdg-lft113 {
  padding-left: 7.0625rem;
}

.pdg114 {
  padding: 7.125rem;
}

.pdg-top114 {
  padding-top: 7.125rem;
}

.pdg-rgt114 {
  padding-right: 7.125rem;
}

.pdg-btm114 {
  padding-bottom: 7.125rem;
}

.pdg-lft114 {
  padding-left: 7.125rem;
}

.pdg115 {
  padding: 7.1875rem;
}

.pdg-top115 {
  padding-top: 7.1875rem;
}

.pdg-rgt115 {
  padding-right: 7.1875rem;
}

.pdg-btm115 {
  padding-bottom: 7.1875rem;
}

.pdg-lft115 {
  padding-left: 7.1875rem;
}

.pdg116 {
  padding: 7.25rem;
}

.pdg-top116 {
  padding-top: 7.25rem;
}

.pdg-rgt116 {
  padding-right: 7.25rem;
}

.pdg-btm116 {
  padding-bottom: 7.25rem;
}

.pdg-lft116 {
  padding-left: 7.25rem;
}

.pdg117 {
  padding: 7.3125rem;
}

.pdg-top117 {
  padding-top: 7.3125rem;
}

.pdg-rgt117 {
  padding-right: 7.3125rem;
}

.pdg-btm117 {
  padding-bottom: 7.3125rem;
}

.pdg-lft117 {
  padding-left: 7.3125rem;
}

.pdg118 {
  padding: 7.375rem;
}

.pdg-top118 {
  padding-top: 7.375rem;
}

.pdg-rgt118 {
  padding-right: 7.375rem;
}

.pdg-btm118 {
  padding-bottom: 7.375rem;
}

.pdg-lft118 {
  padding-left: 7.375rem;
}

.pdg119 {
  padding: 7.4375rem;
}

.pdg-top119 {
  padding-top: 7.4375rem;
}

.pdg-rgt119 {
  padding-right: 7.4375rem;
}

.pdg-btm119 {
  padding-bottom: 7.4375rem;
}

.pdg-lft119 {
  padding-left: 7.4375rem;
}

.pdg120 {
  padding: 7.5rem;
}

.pdg-top120 {
  padding-top: 7.5rem;
}

.pdg-rgt120 {
  padding-right: 7.5rem;
}

.pdg-btm120 {
  padding-bottom: 7.5rem;
}

.pdg-lft120 {
  padding-left: 7.5rem;
}

.pdg121 {
  padding: 7.5625rem;
}

.pdg-top121 {
  padding-top: 7.5625rem;
}

.pdg-rgt121 {
  padding-right: 7.5625rem;
}

.pdg-btm121 {
  padding-bottom: 7.5625rem;
}

.pdg-lft121 {
  padding-left: 7.5625rem;
}

.pdg122 {
  padding: 7.625rem;
}

.pdg-top122 {
  padding-top: 7.625rem;
}

.pdg-rgt122 {
  padding-right: 7.625rem;
}

.pdg-btm122 {
  padding-bottom: 7.625rem;
}

.pdg-lft122 {
  padding-left: 7.625rem;
}

.pdg123 {
  padding: 7.6875rem;
}

.pdg-top123 {
  padding-top: 7.6875rem;
}

.pdg-rgt123 {
  padding-right: 7.6875rem;
}

.pdg-btm123 {
  padding-bottom: 7.6875rem;
}

.pdg-lft123 {
  padding-left: 7.6875rem;
}

.pdg124 {
  padding: 7.75rem;
}

.pdg-top124 {
  padding-top: 7.75rem;
}

.pdg-rgt124 {
  padding-right: 7.75rem;
}

.pdg-btm124 {
  padding-bottom: 7.75rem;
}

.pdg-lft124 {
  padding-left: 7.75rem;
}

.pdg125 {
  padding: 7.8125rem;
}

.pdg-top125 {
  padding-top: 7.8125rem;
}

.pdg-rgt125 {
  padding-right: 7.8125rem;
}

.pdg-btm125 {
  padding-bottom: 7.8125rem;
}

.pdg-lft125 {
  padding-left: 7.8125rem;
}

.pdg126 {
  padding: 7.875rem;
}

.pdg-top126 {
  padding-top: 7.875rem;
}

.pdg-rgt126 {
  padding-right: 7.875rem;
}

.pdg-btm126 {
  padding-bottom: 7.875rem;
}

.pdg-lft126 {
  padding-left: 7.875rem;
}

.pdg127 {
  padding: 7.9375rem;
}

.pdg-top127 {
  padding-top: 7.9375rem;
}

.pdg-rgt127 {
  padding-right: 7.9375rem;
}

.pdg-btm127 {
  padding-bottom: 7.9375rem;
}

.pdg-lft127 {
  padding-left: 7.9375rem;
}

.pdg128 {
  padding: 8rem;
}

.pdg-top128 {
  padding-top: 8rem;
}

.pdg-rgt128 {
  padding-right: 8rem;
}

.pdg-btm128 {
  padding-bottom: 8rem;
}

.pdg-lft128 {
  padding-left: 8rem;
}

.pdg129 {
  padding: 8.0625rem;
}

.pdg-top129 {
  padding-top: 8.0625rem;
}

.pdg-rgt129 {
  padding-right: 8.0625rem;
}

.pdg-btm129 {
  padding-bottom: 8.0625rem;
}

.pdg-lft129 {
  padding-left: 8.0625rem;
}

.pdg130 {
  padding: 8.125rem;
}

.pdg-top130 {
  padding-top: 8.125rem;
}

.pdg-rgt130 {
  padding-right: 8.125rem;
}

.pdg-btm130 {
  padding-bottom: 8.125rem;
}

.pdg-lft130 {
  padding-left: 8.125rem;
}

.pdg131 {
  padding: 8.1875rem;
}

.pdg-top131 {
  padding-top: 8.1875rem;
}

.pdg-rgt131 {
  padding-right: 8.1875rem;
}

.pdg-btm131 {
  padding-bottom: 8.1875rem;
}

.pdg-lft131 {
  padding-left: 8.1875rem;
}

.pdg132 {
  padding: 8.25rem;
}

.pdg-top132 {
  padding-top: 8.25rem;
}

.pdg-rgt132 {
  padding-right: 8.25rem;
}

.pdg-btm132 {
  padding-bottom: 8.25rem;
}

.pdg-lft132 {
  padding-left: 8.25rem;
}

.pdg133 {
  padding: 8.3125rem;
}

.pdg-top133 {
  padding-top: 8.3125rem;
}

.pdg-rgt133 {
  padding-right: 8.3125rem;
}

.pdg-btm133 {
  padding-bottom: 8.3125rem;
}

.pdg-lft133 {
  padding-left: 8.3125rem;
}

.pdg134 {
  padding: 8.375rem;
}

.pdg-top134 {
  padding-top: 8.375rem;
}

.pdg-rgt134 {
  padding-right: 8.375rem;
}

.pdg-btm134 {
  padding-bottom: 8.375rem;
}

.pdg-lft134 {
  padding-left: 8.375rem;
}

.pdg135 {
  padding: 8.4375rem;
}

.pdg-top135 {
  padding-top: 8.4375rem;
}

.pdg-rgt135 {
  padding-right: 8.4375rem;
}

.pdg-btm135 {
  padding-bottom: 8.4375rem;
}

.pdg-lft135 {
  padding-left: 8.4375rem;
}

.pdg136 {
  padding: 8.5rem;
}

.pdg-top136 {
  padding-top: 8.5rem;
}

.pdg-rgt136 {
  padding-right: 8.5rem;
}

.pdg-btm136 {
  padding-bottom: 8.5rem;
}

.pdg-lft136 {
  padding-left: 8.5rem;
}

.pdg137 {
  padding: 8.5625rem;
}

.pdg-top137 {
  padding-top: 8.5625rem;
}

.pdg-rgt137 {
  padding-right: 8.5625rem;
}

.pdg-btm137 {
  padding-bottom: 8.5625rem;
}

.pdg-lft137 {
  padding-left: 8.5625rem;
}

.pdg138 {
  padding: 8.625rem;
}

.pdg-top138 {
  padding-top: 8.625rem;
}

.pdg-rgt138 {
  padding-right: 8.625rem;
}

.pdg-btm138 {
  padding-bottom: 8.625rem;
}

.pdg-lft138 {
  padding-left: 8.625rem;
}

.pdg139 {
  padding: 8.6875rem;
}

.pdg-top139 {
  padding-top: 8.6875rem;
}

.pdg-rgt139 {
  padding-right: 8.6875rem;
}

.pdg-btm139 {
  padding-bottom: 8.6875rem;
}

.pdg-lft139 {
  padding-left: 8.6875rem;
}

.pdg140 {
  padding: 8.75rem;
}

.pdg-top140 {
  padding-top: 8.75rem;
}

.pdg-rgt140 {
  padding-right: 8.75rem;
}

.pdg-btm140 {
  padding-bottom: 8.75rem;
}

.pdg-lft140 {
  padding-left: 8.75rem;
}

.pdg141 {
  padding: 8.8125rem;
}

.pdg-top141 {
  padding-top: 8.8125rem;
}

.pdg-rgt141 {
  padding-right: 8.8125rem;
}

.pdg-btm141 {
  padding-bottom: 8.8125rem;
}

.pdg-lft141 {
  padding-left: 8.8125rem;
}

.pdg142 {
  padding: 8.875rem;
}

.pdg-top142 {
  padding-top: 8.875rem;
}

.pdg-rgt142 {
  padding-right: 8.875rem;
}

.pdg-btm142 {
  padding-bottom: 8.875rem;
}

.pdg-lft142 {
  padding-left: 8.875rem;
}

.pdg143 {
  padding: 8.9375rem;
}

.pdg-top143 {
  padding-top: 8.9375rem;
}

.pdg-rgt143 {
  padding-right: 8.9375rem;
}

.pdg-btm143 {
  padding-bottom: 8.9375rem;
}

.pdg-lft143 {
  padding-left: 8.9375rem;
}

.pdg144 {
  padding: 9rem;
}

.pdg-top144 {
  padding-top: 9rem;
}

.pdg-rgt144 {
  padding-right: 9rem;
}

.pdg-btm144 {
  padding-bottom: 9rem;
}

.pdg-lft144 {
  padding-left: 9rem;
}

.pdg145 {
  padding: 9.0625rem;
}

.pdg-top145 {
  padding-top: 9.0625rem;
}

.pdg-rgt145 {
  padding-right: 9.0625rem;
}

.pdg-btm145 {
  padding-bottom: 9.0625rem;
}

.pdg-lft145 {
  padding-left: 9.0625rem;
}

.pdg146 {
  padding: 9.125rem;
}

.pdg-top146 {
  padding-top: 9.125rem;
}

.pdg-rgt146 {
  padding-right: 9.125rem;
}

.pdg-btm146 {
  padding-bottom: 9.125rem;
}

.pdg-lft146 {
  padding-left: 9.125rem;
}

.pdg147 {
  padding: 9.1875rem;
}

.pdg-top147 {
  padding-top: 9.1875rem;
}

.pdg-rgt147 {
  padding-right: 9.1875rem;
}

.pdg-btm147 {
  padding-bottom: 9.1875rem;
}

.pdg-lft147 {
  padding-left: 9.1875rem;
}

.pdg148 {
  padding: 9.25rem;
}

.pdg-top148 {
  padding-top: 9.25rem;
}

.pdg-rgt148 {
  padding-right: 9.25rem;
}

.pdg-btm148 {
  padding-bottom: 9.25rem;
}

.pdg-lft148 {
  padding-left: 9.25rem;
}

.pdg149 {
  padding: 9.3125rem;
}

.pdg-top149 {
  padding-top: 9.3125rem;
}

.pdg-rgt149 {
  padding-right: 9.3125rem;
}

.pdg-btm149 {
  padding-bottom: 9.3125rem;
}

.pdg-lft149 {
  padding-left: 9.3125rem;
}

.pdg150 {
  padding: 9.375rem;
}

.pdg-top150 {
  padding-top: 9.375rem;
}

.pdg-rgt150 {
  padding-right: 9.375rem;
}

.pdg-btm150 {
  padding-bottom: 9.375rem;
}

.pdg-lft150 {
  padding-left: 9.375rem;
}

/* --- position --- */
.pos-rel {
  position: relative;
}

.pos-ab {
  position: absolute;
}

.pos-fx {
  position: fixed;
}

/* --- float --- */
.flt-rgt {
  float: right;
}

.flt-lft {
  float: left;
}

.ani-delay0 {
  animation-delay: 0ms;
}

.ani-delay100 {
  animation-delay: 100ms;
}

.ani-delay200 {
  animation-delay: 200ms;
}

.ani-delay300 {
  animation-delay: 300ms;
}

.ani-delay400 {
  animation-delay: 400ms;
}

.ani-delay500 {
  animation-delay: 500ms;
}

.ani-delay600 {
  animation-delay: 600ms;
}

.ani-delay700 {
  animation-delay: 700ms;
}

.ani-delay800 {
  animation-delay: 800ms;
}

.ani-delay900 {
  animation-delay: 900ms;
}

.ani-delay1000 {
  animation-delay: 1000ms;
}

.ani-delay1100 {
  animation-delay: 1100ms;
}

.ani-delay1200 {
  animation-delay: 1200ms;
}

.ani-delay1300 {
  animation-delay: 1300ms;
}

.ani-delay1400 {
  animation-delay: 1400ms;
}

.ani-delay1500 {
  animation-delay: 1500ms;
}

.ani-delay1600 {
  animation-delay: 1600ms;
}

.ani-delay1700 {
  animation-delay: 1700ms;
}

.ani-delay1800 {
  animation-delay: 1800ms;
}

.ani-delay1900 {
  animation-delay: 1900ms;
}

.ani-delay2000 {
  animation-delay: 2000ms;
}

.ani-delay2100 {
  animation-delay: 2100ms;
}

.ani-delay2200 {
  animation-delay: 2200ms;
}

.ani-delay2300 {
  animation-delay: 2300ms;
}

.ani-delay2400 {
  animation-delay: 2400ms;
}

.ani-delay2500 {
  animation-delay: 2500ms;
}

.ani-delay2600 {
  animation-delay: 2600ms;
}

.ani-delay2700 {
  animation-delay: 2700ms;
}

.ani-delay2800 {
  animation-delay: 2800ms;
}

.ani-delay2900 {
  animation-delay: 2900ms;
}

.ani-delay3000 {
  animation-delay: 3000ms;
}

.ani-delay3100 {
  animation-delay: 3100ms;
}

.ani-delay3200 {
  animation-delay: 3200ms;
}

.ani-delay3300 {
  animation-delay: 3300ms;
}

.ani-delay3400 {
  animation-delay: 3400ms;
}

.ani-delay3500 {
  animation-delay: 3500ms;
}

.ani-delay3600 {
  animation-delay: 3600ms;
}

.ani-delay3700 {
  animation-delay: 3700ms;
}

.ani-delay3800 {
  animation-delay: 3800ms;
}

.ani-delay3900 {
  animation-delay: 3900ms;
}

.ani-delay4000 {
  animation-delay: 4000ms;
}

.trans-delay0 {
  transition-delay: 0ms;
}

.trans-delay100 {
  transition-delay: 100ms;
}

.trans-delay200 {
  transition-delay: 200ms;
}

.trans-delay300 {
  transition-delay: 300ms;
}

.trans-delay400 {
  transition-delay: 400ms;
}

.trans-delay500 {
  transition-delay: 500ms;
}

.trans-delay600 {
  transition-delay: 600ms;
}

.trans-delay700 {
  transition-delay: 700ms;
}

.trans-delay800 {
  transition-delay: 800ms;
}

.trans-delay900 {
  transition-delay: 900ms;
}

.trans-delay1000 {
  transition-delay: 1000ms;
}

.trans-delay1100 {
  transition-delay: 1100ms;
}

.trans-delay1200 {
  transition-delay: 1200ms;
}

.trans-delay1300 {
  transition-delay: 1300ms;
}

.trans-delay1400 {
  transition-delay: 1400ms;
}

.trans-delay1500 {
  transition-delay: 1500ms;
}

.trans-delay1600 {
  transition-delay: 1600ms;
}

.trans-delay1700 {
  transition-delay: 1700ms;
}

.trans-delay1800 {
  transition-delay: 1800ms;
}

.trans-delay1900 {
  transition-delay: 1900ms;
}

.trans-delay2000 {
  transition-delay: 2000ms;
}

.trans-delay2100 {
  transition-delay: 2100ms;
}

.trans-delay2200 {
  transition-delay: 2200ms;
}

.trans-delay2300 {
  transition-delay: 2300ms;
}

.trans-delay2400 {
  transition-delay: 2400ms;
}

.trans-delay2500 {
  transition-delay: 2500ms;
}

.trans-delay2600 {
  transition-delay: 2600ms;
}

.trans-delay2700 {
  transition-delay: 2700ms;
}

.trans-delay2800 {
  transition-delay: 2800ms;
}

.trans-delay2900 {
  transition-delay: 2900ms;
}

.trans-delay3000 {
  transition-delay: 3000ms;
}

.trans-delay3100 {
  transition-delay: 3100ms;
}

.trans-delay3200 {
  transition-delay: 3200ms;
}

.trans-delay3300 {
  transition-delay: 3300ms;
}

.trans-delay3400 {
  transition-delay: 3400ms;
}

.trans-delay3500 {
  transition-delay: 3500ms;
}

.trans-delay3600 {
  transition-delay: 3600ms;
}

.trans-delay3700 {
  transition-delay: 3700ms;
}

.trans-delay3800 {
  transition-delay: 3800ms;
}

.trans-delay3900 {
  transition-delay: 3900ms;
}

.trans-delay4000 {
  transition-delay: 4000ms;
}

/* breadcrumbs
**************************************** */
.breadcrumbs .inner {
  padding: 1rem 0;
  white-space: nowrap;
}
@media print, screen and (min-width: 48em) {
  .breadcrumbs .inner {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 47.9375em) {
  .breadcrumbs .inner {
    overflow: auto;
  }
}

.breadcrumbs a:hover {
  color: var(--clr-main);
}

.breadcrumbs span span:not(:last-child)::after {
  content: "";
  display: inline-block;
  width: 0.3125rem;
  height: 0.4375rem;
  clip-path: polygon(0 100%, 0 0, 100% 50%);
  background-color: #ccc;
  margin: 0.15em 0.5em 0.15em 1em;
}

/* btn
********************************************** */
.btn:not(:last-child) {
  margin-bottom: 0.5rem;
}

.btn a {
  position: relative;
  z-index: 1;
  display: block;
  padding: 0.625rem 3.5rem 0.625rem 2rem;
  text-align: center;
  border: 2px solid var(--bg-cta);
  border-radius: 0.5rem;
  width: fit-content;
  min-width: min(17.5rem, 100%);
  font-family: var(--font-secondary);
  color: var(--clr-wht);
  background-color: var(--bg-cta);
}
@media screen and (max-width: 47.9375em) {
  .btn a {
    min-width: 100%;
  }
}
@media (hover: hover) {
  .btn a:hover {
    background-color: inherit;
    color: var(--bg-cta);
  }
  .btn a:hover svg {
    fill: var(--bg-cta);
  }
}
.btn a:where(:not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]))::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 2.25rem;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  transition: var(--transit-default);
}
.btn a svg {
  right: 1.5rem;
  fill: var(--clr-wht);
}

.btn.btn-ctr a {
  margin-left: auto;
  margin-right: auto;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--clr-main);
  background-color: var(--bg-wht);
  border-color: var(--bg-wht);
}
@media (hover: hover) {
  .btn-wh a:hover {
    color: var(--clr-wht);
    background-color: var(--bg-cta);
    border-color: var(--clr-cta);
  }
  .btn-wh a svg {
    fill: var(--clr-main);
  }
  .btn-wh a:hover svg {
    fill: var(--clr-wht);
  }
}

/* --- btn-yellow --- */
@media (hover: hover) {
  .btn.btn-yellow a:hover {
    background-color: var(--clr-yellow-07);
    border-color: var(--clr-yellow-07);
  }
}

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--bg-cta);
}
.btn-cta a:hover {
  background-color: var(--bg-cta-hvr);
}

/* --- btn-arrow --- */
.btn-arrow a {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  margin-inline: -1em;
  padding: 1em;
}
.btn-arrow a:hover {
  color: var(--clr-main);
}
.btn-arrow a::after {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  color: var(--clr-main);
  transform: rotate(45deg);
  border-top: 2px solid;
  border-right: 2px solid;
}

/* --- 横並び --- */
.flex > .btn {
  margin-bottom: 0;
}
.flex > .btn:first-child {
  margin-left: auto;
}
.flex > .btn:last-child {
  margin-right: auto;
}
@media print, screen and (min-width: 48em) {
  .flex > .btn:only-child {
    width: fit-content;
    min-width: min(17.5rem, 100%);
  }
}
.flex > .btn a {
  min-width: 100%;
}

/* btn-fan_shape */
.btn-fan_shape {
  position: absolute;
  bottom: -6.25rem;
  right: -6.25rem;
  width: 12.5rem;
  aspect-ratio: 1/1;
  background-color: var(--bg-cta);
  border-radius: 50vw;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .btn-fan_shape {
    bottom: -4.2857142857rem;
    right: -4.2857142857rem;
    width: 8.5714285714rem;
  }
}
.btn-fan_shape::before {
  content: "";
  position: absolute;
  top: 28%;
  left: 25%;
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  transform: translateY(-50%) rotate(45deg);
  border-top: 3px solid var(--clr-wht);
  border-right: 3px solid var(--clr-wht);
}

/* ##############################################################################

  FORMY

############################################################################## */
#formy_form {
  padding: 2rem;
  background-color: var(--clr-wht-42);
  border-radius: 1rem;
  box-shadow: 0 0 3px var(--clr-beige-02);
}

#formy_form table {
  width: 100%;
}

#formy_form th,
#formy_form td {
  padding: 1rem;
  vertical-align: middle;
  border-bottom: solid 1px #eee;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th,
  #formy_form td {
    display: block;
    width: auto !important;
    padding-left: 0;
    padding-right: 0;
  }
}

#formy_form th {
  font-weight: 500;
  white-space: nowrap;
  text-align: left;
}
@media print, screen and (min-width: 48em) {
  #formy_form th {
    width: 34%;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form th {
    width: 100%;
    border-bottom: none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form table textarea {
  width: 100%;
  border-radius: 0.1875rem;
  vertical-align: bottom;
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 0.3125rem 0.9375rem;
  border: 1px solid #ccc;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: max(14px, 1rem);
  transition: var(--transit-default);
}

#formy_form textarea {
  height: 6.25rem;
}

#formy_form select {
  height: 2.5rem;
}

#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#formy_form input:hover {
  opacity: 0.7;
}

#formy_form textarea:hover {
  opacity: 0.7;
}

#formy_form input:focus {
  outline: none;
}

#formy_form .parsley-validated {
  background-color: #eee;
}

#formy_form .parsley-error {
  background-color: #fee;
}

#formy_form .parsley-success {
  background-color: var(--bg-wht);
}

.help_text {
  font-size: max(12px, 0.875rem);
  color: #999;
}

.hidden_help {
  display: none;
}

.formy_privacy div {
  overflow-y: scroll;
  height: 8.75rem;
  border: solid 1px #ccc;
  font-size: max(12px, 0.875rem);
  padding: 0.5rem 1rem;
}

.requiredIcon {
  background-color: #f55;
  color: var(--clr-wht);
  margin: 0 0 0 1em;
  font-size: max(10px, 0.75rem);
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  float: right;
}

#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 2rem;
  text-align: center;
}

#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: var(--clr-wht);
  border-radius: 0.1875rem;
  padding: 1rem 2rem;
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary);
  transition: var(--transit-default);
}
#formy_btn input:not(:first-child) {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn input {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}

#formy_form ul li input[type=radio],
#formy_form ul li input[type=checkbox] {
  display: none !important;
}

#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default);
}

#formy_form ul li label:hover {
  opacity: 0.7;
}

#formy_form ul li label::before {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: 1rem;
  width: 1rem;
  height: 1rem;
  border: 2px solid #788b93;
}

#formy_form ul li input[type=radio] + label::before {
  border-radius: 0.625rem;
}

#formy_form ul li input[type=radio]:checked + label,
#formy_form ul li input[type=checkbox]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}

#formy_form ul li input[type=radio]:checked + label::before,
#formy_form ul li input[type=checkbox]:checked + label::before {
  border-color: #e75f5f;
}

#formy_form ul li input[type=radio]:checked + label::after,
#formy_form ul li input[type=checkbox]:checked + label::after {
  content: "";
  width: 0.625rem;
  height: 1.125rem;
  top: 0.25rem;
  left: 1.25rem;
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}

.formy_confirm {
  background-color: var(--bg-cta);
}

.formy_submit_disabled {
  background-color: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 0.5rem 1.6875rem;
  min-width: auto;
  font-size: max(13px, 0.9375rem);
  border: 1px solid #aaa;
  border-radius: 2rem;
}

.formy_send {
  background-color: var(--clr-orange);
}

#total_required {
  padding: 1rem;
  color: #f55555;
  text-align: center;
}

@media print, screen and (min-width: 48em) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form td {
    padding-top: 0;
  }
  .autoConfirmBack {
    margin-bottom: 0.5714285714rem;
  }
}
/* ##############################################################################

  FORM

############################################################################## */
.nf-form-cont .nf-form-content label {
  font-size: max(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .nf-form-cont .nf-form-content label {
    font-size: 1.0714285714rem;
    margin-bottom: 6px !important;
  }
}

.nf-form-cont .nf-form-content .nf-label-span {
  font-family: var(--font-primary, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
}

.nf-form-cont .nf-form-fields-required {
  display: none !important;
}

.nf-form-cont .nf-field-element {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

#ninja_forms_required_items,
.nf-form-cont .nf-field-container {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #ninja_forms_required_items,
  .nf-form-cont .nf-field-container {
    margin-bottom: 1.1428571429rem;
  }
}

.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div,
.nf-form-cont .nf-form-content input:not([type=button]),
.nf-form-cont .nf-form-content textarea {
  background: var(--bg-wht);
  color: var(--clr-body);
  border-radius: 0.1875rem;
  vertical-align: bottom;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: max(14px, 1rem);
  transition: var(--transit-default, all 0.4s ease-out);
  font-weight: 500;
}

.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div:focus,
.nf-form-cont .nf-form-content input:not([type=button]):focus,
.nf-form-cont .nf-form-content textarea:focus {
  color: var(--clr-body);
}

.nf-form-content .list-checkbox-wrap .nf-field-element li label,
.nf-form-content .list-image-wrap .nf-field-element li label,
.nf-form-content .list-radio-wrap .nf-field-element li label {
  cursor: pointer;
}

.nf-form-content input[type=checkbox]:focus + label:after,
.nf-form-content input[type=radio]:focus + label:after {
  box-shadow: none;
}

.checkbox-wrap .nf-field-element label.nf-checked-label:before,
.checkbox-wrap .nf-field-label label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-element label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
  color: var(--clr-main);
}

.nf-form-cont .ninja-forms-req-symbol {
  font-size: 0 !important;
}
.nf-form-cont .ninja-forms-req-symbol::before {
  content: "必須"; /* 必須 */
  background-color: #f55;
  color: var(--clr-wht);
  margin-left: 0.5em;
  font-size: max(10px, 0.75rem);
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

.nf-form-cont .nf-form-content .submit-wrap {
  text-align: center;
}

.nf-form-cont .nf-form-content .submit-wrap input:not([type=button]) {
  max-width: 100%;
  width: 20rem;
  height: 4rem;
  font-size: max(16px, 1.125rem);
  line-height: 1;
  letter-spacing: 0.1em;
  color: var(--clr-wht);
  background-color: var(--bg-main);
  border-radius: 3.125rem;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: var(--font-primary, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif) !important;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (min-width: 48em) {
  .nf-form-cont .nf-form-content .submit-wrap input:not([type=button]) {
    margin-top: 1.7142857143rem;
  }
}

.nf-form-cont .nf-form-errors {
  margin-top: -1em;
  text-align: center;
}

/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha_policy {
  padding: 0;
  margin-top: 3rem;
  color: var(--clr-body);
  font-size: max(8px, 0.625rem);
  text-align: center;
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .recaptcha_policy {
    text-align: left;
  }
}

.recaptcha_policy a {
  color: #69ABDB;
  font-size: max(10px, 0.75rem);
  text-decoration: underline;
}

/* お問い合わせ
********************************************** */
.cta {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 12rem;
  background-color: var(--bg-primary);
}
@media screen and (max-width: 47.9375em) {
  .cta {
    padding-bottom: 7.1428571429rem;
  }
}

.subpage .cta,
.subpage .footer {
  background-color: var(--bg-wht);
}

@media screen and (max-width: 47.9375em) {
  .cta--ttl_box > .flex.gap40 {
    flex-direction: column;
    gap: 1.5rem;
  }
}

.cta .cta--container {
  border-radius: 2.5rem;
}

.cta .cta_box {
  padding: 6.3125rem 7.5rem 6.3125rem;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .cta .cta_box {
    gap: 0;
    padding: 2.2857142857rem 2.2857142857rem 3.4285714286rem;
  }
}

.cta_box .ttl-03 {
  font-size: 2rem;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 47.9375em) {
  .cta_box .ttl-03 {
    font-size: 1.8571428571rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .cta_box:first-child .ttl-03.txt-wh.dotted {
    margin-bottom: 0;
  }
}

.cta_box-contact {
  background-color: var(--clr-pink);
  border-top-left-radius: 2.5rem;
}
@media print, screen and (min-width: 48em) {
  .cta_box-contact {
    border-bottom-left-radius: 2.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .cta_box-contact {
    border-top-right-radius: 2.5rem;
  }
}

.cta_box-recruit {
  border-bottom-right-radius: 2.5rem;
  background-color: var(--clr-green-13);
  cursor: pointer;
}
@media print, screen and (min-width: 48em) {
  .cta_box-recruit {
    border-top-right-radius: 2.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .cta_box-recruit {
    border-bottom-left-radius: 2.5rem;
  }
}
@media (hover: hover) {
  .cta_box-recruit:hover .btn a {
    color: var(--bg-cta);
    background-color: transparent;
  }
  .cta_box-recruit:hover .btn a::before {
    border-color: var(--bg-cta);
  }
}

.cta--ttl .ttl-01 {
  margin-top: 0;
}

.cta--ttl .ttl-01::before {
  content: none;
}

.cta--ttl .ttl-01-sub {
  margin-bottom: 0;
  text-align: left;
}
@media screen and (max-width: 47.9375em) {
  .cta--ttl .ttl-01-sub {
    text-align: center;
  }
}

.cta_line {
  width: 5px;
  height: 8.75rem;
}
@media print, screen and (min-width: 48em) {
  .cta_line {
    border-right: 5px dotted var(--clr-beige-02);
  }
}
@media screen and (max-width: 47.9375em) {
  .cta_line {
    width: 100%;
    height: 5px;
    border-bottom: 5px dotted var(--clr-beige-02);
  }
}

.cta--txt {
  font-family: var(--font-secondary);
}

.cta--container .cta_tel {
  align-items: center;
}

.cta--container .cta_tel .num {
  font-size: 1.9375rem;
  font-weight: bold;
}

/* cta_box
**************************************** */
.cta_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media screen and (max-width: 47.9375em) {
  .cta_box {
    align-items: center;
    gap: 0.7142857143rem;
  }
}

/* cta_tel
**************************************** */
.cta_tel a {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0.75rem;
  line-height: 1.4;
  border-radius: 1.25rem;
}

.cta_tel a > span {
  display: block;
}

.cta_tel .tel {
  font-size: max(18px, 1.25rem);
}

.cta_tel .tel_txt {
  text-align: center;
  font-size: 1rem;
}

.cta_tel .num {
  font-size: 1.75rem;
}

.cta_tel .hours {
  padding-left: 1em;
  font-size: max(11px, 0.8125rem);
  font-family: var(--font-secondary);
}

/* cta_contact
**************************************** */
.cta_contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
}

/* icon
**************************************** */
.icon-mail {
  width: 1.3125rem;
  aspect-ratio: 21/16;
  fill: var(--bg-wht);
}
.icon-mail + * {
  margin-left: 0.5rem;
}

.cls-10 {
  fill: #fff;
}

.cls-20 {
  fill: #d63e48;
}

/* cloud
********************************************** */
.deco-cloud-01 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/cloud01.png);
  aspect-ratio: 396/239;
}

.deco-cloud-02 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/cloud02.png);
  aspect-ratio: 186/192;
}

.deco-cloud-03 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/cloud03.png);
  aspect-ratio: 396/239;
}

.deco-cloud-04 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/cloud04.png);
  aspect-ratio: 324/183;
}

.deco-cloud-05 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/cloud05.png);
  aspect-ratio: 210/103;
}

/* tree
********************************************** */
.deco {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
}

.deco-round_tree {
  background-image: url(/wp/wp-content/themes/standard_sass/images/round_tree.svg);
  aspect-ratio: 106/125;
}

.deco-triangle_tree-two {
  background-image: url(/wp/wp-content/themes/standard_sass/images/triangle-tree01.svg);
  aspect-ratio: 2/3;
}

.deco-triangle_tree-two-reverse {
  background-image: url(/wp/wp-content/themes/standard_sass/images/triangle-tree02.svg);
  aspect-ratio: 2/3;
}

.deco-triangle_tree {
  background-image: url(/wp/wp-content/themes/standard_sass/images/triangle-tree03.svg);
  aspect-ratio: 61/150;
}

/* people
********************************************** */
.deco-people-01 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people01.png);
  aspect-ratio: 210/288;
}

.deco-people-02 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people02.png);
  aspect-ratio: 514/287;
}

.deco-people-02-2 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people02-2.png);
  aspect-ratio: 336/260;
}

.deco-people-03 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people03.png);
  aspect-ratio: 326/290;
}

.deco-people-03-3 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people03-3.png);
  aspect-ratio: 323/285;
}

.deco-people-04 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people04.png);
  aspect-ratio: 215/286;
}

.deco-people-05 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people05.png);
  aspect-ratio: 396/286;
}

.deco-people-06 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people06.png);
  aspect-ratio: 223/286;
}

.deco-people-07 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people07.png);
  aspect-ratio: 183/287;
}

.deco-people-08 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people08.png);
  aspect-ratio: 459/283;
}

.deco-people-09 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people09.png);
  aspect-ratio: 151/380;
}

.deco-people-10 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/people10.png);
  aspect-ratio: 236/286;
}

/* deco-speaking
********************************************** */
.deco-speaking--txt {
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.1em;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .deco-speaking--txt {
    font-size: 0.7142857143rem;
  }
}

.deco-speaking-about {
  bottom: 4.84375vw;
  right: 5vw;
  width: min(7.7604166667vw, 9.3125rem);
  color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-about_us.png);
  aspect-ratio: 149/106;
}
@media screen and (max-width: 47.9375em) {
  .deco-speaking-about {
    width: 27.5362318841vw;
  }
}

.deco-speaking-feature {
  bottom: max(340px, 21.8229166667vw);
  left: min(22.3958333333vw, 430px);
  width: min(8.0729166667vw, 9.6875rem);
  color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-feature.png);
  aspect-ratio: 155/104;
}
@media print, screen and (min-width: 125.0625em) {
  .deco-speaking-feature {
    bottom: 802px;
  }
}
@media screen and (max-width: 47.9375em) {
  .deco-speaking-feature {
    bottom: 238px;
    left: 76.0869565217vw;
    width: 20.7729468599vw;
  }
}

.deco-speaking-subaru {
  bottom: 4.84375vw;
  left: 7.5vw;
  width: min(10.7291666667vw, 12.875rem);
  z-index: 3;
  background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-subaru.png);
  aspect-ratio: 206/139;
}
@media screen and (max-width: 47.9375em) {
  .deco-speaking-subaru {
    bottom: 50%;
    transform: translateY(50%);
    width: 34.5410628019vw;
  }
}
.deco-speaking-subaru .deco-speaking--txt span {
  color: var(--clr-yellow-03);
}

.deco-speaking-subaru-02 {
  bottom: 4.84375vw;
  width: min(10.7291666667vw, 12.875rem);
  z-index: 3;
  background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-subaru02.png);
  aspect-ratio: 206/140;
}
@media print, screen and (min-width: 48em) {
  .deco-speaking-subaru-02 {
    right: 39.84375vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .deco-speaking-subaru-02 {
    bottom: 0.9661835749vw;
    left: 9.6618357488vw;
    width: 34.5410628019vw;
  }
}
.deco-speaking-subaru-02 .deco-speaking--txt {
  top: 40%;
}
.deco-speaking-subaru-02 .deco-speaking--txt span {
  color: var(--clr-green-11);
}

.deco-speaking-recruit {
  top: clamp(410px, 28.125vw, 540px);
  left: 10.8333333333vw;
  width: min(9.0625vw, 10.875rem);
  color: var(--clr-wht);
  background-image: url(/wp/wp-content/themes/standard_sass/images/speaking-recruit.png);
  aspect-ratio: 174/110;
}
@media screen and (max-width: 47.9375em) {
  .deco-speaking-recruit {
    top: 90%;
    left: 5.7971014493vw;
    width: 28.9855072464vw;
  }
}

/* dot-circle
********************************************** */
.deco-dot_circle {
  background-image: url(/wp/wp-content/themes/standard_sass/images/dot_circle.png);
  aspect-ratio: 569/573;
}

/* hero
********************************************** */
.hero_deco-recruit {
  bottom: 3.0729166667vw;
  right: 3.125vw;
  z-index: 10;
  width: min(7.3958333333vw, 8.875rem);
  background-image: url(/wp/wp-content/themes/standard_sass/images/recruit-icn.png);
  aspect-ratio: 142/157;
}
@media screen and (max-width: 47.9375em) {
  .hero_deco-recruit {
    width: 20.2898550725vw;
  }
}
@media (hover: hover) {
  .hero_deco-recruit:hover .hero_deco-recruit--txt {
    color: var(--clr-green-08);
  }
}

.hero_deco-recruit--txt {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 1.25rem;
  line-height: 1.4;
  letter-spacing: 0.14em;
  color: var(--clr-wht);
  white-space: nowrap;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .hero_deco-recruit--txt {
    font-size: 1rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero_deco-recruit--txt > .fz28 {
    font-size: 1.2857142857rem;
  }
}

/* hero
********************************************** */
.hero .deco-cloud-01 {
  top: 0vw;
  left: -8.28125vw;
  width: min(20.625vw, 24.75rem);
}
.hero .deco-cloud-02 {
  top: 12.1875vw;
  right: 0;
  width: min(9.6875vw, 11.625rem);
}

/* home_intro
********************************************** */
.home_intro .deco-cloud-03 {
  bottom: -2.7604166667vw;
  right: -2.1875vw;
  width: min(13.5416666667vw, 16.25rem);
}

/* home_news
********************************************** */
.home_news .deco-round_tree-01 {
  top: -11.4583333333vw;
  left: 1.3020833333vw;
  width: min(4.8958333333vw, 5.875rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-round_tree-01 {
    width: 8.4541062802vw;
  }
}
.home_news .deco-round_tree-02 {
  bottom: -13.0208333333vw;
  left: 21.5625vw;
  width: min(6.1458333333vw, 7.375rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-round_tree-02 {
    bottom: -24.6376811594vw;
    width: 13.0434782609vw;
  }
}
.home_news .deco-round_tree-03 {
  bottom: -24.6875vw;
  right: -1.6666666667vw;
  width: min(11.4583333333vw, 13.75rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-round_tree-03 {
    bottom: -50.9661835749vw;
    width: 17.6328502415vw;
  }
}
.home_news .deco-triangle_tree-two-01 {
  top: -3.6979166667vw;
  left: 25.7291666667vw;
  width: min(5vw, 6rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-triangle_tree-two-01 {
    top: -8.6956521739vw;
    width: 9.6618357488vw;
  }
}
.home_news .deco-triangle_tree-two-02 {
  top: 2.5vw;
  right: calc(100% + -2.9166666667vw);
  width: min(10.4166666667vw, 12.5rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-triangle_tree-two-02 {
    width: 16.9082125604vw;
  }
}
.home_news .deco-triangle_tree-two-03 {
  bottom: -13.125vw;
  right: 23.1770833333vw;
  width: min(7.2395833333vw, 8.6875rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-triangle_tree-two-03 {
    bottom: -24.3961352657vw;
    width: 13.2850241546vw;
  }
}
.home_news .deco-triangle_tree-two-04 {
  top: -1.71875vw;
  right: -0.8854166667vw;
  width: min(5.7291666667vw, 6.875rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-triangle_tree-two-04 {
    width: 15.4589371981vw;
  }
}
.home_news .deco-triangle_tree-two-05 {
  bottom: -22.8125vw;
  left: -1.25vw;
  width: min(10.4166666667vw, 12.5rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-triangle_tree-two-05 {
    bottom: -50vw;
    width: 18.115942029vw;
  }
}
.home_news .deco-people-01 {
  top: -14.6875vw;
  left: 20.8854166667vw;
  width: min(6.3020833333vw, 7.5625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-people-01 {
    top: -4.5893719807vw;
    left: 4.347826087vw;
    z-index: 2;
    width: 13.5265700483vw;
  }
}
.home_news .deco-people-02 {
  bottom: -27.5vw;
  left: 50%;
  transform: translateX(-50%);
  width: min(26.7708333333vw, 32.125rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-people-02 {
    bottom: -56.2801932367vw;
    width: 42.9951690821vw;
  }
}
.home_news .deco-people-03 {
  bottom: -5.9895833333vw;
  right: 4.1666666667vw;
  z-index: 2;
  width: min(11.1458333333vw, 13.375rem);
}
@media screen and (max-width: 47.9375em) {
  .home_news .deco-people-03 {
    width: 19.3236714976vw;
  }
}

/* home_about
********************************************** */
.home_about .deco-people-04 {
  bottom: -3.0208333333vw;
  right: 0;
  width: min(5.8854166667vw, 7.0625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_about .deco-people-04 {
    bottom: -21.9806763285vw;
    right: -2.4154589372vw;
    width: 14.7342995169vw;
  }
}
.home_about .deco-speaking-about {
  bottom: 2.8125vw;
}
@media screen and (max-width: 47.9375em) {
  .home_about .deco-speaking-about {
    bottom: -5.0724637681vw;
  }
}
.home_about .deco-speaking--txt {
  top: 40%;
}
.home_about .deco_img {
  bottom: -9.0104166667vw;
  left: -5.625vw;
  border-radius: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_about .deco_img {
    bottom: -27.7777777778vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about .deco_img img {
    width: 27.7777777778vw;
    aspect-ratio: 240/305;
  }
}
.home_about .deco-cloud-03 {
  top: 11.71875vw;
  left: -1.5625vw;
  width: min(15.625vw, 18.75rem);
}

/* home_service
********************************************** */
.home_service .deco {
  z-index: 1;
}
.home_service .deco-cloud-03 {
  bottom: 5.1041666667vw;
  right: -1.0416666667vw;
  z-index: 0;
  width: min(11.9791666667vw, 14.375rem);
}
@media screen and (max-width: 47.9375em) {
  .home_service .deco-cloud-03 {
    width: 16.9082125604vw;
  }
}
.home_service .deco-people-02-2 {
  bottom: 9.1145833333vw;
  right: 0;
  width: min(17.5vw, 21rem);
}
@media screen and (max-width: 47.9375em) {
  .home_service .deco-people-02-2 {
    display: none;
  }
}
.home_service .deco-people-05 {
  bottom: 8.125vw;
  right: -0.46875vw;
  width: min(14.5833333333vw, 17.5rem);
}
@media screen and (max-width: 47.9375em) {
  .home_service .deco-people-05 {
    bottom: 28.0193236715vw;
    right: -3.3816425121vw;
    width: 35.5072463768vw;
  }
}
.home_service .deco-people-03-3 {
  bottom: 3.75vw;
  right: 2.1875vw;
  width: min(16.7708333333vw, 20.125rem);
}
@media screen and (max-width: 47.9375em) {
  .home_service .deco-people-03-3 {
    bottom: 26.8115942029vw;
    right: -2.4154589372vw;
    width: 34.5410628019vw;
  }
}
.home_service .deco-people-06 {
  bottom: 2.6041666667vw;
  right: 2.8125vw;
  width: min(11.40625vw, 13.6875rem);
}
@media screen and (max-width: 47.9375em) {
  .home_service .deco-people-06 {
    bottom: 31.884057971vw;
    right: 2.4154589372vw;
    width: 22.4637681159vw;
  }
}
.home_service .home_service--contents .deco-dot_circle {
  z-index: 0;
}
.home_service .home_service--contents-home .deco-dot_circle {
  top: -1.09375vw;
  right: -2.65625vw;
  width: min(29.6354166667vw, 35.5625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_service .home_service--contents-home .deco-dot_circle {
    width: 33.8164251208vw;
  }
}
.home_service .home_service--contents-support .deco-dot_circle {
  top: 2.8645833333vw;
  right: -11.6666666667vw;
  width: min(22.96875vw, 27.5625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_service .home_service--contents-support .deco-dot_circle {
    top: 2.6570048309vw;
    width: 33.8164251208vw;
  }
}
.home_service .home_service--contents-short .deco-dot_circle {
  top: -2.0833333333vw;
  right: -9.6354166667vw;
  width: min(29.6354166667vw, 35.5625rem);
}
.home_service .home_service--contents-day .deco-dot_circle {
  top: -2.0833333333vw;
  right: -9.6354166667vw;
  width: min(29.6354166667vw, 35.5625rem);
}

/* home_feature
********************************************** */
.home_feature .deco {
  z-index: 20;
}
.home_feature .deco-cloud-03 {
  top: 4.5833333333vw;
  left: -1.25vw;
  width: min(15.1041666667vw, 18.125rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-cloud-03 {
    top: -4.347826087vw;
    width: 24.154589372vw;
  }
}
.home_feature .deco-cloud-05 {
  top: 4.5833333333vw;
  right: 47%;
  width: min(10.9375vw, 13.125rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-cloud-05 {
    width: 19.3236714976vw;
  }
}
.home_feature .home_feature_deco-subaru {
  top: -9.4270833333vw;
  right: -2.5520833333vw;
  width: min(57.7083333333vw, 69.25rem);
  z-index: 0;
  background-image: url(/wp/wp-content/themes/standard_sass/images/feature_subaru.png);
  aspect-ratio: 1108/283;
}
.home_feature .deco-round_tree-01 {
  top: 6.3541666667vw;
  right: -2.5520833333vw;
  width: min(4.6875vw, 5.625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-round_tree-01 {
    top: 8.2125603865vw;
    width: 6.2801932367vw;
  }
}
.home_feature .deco-round_tree-02 {
  top: 19.21875vw;
  right: 36.7708333333vw;
  width: min(4.21875vw, 5.0625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-round_tree-02 {
    top: 21.9806763285vw;
    right: 29.7101449275vw;
    width: 6.2801932367vw;
  }
}
.home_feature .deco-round_tree-03 {
  top: min(97.2916666667vw, 1868px);
  right: 28.6458333333vw;
  width: min(6.1458333333vw, 7.375rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-round_tree-03 {
    top: 210.1449275362vw;
    width: 12.077294686vw;
  }
}
.home_feature .deco-round_tree-04 {
  bottom: 10vw;
  left: 23.0208333333vw;
  width: min(6.09375vw, 7.3125rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-round_tree-04 {
    bottom: 26.8115942029vw;
    left: 7.9710144928vw;
    width: 12.077294686vw;
  }
}
.home_feature .deco-triangle_tree-01 {
  top: 16.09375vw;
  left: 23.59375vw;
  width: min(1.71875vw, 2.0625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-01 {
    top: 20.0483091787vw;
    left: 15.4589371981vw;
    width: 3.1400966184vw;
  }
}
.home_feature .deco-triangle_tree-02 {
  top: 9.8958333333vw;
  right: 15.15625vw;
  width: min(2.0833333333vw, 2.5rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-02 {
    top: 10.8695652174vw;
    width: 3.1400966184vw;
  }
}
.home_feature .deco-triangle_tree-two-01 {
  top: 14.7395833333vw;
  left: 19.2708333333vw;
  width: min(3.6979166667vw, 4.4375rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-two-01 {
    top: 18.5990338164vw;
    left: 8.2125603865vw;
    width: 6.038647343vw;
  }
}
.home_feature .deco-triangle_tree-two-02 {
  top: 10.7291666667vw;
  left: 43.90625vw;
  width: min(5.15625vw, 6.1875rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-two-02 {
    width: 6.038647343vw;
  }
}
.home_feature .deco-triangle_tree-two-03 {
  top: min(26.6666666667vw, 512px);
  left: 13.9583333333vw;
  width: min(5.3645833333vw, 6.4375rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-two-03 {
    top: 31.6425120773vw;
    width: 7.2463768116vw;
  }
}
.home_feature .deco-triangle_tree-two-04 {
  top: 55.2083333333vw;
  left: 17.7604166667vw;
  width: min(5.8854166667vw, 7.0625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-two-04 {
    top: 54.1062801932vw;
    left: 23.6714975845vw;
    width: 9.6618357488vw;
  }
}
.home_feature .deco-triangle_tree-two-05 {
  bottom: 55%;
  left: -2.4479166667vw;
  width: min(6.6666666667vw, 8rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-two-05 {
    bottom: 66%;
    left: 30.4347826087vw;
    width: 12.077294686vw;
  }
}
.home_feature .deco-triangle_tree-two-06 {
  bottom: 13.3854166667vw;
  left: 44.9479166667vw;
  width: min(6.6666666667vw, 8rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-two-06 {
    bottom: 265.7004830918vw;
    left: 28.7439613527vw;
    width: 12.077294686vw;
  }
}
.home_feature .deco-triangle_tree-two-07 {
  bottom: min(72.9166666667vw, 1400px);
  left: 43.0208333333vw;
  width: min(5.7291666667vw, 6.875rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-two-07 {
    display: none;
  }
}
.home_feature .deco-triangle_tree-two-08 {
  bottom: 2.0833333333vw;
  right: 21.09375vw;
  width: min(7.6041666667vw, 9.125rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-triangle_tree-two-08 {
    bottom: 8.9371980676vw;
    width: 10.8695652174vw;
  }
}
.home_feature .deco-people-06 {
  top: min(55.5208333333vw, 1066px);
  left: min(5.3645833333vw, 103px);
  width: min(8.6979166667vw, 10.4375rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-people-06 {
    width: 14.4927536232vw;
  }
}
.home_feature .deco-people-07 {
  top: min(24.6875vw, 474px);
  left: 27.1354166667vw;
  width: min(5.0520833333vw, 6.0625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-people-07 {
    width: 7.2463768116vw;
  }
}
.home_feature .deco-people-08 {
  top: min(94.4270833333vw, 1813px);
  right: 8.75vw;
  width: min(17.0833333333vw, 20.5rem);
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-people-08 {
    top: 268.3574879227vw;
    width: 28.9855072464vw;
  }
}
.home_feature .deco-people-03-3 {
  bottom: max(200px, 13.3333333333vw);
  left: 4.3229166667vw;
  width: min(17.8645833333vw, 21.4375rem);
}
@media print, screen and (min-width: 125.0625em) {
  .home_feature .deco-people-03-3 {
    bottom: 570px;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_feature .deco-people-03-3 {
    bottom: 150px;
    left: 60.1449275362vw;
    width: min(150px, 24.154589372vw);
  }
}

/* home_facility
********************************************** */
.home_facility .deco-people-05 {
  bottom: -3.59375vw;
  z-index: 2;
  width: min(11.7708333333vw, 14.125rem);
}
@media print, screen and (min-width: 48em) {
  .home_facility .deco-people-05 {
    right: 38.8020833333vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_facility .deco-people-05 {
    bottom: -19.3236714976vw;
    left: 9.6618357488vw;
    width: 24.154589372vw;
  }
}
.home_facility .deco-people-10 {
  bottom: -3.59375vw;
  left: 7.5vw;
  z-index: 2;
  width: min(7.5520833333vw, 9.0625rem);
}
@media screen and (max-width: 47.9375em) {
  .home_facility .deco-people-10 {
    bottom: 40%;
    width: 19.3236714976vw;
  }
}

/* home_voice
********************************************** */
.home_voice .deco-triangle_tree-two-01 {
  bottom: -5.3125vw;
  right: 12.65625vw;
  width: min(10.15625vw, 12.1875rem);
}
@media screen and (max-width: 47.9375em) {
  .home_voice .deco-triangle_tree-two-01 {
    bottom: -14.4927536232vw;
    width: 16.9082125604vw;
  }
}
.home_voice .deco-triangle_tree-two-02 {
  bottom: -13.5416666667vw;
  right: -0.2604166667vw;
  width: min(10.1041666667vw, 12.125rem);
}
@media screen and (max-width: 47.9375em) {
  .home_voice .deco-triangle_tree-two-02 {
    bottom: -33.8164251208vw;
    width: 16.9082125604vw;
  }
}
.home_voice .deco-people-08 {
  top: -4.9479166667vw;
  left: 9.1145833333vw;
  z-index: 2;
  width: min(19.7916666667vw, 23.75rem);
}
@media screen and (max-width: 47.9375em) {
  .home_voice .deco-people-08 {
    top: 110%;
    width: 28.9855072464vw;
  }
}

/* cta
********************************************** */
.cta .deco-people-03-3 {
  bottom: -0.2604166667vw;
  right: 2.2916666667vw;
  width: min(15.625vw, 18.75rem);
}
@media screen and (max-width: 47.9375em) {
  .cta .deco-people-03-3 {
    width: 21.7391304348vw;
  }
}
.cta .deco-people-09 {
  bottom: -5.2083333333vw;
  right: 5.78125vw;
  width: min(7.8125vw, 9.375rem);
}
@media screen and (max-width: 47.9375em) {
  .cta .deco-people-09 {
    width: 12.077294686vw;
  }
}

/* footer
********************************************** */
.footer-01 .deco-round_tree-01 {
  top: 2.5vw;
  left: 1.3020833333vw;
  width: min(5.5208333333vw, 6.625rem);
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .deco-round_tree-01 {
    top: 31%;
    left: 6.2801932367vw;
    width: 12.077294686vw;
  }
}
.footer-01 .deco-round_tree-02 {
  bottom: -9.1145833333vw;
  left: 20.9895833333vw;
  width: min(10.9895833333vw, 13.1875rem);
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .deco-round_tree-02 {
    width: 15.7004830918vw;
  }
}
.footer-01 .deco-triangle_tree-two-01 {
  top: 8.5416666667vw;
  right: 9.7395833333vw;
  width: min(5.6770833333vw, 6.8125rem);
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .deco-triangle_tree-two-01 {
    top: -0.4830917874vw;
    right: 2.6570048309vw;
    width: 9.6618357488vw;
  }
}
.footer-01 .deco-triangle_tree-two-02 {
  bottom: 4.7916666667vw;
  right: -2.2395833333vw;
  width: min(11.5104166667vw, 13.8125rem);
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .deco-triangle_tree-two-02 {
    width: 15.7004830918vw;
  }
}
.footer-01 .deco-triangle_tree-two-03 {
  bottom: 0;
  left: -1.9270833333vw;
  width: min(10.4166666667vw, 12.5rem);
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .deco-triangle_tree-two-03 {
    width: 15.7004830918vw;
  }
}
.footer-01 .deco-triangle_tree-two-04 {
  bottom: -7.03125vw;
  right: 30.0520833333vw;
  width: min(9.5833333333vw, 11.5rem);
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .deco-triangle_tree-two-04 {
    width: 12.077294686vw;
  }
}
.footer-01 .deco-people-08 {
  top: 9.4270833333vw;
  right: 22.65625vw;
  width: min(12.4479166667vw, 14.9375rem);
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .deco-people-08 {
    width: 24.154589372vw;
  }
}
.footer-01 .deco-people-10 {
  bottom: 19.3229166667vw;
  left: 6.0416666667vw;
  width: min(6.875vw, 8.25rem);
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .deco-people-10 {
    bottom: 64%;
    left: 72.9468599034vw;
    width: 14.4927536232vw;
  }
}

/* --- section_pdg --- */
.section_pdg {
  padding-block: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg {
    padding-block: 2.8571428571rem;
  }
}

.section_pdg-sm {
  padding-block: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-sm {
    padding-block: 1.7142857143rem;
  }
}

/* --- blc:not(:last-child) --- */
.blc:not(:last-child) {
  margin-bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .blc:not(:last-child) {
    margin-bottom: 2.8571428571rem;
  }
}

.blc-sm:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .blc-sm:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .inner {
    width: 86%;
  }
}

.inner-sm {
  max-width: 45rem;
}

.inner-lg {
  max-width: 91rem;
}

.inner-xl {
  max-width: 110rem;
}

.inner-700 {
  max-width: 43.75rem;
}

.inner-701 {
  max-width: 43.8125rem;
}

.inner-702 {
  max-width: 43.875rem;
}

.inner-703 {
  max-width: 43.9375rem;
}

.inner-704 {
  max-width: 44rem;
}

.inner-705 {
  max-width: 44.0625rem;
}

.inner-706 {
  max-width: 44.125rem;
}

.inner-707 {
  max-width: 44.1875rem;
}

.inner-708 {
  max-width: 44.25rem;
}

.inner-709 {
  max-width: 44.3125rem;
}

.inner-710 {
  max-width: 44.375rem;
}

.inner-711 {
  max-width: 44.4375rem;
}

.inner-712 {
  max-width: 44.5rem;
}

.inner-713 {
  max-width: 44.5625rem;
}

.inner-714 {
  max-width: 44.625rem;
}

.inner-715 {
  max-width: 44.6875rem;
}

.inner-716 {
  max-width: 44.75rem;
}

.inner-717 {
  max-width: 44.8125rem;
}

.inner-718 {
  max-width: 44.875rem;
}

.inner-719 {
  max-width: 44.9375rem;
}

.inner-720 {
  max-width: 45rem;
}

.inner-721 {
  max-width: 45.0625rem;
}

.inner-722 {
  max-width: 45.125rem;
}

.inner-723 {
  max-width: 45.1875rem;
}

.inner-724 {
  max-width: 45.25rem;
}

.inner-725 {
  max-width: 45.3125rem;
}

.inner-726 {
  max-width: 45.375rem;
}

.inner-727 {
  max-width: 45.4375rem;
}

.inner-728 {
  max-width: 45.5rem;
}

.inner-729 {
  max-width: 45.5625rem;
}

.inner-730 {
  max-width: 45.625rem;
}

.inner-731 {
  max-width: 45.6875rem;
}

.inner-732 {
  max-width: 45.75rem;
}

.inner-733 {
  max-width: 45.8125rem;
}

.inner-734 {
  max-width: 45.875rem;
}

.inner-735 {
  max-width: 45.9375rem;
}

.inner-736 {
  max-width: 46rem;
}

.inner-737 {
  max-width: 46.0625rem;
}

.inner-738 {
  max-width: 46.125rem;
}

.inner-739 {
  max-width: 46.1875rem;
}

.inner-740 {
  max-width: 46.25rem;
}

.inner-741 {
  max-width: 46.3125rem;
}

.inner-742 {
  max-width: 46.375rem;
}

.inner-743 {
  max-width: 46.4375rem;
}

.inner-744 {
  max-width: 46.5rem;
}

.inner-745 {
  max-width: 46.5625rem;
}

.inner-746 {
  max-width: 46.625rem;
}

.inner-747 {
  max-width: 46.6875rem;
}

.inner-748 {
  max-width: 46.75rem;
}

.inner-749 {
  max-width: 46.8125rem;
}

.inner-750 {
  max-width: 46.875rem;
}

.inner-751 {
  max-width: 46.9375rem;
}

.inner-752 {
  max-width: 47rem;
}

.inner-753 {
  max-width: 47.0625rem;
}

.inner-754 {
  max-width: 47.125rem;
}

.inner-755 {
  max-width: 47.1875rem;
}

.inner-756 {
  max-width: 47.25rem;
}

.inner-757 {
  max-width: 47.3125rem;
}

.inner-758 {
  max-width: 47.375rem;
}

.inner-759 {
  max-width: 47.4375rem;
}

.inner-760 {
  max-width: 47.5rem;
}

.inner-761 {
  max-width: 47.5625rem;
}

.inner-762 {
  max-width: 47.625rem;
}

.inner-763 {
  max-width: 47.6875rem;
}

.inner-764 {
  max-width: 47.75rem;
}

.inner-765 {
  max-width: 47.8125rem;
}

.inner-766 {
  max-width: 47.875rem;
}

.inner-767 {
  max-width: 47.9375rem;
}

.inner-768 {
  max-width: 48rem;
}

.inner-769 {
  max-width: 48.0625rem;
}

.inner-770 {
  max-width: 48.125rem;
}

.inner-771 {
  max-width: 48.1875rem;
}

.inner-772 {
  max-width: 48.25rem;
}

.inner-773 {
  max-width: 48.3125rem;
}

.inner-774 {
  max-width: 48.375rem;
}

.inner-775 {
  max-width: 48.4375rem;
}

.inner-776 {
  max-width: 48.5rem;
}

.inner-777 {
  max-width: 48.5625rem;
}

.inner-778 {
  max-width: 48.625rem;
}

.inner-779 {
  max-width: 48.6875rem;
}

.inner-780 {
  max-width: 48.75rem;
}

.inner-781 {
  max-width: 48.8125rem;
}

.inner-782 {
  max-width: 48.875rem;
}

.inner-783 {
  max-width: 48.9375rem;
}

.inner-784 {
  max-width: 49rem;
}

.inner-785 {
  max-width: 49.0625rem;
}

.inner-786 {
  max-width: 49.125rem;
}

.inner-787 {
  max-width: 49.1875rem;
}

.inner-788 {
  max-width: 49.25rem;
}

.inner-789 {
  max-width: 49.3125rem;
}

.inner-790 {
  max-width: 49.375rem;
}

.inner-791 {
  max-width: 49.4375rem;
}

.inner-792 {
  max-width: 49.5rem;
}

.inner-793 {
  max-width: 49.5625rem;
}

.inner-794 {
  max-width: 49.625rem;
}

.inner-795 {
  max-width: 49.6875rem;
}

.inner-796 {
  max-width: 49.75rem;
}

.inner-797 {
  max-width: 49.8125rem;
}

.inner-798 {
  max-width: 49.875rem;
}

.inner-799 {
  max-width: 49.9375rem;
}

.inner-800 {
  max-width: 50rem;
}

.inner-801 {
  max-width: 50.0625rem;
}

.inner-802 {
  max-width: 50.125rem;
}

.inner-803 {
  max-width: 50.1875rem;
}

.inner-804 {
  max-width: 50.25rem;
}

.inner-805 {
  max-width: 50.3125rem;
}

.inner-806 {
  max-width: 50.375rem;
}

.inner-807 {
  max-width: 50.4375rem;
}

.inner-808 {
  max-width: 50.5rem;
}

.inner-809 {
  max-width: 50.5625rem;
}

.inner-810 {
  max-width: 50.625rem;
}

.inner-811 {
  max-width: 50.6875rem;
}

.inner-812 {
  max-width: 50.75rem;
}

.inner-813 {
  max-width: 50.8125rem;
}

.inner-814 {
  max-width: 50.875rem;
}

.inner-815 {
  max-width: 50.9375rem;
}

.inner-816 {
  max-width: 51rem;
}

.inner-817 {
  max-width: 51.0625rem;
}

.inner-818 {
  max-width: 51.125rem;
}

.inner-819 {
  max-width: 51.1875rem;
}

.inner-820 {
  max-width: 51.25rem;
}

.inner-821 {
  max-width: 51.3125rem;
}

.inner-822 {
  max-width: 51.375rem;
}

.inner-823 {
  max-width: 51.4375rem;
}

.inner-824 {
  max-width: 51.5rem;
}

.inner-825 {
  max-width: 51.5625rem;
}

.inner-826 {
  max-width: 51.625rem;
}

.inner-827 {
  max-width: 51.6875rem;
}

.inner-828 {
  max-width: 51.75rem;
}

.inner-829 {
  max-width: 51.8125rem;
}

.inner-830 {
  max-width: 51.875rem;
}

.inner-831 {
  max-width: 51.9375rem;
}

.inner-832 {
  max-width: 52rem;
}

.inner-833 {
  max-width: 52.0625rem;
}

.inner-834 {
  max-width: 52.125rem;
}

.inner-835 {
  max-width: 52.1875rem;
}

.inner-836 {
  max-width: 52.25rem;
}

.inner-837 {
  max-width: 52.3125rem;
}

.inner-838 {
  max-width: 52.375rem;
}

.inner-839 {
  max-width: 52.4375rem;
}

.inner-840 {
  max-width: 52.5rem;
}

.inner-841 {
  max-width: 52.5625rem;
}

.inner-842 {
  max-width: 52.625rem;
}

.inner-843 {
  max-width: 52.6875rem;
}

.inner-844 {
  max-width: 52.75rem;
}

.inner-845 {
  max-width: 52.8125rem;
}

.inner-846 {
  max-width: 52.875rem;
}

.inner-847 {
  max-width: 52.9375rem;
}

.inner-848 {
  max-width: 53rem;
}

.inner-849 {
  max-width: 53.0625rem;
}

.inner-850 {
  max-width: 53.125rem;
}

.inner-851 {
  max-width: 53.1875rem;
}

.inner-852 {
  max-width: 53.25rem;
}

.inner-853 {
  max-width: 53.3125rem;
}

.inner-854 {
  max-width: 53.375rem;
}

.inner-855 {
  max-width: 53.4375rem;
}

.inner-856 {
  max-width: 53.5rem;
}

.inner-857 {
  max-width: 53.5625rem;
}

.inner-858 {
  max-width: 53.625rem;
}

.inner-859 {
  max-width: 53.6875rem;
}

.inner-860 {
  max-width: 53.75rem;
}

.inner-861 {
  max-width: 53.8125rem;
}

.inner-862 {
  max-width: 53.875rem;
}

.inner-863 {
  max-width: 53.9375rem;
}

.inner-864 {
  max-width: 54rem;
}

.inner-865 {
  max-width: 54.0625rem;
}

.inner-866 {
  max-width: 54.125rem;
}

.inner-867 {
  max-width: 54.1875rem;
}

.inner-868 {
  max-width: 54.25rem;
}

.inner-869 {
  max-width: 54.3125rem;
}

.inner-870 {
  max-width: 54.375rem;
}

.inner-871 {
  max-width: 54.4375rem;
}

.inner-872 {
  max-width: 54.5rem;
}

.inner-873 {
  max-width: 54.5625rem;
}

.inner-874 {
  max-width: 54.625rem;
}

.inner-875 {
  max-width: 54.6875rem;
}

.inner-876 {
  max-width: 54.75rem;
}

.inner-877 {
  max-width: 54.8125rem;
}

.inner-878 {
  max-width: 54.875rem;
}

.inner-879 {
  max-width: 54.9375rem;
}

.inner-880 {
  max-width: 55rem;
}

.inner-881 {
  max-width: 55.0625rem;
}

.inner-882 {
  max-width: 55.125rem;
}

.inner-883 {
  max-width: 55.1875rem;
}

.inner-884 {
  max-width: 55.25rem;
}

.inner-885 {
  max-width: 55.3125rem;
}

.inner-886 {
  max-width: 55.375rem;
}

.inner-887 {
  max-width: 55.4375rem;
}

.inner-888 {
  max-width: 55.5rem;
}

.inner-889 {
  max-width: 55.5625rem;
}

.inner-890 {
  max-width: 55.625rem;
}

.inner-891 {
  max-width: 55.6875rem;
}

.inner-892 {
  max-width: 55.75rem;
}

.inner-893 {
  max-width: 55.8125rem;
}

.inner-894 {
  max-width: 55.875rem;
}

.inner-895 {
  max-width: 55.9375rem;
}

.inner-896 {
  max-width: 56rem;
}

.inner-897 {
  max-width: 56.0625rem;
}

.inner-898 {
  max-width: 56.125rem;
}

.inner-899 {
  max-width: 56.1875rem;
}

.inner-900 {
  max-width: 56.25rem;
}

.inner-901 {
  max-width: 56.3125rem;
}

.inner-902 {
  max-width: 56.375rem;
}

.inner-903 {
  max-width: 56.4375rem;
}

.inner-904 {
  max-width: 56.5rem;
}

.inner-905 {
  max-width: 56.5625rem;
}

.inner-906 {
  max-width: 56.625rem;
}

.inner-907 {
  max-width: 56.6875rem;
}

.inner-908 {
  max-width: 56.75rem;
}

.inner-909 {
  max-width: 56.8125rem;
}

.inner-910 {
  max-width: 56.875rem;
}

.inner-911 {
  max-width: 56.9375rem;
}

.inner-912 {
  max-width: 57rem;
}

.inner-913 {
  max-width: 57.0625rem;
}

.inner-914 {
  max-width: 57.125rem;
}

.inner-915 {
  max-width: 57.1875rem;
}

.inner-916 {
  max-width: 57.25rem;
}

.inner-917 {
  max-width: 57.3125rem;
}

.inner-918 {
  max-width: 57.375rem;
}

.inner-919 {
  max-width: 57.4375rem;
}

.inner-920 {
  max-width: 57.5rem;
}

.inner-921 {
  max-width: 57.5625rem;
}

.inner-922 {
  max-width: 57.625rem;
}

.inner-923 {
  max-width: 57.6875rem;
}

.inner-924 {
  max-width: 57.75rem;
}

.inner-925 {
  max-width: 57.8125rem;
}

.inner-926 {
  max-width: 57.875rem;
}

.inner-927 {
  max-width: 57.9375rem;
}

.inner-928 {
  max-width: 58rem;
}

.inner-929 {
  max-width: 58.0625rem;
}

.inner-930 {
  max-width: 58.125rem;
}

.inner-931 {
  max-width: 58.1875rem;
}

.inner-932 {
  max-width: 58.25rem;
}

.inner-933 {
  max-width: 58.3125rem;
}

.inner-934 {
  max-width: 58.375rem;
}

.inner-935 {
  max-width: 58.4375rem;
}

.inner-936 {
  max-width: 58.5rem;
}

.inner-937 {
  max-width: 58.5625rem;
}

.inner-938 {
  max-width: 58.625rem;
}

.inner-939 {
  max-width: 58.6875rem;
}

.inner-940 {
  max-width: 58.75rem;
}

.inner-941 {
  max-width: 58.8125rem;
}

.inner-942 {
  max-width: 58.875rem;
}

.inner-943 {
  max-width: 58.9375rem;
}

.inner-944 {
  max-width: 59rem;
}

.inner-945 {
  max-width: 59.0625rem;
}

.inner-946 {
  max-width: 59.125rem;
}

.inner-947 {
  max-width: 59.1875rem;
}

.inner-948 {
  max-width: 59.25rem;
}

.inner-949 {
  max-width: 59.3125rem;
}

.inner-950 {
  max-width: 59.375rem;
}

.inner-951 {
  max-width: 59.4375rem;
}

.inner-952 {
  max-width: 59.5rem;
}

.inner-953 {
  max-width: 59.5625rem;
}

.inner-954 {
  max-width: 59.625rem;
}

.inner-955 {
  max-width: 59.6875rem;
}

.inner-956 {
  max-width: 59.75rem;
}

.inner-957 {
  max-width: 59.8125rem;
}

.inner-958 {
  max-width: 59.875rem;
}

.inner-959 {
  max-width: 59.9375rem;
}

.inner-960 {
  max-width: 60rem;
}

.inner-961 {
  max-width: 60.0625rem;
}

.inner-962 {
  max-width: 60.125rem;
}

.inner-963 {
  max-width: 60.1875rem;
}

.inner-964 {
  max-width: 60.25rem;
}

.inner-965 {
  max-width: 60.3125rem;
}

.inner-966 {
  max-width: 60.375rem;
}

.inner-967 {
  max-width: 60.4375rem;
}

.inner-968 {
  max-width: 60.5rem;
}

.inner-969 {
  max-width: 60.5625rem;
}

.inner-970 {
  max-width: 60.625rem;
}

.inner-971 {
  max-width: 60.6875rem;
}

.inner-972 {
  max-width: 60.75rem;
}

.inner-973 {
  max-width: 60.8125rem;
}

.inner-974 {
  max-width: 60.875rem;
}

.inner-975 {
  max-width: 60.9375rem;
}

.inner-976 {
  max-width: 61rem;
}

.inner-977 {
  max-width: 61.0625rem;
}

.inner-978 {
  max-width: 61.125rem;
}

.inner-979 {
  max-width: 61.1875rem;
}

.inner-980 {
  max-width: 61.25rem;
}

.inner-981 {
  max-width: 61.3125rem;
}

.inner-982 {
  max-width: 61.375rem;
}

.inner-983 {
  max-width: 61.4375rem;
}

.inner-984 {
  max-width: 61.5rem;
}

.inner-985 {
  max-width: 61.5625rem;
}

.inner-986 {
  max-width: 61.625rem;
}

.inner-987 {
  max-width: 61.6875rem;
}

.inner-988 {
  max-width: 61.75rem;
}

.inner-989 {
  max-width: 61.8125rem;
}

.inner-990 {
  max-width: 61.875rem;
}

.inner-991 {
  max-width: 61.9375rem;
}

.inner-992 {
  max-width: 62rem;
}

.inner-993 {
  max-width: 62.0625rem;
}

.inner-994 {
  max-width: 62.125rem;
}

.inner-995 {
  max-width: 62.1875rem;
}

.inner-996 {
  max-width: 62.25rem;
}

.inner-997 {
  max-width: 62.3125rem;
}

.inner-998 {
  max-width: 62.375rem;
}

.inner-999 {
  max-width: 62.4375rem;
}

.inner-1000 {
  max-width: 62.5rem;
}

.inner-1001 {
  max-width: 62.5625rem;
}

.inner-1002 {
  max-width: 62.625rem;
}

.inner-1003 {
  max-width: 62.6875rem;
}

.inner-1004 {
  max-width: 62.75rem;
}

.inner-1005 {
  max-width: 62.8125rem;
}

.inner-1006 {
  max-width: 62.875rem;
}

.inner-1007 {
  max-width: 62.9375rem;
}

.inner-1008 {
  max-width: 63rem;
}

.inner-1009 {
  max-width: 63.0625rem;
}

.inner-1010 {
  max-width: 63.125rem;
}

.inner-1011 {
  max-width: 63.1875rem;
}

.inner-1012 {
  max-width: 63.25rem;
}

.inner-1013 {
  max-width: 63.3125rem;
}

.inner-1014 {
  max-width: 63.375rem;
}

.inner-1015 {
  max-width: 63.4375rem;
}

.inner-1016 {
  max-width: 63.5rem;
}

.inner-1017 {
  max-width: 63.5625rem;
}

.inner-1018 {
  max-width: 63.625rem;
}

.inner-1019 {
  max-width: 63.6875rem;
}

.inner-1020 {
  max-width: 63.75rem;
}

.inner-1021 {
  max-width: 63.8125rem;
}

.inner-1022 {
  max-width: 63.875rem;
}

.inner-1023 {
  max-width: 63.9375rem;
}

.inner-1024 {
  max-width: 64rem;
}

.inner-1025 {
  max-width: 64.0625rem;
}

.inner-1026 {
  max-width: 64.125rem;
}

.inner-1027 {
  max-width: 64.1875rem;
}

.inner-1028 {
  max-width: 64.25rem;
}

.inner-1029 {
  max-width: 64.3125rem;
}

.inner-1030 {
  max-width: 64.375rem;
}

.inner-1031 {
  max-width: 64.4375rem;
}

.inner-1032 {
  max-width: 64.5rem;
}

.inner-1033 {
  max-width: 64.5625rem;
}

.inner-1034 {
  max-width: 64.625rem;
}

.inner-1035 {
  max-width: 64.6875rem;
}

.inner-1036 {
  max-width: 64.75rem;
}

.inner-1037 {
  max-width: 64.8125rem;
}

.inner-1038 {
  max-width: 64.875rem;
}

.inner-1039 {
  max-width: 64.9375rem;
}

.inner-1040 {
  max-width: 65rem;
}

.inner-1041 {
  max-width: 65.0625rem;
}

.inner-1042 {
  max-width: 65.125rem;
}

.inner-1043 {
  max-width: 65.1875rem;
}

.inner-1044 {
  max-width: 65.25rem;
}

.inner-1045 {
  max-width: 65.3125rem;
}

.inner-1046 {
  max-width: 65.375rem;
}

.inner-1047 {
  max-width: 65.4375rem;
}

.inner-1048 {
  max-width: 65.5rem;
}

.inner-1049 {
  max-width: 65.5625rem;
}

.inner-1050 {
  max-width: 65.625rem;
}

.inner-1051 {
  max-width: 65.6875rem;
}

.inner-1052 {
  max-width: 65.75rem;
}

.inner-1053 {
  max-width: 65.8125rem;
}

.inner-1054 {
  max-width: 65.875rem;
}

.inner-1055 {
  max-width: 65.9375rem;
}

.inner-1056 {
  max-width: 66rem;
}

.inner-1057 {
  max-width: 66.0625rem;
}

.inner-1058 {
  max-width: 66.125rem;
}

.inner-1059 {
  max-width: 66.1875rem;
}

.inner-1060 {
  max-width: 66.25rem;
}

.inner-1061 {
  max-width: 66.3125rem;
}

.inner-1062 {
  max-width: 66.375rem;
}

.inner-1063 {
  max-width: 66.4375rem;
}

.inner-1064 {
  max-width: 66.5rem;
}

.inner-1065 {
  max-width: 66.5625rem;
}

.inner-1066 {
  max-width: 66.625rem;
}

.inner-1067 {
  max-width: 66.6875rem;
}

.inner-1068 {
  max-width: 66.75rem;
}

.inner-1069 {
  max-width: 66.8125rem;
}

.inner-1070 {
  max-width: 66.875rem;
}

.inner-1071 {
  max-width: 66.9375rem;
}

.inner-1072 {
  max-width: 67rem;
}

.inner-1073 {
  max-width: 67.0625rem;
}

.inner-1074 {
  max-width: 67.125rem;
}

.inner-1075 {
  max-width: 67.1875rem;
}

.inner-1076 {
  max-width: 67.25rem;
}

.inner-1077 {
  max-width: 67.3125rem;
}

.inner-1078 {
  max-width: 67.375rem;
}

.inner-1079 {
  max-width: 67.4375rem;
}

.inner-1080 {
  max-width: 67.5rem;
}

.inner-1081 {
  max-width: 67.5625rem;
}

.inner-1082 {
  max-width: 67.625rem;
}

.inner-1083 {
  max-width: 67.6875rem;
}

.inner-1084 {
  max-width: 67.75rem;
}

.inner-1085 {
  max-width: 67.8125rem;
}

.inner-1086 {
  max-width: 67.875rem;
}

.inner-1087 {
  max-width: 67.9375rem;
}

.inner-1088 {
  max-width: 68rem;
}

.inner-1089 {
  max-width: 68.0625rem;
}

.inner-1090 {
  max-width: 68.125rem;
}

.inner-1091 {
  max-width: 68.1875rem;
}

.inner-1092 {
  max-width: 68.25rem;
}

.inner-1093 {
  max-width: 68.3125rem;
}

.inner-1094 {
  max-width: 68.375rem;
}

.inner-1095 {
  max-width: 68.4375rem;
}

.inner-1096 {
  max-width: 68.5rem;
}

.inner-1097 {
  max-width: 68.5625rem;
}

.inner-1098 {
  max-width: 68.625rem;
}

.inner-1099 {
  max-width: 68.6875rem;
}

.inner-1100 {
  max-width: 68.75rem;
}

.inner-1101 {
  max-width: 68.8125rem;
}

.inner-1102 {
  max-width: 68.875rem;
}

.inner-1103 {
  max-width: 68.9375rem;
}

.inner-1104 {
  max-width: 69rem;
}

.inner-1105 {
  max-width: 69.0625rem;
}

.inner-1106 {
  max-width: 69.125rem;
}

.inner-1107 {
  max-width: 69.1875rem;
}

.inner-1108 {
  max-width: 69.25rem;
}

.inner-1109 {
  max-width: 69.3125rem;
}

.inner-1110 {
  max-width: 69.375rem;
}

.inner-1111 {
  max-width: 69.4375rem;
}

.inner-1112 {
  max-width: 69.5rem;
}

.inner-1113 {
  max-width: 69.5625rem;
}

.inner-1114 {
  max-width: 69.625rem;
}

.inner-1115 {
  max-width: 69.6875rem;
}

.inner-1116 {
  max-width: 69.75rem;
}

.inner-1117 {
  max-width: 69.8125rem;
}

.inner-1118 {
  max-width: 69.875rem;
}

.inner-1119 {
  max-width: 69.9375rem;
}

.inner-1120 {
  max-width: 70rem;
}

.inner-1121 {
  max-width: 70.0625rem;
}

.inner-1122 {
  max-width: 70.125rem;
}

.inner-1123 {
  max-width: 70.1875rem;
}

.inner-1124 {
  max-width: 70.25rem;
}

.inner-1125 {
  max-width: 70.3125rem;
}

.inner-1126 {
  max-width: 70.375rem;
}

.inner-1127 {
  max-width: 70.4375rem;
}

.inner-1128 {
  max-width: 70.5rem;
}

.inner-1129 {
  max-width: 70.5625rem;
}

.inner-1130 {
  max-width: 70.625rem;
}

.inner-1131 {
  max-width: 70.6875rem;
}

.inner-1132 {
  max-width: 70.75rem;
}

.inner-1133 {
  max-width: 70.8125rem;
}

.inner-1134 {
  max-width: 70.875rem;
}

.inner-1135 {
  max-width: 70.9375rem;
}

.inner-1136 {
  max-width: 71rem;
}

.inner-1137 {
  max-width: 71.0625rem;
}

.inner-1138 {
  max-width: 71.125rem;
}

.inner-1139 {
  max-width: 71.1875rem;
}

.inner-1140 {
  max-width: 71.25rem;
}

.inner-1141 {
  max-width: 71.3125rem;
}

.inner-1142 {
  max-width: 71.375rem;
}

.inner-1143 {
  max-width: 71.4375rem;
}

.inner-1144 {
  max-width: 71.5rem;
}

.inner-1145 {
  max-width: 71.5625rem;
}

.inner-1146 {
  max-width: 71.625rem;
}

.inner-1147 {
  max-width: 71.6875rem;
}

.inner-1148 {
  max-width: 71.75rem;
}

.inner-1149 {
  max-width: 71.8125rem;
}

.inner-1150 {
  max-width: 71.875rem;
}

.inner-1151 {
  max-width: 71.9375rem;
}

.inner-1152 {
  max-width: 72rem;
}

.inner-1153 {
  max-width: 72.0625rem;
}

.inner-1154 {
  max-width: 72.125rem;
}

.inner-1155 {
  max-width: 72.1875rem;
}

.inner-1156 {
  max-width: 72.25rem;
}

.inner-1157 {
  max-width: 72.3125rem;
}

.inner-1158 {
  max-width: 72.375rem;
}

.inner-1159 {
  max-width: 72.4375rem;
}

.inner-1160 {
  max-width: 72.5rem;
}

.inner-1161 {
  max-width: 72.5625rem;
}

.inner-1162 {
  max-width: 72.625rem;
}

.inner-1163 {
  max-width: 72.6875rem;
}

.inner-1164 {
  max-width: 72.75rem;
}

.inner-1165 {
  max-width: 72.8125rem;
}

.inner-1166 {
  max-width: 72.875rem;
}

.inner-1167 {
  max-width: 72.9375rem;
}

.inner-1168 {
  max-width: 73rem;
}

.inner-1169 {
  max-width: 73.0625rem;
}

.inner-1170 {
  max-width: 73.125rem;
}

.inner-1171 {
  max-width: 73.1875rem;
}

.inner-1172 {
  max-width: 73.25rem;
}

.inner-1173 {
  max-width: 73.3125rem;
}

.inner-1174 {
  max-width: 73.375rem;
}

.inner-1175 {
  max-width: 73.4375rem;
}

.inner-1176 {
  max-width: 73.5rem;
}

.inner-1177 {
  max-width: 73.5625rem;
}

.inner-1178 {
  max-width: 73.625rem;
}

.inner-1179 {
  max-width: 73.6875rem;
}

.inner-1180 {
  max-width: 73.75rem;
}

.inner-1181 {
  max-width: 73.8125rem;
}

.inner-1182 {
  max-width: 73.875rem;
}

.inner-1183 {
  max-width: 73.9375rem;
}

.inner-1184 {
  max-width: 74rem;
}

.inner-1185 {
  max-width: 74.0625rem;
}

.inner-1186 {
  max-width: 74.125rem;
}

.inner-1187 {
  max-width: 74.1875rem;
}

.inner-1188 {
  max-width: 74.25rem;
}

.inner-1189 {
  max-width: 74.3125rem;
}

.inner-1190 {
  max-width: 74.375rem;
}

.inner-1191 {
  max-width: 74.4375rem;
}

.inner-1192 {
  max-width: 74.5rem;
}

.inner-1193 {
  max-width: 74.5625rem;
}

.inner-1194 {
  max-width: 74.625rem;
}

.inner-1195 {
  max-width: 74.6875rem;
}

.inner-1196 {
  max-width: 74.75rem;
}

.inner-1197 {
  max-width: 74.8125rem;
}

.inner-1198 {
  max-width: 74.875rem;
}

.inner-1199 {
  max-width: 74.9375rem;
}

.inner-1200 {
  max-width: 75rem;
}

.inner-1201 {
  max-width: 75.0625rem;
}

.inner-1202 {
  max-width: 75.125rem;
}

.inner-1203 {
  max-width: 75.1875rem;
}

.inner-1204 {
  max-width: 75.25rem;
}

.inner-1205 {
  max-width: 75.3125rem;
}

.inner-1206 {
  max-width: 75.375rem;
}

.inner-1207 {
  max-width: 75.4375rem;
}

.inner-1208 {
  max-width: 75.5rem;
}

.inner-1209 {
  max-width: 75.5625rem;
}

.inner-1210 {
  max-width: 75.625rem;
}

.inner-1211 {
  max-width: 75.6875rem;
}

.inner-1212 {
  max-width: 75.75rem;
}

.inner-1213 {
  max-width: 75.8125rem;
}

.inner-1214 {
  max-width: 75.875rem;
}

.inner-1215 {
  max-width: 75.9375rem;
}

.inner-1216 {
  max-width: 76rem;
}

.inner-1217 {
  max-width: 76.0625rem;
}

.inner-1218 {
  max-width: 76.125rem;
}

.inner-1219 {
  max-width: 76.1875rem;
}

.inner-1220 {
  max-width: 76.25rem;
}

.inner-1221 {
  max-width: 76.3125rem;
}

.inner-1222 {
  max-width: 76.375rem;
}

.inner-1223 {
  max-width: 76.4375rem;
}

.inner-1224 {
  max-width: 76.5rem;
}

.inner-1225 {
  max-width: 76.5625rem;
}

.inner-1226 {
  max-width: 76.625rem;
}

.inner-1227 {
  max-width: 76.6875rem;
}

.inner-1228 {
  max-width: 76.75rem;
}

.inner-1229 {
  max-width: 76.8125rem;
}

.inner-1230 {
  max-width: 76.875rem;
}

.inner-1231 {
  max-width: 76.9375rem;
}

.inner-1232 {
  max-width: 77rem;
}

.inner-1233 {
  max-width: 77.0625rem;
}

.inner-1234 {
  max-width: 77.125rem;
}

.inner-1235 {
  max-width: 77.1875rem;
}

.inner-1236 {
  max-width: 77.25rem;
}

.inner-1237 {
  max-width: 77.3125rem;
}

.inner-1238 {
  max-width: 77.375rem;
}

.inner-1239 {
  max-width: 77.4375rem;
}

.inner-1240 {
  max-width: 77.5rem;
}

.inner-1241 {
  max-width: 77.5625rem;
}

.inner-1242 {
  max-width: 77.625rem;
}

.inner-1243 {
  max-width: 77.6875rem;
}

.inner-1244 {
  max-width: 77.75rem;
}

.inner-1245 {
  max-width: 77.8125rem;
}

.inner-1246 {
  max-width: 77.875rem;
}

.inner-1247 {
  max-width: 77.9375rem;
}

.inner-1248 {
  max-width: 78rem;
}

.inner-1249 {
  max-width: 78.0625rem;
}

.inner-1250 {
  max-width: 78.125rem;
}

.inner-1251 {
  max-width: 78.1875rem;
}

.inner-1252 {
  max-width: 78.25rem;
}

.inner-1253 {
  max-width: 78.3125rem;
}

.inner-1254 {
  max-width: 78.375rem;
}

.inner-1255 {
  max-width: 78.4375rem;
}

.inner-1256 {
  max-width: 78.5rem;
}

.inner-1257 {
  max-width: 78.5625rem;
}

.inner-1258 {
  max-width: 78.625rem;
}

.inner-1259 {
  max-width: 78.6875rem;
}

.inner-1260 {
  max-width: 78.75rem;
}

.inner-1261 {
  max-width: 78.8125rem;
}

.inner-1262 {
  max-width: 78.875rem;
}

.inner-1263 {
  max-width: 78.9375rem;
}

.inner-1264 {
  max-width: 79rem;
}

.inner-1265 {
  max-width: 79.0625rem;
}

.inner-1266 {
  max-width: 79.125rem;
}

.inner-1267 {
  max-width: 79.1875rem;
}

.inner-1268 {
  max-width: 79.25rem;
}

.inner-1269 {
  max-width: 79.3125rem;
}

.inner-1270 {
  max-width: 79.375rem;
}

.inner-1271 {
  max-width: 79.4375rem;
}

.inner-1272 {
  max-width: 79.5rem;
}

.inner-1273 {
  max-width: 79.5625rem;
}

.inner-1274 {
  max-width: 79.625rem;
}

.inner-1275 {
  max-width: 79.6875rem;
}

.inner-1276 {
  max-width: 79.75rem;
}

.inner-1277 {
  max-width: 79.8125rem;
}

.inner-1278 {
  max-width: 79.875rem;
}

.inner-1279 {
  max-width: 79.9375rem;
}

.inner-1280 {
  max-width: 80rem;
}

.inner-1281 {
  max-width: 80.0625rem;
}

.inner-1282 {
  max-width: 80.125rem;
}

.inner-1283 {
  max-width: 80.1875rem;
}

.inner-1284 {
  max-width: 80.25rem;
}

.inner-1285 {
  max-width: 80.3125rem;
}

.inner-1286 {
  max-width: 80.375rem;
}

.inner-1287 {
  max-width: 80.4375rem;
}

.inner-1288 {
  max-width: 80.5rem;
}

.inner-1289 {
  max-width: 80.5625rem;
}

.inner-1290 {
  max-width: 80.625rem;
}

.inner-1291 {
  max-width: 80.6875rem;
}

.inner-1292 {
  max-width: 80.75rem;
}

.inner-1293 {
  max-width: 80.8125rem;
}

.inner-1294 {
  max-width: 80.875rem;
}

.inner-1295 {
  max-width: 80.9375rem;
}

.inner-1296 {
  max-width: 81rem;
}

.inner-1297 {
  max-width: 81.0625rem;
}

.inner-1298 {
  max-width: 81.125rem;
}

.inner-1299 {
  max-width: 81.1875rem;
}

.inner-1300 {
  max-width: 81.25rem;
}

.inner-1301 {
  max-width: 81.3125rem;
}

.inner-1302 {
  max-width: 81.375rem;
}

.inner-1303 {
  max-width: 81.4375rem;
}

.inner-1304 {
  max-width: 81.5rem;
}

.inner-1305 {
  max-width: 81.5625rem;
}

.inner-1306 {
  max-width: 81.625rem;
}

.inner-1307 {
  max-width: 81.6875rem;
}

.inner-1308 {
  max-width: 81.75rem;
}

.inner-1309 {
  max-width: 81.8125rem;
}

.inner-1310 {
  max-width: 81.875rem;
}

.inner-1311 {
  max-width: 81.9375rem;
}

.inner-1312 {
  max-width: 82rem;
}

.inner-1313 {
  max-width: 82.0625rem;
}

.inner-1314 {
  max-width: 82.125rem;
}

.inner-1315 {
  max-width: 82.1875rem;
}

.inner-1316 {
  max-width: 82.25rem;
}

.inner-1317 {
  max-width: 82.3125rem;
}

.inner-1318 {
  max-width: 82.375rem;
}

.inner-1319 {
  max-width: 82.4375rem;
}

.inner-1320 {
  max-width: 82.5rem;
}

.inner-1321 {
  max-width: 82.5625rem;
}

.inner-1322 {
  max-width: 82.625rem;
}

.inner-1323 {
  max-width: 82.6875rem;
}

.inner-1324 {
  max-width: 82.75rem;
}

.inner-1325 {
  max-width: 82.8125rem;
}

.inner-1326 {
  max-width: 82.875rem;
}

.inner-1327 {
  max-width: 82.9375rem;
}

.inner-1328 {
  max-width: 83rem;
}

.inner-1329 {
  max-width: 83.0625rem;
}

.inner-1330 {
  max-width: 83.125rem;
}

.inner-1331 {
  max-width: 83.1875rem;
}

.inner-1332 {
  max-width: 83.25rem;
}

.inner-1333 {
  max-width: 83.3125rem;
}

.inner-1334 {
  max-width: 83.375rem;
}

.inner-1335 {
  max-width: 83.4375rem;
}

.inner-1336 {
  max-width: 83.5rem;
}

.inner-1337 {
  max-width: 83.5625rem;
}

.inner-1338 {
  max-width: 83.625rem;
}

.inner-1339 {
  max-width: 83.6875rem;
}

.inner-1340 {
  max-width: 83.75rem;
}

.inner-1341 {
  max-width: 83.8125rem;
}

.inner-1342 {
  max-width: 83.875rem;
}

.inner-1343 {
  max-width: 83.9375rem;
}

.inner-1344 {
  max-width: 84rem;
}

.inner-1345 {
  max-width: 84.0625rem;
}

.inner-1346 {
  max-width: 84.125rem;
}

.inner-1347 {
  max-width: 84.1875rem;
}

.inner-1348 {
  max-width: 84.25rem;
}

.inner-1349 {
  max-width: 84.3125rem;
}

.inner-1350 {
  max-width: 84.375rem;
}

.inner-1351 {
  max-width: 84.4375rem;
}

.inner-1352 {
  max-width: 84.5rem;
}

.inner-1353 {
  max-width: 84.5625rem;
}

.inner-1354 {
  max-width: 84.625rem;
}

.inner-1355 {
  max-width: 84.6875rem;
}

.inner-1356 {
  max-width: 84.75rem;
}

.inner-1357 {
  max-width: 84.8125rem;
}

.inner-1358 {
  max-width: 84.875rem;
}

.inner-1359 {
  max-width: 84.9375rem;
}

.inner-1360 {
  max-width: 85rem;
}

.inner-1361 {
  max-width: 85.0625rem;
}

.inner-1362 {
  max-width: 85.125rem;
}

.inner-1363 {
  max-width: 85.1875rem;
}

.inner-1364 {
  max-width: 85.25rem;
}

.inner-1365 {
  max-width: 85.3125rem;
}

.inner-1366 {
  max-width: 85.375rem;
}

.inner-1367 {
  max-width: 85.4375rem;
}

.inner-1368 {
  max-width: 85.5rem;
}

.inner-1369 {
  max-width: 85.5625rem;
}

.inner-1370 {
  max-width: 85.625rem;
}

.inner-1371 {
  max-width: 85.6875rem;
}

.inner-1372 {
  max-width: 85.75rem;
}

.inner-1373 {
  max-width: 85.8125rem;
}

.inner-1374 {
  max-width: 85.875rem;
}

.inner-1375 {
  max-width: 85.9375rem;
}

.inner-1376 {
  max-width: 86rem;
}

.inner-1377 {
  max-width: 86.0625rem;
}

.inner-1378 {
  max-width: 86.125rem;
}

.inner-1379 {
  max-width: 86.1875rem;
}

.inner-1380 {
  max-width: 86.25rem;
}

.inner-1381 {
  max-width: 86.3125rem;
}

.inner-1382 {
  max-width: 86.375rem;
}

.inner-1383 {
  max-width: 86.4375rem;
}

.inner-1384 {
  max-width: 86.5rem;
}

.inner-1385 {
  max-width: 86.5625rem;
}

.inner-1386 {
  max-width: 86.625rem;
}

.inner-1387 {
  max-width: 86.6875rem;
}

.inner-1388 {
  max-width: 86.75rem;
}

.inner-1389 {
  max-width: 86.8125rem;
}

.inner-1390 {
  max-width: 86.875rem;
}

.inner-1391 {
  max-width: 86.9375rem;
}

.inner-1392 {
  max-width: 87rem;
}

.inner-1393 {
  max-width: 87.0625rem;
}

.inner-1394 {
  max-width: 87.125rem;
}

.inner-1395 {
  max-width: 87.1875rem;
}

.inner-1396 {
  max-width: 87.25rem;
}

.inner-1397 {
  max-width: 87.3125rem;
}

.inner-1398 {
  max-width: 87.375rem;
}

.inner-1399 {
  max-width: 87.4375rem;
}

.inner-1400 {
  max-width: 87.5rem;
}

.inner-1401 {
  max-width: 87.5625rem;
}

.inner-1402 {
  max-width: 87.625rem;
}

.inner-1403 {
  max-width: 87.6875rem;
}

.inner-1404 {
  max-width: 87.75rem;
}

.inner-1405 {
  max-width: 87.8125rem;
}

.inner-1406 {
  max-width: 87.875rem;
}

.inner-1407 {
  max-width: 87.9375rem;
}

.inner-1408 {
  max-width: 88rem;
}

.inner-1409 {
  max-width: 88.0625rem;
}

.inner-1410 {
  max-width: 88.125rem;
}

.inner-1411 {
  max-width: 88.1875rem;
}

.inner-1412 {
  max-width: 88.25rem;
}

.inner-1413 {
  max-width: 88.3125rem;
}

.inner-1414 {
  max-width: 88.375rem;
}

.inner-1415 {
  max-width: 88.4375rem;
}

.inner-1416 {
  max-width: 88.5rem;
}

.inner-1417 {
  max-width: 88.5625rem;
}

.inner-1418 {
  max-width: 88.625rem;
}

.inner-1419 {
  max-width: 88.6875rem;
}

.inner-1420 {
  max-width: 88.75rem;
}

.inner-1421 {
  max-width: 88.8125rem;
}

.inner-1422 {
  max-width: 88.875rem;
}

.inner-1423 {
  max-width: 88.9375rem;
}

.inner-1424 {
  max-width: 89rem;
}

.inner-1425 {
  max-width: 89.0625rem;
}

.inner-1426 {
  max-width: 89.125rem;
}

.inner-1427 {
  max-width: 89.1875rem;
}

.inner-1428 {
  max-width: 89.25rem;
}

.inner-1429 {
  max-width: 89.3125rem;
}

.inner-1430 {
  max-width: 89.375rem;
}

.inner-1431 {
  max-width: 89.4375rem;
}

.inner-1432 {
  max-width: 89.5rem;
}

.inner-1433 {
  max-width: 89.5625rem;
}

.inner-1434 {
  max-width: 89.625rem;
}

.inner-1435 {
  max-width: 89.6875rem;
}

.inner-1436 {
  max-width: 89.75rem;
}

.inner-1437 {
  max-width: 89.8125rem;
}

.inner-1438 {
  max-width: 89.875rem;
}

.inner-1439 {
  max-width: 89.9375rem;
}

.inner-1440 {
  max-width: 90rem;
}

.inner-1441 {
  max-width: 90.0625rem;
}

.inner-1442 {
  max-width: 90.125rem;
}

.inner-1443 {
  max-width: 90.1875rem;
}

.inner-1444 {
  max-width: 90.25rem;
}

.inner-1445 {
  max-width: 90.3125rem;
}

.inner-1446 {
  max-width: 90.375rem;
}

.inner-1447 {
  max-width: 90.4375rem;
}

.inner-1448 {
  max-width: 90.5rem;
}

.inner-1449 {
  max-width: 90.5625rem;
}

.inner-1450 {
  max-width: 90.625rem;
}

.inner-1451 {
  max-width: 90.6875rem;
}

.inner-1452 {
  max-width: 90.75rem;
}

.inner-1453 {
  max-width: 90.8125rem;
}

.inner-1454 {
  max-width: 90.875rem;
}

.inner-1455 {
  max-width: 90.9375rem;
}

.inner-1456 {
  max-width: 91rem;
}

.inner-1457 {
  max-width: 91.0625rem;
}

.inner-1458 {
  max-width: 91.125rem;
}

.inner-1459 {
  max-width: 91.1875rem;
}

.inner-1460 {
  max-width: 91.25rem;
}

.inner-1461 {
  max-width: 91.3125rem;
}

.inner-1462 {
  max-width: 91.375rem;
}

.inner-1463 {
  max-width: 91.4375rem;
}

.inner-1464 {
  max-width: 91.5rem;
}

.inner-1465 {
  max-width: 91.5625rem;
}

.inner-1466 {
  max-width: 91.625rem;
}

.inner-1467 {
  max-width: 91.6875rem;
}

.inner-1468 {
  max-width: 91.75rem;
}

.inner-1469 {
  max-width: 91.8125rem;
}

.inner-1470 {
  max-width: 91.875rem;
}

.inner-1471 {
  max-width: 91.9375rem;
}

.inner-1472 {
  max-width: 92rem;
}

.inner-1473 {
  max-width: 92.0625rem;
}

.inner-1474 {
  max-width: 92.125rem;
}

.inner-1475 {
  max-width: 92.1875rem;
}

.inner-1476 {
  max-width: 92.25rem;
}

.inner-1477 {
  max-width: 92.3125rem;
}

.inner-1478 {
  max-width: 92.375rem;
}

.inner-1479 {
  max-width: 92.4375rem;
}

.inner-1480 {
  max-width: 92.5rem;
}

.inner-1481 {
  max-width: 92.5625rem;
}

.inner-1482 {
  max-width: 92.625rem;
}

.inner-1483 {
  max-width: 92.6875rem;
}

.inner-1484 {
  max-width: 92.75rem;
}

.inner-1485 {
  max-width: 92.8125rem;
}

.inner-1486 {
  max-width: 92.875rem;
}

.inner-1487 {
  max-width: 92.9375rem;
}

.inner-1488 {
  max-width: 93rem;
}

.inner-1489 {
  max-width: 93.0625rem;
}

.inner-1490 {
  max-width: 93.125rem;
}

.inner-1491 {
  max-width: 93.1875rem;
}

.inner-1492 {
  max-width: 93.25rem;
}

.inner-1493 {
  max-width: 93.3125rem;
}

.inner-1494 {
  max-width: 93.375rem;
}

.inner-1495 {
  max-width: 93.4375rem;
}

.inner-1496 {
  max-width: 93.5rem;
}

.inner-1497 {
  max-width: 93.5625rem;
}

.inner-1498 {
  max-width: 93.625rem;
}

.inner-1499 {
  max-width: 93.6875rem;
}

.inner-1500 {
  max-width: 93.75rem;
}

.inner-1501 {
  max-width: 93.8125rem;
}

.inner-1502 {
  max-width: 93.875rem;
}

.inner-1503 {
  max-width: 93.9375rem;
}

.inner-1504 {
  max-width: 94rem;
}

.inner-1505 {
  max-width: 94.0625rem;
}

.inner-1506 {
  max-width: 94.125rem;
}

.inner-1507 {
  max-width: 94.1875rem;
}

.inner-1508 {
  max-width: 94.25rem;
}

.inner-1509 {
  max-width: 94.3125rem;
}

.inner-1510 {
  max-width: 94.375rem;
}

.inner-1511 {
  max-width: 94.4375rem;
}

.inner-1512 {
  max-width: 94.5rem;
}

.inner-1513 {
  max-width: 94.5625rem;
}

.inner-1514 {
  max-width: 94.625rem;
}

.inner-1515 {
  max-width: 94.6875rem;
}

.inner-1516 {
  max-width: 94.75rem;
}

.inner-1517 {
  max-width: 94.8125rem;
}

.inner-1518 {
  max-width: 94.875rem;
}

.inner-1519 {
  max-width: 94.9375rem;
}

.inner-1520 {
  max-width: 95rem;
}

.inner-1521 {
  max-width: 95.0625rem;
}

.inner-1522 {
  max-width: 95.125rem;
}

.inner-1523 {
  max-width: 95.1875rem;
}

.inner-1524 {
  max-width: 95.25rem;
}

.inner-1525 {
  max-width: 95.3125rem;
}

.inner-1526 {
  max-width: 95.375rem;
}

.inner-1527 {
  max-width: 95.4375rem;
}

.inner-1528 {
  max-width: 95.5rem;
}

.inner-1529 {
  max-width: 95.5625rem;
}

.inner-1530 {
  max-width: 95.625rem;
}

.inner-1531 {
  max-width: 95.6875rem;
}

.inner-1532 {
  max-width: 95.75rem;
}

.inner-1533 {
  max-width: 95.8125rem;
}

.inner-1534 {
  max-width: 95.875rem;
}

.inner-1535 {
  max-width: 95.9375rem;
}

.inner-1536 {
  max-width: 96rem;
}

.inner-1537 {
  max-width: 96.0625rem;
}

.inner-1538 {
  max-width: 96.125rem;
}

.inner-1539 {
  max-width: 96.1875rem;
}

.inner-1540 {
  max-width: 96.25rem;
}

.inner-1541 {
  max-width: 96.3125rem;
}

.inner-1542 {
  max-width: 96.375rem;
}

.inner-1543 {
  max-width: 96.4375rem;
}

.inner-1544 {
  max-width: 96.5rem;
}

.inner-1545 {
  max-width: 96.5625rem;
}

.inner-1546 {
  max-width: 96.625rem;
}

.inner-1547 {
  max-width: 96.6875rem;
}

.inner-1548 {
  max-width: 96.75rem;
}

.inner-1549 {
  max-width: 96.8125rem;
}

.inner-1550 {
  max-width: 96.875rem;
}

.inner-1551 {
  max-width: 96.9375rem;
}

.inner-1552 {
  max-width: 97rem;
}

.inner-1553 {
  max-width: 97.0625rem;
}

.inner-1554 {
  max-width: 97.125rem;
}

.inner-1555 {
  max-width: 97.1875rem;
}

.inner-1556 {
  max-width: 97.25rem;
}

.inner-1557 {
  max-width: 97.3125rem;
}

.inner-1558 {
  max-width: 97.375rem;
}

.inner-1559 {
  max-width: 97.4375rem;
}

.inner-1560 {
  max-width: 97.5rem;
}

.inner-1561 {
  max-width: 97.5625rem;
}

.inner-1562 {
  max-width: 97.625rem;
}

.inner-1563 {
  max-width: 97.6875rem;
}

.inner-1564 {
  max-width: 97.75rem;
}

.inner-1565 {
  max-width: 97.8125rem;
}

.inner-1566 {
  max-width: 97.875rem;
}

.inner-1567 {
  max-width: 97.9375rem;
}

.inner-1568 {
  max-width: 98rem;
}

.inner-1569 {
  max-width: 98.0625rem;
}

.inner-1570 {
  max-width: 98.125rem;
}

.inner-1571 {
  max-width: 98.1875rem;
}

.inner-1572 {
  max-width: 98.25rem;
}

.inner-1573 {
  max-width: 98.3125rem;
}

.inner-1574 {
  max-width: 98.375rem;
}

.inner-1575 {
  max-width: 98.4375rem;
}

.inner-1576 {
  max-width: 98.5rem;
}

.inner-1577 {
  max-width: 98.5625rem;
}

.inner-1578 {
  max-width: 98.625rem;
}

.inner-1579 {
  max-width: 98.6875rem;
}

.inner-1580 {
  max-width: 98.75rem;
}

.inner-1581 {
  max-width: 98.8125rem;
}

.inner-1582 {
  max-width: 98.875rem;
}

.inner-1583 {
  max-width: 98.9375rem;
}

.inner-1584 {
  max-width: 99rem;
}

.inner-1585 {
  max-width: 99.0625rem;
}

.inner-1586 {
  max-width: 99.125rem;
}

.inner-1587 {
  max-width: 99.1875rem;
}

.inner-1588 {
  max-width: 99.25rem;
}

.inner-1589 {
  max-width: 99.3125rem;
}

.inner-1590 {
  max-width: 99.375rem;
}

.inner-1591 {
  max-width: 99.4375rem;
}

.inner-1592 {
  max-width: 99.5rem;
}

.inner-1593 {
  max-width: 99.5625rem;
}

.inner-1594 {
  max-width: 99.625rem;
}

.inner-1595 {
  max-width: 99.6875rem;
}

.inner-1596 {
  max-width: 99.75rem;
}

.inner-1597 {
  max-width: 99.8125rem;
}

.inner-1598 {
  max-width: 99.875rem;
}

.inner-1599 {
  max-width: 99.9375rem;
}

.inner-1600 {
  max-width: 100rem;
}

.inner-1601 {
  max-width: 100.0625rem;
}

.inner-1602 {
  max-width: 100.125rem;
}

.inner-1603 {
  max-width: 100.1875rem;
}

.inner-1604 {
  max-width: 100.25rem;
}

.inner-1605 {
  max-width: 100.3125rem;
}

.inner-1606 {
  max-width: 100.375rem;
}

.inner-1607 {
  max-width: 100.4375rem;
}

.inner-1608 {
  max-width: 100.5rem;
}

.inner-1609 {
  max-width: 100.5625rem;
}

.inner-1610 {
  max-width: 100.625rem;
}

.inner-1611 {
  max-width: 100.6875rem;
}

.inner-1612 {
  max-width: 100.75rem;
}

.inner-1613 {
  max-width: 100.8125rem;
}

.inner-1614 {
  max-width: 100.875rem;
}

.inner-1615 {
  max-width: 100.9375rem;
}

.inner-1616 {
  max-width: 101rem;
}

.inner-1617 {
  max-width: 101.0625rem;
}

.inner-1618 {
  max-width: 101.125rem;
}

.inner-1619 {
  max-width: 101.1875rem;
}

.inner-1620 {
  max-width: 101.25rem;
}

.inner-1621 {
  max-width: 101.3125rem;
}

.inner-1622 {
  max-width: 101.375rem;
}

.inner-1623 {
  max-width: 101.4375rem;
}

.inner-1624 {
  max-width: 101.5rem;
}

.inner-1625 {
  max-width: 101.5625rem;
}

.inner-1626 {
  max-width: 101.625rem;
}

.inner-1627 {
  max-width: 101.6875rem;
}

.inner-1628 {
  max-width: 101.75rem;
}

.inner-1629 {
  max-width: 101.8125rem;
}

.inner-1630 {
  max-width: 101.875rem;
}

.inner-1631 {
  max-width: 101.9375rem;
}

.inner-1632 {
  max-width: 102rem;
}

.inner-1633 {
  max-width: 102.0625rem;
}

.inner-1634 {
  max-width: 102.125rem;
}

.inner-1635 {
  max-width: 102.1875rem;
}

.inner-1636 {
  max-width: 102.25rem;
}

.inner-1637 {
  max-width: 102.3125rem;
}

.inner-1638 {
  max-width: 102.375rem;
}

.inner-1639 {
  max-width: 102.4375rem;
}

.inner-1640 {
  max-width: 102.5rem;
}

.inner-1641 {
  max-width: 102.5625rem;
}

.inner-1642 {
  max-width: 102.625rem;
}

.inner-1643 {
  max-width: 102.6875rem;
}

.inner-1644 {
  max-width: 102.75rem;
}

.inner-1645 {
  max-width: 102.8125rem;
}

.inner-1646 {
  max-width: 102.875rem;
}

.inner-1647 {
  max-width: 102.9375rem;
}

.inner-1648 {
  max-width: 103rem;
}

.inner-1649 {
  max-width: 103.0625rem;
}

.inner-1650 {
  max-width: 103.125rem;
}

.inner-1651 {
  max-width: 103.1875rem;
}

.inner-1652 {
  max-width: 103.25rem;
}

.inner-1653 {
  max-width: 103.3125rem;
}

.inner-1654 {
  max-width: 103.375rem;
}

.inner-1655 {
  max-width: 103.4375rem;
}

.inner-1656 {
  max-width: 103.5rem;
}

.inner-1657 {
  max-width: 103.5625rem;
}

.inner-1658 {
  max-width: 103.625rem;
}

.inner-1659 {
  max-width: 103.6875rem;
}

.inner-1660 {
  max-width: 103.75rem;
}

.inner-1661 {
  max-width: 103.8125rem;
}

.inner-1662 {
  max-width: 103.875rem;
}

.inner-1663 {
  max-width: 103.9375rem;
}

.inner-1664 {
  max-width: 104rem;
}

.inner-1665 {
  max-width: 104.0625rem;
}

.inner-1666 {
  max-width: 104.125rem;
}

.inner-1667 {
  max-width: 104.1875rem;
}

.inner-1668 {
  max-width: 104.25rem;
}

.inner-1669 {
  max-width: 104.3125rem;
}

.inner-1670 {
  max-width: 104.375rem;
}

.inner-1671 {
  max-width: 104.4375rem;
}

.inner-1672 {
  max-width: 104.5rem;
}

.inner-1673 {
  max-width: 104.5625rem;
}

.inner-1674 {
  max-width: 104.625rem;
}

.inner-1675 {
  max-width: 104.6875rem;
}

.inner-1676 {
  max-width: 104.75rem;
}

.inner-1677 {
  max-width: 104.8125rem;
}

.inner-1678 {
  max-width: 104.875rem;
}

.inner-1679 {
  max-width: 104.9375rem;
}

.inner-1680 {
  max-width: 105rem;
}

.inner-1681 {
  max-width: 105.0625rem;
}

.inner-1682 {
  max-width: 105.125rem;
}

.inner-1683 {
  max-width: 105.1875rem;
}

.inner-1684 {
  max-width: 105.25rem;
}

.inner-1685 {
  max-width: 105.3125rem;
}

.inner-1686 {
  max-width: 105.375rem;
}

.inner-1687 {
  max-width: 105.4375rem;
}

.inner-1688 {
  max-width: 105.5rem;
}

.inner-1689 {
  max-width: 105.5625rem;
}

.inner-1690 {
  max-width: 105.625rem;
}

.inner-1691 {
  max-width: 105.6875rem;
}

.inner-1692 {
  max-width: 105.75rem;
}

.inner-1693 {
  max-width: 105.8125rem;
}

.inner-1694 {
  max-width: 105.875rem;
}

.inner-1695 {
  max-width: 105.9375rem;
}

.inner-1696 {
  max-width: 106rem;
}

.inner-1697 {
  max-width: 106.0625rem;
}

.inner-1698 {
  max-width: 106.125rem;
}

.inner-1699 {
  max-width: 106.1875rem;
}

.inner-1700 {
  max-width: 106.25rem;
}

.inner-1701 {
  max-width: 106.3125rem;
}

.inner-1702 {
  max-width: 106.375rem;
}

.inner-1703 {
  max-width: 106.4375rem;
}

.inner-1704 {
  max-width: 106.5rem;
}

.inner-1705 {
  max-width: 106.5625rem;
}

.inner-1706 {
  max-width: 106.625rem;
}

.inner-1707 {
  max-width: 106.6875rem;
}

.inner-1708 {
  max-width: 106.75rem;
}

.inner-1709 {
  max-width: 106.8125rem;
}

.inner-1710 {
  max-width: 106.875rem;
}

.inner-1711 {
  max-width: 106.9375rem;
}

.inner-1712 {
  max-width: 107rem;
}

.inner-1713 {
  max-width: 107.0625rem;
}

.inner-1714 {
  max-width: 107.125rem;
}

.inner-1715 {
  max-width: 107.1875rem;
}

.inner-1716 {
  max-width: 107.25rem;
}

.inner-1717 {
  max-width: 107.3125rem;
}

.inner-1718 {
  max-width: 107.375rem;
}

.inner-1719 {
  max-width: 107.4375rem;
}

.inner-1720 {
  max-width: 107.5rem;
}

.inner-1721 {
  max-width: 107.5625rem;
}

.inner-1722 {
  max-width: 107.625rem;
}

.inner-1723 {
  max-width: 107.6875rem;
}

.inner-1724 {
  max-width: 107.75rem;
}

.inner-1725 {
  max-width: 107.8125rem;
}

.inner-1726 {
  max-width: 107.875rem;
}

.inner-1727 {
  max-width: 107.9375rem;
}

.inner-1728 {
  max-width: 108rem;
}

.inner-1729 {
  max-width: 108.0625rem;
}

.inner-1730 {
  max-width: 108.125rem;
}

.inner-1731 {
  max-width: 108.1875rem;
}

.inner-1732 {
  max-width: 108.25rem;
}

.inner-1733 {
  max-width: 108.3125rem;
}

.inner-1734 {
  max-width: 108.375rem;
}

.inner-1735 {
  max-width: 108.4375rem;
}

.inner-1736 {
  max-width: 108.5rem;
}

.inner-1737 {
  max-width: 108.5625rem;
}

.inner-1738 {
  max-width: 108.625rem;
}

.inner-1739 {
  max-width: 108.6875rem;
}

.inner-1740 {
  max-width: 108.75rem;
}

.inner-1741 {
  max-width: 108.8125rem;
}

.inner-1742 {
  max-width: 108.875rem;
}

.inner-1743 {
  max-width: 108.9375rem;
}

.inner-1744 {
  max-width: 109rem;
}

.inner-1745 {
  max-width: 109.0625rem;
}

.inner-1746 {
  max-width: 109.125rem;
}

.inner-1747 {
  max-width: 109.1875rem;
}

.inner-1748 {
  max-width: 109.25rem;
}

.inner-1749 {
  max-width: 109.3125rem;
}

.inner-1750 {
  max-width: 109.375rem;
}

.inner-1751 {
  max-width: 109.4375rem;
}

.inner-1752 {
  max-width: 109.5rem;
}

.inner-1753 {
  max-width: 109.5625rem;
}

.inner-1754 {
  max-width: 109.625rem;
}

.inner-1755 {
  max-width: 109.6875rem;
}

.inner-1756 {
  max-width: 109.75rem;
}

.inner-1757 {
  max-width: 109.8125rem;
}

.inner-1758 {
  max-width: 109.875rem;
}

.inner-1759 {
  max-width: 109.9375rem;
}

.inner-1760 {
  max-width: 110rem;
}

.inner-1761 {
  max-width: 110.0625rem;
}

.inner-1762 {
  max-width: 110.125rem;
}

.inner-1763 {
  max-width: 110.1875rem;
}

.inner-1764 {
  max-width: 110.25rem;
}

.inner-1765 {
  max-width: 110.3125rem;
}

.inner-1766 {
  max-width: 110.375rem;
}

.inner-1767 {
  max-width: 110.4375rem;
}

.inner-1768 {
  max-width: 110.5rem;
}

.inner-1769 {
  max-width: 110.5625rem;
}

.inner-1770 {
  max-width: 110.625rem;
}

.inner-1771 {
  max-width: 110.6875rem;
}

.inner-1772 {
  max-width: 110.75rem;
}

.inner-1773 {
  max-width: 110.8125rem;
}

.inner-1774 {
  max-width: 110.875rem;
}

.inner-1775 {
  max-width: 110.9375rem;
}

.inner-1776 {
  max-width: 111rem;
}

.inner-1777 {
  max-width: 111.0625rem;
}

.inner-1778 {
  max-width: 111.125rem;
}

.inner-1779 {
  max-width: 111.1875rem;
}

.inner-1780 {
  max-width: 111.25rem;
}

.inner-1781 {
  max-width: 111.3125rem;
}

.inner-1782 {
  max-width: 111.375rem;
}

.inner-1783 {
  max-width: 111.4375rem;
}

.inner-1784 {
  max-width: 111.5rem;
}

.inner-1785 {
  max-width: 111.5625rem;
}

.inner-1786 {
  max-width: 111.625rem;
}

.inner-1787 {
  max-width: 111.6875rem;
}

.inner-1788 {
  max-width: 111.75rem;
}

.inner-1789 {
  max-width: 111.8125rem;
}

.inner-1790 {
  max-width: 111.875rem;
}

.inner-1791 {
  max-width: 111.9375rem;
}

.inner-1792 {
  max-width: 112rem;
}

.inner-1793 {
  max-width: 112.0625rem;
}

.inner-1794 {
  max-width: 112.125rem;
}

.inner-1795 {
  max-width: 112.1875rem;
}

.inner-1796 {
  max-width: 112.25rem;
}

.inner-1797 {
  max-width: 112.3125rem;
}

.inner-1798 {
  max-width: 112.375rem;
}

.inner-1799 {
  max-width: 112.4375rem;
}

.inner-1800 {
  max-width: 112.5rem;
}

.inner-1801 {
  max-width: 112.5625rem;
}

.inner-1802 {
  max-width: 112.625rem;
}

.inner-1803 {
  max-width: 112.6875rem;
}

.inner-1804 {
  max-width: 112.75rem;
}

.inner-1805 {
  max-width: 112.8125rem;
}

.inner-1806 {
  max-width: 112.875rem;
}

.inner-1807 {
  max-width: 112.9375rem;
}

.inner-1808 {
  max-width: 113rem;
}

.inner-1809 {
  max-width: 113.0625rem;
}

.inner-1810 {
  max-width: 113.125rem;
}

.inner-1811 {
  max-width: 113.1875rem;
}

.inner-1812 {
  max-width: 113.25rem;
}

.inner-1813 {
  max-width: 113.3125rem;
}

.inner-1814 {
  max-width: 113.375rem;
}

.inner-1815 {
  max-width: 113.4375rem;
}

.inner-1816 {
  max-width: 113.5rem;
}

.inner-1817 {
  max-width: 113.5625rem;
}

.inner-1818 {
  max-width: 113.625rem;
}

.inner-1819 {
  max-width: 113.6875rem;
}

.inner-1820 {
  max-width: 113.75rem;
}

.inner-1821 {
  max-width: 113.8125rem;
}

.inner-1822 {
  max-width: 113.875rem;
}

.inner-1823 {
  max-width: 113.9375rem;
}

.inner-1824 {
  max-width: 114rem;
}

.inner-1825 {
  max-width: 114.0625rem;
}

.inner-1826 {
  max-width: 114.125rem;
}

.inner-1827 {
  max-width: 114.1875rem;
}

.inner-1828 {
  max-width: 114.25rem;
}

.inner-1829 {
  max-width: 114.3125rem;
}

.inner-1830 {
  max-width: 114.375rem;
}

.inner-1831 {
  max-width: 114.4375rem;
}

.inner-1832 {
  max-width: 114.5rem;
}

.inner-1833 {
  max-width: 114.5625rem;
}

.inner-1834 {
  max-width: 114.625rem;
}

.inner-1835 {
  max-width: 114.6875rem;
}

.inner-1836 {
  max-width: 114.75rem;
}

.inner-1837 {
  max-width: 114.8125rem;
}

.inner-1838 {
  max-width: 114.875rem;
}

.inner-1839 {
  max-width: 114.9375rem;
}

.inner-1840 {
  max-width: 115rem;
}

.inner-1841 {
  max-width: 115.0625rem;
}

.inner-1842 {
  max-width: 115.125rem;
}

.inner-1843 {
  max-width: 115.1875rem;
}

.inner-1844 {
  max-width: 115.25rem;
}

.inner-1845 {
  max-width: 115.3125rem;
}

.inner-1846 {
  max-width: 115.375rem;
}

.inner-1847 {
  max-width: 115.4375rem;
}

.inner-1848 {
  max-width: 115.5rem;
}

.inner-1849 {
  max-width: 115.5625rem;
}

.inner-1850 {
  max-width: 115.625rem;
}

.inner-1851 {
  max-width: 115.6875rem;
}

.inner-1852 {
  max-width: 115.75rem;
}

.inner-1853 {
  max-width: 115.8125rem;
}

.inner-1854 {
  max-width: 115.875rem;
}

.inner-1855 {
  max-width: 115.9375rem;
}

.inner-1856 {
  max-width: 116rem;
}

.inner-1857 {
  max-width: 116.0625rem;
}

.inner-1858 {
  max-width: 116.125rem;
}

.inner-1859 {
  max-width: 116.1875rem;
}

.inner-1860 {
  max-width: 116.25rem;
}

.inner-1861 {
  max-width: 116.3125rem;
}

.inner-1862 {
  max-width: 116.375rem;
}

.inner-1863 {
  max-width: 116.4375rem;
}

.inner-1864 {
  max-width: 116.5rem;
}

.inner-1865 {
  max-width: 116.5625rem;
}

.inner-1866 {
  max-width: 116.625rem;
}

.inner-1867 {
  max-width: 116.6875rem;
}

.inner-1868 {
  max-width: 116.75rem;
}

.inner-1869 {
  max-width: 116.8125rem;
}

.inner-1870 {
  max-width: 116.875rem;
}

.inner-1871 {
  max-width: 116.9375rem;
}

.inner-1872 {
  max-width: 117rem;
}

.inner-1873 {
  max-width: 117.0625rem;
}

.inner-1874 {
  max-width: 117.125rem;
}

.inner-1875 {
  max-width: 117.1875rem;
}

.inner-1876 {
  max-width: 117.25rem;
}

.inner-1877 {
  max-width: 117.3125rem;
}

.inner-1878 {
  max-width: 117.375rem;
}

.inner-1879 {
  max-width: 117.4375rem;
}

.inner-1880 {
  max-width: 117.5rem;
}

.inner-1881 {
  max-width: 117.5625rem;
}

.inner-1882 {
  max-width: 117.625rem;
}

.inner-1883 {
  max-width: 117.6875rem;
}

.inner-1884 {
  max-width: 117.75rem;
}

.inner-1885 {
  max-width: 117.8125rem;
}

.inner-1886 {
  max-width: 117.875rem;
}

.inner-1887 {
  max-width: 117.9375rem;
}

.inner-1888 {
  max-width: 118rem;
}

.inner-1889 {
  max-width: 118.0625rem;
}

.inner-1890 {
  max-width: 118.125rem;
}

.inner-1891 {
  max-width: 118.1875rem;
}

.inner-1892 {
  max-width: 118.25rem;
}

.inner-1893 {
  max-width: 118.3125rem;
}

.inner-1894 {
  max-width: 118.375rem;
}

.inner-1895 {
  max-width: 118.4375rem;
}

.inner-1896 {
  max-width: 118.5rem;
}

.inner-1897 {
  max-width: 118.5625rem;
}

.inner-1898 {
  max-width: 118.625rem;
}

.inner-1899 {
  max-width: 118.6875rem;
}

.inner-1900 {
  max-width: 118.75rem;
}

.inner-1901 {
  max-width: 118.8125rem;
}

.inner-1902 {
  max-width: 118.875rem;
}

.inner-1903 {
  max-width: 118.9375rem;
}

.inner-1904 {
  max-width: 119rem;
}

.inner-1905 {
  max-width: 119.0625rem;
}

.inner-1906 {
  max-width: 119.125rem;
}

.inner-1907 {
  max-width: 119.1875rem;
}

.inner-1908 {
  max-width: 119.25rem;
}

.inner-1909 {
  max-width: 119.3125rem;
}

.inner-1910 {
  max-width: 119.375rem;
}

.inner-1911 {
  max-width: 119.4375rem;
}

.inner-1912 {
  max-width: 119.5rem;
}

.inner-1913 {
  max-width: 119.5625rem;
}

.inner-1914 {
  max-width: 119.625rem;
}

.inner-1915 {
  max-width: 119.6875rem;
}

.inner-1916 {
  max-width: 119.75rem;
}

.inner-1917 {
  max-width: 119.8125rem;
}

.inner-1918 {
  max-width: 119.875rem;
}

.inner-1919 {
  max-width: 119.9375rem;
}

.inner-1920 {
  max-width: 120rem;
}

/* --- anchor --- */
.anchor {
  position: absolute;
  top: -7.125rem;
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .anchor .anchor {
    top: -5.25rem;
  }
}

/* page-contact
********************************************** */
.page-contact .lps_sec:nth-child(odd) {
  background-color: var(--clr-beige);
  padding-bottom: 0;
}

.page-contact .lps_sec.section_pdg {
  padding-top: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .page-contact .lps_sec.section_pdg {
    padding-top: 3rem;
  }
}

.page-contact .footer {
  background-color: var(--bg-off_wht);
}

/* section-contact
********************************************** */
.section-contact .cta_tel {
  width: 100%;
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .section-contact .cta_tel.mgn-btm48 {
    margin-bottom: 3rem;
  }
}
.section-contact .cta_tel a {
  padding: 1.5rem 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .section-contact .cta_tel a {
    display: block;
    width: auto;
    padding: 1.1428571429rem;
  }
}

/* flex
**************************************** */
/* --- ブロック要素 --- */
.flex {
  display: flex;
}
.flex.gap1 {
  gap: 0.0625rem;
}
.flex.gap2 {
  gap: 0.125rem;
}
.flex.gap3 {
  gap: 0.1875rem;
}
.flex.gap4 {
  gap: 0.25rem;
}
.flex.gap5 {
  gap: 0.3125rem;
}
.flex.gap6 {
  gap: 0.375rem;
}
.flex.gap7 {
  gap: 0.4375rem;
}
.flex.gap8 {
  gap: 0.5rem;
}
.flex.gap9 {
  gap: 0.5625rem;
}
.flex.gap10 {
  gap: 0.625rem;
}
.flex.gap11 {
  gap: 0.6875rem;
}
.flex.gap12 {
  gap: 0.75rem;
}
.flex.gap13 {
  gap: 0.8125rem;
}
.flex.gap14 {
  gap: 0.875rem;
}
.flex.gap15 {
  gap: 0.9375rem;
}
.flex.gap16 {
  gap: 1rem;
}
.flex.gap17 {
  gap: 1.0625rem;
}
.flex.gap18 {
  gap: 1.125rem;
}
.flex.gap19 {
  gap: 1.1875rem;
}
.flex.gap20 {
  gap: 1.25rem;
}
.flex.gap21 {
  gap: 1.3125rem;
}
.flex.gap22 {
  gap: 1.375rem;
}
.flex.gap23 {
  gap: 1.4375rem;
}
.flex.gap24 {
  gap: 1.5rem;
}
.flex.gap25 {
  gap: 1.5625rem;
}
.flex.gap26 {
  gap: 1.625rem;
}
.flex.gap27 {
  gap: 1.6875rem;
}
.flex.gap28 {
  gap: 1.75rem;
}
.flex.gap29 {
  gap: 1.8125rem;
}
.flex.gap30 {
  gap: 1.875rem;
}
.flex.gap31 {
  gap: 1.9375rem;
}
.flex.gap32 {
  gap: 2rem;
}
.flex.gap33 {
  gap: 2.0625rem;
}
.flex.gap34 {
  gap: 2.125rem;
}
.flex.gap35 {
  gap: 2.1875rem;
}
.flex.gap36 {
  gap: 2.25rem;
}
.flex.gap37 {
  gap: 2.3125rem;
}
.flex.gap38 {
  gap: 2.375rem;
}
.flex.gap39 {
  gap: 2.4375rem;
}
.flex.gap40 {
  gap: 2.5rem;
}
.flex.gap41 {
  gap: 2.5625rem;
}
.flex.gap42 {
  gap: 2.625rem;
}
.flex.gap43 {
  gap: 2.6875rem;
}
.flex.gap44 {
  gap: 2.75rem;
}
.flex.gap45 {
  gap: 2.8125rem;
}
.flex.gap46 {
  gap: 2.875rem;
}
.flex.gap47 {
  gap: 2.9375rem;
}
.flex.gap48 {
  gap: 3rem;
}
.flex.gap49 {
  gap: 3.0625rem;
}
.flex.gap50 {
  gap: 3.125rem;
}
.flex.gap51 {
  gap: 3.1875rem;
}
.flex.gap52 {
  gap: 3.25rem;
}
.flex.gap53 {
  gap: 3.3125rem;
}
.flex.gap54 {
  gap: 3.375rem;
}
.flex.gap55 {
  gap: 3.4375rem;
}
.flex.gap56 {
  gap: 3.5rem;
}
.flex.gap57 {
  gap: 3.5625rem;
}
.flex.gap58 {
  gap: 3.625rem;
}
.flex.gap59 {
  gap: 3.6875rem;
}
.flex.gap60 {
  gap: 3.75rem;
}
.flex.gap61 {
  gap: 3.8125rem;
}
.flex.gap62 {
  gap: 3.875rem;
}
.flex.gap63 {
  gap: 3.9375rem;
}
.flex.gap64 {
  gap: 4rem;
}
.flex.gap65 {
  gap: 4.0625rem;
}
.flex.gap66 {
  gap: 4.125rem;
}
.flex.gap67 {
  gap: 4.1875rem;
}
.flex.gap68 {
  gap: 4.25rem;
}
.flex.gap69 {
  gap: 4.3125rem;
}
.flex.gap70 {
  gap: 4.375rem;
}
.flex.gap71 {
  gap: 4.4375rem;
}
.flex.gap72 {
  gap: 4.5rem;
}
.flex.gap73 {
  gap: 4.5625rem;
}
.flex.gap74 {
  gap: 4.625rem;
}
.flex.gap75 {
  gap: 4.6875rem;
}
.flex.gap76 {
  gap: 4.75rem;
}
.flex.gap77 {
  gap: 4.8125rem;
}
.flex.gap78 {
  gap: 4.875rem;
}
.flex.gap79 {
  gap: 4.9375rem;
}
.flex.gap80 {
  gap: 5rem;
}
.flex.gap81 {
  gap: 5.0625rem;
}
.flex.gap82 {
  gap: 5.125rem;
}
.flex.gap83 {
  gap: 5.1875rem;
}
.flex.gap84 {
  gap: 5.25rem;
}
.flex.gap85 {
  gap: 5.3125rem;
}
.flex.gap86 {
  gap: 5.375rem;
}
.flex.gap87 {
  gap: 5.4375rem;
}
.flex.gap88 {
  gap: 5.5rem;
}
.flex.gap89 {
  gap: 5.5625rem;
}
.flex.gap90 {
  gap: 5.625rem;
}
.flex.gap91 {
  gap: 5.6875rem;
}
.flex.gap92 {
  gap: 5.75rem;
}
.flex.gap93 {
  gap: 5.8125rem;
}
.flex.gap94 {
  gap: 5.875rem;
}
.flex.gap95 {
  gap: 5.9375rem;
}
.flex.gap96 {
  gap: 6rem;
}
.flex.gap97 {
  gap: 6.0625rem;
}
.flex.gap98 {
  gap: 6.125rem;
}
.flex.gap99 {
  gap: 6.1875rem;
}
.flex.gap100 {
  gap: 6.25rem;
}
.flex.gap101 {
  gap: 6.3125rem;
}
.flex.gap102 {
  gap: 6.375rem;
}
.flex.gap103 {
  gap: 6.4375rem;
}
.flex.gap104 {
  gap: 6.5rem;
}
.flex.gap105 {
  gap: 6.5625rem;
}
.flex.gap106 {
  gap: 6.625rem;
}
.flex.gap107 {
  gap: 6.6875rem;
}
.flex.gap108 {
  gap: 6.75rem;
}
.flex.gap109 {
  gap: 6.8125rem;
}
.flex.gap110 {
  gap: 6.875rem;
}
.flex.gap111 {
  gap: 6.9375rem;
}
.flex.gap112 {
  gap: 7rem;
}
.flex.gap113 {
  gap: 7.0625rem;
}
.flex.gap114 {
  gap: 7.125rem;
}
.flex.gap115 {
  gap: 7.1875rem;
}
.flex.gap116 {
  gap: 7.25rem;
}
.flex.gap117 {
  gap: 7.3125rem;
}
.flex.gap118 {
  gap: 7.375rem;
}
.flex.gap119 {
  gap: 7.4375rem;
}
.flex.gap120 {
  gap: 7.5rem;
}
.flex.gap121 {
  gap: 7.5625rem;
}
.flex.gap122 {
  gap: 7.625rem;
}
.flex.gap123 {
  gap: 7.6875rem;
}
.flex.gap124 {
  gap: 7.75rem;
}
.flex.gap125 {
  gap: 7.8125rem;
}
.flex.gap126 {
  gap: 7.875rem;
}
.flex.gap127 {
  gap: 7.9375rem;
}
.flex.gap128 {
  gap: 8rem;
}
.flex.gap129 {
  gap: 8.0625rem;
}
.flex.gap130 {
  gap: 8.125rem;
}
.flex.gap131 {
  gap: 8.1875rem;
}
.flex.gap132 {
  gap: 8.25rem;
}
.flex.gap133 {
  gap: 8.3125rem;
}
.flex.gap134 {
  gap: 8.375rem;
}
.flex.gap135 {
  gap: 8.4375rem;
}
.flex.gap136 {
  gap: 8.5rem;
}
.flex.gap137 {
  gap: 8.5625rem;
}
.flex.gap138 {
  gap: 8.625rem;
}
.flex.gap139 {
  gap: 8.6875rem;
}
.flex.gap140 {
  gap: 8.75rem;
}
.flex.gap141 {
  gap: 8.8125rem;
}
.flex.gap142 {
  gap: 8.875rem;
}
.flex.gap143 {
  gap: 8.9375rem;
}
.flex.gap144 {
  gap: 9rem;
}
.flex.gap145 {
  gap: 9.0625rem;
}
.flex.gap146 {
  gap: 9.125rem;
}
.flex.gap147 {
  gap: 9.1875rem;
}
.flex.gap148 {
  gap: 9.25rem;
}
.flex.gap149 {
  gap: 9.3125rem;
}
.flex.gap150 {
  gap: 9.375rem;
}
.flex.gap151 {
  gap: 9.4375rem;
}
.flex.gap152 {
  gap: 9.5rem;
}
.flex.gap153 {
  gap: 9.5625rem;
}
.flex.gap154 {
  gap: 9.625rem;
}
.flex.gap155 {
  gap: 9.6875rem;
}
.flex.gap156 {
  gap: 9.75rem;
}
.flex.gap157 {
  gap: 9.8125rem;
}
.flex.gap158 {
  gap: 9.875rem;
}
.flex.gap159 {
  gap: 9.9375rem;
}
.flex.gap160 {
  gap: 10rem;
}
.flex.gap161 {
  gap: 10.0625rem;
}
.flex.gap162 {
  gap: 10.125rem;
}
.flex.gap163 {
  gap: 10.1875rem;
}
.flex.gap164 {
  gap: 10.25rem;
}
.flex.gap165 {
  gap: 10.3125rem;
}
.flex.gap166 {
  gap: 10.375rem;
}
.flex.gap167 {
  gap: 10.4375rem;
}
.flex.gap168 {
  gap: 10.5rem;
}
.flex.gap169 {
  gap: 10.5625rem;
}
.flex.gap170 {
  gap: 10.625rem;
}
.flex.gap171 {
  gap: 10.6875rem;
}
.flex.gap172 {
  gap: 10.75rem;
}
.flex.gap173 {
  gap: 10.8125rem;
}
.flex.gap174 {
  gap: 10.875rem;
}
.flex.gap175 {
  gap: 10.9375rem;
}
.flex.gap176 {
  gap: 11rem;
}
.flex.gap177 {
  gap: 11.0625rem;
}
.flex.gap178 {
  gap: 11.125rem;
}
.flex.gap179 {
  gap: 11.1875rem;
}
.flex.gap180 {
  gap: 11.25rem;
}
.flex.gap181 {
  gap: 11.3125rem;
}
.flex.gap182 {
  gap: 11.375rem;
}
.flex.gap183 {
  gap: 11.4375rem;
}
.flex.gap184 {
  gap: 11.5rem;
}
.flex.gap185 {
  gap: 11.5625rem;
}
.flex.gap186 {
  gap: 11.625rem;
}
.flex.gap187 {
  gap: 11.6875rem;
}
.flex.gap188 {
  gap: 11.75rem;
}
.flex.gap189 {
  gap: 11.8125rem;
}
.flex.gap190 {
  gap: 11.875rem;
}
.flex.gap191 {
  gap: 11.9375rem;
}
.flex.gap192 {
  gap: 12rem;
}
.flex.gap193 {
  gap: 12.0625rem;
}
.flex.gap194 {
  gap: 12.125rem;
}
.flex.gap195 {
  gap: 12.1875rem;
}
.flex.gap196 {
  gap: 12.25rem;
}
.flex.gap197 {
  gap: 12.3125rem;
}
.flex.gap198 {
  gap: 12.375rem;
}
.flex.gap199 {
  gap: 12.4375rem;
}
.flex.gap200 {
  gap: 12.5rem;
}
.flex.gap201 {
  gap: 12.5625rem;
}
.flex.gap202 {
  gap: 12.625rem;
}
.flex.gap203 {
  gap: 12.6875rem;
}
.flex.gap204 {
  gap: 12.75rem;
}
.flex.gap205 {
  gap: 12.8125rem;
}
.flex.gap206 {
  gap: 12.875rem;
}
.flex.gap207 {
  gap: 12.9375rem;
}
.flex.gap208 {
  gap: 13rem;
}
.flex.gap209 {
  gap: 13.0625rem;
}
.flex.gap210 {
  gap: 13.125rem;
}
.flex.gap211 {
  gap: 13.1875rem;
}
.flex.gap212 {
  gap: 13.25rem;
}
.flex.gap213 {
  gap: 13.3125rem;
}
.flex.gap214 {
  gap: 13.375rem;
}
.flex.gap215 {
  gap: 13.4375rem;
}
.flex.gap216 {
  gap: 13.5rem;
}
.flex.gap217 {
  gap: 13.5625rem;
}
.flex.gap218 {
  gap: 13.625rem;
}
.flex.gap219 {
  gap: 13.6875rem;
}
.flex.gap220 {
  gap: 13.75rem;
}
.flex.gap221 {
  gap: 13.8125rem;
}
.flex.gap222 {
  gap: 13.875rem;
}
.flex.gap223 {
  gap: 13.9375rem;
}
.flex.gap224 {
  gap: 14rem;
}
.flex.gap225 {
  gap: 14.0625rem;
}
.flex.gap226 {
  gap: 14.125rem;
}
.flex.gap227 {
  gap: 14.1875rem;
}
.flex.gap228 {
  gap: 14.25rem;
}
.flex.gap229 {
  gap: 14.3125rem;
}
.flex.gap230 {
  gap: 14.375rem;
}
.flex.gap231 {
  gap: 14.4375rem;
}
.flex.gap232 {
  gap: 14.5rem;
}
.flex.gap233 {
  gap: 14.5625rem;
}
.flex.gap234 {
  gap: 14.625rem;
}
.flex.gap235 {
  gap: 14.6875rem;
}
.flex.gap236 {
  gap: 14.75rem;
}
.flex.gap237 {
  gap: 14.8125rem;
}
.flex.gap238 {
  gap: 14.875rem;
}
.flex.gap239 {
  gap: 14.9375rem;
}
.flex.gap240 {
  gap: 15rem;
}
.flex.gap241 {
  gap: 15.0625rem;
}
.flex.gap242 {
  gap: 15.125rem;
}
.flex.gap243 {
  gap: 15.1875rem;
}
.flex.gap244 {
  gap: 15.25rem;
}
.flex.gap245 {
  gap: 15.3125rem;
}
.flex.gap246 {
  gap: 15.375rem;
}
.flex.gap247 {
  gap: 15.4375rem;
}
.flex.gap248 {
  gap: 15.5rem;
}
.flex.gap249 {
  gap: 15.5625rem;
}
.flex.gap250 {
  gap: 15.625rem;
}
.flex.gap251 {
  gap: 15.6875rem;
}
.flex.gap252 {
  gap: 15.75rem;
}
.flex.gap253 {
  gap: 15.8125rem;
}
.flex.gap254 {
  gap: 15.875rem;
}
.flex.gap255 {
  gap: 15.9375rem;
}
.flex.gap256 {
  gap: 16rem;
}
.flex.gap257 {
  gap: 16.0625rem;
}
.flex.gap258 {
  gap: 16.125rem;
}
.flex.gap259 {
  gap: 16.1875rem;
}
.flex.gap260 {
  gap: 16.25rem;
}
.flex.gap261 {
  gap: 16.3125rem;
}
.flex.gap262 {
  gap: 16.375rem;
}
.flex.gap263 {
  gap: 16.4375rem;
}
.flex.gap264 {
  gap: 16.5rem;
}
.flex.gap265 {
  gap: 16.5625rem;
}
.flex.gap266 {
  gap: 16.625rem;
}
.flex.gap267 {
  gap: 16.6875rem;
}
.flex.gap268 {
  gap: 16.75rem;
}
.flex.gap269 {
  gap: 16.8125rem;
}
.flex.gap270 {
  gap: 16.875rem;
}
.flex.gap271 {
  gap: 16.9375rem;
}
.flex.gap272 {
  gap: 17rem;
}
.flex.gap273 {
  gap: 17.0625rem;
}
.flex.gap274 {
  gap: 17.125rem;
}
.flex.gap275 {
  gap: 17.1875rem;
}
.flex.gap276 {
  gap: 17.25rem;
}
.flex.gap277 {
  gap: 17.3125rem;
}
.flex.gap278 {
  gap: 17.375rem;
}
.flex.gap279 {
  gap: 17.4375rem;
}
.flex.gap280 {
  gap: 17.5rem;
}
.flex.gap281 {
  gap: 17.5625rem;
}
.flex.gap282 {
  gap: 17.625rem;
}
.flex.gap283 {
  gap: 17.6875rem;
}
.flex.gap284 {
  gap: 17.75rem;
}
.flex.gap285 {
  gap: 17.8125rem;
}
.flex.gap286 {
  gap: 17.875rem;
}
.flex.gap287 {
  gap: 17.9375rem;
}
.flex.gap288 {
  gap: 18rem;
}
.flex.gap289 {
  gap: 18.0625rem;
}
.flex.gap290 {
  gap: 18.125rem;
}
.flex.gap291 {
  gap: 18.1875rem;
}
.flex.gap292 {
  gap: 18.25rem;
}
.flex.gap293 {
  gap: 18.3125rem;
}
.flex.gap294 {
  gap: 18.375rem;
}
.flex.gap295 {
  gap: 18.4375rem;
}
.flex.gap296 {
  gap: 18.5rem;
}
.flex.gap297 {
  gap: 18.5625rem;
}
.flex.gap298 {
  gap: 18.625rem;
}
.flex.gap299 {
  gap: 18.6875rem;
}
.flex.gap300 {
  gap: 18.75rem;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex-j-ctr {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-ctr {
  align-items: center;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}

.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-end {
  align-content: flex-end;
}

.flex-c-ctr {
  align-content: center;
}

.flex-c-baseline {
  align-content: baseline;
}

.flex-c-stretch {
  align-content: stretch;
}

@media screen and (max-width: 47.9375em) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}
.flex-col1 {
  flex-wrap: wrap;
}
.flex-col1 > * {
  width: 100%;
}
.flex-col1.gap1 > * {
  width: calc(100% - 0.0625rem / 1 * 0);
}
.flex-col1.gap1 {
  gap: 0.0625rem;
}
.flex-col1.gap2 > * {
  width: calc(100% - 0.125rem / 1 * 0);
}
.flex-col1.gap2 {
  gap: 0.125rem;
}
.flex-col1.gap3 > * {
  width: calc(100% - 0.1875rem / 1 * 0);
}
.flex-col1.gap3 {
  gap: 0.1875rem;
}
.flex-col1.gap4 > * {
  width: calc(100% - 0.25rem / 1 * 0);
}
.flex-col1.gap4 {
  gap: 0.25rem;
}
.flex-col1.gap5 > * {
  width: calc(100% - 0.3125rem / 1 * 0);
}
.flex-col1.gap5 {
  gap: 0.3125rem;
}
.flex-col1.gap6 > * {
  width: calc(100% - 0.375rem / 1 * 0);
}
.flex-col1.gap6 {
  gap: 0.375rem;
}
.flex-col1.gap7 > * {
  width: calc(100% - 0.4375rem / 1 * 0);
}
.flex-col1.gap7 {
  gap: 0.4375rem;
}
.flex-col1.gap8 > * {
  width: calc(100% - 0.5rem / 1 * 0);
}
.flex-col1.gap8 {
  gap: 0.5rem;
}
.flex-col1.gap9 > * {
  width: calc(100% - 0.5625rem / 1 * 0);
}
.flex-col1.gap9 {
  gap: 0.5625rem;
}
.flex-col1.gap10 > * {
  width: calc(100% - 0.625rem / 1 * 0);
}
.flex-col1.gap10 {
  gap: 0.625rem;
}
.flex-col1.gap11 > * {
  width: calc(100% - 0.6875rem / 1 * 0);
}
.flex-col1.gap11 {
  gap: 0.6875rem;
}
.flex-col1.gap12 > * {
  width: calc(100% - 0.75rem / 1 * 0);
}
.flex-col1.gap12 {
  gap: 0.75rem;
}
.flex-col1.gap13 > * {
  width: calc(100% - 0.8125rem / 1 * 0);
}
.flex-col1.gap13 {
  gap: 0.8125rem;
}
.flex-col1.gap14 > * {
  width: calc(100% - 0.875rem / 1 * 0);
}
.flex-col1.gap14 {
  gap: 0.875rem;
}
.flex-col1.gap15 > * {
  width: calc(100% - 0.9375rem / 1 * 0);
}
.flex-col1.gap15 {
  gap: 0.9375rem;
}
.flex-col1.gap16 > * {
  width: calc(100% - 1rem / 1 * 0);
}
.flex-col1.gap16 {
  gap: 1rem;
}
.flex-col1.gap17 > * {
  width: calc(100% - 1.0625rem / 1 * 0);
}
.flex-col1.gap17 {
  gap: 1.0625rem;
}
.flex-col1.gap18 > * {
  width: calc(100% - 1.125rem / 1 * 0);
}
.flex-col1.gap18 {
  gap: 1.125rem;
}
.flex-col1.gap19 > * {
  width: calc(100% - 1.1875rem / 1 * 0);
}
.flex-col1.gap19 {
  gap: 1.1875rem;
}
.flex-col1.gap20 > * {
  width: calc(100% - 1.25rem / 1 * 0);
}
.flex-col1.gap20 {
  gap: 1.25rem;
}
.flex-col1.gap21 > * {
  width: calc(100% - 1.3125rem / 1 * 0);
}
.flex-col1.gap21 {
  gap: 1.3125rem;
}
.flex-col1.gap22 > * {
  width: calc(100% - 1.375rem / 1 * 0);
}
.flex-col1.gap22 {
  gap: 1.375rem;
}
.flex-col1.gap23 > * {
  width: calc(100% - 1.4375rem / 1 * 0);
}
.flex-col1.gap23 {
  gap: 1.4375rem;
}
.flex-col1.gap24 > * {
  width: calc(100% - 1.5rem / 1 * 0);
}
.flex-col1.gap24 {
  gap: 1.5rem;
}
.flex-col1.gap25 > * {
  width: calc(100% - 1.5625rem / 1 * 0);
}
.flex-col1.gap25 {
  gap: 1.5625rem;
}
.flex-col1.gap26 > * {
  width: calc(100% - 1.625rem / 1 * 0);
}
.flex-col1.gap26 {
  gap: 1.625rem;
}
.flex-col1.gap27 > * {
  width: calc(100% - 1.6875rem / 1 * 0);
}
.flex-col1.gap27 {
  gap: 1.6875rem;
}
.flex-col1.gap28 > * {
  width: calc(100% - 1.75rem / 1 * 0);
}
.flex-col1.gap28 {
  gap: 1.75rem;
}
.flex-col1.gap29 > * {
  width: calc(100% - 1.8125rem / 1 * 0);
}
.flex-col1.gap29 {
  gap: 1.8125rem;
}
.flex-col1.gap30 > * {
  width: calc(100% - 1.875rem / 1 * 0);
}
.flex-col1.gap30 {
  gap: 1.875rem;
}
.flex-col1.gap31 > * {
  width: calc(100% - 1.9375rem / 1 * 0);
}
.flex-col1.gap31 {
  gap: 1.9375rem;
}
.flex-col1.gap32 > * {
  width: calc(100% - 2rem / 1 * 0);
}
.flex-col1.gap32 {
  gap: 2rem;
}
.flex-col1.gap33 > * {
  width: calc(100% - 2.0625rem / 1 * 0);
}
.flex-col1.gap33 {
  gap: 2.0625rem;
}
.flex-col1.gap34 > * {
  width: calc(100% - 2.125rem / 1 * 0);
}
.flex-col1.gap34 {
  gap: 2.125rem;
}
.flex-col1.gap35 > * {
  width: calc(100% - 2.1875rem / 1 * 0);
}
.flex-col1.gap35 {
  gap: 2.1875rem;
}
.flex-col1.gap36 > * {
  width: calc(100% - 2.25rem / 1 * 0);
}
.flex-col1.gap36 {
  gap: 2.25rem;
}
.flex-col1.gap37 > * {
  width: calc(100% - 2.3125rem / 1 * 0);
}
.flex-col1.gap37 {
  gap: 2.3125rem;
}
.flex-col1.gap38 > * {
  width: calc(100% - 2.375rem / 1 * 0);
}
.flex-col1.gap38 {
  gap: 2.375rem;
}
.flex-col1.gap39 > * {
  width: calc(100% - 2.4375rem / 1 * 0);
}
.flex-col1.gap39 {
  gap: 2.4375rem;
}
.flex-col1.gap40 > * {
  width: calc(100% - 2.5rem / 1 * 0);
}
.flex-col1.gap40 {
  gap: 2.5rem;
}
.flex-col1.gap41 > * {
  width: calc(100% - 2.5625rem / 1 * 0);
}
.flex-col1.gap41 {
  gap: 2.5625rem;
}
.flex-col1.gap42 > * {
  width: calc(100% - 2.625rem / 1 * 0);
}
.flex-col1.gap42 {
  gap: 2.625rem;
}
.flex-col1.gap43 > * {
  width: calc(100% - 2.6875rem / 1 * 0);
}
.flex-col1.gap43 {
  gap: 2.6875rem;
}
.flex-col1.gap44 > * {
  width: calc(100% - 2.75rem / 1 * 0);
}
.flex-col1.gap44 {
  gap: 2.75rem;
}
.flex-col1.gap45 > * {
  width: calc(100% - 2.8125rem / 1 * 0);
}
.flex-col1.gap45 {
  gap: 2.8125rem;
}
.flex-col1.gap46 > * {
  width: calc(100% - 2.875rem / 1 * 0);
}
.flex-col1.gap46 {
  gap: 2.875rem;
}
.flex-col1.gap47 > * {
  width: calc(100% - 2.9375rem / 1 * 0);
}
.flex-col1.gap47 {
  gap: 2.9375rem;
}
.flex-col1.gap48 > * {
  width: calc(100% - 3rem / 1 * 0);
}
.flex-col1.gap48 {
  gap: 3rem;
}
.flex-col1.gap49 > * {
  width: calc(100% - 3.0625rem / 1 * 0);
}
.flex-col1.gap49 {
  gap: 3.0625rem;
}
.flex-col1.gap50 > * {
  width: calc(100% - 3.125rem / 1 * 0);
}
.flex-col1.gap50 {
  gap: 3.125rem;
}
.flex-col1.gap51 > * {
  width: calc(100% - 3.1875rem / 1 * 0);
}
.flex-col1.gap51 {
  gap: 3.1875rem;
}
.flex-col1.gap52 > * {
  width: calc(100% - 3.25rem / 1 * 0);
}
.flex-col1.gap52 {
  gap: 3.25rem;
}
.flex-col1.gap53 > * {
  width: calc(100% - 3.3125rem / 1 * 0);
}
.flex-col1.gap53 {
  gap: 3.3125rem;
}
.flex-col1.gap54 > * {
  width: calc(100% - 3.375rem / 1 * 0);
}
.flex-col1.gap54 {
  gap: 3.375rem;
}
.flex-col1.gap55 > * {
  width: calc(100% - 3.4375rem / 1 * 0);
}
.flex-col1.gap55 {
  gap: 3.4375rem;
}
.flex-col1.gap56 > * {
  width: calc(100% - 3.5rem / 1 * 0);
}
.flex-col1.gap56 {
  gap: 3.5rem;
}
.flex-col1.gap57 > * {
  width: calc(100% - 3.5625rem / 1 * 0);
}
.flex-col1.gap57 {
  gap: 3.5625rem;
}
.flex-col1.gap58 > * {
  width: calc(100% - 3.625rem / 1 * 0);
}
.flex-col1.gap58 {
  gap: 3.625rem;
}
.flex-col1.gap59 > * {
  width: calc(100% - 3.6875rem / 1 * 0);
}
.flex-col1.gap59 {
  gap: 3.6875rem;
}
.flex-col1.gap60 > * {
  width: calc(100% - 3.75rem / 1 * 0);
}
.flex-col1.gap60 {
  gap: 3.75rem;
}
.flex-col1.gap61 > * {
  width: calc(100% - 3.8125rem / 1 * 0);
}
.flex-col1.gap61 {
  gap: 3.8125rem;
}
.flex-col1.gap62 > * {
  width: calc(100% - 3.875rem / 1 * 0);
}
.flex-col1.gap62 {
  gap: 3.875rem;
}
.flex-col1.gap63 > * {
  width: calc(100% - 3.9375rem / 1 * 0);
}
.flex-col1.gap63 {
  gap: 3.9375rem;
}
.flex-col1.gap64 > * {
  width: calc(100% - 4rem / 1 * 0);
}
.flex-col1.gap64 {
  gap: 4rem;
}
.flex-col1.gap65 > * {
  width: calc(100% - 4.0625rem / 1 * 0);
}
.flex-col1.gap65 {
  gap: 4.0625rem;
}
.flex-col1.gap66 > * {
  width: calc(100% - 4.125rem / 1 * 0);
}
.flex-col1.gap66 {
  gap: 4.125rem;
}
.flex-col1.gap67 > * {
  width: calc(100% - 4.1875rem / 1 * 0);
}
.flex-col1.gap67 {
  gap: 4.1875rem;
}
.flex-col1.gap68 > * {
  width: calc(100% - 4.25rem / 1 * 0);
}
.flex-col1.gap68 {
  gap: 4.25rem;
}
.flex-col1.gap69 > * {
  width: calc(100% - 4.3125rem / 1 * 0);
}
.flex-col1.gap69 {
  gap: 4.3125rem;
}
.flex-col1.gap70 > * {
  width: calc(100% - 4.375rem / 1 * 0);
}
.flex-col1.gap70 {
  gap: 4.375rem;
}
.flex-col1.gap71 > * {
  width: calc(100% - 4.4375rem / 1 * 0);
}
.flex-col1.gap71 {
  gap: 4.4375rem;
}
.flex-col1.gap72 > * {
  width: calc(100% - 4.5rem / 1 * 0);
}
.flex-col1.gap72 {
  gap: 4.5rem;
}
.flex-col1.gap73 > * {
  width: calc(100% - 4.5625rem / 1 * 0);
}
.flex-col1.gap73 {
  gap: 4.5625rem;
}
.flex-col1.gap74 > * {
  width: calc(100% - 4.625rem / 1 * 0);
}
.flex-col1.gap74 {
  gap: 4.625rem;
}
.flex-col1.gap75 > * {
  width: calc(100% - 4.6875rem / 1 * 0);
}
.flex-col1.gap75 {
  gap: 4.6875rem;
}
.flex-col1.gap76 > * {
  width: calc(100% - 4.75rem / 1 * 0);
}
.flex-col1.gap76 {
  gap: 4.75rem;
}
.flex-col1.gap77 > * {
  width: calc(100% - 4.8125rem / 1 * 0);
}
.flex-col1.gap77 {
  gap: 4.8125rem;
}
.flex-col1.gap78 > * {
  width: calc(100% - 4.875rem / 1 * 0);
}
.flex-col1.gap78 {
  gap: 4.875rem;
}
.flex-col1.gap79 > * {
  width: calc(100% - 4.9375rem / 1 * 0);
}
.flex-col1.gap79 {
  gap: 4.9375rem;
}
.flex-col1.gap80 > * {
  width: calc(100% - 5rem / 1 * 0);
}
.flex-col1.gap80 {
  gap: 5rem;
}
.flex-col1.gap81 > * {
  width: calc(100% - 5.0625rem / 1 * 0);
}
.flex-col1.gap81 {
  gap: 5.0625rem;
}
.flex-col1.gap82 > * {
  width: calc(100% - 5.125rem / 1 * 0);
}
.flex-col1.gap82 {
  gap: 5.125rem;
}
.flex-col1.gap83 > * {
  width: calc(100% - 5.1875rem / 1 * 0);
}
.flex-col1.gap83 {
  gap: 5.1875rem;
}
.flex-col1.gap84 > * {
  width: calc(100% - 5.25rem / 1 * 0);
}
.flex-col1.gap84 {
  gap: 5.25rem;
}
.flex-col1.gap85 > * {
  width: calc(100% - 5.3125rem / 1 * 0);
}
.flex-col1.gap85 {
  gap: 5.3125rem;
}
.flex-col1.gap86 > * {
  width: calc(100% - 5.375rem / 1 * 0);
}
.flex-col1.gap86 {
  gap: 5.375rem;
}
.flex-col1.gap87 > * {
  width: calc(100% - 5.4375rem / 1 * 0);
}
.flex-col1.gap87 {
  gap: 5.4375rem;
}
.flex-col1.gap88 > * {
  width: calc(100% - 5.5rem / 1 * 0);
}
.flex-col1.gap88 {
  gap: 5.5rem;
}
.flex-col1.gap89 > * {
  width: calc(100% - 5.5625rem / 1 * 0);
}
.flex-col1.gap89 {
  gap: 5.5625rem;
}
.flex-col1.gap90 > * {
  width: calc(100% - 5.625rem / 1 * 0);
}
.flex-col1.gap90 {
  gap: 5.625rem;
}
.flex-col1.gap91 > * {
  width: calc(100% - 5.6875rem / 1 * 0);
}
.flex-col1.gap91 {
  gap: 5.6875rem;
}
.flex-col1.gap92 > * {
  width: calc(100% - 5.75rem / 1 * 0);
}
.flex-col1.gap92 {
  gap: 5.75rem;
}
.flex-col1.gap93 > * {
  width: calc(100% - 5.8125rem / 1 * 0);
}
.flex-col1.gap93 {
  gap: 5.8125rem;
}
.flex-col1.gap94 > * {
  width: calc(100% - 5.875rem / 1 * 0);
}
.flex-col1.gap94 {
  gap: 5.875rem;
}
.flex-col1.gap95 > * {
  width: calc(100% - 5.9375rem / 1 * 0);
}
.flex-col1.gap95 {
  gap: 5.9375rem;
}
.flex-col1.gap96 > * {
  width: calc(100% - 6rem / 1 * 0);
}
.flex-col1.gap96 {
  gap: 6rem;
}
.flex-col1.gap97 > * {
  width: calc(100% - 6.0625rem / 1 * 0);
}
.flex-col1.gap97 {
  gap: 6.0625rem;
}
.flex-col1.gap98 > * {
  width: calc(100% - 6.125rem / 1 * 0);
}
.flex-col1.gap98 {
  gap: 6.125rem;
}
.flex-col1.gap99 > * {
  width: calc(100% - 6.1875rem / 1 * 0);
}
.flex-col1.gap99 {
  gap: 6.1875rem;
}
.flex-col1.gap100 > * {
  width: calc(100% - 6.25rem / 1 * 0);
}
.flex-col1.gap100 {
  gap: 6.25rem;
}
.flex-col1.gap101 > * {
  width: calc(100% - 6.3125rem / 1 * 0);
}
.flex-col1.gap101 {
  gap: 6.3125rem;
}
.flex-col1.gap102 > * {
  width: calc(100% - 6.375rem / 1 * 0);
}
.flex-col1.gap102 {
  gap: 6.375rem;
}
.flex-col1.gap103 > * {
  width: calc(100% - 6.4375rem / 1 * 0);
}
.flex-col1.gap103 {
  gap: 6.4375rem;
}
.flex-col1.gap104 > * {
  width: calc(100% - 6.5rem / 1 * 0);
}
.flex-col1.gap104 {
  gap: 6.5rem;
}
.flex-col1.gap105 > * {
  width: calc(100% - 6.5625rem / 1 * 0);
}
.flex-col1.gap105 {
  gap: 6.5625rem;
}
.flex-col1.gap106 > * {
  width: calc(100% - 6.625rem / 1 * 0);
}
.flex-col1.gap106 {
  gap: 6.625rem;
}
.flex-col1.gap107 > * {
  width: calc(100% - 6.6875rem / 1 * 0);
}
.flex-col1.gap107 {
  gap: 6.6875rem;
}
.flex-col1.gap108 > * {
  width: calc(100% - 6.75rem / 1 * 0);
}
.flex-col1.gap108 {
  gap: 6.75rem;
}
.flex-col1.gap109 > * {
  width: calc(100% - 6.8125rem / 1 * 0);
}
.flex-col1.gap109 {
  gap: 6.8125rem;
}
.flex-col1.gap110 > * {
  width: calc(100% - 6.875rem / 1 * 0);
}
.flex-col1.gap110 {
  gap: 6.875rem;
}
.flex-col1.gap111 > * {
  width: calc(100% - 6.9375rem / 1 * 0);
}
.flex-col1.gap111 {
  gap: 6.9375rem;
}
.flex-col1.gap112 > * {
  width: calc(100% - 7rem / 1 * 0);
}
.flex-col1.gap112 {
  gap: 7rem;
}
.flex-col1.gap113 > * {
  width: calc(100% - 7.0625rem / 1 * 0);
}
.flex-col1.gap113 {
  gap: 7.0625rem;
}
.flex-col1.gap114 > * {
  width: calc(100% - 7.125rem / 1 * 0);
}
.flex-col1.gap114 {
  gap: 7.125rem;
}
.flex-col1.gap115 > * {
  width: calc(100% - 7.1875rem / 1 * 0);
}
.flex-col1.gap115 {
  gap: 7.1875rem;
}
.flex-col1.gap116 > * {
  width: calc(100% - 7.25rem / 1 * 0);
}
.flex-col1.gap116 {
  gap: 7.25rem;
}
.flex-col1.gap117 > * {
  width: calc(100% - 7.3125rem / 1 * 0);
}
.flex-col1.gap117 {
  gap: 7.3125rem;
}
.flex-col1.gap118 > * {
  width: calc(100% - 7.375rem / 1 * 0);
}
.flex-col1.gap118 {
  gap: 7.375rem;
}
.flex-col1.gap119 > * {
  width: calc(100% - 7.4375rem / 1 * 0);
}
.flex-col1.gap119 {
  gap: 7.4375rem;
}
.flex-col1.gap120 > * {
  width: calc(100% - 7.5rem / 1 * 0);
}
.flex-col1.gap120 {
  gap: 7.5rem;
}
.flex-col1.gap121 > * {
  width: calc(100% - 7.5625rem / 1 * 0);
}
.flex-col1.gap121 {
  gap: 7.5625rem;
}
.flex-col1.gap122 > * {
  width: calc(100% - 7.625rem / 1 * 0);
}
.flex-col1.gap122 {
  gap: 7.625rem;
}
.flex-col1.gap123 > * {
  width: calc(100% - 7.6875rem / 1 * 0);
}
.flex-col1.gap123 {
  gap: 7.6875rem;
}
.flex-col1.gap124 > * {
  width: calc(100% - 7.75rem / 1 * 0);
}
.flex-col1.gap124 {
  gap: 7.75rem;
}
.flex-col1.gap125 > * {
  width: calc(100% - 7.8125rem / 1 * 0);
}
.flex-col1.gap125 {
  gap: 7.8125rem;
}
.flex-col1.gap126 > * {
  width: calc(100% - 7.875rem / 1 * 0);
}
.flex-col1.gap126 {
  gap: 7.875rem;
}
.flex-col1.gap127 > * {
  width: calc(100% - 7.9375rem / 1 * 0);
}
.flex-col1.gap127 {
  gap: 7.9375rem;
}
.flex-col1.gap128 > * {
  width: calc(100% - 8rem / 1 * 0);
}
.flex-col1.gap128 {
  gap: 8rem;
}
.flex-col1.gap129 > * {
  width: calc(100% - 8.0625rem / 1 * 0);
}
.flex-col1.gap129 {
  gap: 8.0625rem;
}
.flex-col1.gap130 > * {
  width: calc(100% - 8.125rem / 1 * 0);
}
.flex-col1.gap130 {
  gap: 8.125rem;
}
.flex-col1.gap131 > * {
  width: calc(100% - 8.1875rem / 1 * 0);
}
.flex-col1.gap131 {
  gap: 8.1875rem;
}
.flex-col1.gap132 > * {
  width: calc(100% - 8.25rem / 1 * 0);
}
.flex-col1.gap132 {
  gap: 8.25rem;
}
.flex-col1.gap133 > * {
  width: calc(100% - 8.3125rem / 1 * 0);
}
.flex-col1.gap133 {
  gap: 8.3125rem;
}
.flex-col1.gap134 > * {
  width: calc(100% - 8.375rem / 1 * 0);
}
.flex-col1.gap134 {
  gap: 8.375rem;
}
.flex-col1.gap135 > * {
  width: calc(100% - 8.4375rem / 1 * 0);
}
.flex-col1.gap135 {
  gap: 8.4375rem;
}
.flex-col1.gap136 > * {
  width: calc(100% - 8.5rem / 1 * 0);
}
.flex-col1.gap136 {
  gap: 8.5rem;
}
.flex-col1.gap137 > * {
  width: calc(100% - 8.5625rem / 1 * 0);
}
.flex-col1.gap137 {
  gap: 8.5625rem;
}
.flex-col1.gap138 > * {
  width: calc(100% - 8.625rem / 1 * 0);
}
.flex-col1.gap138 {
  gap: 8.625rem;
}
.flex-col1.gap139 > * {
  width: calc(100% - 8.6875rem / 1 * 0);
}
.flex-col1.gap139 {
  gap: 8.6875rem;
}
.flex-col1.gap140 > * {
  width: calc(100% - 8.75rem / 1 * 0);
}
.flex-col1.gap140 {
  gap: 8.75rem;
}
.flex-col1.gap141 > * {
  width: calc(100% - 8.8125rem / 1 * 0);
}
.flex-col1.gap141 {
  gap: 8.8125rem;
}
.flex-col1.gap142 > * {
  width: calc(100% - 8.875rem / 1 * 0);
}
.flex-col1.gap142 {
  gap: 8.875rem;
}
.flex-col1.gap143 > * {
  width: calc(100% - 8.9375rem / 1 * 0);
}
.flex-col1.gap143 {
  gap: 8.9375rem;
}
.flex-col1.gap144 > * {
  width: calc(100% - 9rem / 1 * 0);
}
.flex-col1.gap144 {
  gap: 9rem;
}
.flex-col1.gap145 > * {
  width: calc(100% - 9.0625rem / 1 * 0);
}
.flex-col1.gap145 {
  gap: 9.0625rem;
}
.flex-col1.gap146 > * {
  width: calc(100% - 9.125rem / 1 * 0);
}
.flex-col1.gap146 {
  gap: 9.125rem;
}
.flex-col1.gap147 > * {
  width: calc(100% - 9.1875rem / 1 * 0);
}
.flex-col1.gap147 {
  gap: 9.1875rem;
}
.flex-col1.gap148 > * {
  width: calc(100% - 9.25rem / 1 * 0);
}
.flex-col1.gap148 {
  gap: 9.25rem;
}
.flex-col1.gap149 > * {
  width: calc(100% - 9.3125rem / 1 * 0);
}
.flex-col1.gap149 {
  gap: 9.3125rem;
}
.flex-col1.gap150 > * {
  width: calc(100% - 9.375rem / 1 * 0);
}
.flex-col1.gap150 {
  gap: 9.375rem;
}

.flex-col2 {
  flex-wrap: wrap;
}
.flex-col2 > * {
  width: 50%;
}
.flex-col2.gap1 > * {
  width: calc(50% - 0.0625rem / 2 * 1);
}
.flex-col2.gap1 {
  gap: 0.0625rem;
}
.flex-col2.gap2 > * {
  width: calc(50% - 0.125rem / 2 * 1);
}
.flex-col2.gap2 {
  gap: 0.125rem;
}
.flex-col2.gap3 > * {
  width: calc(50% - 0.1875rem / 2 * 1);
}
.flex-col2.gap3 {
  gap: 0.1875rem;
}
.flex-col2.gap4 > * {
  width: calc(50% - 0.25rem / 2 * 1);
}
.flex-col2.gap4 {
  gap: 0.25rem;
}
.flex-col2.gap5 > * {
  width: calc(50% - 0.3125rem / 2 * 1);
}
.flex-col2.gap5 {
  gap: 0.3125rem;
}
.flex-col2.gap6 > * {
  width: calc(50% - 0.375rem / 2 * 1);
}
.flex-col2.gap6 {
  gap: 0.375rem;
}
.flex-col2.gap7 > * {
  width: calc(50% - 0.4375rem / 2 * 1);
}
.flex-col2.gap7 {
  gap: 0.4375rem;
}
.flex-col2.gap8 > * {
  width: calc(50% - 0.5rem / 2 * 1);
}
.flex-col2.gap8 {
  gap: 0.5rem;
}
.flex-col2.gap9 > * {
  width: calc(50% - 0.5625rem / 2 * 1);
}
.flex-col2.gap9 {
  gap: 0.5625rem;
}
.flex-col2.gap10 > * {
  width: calc(50% - 0.625rem / 2 * 1);
}
.flex-col2.gap10 {
  gap: 0.625rem;
}
.flex-col2.gap11 > * {
  width: calc(50% - 0.6875rem / 2 * 1);
}
.flex-col2.gap11 {
  gap: 0.6875rem;
}
.flex-col2.gap12 > * {
  width: calc(50% - 0.75rem / 2 * 1);
}
.flex-col2.gap12 {
  gap: 0.75rem;
}
.flex-col2.gap13 > * {
  width: calc(50% - 0.8125rem / 2 * 1);
}
.flex-col2.gap13 {
  gap: 0.8125rem;
}
.flex-col2.gap14 > * {
  width: calc(50% - 0.875rem / 2 * 1);
}
.flex-col2.gap14 {
  gap: 0.875rem;
}
.flex-col2.gap15 > * {
  width: calc(50% - 0.9375rem / 2 * 1);
}
.flex-col2.gap15 {
  gap: 0.9375rem;
}
.flex-col2.gap16 > * {
  width: calc(50% - 1rem / 2 * 1);
}
.flex-col2.gap16 {
  gap: 1rem;
}
.flex-col2.gap17 > * {
  width: calc(50% - 1.0625rem / 2 * 1);
}
.flex-col2.gap17 {
  gap: 1.0625rem;
}
.flex-col2.gap18 > * {
  width: calc(50% - 1.125rem / 2 * 1);
}
.flex-col2.gap18 {
  gap: 1.125rem;
}
.flex-col2.gap19 > * {
  width: calc(50% - 1.1875rem / 2 * 1);
}
.flex-col2.gap19 {
  gap: 1.1875rem;
}
.flex-col2.gap20 > * {
  width: calc(50% - 1.25rem / 2 * 1);
}
.flex-col2.gap20 {
  gap: 1.25rem;
}
.flex-col2.gap21 > * {
  width: calc(50% - 1.3125rem / 2 * 1);
}
.flex-col2.gap21 {
  gap: 1.3125rem;
}
.flex-col2.gap22 > * {
  width: calc(50% - 1.375rem / 2 * 1);
}
.flex-col2.gap22 {
  gap: 1.375rem;
}
.flex-col2.gap23 > * {
  width: calc(50% - 1.4375rem / 2 * 1);
}
.flex-col2.gap23 {
  gap: 1.4375rem;
}
.flex-col2.gap24 > * {
  width: calc(50% - 1.5rem / 2 * 1);
}
.flex-col2.gap24 {
  gap: 1.5rem;
}
.flex-col2.gap25 > * {
  width: calc(50% - 1.5625rem / 2 * 1);
}
.flex-col2.gap25 {
  gap: 1.5625rem;
}
.flex-col2.gap26 > * {
  width: calc(50% - 1.625rem / 2 * 1);
}
.flex-col2.gap26 {
  gap: 1.625rem;
}
.flex-col2.gap27 > * {
  width: calc(50% - 1.6875rem / 2 * 1);
}
.flex-col2.gap27 {
  gap: 1.6875rem;
}
.flex-col2.gap28 > * {
  width: calc(50% - 1.75rem / 2 * 1);
}
.flex-col2.gap28 {
  gap: 1.75rem;
}
.flex-col2.gap29 > * {
  width: calc(50% - 1.8125rem / 2 * 1);
}
.flex-col2.gap29 {
  gap: 1.8125rem;
}
.flex-col2.gap30 > * {
  width: calc(50% - 1.875rem / 2 * 1);
}
.flex-col2.gap30 {
  gap: 1.875rem;
}
.flex-col2.gap31 > * {
  width: calc(50% - 1.9375rem / 2 * 1);
}
.flex-col2.gap31 {
  gap: 1.9375rem;
}
.flex-col2.gap32 > * {
  width: calc(50% - 2rem / 2 * 1);
}
.flex-col2.gap32 {
  gap: 2rem;
}
.flex-col2.gap33 > * {
  width: calc(50% - 2.0625rem / 2 * 1);
}
.flex-col2.gap33 {
  gap: 2.0625rem;
}
.flex-col2.gap34 > * {
  width: calc(50% - 2.125rem / 2 * 1);
}
.flex-col2.gap34 {
  gap: 2.125rem;
}
.flex-col2.gap35 > * {
  width: calc(50% - 2.1875rem / 2 * 1);
}
.flex-col2.gap35 {
  gap: 2.1875rem;
}
.flex-col2.gap36 > * {
  width: calc(50% - 2.25rem / 2 * 1);
}
.flex-col2.gap36 {
  gap: 2.25rem;
}
.flex-col2.gap37 > * {
  width: calc(50% - 2.3125rem / 2 * 1);
}
.flex-col2.gap37 {
  gap: 2.3125rem;
}
.flex-col2.gap38 > * {
  width: calc(50% - 2.375rem / 2 * 1);
}
.flex-col2.gap38 {
  gap: 2.375rem;
}
.flex-col2.gap39 > * {
  width: calc(50% - 2.4375rem / 2 * 1);
}
.flex-col2.gap39 {
  gap: 2.4375rem;
}
.flex-col2.gap40 > * {
  width: calc(50% - 2.5rem / 2 * 1);
}
.flex-col2.gap40 {
  gap: 2.5rem;
}
.flex-col2.gap41 > * {
  width: calc(50% - 2.5625rem / 2 * 1);
}
.flex-col2.gap41 {
  gap: 2.5625rem;
}
.flex-col2.gap42 > * {
  width: calc(50% - 2.625rem / 2 * 1);
}
.flex-col2.gap42 {
  gap: 2.625rem;
}
.flex-col2.gap43 > * {
  width: calc(50% - 2.6875rem / 2 * 1);
}
.flex-col2.gap43 {
  gap: 2.6875rem;
}
.flex-col2.gap44 > * {
  width: calc(50% - 2.75rem / 2 * 1);
}
.flex-col2.gap44 {
  gap: 2.75rem;
}
.flex-col2.gap45 > * {
  width: calc(50% - 2.8125rem / 2 * 1);
}
.flex-col2.gap45 {
  gap: 2.8125rem;
}
.flex-col2.gap46 > * {
  width: calc(50% - 2.875rem / 2 * 1);
}
.flex-col2.gap46 {
  gap: 2.875rem;
}
.flex-col2.gap47 > * {
  width: calc(50% - 2.9375rem / 2 * 1);
}
.flex-col2.gap47 {
  gap: 2.9375rem;
}
.flex-col2.gap48 > * {
  width: calc(50% - 3rem / 2 * 1);
}
.flex-col2.gap48 {
  gap: 3rem;
}
.flex-col2.gap49 > * {
  width: calc(50% - 3.0625rem / 2 * 1);
}
.flex-col2.gap49 {
  gap: 3.0625rem;
}
.flex-col2.gap50 > * {
  width: calc(50% - 3.125rem / 2 * 1);
}
.flex-col2.gap50 {
  gap: 3.125rem;
}
.flex-col2.gap51 > * {
  width: calc(50% - 3.1875rem / 2 * 1);
}
.flex-col2.gap51 {
  gap: 3.1875rem;
}
.flex-col2.gap52 > * {
  width: calc(50% - 3.25rem / 2 * 1);
}
.flex-col2.gap52 {
  gap: 3.25rem;
}
.flex-col2.gap53 > * {
  width: calc(50% - 3.3125rem / 2 * 1);
}
.flex-col2.gap53 {
  gap: 3.3125rem;
}
.flex-col2.gap54 > * {
  width: calc(50% - 3.375rem / 2 * 1);
}
.flex-col2.gap54 {
  gap: 3.375rem;
}
.flex-col2.gap55 > * {
  width: calc(50% - 3.4375rem / 2 * 1);
}
.flex-col2.gap55 {
  gap: 3.4375rem;
}
.flex-col2.gap56 > * {
  width: calc(50% - 3.5rem / 2 * 1);
}
.flex-col2.gap56 {
  gap: 3.5rem;
}
.flex-col2.gap57 > * {
  width: calc(50% - 3.5625rem / 2 * 1);
}
.flex-col2.gap57 {
  gap: 3.5625rem;
}
.flex-col2.gap58 > * {
  width: calc(50% - 3.625rem / 2 * 1);
}
.flex-col2.gap58 {
  gap: 3.625rem;
}
.flex-col2.gap59 > * {
  width: calc(50% - 3.6875rem / 2 * 1);
}
.flex-col2.gap59 {
  gap: 3.6875rem;
}
.flex-col2.gap60 > * {
  width: calc(50% - 3.75rem / 2 * 1);
}
.flex-col2.gap60 {
  gap: 3.75rem;
}
.flex-col2.gap61 > * {
  width: calc(50% - 3.8125rem / 2 * 1);
}
.flex-col2.gap61 {
  gap: 3.8125rem;
}
.flex-col2.gap62 > * {
  width: calc(50% - 3.875rem / 2 * 1);
}
.flex-col2.gap62 {
  gap: 3.875rem;
}
.flex-col2.gap63 > * {
  width: calc(50% - 3.9375rem / 2 * 1);
}
.flex-col2.gap63 {
  gap: 3.9375rem;
}
.flex-col2.gap64 > * {
  width: calc(50% - 4rem / 2 * 1);
}
.flex-col2.gap64 {
  gap: 4rem;
}
.flex-col2.gap65 > * {
  width: calc(50% - 4.0625rem / 2 * 1);
}
.flex-col2.gap65 {
  gap: 4.0625rem;
}
.flex-col2.gap66 > * {
  width: calc(50% - 4.125rem / 2 * 1);
}
.flex-col2.gap66 {
  gap: 4.125rem;
}
.flex-col2.gap67 > * {
  width: calc(50% - 4.1875rem / 2 * 1);
}
.flex-col2.gap67 {
  gap: 4.1875rem;
}
.flex-col2.gap68 > * {
  width: calc(50% - 4.25rem / 2 * 1);
}
.flex-col2.gap68 {
  gap: 4.25rem;
}
.flex-col2.gap69 > * {
  width: calc(50% - 4.3125rem / 2 * 1);
}
.flex-col2.gap69 {
  gap: 4.3125rem;
}
.flex-col2.gap70 > * {
  width: calc(50% - 4.375rem / 2 * 1);
}
.flex-col2.gap70 {
  gap: 4.375rem;
}
.flex-col2.gap71 > * {
  width: calc(50% - 4.4375rem / 2 * 1);
}
.flex-col2.gap71 {
  gap: 4.4375rem;
}
.flex-col2.gap72 > * {
  width: calc(50% - 4.5rem / 2 * 1);
}
.flex-col2.gap72 {
  gap: 4.5rem;
}
.flex-col2.gap73 > * {
  width: calc(50% - 4.5625rem / 2 * 1);
}
.flex-col2.gap73 {
  gap: 4.5625rem;
}
.flex-col2.gap74 > * {
  width: calc(50% - 4.625rem / 2 * 1);
}
.flex-col2.gap74 {
  gap: 4.625rem;
}
.flex-col2.gap75 > * {
  width: calc(50% - 4.6875rem / 2 * 1);
}
.flex-col2.gap75 {
  gap: 4.6875rem;
}
.flex-col2.gap76 > * {
  width: calc(50% - 4.75rem / 2 * 1);
}
.flex-col2.gap76 {
  gap: 4.75rem;
}
.flex-col2.gap77 > * {
  width: calc(50% - 4.8125rem / 2 * 1);
}
.flex-col2.gap77 {
  gap: 4.8125rem;
}
.flex-col2.gap78 > * {
  width: calc(50% - 4.875rem / 2 * 1);
}
.flex-col2.gap78 {
  gap: 4.875rem;
}
.flex-col2.gap79 > * {
  width: calc(50% - 4.9375rem / 2 * 1);
}
.flex-col2.gap79 {
  gap: 4.9375rem;
}
.flex-col2.gap80 > * {
  width: calc(50% - 5rem / 2 * 1);
}
.flex-col2.gap80 {
  gap: 5rem;
}
.flex-col2.gap81 > * {
  width: calc(50% - 5.0625rem / 2 * 1);
}
.flex-col2.gap81 {
  gap: 5.0625rem;
}
.flex-col2.gap82 > * {
  width: calc(50% - 5.125rem / 2 * 1);
}
.flex-col2.gap82 {
  gap: 5.125rem;
}
.flex-col2.gap83 > * {
  width: calc(50% - 5.1875rem / 2 * 1);
}
.flex-col2.gap83 {
  gap: 5.1875rem;
}
.flex-col2.gap84 > * {
  width: calc(50% - 5.25rem / 2 * 1);
}
.flex-col2.gap84 {
  gap: 5.25rem;
}
.flex-col2.gap85 > * {
  width: calc(50% - 5.3125rem / 2 * 1);
}
.flex-col2.gap85 {
  gap: 5.3125rem;
}
.flex-col2.gap86 > * {
  width: calc(50% - 5.375rem / 2 * 1);
}
.flex-col2.gap86 {
  gap: 5.375rem;
}
.flex-col2.gap87 > * {
  width: calc(50% - 5.4375rem / 2 * 1);
}
.flex-col2.gap87 {
  gap: 5.4375rem;
}
.flex-col2.gap88 > * {
  width: calc(50% - 5.5rem / 2 * 1);
}
.flex-col2.gap88 {
  gap: 5.5rem;
}
.flex-col2.gap89 > * {
  width: calc(50% - 5.5625rem / 2 * 1);
}
.flex-col2.gap89 {
  gap: 5.5625rem;
}
.flex-col2.gap90 > * {
  width: calc(50% - 5.625rem / 2 * 1);
}
.flex-col2.gap90 {
  gap: 5.625rem;
}
.flex-col2.gap91 > * {
  width: calc(50% - 5.6875rem / 2 * 1);
}
.flex-col2.gap91 {
  gap: 5.6875rem;
}
.flex-col2.gap92 > * {
  width: calc(50% - 5.75rem / 2 * 1);
}
.flex-col2.gap92 {
  gap: 5.75rem;
}
.flex-col2.gap93 > * {
  width: calc(50% - 5.8125rem / 2 * 1);
}
.flex-col2.gap93 {
  gap: 5.8125rem;
}
.flex-col2.gap94 > * {
  width: calc(50% - 5.875rem / 2 * 1);
}
.flex-col2.gap94 {
  gap: 5.875rem;
}
.flex-col2.gap95 > * {
  width: calc(50% - 5.9375rem / 2 * 1);
}
.flex-col2.gap95 {
  gap: 5.9375rem;
}
.flex-col2.gap96 > * {
  width: calc(50% - 6rem / 2 * 1);
}
.flex-col2.gap96 {
  gap: 6rem;
}
.flex-col2.gap97 > * {
  width: calc(50% - 6.0625rem / 2 * 1);
}
.flex-col2.gap97 {
  gap: 6.0625rem;
}
.flex-col2.gap98 > * {
  width: calc(50% - 6.125rem / 2 * 1);
}
.flex-col2.gap98 {
  gap: 6.125rem;
}
.flex-col2.gap99 > * {
  width: calc(50% - 6.1875rem / 2 * 1);
}
.flex-col2.gap99 {
  gap: 6.1875rem;
}
.flex-col2.gap100 > * {
  width: calc(50% - 6.25rem / 2 * 1);
}
.flex-col2.gap100 {
  gap: 6.25rem;
}
.flex-col2.gap101 > * {
  width: calc(50% - 6.3125rem / 2 * 1);
}
.flex-col2.gap101 {
  gap: 6.3125rem;
}
.flex-col2.gap102 > * {
  width: calc(50% - 6.375rem / 2 * 1);
}
.flex-col2.gap102 {
  gap: 6.375rem;
}
.flex-col2.gap103 > * {
  width: calc(50% - 6.4375rem / 2 * 1);
}
.flex-col2.gap103 {
  gap: 6.4375rem;
}
.flex-col2.gap104 > * {
  width: calc(50% - 6.5rem / 2 * 1);
}
.flex-col2.gap104 {
  gap: 6.5rem;
}
.flex-col2.gap105 > * {
  width: calc(50% - 6.5625rem / 2 * 1);
}
.flex-col2.gap105 {
  gap: 6.5625rem;
}
.flex-col2.gap106 > * {
  width: calc(50% - 6.625rem / 2 * 1);
}
.flex-col2.gap106 {
  gap: 6.625rem;
}
.flex-col2.gap107 > * {
  width: calc(50% - 6.6875rem / 2 * 1);
}
.flex-col2.gap107 {
  gap: 6.6875rem;
}
.flex-col2.gap108 > * {
  width: calc(50% - 6.75rem / 2 * 1);
}
.flex-col2.gap108 {
  gap: 6.75rem;
}
.flex-col2.gap109 > * {
  width: calc(50% - 6.8125rem / 2 * 1);
}
.flex-col2.gap109 {
  gap: 6.8125rem;
}
.flex-col2.gap110 > * {
  width: calc(50% - 6.875rem / 2 * 1);
}
.flex-col2.gap110 {
  gap: 6.875rem;
}
.flex-col2.gap111 > * {
  width: calc(50% - 6.9375rem / 2 * 1);
}
.flex-col2.gap111 {
  gap: 6.9375rem;
}
.flex-col2.gap112 > * {
  width: calc(50% - 7rem / 2 * 1);
}
.flex-col2.gap112 {
  gap: 7rem;
}
.flex-col2.gap113 > * {
  width: calc(50% - 7.0625rem / 2 * 1);
}
.flex-col2.gap113 {
  gap: 7.0625rem;
}
.flex-col2.gap114 > * {
  width: calc(50% - 7.125rem / 2 * 1);
}
.flex-col2.gap114 {
  gap: 7.125rem;
}
.flex-col2.gap115 > * {
  width: calc(50% - 7.1875rem / 2 * 1);
}
.flex-col2.gap115 {
  gap: 7.1875rem;
}
.flex-col2.gap116 > * {
  width: calc(50% - 7.25rem / 2 * 1);
}
.flex-col2.gap116 {
  gap: 7.25rem;
}
.flex-col2.gap117 > * {
  width: calc(50% - 7.3125rem / 2 * 1);
}
.flex-col2.gap117 {
  gap: 7.3125rem;
}
.flex-col2.gap118 > * {
  width: calc(50% - 7.375rem / 2 * 1);
}
.flex-col2.gap118 {
  gap: 7.375rem;
}
.flex-col2.gap119 > * {
  width: calc(50% - 7.4375rem / 2 * 1);
}
.flex-col2.gap119 {
  gap: 7.4375rem;
}
.flex-col2.gap120 > * {
  width: calc(50% - 7.5rem / 2 * 1);
}
.flex-col2.gap120 {
  gap: 7.5rem;
}
.flex-col2.gap121 > * {
  width: calc(50% - 7.5625rem / 2 * 1);
}
.flex-col2.gap121 {
  gap: 7.5625rem;
}
.flex-col2.gap122 > * {
  width: calc(50% - 7.625rem / 2 * 1);
}
.flex-col2.gap122 {
  gap: 7.625rem;
}
.flex-col2.gap123 > * {
  width: calc(50% - 7.6875rem / 2 * 1);
}
.flex-col2.gap123 {
  gap: 7.6875rem;
}
.flex-col2.gap124 > * {
  width: calc(50% - 7.75rem / 2 * 1);
}
.flex-col2.gap124 {
  gap: 7.75rem;
}
.flex-col2.gap125 > * {
  width: calc(50% - 7.8125rem / 2 * 1);
}
.flex-col2.gap125 {
  gap: 7.8125rem;
}
.flex-col2.gap126 > * {
  width: calc(50% - 7.875rem / 2 * 1);
}
.flex-col2.gap126 {
  gap: 7.875rem;
}
.flex-col2.gap127 > * {
  width: calc(50% - 7.9375rem / 2 * 1);
}
.flex-col2.gap127 {
  gap: 7.9375rem;
}
.flex-col2.gap128 > * {
  width: calc(50% - 8rem / 2 * 1);
}
.flex-col2.gap128 {
  gap: 8rem;
}
.flex-col2.gap129 > * {
  width: calc(50% - 8.0625rem / 2 * 1);
}
.flex-col2.gap129 {
  gap: 8.0625rem;
}
.flex-col2.gap130 > * {
  width: calc(50% - 8.125rem / 2 * 1);
}
.flex-col2.gap130 {
  gap: 8.125rem;
}
.flex-col2.gap131 > * {
  width: calc(50% - 8.1875rem / 2 * 1);
}
.flex-col2.gap131 {
  gap: 8.1875rem;
}
.flex-col2.gap132 > * {
  width: calc(50% - 8.25rem / 2 * 1);
}
.flex-col2.gap132 {
  gap: 8.25rem;
}
.flex-col2.gap133 > * {
  width: calc(50% - 8.3125rem / 2 * 1);
}
.flex-col2.gap133 {
  gap: 8.3125rem;
}
.flex-col2.gap134 > * {
  width: calc(50% - 8.375rem / 2 * 1);
}
.flex-col2.gap134 {
  gap: 8.375rem;
}
.flex-col2.gap135 > * {
  width: calc(50% - 8.4375rem / 2 * 1);
}
.flex-col2.gap135 {
  gap: 8.4375rem;
}
.flex-col2.gap136 > * {
  width: calc(50% - 8.5rem / 2 * 1);
}
.flex-col2.gap136 {
  gap: 8.5rem;
}
.flex-col2.gap137 > * {
  width: calc(50% - 8.5625rem / 2 * 1);
}
.flex-col2.gap137 {
  gap: 8.5625rem;
}
.flex-col2.gap138 > * {
  width: calc(50% - 8.625rem / 2 * 1);
}
.flex-col2.gap138 {
  gap: 8.625rem;
}
.flex-col2.gap139 > * {
  width: calc(50% - 8.6875rem / 2 * 1);
}
.flex-col2.gap139 {
  gap: 8.6875rem;
}
.flex-col2.gap140 > * {
  width: calc(50% - 8.75rem / 2 * 1);
}
.flex-col2.gap140 {
  gap: 8.75rem;
}
.flex-col2.gap141 > * {
  width: calc(50% - 8.8125rem / 2 * 1);
}
.flex-col2.gap141 {
  gap: 8.8125rem;
}
.flex-col2.gap142 > * {
  width: calc(50% - 8.875rem / 2 * 1);
}
.flex-col2.gap142 {
  gap: 8.875rem;
}
.flex-col2.gap143 > * {
  width: calc(50% - 8.9375rem / 2 * 1);
}
.flex-col2.gap143 {
  gap: 8.9375rem;
}
.flex-col2.gap144 > * {
  width: calc(50% - 9rem / 2 * 1);
}
.flex-col2.gap144 {
  gap: 9rem;
}
.flex-col2.gap145 > * {
  width: calc(50% - 9.0625rem / 2 * 1);
}
.flex-col2.gap145 {
  gap: 9.0625rem;
}
.flex-col2.gap146 > * {
  width: calc(50% - 9.125rem / 2 * 1);
}
.flex-col2.gap146 {
  gap: 9.125rem;
}
.flex-col2.gap147 > * {
  width: calc(50% - 9.1875rem / 2 * 1);
}
.flex-col2.gap147 {
  gap: 9.1875rem;
}
.flex-col2.gap148 > * {
  width: calc(50% - 9.25rem / 2 * 1);
}
.flex-col2.gap148 {
  gap: 9.25rem;
}
.flex-col2.gap149 > * {
  width: calc(50% - 9.3125rem / 2 * 1);
}
.flex-col2.gap149 {
  gap: 9.3125rem;
}
.flex-col2.gap150 > * {
  width: calc(50% - 9.375rem / 2 * 1);
}
.flex-col2.gap150 {
  gap: 9.375rem;
}

.flex-col3 {
  flex-wrap: wrap;
}
.flex-col3 > * {
  width: 33.3333333333%;
}
.flex-col3.gap1 > * {
  width: calc(33.3333333333% - 0.0625rem / 3 * 2);
}
.flex-col3.gap1 {
  gap: 0.0625rem;
}
.flex-col3.gap2 > * {
  width: calc(33.3333333333% - 0.125rem / 3 * 2);
}
.flex-col3.gap2 {
  gap: 0.125rem;
}
.flex-col3.gap3 > * {
  width: calc(33.3333333333% - 0.1875rem / 3 * 2);
}
.flex-col3.gap3 {
  gap: 0.1875rem;
}
.flex-col3.gap4 > * {
  width: calc(33.3333333333% - 0.25rem / 3 * 2);
}
.flex-col3.gap4 {
  gap: 0.25rem;
}
.flex-col3.gap5 > * {
  width: calc(33.3333333333% - 0.3125rem / 3 * 2);
}
.flex-col3.gap5 {
  gap: 0.3125rem;
}
.flex-col3.gap6 > * {
  width: calc(33.3333333333% - 0.375rem / 3 * 2);
}
.flex-col3.gap6 {
  gap: 0.375rem;
}
.flex-col3.gap7 > * {
  width: calc(33.3333333333% - 0.4375rem / 3 * 2);
}
.flex-col3.gap7 {
  gap: 0.4375rem;
}
.flex-col3.gap8 > * {
  width: calc(33.3333333333% - 0.5rem / 3 * 2);
}
.flex-col3.gap8 {
  gap: 0.5rem;
}
.flex-col3.gap9 > * {
  width: calc(33.3333333333% - 0.5625rem / 3 * 2);
}
.flex-col3.gap9 {
  gap: 0.5625rem;
}
.flex-col3.gap10 > * {
  width: calc(33.3333333333% - 0.625rem / 3 * 2);
}
.flex-col3.gap10 {
  gap: 0.625rem;
}
.flex-col3.gap11 > * {
  width: calc(33.3333333333% - 0.6875rem / 3 * 2);
}
.flex-col3.gap11 {
  gap: 0.6875rem;
}
.flex-col3.gap12 > * {
  width: calc(33.3333333333% - 0.75rem / 3 * 2);
}
.flex-col3.gap12 {
  gap: 0.75rem;
}
.flex-col3.gap13 > * {
  width: calc(33.3333333333% - 0.8125rem / 3 * 2);
}
.flex-col3.gap13 {
  gap: 0.8125rem;
}
.flex-col3.gap14 > * {
  width: calc(33.3333333333% - 0.875rem / 3 * 2);
}
.flex-col3.gap14 {
  gap: 0.875rem;
}
.flex-col3.gap15 > * {
  width: calc(33.3333333333% - 0.9375rem / 3 * 2);
}
.flex-col3.gap15 {
  gap: 0.9375rem;
}
.flex-col3.gap16 > * {
  width: calc(33.3333333333% - 1rem / 3 * 2);
}
.flex-col3.gap16 {
  gap: 1rem;
}
.flex-col3.gap17 > * {
  width: calc(33.3333333333% - 1.0625rem / 3 * 2);
}
.flex-col3.gap17 {
  gap: 1.0625rem;
}
.flex-col3.gap18 > * {
  width: calc(33.3333333333% - 1.125rem / 3 * 2);
}
.flex-col3.gap18 {
  gap: 1.125rem;
}
.flex-col3.gap19 > * {
  width: calc(33.3333333333% - 1.1875rem / 3 * 2);
}
.flex-col3.gap19 {
  gap: 1.1875rem;
}
.flex-col3.gap20 > * {
  width: calc(33.3333333333% - 1.25rem / 3 * 2);
}
.flex-col3.gap20 {
  gap: 1.25rem;
}
.flex-col3.gap21 > * {
  width: calc(33.3333333333% - 1.3125rem / 3 * 2);
}
.flex-col3.gap21 {
  gap: 1.3125rem;
}
.flex-col3.gap22 > * {
  width: calc(33.3333333333% - 1.375rem / 3 * 2);
}
.flex-col3.gap22 {
  gap: 1.375rem;
}
.flex-col3.gap23 > * {
  width: calc(33.3333333333% - 1.4375rem / 3 * 2);
}
.flex-col3.gap23 {
  gap: 1.4375rem;
}
.flex-col3.gap24 > * {
  width: calc(33.3333333333% - 1.5rem / 3 * 2);
}
.flex-col3.gap24 {
  gap: 1.5rem;
}
.flex-col3.gap25 > * {
  width: calc(33.3333333333% - 1.5625rem / 3 * 2);
}
.flex-col3.gap25 {
  gap: 1.5625rem;
}
.flex-col3.gap26 > * {
  width: calc(33.3333333333% - 1.625rem / 3 * 2);
}
.flex-col3.gap26 {
  gap: 1.625rem;
}
.flex-col3.gap27 > * {
  width: calc(33.3333333333% - 1.6875rem / 3 * 2);
}
.flex-col3.gap27 {
  gap: 1.6875rem;
}
.flex-col3.gap28 > * {
  width: calc(33.3333333333% - 1.75rem / 3 * 2);
}
.flex-col3.gap28 {
  gap: 1.75rem;
}
.flex-col3.gap29 > * {
  width: calc(33.3333333333% - 1.8125rem / 3 * 2);
}
.flex-col3.gap29 {
  gap: 1.8125rem;
}
.flex-col3.gap30 > * {
  width: calc(33.3333333333% - 1.875rem / 3 * 2);
}
.flex-col3.gap30 {
  gap: 1.875rem;
}
.flex-col3.gap31 > * {
  width: calc(33.3333333333% - 1.9375rem / 3 * 2);
}
.flex-col3.gap31 {
  gap: 1.9375rem;
}
.flex-col3.gap32 > * {
  width: calc(33.3333333333% - 2rem / 3 * 2);
}
.flex-col3.gap32 {
  gap: 2rem;
}
.flex-col3.gap33 > * {
  width: calc(33.3333333333% - 2.0625rem / 3 * 2);
}
.flex-col3.gap33 {
  gap: 2.0625rem;
}
.flex-col3.gap34 > * {
  width: calc(33.3333333333% - 2.125rem / 3 * 2);
}
.flex-col3.gap34 {
  gap: 2.125rem;
}
.flex-col3.gap35 > * {
  width: calc(33.3333333333% - 2.1875rem / 3 * 2);
}
.flex-col3.gap35 {
  gap: 2.1875rem;
}
.flex-col3.gap36 > * {
  width: calc(33.3333333333% - 2.25rem / 3 * 2);
}
.flex-col3.gap36 {
  gap: 2.25rem;
}
.flex-col3.gap37 > * {
  width: calc(33.3333333333% - 2.3125rem / 3 * 2);
}
.flex-col3.gap37 {
  gap: 2.3125rem;
}
.flex-col3.gap38 > * {
  width: calc(33.3333333333% - 2.375rem / 3 * 2);
}
.flex-col3.gap38 {
  gap: 2.375rem;
}
.flex-col3.gap39 > * {
  width: calc(33.3333333333% - 2.4375rem / 3 * 2);
}
.flex-col3.gap39 {
  gap: 2.4375rem;
}
.flex-col3.gap40 > * {
  width: calc(33.3333333333% - 2.5rem / 3 * 2);
}
.flex-col3.gap40 {
  gap: 2.5rem;
}
.flex-col3.gap41 > * {
  width: calc(33.3333333333% - 2.5625rem / 3 * 2);
}
.flex-col3.gap41 {
  gap: 2.5625rem;
}
.flex-col3.gap42 > * {
  width: calc(33.3333333333% - 2.625rem / 3 * 2);
}
.flex-col3.gap42 {
  gap: 2.625rem;
}
.flex-col3.gap43 > * {
  width: calc(33.3333333333% - 2.6875rem / 3 * 2);
}
.flex-col3.gap43 {
  gap: 2.6875rem;
}
.flex-col3.gap44 > * {
  width: calc(33.3333333333% - 2.75rem / 3 * 2);
}
.flex-col3.gap44 {
  gap: 2.75rem;
}
.flex-col3.gap45 > * {
  width: calc(33.3333333333% - 2.8125rem / 3 * 2);
}
.flex-col3.gap45 {
  gap: 2.8125rem;
}
.flex-col3.gap46 > * {
  width: calc(33.3333333333% - 2.875rem / 3 * 2);
}
.flex-col3.gap46 {
  gap: 2.875rem;
}
.flex-col3.gap47 > * {
  width: calc(33.3333333333% - 2.9375rem / 3 * 2);
}
.flex-col3.gap47 {
  gap: 2.9375rem;
}
.flex-col3.gap48 > * {
  width: calc(33.3333333333% - 3rem / 3 * 2);
}
.flex-col3.gap48 {
  gap: 3rem;
}
.flex-col3.gap49 > * {
  width: calc(33.3333333333% - 3.0625rem / 3 * 2);
}
.flex-col3.gap49 {
  gap: 3.0625rem;
}
.flex-col3.gap50 > * {
  width: calc(33.3333333333% - 3.125rem / 3 * 2);
}
.flex-col3.gap50 {
  gap: 3.125rem;
}
.flex-col3.gap51 > * {
  width: calc(33.3333333333% - 3.1875rem / 3 * 2);
}
.flex-col3.gap51 {
  gap: 3.1875rem;
}
.flex-col3.gap52 > * {
  width: calc(33.3333333333% - 3.25rem / 3 * 2);
}
.flex-col3.gap52 {
  gap: 3.25rem;
}
.flex-col3.gap53 > * {
  width: calc(33.3333333333% - 3.3125rem / 3 * 2);
}
.flex-col3.gap53 {
  gap: 3.3125rem;
}
.flex-col3.gap54 > * {
  width: calc(33.3333333333% - 3.375rem / 3 * 2);
}
.flex-col3.gap54 {
  gap: 3.375rem;
}
.flex-col3.gap55 > * {
  width: calc(33.3333333333% - 3.4375rem / 3 * 2);
}
.flex-col3.gap55 {
  gap: 3.4375rem;
}
.flex-col3.gap56 > * {
  width: calc(33.3333333333% - 3.5rem / 3 * 2);
}
.flex-col3.gap56 {
  gap: 3.5rem;
}
.flex-col3.gap57 > * {
  width: calc(33.3333333333% - 3.5625rem / 3 * 2);
}
.flex-col3.gap57 {
  gap: 3.5625rem;
}
.flex-col3.gap58 > * {
  width: calc(33.3333333333% - 3.625rem / 3 * 2);
}
.flex-col3.gap58 {
  gap: 3.625rem;
}
.flex-col3.gap59 > * {
  width: calc(33.3333333333% - 3.6875rem / 3 * 2);
}
.flex-col3.gap59 {
  gap: 3.6875rem;
}
.flex-col3.gap60 > * {
  width: calc(33.3333333333% - 3.75rem / 3 * 2);
}
.flex-col3.gap60 {
  gap: 3.75rem;
}
.flex-col3.gap61 > * {
  width: calc(33.3333333333% - 3.8125rem / 3 * 2);
}
.flex-col3.gap61 {
  gap: 3.8125rem;
}
.flex-col3.gap62 > * {
  width: calc(33.3333333333% - 3.875rem / 3 * 2);
}
.flex-col3.gap62 {
  gap: 3.875rem;
}
.flex-col3.gap63 > * {
  width: calc(33.3333333333% - 3.9375rem / 3 * 2);
}
.flex-col3.gap63 {
  gap: 3.9375rem;
}
.flex-col3.gap64 > * {
  width: calc(33.3333333333% - 4rem / 3 * 2);
}
.flex-col3.gap64 {
  gap: 4rem;
}
.flex-col3.gap65 > * {
  width: calc(33.3333333333% - 4.0625rem / 3 * 2);
}
.flex-col3.gap65 {
  gap: 4.0625rem;
}
.flex-col3.gap66 > * {
  width: calc(33.3333333333% - 4.125rem / 3 * 2);
}
.flex-col3.gap66 {
  gap: 4.125rem;
}
.flex-col3.gap67 > * {
  width: calc(33.3333333333% - 4.1875rem / 3 * 2);
}
.flex-col3.gap67 {
  gap: 4.1875rem;
}
.flex-col3.gap68 > * {
  width: calc(33.3333333333% - 4.25rem / 3 * 2);
}
.flex-col3.gap68 {
  gap: 4.25rem;
}
.flex-col3.gap69 > * {
  width: calc(33.3333333333% - 4.3125rem / 3 * 2);
}
.flex-col3.gap69 {
  gap: 4.3125rem;
}
.flex-col3.gap70 > * {
  width: calc(33.3333333333% - 4.375rem / 3 * 2);
}
.flex-col3.gap70 {
  gap: 4.375rem;
}
.flex-col3.gap71 > * {
  width: calc(33.3333333333% - 4.4375rem / 3 * 2);
}
.flex-col3.gap71 {
  gap: 4.4375rem;
}
.flex-col3.gap72 > * {
  width: calc(33.3333333333% - 4.5rem / 3 * 2);
}
.flex-col3.gap72 {
  gap: 4.5rem;
}
.flex-col3.gap73 > * {
  width: calc(33.3333333333% - 4.5625rem / 3 * 2);
}
.flex-col3.gap73 {
  gap: 4.5625rem;
}
.flex-col3.gap74 > * {
  width: calc(33.3333333333% - 4.625rem / 3 * 2);
}
.flex-col3.gap74 {
  gap: 4.625rem;
}
.flex-col3.gap75 > * {
  width: calc(33.3333333333% - 4.6875rem / 3 * 2);
}
.flex-col3.gap75 {
  gap: 4.6875rem;
}
.flex-col3.gap76 > * {
  width: calc(33.3333333333% - 4.75rem / 3 * 2);
}
.flex-col3.gap76 {
  gap: 4.75rem;
}
.flex-col3.gap77 > * {
  width: calc(33.3333333333% - 4.8125rem / 3 * 2);
}
.flex-col3.gap77 {
  gap: 4.8125rem;
}
.flex-col3.gap78 > * {
  width: calc(33.3333333333% - 4.875rem / 3 * 2);
}
.flex-col3.gap78 {
  gap: 4.875rem;
}
.flex-col3.gap79 > * {
  width: calc(33.3333333333% - 4.9375rem / 3 * 2);
}
.flex-col3.gap79 {
  gap: 4.9375rem;
}
.flex-col3.gap80 > * {
  width: calc(33.3333333333% - 5rem / 3 * 2);
}
.flex-col3.gap80 {
  gap: 5rem;
}
.flex-col3.gap81 > * {
  width: calc(33.3333333333% - 5.0625rem / 3 * 2);
}
.flex-col3.gap81 {
  gap: 5.0625rem;
}
.flex-col3.gap82 > * {
  width: calc(33.3333333333% - 5.125rem / 3 * 2);
}
.flex-col3.gap82 {
  gap: 5.125rem;
}
.flex-col3.gap83 > * {
  width: calc(33.3333333333% - 5.1875rem / 3 * 2);
}
.flex-col3.gap83 {
  gap: 5.1875rem;
}
.flex-col3.gap84 > * {
  width: calc(33.3333333333% - 5.25rem / 3 * 2);
}
.flex-col3.gap84 {
  gap: 5.25rem;
}
.flex-col3.gap85 > * {
  width: calc(33.3333333333% - 5.3125rem / 3 * 2);
}
.flex-col3.gap85 {
  gap: 5.3125rem;
}
.flex-col3.gap86 > * {
  width: calc(33.3333333333% - 5.375rem / 3 * 2);
}
.flex-col3.gap86 {
  gap: 5.375rem;
}
.flex-col3.gap87 > * {
  width: calc(33.3333333333% - 5.4375rem / 3 * 2);
}
.flex-col3.gap87 {
  gap: 5.4375rem;
}
.flex-col3.gap88 > * {
  width: calc(33.3333333333% - 5.5rem / 3 * 2);
}
.flex-col3.gap88 {
  gap: 5.5rem;
}
.flex-col3.gap89 > * {
  width: calc(33.3333333333% - 5.5625rem / 3 * 2);
}
.flex-col3.gap89 {
  gap: 5.5625rem;
}
.flex-col3.gap90 > * {
  width: calc(33.3333333333% - 5.625rem / 3 * 2);
}
.flex-col3.gap90 {
  gap: 5.625rem;
}
.flex-col3.gap91 > * {
  width: calc(33.3333333333% - 5.6875rem / 3 * 2);
}
.flex-col3.gap91 {
  gap: 5.6875rem;
}
.flex-col3.gap92 > * {
  width: calc(33.3333333333% - 5.75rem / 3 * 2);
}
.flex-col3.gap92 {
  gap: 5.75rem;
}
.flex-col3.gap93 > * {
  width: calc(33.3333333333% - 5.8125rem / 3 * 2);
}
.flex-col3.gap93 {
  gap: 5.8125rem;
}
.flex-col3.gap94 > * {
  width: calc(33.3333333333% - 5.875rem / 3 * 2);
}
.flex-col3.gap94 {
  gap: 5.875rem;
}
.flex-col3.gap95 > * {
  width: calc(33.3333333333% - 5.9375rem / 3 * 2);
}
.flex-col3.gap95 {
  gap: 5.9375rem;
}
.flex-col3.gap96 > * {
  width: calc(33.3333333333% - 6rem / 3 * 2);
}
.flex-col3.gap96 {
  gap: 6rem;
}
.flex-col3.gap97 > * {
  width: calc(33.3333333333% - 6.0625rem / 3 * 2);
}
.flex-col3.gap97 {
  gap: 6.0625rem;
}
.flex-col3.gap98 > * {
  width: calc(33.3333333333% - 6.125rem / 3 * 2);
}
.flex-col3.gap98 {
  gap: 6.125rem;
}
.flex-col3.gap99 > * {
  width: calc(33.3333333333% - 6.1875rem / 3 * 2);
}
.flex-col3.gap99 {
  gap: 6.1875rem;
}
.flex-col3.gap100 > * {
  width: calc(33.3333333333% - 6.25rem / 3 * 2);
}
.flex-col3.gap100 {
  gap: 6.25rem;
}
.flex-col3.gap101 > * {
  width: calc(33.3333333333% - 6.3125rem / 3 * 2);
}
.flex-col3.gap101 {
  gap: 6.3125rem;
}
.flex-col3.gap102 > * {
  width: calc(33.3333333333% - 6.375rem / 3 * 2);
}
.flex-col3.gap102 {
  gap: 6.375rem;
}
.flex-col3.gap103 > * {
  width: calc(33.3333333333% - 6.4375rem / 3 * 2);
}
.flex-col3.gap103 {
  gap: 6.4375rem;
}
.flex-col3.gap104 > * {
  width: calc(33.3333333333% - 6.5rem / 3 * 2);
}
.flex-col3.gap104 {
  gap: 6.5rem;
}
.flex-col3.gap105 > * {
  width: calc(33.3333333333% - 6.5625rem / 3 * 2);
}
.flex-col3.gap105 {
  gap: 6.5625rem;
}
.flex-col3.gap106 > * {
  width: calc(33.3333333333% - 6.625rem / 3 * 2);
}
.flex-col3.gap106 {
  gap: 6.625rem;
}
.flex-col3.gap107 > * {
  width: calc(33.3333333333% - 6.6875rem / 3 * 2);
}
.flex-col3.gap107 {
  gap: 6.6875rem;
}
.flex-col3.gap108 > * {
  width: calc(33.3333333333% - 6.75rem / 3 * 2);
}
.flex-col3.gap108 {
  gap: 6.75rem;
}
.flex-col3.gap109 > * {
  width: calc(33.3333333333% - 6.8125rem / 3 * 2);
}
.flex-col3.gap109 {
  gap: 6.8125rem;
}
.flex-col3.gap110 > * {
  width: calc(33.3333333333% - 6.875rem / 3 * 2);
}
.flex-col3.gap110 {
  gap: 6.875rem;
}
.flex-col3.gap111 > * {
  width: calc(33.3333333333% - 6.9375rem / 3 * 2);
}
.flex-col3.gap111 {
  gap: 6.9375rem;
}
.flex-col3.gap112 > * {
  width: calc(33.3333333333% - 7rem / 3 * 2);
}
.flex-col3.gap112 {
  gap: 7rem;
}
.flex-col3.gap113 > * {
  width: calc(33.3333333333% - 7.0625rem / 3 * 2);
}
.flex-col3.gap113 {
  gap: 7.0625rem;
}
.flex-col3.gap114 > * {
  width: calc(33.3333333333% - 7.125rem / 3 * 2);
}
.flex-col3.gap114 {
  gap: 7.125rem;
}
.flex-col3.gap115 > * {
  width: calc(33.3333333333% - 7.1875rem / 3 * 2);
}
.flex-col3.gap115 {
  gap: 7.1875rem;
}
.flex-col3.gap116 > * {
  width: calc(33.3333333333% - 7.25rem / 3 * 2);
}
.flex-col3.gap116 {
  gap: 7.25rem;
}
.flex-col3.gap117 > * {
  width: calc(33.3333333333% - 7.3125rem / 3 * 2);
}
.flex-col3.gap117 {
  gap: 7.3125rem;
}
.flex-col3.gap118 > * {
  width: calc(33.3333333333% - 7.375rem / 3 * 2);
}
.flex-col3.gap118 {
  gap: 7.375rem;
}
.flex-col3.gap119 > * {
  width: calc(33.3333333333% - 7.4375rem / 3 * 2);
}
.flex-col3.gap119 {
  gap: 7.4375rem;
}
.flex-col3.gap120 > * {
  width: calc(33.3333333333% - 7.5rem / 3 * 2);
}
.flex-col3.gap120 {
  gap: 7.5rem;
}
.flex-col3.gap121 > * {
  width: calc(33.3333333333% - 7.5625rem / 3 * 2);
}
.flex-col3.gap121 {
  gap: 7.5625rem;
}
.flex-col3.gap122 > * {
  width: calc(33.3333333333% - 7.625rem / 3 * 2);
}
.flex-col3.gap122 {
  gap: 7.625rem;
}
.flex-col3.gap123 > * {
  width: calc(33.3333333333% - 7.6875rem / 3 * 2);
}
.flex-col3.gap123 {
  gap: 7.6875rem;
}
.flex-col3.gap124 > * {
  width: calc(33.3333333333% - 7.75rem / 3 * 2);
}
.flex-col3.gap124 {
  gap: 7.75rem;
}
.flex-col3.gap125 > * {
  width: calc(33.3333333333% - 7.8125rem / 3 * 2);
}
.flex-col3.gap125 {
  gap: 7.8125rem;
}
.flex-col3.gap126 > * {
  width: calc(33.3333333333% - 7.875rem / 3 * 2);
}
.flex-col3.gap126 {
  gap: 7.875rem;
}
.flex-col3.gap127 > * {
  width: calc(33.3333333333% - 7.9375rem / 3 * 2);
}
.flex-col3.gap127 {
  gap: 7.9375rem;
}
.flex-col3.gap128 > * {
  width: calc(33.3333333333% - 8rem / 3 * 2);
}
.flex-col3.gap128 {
  gap: 8rem;
}
.flex-col3.gap129 > * {
  width: calc(33.3333333333% - 8.0625rem / 3 * 2);
}
.flex-col3.gap129 {
  gap: 8.0625rem;
}
.flex-col3.gap130 > * {
  width: calc(33.3333333333% - 8.125rem / 3 * 2);
}
.flex-col3.gap130 {
  gap: 8.125rem;
}
.flex-col3.gap131 > * {
  width: calc(33.3333333333% - 8.1875rem / 3 * 2);
}
.flex-col3.gap131 {
  gap: 8.1875rem;
}
.flex-col3.gap132 > * {
  width: calc(33.3333333333% - 8.25rem / 3 * 2);
}
.flex-col3.gap132 {
  gap: 8.25rem;
}
.flex-col3.gap133 > * {
  width: calc(33.3333333333% - 8.3125rem / 3 * 2);
}
.flex-col3.gap133 {
  gap: 8.3125rem;
}
.flex-col3.gap134 > * {
  width: calc(33.3333333333% - 8.375rem / 3 * 2);
}
.flex-col3.gap134 {
  gap: 8.375rem;
}
.flex-col3.gap135 > * {
  width: calc(33.3333333333% - 8.4375rem / 3 * 2);
}
.flex-col3.gap135 {
  gap: 8.4375rem;
}
.flex-col3.gap136 > * {
  width: calc(33.3333333333% - 8.5rem / 3 * 2);
}
.flex-col3.gap136 {
  gap: 8.5rem;
}
.flex-col3.gap137 > * {
  width: calc(33.3333333333% - 8.5625rem / 3 * 2);
}
.flex-col3.gap137 {
  gap: 8.5625rem;
}
.flex-col3.gap138 > * {
  width: calc(33.3333333333% - 8.625rem / 3 * 2);
}
.flex-col3.gap138 {
  gap: 8.625rem;
}
.flex-col3.gap139 > * {
  width: calc(33.3333333333% - 8.6875rem / 3 * 2);
}
.flex-col3.gap139 {
  gap: 8.6875rem;
}
.flex-col3.gap140 > * {
  width: calc(33.3333333333% - 8.75rem / 3 * 2);
}
.flex-col3.gap140 {
  gap: 8.75rem;
}
.flex-col3.gap141 > * {
  width: calc(33.3333333333% - 8.8125rem / 3 * 2);
}
.flex-col3.gap141 {
  gap: 8.8125rem;
}
.flex-col3.gap142 > * {
  width: calc(33.3333333333% - 8.875rem / 3 * 2);
}
.flex-col3.gap142 {
  gap: 8.875rem;
}
.flex-col3.gap143 > * {
  width: calc(33.3333333333% - 8.9375rem / 3 * 2);
}
.flex-col3.gap143 {
  gap: 8.9375rem;
}
.flex-col3.gap144 > * {
  width: calc(33.3333333333% - 9rem / 3 * 2);
}
.flex-col3.gap144 {
  gap: 9rem;
}
.flex-col3.gap145 > * {
  width: calc(33.3333333333% - 9.0625rem / 3 * 2);
}
.flex-col3.gap145 {
  gap: 9.0625rem;
}
.flex-col3.gap146 > * {
  width: calc(33.3333333333% - 9.125rem / 3 * 2);
}
.flex-col3.gap146 {
  gap: 9.125rem;
}
.flex-col3.gap147 > * {
  width: calc(33.3333333333% - 9.1875rem / 3 * 2);
}
.flex-col3.gap147 {
  gap: 9.1875rem;
}
.flex-col3.gap148 > * {
  width: calc(33.3333333333% - 9.25rem / 3 * 2);
}
.flex-col3.gap148 {
  gap: 9.25rem;
}
.flex-col3.gap149 > * {
  width: calc(33.3333333333% - 9.3125rem / 3 * 2);
}
.flex-col3.gap149 {
  gap: 9.3125rem;
}
.flex-col3.gap150 > * {
  width: calc(33.3333333333% - 9.375rem / 3 * 2);
}
.flex-col3.gap150 {
  gap: 9.375rem;
}

.flex-col4 {
  flex-wrap: wrap;
}
.flex-col4 > * {
  width: 25%;
}
.flex-col4.gap1 > * {
  width: calc(25% - 0.0625rem / 4 * 3);
}
.flex-col4.gap1 {
  gap: 0.0625rem;
}
.flex-col4.gap2 > * {
  width: calc(25% - 0.125rem / 4 * 3);
}
.flex-col4.gap2 {
  gap: 0.125rem;
}
.flex-col4.gap3 > * {
  width: calc(25% - 0.1875rem / 4 * 3);
}
.flex-col4.gap3 {
  gap: 0.1875rem;
}
.flex-col4.gap4 > * {
  width: calc(25% - 0.25rem / 4 * 3);
}
.flex-col4.gap4 {
  gap: 0.25rem;
}
.flex-col4.gap5 > * {
  width: calc(25% - 0.3125rem / 4 * 3);
}
.flex-col4.gap5 {
  gap: 0.3125rem;
}
.flex-col4.gap6 > * {
  width: calc(25% - 0.375rem / 4 * 3);
}
.flex-col4.gap6 {
  gap: 0.375rem;
}
.flex-col4.gap7 > * {
  width: calc(25% - 0.4375rem / 4 * 3);
}
.flex-col4.gap7 {
  gap: 0.4375rem;
}
.flex-col4.gap8 > * {
  width: calc(25% - 0.5rem / 4 * 3);
}
.flex-col4.gap8 {
  gap: 0.5rem;
}
.flex-col4.gap9 > * {
  width: calc(25% - 0.5625rem / 4 * 3);
}
.flex-col4.gap9 {
  gap: 0.5625rem;
}
.flex-col4.gap10 > * {
  width: calc(25% - 0.625rem / 4 * 3);
}
.flex-col4.gap10 {
  gap: 0.625rem;
}
.flex-col4.gap11 > * {
  width: calc(25% - 0.6875rem / 4 * 3);
}
.flex-col4.gap11 {
  gap: 0.6875rem;
}
.flex-col4.gap12 > * {
  width: calc(25% - 0.75rem / 4 * 3);
}
.flex-col4.gap12 {
  gap: 0.75rem;
}
.flex-col4.gap13 > * {
  width: calc(25% - 0.8125rem / 4 * 3);
}
.flex-col4.gap13 {
  gap: 0.8125rem;
}
.flex-col4.gap14 > * {
  width: calc(25% - 0.875rem / 4 * 3);
}
.flex-col4.gap14 {
  gap: 0.875rem;
}
.flex-col4.gap15 > * {
  width: calc(25% - 0.9375rem / 4 * 3);
}
.flex-col4.gap15 {
  gap: 0.9375rem;
}
.flex-col4.gap16 > * {
  width: calc(25% - 1rem / 4 * 3);
}
.flex-col4.gap16 {
  gap: 1rem;
}
.flex-col4.gap17 > * {
  width: calc(25% - 1.0625rem / 4 * 3);
}
.flex-col4.gap17 {
  gap: 1.0625rem;
}
.flex-col4.gap18 > * {
  width: calc(25% - 1.125rem / 4 * 3);
}
.flex-col4.gap18 {
  gap: 1.125rem;
}
.flex-col4.gap19 > * {
  width: calc(25% - 1.1875rem / 4 * 3);
}
.flex-col4.gap19 {
  gap: 1.1875rem;
}
.flex-col4.gap20 > * {
  width: calc(25% - 1.25rem / 4 * 3);
}
.flex-col4.gap20 {
  gap: 1.25rem;
}
.flex-col4.gap21 > * {
  width: calc(25% - 1.3125rem / 4 * 3);
}
.flex-col4.gap21 {
  gap: 1.3125rem;
}
.flex-col4.gap22 > * {
  width: calc(25% - 1.375rem / 4 * 3);
}
.flex-col4.gap22 {
  gap: 1.375rem;
}
.flex-col4.gap23 > * {
  width: calc(25% - 1.4375rem / 4 * 3);
}
.flex-col4.gap23 {
  gap: 1.4375rem;
}
.flex-col4.gap24 > * {
  width: calc(25% - 1.5rem / 4 * 3);
}
.flex-col4.gap24 {
  gap: 1.5rem;
}
.flex-col4.gap25 > * {
  width: calc(25% - 1.5625rem / 4 * 3);
}
.flex-col4.gap25 {
  gap: 1.5625rem;
}
.flex-col4.gap26 > * {
  width: calc(25% - 1.625rem / 4 * 3);
}
.flex-col4.gap26 {
  gap: 1.625rem;
}
.flex-col4.gap27 > * {
  width: calc(25% - 1.6875rem / 4 * 3);
}
.flex-col4.gap27 {
  gap: 1.6875rem;
}
.flex-col4.gap28 > * {
  width: calc(25% - 1.75rem / 4 * 3);
}
.flex-col4.gap28 {
  gap: 1.75rem;
}
.flex-col4.gap29 > * {
  width: calc(25% - 1.8125rem / 4 * 3);
}
.flex-col4.gap29 {
  gap: 1.8125rem;
}
.flex-col4.gap30 > * {
  width: calc(25% - 1.875rem / 4 * 3);
}
.flex-col4.gap30 {
  gap: 1.875rem;
}
.flex-col4.gap31 > * {
  width: calc(25% - 1.9375rem / 4 * 3);
}
.flex-col4.gap31 {
  gap: 1.9375rem;
}
.flex-col4.gap32 > * {
  width: calc(25% - 2rem / 4 * 3);
}
.flex-col4.gap32 {
  gap: 2rem;
}
.flex-col4.gap33 > * {
  width: calc(25% - 2.0625rem / 4 * 3);
}
.flex-col4.gap33 {
  gap: 2.0625rem;
}
.flex-col4.gap34 > * {
  width: calc(25% - 2.125rem / 4 * 3);
}
.flex-col4.gap34 {
  gap: 2.125rem;
}
.flex-col4.gap35 > * {
  width: calc(25% - 2.1875rem / 4 * 3);
}
.flex-col4.gap35 {
  gap: 2.1875rem;
}
.flex-col4.gap36 > * {
  width: calc(25% - 2.25rem / 4 * 3);
}
.flex-col4.gap36 {
  gap: 2.25rem;
}
.flex-col4.gap37 > * {
  width: calc(25% - 2.3125rem / 4 * 3);
}
.flex-col4.gap37 {
  gap: 2.3125rem;
}
.flex-col4.gap38 > * {
  width: calc(25% - 2.375rem / 4 * 3);
}
.flex-col4.gap38 {
  gap: 2.375rem;
}
.flex-col4.gap39 > * {
  width: calc(25% - 2.4375rem / 4 * 3);
}
.flex-col4.gap39 {
  gap: 2.4375rem;
}
.flex-col4.gap40 > * {
  width: calc(25% - 2.5rem / 4 * 3);
}
.flex-col4.gap40 {
  gap: 2.5rem;
}
.flex-col4.gap41 > * {
  width: calc(25% - 2.5625rem / 4 * 3);
}
.flex-col4.gap41 {
  gap: 2.5625rem;
}
.flex-col4.gap42 > * {
  width: calc(25% - 2.625rem / 4 * 3);
}
.flex-col4.gap42 {
  gap: 2.625rem;
}
.flex-col4.gap43 > * {
  width: calc(25% - 2.6875rem / 4 * 3);
}
.flex-col4.gap43 {
  gap: 2.6875rem;
}
.flex-col4.gap44 > * {
  width: calc(25% - 2.75rem / 4 * 3);
}
.flex-col4.gap44 {
  gap: 2.75rem;
}
.flex-col4.gap45 > * {
  width: calc(25% - 2.8125rem / 4 * 3);
}
.flex-col4.gap45 {
  gap: 2.8125rem;
}
.flex-col4.gap46 > * {
  width: calc(25% - 2.875rem / 4 * 3);
}
.flex-col4.gap46 {
  gap: 2.875rem;
}
.flex-col4.gap47 > * {
  width: calc(25% - 2.9375rem / 4 * 3);
}
.flex-col4.gap47 {
  gap: 2.9375rem;
}
.flex-col4.gap48 > * {
  width: calc(25% - 3rem / 4 * 3);
}
.flex-col4.gap48 {
  gap: 3rem;
}
.flex-col4.gap49 > * {
  width: calc(25% - 3.0625rem / 4 * 3);
}
.flex-col4.gap49 {
  gap: 3.0625rem;
}
.flex-col4.gap50 > * {
  width: calc(25% - 3.125rem / 4 * 3);
}
.flex-col4.gap50 {
  gap: 3.125rem;
}
.flex-col4.gap51 > * {
  width: calc(25% - 3.1875rem / 4 * 3);
}
.flex-col4.gap51 {
  gap: 3.1875rem;
}
.flex-col4.gap52 > * {
  width: calc(25% - 3.25rem / 4 * 3);
}
.flex-col4.gap52 {
  gap: 3.25rem;
}
.flex-col4.gap53 > * {
  width: calc(25% - 3.3125rem / 4 * 3);
}
.flex-col4.gap53 {
  gap: 3.3125rem;
}
.flex-col4.gap54 > * {
  width: calc(25% - 3.375rem / 4 * 3);
}
.flex-col4.gap54 {
  gap: 3.375rem;
}
.flex-col4.gap55 > * {
  width: calc(25% - 3.4375rem / 4 * 3);
}
.flex-col4.gap55 {
  gap: 3.4375rem;
}
.flex-col4.gap56 > * {
  width: calc(25% - 3.5rem / 4 * 3);
}
.flex-col4.gap56 {
  gap: 3.5rem;
}
.flex-col4.gap57 > * {
  width: calc(25% - 3.5625rem / 4 * 3);
}
.flex-col4.gap57 {
  gap: 3.5625rem;
}
.flex-col4.gap58 > * {
  width: calc(25% - 3.625rem / 4 * 3);
}
.flex-col4.gap58 {
  gap: 3.625rem;
}
.flex-col4.gap59 > * {
  width: calc(25% - 3.6875rem / 4 * 3);
}
.flex-col4.gap59 {
  gap: 3.6875rem;
}
.flex-col4.gap60 > * {
  width: calc(25% - 3.75rem / 4 * 3);
}
.flex-col4.gap60 {
  gap: 3.75rem;
}
.flex-col4.gap61 > * {
  width: calc(25% - 3.8125rem / 4 * 3);
}
.flex-col4.gap61 {
  gap: 3.8125rem;
}
.flex-col4.gap62 > * {
  width: calc(25% - 3.875rem / 4 * 3);
}
.flex-col4.gap62 {
  gap: 3.875rem;
}
.flex-col4.gap63 > * {
  width: calc(25% - 3.9375rem / 4 * 3);
}
.flex-col4.gap63 {
  gap: 3.9375rem;
}
.flex-col4.gap64 > * {
  width: calc(25% - 4rem / 4 * 3);
}
.flex-col4.gap64 {
  gap: 4rem;
}
.flex-col4.gap65 > * {
  width: calc(25% - 4.0625rem / 4 * 3);
}
.flex-col4.gap65 {
  gap: 4.0625rem;
}
.flex-col4.gap66 > * {
  width: calc(25% - 4.125rem / 4 * 3);
}
.flex-col4.gap66 {
  gap: 4.125rem;
}
.flex-col4.gap67 > * {
  width: calc(25% - 4.1875rem / 4 * 3);
}
.flex-col4.gap67 {
  gap: 4.1875rem;
}
.flex-col4.gap68 > * {
  width: calc(25% - 4.25rem / 4 * 3);
}
.flex-col4.gap68 {
  gap: 4.25rem;
}
.flex-col4.gap69 > * {
  width: calc(25% - 4.3125rem / 4 * 3);
}
.flex-col4.gap69 {
  gap: 4.3125rem;
}
.flex-col4.gap70 > * {
  width: calc(25% - 4.375rem / 4 * 3);
}
.flex-col4.gap70 {
  gap: 4.375rem;
}
.flex-col4.gap71 > * {
  width: calc(25% - 4.4375rem / 4 * 3);
}
.flex-col4.gap71 {
  gap: 4.4375rem;
}
.flex-col4.gap72 > * {
  width: calc(25% - 4.5rem / 4 * 3);
}
.flex-col4.gap72 {
  gap: 4.5rem;
}
.flex-col4.gap73 > * {
  width: calc(25% - 4.5625rem / 4 * 3);
}
.flex-col4.gap73 {
  gap: 4.5625rem;
}
.flex-col4.gap74 > * {
  width: calc(25% - 4.625rem / 4 * 3);
}
.flex-col4.gap74 {
  gap: 4.625rem;
}
.flex-col4.gap75 > * {
  width: calc(25% - 4.6875rem / 4 * 3);
}
.flex-col4.gap75 {
  gap: 4.6875rem;
}
.flex-col4.gap76 > * {
  width: calc(25% - 4.75rem / 4 * 3);
}
.flex-col4.gap76 {
  gap: 4.75rem;
}
.flex-col4.gap77 > * {
  width: calc(25% - 4.8125rem / 4 * 3);
}
.flex-col4.gap77 {
  gap: 4.8125rem;
}
.flex-col4.gap78 > * {
  width: calc(25% - 4.875rem / 4 * 3);
}
.flex-col4.gap78 {
  gap: 4.875rem;
}
.flex-col4.gap79 > * {
  width: calc(25% - 4.9375rem / 4 * 3);
}
.flex-col4.gap79 {
  gap: 4.9375rem;
}
.flex-col4.gap80 > * {
  width: calc(25% - 5rem / 4 * 3);
}
.flex-col4.gap80 {
  gap: 5rem;
}
.flex-col4.gap81 > * {
  width: calc(25% - 5.0625rem / 4 * 3);
}
.flex-col4.gap81 {
  gap: 5.0625rem;
}
.flex-col4.gap82 > * {
  width: calc(25% - 5.125rem / 4 * 3);
}
.flex-col4.gap82 {
  gap: 5.125rem;
}
.flex-col4.gap83 > * {
  width: calc(25% - 5.1875rem / 4 * 3);
}
.flex-col4.gap83 {
  gap: 5.1875rem;
}
.flex-col4.gap84 > * {
  width: calc(25% - 5.25rem / 4 * 3);
}
.flex-col4.gap84 {
  gap: 5.25rem;
}
.flex-col4.gap85 > * {
  width: calc(25% - 5.3125rem / 4 * 3);
}
.flex-col4.gap85 {
  gap: 5.3125rem;
}
.flex-col4.gap86 > * {
  width: calc(25% - 5.375rem / 4 * 3);
}
.flex-col4.gap86 {
  gap: 5.375rem;
}
.flex-col4.gap87 > * {
  width: calc(25% - 5.4375rem / 4 * 3);
}
.flex-col4.gap87 {
  gap: 5.4375rem;
}
.flex-col4.gap88 > * {
  width: calc(25% - 5.5rem / 4 * 3);
}
.flex-col4.gap88 {
  gap: 5.5rem;
}
.flex-col4.gap89 > * {
  width: calc(25% - 5.5625rem / 4 * 3);
}
.flex-col4.gap89 {
  gap: 5.5625rem;
}
.flex-col4.gap90 > * {
  width: calc(25% - 5.625rem / 4 * 3);
}
.flex-col4.gap90 {
  gap: 5.625rem;
}
.flex-col4.gap91 > * {
  width: calc(25% - 5.6875rem / 4 * 3);
}
.flex-col4.gap91 {
  gap: 5.6875rem;
}
.flex-col4.gap92 > * {
  width: calc(25% - 5.75rem / 4 * 3);
}
.flex-col4.gap92 {
  gap: 5.75rem;
}
.flex-col4.gap93 > * {
  width: calc(25% - 5.8125rem / 4 * 3);
}
.flex-col4.gap93 {
  gap: 5.8125rem;
}
.flex-col4.gap94 > * {
  width: calc(25% - 5.875rem / 4 * 3);
}
.flex-col4.gap94 {
  gap: 5.875rem;
}
.flex-col4.gap95 > * {
  width: calc(25% - 5.9375rem / 4 * 3);
}
.flex-col4.gap95 {
  gap: 5.9375rem;
}
.flex-col4.gap96 > * {
  width: calc(25% - 6rem / 4 * 3);
}
.flex-col4.gap96 {
  gap: 6rem;
}
.flex-col4.gap97 > * {
  width: calc(25% - 6.0625rem / 4 * 3);
}
.flex-col4.gap97 {
  gap: 6.0625rem;
}
.flex-col4.gap98 > * {
  width: calc(25% - 6.125rem / 4 * 3);
}
.flex-col4.gap98 {
  gap: 6.125rem;
}
.flex-col4.gap99 > * {
  width: calc(25% - 6.1875rem / 4 * 3);
}
.flex-col4.gap99 {
  gap: 6.1875rem;
}
.flex-col4.gap100 > * {
  width: calc(25% - 6.25rem / 4 * 3);
}
.flex-col4.gap100 {
  gap: 6.25rem;
}
.flex-col4.gap101 > * {
  width: calc(25% - 6.3125rem / 4 * 3);
}
.flex-col4.gap101 {
  gap: 6.3125rem;
}
.flex-col4.gap102 > * {
  width: calc(25% - 6.375rem / 4 * 3);
}
.flex-col4.gap102 {
  gap: 6.375rem;
}
.flex-col4.gap103 > * {
  width: calc(25% - 6.4375rem / 4 * 3);
}
.flex-col4.gap103 {
  gap: 6.4375rem;
}
.flex-col4.gap104 > * {
  width: calc(25% - 6.5rem / 4 * 3);
}
.flex-col4.gap104 {
  gap: 6.5rem;
}
.flex-col4.gap105 > * {
  width: calc(25% - 6.5625rem / 4 * 3);
}
.flex-col4.gap105 {
  gap: 6.5625rem;
}
.flex-col4.gap106 > * {
  width: calc(25% - 6.625rem / 4 * 3);
}
.flex-col4.gap106 {
  gap: 6.625rem;
}
.flex-col4.gap107 > * {
  width: calc(25% - 6.6875rem / 4 * 3);
}
.flex-col4.gap107 {
  gap: 6.6875rem;
}
.flex-col4.gap108 > * {
  width: calc(25% - 6.75rem / 4 * 3);
}
.flex-col4.gap108 {
  gap: 6.75rem;
}
.flex-col4.gap109 > * {
  width: calc(25% - 6.8125rem / 4 * 3);
}
.flex-col4.gap109 {
  gap: 6.8125rem;
}
.flex-col4.gap110 > * {
  width: calc(25% - 6.875rem / 4 * 3);
}
.flex-col4.gap110 {
  gap: 6.875rem;
}
.flex-col4.gap111 > * {
  width: calc(25% - 6.9375rem / 4 * 3);
}
.flex-col4.gap111 {
  gap: 6.9375rem;
}
.flex-col4.gap112 > * {
  width: calc(25% - 7rem / 4 * 3);
}
.flex-col4.gap112 {
  gap: 7rem;
}
.flex-col4.gap113 > * {
  width: calc(25% - 7.0625rem / 4 * 3);
}
.flex-col4.gap113 {
  gap: 7.0625rem;
}
.flex-col4.gap114 > * {
  width: calc(25% - 7.125rem / 4 * 3);
}
.flex-col4.gap114 {
  gap: 7.125rem;
}
.flex-col4.gap115 > * {
  width: calc(25% - 7.1875rem / 4 * 3);
}
.flex-col4.gap115 {
  gap: 7.1875rem;
}
.flex-col4.gap116 > * {
  width: calc(25% - 7.25rem / 4 * 3);
}
.flex-col4.gap116 {
  gap: 7.25rem;
}
.flex-col4.gap117 > * {
  width: calc(25% - 7.3125rem / 4 * 3);
}
.flex-col4.gap117 {
  gap: 7.3125rem;
}
.flex-col4.gap118 > * {
  width: calc(25% - 7.375rem / 4 * 3);
}
.flex-col4.gap118 {
  gap: 7.375rem;
}
.flex-col4.gap119 > * {
  width: calc(25% - 7.4375rem / 4 * 3);
}
.flex-col4.gap119 {
  gap: 7.4375rem;
}
.flex-col4.gap120 > * {
  width: calc(25% - 7.5rem / 4 * 3);
}
.flex-col4.gap120 {
  gap: 7.5rem;
}
.flex-col4.gap121 > * {
  width: calc(25% - 7.5625rem / 4 * 3);
}
.flex-col4.gap121 {
  gap: 7.5625rem;
}
.flex-col4.gap122 > * {
  width: calc(25% - 7.625rem / 4 * 3);
}
.flex-col4.gap122 {
  gap: 7.625rem;
}
.flex-col4.gap123 > * {
  width: calc(25% - 7.6875rem / 4 * 3);
}
.flex-col4.gap123 {
  gap: 7.6875rem;
}
.flex-col4.gap124 > * {
  width: calc(25% - 7.75rem / 4 * 3);
}
.flex-col4.gap124 {
  gap: 7.75rem;
}
.flex-col4.gap125 > * {
  width: calc(25% - 7.8125rem / 4 * 3);
}
.flex-col4.gap125 {
  gap: 7.8125rem;
}
.flex-col4.gap126 > * {
  width: calc(25% - 7.875rem / 4 * 3);
}
.flex-col4.gap126 {
  gap: 7.875rem;
}
.flex-col4.gap127 > * {
  width: calc(25% - 7.9375rem / 4 * 3);
}
.flex-col4.gap127 {
  gap: 7.9375rem;
}
.flex-col4.gap128 > * {
  width: calc(25% - 8rem / 4 * 3);
}
.flex-col4.gap128 {
  gap: 8rem;
}
.flex-col4.gap129 > * {
  width: calc(25% - 8.0625rem / 4 * 3);
}
.flex-col4.gap129 {
  gap: 8.0625rem;
}
.flex-col4.gap130 > * {
  width: calc(25% - 8.125rem / 4 * 3);
}
.flex-col4.gap130 {
  gap: 8.125rem;
}
.flex-col4.gap131 > * {
  width: calc(25% - 8.1875rem / 4 * 3);
}
.flex-col4.gap131 {
  gap: 8.1875rem;
}
.flex-col4.gap132 > * {
  width: calc(25% - 8.25rem / 4 * 3);
}
.flex-col4.gap132 {
  gap: 8.25rem;
}
.flex-col4.gap133 > * {
  width: calc(25% - 8.3125rem / 4 * 3);
}
.flex-col4.gap133 {
  gap: 8.3125rem;
}
.flex-col4.gap134 > * {
  width: calc(25% - 8.375rem / 4 * 3);
}
.flex-col4.gap134 {
  gap: 8.375rem;
}
.flex-col4.gap135 > * {
  width: calc(25% - 8.4375rem / 4 * 3);
}
.flex-col4.gap135 {
  gap: 8.4375rem;
}
.flex-col4.gap136 > * {
  width: calc(25% - 8.5rem / 4 * 3);
}
.flex-col4.gap136 {
  gap: 8.5rem;
}
.flex-col4.gap137 > * {
  width: calc(25% - 8.5625rem / 4 * 3);
}
.flex-col4.gap137 {
  gap: 8.5625rem;
}
.flex-col4.gap138 > * {
  width: calc(25% - 8.625rem / 4 * 3);
}
.flex-col4.gap138 {
  gap: 8.625rem;
}
.flex-col4.gap139 > * {
  width: calc(25% - 8.6875rem / 4 * 3);
}
.flex-col4.gap139 {
  gap: 8.6875rem;
}
.flex-col4.gap140 > * {
  width: calc(25% - 8.75rem / 4 * 3);
}
.flex-col4.gap140 {
  gap: 8.75rem;
}
.flex-col4.gap141 > * {
  width: calc(25% - 8.8125rem / 4 * 3);
}
.flex-col4.gap141 {
  gap: 8.8125rem;
}
.flex-col4.gap142 > * {
  width: calc(25% - 8.875rem / 4 * 3);
}
.flex-col4.gap142 {
  gap: 8.875rem;
}
.flex-col4.gap143 > * {
  width: calc(25% - 8.9375rem / 4 * 3);
}
.flex-col4.gap143 {
  gap: 8.9375rem;
}
.flex-col4.gap144 > * {
  width: calc(25% - 9rem / 4 * 3);
}
.flex-col4.gap144 {
  gap: 9rem;
}
.flex-col4.gap145 > * {
  width: calc(25% - 9.0625rem / 4 * 3);
}
.flex-col4.gap145 {
  gap: 9.0625rem;
}
.flex-col4.gap146 > * {
  width: calc(25% - 9.125rem / 4 * 3);
}
.flex-col4.gap146 {
  gap: 9.125rem;
}
.flex-col4.gap147 > * {
  width: calc(25% - 9.1875rem / 4 * 3);
}
.flex-col4.gap147 {
  gap: 9.1875rem;
}
.flex-col4.gap148 > * {
  width: calc(25% - 9.25rem / 4 * 3);
}
.flex-col4.gap148 {
  gap: 9.25rem;
}
.flex-col4.gap149 > * {
  width: calc(25% - 9.3125rem / 4 * 3);
}
.flex-col4.gap149 {
  gap: 9.3125rem;
}
.flex-col4.gap150 > * {
  width: calc(25% - 9.375rem / 4 * 3);
}
.flex-col4.gap150 {
  gap: 9.375rem;
}

.flex-col5 {
  flex-wrap: wrap;
}
.flex-col5 > * {
  width: 20%;
}
.flex-col5.gap1 > * {
  width: calc(20% - 0.0625rem / 5 * 4);
}
.flex-col5.gap1 {
  gap: 0.0625rem;
}
.flex-col5.gap2 > * {
  width: calc(20% - 0.125rem / 5 * 4);
}
.flex-col5.gap2 {
  gap: 0.125rem;
}
.flex-col5.gap3 > * {
  width: calc(20% - 0.1875rem / 5 * 4);
}
.flex-col5.gap3 {
  gap: 0.1875rem;
}
.flex-col5.gap4 > * {
  width: calc(20% - 0.25rem / 5 * 4);
}
.flex-col5.gap4 {
  gap: 0.25rem;
}
.flex-col5.gap5 > * {
  width: calc(20% - 0.3125rem / 5 * 4);
}
.flex-col5.gap5 {
  gap: 0.3125rem;
}
.flex-col5.gap6 > * {
  width: calc(20% - 0.375rem / 5 * 4);
}
.flex-col5.gap6 {
  gap: 0.375rem;
}
.flex-col5.gap7 > * {
  width: calc(20% - 0.4375rem / 5 * 4);
}
.flex-col5.gap7 {
  gap: 0.4375rem;
}
.flex-col5.gap8 > * {
  width: calc(20% - 0.5rem / 5 * 4);
}
.flex-col5.gap8 {
  gap: 0.5rem;
}
.flex-col5.gap9 > * {
  width: calc(20% - 0.5625rem / 5 * 4);
}
.flex-col5.gap9 {
  gap: 0.5625rem;
}
.flex-col5.gap10 > * {
  width: calc(20% - 0.625rem / 5 * 4);
}
.flex-col5.gap10 {
  gap: 0.625rem;
}
.flex-col5.gap11 > * {
  width: calc(20% - 0.6875rem / 5 * 4);
}
.flex-col5.gap11 {
  gap: 0.6875rem;
}
.flex-col5.gap12 > * {
  width: calc(20% - 0.75rem / 5 * 4);
}
.flex-col5.gap12 {
  gap: 0.75rem;
}
.flex-col5.gap13 > * {
  width: calc(20% - 0.8125rem / 5 * 4);
}
.flex-col5.gap13 {
  gap: 0.8125rem;
}
.flex-col5.gap14 > * {
  width: calc(20% - 0.875rem / 5 * 4);
}
.flex-col5.gap14 {
  gap: 0.875rem;
}
.flex-col5.gap15 > * {
  width: calc(20% - 0.9375rem / 5 * 4);
}
.flex-col5.gap15 {
  gap: 0.9375rem;
}
.flex-col5.gap16 > * {
  width: calc(20% - 1rem / 5 * 4);
}
.flex-col5.gap16 {
  gap: 1rem;
}
.flex-col5.gap17 > * {
  width: calc(20% - 1.0625rem / 5 * 4);
}
.flex-col5.gap17 {
  gap: 1.0625rem;
}
.flex-col5.gap18 > * {
  width: calc(20% - 1.125rem / 5 * 4);
}
.flex-col5.gap18 {
  gap: 1.125rem;
}
.flex-col5.gap19 > * {
  width: calc(20% - 1.1875rem / 5 * 4);
}
.flex-col5.gap19 {
  gap: 1.1875rem;
}
.flex-col5.gap20 > * {
  width: calc(20% - 1.25rem / 5 * 4);
}
.flex-col5.gap20 {
  gap: 1.25rem;
}
.flex-col5.gap21 > * {
  width: calc(20% - 1.3125rem / 5 * 4);
}
.flex-col5.gap21 {
  gap: 1.3125rem;
}
.flex-col5.gap22 > * {
  width: calc(20% - 1.375rem / 5 * 4);
}
.flex-col5.gap22 {
  gap: 1.375rem;
}
.flex-col5.gap23 > * {
  width: calc(20% - 1.4375rem / 5 * 4);
}
.flex-col5.gap23 {
  gap: 1.4375rem;
}
.flex-col5.gap24 > * {
  width: calc(20% - 1.5rem / 5 * 4);
}
.flex-col5.gap24 {
  gap: 1.5rem;
}
.flex-col5.gap25 > * {
  width: calc(20% - 1.5625rem / 5 * 4);
}
.flex-col5.gap25 {
  gap: 1.5625rem;
}
.flex-col5.gap26 > * {
  width: calc(20% - 1.625rem / 5 * 4);
}
.flex-col5.gap26 {
  gap: 1.625rem;
}
.flex-col5.gap27 > * {
  width: calc(20% - 1.6875rem / 5 * 4);
}
.flex-col5.gap27 {
  gap: 1.6875rem;
}
.flex-col5.gap28 > * {
  width: calc(20% - 1.75rem / 5 * 4);
}
.flex-col5.gap28 {
  gap: 1.75rem;
}
.flex-col5.gap29 > * {
  width: calc(20% - 1.8125rem / 5 * 4);
}
.flex-col5.gap29 {
  gap: 1.8125rem;
}
.flex-col5.gap30 > * {
  width: calc(20% - 1.875rem / 5 * 4);
}
.flex-col5.gap30 {
  gap: 1.875rem;
}
.flex-col5.gap31 > * {
  width: calc(20% - 1.9375rem / 5 * 4);
}
.flex-col5.gap31 {
  gap: 1.9375rem;
}
.flex-col5.gap32 > * {
  width: calc(20% - 2rem / 5 * 4);
}
.flex-col5.gap32 {
  gap: 2rem;
}
.flex-col5.gap33 > * {
  width: calc(20% - 2.0625rem / 5 * 4);
}
.flex-col5.gap33 {
  gap: 2.0625rem;
}
.flex-col5.gap34 > * {
  width: calc(20% - 2.125rem / 5 * 4);
}
.flex-col5.gap34 {
  gap: 2.125rem;
}
.flex-col5.gap35 > * {
  width: calc(20% - 2.1875rem / 5 * 4);
}
.flex-col5.gap35 {
  gap: 2.1875rem;
}
.flex-col5.gap36 > * {
  width: calc(20% - 2.25rem / 5 * 4);
}
.flex-col5.gap36 {
  gap: 2.25rem;
}
.flex-col5.gap37 > * {
  width: calc(20% - 2.3125rem / 5 * 4);
}
.flex-col5.gap37 {
  gap: 2.3125rem;
}
.flex-col5.gap38 > * {
  width: calc(20% - 2.375rem / 5 * 4);
}
.flex-col5.gap38 {
  gap: 2.375rem;
}
.flex-col5.gap39 > * {
  width: calc(20% - 2.4375rem / 5 * 4);
}
.flex-col5.gap39 {
  gap: 2.4375rem;
}
.flex-col5.gap40 > * {
  width: calc(20% - 2.5rem / 5 * 4);
}
.flex-col5.gap40 {
  gap: 2.5rem;
}
.flex-col5.gap41 > * {
  width: calc(20% - 2.5625rem / 5 * 4);
}
.flex-col5.gap41 {
  gap: 2.5625rem;
}
.flex-col5.gap42 > * {
  width: calc(20% - 2.625rem / 5 * 4);
}
.flex-col5.gap42 {
  gap: 2.625rem;
}
.flex-col5.gap43 > * {
  width: calc(20% - 2.6875rem / 5 * 4);
}
.flex-col5.gap43 {
  gap: 2.6875rem;
}
.flex-col5.gap44 > * {
  width: calc(20% - 2.75rem / 5 * 4);
}
.flex-col5.gap44 {
  gap: 2.75rem;
}
.flex-col5.gap45 > * {
  width: calc(20% - 2.8125rem / 5 * 4);
}
.flex-col5.gap45 {
  gap: 2.8125rem;
}
.flex-col5.gap46 > * {
  width: calc(20% - 2.875rem / 5 * 4);
}
.flex-col5.gap46 {
  gap: 2.875rem;
}
.flex-col5.gap47 > * {
  width: calc(20% - 2.9375rem / 5 * 4);
}
.flex-col5.gap47 {
  gap: 2.9375rem;
}
.flex-col5.gap48 > * {
  width: calc(20% - 3rem / 5 * 4);
}
.flex-col5.gap48 {
  gap: 3rem;
}
.flex-col5.gap49 > * {
  width: calc(20% - 3.0625rem / 5 * 4);
}
.flex-col5.gap49 {
  gap: 3.0625rem;
}
.flex-col5.gap50 > * {
  width: calc(20% - 3.125rem / 5 * 4);
}
.flex-col5.gap50 {
  gap: 3.125rem;
}
.flex-col5.gap51 > * {
  width: calc(20% - 3.1875rem / 5 * 4);
}
.flex-col5.gap51 {
  gap: 3.1875rem;
}
.flex-col5.gap52 > * {
  width: calc(20% - 3.25rem / 5 * 4);
}
.flex-col5.gap52 {
  gap: 3.25rem;
}
.flex-col5.gap53 > * {
  width: calc(20% - 3.3125rem / 5 * 4);
}
.flex-col5.gap53 {
  gap: 3.3125rem;
}
.flex-col5.gap54 > * {
  width: calc(20% - 3.375rem / 5 * 4);
}
.flex-col5.gap54 {
  gap: 3.375rem;
}
.flex-col5.gap55 > * {
  width: calc(20% - 3.4375rem / 5 * 4);
}
.flex-col5.gap55 {
  gap: 3.4375rem;
}
.flex-col5.gap56 > * {
  width: calc(20% - 3.5rem / 5 * 4);
}
.flex-col5.gap56 {
  gap: 3.5rem;
}
.flex-col5.gap57 > * {
  width: calc(20% - 3.5625rem / 5 * 4);
}
.flex-col5.gap57 {
  gap: 3.5625rem;
}
.flex-col5.gap58 > * {
  width: calc(20% - 3.625rem / 5 * 4);
}
.flex-col5.gap58 {
  gap: 3.625rem;
}
.flex-col5.gap59 > * {
  width: calc(20% - 3.6875rem / 5 * 4);
}
.flex-col5.gap59 {
  gap: 3.6875rem;
}
.flex-col5.gap60 > * {
  width: calc(20% - 3.75rem / 5 * 4);
}
.flex-col5.gap60 {
  gap: 3.75rem;
}
.flex-col5.gap61 > * {
  width: calc(20% - 3.8125rem / 5 * 4);
}
.flex-col5.gap61 {
  gap: 3.8125rem;
}
.flex-col5.gap62 > * {
  width: calc(20% - 3.875rem / 5 * 4);
}
.flex-col5.gap62 {
  gap: 3.875rem;
}
.flex-col5.gap63 > * {
  width: calc(20% - 3.9375rem / 5 * 4);
}
.flex-col5.gap63 {
  gap: 3.9375rem;
}
.flex-col5.gap64 > * {
  width: calc(20% - 4rem / 5 * 4);
}
.flex-col5.gap64 {
  gap: 4rem;
}
.flex-col5.gap65 > * {
  width: calc(20% - 4.0625rem / 5 * 4);
}
.flex-col5.gap65 {
  gap: 4.0625rem;
}
.flex-col5.gap66 > * {
  width: calc(20% - 4.125rem / 5 * 4);
}
.flex-col5.gap66 {
  gap: 4.125rem;
}
.flex-col5.gap67 > * {
  width: calc(20% - 4.1875rem / 5 * 4);
}
.flex-col5.gap67 {
  gap: 4.1875rem;
}
.flex-col5.gap68 > * {
  width: calc(20% - 4.25rem / 5 * 4);
}
.flex-col5.gap68 {
  gap: 4.25rem;
}
.flex-col5.gap69 > * {
  width: calc(20% - 4.3125rem / 5 * 4);
}
.flex-col5.gap69 {
  gap: 4.3125rem;
}
.flex-col5.gap70 > * {
  width: calc(20% - 4.375rem / 5 * 4);
}
.flex-col5.gap70 {
  gap: 4.375rem;
}
.flex-col5.gap71 > * {
  width: calc(20% - 4.4375rem / 5 * 4);
}
.flex-col5.gap71 {
  gap: 4.4375rem;
}
.flex-col5.gap72 > * {
  width: calc(20% - 4.5rem / 5 * 4);
}
.flex-col5.gap72 {
  gap: 4.5rem;
}
.flex-col5.gap73 > * {
  width: calc(20% - 4.5625rem / 5 * 4);
}
.flex-col5.gap73 {
  gap: 4.5625rem;
}
.flex-col5.gap74 > * {
  width: calc(20% - 4.625rem / 5 * 4);
}
.flex-col5.gap74 {
  gap: 4.625rem;
}
.flex-col5.gap75 > * {
  width: calc(20% - 4.6875rem / 5 * 4);
}
.flex-col5.gap75 {
  gap: 4.6875rem;
}
.flex-col5.gap76 > * {
  width: calc(20% - 4.75rem / 5 * 4);
}
.flex-col5.gap76 {
  gap: 4.75rem;
}
.flex-col5.gap77 > * {
  width: calc(20% - 4.8125rem / 5 * 4);
}
.flex-col5.gap77 {
  gap: 4.8125rem;
}
.flex-col5.gap78 > * {
  width: calc(20% - 4.875rem / 5 * 4);
}
.flex-col5.gap78 {
  gap: 4.875rem;
}
.flex-col5.gap79 > * {
  width: calc(20% - 4.9375rem / 5 * 4);
}
.flex-col5.gap79 {
  gap: 4.9375rem;
}
.flex-col5.gap80 > * {
  width: calc(20% - 5rem / 5 * 4);
}
.flex-col5.gap80 {
  gap: 5rem;
}
.flex-col5.gap81 > * {
  width: calc(20% - 5.0625rem / 5 * 4);
}
.flex-col5.gap81 {
  gap: 5.0625rem;
}
.flex-col5.gap82 > * {
  width: calc(20% - 5.125rem / 5 * 4);
}
.flex-col5.gap82 {
  gap: 5.125rem;
}
.flex-col5.gap83 > * {
  width: calc(20% - 5.1875rem / 5 * 4);
}
.flex-col5.gap83 {
  gap: 5.1875rem;
}
.flex-col5.gap84 > * {
  width: calc(20% - 5.25rem / 5 * 4);
}
.flex-col5.gap84 {
  gap: 5.25rem;
}
.flex-col5.gap85 > * {
  width: calc(20% - 5.3125rem / 5 * 4);
}
.flex-col5.gap85 {
  gap: 5.3125rem;
}
.flex-col5.gap86 > * {
  width: calc(20% - 5.375rem / 5 * 4);
}
.flex-col5.gap86 {
  gap: 5.375rem;
}
.flex-col5.gap87 > * {
  width: calc(20% - 5.4375rem / 5 * 4);
}
.flex-col5.gap87 {
  gap: 5.4375rem;
}
.flex-col5.gap88 > * {
  width: calc(20% - 5.5rem / 5 * 4);
}
.flex-col5.gap88 {
  gap: 5.5rem;
}
.flex-col5.gap89 > * {
  width: calc(20% - 5.5625rem / 5 * 4);
}
.flex-col5.gap89 {
  gap: 5.5625rem;
}
.flex-col5.gap90 > * {
  width: calc(20% - 5.625rem / 5 * 4);
}
.flex-col5.gap90 {
  gap: 5.625rem;
}
.flex-col5.gap91 > * {
  width: calc(20% - 5.6875rem / 5 * 4);
}
.flex-col5.gap91 {
  gap: 5.6875rem;
}
.flex-col5.gap92 > * {
  width: calc(20% - 5.75rem / 5 * 4);
}
.flex-col5.gap92 {
  gap: 5.75rem;
}
.flex-col5.gap93 > * {
  width: calc(20% - 5.8125rem / 5 * 4);
}
.flex-col5.gap93 {
  gap: 5.8125rem;
}
.flex-col5.gap94 > * {
  width: calc(20% - 5.875rem / 5 * 4);
}
.flex-col5.gap94 {
  gap: 5.875rem;
}
.flex-col5.gap95 > * {
  width: calc(20% - 5.9375rem / 5 * 4);
}
.flex-col5.gap95 {
  gap: 5.9375rem;
}
.flex-col5.gap96 > * {
  width: calc(20% - 6rem / 5 * 4);
}
.flex-col5.gap96 {
  gap: 6rem;
}
.flex-col5.gap97 > * {
  width: calc(20% - 6.0625rem / 5 * 4);
}
.flex-col5.gap97 {
  gap: 6.0625rem;
}
.flex-col5.gap98 > * {
  width: calc(20% - 6.125rem / 5 * 4);
}
.flex-col5.gap98 {
  gap: 6.125rem;
}
.flex-col5.gap99 > * {
  width: calc(20% - 6.1875rem / 5 * 4);
}
.flex-col5.gap99 {
  gap: 6.1875rem;
}
.flex-col5.gap100 > * {
  width: calc(20% - 6.25rem / 5 * 4);
}
.flex-col5.gap100 {
  gap: 6.25rem;
}
.flex-col5.gap101 > * {
  width: calc(20% - 6.3125rem / 5 * 4);
}
.flex-col5.gap101 {
  gap: 6.3125rem;
}
.flex-col5.gap102 > * {
  width: calc(20% - 6.375rem / 5 * 4);
}
.flex-col5.gap102 {
  gap: 6.375rem;
}
.flex-col5.gap103 > * {
  width: calc(20% - 6.4375rem / 5 * 4);
}
.flex-col5.gap103 {
  gap: 6.4375rem;
}
.flex-col5.gap104 > * {
  width: calc(20% - 6.5rem / 5 * 4);
}
.flex-col5.gap104 {
  gap: 6.5rem;
}
.flex-col5.gap105 > * {
  width: calc(20% - 6.5625rem / 5 * 4);
}
.flex-col5.gap105 {
  gap: 6.5625rem;
}
.flex-col5.gap106 > * {
  width: calc(20% - 6.625rem / 5 * 4);
}
.flex-col5.gap106 {
  gap: 6.625rem;
}
.flex-col5.gap107 > * {
  width: calc(20% - 6.6875rem / 5 * 4);
}
.flex-col5.gap107 {
  gap: 6.6875rem;
}
.flex-col5.gap108 > * {
  width: calc(20% - 6.75rem / 5 * 4);
}
.flex-col5.gap108 {
  gap: 6.75rem;
}
.flex-col5.gap109 > * {
  width: calc(20% - 6.8125rem / 5 * 4);
}
.flex-col5.gap109 {
  gap: 6.8125rem;
}
.flex-col5.gap110 > * {
  width: calc(20% - 6.875rem / 5 * 4);
}
.flex-col5.gap110 {
  gap: 6.875rem;
}
.flex-col5.gap111 > * {
  width: calc(20% - 6.9375rem / 5 * 4);
}
.flex-col5.gap111 {
  gap: 6.9375rem;
}
.flex-col5.gap112 > * {
  width: calc(20% - 7rem / 5 * 4);
}
.flex-col5.gap112 {
  gap: 7rem;
}
.flex-col5.gap113 > * {
  width: calc(20% - 7.0625rem / 5 * 4);
}
.flex-col5.gap113 {
  gap: 7.0625rem;
}
.flex-col5.gap114 > * {
  width: calc(20% - 7.125rem / 5 * 4);
}
.flex-col5.gap114 {
  gap: 7.125rem;
}
.flex-col5.gap115 > * {
  width: calc(20% - 7.1875rem / 5 * 4);
}
.flex-col5.gap115 {
  gap: 7.1875rem;
}
.flex-col5.gap116 > * {
  width: calc(20% - 7.25rem / 5 * 4);
}
.flex-col5.gap116 {
  gap: 7.25rem;
}
.flex-col5.gap117 > * {
  width: calc(20% - 7.3125rem / 5 * 4);
}
.flex-col5.gap117 {
  gap: 7.3125rem;
}
.flex-col5.gap118 > * {
  width: calc(20% - 7.375rem / 5 * 4);
}
.flex-col5.gap118 {
  gap: 7.375rem;
}
.flex-col5.gap119 > * {
  width: calc(20% - 7.4375rem / 5 * 4);
}
.flex-col5.gap119 {
  gap: 7.4375rem;
}
.flex-col5.gap120 > * {
  width: calc(20% - 7.5rem / 5 * 4);
}
.flex-col5.gap120 {
  gap: 7.5rem;
}
.flex-col5.gap121 > * {
  width: calc(20% - 7.5625rem / 5 * 4);
}
.flex-col5.gap121 {
  gap: 7.5625rem;
}
.flex-col5.gap122 > * {
  width: calc(20% - 7.625rem / 5 * 4);
}
.flex-col5.gap122 {
  gap: 7.625rem;
}
.flex-col5.gap123 > * {
  width: calc(20% - 7.6875rem / 5 * 4);
}
.flex-col5.gap123 {
  gap: 7.6875rem;
}
.flex-col5.gap124 > * {
  width: calc(20% - 7.75rem / 5 * 4);
}
.flex-col5.gap124 {
  gap: 7.75rem;
}
.flex-col5.gap125 > * {
  width: calc(20% - 7.8125rem / 5 * 4);
}
.flex-col5.gap125 {
  gap: 7.8125rem;
}
.flex-col5.gap126 > * {
  width: calc(20% - 7.875rem / 5 * 4);
}
.flex-col5.gap126 {
  gap: 7.875rem;
}
.flex-col5.gap127 > * {
  width: calc(20% - 7.9375rem / 5 * 4);
}
.flex-col5.gap127 {
  gap: 7.9375rem;
}
.flex-col5.gap128 > * {
  width: calc(20% - 8rem / 5 * 4);
}
.flex-col5.gap128 {
  gap: 8rem;
}
.flex-col5.gap129 > * {
  width: calc(20% - 8.0625rem / 5 * 4);
}
.flex-col5.gap129 {
  gap: 8.0625rem;
}
.flex-col5.gap130 > * {
  width: calc(20% - 8.125rem / 5 * 4);
}
.flex-col5.gap130 {
  gap: 8.125rem;
}
.flex-col5.gap131 > * {
  width: calc(20% - 8.1875rem / 5 * 4);
}
.flex-col5.gap131 {
  gap: 8.1875rem;
}
.flex-col5.gap132 > * {
  width: calc(20% - 8.25rem / 5 * 4);
}
.flex-col5.gap132 {
  gap: 8.25rem;
}
.flex-col5.gap133 > * {
  width: calc(20% - 8.3125rem / 5 * 4);
}
.flex-col5.gap133 {
  gap: 8.3125rem;
}
.flex-col5.gap134 > * {
  width: calc(20% - 8.375rem / 5 * 4);
}
.flex-col5.gap134 {
  gap: 8.375rem;
}
.flex-col5.gap135 > * {
  width: calc(20% - 8.4375rem / 5 * 4);
}
.flex-col5.gap135 {
  gap: 8.4375rem;
}
.flex-col5.gap136 > * {
  width: calc(20% - 8.5rem / 5 * 4);
}
.flex-col5.gap136 {
  gap: 8.5rem;
}
.flex-col5.gap137 > * {
  width: calc(20% - 8.5625rem / 5 * 4);
}
.flex-col5.gap137 {
  gap: 8.5625rem;
}
.flex-col5.gap138 > * {
  width: calc(20% - 8.625rem / 5 * 4);
}
.flex-col5.gap138 {
  gap: 8.625rem;
}
.flex-col5.gap139 > * {
  width: calc(20% - 8.6875rem / 5 * 4);
}
.flex-col5.gap139 {
  gap: 8.6875rem;
}
.flex-col5.gap140 > * {
  width: calc(20% - 8.75rem / 5 * 4);
}
.flex-col5.gap140 {
  gap: 8.75rem;
}
.flex-col5.gap141 > * {
  width: calc(20% - 8.8125rem / 5 * 4);
}
.flex-col5.gap141 {
  gap: 8.8125rem;
}
.flex-col5.gap142 > * {
  width: calc(20% - 8.875rem / 5 * 4);
}
.flex-col5.gap142 {
  gap: 8.875rem;
}
.flex-col5.gap143 > * {
  width: calc(20% - 8.9375rem / 5 * 4);
}
.flex-col5.gap143 {
  gap: 8.9375rem;
}
.flex-col5.gap144 > * {
  width: calc(20% - 9rem / 5 * 4);
}
.flex-col5.gap144 {
  gap: 9rem;
}
.flex-col5.gap145 > * {
  width: calc(20% - 9.0625rem / 5 * 4);
}
.flex-col5.gap145 {
  gap: 9.0625rem;
}
.flex-col5.gap146 > * {
  width: calc(20% - 9.125rem / 5 * 4);
}
.flex-col5.gap146 {
  gap: 9.125rem;
}
.flex-col5.gap147 > * {
  width: calc(20% - 9.1875rem / 5 * 4);
}
.flex-col5.gap147 {
  gap: 9.1875rem;
}
.flex-col5.gap148 > * {
  width: calc(20% - 9.25rem / 5 * 4);
}
.flex-col5.gap148 {
  gap: 9.25rem;
}
.flex-col5.gap149 > * {
  width: calc(20% - 9.3125rem / 5 * 4);
}
.flex-col5.gap149 {
  gap: 9.3125rem;
}
.flex-col5.gap150 > * {
  width: calc(20% - 9.375rem / 5 * 4);
}
.flex-col5.gap150 {
  gap: 9.375rem;
}

.flex-col6 {
  flex-wrap: wrap;
}
.flex-col6 > * {
  width: 16.6666666667%;
}
.flex-col6.gap1 > * {
  width: calc(16.6666666667% - 0.0625rem / 6 * 5);
}
.flex-col6.gap1 {
  gap: 0.0625rem;
}
.flex-col6.gap2 > * {
  width: calc(16.6666666667% - 0.125rem / 6 * 5);
}
.flex-col6.gap2 {
  gap: 0.125rem;
}
.flex-col6.gap3 > * {
  width: calc(16.6666666667% - 0.1875rem / 6 * 5);
}
.flex-col6.gap3 {
  gap: 0.1875rem;
}
.flex-col6.gap4 > * {
  width: calc(16.6666666667% - 0.25rem / 6 * 5);
}
.flex-col6.gap4 {
  gap: 0.25rem;
}
.flex-col6.gap5 > * {
  width: calc(16.6666666667% - 0.3125rem / 6 * 5);
}
.flex-col6.gap5 {
  gap: 0.3125rem;
}
.flex-col6.gap6 > * {
  width: calc(16.6666666667% - 0.375rem / 6 * 5);
}
.flex-col6.gap6 {
  gap: 0.375rem;
}
.flex-col6.gap7 > * {
  width: calc(16.6666666667% - 0.4375rem / 6 * 5);
}
.flex-col6.gap7 {
  gap: 0.4375rem;
}
.flex-col6.gap8 > * {
  width: calc(16.6666666667% - 0.5rem / 6 * 5);
}
.flex-col6.gap8 {
  gap: 0.5rem;
}
.flex-col6.gap9 > * {
  width: calc(16.6666666667% - 0.5625rem / 6 * 5);
}
.flex-col6.gap9 {
  gap: 0.5625rem;
}
.flex-col6.gap10 > * {
  width: calc(16.6666666667% - 0.625rem / 6 * 5);
}
.flex-col6.gap10 {
  gap: 0.625rem;
}
.flex-col6.gap11 > * {
  width: calc(16.6666666667% - 0.6875rem / 6 * 5);
}
.flex-col6.gap11 {
  gap: 0.6875rem;
}
.flex-col6.gap12 > * {
  width: calc(16.6666666667% - 0.75rem / 6 * 5);
}
.flex-col6.gap12 {
  gap: 0.75rem;
}
.flex-col6.gap13 > * {
  width: calc(16.6666666667% - 0.8125rem / 6 * 5);
}
.flex-col6.gap13 {
  gap: 0.8125rem;
}
.flex-col6.gap14 > * {
  width: calc(16.6666666667% - 0.875rem / 6 * 5);
}
.flex-col6.gap14 {
  gap: 0.875rem;
}
.flex-col6.gap15 > * {
  width: calc(16.6666666667% - 0.9375rem / 6 * 5);
}
.flex-col6.gap15 {
  gap: 0.9375rem;
}
.flex-col6.gap16 > * {
  width: calc(16.6666666667% - 1rem / 6 * 5);
}
.flex-col6.gap16 {
  gap: 1rem;
}
.flex-col6.gap17 > * {
  width: calc(16.6666666667% - 1.0625rem / 6 * 5);
}
.flex-col6.gap17 {
  gap: 1.0625rem;
}
.flex-col6.gap18 > * {
  width: calc(16.6666666667% - 1.125rem / 6 * 5);
}
.flex-col6.gap18 {
  gap: 1.125rem;
}
.flex-col6.gap19 > * {
  width: calc(16.6666666667% - 1.1875rem / 6 * 5);
}
.flex-col6.gap19 {
  gap: 1.1875rem;
}
.flex-col6.gap20 > * {
  width: calc(16.6666666667% - 1.25rem / 6 * 5);
}
.flex-col6.gap20 {
  gap: 1.25rem;
}
.flex-col6.gap21 > * {
  width: calc(16.6666666667% - 1.3125rem / 6 * 5);
}
.flex-col6.gap21 {
  gap: 1.3125rem;
}
.flex-col6.gap22 > * {
  width: calc(16.6666666667% - 1.375rem / 6 * 5);
}
.flex-col6.gap22 {
  gap: 1.375rem;
}
.flex-col6.gap23 > * {
  width: calc(16.6666666667% - 1.4375rem / 6 * 5);
}
.flex-col6.gap23 {
  gap: 1.4375rem;
}
.flex-col6.gap24 > * {
  width: calc(16.6666666667% - 1.5rem / 6 * 5);
}
.flex-col6.gap24 {
  gap: 1.5rem;
}
.flex-col6.gap25 > * {
  width: calc(16.6666666667% - 1.5625rem / 6 * 5);
}
.flex-col6.gap25 {
  gap: 1.5625rem;
}
.flex-col6.gap26 > * {
  width: calc(16.6666666667% - 1.625rem / 6 * 5);
}
.flex-col6.gap26 {
  gap: 1.625rem;
}
.flex-col6.gap27 > * {
  width: calc(16.6666666667% - 1.6875rem / 6 * 5);
}
.flex-col6.gap27 {
  gap: 1.6875rem;
}
.flex-col6.gap28 > * {
  width: calc(16.6666666667% - 1.75rem / 6 * 5);
}
.flex-col6.gap28 {
  gap: 1.75rem;
}
.flex-col6.gap29 > * {
  width: calc(16.6666666667% - 1.8125rem / 6 * 5);
}
.flex-col6.gap29 {
  gap: 1.8125rem;
}
.flex-col6.gap30 > * {
  width: calc(16.6666666667% - 1.875rem / 6 * 5);
}
.flex-col6.gap30 {
  gap: 1.875rem;
}
.flex-col6.gap31 > * {
  width: calc(16.6666666667% - 1.9375rem / 6 * 5);
}
.flex-col6.gap31 {
  gap: 1.9375rem;
}
.flex-col6.gap32 > * {
  width: calc(16.6666666667% - 2rem / 6 * 5);
}
.flex-col6.gap32 {
  gap: 2rem;
}
.flex-col6.gap33 > * {
  width: calc(16.6666666667% - 2.0625rem / 6 * 5);
}
.flex-col6.gap33 {
  gap: 2.0625rem;
}
.flex-col6.gap34 > * {
  width: calc(16.6666666667% - 2.125rem / 6 * 5);
}
.flex-col6.gap34 {
  gap: 2.125rem;
}
.flex-col6.gap35 > * {
  width: calc(16.6666666667% - 2.1875rem / 6 * 5);
}
.flex-col6.gap35 {
  gap: 2.1875rem;
}
.flex-col6.gap36 > * {
  width: calc(16.6666666667% - 2.25rem / 6 * 5);
}
.flex-col6.gap36 {
  gap: 2.25rem;
}
.flex-col6.gap37 > * {
  width: calc(16.6666666667% - 2.3125rem / 6 * 5);
}
.flex-col6.gap37 {
  gap: 2.3125rem;
}
.flex-col6.gap38 > * {
  width: calc(16.6666666667% - 2.375rem / 6 * 5);
}
.flex-col6.gap38 {
  gap: 2.375rem;
}
.flex-col6.gap39 > * {
  width: calc(16.6666666667% - 2.4375rem / 6 * 5);
}
.flex-col6.gap39 {
  gap: 2.4375rem;
}
.flex-col6.gap40 > * {
  width: calc(16.6666666667% - 2.5rem / 6 * 5);
}
.flex-col6.gap40 {
  gap: 2.5rem;
}
.flex-col6.gap41 > * {
  width: calc(16.6666666667% - 2.5625rem / 6 * 5);
}
.flex-col6.gap41 {
  gap: 2.5625rem;
}
.flex-col6.gap42 > * {
  width: calc(16.6666666667% - 2.625rem / 6 * 5);
}
.flex-col6.gap42 {
  gap: 2.625rem;
}
.flex-col6.gap43 > * {
  width: calc(16.6666666667% - 2.6875rem / 6 * 5);
}
.flex-col6.gap43 {
  gap: 2.6875rem;
}
.flex-col6.gap44 > * {
  width: calc(16.6666666667% - 2.75rem / 6 * 5);
}
.flex-col6.gap44 {
  gap: 2.75rem;
}
.flex-col6.gap45 > * {
  width: calc(16.6666666667% - 2.8125rem / 6 * 5);
}
.flex-col6.gap45 {
  gap: 2.8125rem;
}
.flex-col6.gap46 > * {
  width: calc(16.6666666667% - 2.875rem / 6 * 5);
}
.flex-col6.gap46 {
  gap: 2.875rem;
}
.flex-col6.gap47 > * {
  width: calc(16.6666666667% - 2.9375rem / 6 * 5);
}
.flex-col6.gap47 {
  gap: 2.9375rem;
}
.flex-col6.gap48 > * {
  width: calc(16.6666666667% - 3rem / 6 * 5);
}
.flex-col6.gap48 {
  gap: 3rem;
}
.flex-col6.gap49 > * {
  width: calc(16.6666666667% - 3.0625rem / 6 * 5);
}
.flex-col6.gap49 {
  gap: 3.0625rem;
}
.flex-col6.gap50 > * {
  width: calc(16.6666666667% - 3.125rem / 6 * 5);
}
.flex-col6.gap50 {
  gap: 3.125rem;
}
.flex-col6.gap51 > * {
  width: calc(16.6666666667% - 3.1875rem / 6 * 5);
}
.flex-col6.gap51 {
  gap: 3.1875rem;
}
.flex-col6.gap52 > * {
  width: calc(16.6666666667% - 3.25rem / 6 * 5);
}
.flex-col6.gap52 {
  gap: 3.25rem;
}
.flex-col6.gap53 > * {
  width: calc(16.6666666667% - 3.3125rem / 6 * 5);
}
.flex-col6.gap53 {
  gap: 3.3125rem;
}
.flex-col6.gap54 > * {
  width: calc(16.6666666667% - 3.375rem / 6 * 5);
}
.flex-col6.gap54 {
  gap: 3.375rem;
}
.flex-col6.gap55 > * {
  width: calc(16.6666666667% - 3.4375rem / 6 * 5);
}
.flex-col6.gap55 {
  gap: 3.4375rem;
}
.flex-col6.gap56 > * {
  width: calc(16.6666666667% - 3.5rem / 6 * 5);
}
.flex-col6.gap56 {
  gap: 3.5rem;
}
.flex-col6.gap57 > * {
  width: calc(16.6666666667% - 3.5625rem / 6 * 5);
}
.flex-col6.gap57 {
  gap: 3.5625rem;
}
.flex-col6.gap58 > * {
  width: calc(16.6666666667% - 3.625rem / 6 * 5);
}
.flex-col6.gap58 {
  gap: 3.625rem;
}
.flex-col6.gap59 > * {
  width: calc(16.6666666667% - 3.6875rem / 6 * 5);
}
.flex-col6.gap59 {
  gap: 3.6875rem;
}
.flex-col6.gap60 > * {
  width: calc(16.6666666667% - 3.75rem / 6 * 5);
}
.flex-col6.gap60 {
  gap: 3.75rem;
}
.flex-col6.gap61 > * {
  width: calc(16.6666666667% - 3.8125rem / 6 * 5);
}
.flex-col6.gap61 {
  gap: 3.8125rem;
}
.flex-col6.gap62 > * {
  width: calc(16.6666666667% - 3.875rem / 6 * 5);
}
.flex-col6.gap62 {
  gap: 3.875rem;
}
.flex-col6.gap63 > * {
  width: calc(16.6666666667% - 3.9375rem / 6 * 5);
}
.flex-col6.gap63 {
  gap: 3.9375rem;
}
.flex-col6.gap64 > * {
  width: calc(16.6666666667% - 4rem / 6 * 5);
}
.flex-col6.gap64 {
  gap: 4rem;
}
.flex-col6.gap65 > * {
  width: calc(16.6666666667% - 4.0625rem / 6 * 5);
}
.flex-col6.gap65 {
  gap: 4.0625rem;
}
.flex-col6.gap66 > * {
  width: calc(16.6666666667% - 4.125rem / 6 * 5);
}
.flex-col6.gap66 {
  gap: 4.125rem;
}
.flex-col6.gap67 > * {
  width: calc(16.6666666667% - 4.1875rem / 6 * 5);
}
.flex-col6.gap67 {
  gap: 4.1875rem;
}
.flex-col6.gap68 > * {
  width: calc(16.6666666667% - 4.25rem / 6 * 5);
}
.flex-col6.gap68 {
  gap: 4.25rem;
}
.flex-col6.gap69 > * {
  width: calc(16.6666666667% - 4.3125rem / 6 * 5);
}
.flex-col6.gap69 {
  gap: 4.3125rem;
}
.flex-col6.gap70 > * {
  width: calc(16.6666666667% - 4.375rem / 6 * 5);
}
.flex-col6.gap70 {
  gap: 4.375rem;
}
.flex-col6.gap71 > * {
  width: calc(16.6666666667% - 4.4375rem / 6 * 5);
}
.flex-col6.gap71 {
  gap: 4.4375rem;
}
.flex-col6.gap72 > * {
  width: calc(16.6666666667% - 4.5rem / 6 * 5);
}
.flex-col6.gap72 {
  gap: 4.5rem;
}
.flex-col6.gap73 > * {
  width: calc(16.6666666667% - 4.5625rem / 6 * 5);
}
.flex-col6.gap73 {
  gap: 4.5625rem;
}
.flex-col6.gap74 > * {
  width: calc(16.6666666667% - 4.625rem / 6 * 5);
}
.flex-col6.gap74 {
  gap: 4.625rem;
}
.flex-col6.gap75 > * {
  width: calc(16.6666666667% - 4.6875rem / 6 * 5);
}
.flex-col6.gap75 {
  gap: 4.6875rem;
}
.flex-col6.gap76 > * {
  width: calc(16.6666666667% - 4.75rem / 6 * 5);
}
.flex-col6.gap76 {
  gap: 4.75rem;
}
.flex-col6.gap77 > * {
  width: calc(16.6666666667% - 4.8125rem / 6 * 5);
}
.flex-col6.gap77 {
  gap: 4.8125rem;
}
.flex-col6.gap78 > * {
  width: calc(16.6666666667% - 4.875rem / 6 * 5);
}
.flex-col6.gap78 {
  gap: 4.875rem;
}
.flex-col6.gap79 > * {
  width: calc(16.6666666667% - 4.9375rem / 6 * 5);
}
.flex-col6.gap79 {
  gap: 4.9375rem;
}
.flex-col6.gap80 > * {
  width: calc(16.6666666667% - 5rem / 6 * 5);
}
.flex-col6.gap80 {
  gap: 5rem;
}
.flex-col6.gap81 > * {
  width: calc(16.6666666667% - 5.0625rem / 6 * 5);
}
.flex-col6.gap81 {
  gap: 5.0625rem;
}
.flex-col6.gap82 > * {
  width: calc(16.6666666667% - 5.125rem / 6 * 5);
}
.flex-col6.gap82 {
  gap: 5.125rem;
}
.flex-col6.gap83 > * {
  width: calc(16.6666666667% - 5.1875rem / 6 * 5);
}
.flex-col6.gap83 {
  gap: 5.1875rem;
}
.flex-col6.gap84 > * {
  width: calc(16.6666666667% - 5.25rem / 6 * 5);
}
.flex-col6.gap84 {
  gap: 5.25rem;
}
.flex-col6.gap85 > * {
  width: calc(16.6666666667% - 5.3125rem / 6 * 5);
}
.flex-col6.gap85 {
  gap: 5.3125rem;
}
.flex-col6.gap86 > * {
  width: calc(16.6666666667% - 5.375rem / 6 * 5);
}
.flex-col6.gap86 {
  gap: 5.375rem;
}
.flex-col6.gap87 > * {
  width: calc(16.6666666667% - 5.4375rem / 6 * 5);
}
.flex-col6.gap87 {
  gap: 5.4375rem;
}
.flex-col6.gap88 > * {
  width: calc(16.6666666667% - 5.5rem / 6 * 5);
}
.flex-col6.gap88 {
  gap: 5.5rem;
}
.flex-col6.gap89 > * {
  width: calc(16.6666666667% - 5.5625rem / 6 * 5);
}
.flex-col6.gap89 {
  gap: 5.5625rem;
}
.flex-col6.gap90 > * {
  width: calc(16.6666666667% - 5.625rem / 6 * 5);
}
.flex-col6.gap90 {
  gap: 5.625rem;
}
.flex-col6.gap91 > * {
  width: calc(16.6666666667% - 5.6875rem / 6 * 5);
}
.flex-col6.gap91 {
  gap: 5.6875rem;
}
.flex-col6.gap92 > * {
  width: calc(16.6666666667% - 5.75rem / 6 * 5);
}
.flex-col6.gap92 {
  gap: 5.75rem;
}
.flex-col6.gap93 > * {
  width: calc(16.6666666667% - 5.8125rem / 6 * 5);
}
.flex-col6.gap93 {
  gap: 5.8125rem;
}
.flex-col6.gap94 > * {
  width: calc(16.6666666667% - 5.875rem / 6 * 5);
}
.flex-col6.gap94 {
  gap: 5.875rem;
}
.flex-col6.gap95 > * {
  width: calc(16.6666666667% - 5.9375rem / 6 * 5);
}
.flex-col6.gap95 {
  gap: 5.9375rem;
}
.flex-col6.gap96 > * {
  width: calc(16.6666666667% - 6rem / 6 * 5);
}
.flex-col6.gap96 {
  gap: 6rem;
}
.flex-col6.gap97 > * {
  width: calc(16.6666666667% - 6.0625rem / 6 * 5);
}
.flex-col6.gap97 {
  gap: 6.0625rem;
}
.flex-col6.gap98 > * {
  width: calc(16.6666666667% - 6.125rem / 6 * 5);
}
.flex-col6.gap98 {
  gap: 6.125rem;
}
.flex-col6.gap99 > * {
  width: calc(16.6666666667% - 6.1875rem / 6 * 5);
}
.flex-col6.gap99 {
  gap: 6.1875rem;
}
.flex-col6.gap100 > * {
  width: calc(16.6666666667% - 6.25rem / 6 * 5);
}
.flex-col6.gap100 {
  gap: 6.25rem;
}
.flex-col6.gap101 > * {
  width: calc(16.6666666667% - 6.3125rem / 6 * 5);
}
.flex-col6.gap101 {
  gap: 6.3125rem;
}
.flex-col6.gap102 > * {
  width: calc(16.6666666667% - 6.375rem / 6 * 5);
}
.flex-col6.gap102 {
  gap: 6.375rem;
}
.flex-col6.gap103 > * {
  width: calc(16.6666666667% - 6.4375rem / 6 * 5);
}
.flex-col6.gap103 {
  gap: 6.4375rem;
}
.flex-col6.gap104 > * {
  width: calc(16.6666666667% - 6.5rem / 6 * 5);
}
.flex-col6.gap104 {
  gap: 6.5rem;
}
.flex-col6.gap105 > * {
  width: calc(16.6666666667% - 6.5625rem / 6 * 5);
}
.flex-col6.gap105 {
  gap: 6.5625rem;
}
.flex-col6.gap106 > * {
  width: calc(16.6666666667% - 6.625rem / 6 * 5);
}
.flex-col6.gap106 {
  gap: 6.625rem;
}
.flex-col6.gap107 > * {
  width: calc(16.6666666667% - 6.6875rem / 6 * 5);
}
.flex-col6.gap107 {
  gap: 6.6875rem;
}
.flex-col6.gap108 > * {
  width: calc(16.6666666667% - 6.75rem / 6 * 5);
}
.flex-col6.gap108 {
  gap: 6.75rem;
}
.flex-col6.gap109 > * {
  width: calc(16.6666666667% - 6.8125rem / 6 * 5);
}
.flex-col6.gap109 {
  gap: 6.8125rem;
}
.flex-col6.gap110 > * {
  width: calc(16.6666666667% - 6.875rem / 6 * 5);
}
.flex-col6.gap110 {
  gap: 6.875rem;
}
.flex-col6.gap111 > * {
  width: calc(16.6666666667% - 6.9375rem / 6 * 5);
}
.flex-col6.gap111 {
  gap: 6.9375rem;
}
.flex-col6.gap112 > * {
  width: calc(16.6666666667% - 7rem / 6 * 5);
}
.flex-col6.gap112 {
  gap: 7rem;
}
.flex-col6.gap113 > * {
  width: calc(16.6666666667% - 7.0625rem / 6 * 5);
}
.flex-col6.gap113 {
  gap: 7.0625rem;
}
.flex-col6.gap114 > * {
  width: calc(16.6666666667% - 7.125rem / 6 * 5);
}
.flex-col6.gap114 {
  gap: 7.125rem;
}
.flex-col6.gap115 > * {
  width: calc(16.6666666667% - 7.1875rem / 6 * 5);
}
.flex-col6.gap115 {
  gap: 7.1875rem;
}
.flex-col6.gap116 > * {
  width: calc(16.6666666667% - 7.25rem / 6 * 5);
}
.flex-col6.gap116 {
  gap: 7.25rem;
}
.flex-col6.gap117 > * {
  width: calc(16.6666666667% - 7.3125rem / 6 * 5);
}
.flex-col6.gap117 {
  gap: 7.3125rem;
}
.flex-col6.gap118 > * {
  width: calc(16.6666666667% - 7.375rem / 6 * 5);
}
.flex-col6.gap118 {
  gap: 7.375rem;
}
.flex-col6.gap119 > * {
  width: calc(16.6666666667% - 7.4375rem / 6 * 5);
}
.flex-col6.gap119 {
  gap: 7.4375rem;
}
.flex-col6.gap120 > * {
  width: calc(16.6666666667% - 7.5rem / 6 * 5);
}
.flex-col6.gap120 {
  gap: 7.5rem;
}
.flex-col6.gap121 > * {
  width: calc(16.6666666667% - 7.5625rem / 6 * 5);
}
.flex-col6.gap121 {
  gap: 7.5625rem;
}
.flex-col6.gap122 > * {
  width: calc(16.6666666667% - 7.625rem / 6 * 5);
}
.flex-col6.gap122 {
  gap: 7.625rem;
}
.flex-col6.gap123 > * {
  width: calc(16.6666666667% - 7.6875rem / 6 * 5);
}
.flex-col6.gap123 {
  gap: 7.6875rem;
}
.flex-col6.gap124 > * {
  width: calc(16.6666666667% - 7.75rem / 6 * 5);
}
.flex-col6.gap124 {
  gap: 7.75rem;
}
.flex-col6.gap125 > * {
  width: calc(16.6666666667% - 7.8125rem / 6 * 5);
}
.flex-col6.gap125 {
  gap: 7.8125rem;
}
.flex-col6.gap126 > * {
  width: calc(16.6666666667% - 7.875rem / 6 * 5);
}
.flex-col6.gap126 {
  gap: 7.875rem;
}
.flex-col6.gap127 > * {
  width: calc(16.6666666667% - 7.9375rem / 6 * 5);
}
.flex-col6.gap127 {
  gap: 7.9375rem;
}
.flex-col6.gap128 > * {
  width: calc(16.6666666667% - 8rem / 6 * 5);
}
.flex-col6.gap128 {
  gap: 8rem;
}
.flex-col6.gap129 > * {
  width: calc(16.6666666667% - 8.0625rem / 6 * 5);
}
.flex-col6.gap129 {
  gap: 8.0625rem;
}
.flex-col6.gap130 > * {
  width: calc(16.6666666667% - 8.125rem / 6 * 5);
}
.flex-col6.gap130 {
  gap: 8.125rem;
}
.flex-col6.gap131 > * {
  width: calc(16.6666666667% - 8.1875rem / 6 * 5);
}
.flex-col6.gap131 {
  gap: 8.1875rem;
}
.flex-col6.gap132 > * {
  width: calc(16.6666666667% - 8.25rem / 6 * 5);
}
.flex-col6.gap132 {
  gap: 8.25rem;
}
.flex-col6.gap133 > * {
  width: calc(16.6666666667% - 8.3125rem / 6 * 5);
}
.flex-col6.gap133 {
  gap: 8.3125rem;
}
.flex-col6.gap134 > * {
  width: calc(16.6666666667% - 8.375rem / 6 * 5);
}
.flex-col6.gap134 {
  gap: 8.375rem;
}
.flex-col6.gap135 > * {
  width: calc(16.6666666667% - 8.4375rem / 6 * 5);
}
.flex-col6.gap135 {
  gap: 8.4375rem;
}
.flex-col6.gap136 > * {
  width: calc(16.6666666667% - 8.5rem / 6 * 5);
}
.flex-col6.gap136 {
  gap: 8.5rem;
}
.flex-col6.gap137 > * {
  width: calc(16.6666666667% - 8.5625rem / 6 * 5);
}
.flex-col6.gap137 {
  gap: 8.5625rem;
}
.flex-col6.gap138 > * {
  width: calc(16.6666666667% - 8.625rem / 6 * 5);
}
.flex-col6.gap138 {
  gap: 8.625rem;
}
.flex-col6.gap139 > * {
  width: calc(16.6666666667% - 8.6875rem / 6 * 5);
}
.flex-col6.gap139 {
  gap: 8.6875rem;
}
.flex-col6.gap140 > * {
  width: calc(16.6666666667% - 8.75rem / 6 * 5);
}
.flex-col6.gap140 {
  gap: 8.75rem;
}
.flex-col6.gap141 > * {
  width: calc(16.6666666667% - 8.8125rem / 6 * 5);
}
.flex-col6.gap141 {
  gap: 8.8125rem;
}
.flex-col6.gap142 > * {
  width: calc(16.6666666667% - 8.875rem / 6 * 5);
}
.flex-col6.gap142 {
  gap: 8.875rem;
}
.flex-col6.gap143 > * {
  width: calc(16.6666666667% - 8.9375rem / 6 * 5);
}
.flex-col6.gap143 {
  gap: 8.9375rem;
}
.flex-col6.gap144 > * {
  width: calc(16.6666666667% - 9rem / 6 * 5);
}
.flex-col6.gap144 {
  gap: 9rem;
}
.flex-col6.gap145 > * {
  width: calc(16.6666666667% - 9.0625rem / 6 * 5);
}
.flex-col6.gap145 {
  gap: 9.0625rem;
}
.flex-col6.gap146 > * {
  width: calc(16.6666666667% - 9.125rem / 6 * 5);
}
.flex-col6.gap146 {
  gap: 9.125rem;
}
.flex-col6.gap147 > * {
  width: calc(16.6666666667% - 9.1875rem / 6 * 5);
}
.flex-col6.gap147 {
  gap: 9.1875rem;
}
.flex-col6.gap148 > * {
  width: calc(16.6666666667% - 9.25rem / 6 * 5);
}
.flex-col6.gap148 {
  gap: 9.25rem;
}
.flex-col6.gap149 > * {
  width: calc(16.6666666667% - 9.3125rem / 6 * 5);
}
.flex-col6.gap149 {
  gap: 9.3125rem;
}
.flex-col6.gap150 > * {
  width: calc(16.6666666667% - 9.375rem / 6 * 5);
}
.flex-col6.gap150 {
  gap: 9.375rem;
}

.flex-col7 {
  flex-wrap: wrap;
}
.flex-col7 > * {
  width: 14.2857142857%;
}
.flex-col7.gap1 > * {
  width: calc(14.2857142857% - 0.0625rem / 7 * 6);
}
.flex-col7.gap1 {
  gap: 0.0625rem;
}
.flex-col7.gap2 > * {
  width: calc(14.2857142857% - 0.125rem / 7 * 6);
}
.flex-col7.gap2 {
  gap: 0.125rem;
}
.flex-col7.gap3 > * {
  width: calc(14.2857142857% - 0.1875rem / 7 * 6);
}
.flex-col7.gap3 {
  gap: 0.1875rem;
}
.flex-col7.gap4 > * {
  width: calc(14.2857142857% - 0.25rem / 7 * 6);
}
.flex-col7.gap4 {
  gap: 0.25rem;
}
.flex-col7.gap5 > * {
  width: calc(14.2857142857% - 0.3125rem / 7 * 6);
}
.flex-col7.gap5 {
  gap: 0.3125rem;
}
.flex-col7.gap6 > * {
  width: calc(14.2857142857% - 0.375rem / 7 * 6);
}
.flex-col7.gap6 {
  gap: 0.375rem;
}
.flex-col7.gap7 > * {
  width: calc(14.2857142857% - 0.4375rem / 7 * 6);
}
.flex-col7.gap7 {
  gap: 0.4375rem;
}
.flex-col7.gap8 > * {
  width: calc(14.2857142857% - 0.5rem / 7 * 6);
}
.flex-col7.gap8 {
  gap: 0.5rem;
}
.flex-col7.gap9 > * {
  width: calc(14.2857142857% - 0.5625rem / 7 * 6);
}
.flex-col7.gap9 {
  gap: 0.5625rem;
}
.flex-col7.gap10 > * {
  width: calc(14.2857142857% - 0.625rem / 7 * 6);
}
.flex-col7.gap10 {
  gap: 0.625rem;
}
.flex-col7.gap11 > * {
  width: calc(14.2857142857% - 0.6875rem / 7 * 6);
}
.flex-col7.gap11 {
  gap: 0.6875rem;
}
.flex-col7.gap12 > * {
  width: calc(14.2857142857% - 0.75rem / 7 * 6);
}
.flex-col7.gap12 {
  gap: 0.75rem;
}
.flex-col7.gap13 > * {
  width: calc(14.2857142857% - 0.8125rem / 7 * 6);
}
.flex-col7.gap13 {
  gap: 0.8125rem;
}
.flex-col7.gap14 > * {
  width: calc(14.2857142857% - 0.875rem / 7 * 6);
}
.flex-col7.gap14 {
  gap: 0.875rem;
}
.flex-col7.gap15 > * {
  width: calc(14.2857142857% - 0.9375rem / 7 * 6);
}
.flex-col7.gap15 {
  gap: 0.9375rem;
}
.flex-col7.gap16 > * {
  width: calc(14.2857142857% - 1rem / 7 * 6);
}
.flex-col7.gap16 {
  gap: 1rem;
}
.flex-col7.gap17 > * {
  width: calc(14.2857142857% - 1.0625rem / 7 * 6);
}
.flex-col7.gap17 {
  gap: 1.0625rem;
}
.flex-col7.gap18 > * {
  width: calc(14.2857142857% - 1.125rem / 7 * 6);
}
.flex-col7.gap18 {
  gap: 1.125rem;
}
.flex-col7.gap19 > * {
  width: calc(14.2857142857% - 1.1875rem / 7 * 6);
}
.flex-col7.gap19 {
  gap: 1.1875rem;
}
.flex-col7.gap20 > * {
  width: calc(14.2857142857% - 1.25rem / 7 * 6);
}
.flex-col7.gap20 {
  gap: 1.25rem;
}
.flex-col7.gap21 > * {
  width: calc(14.2857142857% - 1.3125rem / 7 * 6);
}
.flex-col7.gap21 {
  gap: 1.3125rem;
}
.flex-col7.gap22 > * {
  width: calc(14.2857142857% - 1.375rem / 7 * 6);
}
.flex-col7.gap22 {
  gap: 1.375rem;
}
.flex-col7.gap23 > * {
  width: calc(14.2857142857% - 1.4375rem / 7 * 6);
}
.flex-col7.gap23 {
  gap: 1.4375rem;
}
.flex-col7.gap24 > * {
  width: calc(14.2857142857% - 1.5rem / 7 * 6);
}
.flex-col7.gap24 {
  gap: 1.5rem;
}
.flex-col7.gap25 > * {
  width: calc(14.2857142857% - 1.5625rem / 7 * 6);
}
.flex-col7.gap25 {
  gap: 1.5625rem;
}
.flex-col7.gap26 > * {
  width: calc(14.2857142857% - 1.625rem / 7 * 6);
}
.flex-col7.gap26 {
  gap: 1.625rem;
}
.flex-col7.gap27 > * {
  width: calc(14.2857142857% - 1.6875rem / 7 * 6);
}
.flex-col7.gap27 {
  gap: 1.6875rem;
}
.flex-col7.gap28 > * {
  width: calc(14.2857142857% - 1.75rem / 7 * 6);
}
.flex-col7.gap28 {
  gap: 1.75rem;
}
.flex-col7.gap29 > * {
  width: calc(14.2857142857% - 1.8125rem / 7 * 6);
}
.flex-col7.gap29 {
  gap: 1.8125rem;
}
.flex-col7.gap30 > * {
  width: calc(14.2857142857% - 1.875rem / 7 * 6);
}
.flex-col7.gap30 {
  gap: 1.875rem;
}
.flex-col7.gap31 > * {
  width: calc(14.2857142857% - 1.9375rem / 7 * 6);
}
.flex-col7.gap31 {
  gap: 1.9375rem;
}
.flex-col7.gap32 > * {
  width: calc(14.2857142857% - 2rem / 7 * 6);
}
.flex-col7.gap32 {
  gap: 2rem;
}
.flex-col7.gap33 > * {
  width: calc(14.2857142857% - 2.0625rem / 7 * 6);
}
.flex-col7.gap33 {
  gap: 2.0625rem;
}
.flex-col7.gap34 > * {
  width: calc(14.2857142857% - 2.125rem / 7 * 6);
}
.flex-col7.gap34 {
  gap: 2.125rem;
}
.flex-col7.gap35 > * {
  width: calc(14.2857142857% - 2.1875rem / 7 * 6);
}
.flex-col7.gap35 {
  gap: 2.1875rem;
}
.flex-col7.gap36 > * {
  width: calc(14.2857142857% - 2.25rem / 7 * 6);
}
.flex-col7.gap36 {
  gap: 2.25rem;
}
.flex-col7.gap37 > * {
  width: calc(14.2857142857% - 2.3125rem / 7 * 6);
}
.flex-col7.gap37 {
  gap: 2.3125rem;
}
.flex-col7.gap38 > * {
  width: calc(14.2857142857% - 2.375rem / 7 * 6);
}
.flex-col7.gap38 {
  gap: 2.375rem;
}
.flex-col7.gap39 > * {
  width: calc(14.2857142857% - 2.4375rem / 7 * 6);
}
.flex-col7.gap39 {
  gap: 2.4375rem;
}
.flex-col7.gap40 > * {
  width: calc(14.2857142857% - 2.5rem / 7 * 6);
}
.flex-col7.gap40 {
  gap: 2.5rem;
}
.flex-col7.gap41 > * {
  width: calc(14.2857142857% - 2.5625rem / 7 * 6);
}
.flex-col7.gap41 {
  gap: 2.5625rem;
}
.flex-col7.gap42 > * {
  width: calc(14.2857142857% - 2.625rem / 7 * 6);
}
.flex-col7.gap42 {
  gap: 2.625rem;
}
.flex-col7.gap43 > * {
  width: calc(14.2857142857% - 2.6875rem / 7 * 6);
}
.flex-col7.gap43 {
  gap: 2.6875rem;
}
.flex-col7.gap44 > * {
  width: calc(14.2857142857% - 2.75rem / 7 * 6);
}
.flex-col7.gap44 {
  gap: 2.75rem;
}
.flex-col7.gap45 > * {
  width: calc(14.2857142857% - 2.8125rem / 7 * 6);
}
.flex-col7.gap45 {
  gap: 2.8125rem;
}
.flex-col7.gap46 > * {
  width: calc(14.2857142857% - 2.875rem / 7 * 6);
}
.flex-col7.gap46 {
  gap: 2.875rem;
}
.flex-col7.gap47 > * {
  width: calc(14.2857142857% - 2.9375rem / 7 * 6);
}
.flex-col7.gap47 {
  gap: 2.9375rem;
}
.flex-col7.gap48 > * {
  width: calc(14.2857142857% - 3rem / 7 * 6);
}
.flex-col7.gap48 {
  gap: 3rem;
}
.flex-col7.gap49 > * {
  width: calc(14.2857142857% - 3.0625rem / 7 * 6);
}
.flex-col7.gap49 {
  gap: 3.0625rem;
}
.flex-col7.gap50 > * {
  width: calc(14.2857142857% - 3.125rem / 7 * 6);
}
.flex-col7.gap50 {
  gap: 3.125rem;
}
.flex-col7.gap51 > * {
  width: calc(14.2857142857% - 3.1875rem / 7 * 6);
}
.flex-col7.gap51 {
  gap: 3.1875rem;
}
.flex-col7.gap52 > * {
  width: calc(14.2857142857% - 3.25rem / 7 * 6);
}
.flex-col7.gap52 {
  gap: 3.25rem;
}
.flex-col7.gap53 > * {
  width: calc(14.2857142857% - 3.3125rem / 7 * 6);
}
.flex-col7.gap53 {
  gap: 3.3125rem;
}
.flex-col7.gap54 > * {
  width: calc(14.2857142857% - 3.375rem / 7 * 6);
}
.flex-col7.gap54 {
  gap: 3.375rem;
}
.flex-col7.gap55 > * {
  width: calc(14.2857142857% - 3.4375rem / 7 * 6);
}
.flex-col7.gap55 {
  gap: 3.4375rem;
}
.flex-col7.gap56 > * {
  width: calc(14.2857142857% - 3.5rem / 7 * 6);
}
.flex-col7.gap56 {
  gap: 3.5rem;
}
.flex-col7.gap57 > * {
  width: calc(14.2857142857% - 3.5625rem / 7 * 6);
}
.flex-col7.gap57 {
  gap: 3.5625rem;
}
.flex-col7.gap58 > * {
  width: calc(14.2857142857% - 3.625rem / 7 * 6);
}
.flex-col7.gap58 {
  gap: 3.625rem;
}
.flex-col7.gap59 > * {
  width: calc(14.2857142857% - 3.6875rem / 7 * 6);
}
.flex-col7.gap59 {
  gap: 3.6875rem;
}
.flex-col7.gap60 > * {
  width: calc(14.2857142857% - 3.75rem / 7 * 6);
}
.flex-col7.gap60 {
  gap: 3.75rem;
}
.flex-col7.gap61 > * {
  width: calc(14.2857142857% - 3.8125rem / 7 * 6);
}
.flex-col7.gap61 {
  gap: 3.8125rem;
}
.flex-col7.gap62 > * {
  width: calc(14.2857142857% - 3.875rem / 7 * 6);
}
.flex-col7.gap62 {
  gap: 3.875rem;
}
.flex-col7.gap63 > * {
  width: calc(14.2857142857% - 3.9375rem / 7 * 6);
}
.flex-col7.gap63 {
  gap: 3.9375rem;
}
.flex-col7.gap64 > * {
  width: calc(14.2857142857% - 4rem / 7 * 6);
}
.flex-col7.gap64 {
  gap: 4rem;
}
.flex-col7.gap65 > * {
  width: calc(14.2857142857% - 4.0625rem / 7 * 6);
}
.flex-col7.gap65 {
  gap: 4.0625rem;
}
.flex-col7.gap66 > * {
  width: calc(14.2857142857% - 4.125rem / 7 * 6);
}
.flex-col7.gap66 {
  gap: 4.125rem;
}
.flex-col7.gap67 > * {
  width: calc(14.2857142857% - 4.1875rem / 7 * 6);
}
.flex-col7.gap67 {
  gap: 4.1875rem;
}
.flex-col7.gap68 > * {
  width: calc(14.2857142857% - 4.25rem / 7 * 6);
}
.flex-col7.gap68 {
  gap: 4.25rem;
}
.flex-col7.gap69 > * {
  width: calc(14.2857142857% - 4.3125rem / 7 * 6);
}
.flex-col7.gap69 {
  gap: 4.3125rem;
}
.flex-col7.gap70 > * {
  width: calc(14.2857142857% - 4.375rem / 7 * 6);
}
.flex-col7.gap70 {
  gap: 4.375rem;
}
.flex-col7.gap71 > * {
  width: calc(14.2857142857% - 4.4375rem / 7 * 6);
}
.flex-col7.gap71 {
  gap: 4.4375rem;
}
.flex-col7.gap72 > * {
  width: calc(14.2857142857% - 4.5rem / 7 * 6);
}
.flex-col7.gap72 {
  gap: 4.5rem;
}
.flex-col7.gap73 > * {
  width: calc(14.2857142857% - 4.5625rem / 7 * 6);
}
.flex-col7.gap73 {
  gap: 4.5625rem;
}
.flex-col7.gap74 > * {
  width: calc(14.2857142857% - 4.625rem / 7 * 6);
}
.flex-col7.gap74 {
  gap: 4.625rem;
}
.flex-col7.gap75 > * {
  width: calc(14.2857142857% - 4.6875rem / 7 * 6);
}
.flex-col7.gap75 {
  gap: 4.6875rem;
}
.flex-col7.gap76 > * {
  width: calc(14.2857142857% - 4.75rem / 7 * 6);
}
.flex-col7.gap76 {
  gap: 4.75rem;
}
.flex-col7.gap77 > * {
  width: calc(14.2857142857% - 4.8125rem / 7 * 6);
}
.flex-col7.gap77 {
  gap: 4.8125rem;
}
.flex-col7.gap78 > * {
  width: calc(14.2857142857% - 4.875rem / 7 * 6);
}
.flex-col7.gap78 {
  gap: 4.875rem;
}
.flex-col7.gap79 > * {
  width: calc(14.2857142857% - 4.9375rem / 7 * 6);
}
.flex-col7.gap79 {
  gap: 4.9375rem;
}
.flex-col7.gap80 > * {
  width: calc(14.2857142857% - 5rem / 7 * 6);
}
.flex-col7.gap80 {
  gap: 5rem;
}
.flex-col7.gap81 > * {
  width: calc(14.2857142857% - 5.0625rem / 7 * 6);
}
.flex-col7.gap81 {
  gap: 5.0625rem;
}
.flex-col7.gap82 > * {
  width: calc(14.2857142857% - 5.125rem / 7 * 6);
}
.flex-col7.gap82 {
  gap: 5.125rem;
}
.flex-col7.gap83 > * {
  width: calc(14.2857142857% - 5.1875rem / 7 * 6);
}
.flex-col7.gap83 {
  gap: 5.1875rem;
}
.flex-col7.gap84 > * {
  width: calc(14.2857142857% - 5.25rem / 7 * 6);
}
.flex-col7.gap84 {
  gap: 5.25rem;
}
.flex-col7.gap85 > * {
  width: calc(14.2857142857% - 5.3125rem / 7 * 6);
}
.flex-col7.gap85 {
  gap: 5.3125rem;
}
.flex-col7.gap86 > * {
  width: calc(14.2857142857% - 5.375rem / 7 * 6);
}
.flex-col7.gap86 {
  gap: 5.375rem;
}
.flex-col7.gap87 > * {
  width: calc(14.2857142857% - 5.4375rem / 7 * 6);
}
.flex-col7.gap87 {
  gap: 5.4375rem;
}
.flex-col7.gap88 > * {
  width: calc(14.2857142857% - 5.5rem / 7 * 6);
}
.flex-col7.gap88 {
  gap: 5.5rem;
}
.flex-col7.gap89 > * {
  width: calc(14.2857142857% - 5.5625rem / 7 * 6);
}
.flex-col7.gap89 {
  gap: 5.5625rem;
}
.flex-col7.gap90 > * {
  width: calc(14.2857142857% - 5.625rem / 7 * 6);
}
.flex-col7.gap90 {
  gap: 5.625rem;
}
.flex-col7.gap91 > * {
  width: calc(14.2857142857% - 5.6875rem / 7 * 6);
}
.flex-col7.gap91 {
  gap: 5.6875rem;
}
.flex-col7.gap92 > * {
  width: calc(14.2857142857% - 5.75rem / 7 * 6);
}
.flex-col7.gap92 {
  gap: 5.75rem;
}
.flex-col7.gap93 > * {
  width: calc(14.2857142857% - 5.8125rem / 7 * 6);
}
.flex-col7.gap93 {
  gap: 5.8125rem;
}
.flex-col7.gap94 > * {
  width: calc(14.2857142857% - 5.875rem / 7 * 6);
}
.flex-col7.gap94 {
  gap: 5.875rem;
}
.flex-col7.gap95 > * {
  width: calc(14.2857142857% - 5.9375rem / 7 * 6);
}
.flex-col7.gap95 {
  gap: 5.9375rem;
}
.flex-col7.gap96 > * {
  width: calc(14.2857142857% - 6rem / 7 * 6);
}
.flex-col7.gap96 {
  gap: 6rem;
}
.flex-col7.gap97 > * {
  width: calc(14.2857142857% - 6.0625rem / 7 * 6);
}
.flex-col7.gap97 {
  gap: 6.0625rem;
}
.flex-col7.gap98 > * {
  width: calc(14.2857142857% - 6.125rem / 7 * 6);
}
.flex-col7.gap98 {
  gap: 6.125rem;
}
.flex-col7.gap99 > * {
  width: calc(14.2857142857% - 6.1875rem / 7 * 6);
}
.flex-col7.gap99 {
  gap: 6.1875rem;
}
.flex-col7.gap100 > * {
  width: calc(14.2857142857% - 6.25rem / 7 * 6);
}
.flex-col7.gap100 {
  gap: 6.25rem;
}
.flex-col7.gap101 > * {
  width: calc(14.2857142857% - 6.3125rem / 7 * 6);
}
.flex-col7.gap101 {
  gap: 6.3125rem;
}
.flex-col7.gap102 > * {
  width: calc(14.2857142857% - 6.375rem / 7 * 6);
}
.flex-col7.gap102 {
  gap: 6.375rem;
}
.flex-col7.gap103 > * {
  width: calc(14.2857142857% - 6.4375rem / 7 * 6);
}
.flex-col7.gap103 {
  gap: 6.4375rem;
}
.flex-col7.gap104 > * {
  width: calc(14.2857142857% - 6.5rem / 7 * 6);
}
.flex-col7.gap104 {
  gap: 6.5rem;
}
.flex-col7.gap105 > * {
  width: calc(14.2857142857% - 6.5625rem / 7 * 6);
}
.flex-col7.gap105 {
  gap: 6.5625rem;
}
.flex-col7.gap106 > * {
  width: calc(14.2857142857% - 6.625rem / 7 * 6);
}
.flex-col7.gap106 {
  gap: 6.625rem;
}
.flex-col7.gap107 > * {
  width: calc(14.2857142857% - 6.6875rem / 7 * 6);
}
.flex-col7.gap107 {
  gap: 6.6875rem;
}
.flex-col7.gap108 > * {
  width: calc(14.2857142857% - 6.75rem / 7 * 6);
}
.flex-col7.gap108 {
  gap: 6.75rem;
}
.flex-col7.gap109 > * {
  width: calc(14.2857142857% - 6.8125rem / 7 * 6);
}
.flex-col7.gap109 {
  gap: 6.8125rem;
}
.flex-col7.gap110 > * {
  width: calc(14.2857142857% - 6.875rem / 7 * 6);
}
.flex-col7.gap110 {
  gap: 6.875rem;
}
.flex-col7.gap111 > * {
  width: calc(14.2857142857% - 6.9375rem / 7 * 6);
}
.flex-col7.gap111 {
  gap: 6.9375rem;
}
.flex-col7.gap112 > * {
  width: calc(14.2857142857% - 7rem / 7 * 6);
}
.flex-col7.gap112 {
  gap: 7rem;
}
.flex-col7.gap113 > * {
  width: calc(14.2857142857% - 7.0625rem / 7 * 6);
}
.flex-col7.gap113 {
  gap: 7.0625rem;
}
.flex-col7.gap114 > * {
  width: calc(14.2857142857% - 7.125rem / 7 * 6);
}
.flex-col7.gap114 {
  gap: 7.125rem;
}
.flex-col7.gap115 > * {
  width: calc(14.2857142857% - 7.1875rem / 7 * 6);
}
.flex-col7.gap115 {
  gap: 7.1875rem;
}
.flex-col7.gap116 > * {
  width: calc(14.2857142857% - 7.25rem / 7 * 6);
}
.flex-col7.gap116 {
  gap: 7.25rem;
}
.flex-col7.gap117 > * {
  width: calc(14.2857142857% - 7.3125rem / 7 * 6);
}
.flex-col7.gap117 {
  gap: 7.3125rem;
}
.flex-col7.gap118 > * {
  width: calc(14.2857142857% - 7.375rem / 7 * 6);
}
.flex-col7.gap118 {
  gap: 7.375rem;
}
.flex-col7.gap119 > * {
  width: calc(14.2857142857% - 7.4375rem / 7 * 6);
}
.flex-col7.gap119 {
  gap: 7.4375rem;
}
.flex-col7.gap120 > * {
  width: calc(14.2857142857% - 7.5rem / 7 * 6);
}
.flex-col7.gap120 {
  gap: 7.5rem;
}
.flex-col7.gap121 > * {
  width: calc(14.2857142857% - 7.5625rem / 7 * 6);
}
.flex-col7.gap121 {
  gap: 7.5625rem;
}
.flex-col7.gap122 > * {
  width: calc(14.2857142857% - 7.625rem / 7 * 6);
}
.flex-col7.gap122 {
  gap: 7.625rem;
}
.flex-col7.gap123 > * {
  width: calc(14.2857142857% - 7.6875rem / 7 * 6);
}
.flex-col7.gap123 {
  gap: 7.6875rem;
}
.flex-col7.gap124 > * {
  width: calc(14.2857142857% - 7.75rem / 7 * 6);
}
.flex-col7.gap124 {
  gap: 7.75rem;
}
.flex-col7.gap125 > * {
  width: calc(14.2857142857% - 7.8125rem / 7 * 6);
}
.flex-col7.gap125 {
  gap: 7.8125rem;
}
.flex-col7.gap126 > * {
  width: calc(14.2857142857% - 7.875rem / 7 * 6);
}
.flex-col7.gap126 {
  gap: 7.875rem;
}
.flex-col7.gap127 > * {
  width: calc(14.2857142857% - 7.9375rem / 7 * 6);
}
.flex-col7.gap127 {
  gap: 7.9375rem;
}
.flex-col7.gap128 > * {
  width: calc(14.2857142857% - 8rem / 7 * 6);
}
.flex-col7.gap128 {
  gap: 8rem;
}
.flex-col7.gap129 > * {
  width: calc(14.2857142857% - 8.0625rem / 7 * 6);
}
.flex-col7.gap129 {
  gap: 8.0625rem;
}
.flex-col7.gap130 > * {
  width: calc(14.2857142857% - 8.125rem / 7 * 6);
}
.flex-col7.gap130 {
  gap: 8.125rem;
}
.flex-col7.gap131 > * {
  width: calc(14.2857142857% - 8.1875rem / 7 * 6);
}
.flex-col7.gap131 {
  gap: 8.1875rem;
}
.flex-col7.gap132 > * {
  width: calc(14.2857142857% - 8.25rem / 7 * 6);
}
.flex-col7.gap132 {
  gap: 8.25rem;
}
.flex-col7.gap133 > * {
  width: calc(14.2857142857% - 8.3125rem / 7 * 6);
}
.flex-col7.gap133 {
  gap: 8.3125rem;
}
.flex-col7.gap134 > * {
  width: calc(14.2857142857% - 8.375rem / 7 * 6);
}
.flex-col7.gap134 {
  gap: 8.375rem;
}
.flex-col7.gap135 > * {
  width: calc(14.2857142857% - 8.4375rem / 7 * 6);
}
.flex-col7.gap135 {
  gap: 8.4375rem;
}
.flex-col7.gap136 > * {
  width: calc(14.2857142857% - 8.5rem / 7 * 6);
}
.flex-col7.gap136 {
  gap: 8.5rem;
}
.flex-col7.gap137 > * {
  width: calc(14.2857142857% - 8.5625rem / 7 * 6);
}
.flex-col7.gap137 {
  gap: 8.5625rem;
}
.flex-col7.gap138 > * {
  width: calc(14.2857142857% - 8.625rem / 7 * 6);
}
.flex-col7.gap138 {
  gap: 8.625rem;
}
.flex-col7.gap139 > * {
  width: calc(14.2857142857% - 8.6875rem / 7 * 6);
}
.flex-col7.gap139 {
  gap: 8.6875rem;
}
.flex-col7.gap140 > * {
  width: calc(14.2857142857% - 8.75rem / 7 * 6);
}
.flex-col7.gap140 {
  gap: 8.75rem;
}
.flex-col7.gap141 > * {
  width: calc(14.2857142857% - 8.8125rem / 7 * 6);
}
.flex-col7.gap141 {
  gap: 8.8125rem;
}
.flex-col7.gap142 > * {
  width: calc(14.2857142857% - 8.875rem / 7 * 6);
}
.flex-col7.gap142 {
  gap: 8.875rem;
}
.flex-col7.gap143 > * {
  width: calc(14.2857142857% - 8.9375rem / 7 * 6);
}
.flex-col7.gap143 {
  gap: 8.9375rem;
}
.flex-col7.gap144 > * {
  width: calc(14.2857142857% - 9rem / 7 * 6);
}
.flex-col7.gap144 {
  gap: 9rem;
}
.flex-col7.gap145 > * {
  width: calc(14.2857142857% - 9.0625rem / 7 * 6);
}
.flex-col7.gap145 {
  gap: 9.0625rem;
}
.flex-col7.gap146 > * {
  width: calc(14.2857142857% - 9.125rem / 7 * 6);
}
.flex-col7.gap146 {
  gap: 9.125rem;
}
.flex-col7.gap147 > * {
  width: calc(14.2857142857% - 9.1875rem / 7 * 6);
}
.flex-col7.gap147 {
  gap: 9.1875rem;
}
.flex-col7.gap148 > * {
  width: calc(14.2857142857% - 9.25rem / 7 * 6);
}
.flex-col7.gap148 {
  gap: 9.25rem;
}
.flex-col7.gap149 > * {
  width: calc(14.2857142857% - 9.3125rem / 7 * 6);
}
.flex-col7.gap149 {
  gap: 9.3125rem;
}
.flex-col7.gap150 > * {
  width: calc(14.2857142857% - 9.375rem / 7 * 6);
}
.flex-col7.gap150 {
  gap: 9.375rem;
}

.flex-col8 {
  flex-wrap: wrap;
}
.flex-col8 > * {
  width: 12.5%;
}
.flex-col8.gap1 > * {
  width: calc(12.5% - 0.0625rem / 8 * 7);
}
.flex-col8.gap1 {
  gap: 0.0625rem;
}
.flex-col8.gap2 > * {
  width: calc(12.5% - 0.125rem / 8 * 7);
}
.flex-col8.gap2 {
  gap: 0.125rem;
}
.flex-col8.gap3 > * {
  width: calc(12.5% - 0.1875rem / 8 * 7);
}
.flex-col8.gap3 {
  gap: 0.1875rem;
}
.flex-col8.gap4 > * {
  width: calc(12.5% - 0.25rem / 8 * 7);
}
.flex-col8.gap4 {
  gap: 0.25rem;
}
.flex-col8.gap5 > * {
  width: calc(12.5% - 0.3125rem / 8 * 7);
}
.flex-col8.gap5 {
  gap: 0.3125rem;
}
.flex-col8.gap6 > * {
  width: calc(12.5% - 0.375rem / 8 * 7);
}
.flex-col8.gap6 {
  gap: 0.375rem;
}
.flex-col8.gap7 > * {
  width: calc(12.5% - 0.4375rem / 8 * 7);
}
.flex-col8.gap7 {
  gap: 0.4375rem;
}
.flex-col8.gap8 > * {
  width: calc(12.5% - 0.5rem / 8 * 7);
}
.flex-col8.gap8 {
  gap: 0.5rem;
}
.flex-col8.gap9 > * {
  width: calc(12.5% - 0.5625rem / 8 * 7);
}
.flex-col8.gap9 {
  gap: 0.5625rem;
}
.flex-col8.gap10 > * {
  width: calc(12.5% - 0.625rem / 8 * 7);
}
.flex-col8.gap10 {
  gap: 0.625rem;
}
.flex-col8.gap11 > * {
  width: calc(12.5% - 0.6875rem / 8 * 7);
}
.flex-col8.gap11 {
  gap: 0.6875rem;
}
.flex-col8.gap12 > * {
  width: calc(12.5% - 0.75rem / 8 * 7);
}
.flex-col8.gap12 {
  gap: 0.75rem;
}
.flex-col8.gap13 > * {
  width: calc(12.5% - 0.8125rem / 8 * 7);
}
.flex-col8.gap13 {
  gap: 0.8125rem;
}
.flex-col8.gap14 > * {
  width: calc(12.5% - 0.875rem / 8 * 7);
}
.flex-col8.gap14 {
  gap: 0.875rem;
}
.flex-col8.gap15 > * {
  width: calc(12.5% - 0.9375rem / 8 * 7);
}
.flex-col8.gap15 {
  gap: 0.9375rem;
}
.flex-col8.gap16 > * {
  width: calc(12.5% - 1rem / 8 * 7);
}
.flex-col8.gap16 {
  gap: 1rem;
}
.flex-col8.gap17 > * {
  width: calc(12.5% - 1.0625rem / 8 * 7);
}
.flex-col8.gap17 {
  gap: 1.0625rem;
}
.flex-col8.gap18 > * {
  width: calc(12.5% - 1.125rem / 8 * 7);
}
.flex-col8.gap18 {
  gap: 1.125rem;
}
.flex-col8.gap19 > * {
  width: calc(12.5% - 1.1875rem / 8 * 7);
}
.flex-col8.gap19 {
  gap: 1.1875rem;
}
.flex-col8.gap20 > * {
  width: calc(12.5% - 1.25rem / 8 * 7);
}
.flex-col8.gap20 {
  gap: 1.25rem;
}
.flex-col8.gap21 > * {
  width: calc(12.5% - 1.3125rem / 8 * 7);
}
.flex-col8.gap21 {
  gap: 1.3125rem;
}
.flex-col8.gap22 > * {
  width: calc(12.5% - 1.375rem / 8 * 7);
}
.flex-col8.gap22 {
  gap: 1.375rem;
}
.flex-col8.gap23 > * {
  width: calc(12.5% - 1.4375rem / 8 * 7);
}
.flex-col8.gap23 {
  gap: 1.4375rem;
}
.flex-col8.gap24 > * {
  width: calc(12.5% - 1.5rem / 8 * 7);
}
.flex-col8.gap24 {
  gap: 1.5rem;
}
.flex-col8.gap25 > * {
  width: calc(12.5% - 1.5625rem / 8 * 7);
}
.flex-col8.gap25 {
  gap: 1.5625rem;
}
.flex-col8.gap26 > * {
  width: calc(12.5% - 1.625rem / 8 * 7);
}
.flex-col8.gap26 {
  gap: 1.625rem;
}
.flex-col8.gap27 > * {
  width: calc(12.5% - 1.6875rem / 8 * 7);
}
.flex-col8.gap27 {
  gap: 1.6875rem;
}
.flex-col8.gap28 > * {
  width: calc(12.5% - 1.75rem / 8 * 7);
}
.flex-col8.gap28 {
  gap: 1.75rem;
}
.flex-col8.gap29 > * {
  width: calc(12.5% - 1.8125rem / 8 * 7);
}
.flex-col8.gap29 {
  gap: 1.8125rem;
}
.flex-col8.gap30 > * {
  width: calc(12.5% - 1.875rem / 8 * 7);
}
.flex-col8.gap30 {
  gap: 1.875rem;
}
.flex-col8.gap31 > * {
  width: calc(12.5% - 1.9375rem / 8 * 7);
}
.flex-col8.gap31 {
  gap: 1.9375rem;
}
.flex-col8.gap32 > * {
  width: calc(12.5% - 2rem / 8 * 7);
}
.flex-col8.gap32 {
  gap: 2rem;
}
.flex-col8.gap33 > * {
  width: calc(12.5% - 2.0625rem / 8 * 7);
}
.flex-col8.gap33 {
  gap: 2.0625rem;
}
.flex-col8.gap34 > * {
  width: calc(12.5% - 2.125rem / 8 * 7);
}
.flex-col8.gap34 {
  gap: 2.125rem;
}
.flex-col8.gap35 > * {
  width: calc(12.5% - 2.1875rem / 8 * 7);
}
.flex-col8.gap35 {
  gap: 2.1875rem;
}
.flex-col8.gap36 > * {
  width: calc(12.5% - 2.25rem / 8 * 7);
}
.flex-col8.gap36 {
  gap: 2.25rem;
}
.flex-col8.gap37 > * {
  width: calc(12.5% - 2.3125rem / 8 * 7);
}
.flex-col8.gap37 {
  gap: 2.3125rem;
}
.flex-col8.gap38 > * {
  width: calc(12.5% - 2.375rem / 8 * 7);
}
.flex-col8.gap38 {
  gap: 2.375rem;
}
.flex-col8.gap39 > * {
  width: calc(12.5% - 2.4375rem / 8 * 7);
}
.flex-col8.gap39 {
  gap: 2.4375rem;
}
.flex-col8.gap40 > * {
  width: calc(12.5% - 2.5rem / 8 * 7);
}
.flex-col8.gap40 {
  gap: 2.5rem;
}
.flex-col8.gap41 > * {
  width: calc(12.5% - 2.5625rem / 8 * 7);
}
.flex-col8.gap41 {
  gap: 2.5625rem;
}
.flex-col8.gap42 > * {
  width: calc(12.5% - 2.625rem / 8 * 7);
}
.flex-col8.gap42 {
  gap: 2.625rem;
}
.flex-col8.gap43 > * {
  width: calc(12.5% - 2.6875rem / 8 * 7);
}
.flex-col8.gap43 {
  gap: 2.6875rem;
}
.flex-col8.gap44 > * {
  width: calc(12.5% - 2.75rem / 8 * 7);
}
.flex-col8.gap44 {
  gap: 2.75rem;
}
.flex-col8.gap45 > * {
  width: calc(12.5% - 2.8125rem / 8 * 7);
}
.flex-col8.gap45 {
  gap: 2.8125rem;
}
.flex-col8.gap46 > * {
  width: calc(12.5% - 2.875rem / 8 * 7);
}
.flex-col8.gap46 {
  gap: 2.875rem;
}
.flex-col8.gap47 > * {
  width: calc(12.5% - 2.9375rem / 8 * 7);
}
.flex-col8.gap47 {
  gap: 2.9375rem;
}
.flex-col8.gap48 > * {
  width: calc(12.5% - 3rem / 8 * 7);
}
.flex-col8.gap48 {
  gap: 3rem;
}
.flex-col8.gap49 > * {
  width: calc(12.5% - 3.0625rem / 8 * 7);
}
.flex-col8.gap49 {
  gap: 3.0625rem;
}
.flex-col8.gap50 > * {
  width: calc(12.5% - 3.125rem / 8 * 7);
}
.flex-col8.gap50 {
  gap: 3.125rem;
}
.flex-col8.gap51 > * {
  width: calc(12.5% - 3.1875rem / 8 * 7);
}
.flex-col8.gap51 {
  gap: 3.1875rem;
}
.flex-col8.gap52 > * {
  width: calc(12.5% - 3.25rem / 8 * 7);
}
.flex-col8.gap52 {
  gap: 3.25rem;
}
.flex-col8.gap53 > * {
  width: calc(12.5% - 3.3125rem / 8 * 7);
}
.flex-col8.gap53 {
  gap: 3.3125rem;
}
.flex-col8.gap54 > * {
  width: calc(12.5% - 3.375rem / 8 * 7);
}
.flex-col8.gap54 {
  gap: 3.375rem;
}
.flex-col8.gap55 > * {
  width: calc(12.5% - 3.4375rem / 8 * 7);
}
.flex-col8.gap55 {
  gap: 3.4375rem;
}
.flex-col8.gap56 > * {
  width: calc(12.5% - 3.5rem / 8 * 7);
}
.flex-col8.gap56 {
  gap: 3.5rem;
}
.flex-col8.gap57 > * {
  width: calc(12.5% - 3.5625rem / 8 * 7);
}
.flex-col8.gap57 {
  gap: 3.5625rem;
}
.flex-col8.gap58 > * {
  width: calc(12.5% - 3.625rem / 8 * 7);
}
.flex-col8.gap58 {
  gap: 3.625rem;
}
.flex-col8.gap59 > * {
  width: calc(12.5% - 3.6875rem / 8 * 7);
}
.flex-col8.gap59 {
  gap: 3.6875rem;
}
.flex-col8.gap60 > * {
  width: calc(12.5% - 3.75rem / 8 * 7);
}
.flex-col8.gap60 {
  gap: 3.75rem;
}
.flex-col8.gap61 > * {
  width: calc(12.5% - 3.8125rem / 8 * 7);
}
.flex-col8.gap61 {
  gap: 3.8125rem;
}
.flex-col8.gap62 > * {
  width: calc(12.5% - 3.875rem / 8 * 7);
}
.flex-col8.gap62 {
  gap: 3.875rem;
}
.flex-col8.gap63 > * {
  width: calc(12.5% - 3.9375rem / 8 * 7);
}
.flex-col8.gap63 {
  gap: 3.9375rem;
}
.flex-col8.gap64 > * {
  width: calc(12.5% - 4rem / 8 * 7);
}
.flex-col8.gap64 {
  gap: 4rem;
}
.flex-col8.gap65 > * {
  width: calc(12.5% - 4.0625rem / 8 * 7);
}
.flex-col8.gap65 {
  gap: 4.0625rem;
}
.flex-col8.gap66 > * {
  width: calc(12.5% - 4.125rem / 8 * 7);
}
.flex-col8.gap66 {
  gap: 4.125rem;
}
.flex-col8.gap67 > * {
  width: calc(12.5% - 4.1875rem / 8 * 7);
}
.flex-col8.gap67 {
  gap: 4.1875rem;
}
.flex-col8.gap68 > * {
  width: calc(12.5% - 4.25rem / 8 * 7);
}
.flex-col8.gap68 {
  gap: 4.25rem;
}
.flex-col8.gap69 > * {
  width: calc(12.5% - 4.3125rem / 8 * 7);
}
.flex-col8.gap69 {
  gap: 4.3125rem;
}
.flex-col8.gap70 > * {
  width: calc(12.5% - 4.375rem / 8 * 7);
}
.flex-col8.gap70 {
  gap: 4.375rem;
}
.flex-col8.gap71 > * {
  width: calc(12.5% - 4.4375rem / 8 * 7);
}
.flex-col8.gap71 {
  gap: 4.4375rem;
}
.flex-col8.gap72 > * {
  width: calc(12.5% - 4.5rem / 8 * 7);
}
.flex-col8.gap72 {
  gap: 4.5rem;
}
.flex-col8.gap73 > * {
  width: calc(12.5% - 4.5625rem / 8 * 7);
}
.flex-col8.gap73 {
  gap: 4.5625rem;
}
.flex-col8.gap74 > * {
  width: calc(12.5% - 4.625rem / 8 * 7);
}
.flex-col8.gap74 {
  gap: 4.625rem;
}
.flex-col8.gap75 > * {
  width: calc(12.5% - 4.6875rem / 8 * 7);
}
.flex-col8.gap75 {
  gap: 4.6875rem;
}
.flex-col8.gap76 > * {
  width: calc(12.5% - 4.75rem / 8 * 7);
}
.flex-col8.gap76 {
  gap: 4.75rem;
}
.flex-col8.gap77 > * {
  width: calc(12.5% - 4.8125rem / 8 * 7);
}
.flex-col8.gap77 {
  gap: 4.8125rem;
}
.flex-col8.gap78 > * {
  width: calc(12.5% - 4.875rem / 8 * 7);
}
.flex-col8.gap78 {
  gap: 4.875rem;
}
.flex-col8.gap79 > * {
  width: calc(12.5% - 4.9375rem / 8 * 7);
}
.flex-col8.gap79 {
  gap: 4.9375rem;
}
.flex-col8.gap80 > * {
  width: calc(12.5% - 5rem / 8 * 7);
}
.flex-col8.gap80 {
  gap: 5rem;
}
.flex-col8.gap81 > * {
  width: calc(12.5% - 5.0625rem / 8 * 7);
}
.flex-col8.gap81 {
  gap: 5.0625rem;
}
.flex-col8.gap82 > * {
  width: calc(12.5% - 5.125rem / 8 * 7);
}
.flex-col8.gap82 {
  gap: 5.125rem;
}
.flex-col8.gap83 > * {
  width: calc(12.5% - 5.1875rem / 8 * 7);
}
.flex-col8.gap83 {
  gap: 5.1875rem;
}
.flex-col8.gap84 > * {
  width: calc(12.5% - 5.25rem / 8 * 7);
}
.flex-col8.gap84 {
  gap: 5.25rem;
}
.flex-col8.gap85 > * {
  width: calc(12.5% - 5.3125rem / 8 * 7);
}
.flex-col8.gap85 {
  gap: 5.3125rem;
}
.flex-col8.gap86 > * {
  width: calc(12.5% - 5.375rem / 8 * 7);
}
.flex-col8.gap86 {
  gap: 5.375rem;
}
.flex-col8.gap87 > * {
  width: calc(12.5% - 5.4375rem / 8 * 7);
}
.flex-col8.gap87 {
  gap: 5.4375rem;
}
.flex-col8.gap88 > * {
  width: calc(12.5% - 5.5rem / 8 * 7);
}
.flex-col8.gap88 {
  gap: 5.5rem;
}
.flex-col8.gap89 > * {
  width: calc(12.5% - 5.5625rem / 8 * 7);
}
.flex-col8.gap89 {
  gap: 5.5625rem;
}
.flex-col8.gap90 > * {
  width: calc(12.5% - 5.625rem / 8 * 7);
}
.flex-col8.gap90 {
  gap: 5.625rem;
}
.flex-col8.gap91 > * {
  width: calc(12.5% - 5.6875rem / 8 * 7);
}
.flex-col8.gap91 {
  gap: 5.6875rem;
}
.flex-col8.gap92 > * {
  width: calc(12.5% - 5.75rem / 8 * 7);
}
.flex-col8.gap92 {
  gap: 5.75rem;
}
.flex-col8.gap93 > * {
  width: calc(12.5% - 5.8125rem / 8 * 7);
}
.flex-col8.gap93 {
  gap: 5.8125rem;
}
.flex-col8.gap94 > * {
  width: calc(12.5% - 5.875rem / 8 * 7);
}
.flex-col8.gap94 {
  gap: 5.875rem;
}
.flex-col8.gap95 > * {
  width: calc(12.5% - 5.9375rem / 8 * 7);
}
.flex-col8.gap95 {
  gap: 5.9375rem;
}
.flex-col8.gap96 > * {
  width: calc(12.5% - 6rem / 8 * 7);
}
.flex-col8.gap96 {
  gap: 6rem;
}
.flex-col8.gap97 > * {
  width: calc(12.5% - 6.0625rem / 8 * 7);
}
.flex-col8.gap97 {
  gap: 6.0625rem;
}
.flex-col8.gap98 > * {
  width: calc(12.5% - 6.125rem / 8 * 7);
}
.flex-col8.gap98 {
  gap: 6.125rem;
}
.flex-col8.gap99 > * {
  width: calc(12.5% - 6.1875rem / 8 * 7);
}
.flex-col8.gap99 {
  gap: 6.1875rem;
}
.flex-col8.gap100 > * {
  width: calc(12.5% - 6.25rem / 8 * 7);
}
.flex-col8.gap100 {
  gap: 6.25rem;
}
.flex-col8.gap101 > * {
  width: calc(12.5% - 6.3125rem / 8 * 7);
}
.flex-col8.gap101 {
  gap: 6.3125rem;
}
.flex-col8.gap102 > * {
  width: calc(12.5% - 6.375rem / 8 * 7);
}
.flex-col8.gap102 {
  gap: 6.375rem;
}
.flex-col8.gap103 > * {
  width: calc(12.5% - 6.4375rem / 8 * 7);
}
.flex-col8.gap103 {
  gap: 6.4375rem;
}
.flex-col8.gap104 > * {
  width: calc(12.5% - 6.5rem / 8 * 7);
}
.flex-col8.gap104 {
  gap: 6.5rem;
}
.flex-col8.gap105 > * {
  width: calc(12.5% - 6.5625rem / 8 * 7);
}
.flex-col8.gap105 {
  gap: 6.5625rem;
}
.flex-col8.gap106 > * {
  width: calc(12.5% - 6.625rem / 8 * 7);
}
.flex-col8.gap106 {
  gap: 6.625rem;
}
.flex-col8.gap107 > * {
  width: calc(12.5% - 6.6875rem / 8 * 7);
}
.flex-col8.gap107 {
  gap: 6.6875rem;
}
.flex-col8.gap108 > * {
  width: calc(12.5% - 6.75rem / 8 * 7);
}
.flex-col8.gap108 {
  gap: 6.75rem;
}
.flex-col8.gap109 > * {
  width: calc(12.5% - 6.8125rem / 8 * 7);
}
.flex-col8.gap109 {
  gap: 6.8125rem;
}
.flex-col8.gap110 > * {
  width: calc(12.5% - 6.875rem / 8 * 7);
}
.flex-col8.gap110 {
  gap: 6.875rem;
}
.flex-col8.gap111 > * {
  width: calc(12.5% - 6.9375rem / 8 * 7);
}
.flex-col8.gap111 {
  gap: 6.9375rem;
}
.flex-col8.gap112 > * {
  width: calc(12.5% - 7rem / 8 * 7);
}
.flex-col8.gap112 {
  gap: 7rem;
}
.flex-col8.gap113 > * {
  width: calc(12.5% - 7.0625rem / 8 * 7);
}
.flex-col8.gap113 {
  gap: 7.0625rem;
}
.flex-col8.gap114 > * {
  width: calc(12.5% - 7.125rem / 8 * 7);
}
.flex-col8.gap114 {
  gap: 7.125rem;
}
.flex-col8.gap115 > * {
  width: calc(12.5% - 7.1875rem / 8 * 7);
}
.flex-col8.gap115 {
  gap: 7.1875rem;
}
.flex-col8.gap116 > * {
  width: calc(12.5% - 7.25rem / 8 * 7);
}
.flex-col8.gap116 {
  gap: 7.25rem;
}
.flex-col8.gap117 > * {
  width: calc(12.5% - 7.3125rem / 8 * 7);
}
.flex-col8.gap117 {
  gap: 7.3125rem;
}
.flex-col8.gap118 > * {
  width: calc(12.5% - 7.375rem / 8 * 7);
}
.flex-col8.gap118 {
  gap: 7.375rem;
}
.flex-col8.gap119 > * {
  width: calc(12.5% - 7.4375rem / 8 * 7);
}
.flex-col8.gap119 {
  gap: 7.4375rem;
}
.flex-col8.gap120 > * {
  width: calc(12.5% - 7.5rem / 8 * 7);
}
.flex-col8.gap120 {
  gap: 7.5rem;
}
.flex-col8.gap121 > * {
  width: calc(12.5% - 7.5625rem / 8 * 7);
}
.flex-col8.gap121 {
  gap: 7.5625rem;
}
.flex-col8.gap122 > * {
  width: calc(12.5% - 7.625rem / 8 * 7);
}
.flex-col8.gap122 {
  gap: 7.625rem;
}
.flex-col8.gap123 > * {
  width: calc(12.5% - 7.6875rem / 8 * 7);
}
.flex-col8.gap123 {
  gap: 7.6875rem;
}
.flex-col8.gap124 > * {
  width: calc(12.5% - 7.75rem / 8 * 7);
}
.flex-col8.gap124 {
  gap: 7.75rem;
}
.flex-col8.gap125 > * {
  width: calc(12.5% - 7.8125rem / 8 * 7);
}
.flex-col8.gap125 {
  gap: 7.8125rem;
}
.flex-col8.gap126 > * {
  width: calc(12.5% - 7.875rem / 8 * 7);
}
.flex-col8.gap126 {
  gap: 7.875rem;
}
.flex-col8.gap127 > * {
  width: calc(12.5% - 7.9375rem / 8 * 7);
}
.flex-col8.gap127 {
  gap: 7.9375rem;
}
.flex-col8.gap128 > * {
  width: calc(12.5% - 8rem / 8 * 7);
}
.flex-col8.gap128 {
  gap: 8rem;
}
.flex-col8.gap129 > * {
  width: calc(12.5% - 8.0625rem / 8 * 7);
}
.flex-col8.gap129 {
  gap: 8.0625rem;
}
.flex-col8.gap130 > * {
  width: calc(12.5% - 8.125rem / 8 * 7);
}
.flex-col8.gap130 {
  gap: 8.125rem;
}
.flex-col8.gap131 > * {
  width: calc(12.5% - 8.1875rem / 8 * 7);
}
.flex-col8.gap131 {
  gap: 8.1875rem;
}
.flex-col8.gap132 > * {
  width: calc(12.5% - 8.25rem / 8 * 7);
}
.flex-col8.gap132 {
  gap: 8.25rem;
}
.flex-col8.gap133 > * {
  width: calc(12.5% - 8.3125rem / 8 * 7);
}
.flex-col8.gap133 {
  gap: 8.3125rem;
}
.flex-col8.gap134 > * {
  width: calc(12.5% - 8.375rem / 8 * 7);
}
.flex-col8.gap134 {
  gap: 8.375rem;
}
.flex-col8.gap135 > * {
  width: calc(12.5% - 8.4375rem / 8 * 7);
}
.flex-col8.gap135 {
  gap: 8.4375rem;
}
.flex-col8.gap136 > * {
  width: calc(12.5% - 8.5rem / 8 * 7);
}
.flex-col8.gap136 {
  gap: 8.5rem;
}
.flex-col8.gap137 > * {
  width: calc(12.5% - 8.5625rem / 8 * 7);
}
.flex-col8.gap137 {
  gap: 8.5625rem;
}
.flex-col8.gap138 > * {
  width: calc(12.5% - 8.625rem / 8 * 7);
}
.flex-col8.gap138 {
  gap: 8.625rem;
}
.flex-col8.gap139 > * {
  width: calc(12.5% - 8.6875rem / 8 * 7);
}
.flex-col8.gap139 {
  gap: 8.6875rem;
}
.flex-col8.gap140 > * {
  width: calc(12.5% - 8.75rem / 8 * 7);
}
.flex-col8.gap140 {
  gap: 8.75rem;
}
.flex-col8.gap141 > * {
  width: calc(12.5% - 8.8125rem / 8 * 7);
}
.flex-col8.gap141 {
  gap: 8.8125rem;
}
.flex-col8.gap142 > * {
  width: calc(12.5% - 8.875rem / 8 * 7);
}
.flex-col8.gap142 {
  gap: 8.875rem;
}
.flex-col8.gap143 > * {
  width: calc(12.5% - 8.9375rem / 8 * 7);
}
.flex-col8.gap143 {
  gap: 8.9375rem;
}
.flex-col8.gap144 > * {
  width: calc(12.5% - 9rem / 8 * 7);
}
.flex-col8.gap144 {
  gap: 9rem;
}
.flex-col8.gap145 > * {
  width: calc(12.5% - 9.0625rem / 8 * 7);
}
.flex-col8.gap145 {
  gap: 9.0625rem;
}
.flex-col8.gap146 > * {
  width: calc(12.5% - 9.125rem / 8 * 7);
}
.flex-col8.gap146 {
  gap: 9.125rem;
}
.flex-col8.gap147 > * {
  width: calc(12.5% - 9.1875rem / 8 * 7);
}
.flex-col8.gap147 {
  gap: 9.1875rem;
}
.flex-col8.gap148 > * {
  width: calc(12.5% - 9.25rem / 8 * 7);
}
.flex-col8.gap148 {
  gap: 9.25rem;
}
.flex-col8.gap149 > * {
  width: calc(12.5% - 9.3125rem / 8 * 7);
}
.flex-col8.gap149 {
  gap: 9.3125rem;
}
.flex-col8.gap150 > * {
  width: calc(12.5% - 9.375rem / 8 * 7);
}
.flex-col8.gap150 {
  gap: 9.375rem;
}

@media screen and (max-width: 47.9375em) {
  .flex-sp-col1 {
    flex-wrap: wrap;
  }
  .flex-sp-col1 > * {
    width: 100%;
  }
  .flex-sp-col1.gap1 > * {
    width: calc(100% - 0.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap2 > * {
    width: calc(100% - 0.125rem / 1 * 0);
  }
  .flex-sp-col1.gap3 > * {
    width: calc(100% - 0.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap4 > * {
    width: calc(100% - 0.25rem / 1 * 0);
  }
  .flex-sp-col1.gap5 > * {
    width: calc(100% - 0.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap6 > * {
    width: calc(100% - 0.375rem / 1 * 0);
  }
  .flex-sp-col1.gap7 > * {
    width: calc(100% - 0.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap8 > * {
    width: calc(100% - 0.5rem / 1 * 0);
  }
  .flex-sp-col1.gap9 > * {
    width: calc(100% - 0.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap10 > * {
    width: calc(100% - 0.625rem / 1 * 0);
  }
  .flex-sp-col1.gap11 > * {
    width: calc(100% - 0.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap12 > * {
    width: calc(100% - 0.75rem / 1 * 0);
  }
  .flex-sp-col1.gap13 > * {
    width: calc(100% - 0.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap14 > * {
    width: calc(100% - 0.875rem / 1 * 0);
  }
  .flex-sp-col1.gap15 > * {
    width: calc(100% - 0.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap16 > * {
    width: calc(100% - 1rem / 1 * 0);
  }
  .flex-sp-col1.gap17 > * {
    width: calc(100% - 1.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap18 > * {
    width: calc(100% - 1.125rem / 1 * 0);
  }
  .flex-sp-col1.gap19 > * {
    width: calc(100% - 1.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap20 > * {
    width: calc(100% - 1.25rem / 1 * 0);
  }
  .flex-sp-col1.gap21 > * {
    width: calc(100% - 1.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap22 > * {
    width: calc(100% - 1.375rem / 1 * 0);
  }
  .flex-sp-col1.gap23 > * {
    width: calc(100% - 1.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap24 > * {
    width: calc(100% - 1.5rem / 1 * 0);
  }
  .flex-sp-col1.gap25 > * {
    width: calc(100% - 1.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap26 > * {
    width: calc(100% - 1.625rem / 1 * 0);
  }
  .flex-sp-col1.gap27 > * {
    width: calc(100% - 1.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap28 > * {
    width: calc(100% - 1.75rem / 1 * 0);
  }
  .flex-sp-col1.gap29 > * {
    width: calc(100% - 1.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap30 > * {
    width: calc(100% - 1.875rem / 1 * 0);
  }
  .flex-sp-col1.gap31 > * {
    width: calc(100% - 1.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap32 > * {
    width: calc(100% - 2rem / 1 * 0);
  }
  .flex-sp-col1.gap33 > * {
    width: calc(100% - 2.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap34 > * {
    width: calc(100% - 2.125rem / 1 * 0);
  }
  .flex-sp-col1.gap35 > * {
    width: calc(100% - 2.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap36 > * {
    width: calc(100% - 2.25rem / 1 * 0);
  }
  .flex-sp-col1.gap37 > * {
    width: calc(100% - 2.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap38 > * {
    width: calc(100% - 2.375rem / 1 * 0);
  }
  .flex-sp-col1.gap39 > * {
    width: calc(100% - 2.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap40 > * {
    width: calc(100% - 2.5rem / 1 * 0);
  }
  .flex-sp-col1.gap41 > * {
    width: calc(100% - 2.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap42 > * {
    width: calc(100% - 2.625rem / 1 * 0);
  }
  .flex-sp-col1.gap43 > * {
    width: calc(100% - 2.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap44 > * {
    width: calc(100% - 2.75rem / 1 * 0);
  }
  .flex-sp-col1.gap45 > * {
    width: calc(100% - 2.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap46 > * {
    width: calc(100% - 2.875rem / 1 * 0);
  }
  .flex-sp-col1.gap47 > * {
    width: calc(100% - 2.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap48 > * {
    width: calc(100% - 3rem / 1 * 0);
  }
  .flex-sp-col1.gap49 > * {
    width: calc(100% - 3.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap50 > * {
    width: calc(100% - 3.125rem / 1 * 0);
  }
  .flex-sp-col1.gap51 > * {
    width: calc(100% - 3.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap52 > * {
    width: calc(100% - 3.25rem / 1 * 0);
  }
  .flex-sp-col1.gap53 > * {
    width: calc(100% - 3.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap54 > * {
    width: calc(100% - 3.375rem / 1 * 0);
  }
  .flex-sp-col1.gap55 > * {
    width: calc(100% - 3.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap56 > * {
    width: calc(100% - 3.5rem / 1 * 0);
  }
  .flex-sp-col1.gap57 > * {
    width: calc(100% - 3.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap58 > * {
    width: calc(100% - 3.625rem / 1 * 0);
  }
  .flex-sp-col1.gap59 > * {
    width: calc(100% - 3.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap60 > * {
    width: calc(100% - 3.75rem / 1 * 0);
  }
  .flex-sp-col1.gap61 > * {
    width: calc(100% - 3.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap62 > * {
    width: calc(100% - 3.875rem / 1 * 0);
  }
  .flex-sp-col1.gap63 > * {
    width: calc(100% - 3.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap64 > * {
    width: calc(100% - 4rem / 1 * 0);
  }
  .flex-sp-col1.gap65 > * {
    width: calc(100% - 4.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap66 > * {
    width: calc(100% - 4.125rem / 1 * 0);
  }
  .flex-sp-col1.gap67 > * {
    width: calc(100% - 4.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap68 > * {
    width: calc(100% - 4.25rem / 1 * 0);
  }
  .flex-sp-col1.gap69 > * {
    width: calc(100% - 4.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap70 > * {
    width: calc(100% - 4.375rem / 1 * 0);
  }
  .flex-sp-col1.gap71 > * {
    width: calc(100% - 4.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap72 > * {
    width: calc(100% - 4.5rem / 1 * 0);
  }
  .flex-sp-col1.gap73 > * {
    width: calc(100% - 4.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap74 > * {
    width: calc(100% - 4.625rem / 1 * 0);
  }
  .flex-sp-col1.gap75 > * {
    width: calc(100% - 4.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap76 > * {
    width: calc(100% - 4.75rem / 1 * 0);
  }
  .flex-sp-col1.gap77 > * {
    width: calc(100% - 4.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap78 > * {
    width: calc(100% - 4.875rem / 1 * 0);
  }
  .flex-sp-col1.gap79 > * {
    width: calc(100% - 4.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap80 > * {
    width: calc(100% - 5rem / 1 * 0);
  }
  .flex-sp-col1.gap81 > * {
    width: calc(100% - 5.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap82 > * {
    width: calc(100% - 5.125rem / 1 * 0);
  }
  .flex-sp-col1.gap83 > * {
    width: calc(100% - 5.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap84 > * {
    width: calc(100% - 5.25rem / 1 * 0);
  }
  .flex-sp-col1.gap85 > * {
    width: calc(100% - 5.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap86 > * {
    width: calc(100% - 5.375rem / 1 * 0);
  }
  .flex-sp-col1.gap87 > * {
    width: calc(100% - 5.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap88 > * {
    width: calc(100% - 5.5rem / 1 * 0);
  }
  .flex-sp-col1.gap89 > * {
    width: calc(100% - 5.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap90 > * {
    width: calc(100% - 5.625rem / 1 * 0);
  }
  .flex-sp-col1.gap91 > * {
    width: calc(100% - 5.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap92 > * {
    width: calc(100% - 5.75rem / 1 * 0);
  }
  .flex-sp-col1.gap93 > * {
    width: calc(100% - 5.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap94 > * {
    width: calc(100% - 5.875rem / 1 * 0);
  }
  .flex-sp-col1.gap95 > * {
    width: calc(100% - 5.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap96 > * {
    width: calc(100% - 6rem / 1 * 0);
  }
  .flex-sp-col1.gap97 > * {
    width: calc(100% - 6.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap98 > * {
    width: calc(100% - 6.125rem / 1 * 0);
  }
  .flex-sp-col1.gap99 > * {
    width: calc(100% - 6.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap100 > * {
    width: calc(100% - 6.25rem / 1 * 0);
  }
  .flex-sp-col1.gap101 > * {
    width: calc(100% - 6.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap102 > * {
    width: calc(100% - 6.375rem / 1 * 0);
  }
  .flex-sp-col1.gap103 > * {
    width: calc(100% - 6.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap104 > * {
    width: calc(100% - 6.5rem / 1 * 0);
  }
  .flex-sp-col1.gap105 > * {
    width: calc(100% - 6.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap106 > * {
    width: calc(100% - 6.625rem / 1 * 0);
  }
  .flex-sp-col1.gap107 > * {
    width: calc(100% - 6.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap108 > * {
    width: calc(100% - 6.75rem / 1 * 0);
  }
  .flex-sp-col1.gap109 > * {
    width: calc(100% - 6.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap110 > * {
    width: calc(100% - 6.875rem / 1 * 0);
  }
  .flex-sp-col1.gap111 > * {
    width: calc(100% - 6.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap112 > * {
    width: calc(100% - 7rem / 1 * 0);
  }
  .flex-sp-col1.gap113 > * {
    width: calc(100% - 7.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap114 > * {
    width: calc(100% - 7.125rem / 1 * 0);
  }
  .flex-sp-col1.gap115 > * {
    width: calc(100% - 7.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap116 > * {
    width: calc(100% - 7.25rem / 1 * 0);
  }
  .flex-sp-col1.gap117 > * {
    width: calc(100% - 7.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap118 > * {
    width: calc(100% - 7.375rem / 1 * 0);
  }
  .flex-sp-col1.gap119 > * {
    width: calc(100% - 7.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap120 > * {
    width: calc(100% - 7.5rem / 1 * 0);
  }
  .flex-sp-col1.gap121 > * {
    width: calc(100% - 7.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap122 > * {
    width: calc(100% - 7.625rem / 1 * 0);
  }
  .flex-sp-col1.gap123 > * {
    width: calc(100% - 7.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap124 > * {
    width: calc(100% - 7.75rem / 1 * 0);
  }
  .flex-sp-col1.gap125 > * {
    width: calc(100% - 7.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap126 > * {
    width: calc(100% - 7.875rem / 1 * 0);
  }
  .flex-sp-col1.gap127 > * {
    width: calc(100% - 7.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap128 > * {
    width: calc(100% - 8rem / 1 * 0);
  }
  .flex-sp-col1.gap129 > * {
    width: calc(100% - 8.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap130 > * {
    width: calc(100% - 8.125rem / 1 * 0);
  }
  .flex-sp-col1.gap131 > * {
    width: calc(100% - 8.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap132 > * {
    width: calc(100% - 8.25rem / 1 * 0);
  }
  .flex-sp-col1.gap133 > * {
    width: calc(100% - 8.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap134 > * {
    width: calc(100% - 8.375rem / 1 * 0);
  }
  .flex-sp-col1.gap135 > * {
    width: calc(100% - 8.4375rem / 1 * 0);
  }
  .flex-sp-col1.gap136 > * {
    width: calc(100% - 8.5rem / 1 * 0);
  }
  .flex-sp-col1.gap137 > * {
    width: calc(100% - 8.5625rem / 1 * 0);
  }
  .flex-sp-col1.gap138 > * {
    width: calc(100% - 8.625rem / 1 * 0);
  }
  .flex-sp-col1.gap139 > * {
    width: calc(100% - 8.6875rem / 1 * 0);
  }
  .flex-sp-col1.gap140 > * {
    width: calc(100% - 8.75rem / 1 * 0);
  }
  .flex-sp-col1.gap141 > * {
    width: calc(100% - 8.8125rem / 1 * 0);
  }
  .flex-sp-col1.gap142 > * {
    width: calc(100% - 8.875rem / 1 * 0);
  }
  .flex-sp-col1.gap143 > * {
    width: calc(100% - 8.9375rem / 1 * 0);
  }
  .flex-sp-col1.gap144 > * {
    width: calc(100% - 9rem / 1 * 0);
  }
  .flex-sp-col1.gap145 > * {
    width: calc(100% - 9.0625rem / 1 * 0);
  }
  .flex-sp-col1.gap146 > * {
    width: calc(100% - 9.125rem / 1 * 0);
  }
  .flex-sp-col1.gap147 > * {
    width: calc(100% - 9.1875rem / 1 * 0);
  }
  .flex-sp-col1.gap148 > * {
    width: calc(100% - 9.25rem / 1 * 0);
  }
  .flex-sp-col1.gap149 > * {
    width: calc(100% - 9.3125rem / 1 * 0);
  }
  .flex-sp-col1.gap150 > * {
    width: calc(100% - 9.375rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp1 > * {
    width: calc(100% - 0.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp2 > * {
    width: calc(100% - 0.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp3 > * {
    width: calc(100% - 0.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp4 > * {
    width: calc(100% - 0.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp5 > * {
    width: calc(100% - 0.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp6 > * {
    width: calc(100% - 0.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp7 > * {
    width: calc(100% - 0.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp8 > * {
    width: calc(100% - 0.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp9 > * {
    width: calc(100% - 0.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp10 > * {
    width: calc(100% - 0.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp11 > * {
    width: calc(100% - 0.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp12 > * {
    width: calc(100% - 0.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp13 > * {
    width: calc(100% - 0.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp14 > * {
    width: calc(100% - 1rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp15 > * {
    width: calc(100% - 1.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp16 > * {
    width: calc(100% - 1.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp17 > * {
    width: calc(100% - 1.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp18 > * {
    width: calc(100% - 1.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp19 > * {
    width: calc(100% - 1.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp20 > * {
    width: calc(100% - 1.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp21 > * {
    width: calc(100% - 1.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp22 > * {
    width: calc(100% - 1.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp23 > * {
    width: calc(100% - 1.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp24 > * {
    width: calc(100% - 1.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp25 > * {
    width: calc(100% - 1.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp26 > * {
    width: calc(100% - 1.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp27 > * {
    width: calc(100% - 1.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp28 > * {
    width: calc(100% - 2rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp29 > * {
    width: calc(100% - 2.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp30 > * {
    width: calc(100% - 2.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp31 > * {
    width: calc(100% - 2.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp32 > * {
    width: calc(100% - 2.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp33 > * {
    width: calc(100% - 2.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp34 > * {
    width: calc(100% - 2.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp35 > * {
    width: calc(100% - 2.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp36 > * {
    width: calc(100% - 2.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp37 > * {
    width: calc(100% - 2.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp38 > * {
    width: calc(100% - 2.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp39 > * {
    width: calc(100% - 2.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp40 > * {
    width: calc(100% - 2.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp41 > * {
    width: calc(100% - 2.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp42 > * {
    width: calc(100% - 3rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp43 > * {
    width: calc(100% - 3.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp44 > * {
    width: calc(100% - 3.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp45 > * {
    width: calc(100% - 3.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp46 > * {
    width: calc(100% - 3.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp47 > * {
    width: calc(100% - 3.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp48 > * {
    width: calc(100% - 3.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp49 > * {
    width: calc(100% - 3.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp50 > * {
    width: calc(100% - 3.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp51 > * {
    width: calc(100% - 3.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp52 > * {
    width: calc(100% - 3.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp53 > * {
    width: calc(100% - 3.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp54 > * {
    width: calc(100% - 3.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp55 > * {
    width: calc(100% - 3.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp56 > * {
    width: calc(100% - 4rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp57 > * {
    width: calc(100% - 4.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp58 > * {
    width: calc(100% - 4.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp59 > * {
    width: calc(100% - 4.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp60 > * {
    width: calc(100% - 4.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp61 > * {
    width: calc(100% - 4.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp62 > * {
    width: calc(100% - 4.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp63 > * {
    width: calc(100% - 4.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp64 > * {
    width: calc(100% - 4.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp65 > * {
    width: calc(100% - 4.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp66 > * {
    width: calc(100% - 4.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp67 > * {
    width: calc(100% - 4.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp68 > * {
    width: calc(100% - 4.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp69 > * {
    width: calc(100% - 4.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp70 > * {
    width: calc(100% - 5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp71 > * {
    width: calc(100% - 5.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp72 > * {
    width: calc(100% - 5.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp73 > * {
    width: calc(100% - 5.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp74 > * {
    width: calc(100% - 5.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp75 > * {
    width: calc(100% - 5.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp76 > * {
    width: calc(100% - 5.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp77 > * {
    width: calc(100% - 5.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp78 > * {
    width: calc(100% - 5.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp79 > * {
    width: calc(100% - 5.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp80 > * {
    width: calc(100% - 5.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp81 > * {
    width: calc(100% - 5.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp82 > * {
    width: calc(100% - 5.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp83 > * {
    width: calc(100% - 5.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp84 > * {
    width: calc(100% - 6rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp85 > * {
    width: calc(100% - 6.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp86 > * {
    width: calc(100% - 6.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp87 > * {
    width: calc(100% - 6.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp88 > * {
    width: calc(100% - 6.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp89 > * {
    width: calc(100% - 6.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp90 > * {
    width: calc(100% - 6.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp91 > * {
    width: calc(100% - 6.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp92 > * {
    width: calc(100% - 6.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp93 > * {
    width: calc(100% - 6.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp94 > * {
    width: calc(100% - 6.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp95 > * {
    width: calc(100% - 6.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp96 > * {
    width: calc(100% - 6.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp97 > * {
    width: calc(100% - 6.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp98 > * {
    width: calc(100% - 7rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp99 > * {
    width: calc(100% - 7.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp100 > * {
    width: calc(100% - 7.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp101 > * {
    width: calc(100% - 7.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp102 > * {
    width: calc(100% - 7.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp103 > * {
    width: calc(100% - 7.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp104 > * {
    width: calc(100% - 7.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp105 > * {
    width: calc(100% - 7.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp106 > * {
    width: calc(100% - 7.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp107 > * {
    width: calc(100% - 7.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp108 > * {
    width: calc(100% - 7.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp109 > * {
    width: calc(100% - 7.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp110 > * {
    width: calc(100% - 7.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp111 > * {
    width: calc(100% - 7.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp112 > * {
    width: calc(100% - 8rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp113 > * {
    width: calc(100% - 8.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp114 > * {
    width: calc(100% - 8.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp115 > * {
    width: calc(100% - 8.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp116 > * {
    width: calc(100% - 8.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp117 > * {
    width: calc(100% - 8.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp118 > * {
    width: calc(100% - 8.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp119 > * {
    width: calc(100% - 8.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp120 > * {
    width: calc(100% - 8.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp121 > * {
    width: calc(100% - 8.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp122 > * {
    width: calc(100% - 8.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp123 > * {
    width: calc(100% - 8.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp124 > * {
    width: calc(100% - 8.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp125 > * {
    width: calc(100% - 8.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp126 > * {
    width: calc(100% - 9rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp127 > * {
    width: calc(100% - 9.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp128 > * {
    width: calc(100% - 9.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp129 > * {
    width: calc(100% - 9.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp130 > * {
    width: calc(100% - 9.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp131 > * {
    width: calc(100% - 9.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp132 > * {
    width: calc(100% - 9.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp133 > * {
    width: calc(100% - 9.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp134 > * {
    width: calc(100% - 9.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp135 > * {
    width: calc(100% - 9.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp136 > * {
    width: calc(100% - 9.7142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp137 > * {
    width: calc(100% - 9.7857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp138 > * {
    width: calc(100% - 9.8571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp139 > * {
    width: calc(100% - 9.9285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp140 > * {
    width: calc(100% - 10rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp141 > * {
    width: calc(100% - 10.0714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp142 > * {
    width: calc(100% - 10.1428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp143 > * {
    width: calc(100% - 10.2142857143rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp144 > * {
    width: calc(100% - 10.2857142857rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp145 > * {
    width: calc(100% - 10.3571428571rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp146 > * {
    width: calc(100% - 10.4285714286rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp147 > * {
    width: calc(100% - 10.5rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp148 > * {
    width: calc(100% - 10.5714285714rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp149 > * {
    width: calc(100% - 10.6428571429rem / 1 * 0);
  }
  .flex-sp-col1.gap-sp150 > * {
    width: calc(100% - 10.7142857143rem / 1 * 0);
  }
  .flex-sp-col2 {
    flex-wrap: wrap;
  }
  .flex-sp-col2 > * {
    width: 50%;
  }
  .flex-sp-col2.gap1 > * {
    width: calc(50% - 0.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap2 > * {
    width: calc(50% - 0.125rem / 2 * 1);
  }
  .flex-sp-col2.gap3 > * {
    width: calc(50% - 0.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap4 > * {
    width: calc(50% - 0.25rem / 2 * 1);
  }
  .flex-sp-col2.gap5 > * {
    width: calc(50% - 0.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap6 > * {
    width: calc(50% - 0.375rem / 2 * 1);
  }
  .flex-sp-col2.gap7 > * {
    width: calc(50% - 0.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap8 > * {
    width: calc(50% - 0.5rem / 2 * 1);
  }
  .flex-sp-col2.gap9 > * {
    width: calc(50% - 0.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap10 > * {
    width: calc(50% - 0.625rem / 2 * 1);
  }
  .flex-sp-col2.gap11 > * {
    width: calc(50% - 0.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap12 > * {
    width: calc(50% - 0.75rem / 2 * 1);
  }
  .flex-sp-col2.gap13 > * {
    width: calc(50% - 0.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap14 > * {
    width: calc(50% - 0.875rem / 2 * 1);
  }
  .flex-sp-col2.gap15 > * {
    width: calc(50% - 0.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap16 > * {
    width: calc(50% - 1rem / 2 * 1);
  }
  .flex-sp-col2.gap17 > * {
    width: calc(50% - 1.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap18 > * {
    width: calc(50% - 1.125rem / 2 * 1);
  }
  .flex-sp-col2.gap19 > * {
    width: calc(50% - 1.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap20 > * {
    width: calc(50% - 1.25rem / 2 * 1);
  }
  .flex-sp-col2.gap21 > * {
    width: calc(50% - 1.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap22 > * {
    width: calc(50% - 1.375rem / 2 * 1);
  }
  .flex-sp-col2.gap23 > * {
    width: calc(50% - 1.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap24 > * {
    width: calc(50% - 1.5rem / 2 * 1);
  }
  .flex-sp-col2.gap25 > * {
    width: calc(50% - 1.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap26 > * {
    width: calc(50% - 1.625rem / 2 * 1);
  }
  .flex-sp-col2.gap27 > * {
    width: calc(50% - 1.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap28 > * {
    width: calc(50% - 1.75rem / 2 * 1);
  }
  .flex-sp-col2.gap29 > * {
    width: calc(50% - 1.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap30 > * {
    width: calc(50% - 1.875rem / 2 * 1);
  }
  .flex-sp-col2.gap31 > * {
    width: calc(50% - 1.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap32 > * {
    width: calc(50% - 2rem / 2 * 1);
  }
  .flex-sp-col2.gap33 > * {
    width: calc(50% - 2.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap34 > * {
    width: calc(50% - 2.125rem / 2 * 1);
  }
  .flex-sp-col2.gap35 > * {
    width: calc(50% - 2.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap36 > * {
    width: calc(50% - 2.25rem / 2 * 1);
  }
  .flex-sp-col2.gap37 > * {
    width: calc(50% - 2.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap38 > * {
    width: calc(50% - 2.375rem / 2 * 1);
  }
  .flex-sp-col2.gap39 > * {
    width: calc(50% - 2.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap40 > * {
    width: calc(50% - 2.5rem / 2 * 1);
  }
  .flex-sp-col2.gap41 > * {
    width: calc(50% - 2.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap42 > * {
    width: calc(50% - 2.625rem / 2 * 1);
  }
  .flex-sp-col2.gap43 > * {
    width: calc(50% - 2.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap44 > * {
    width: calc(50% - 2.75rem / 2 * 1);
  }
  .flex-sp-col2.gap45 > * {
    width: calc(50% - 2.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap46 > * {
    width: calc(50% - 2.875rem / 2 * 1);
  }
  .flex-sp-col2.gap47 > * {
    width: calc(50% - 2.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap48 > * {
    width: calc(50% - 3rem / 2 * 1);
  }
  .flex-sp-col2.gap49 > * {
    width: calc(50% - 3.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap50 > * {
    width: calc(50% - 3.125rem / 2 * 1);
  }
  .flex-sp-col2.gap51 > * {
    width: calc(50% - 3.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap52 > * {
    width: calc(50% - 3.25rem / 2 * 1);
  }
  .flex-sp-col2.gap53 > * {
    width: calc(50% - 3.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap54 > * {
    width: calc(50% - 3.375rem / 2 * 1);
  }
  .flex-sp-col2.gap55 > * {
    width: calc(50% - 3.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap56 > * {
    width: calc(50% - 3.5rem / 2 * 1);
  }
  .flex-sp-col2.gap57 > * {
    width: calc(50% - 3.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap58 > * {
    width: calc(50% - 3.625rem / 2 * 1);
  }
  .flex-sp-col2.gap59 > * {
    width: calc(50% - 3.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap60 > * {
    width: calc(50% - 3.75rem / 2 * 1);
  }
  .flex-sp-col2.gap61 > * {
    width: calc(50% - 3.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap62 > * {
    width: calc(50% - 3.875rem / 2 * 1);
  }
  .flex-sp-col2.gap63 > * {
    width: calc(50% - 3.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap64 > * {
    width: calc(50% - 4rem / 2 * 1);
  }
  .flex-sp-col2.gap65 > * {
    width: calc(50% - 4.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap66 > * {
    width: calc(50% - 4.125rem / 2 * 1);
  }
  .flex-sp-col2.gap67 > * {
    width: calc(50% - 4.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap68 > * {
    width: calc(50% - 4.25rem / 2 * 1);
  }
  .flex-sp-col2.gap69 > * {
    width: calc(50% - 4.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap70 > * {
    width: calc(50% - 4.375rem / 2 * 1);
  }
  .flex-sp-col2.gap71 > * {
    width: calc(50% - 4.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap72 > * {
    width: calc(50% - 4.5rem / 2 * 1);
  }
  .flex-sp-col2.gap73 > * {
    width: calc(50% - 4.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap74 > * {
    width: calc(50% - 4.625rem / 2 * 1);
  }
  .flex-sp-col2.gap75 > * {
    width: calc(50% - 4.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap76 > * {
    width: calc(50% - 4.75rem / 2 * 1);
  }
  .flex-sp-col2.gap77 > * {
    width: calc(50% - 4.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap78 > * {
    width: calc(50% - 4.875rem / 2 * 1);
  }
  .flex-sp-col2.gap79 > * {
    width: calc(50% - 4.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap80 > * {
    width: calc(50% - 5rem / 2 * 1);
  }
  .flex-sp-col2.gap81 > * {
    width: calc(50% - 5.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap82 > * {
    width: calc(50% - 5.125rem / 2 * 1);
  }
  .flex-sp-col2.gap83 > * {
    width: calc(50% - 5.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap84 > * {
    width: calc(50% - 5.25rem / 2 * 1);
  }
  .flex-sp-col2.gap85 > * {
    width: calc(50% - 5.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap86 > * {
    width: calc(50% - 5.375rem / 2 * 1);
  }
  .flex-sp-col2.gap87 > * {
    width: calc(50% - 5.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap88 > * {
    width: calc(50% - 5.5rem / 2 * 1);
  }
  .flex-sp-col2.gap89 > * {
    width: calc(50% - 5.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap90 > * {
    width: calc(50% - 5.625rem / 2 * 1);
  }
  .flex-sp-col2.gap91 > * {
    width: calc(50% - 5.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap92 > * {
    width: calc(50% - 5.75rem / 2 * 1);
  }
  .flex-sp-col2.gap93 > * {
    width: calc(50% - 5.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap94 > * {
    width: calc(50% - 5.875rem / 2 * 1);
  }
  .flex-sp-col2.gap95 > * {
    width: calc(50% - 5.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap96 > * {
    width: calc(50% - 6rem / 2 * 1);
  }
  .flex-sp-col2.gap97 > * {
    width: calc(50% - 6.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap98 > * {
    width: calc(50% - 6.125rem / 2 * 1);
  }
  .flex-sp-col2.gap99 > * {
    width: calc(50% - 6.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap100 > * {
    width: calc(50% - 6.25rem / 2 * 1);
  }
  .flex-sp-col2.gap101 > * {
    width: calc(50% - 6.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap102 > * {
    width: calc(50% - 6.375rem / 2 * 1);
  }
  .flex-sp-col2.gap103 > * {
    width: calc(50% - 6.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap104 > * {
    width: calc(50% - 6.5rem / 2 * 1);
  }
  .flex-sp-col2.gap105 > * {
    width: calc(50% - 6.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap106 > * {
    width: calc(50% - 6.625rem / 2 * 1);
  }
  .flex-sp-col2.gap107 > * {
    width: calc(50% - 6.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap108 > * {
    width: calc(50% - 6.75rem / 2 * 1);
  }
  .flex-sp-col2.gap109 > * {
    width: calc(50% - 6.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap110 > * {
    width: calc(50% - 6.875rem / 2 * 1);
  }
  .flex-sp-col2.gap111 > * {
    width: calc(50% - 6.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap112 > * {
    width: calc(50% - 7rem / 2 * 1);
  }
  .flex-sp-col2.gap113 > * {
    width: calc(50% - 7.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap114 > * {
    width: calc(50% - 7.125rem / 2 * 1);
  }
  .flex-sp-col2.gap115 > * {
    width: calc(50% - 7.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap116 > * {
    width: calc(50% - 7.25rem / 2 * 1);
  }
  .flex-sp-col2.gap117 > * {
    width: calc(50% - 7.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap118 > * {
    width: calc(50% - 7.375rem / 2 * 1);
  }
  .flex-sp-col2.gap119 > * {
    width: calc(50% - 7.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap120 > * {
    width: calc(50% - 7.5rem / 2 * 1);
  }
  .flex-sp-col2.gap121 > * {
    width: calc(50% - 7.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap122 > * {
    width: calc(50% - 7.625rem / 2 * 1);
  }
  .flex-sp-col2.gap123 > * {
    width: calc(50% - 7.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap124 > * {
    width: calc(50% - 7.75rem / 2 * 1);
  }
  .flex-sp-col2.gap125 > * {
    width: calc(50% - 7.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap126 > * {
    width: calc(50% - 7.875rem / 2 * 1);
  }
  .flex-sp-col2.gap127 > * {
    width: calc(50% - 7.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap128 > * {
    width: calc(50% - 8rem / 2 * 1);
  }
  .flex-sp-col2.gap129 > * {
    width: calc(50% - 8.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap130 > * {
    width: calc(50% - 8.125rem / 2 * 1);
  }
  .flex-sp-col2.gap131 > * {
    width: calc(50% - 8.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap132 > * {
    width: calc(50% - 8.25rem / 2 * 1);
  }
  .flex-sp-col2.gap133 > * {
    width: calc(50% - 8.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap134 > * {
    width: calc(50% - 8.375rem / 2 * 1);
  }
  .flex-sp-col2.gap135 > * {
    width: calc(50% - 8.4375rem / 2 * 1);
  }
  .flex-sp-col2.gap136 > * {
    width: calc(50% - 8.5rem / 2 * 1);
  }
  .flex-sp-col2.gap137 > * {
    width: calc(50% - 8.5625rem / 2 * 1);
  }
  .flex-sp-col2.gap138 > * {
    width: calc(50% - 8.625rem / 2 * 1);
  }
  .flex-sp-col2.gap139 > * {
    width: calc(50% - 8.6875rem / 2 * 1);
  }
  .flex-sp-col2.gap140 > * {
    width: calc(50% - 8.75rem / 2 * 1);
  }
  .flex-sp-col2.gap141 > * {
    width: calc(50% - 8.8125rem / 2 * 1);
  }
  .flex-sp-col2.gap142 > * {
    width: calc(50% - 8.875rem / 2 * 1);
  }
  .flex-sp-col2.gap143 > * {
    width: calc(50% - 8.9375rem / 2 * 1);
  }
  .flex-sp-col2.gap144 > * {
    width: calc(50% - 9rem / 2 * 1);
  }
  .flex-sp-col2.gap145 > * {
    width: calc(50% - 9.0625rem / 2 * 1);
  }
  .flex-sp-col2.gap146 > * {
    width: calc(50% - 9.125rem / 2 * 1);
  }
  .flex-sp-col2.gap147 > * {
    width: calc(50% - 9.1875rem / 2 * 1);
  }
  .flex-sp-col2.gap148 > * {
    width: calc(50% - 9.25rem / 2 * 1);
  }
  .flex-sp-col2.gap149 > * {
    width: calc(50% - 9.3125rem / 2 * 1);
  }
  .flex-sp-col2.gap150 > * {
    width: calc(50% - 9.375rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp1 > * {
    width: calc(50% - 0.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp2 > * {
    width: calc(50% - 0.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp3 > * {
    width: calc(50% - 0.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp4 > * {
    width: calc(50% - 0.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp5 > * {
    width: calc(50% - 0.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp6 > * {
    width: calc(50% - 0.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp7 > * {
    width: calc(50% - 0.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp8 > * {
    width: calc(50% - 0.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp9 > * {
    width: calc(50% - 0.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp10 > * {
    width: calc(50% - 0.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp11 > * {
    width: calc(50% - 0.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp12 > * {
    width: calc(50% - 0.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp13 > * {
    width: calc(50% - 0.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp14 > * {
    width: calc(50% - 1rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp15 > * {
    width: calc(50% - 1.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp16 > * {
    width: calc(50% - 1.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp17 > * {
    width: calc(50% - 1.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp18 > * {
    width: calc(50% - 1.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp19 > * {
    width: calc(50% - 1.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp20 > * {
    width: calc(50% - 1.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp21 > * {
    width: calc(50% - 1.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp22 > * {
    width: calc(50% - 1.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp23 > * {
    width: calc(50% - 1.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp24 > * {
    width: calc(50% - 1.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp25 > * {
    width: calc(50% - 1.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp26 > * {
    width: calc(50% - 1.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp27 > * {
    width: calc(50% - 1.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp28 > * {
    width: calc(50% - 2rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp29 > * {
    width: calc(50% - 2.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp30 > * {
    width: calc(50% - 2.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp31 > * {
    width: calc(50% - 2.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp32 > * {
    width: calc(50% - 2.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp33 > * {
    width: calc(50% - 2.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp34 > * {
    width: calc(50% - 2.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp35 > * {
    width: calc(50% - 2.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp36 > * {
    width: calc(50% - 2.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp37 > * {
    width: calc(50% - 2.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp38 > * {
    width: calc(50% - 2.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp39 > * {
    width: calc(50% - 2.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp40 > * {
    width: calc(50% - 2.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp41 > * {
    width: calc(50% - 2.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp42 > * {
    width: calc(50% - 3rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp43 > * {
    width: calc(50% - 3.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp44 > * {
    width: calc(50% - 3.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp45 > * {
    width: calc(50% - 3.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp46 > * {
    width: calc(50% - 3.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp47 > * {
    width: calc(50% - 3.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp48 > * {
    width: calc(50% - 3.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp49 > * {
    width: calc(50% - 3.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp50 > * {
    width: calc(50% - 3.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp51 > * {
    width: calc(50% - 3.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp52 > * {
    width: calc(50% - 3.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp53 > * {
    width: calc(50% - 3.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp54 > * {
    width: calc(50% - 3.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp55 > * {
    width: calc(50% - 3.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp56 > * {
    width: calc(50% - 4rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp57 > * {
    width: calc(50% - 4.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp58 > * {
    width: calc(50% - 4.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp59 > * {
    width: calc(50% - 4.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp60 > * {
    width: calc(50% - 4.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp61 > * {
    width: calc(50% - 4.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp62 > * {
    width: calc(50% - 4.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp63 > * {
    width: calc(50% - 4.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp64 > * {
    width: calc(50% - 4.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp65 > * {
    width: calc(50% - 4.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp66 > * {
    width: calc(50% - 4.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp67 > * {
    width: calc(50% - 4.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp68 > * {
    width: calc(50% - 4.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp69 > * {
    width: calc(50% - 4.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp70 > * {
    width: calc(50% - 5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp71 > * {
    width: calc(50% - 5.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp72 > * {
    width: calc(50% - 5.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp73 > * {
    width: calc(50% - 5.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp74 > * {
    width: calc(50% - 5.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp75 > * {
    width: calc(50% - 5.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp76 > * {
    width: calc(50% - 5.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp77 > * {
    width: calc(50% - 5.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp78 > * {
    width: calc(50% - 5.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp79 > * {
    width: calc(50% - 5.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp80 > * {
    width: calc(50% - 5.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp81 > * {
    width: calc(50% - 5.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp82 > * {
    width: calc(50% - 5.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp83 > * {
    width: calc(50% - 5.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp84 > * {
    width: calc(50% - 6rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp85 > * {
    width: calc(50% - 6.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp86 > * {
    width: calc(50% - 6.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp87 > * {
    width: calc(50% - 6.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp88 > * {
    width: calc(50% - 6.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp89 > * {
    width: calc(50% - 6.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp90 > * {
    width: calc(50% - 6.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp91 > * {
    width: calc(50% - 6.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp92 > * {
    width: calc(50% - 6.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp93 > * {
    width: calc(50% - 6.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp94 > * {
    width: calc(50% - 6.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp95 > * {
    width: calc(50% - 6.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp96 > * {
    width: calc(50% - 6.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp97 > * {
    width: calc(50% - 6.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp98 > * {
    width: calc(50% - 7rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp99 > * {
    width: calc(50% - 7.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp100 > * {
    width: calc(50% - 7.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp101 > * {
    width: calc(50% - 7.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp102 > * {
    width: calc(50% - 7.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp103 > * {
    width: calc(50% - 7.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp104 > * {
    width: calc(50% - 7.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp105 > * {
    width: calc(50% - 7.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp106 > * {
    width: calc(50% - 7.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp107 > * {
    width: calc(50% - 7.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp108 > * {
    width: calc(50% - 7.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp109 > * {
    width: calc(50% - 7.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp110 > * {
    width: calc(50% - 7.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp111 > * {
    width: calc(50% - 7.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp112 > * {
    width: calc(50% - 8rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp113 > * {
    width: calc(50% - 8.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp114 > * {
    width: calc(50% - 8.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp115 > * {
    width: calc(50% - 8.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp116 > * {
    width: calc(50% - 8.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp117 > * {
    width: calc(50% - 8.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp118 > * {
    width: calc(50% - 8.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp119 > * {
    width: calc(50% - 8.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp120 > * {
    width: calc(50% - 8.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp121 > * {
    width: calc(50% - 8.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp122 > * {
    width: calc(50% - 8.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp123 > * {
    width: calc(50% - 8.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp124 > * {
    width: calc(50% - 8.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp125 > * {
    width: calc(50% - 8.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp126 > * {
    width: calc(50% - 9rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp127 > * {
    width: calc(50% - 9.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp128 > * {
    width: calc(50% - 9.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp129 > * {
    width: calc(50% - 9.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp130 > * {
    width: calc(50% - 9.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp131 > * {
    width: calc(50% - 9.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp132 > * {
    width: calc(50% - 9.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp133 > * {
    width: calc(50% - 9.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp134 > * {
    width: calc(50% - 9.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp135 > * {
    width: calc(50% - 9.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp136 > * {
    width: calc(50% - 9.7142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp137 > * {
    width: calc(50% - 9.7857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp138 > * {
    width: calc(50% - 9.8571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp139 > * {
    width: calc(50% - 9.9285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp140 > * {
    width: calc(50% - 10rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp141 > * {
    width: calc(50% - 10.0714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp142 > * {
    width: calc(50% - 10.1428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp143 > * {
    width: calc(50% - 10.2142857143rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp144 > * {
    width: calc(50% - 10.2857142857rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp145 > * {
    width: calc(50% - 10.3571428571rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp146 > * {
    width: calc(50% - 10.4285714286rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp147 > * {
    width: calc(50% - 10.5rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp148 > * {
    width: calc(50% - 10.5714285714rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp149 > * {
    width: calc(50% - 10.6428571429rem / 2 * 1);
  }
  .flex-sp-col2.gap-sp150 > * {
    width: calc(50% - 10.7142857143rem / 2 * 1);
  }
  .flex-sp-col3 {
    flex-wrap: wrap;
  }
  .flex-sp-col3 > * {
    width: 33.3333333333%;
  }
  .flex-sp-col3.gap1 > * {
    width: calc(33.3333333333% - 0.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap2 > * {
    width: calc(33.3333333333% - 0.125rem / 3 * 2);
  }
  .flex-sp-col3.gap3 > * {
    width: calc(33.3333333333% - 0.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap4 > * {
    width: calc(33.3333333333% - 0.25rem / 3 * 2);
  }
  .flex-sp-col3.gap5 > * {
    width: calc(33.3333333333% - 0.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap6 > * {
    width: calc(33.3333333333% - 0.375rem / 3 * 2);
  }
  .flex-sp-col3.gap7 > * {
    width: calc(33.3333333333% - 0.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap8 > * {
    width: calc(33.3333333333% - 0.5rem / 3 * 2);
  }
  .flex-sp-col3.gap9 > * {
    width: calc(33.3333333333% - 0.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap10 > * {
    width: calc(33.3333333333% - 0.625rem / 3 * 2);
  }
  .flex-sp-col3.gap11 > * {
    width: calc(33.3333333333% - 0.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap12 > * {
    width: calc(33.3333333333% - 0.75rem / 3 * 2);
  }
  .flex-sp-col3.gap13 > * {
    width: calc(33.3333333333% - 0.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap14 > * {
    width: calc(33.3333333333% - 0.875rem / 3 * 2);
  }
  .flex-sp-col3.gap15 > * {
    width: calc(33.3333333333% - 0.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap16 > * {
    width: calc(33.3333333333% - 1rem / 3 * 2);
  }
  .flex-sp-col3.gap17 > * {
    width: calc(33.3333333333% - 1.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap18 > * {
    width: calc(33.3333333333% - 1.125rem / 3 * 2);
  }
  .flex-sp-col3.gap19 > * {
    width: calc(33.3333333333% - 1.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap20 > * {
    width: calc(33.3333333333% - 1.25rem / 3 * 2);
  }
  .flex-sp-col3.gap21 > * {
    width: calc(33.3333333333% - 1.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap22 > * {
    width: calc(33.3333333333% - 1.375rem / 3 * 2);
  }
  .flex-sp-col3.gap23 > * {
    width: calc(33.3333333333% - 1.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap24 > * {
    width: calc(33.3333333333% - 1.5rem / 3 * 2);
  }
  .flex-sp-col3.gap25 > * {
    width: calc(33.3333333333% - 1.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap26 > * {
    width: calc(33.3333333333% - 1.625rem / 3 * 2);
  }
  .flex-sp-col3.gap27 > * {
    width: calc(33.3333333333% - 1.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap28 > * {
    width: calc(33.3333333333% - 1.75rem / 3 * 2);
  }
  .flex-sp-col3.gap29 > * {
    width: calc(33.3333333333% - 1.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap30 > * {
    width: calc(33.3333333333% - 1.875rem / 3 * 2);
  }
  .flex-sp-col3.gap31 > * {
    width: calc(33.3333333333% - 1.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap32 > * {
    width: calc(33.3333333333% - 2rem / 3 * 2);
  }
  .flex-sp-col3.gap33 > * {
    width: calc(33.3333333333% - 2.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap34 > * {
    width: calc(33.3333333333% - 2.125rem / 3 * 2);
  }
  .flex-sp-col3.gap35 > * {
    width: calc(33.3333333333% - 2.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap36 > * {
    width: calc(33.3333333333% - 2.25rem / 3 * 2);
  }
  .flex-sp-col3.gap37 > * {
    width: calc(33.3333333333% - 2.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap38 > * {
    width: calc(33.3333333333% - 2.375rem / 3 * 2);
  }
  .flex-sp-col3.gap39 > * {
    width: calc(33.3333333333% - 2.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap40 > * {
    width: calc(33.3333333333% - 2.5rem / 3 * 2);
  }
  .flex-sp-col3.gap41 > * {
    width: calc(33.3333333333% - 2.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap42 > * {
    width: calc(33.3333333333% - 2.625rem / 3 * 2);
  }
  .flex-sp-col3.gap43 > * {
    width: calc(33.3333333333% - 2.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap44 > * {
    width: calc(33.3333333333% - 2.75rem / 3 * 2);
  }
  .flex-sp-col3.gap45 > * {
    width: calc(33.3333333333% - 2.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap46 > * {
    width: calc(33.3333333333% - 2.875rem / 3 * 2);
  }
  .flex-sp-col3.gap47 > * {
    width: calc(33.3333333333% - 2.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap48 > * {
    width: calc(33.3333333333% - 3rem / 3 * 2);
  }
  .flex-sp-col3.gap49 > * {
    width: calc(33.3333333333% - 3.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap50 > * {
    width: calc(33.3333333333% - 3.125rem / 3 * 2);
  }
  .flex-sp-col3.gap51 > * {
    width: calc(33.3333333333% - 3.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap52 > * {
    width: calc(33.3333333333% - 3.25rem / 3 * 2);
  }
  .flex-sp-col3.gap53 > * {
    width: calc(33.3333333333% - 3.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap54 > * {
    width: calc(33.3333333333% - 3.375rem / 3 * 2);
  }
  .flex-sp-col3.gap55 > * {
    width: calc(33.3333333333% - 3.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap56 > * {
    width: calc(33.3333333333% - 3.5rem / 3 * 2);
  }
  .flex-sp-col3.gap57 > * {
    width: calc(33.3333333333% - 3.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap58 > * {
    width: calc(33.3333333333% - 3.625rem / 3 * 2);
  }
  .flex-sp-col3.gap59 > * {
    width: calc(33.3333333333% - 3.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap60 > * {
    width: calc(33.3333333333% - 3.75rem / 3 * 2);
  }
  .flex-sp-col3.gap61 > * {
    width: calc(33.3333333333% - 3.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap62 > * {
    width: calc(33.3333333333% - 3.875rem / 3 * 2);
  }
  .flex-sp-col3.gap63 > * {
    width: calc(33.3333333333% - 3.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap64 > * {
    width: calc(33.3333333333% - 4rem / 3 * 2);
  }
  .flex-sp-col3.gap65 > * {
    width: calc(33.3333333333% - 4.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap66 > * {
    width: calc(33.3333333333% - 4.125rem / 3 * 2);
  }
  .flex-sp-col3.gap67 > * {
    width: calc(33.3333333333% - 4.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap68 > * {
    width: calc(33.3333333333% - 4.25rem / 3 * 2);
  }
  .flex-sp-col3.gap69 > * {
    width: calc(33.3333333333% - 4.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap70 > * {
    width: calc(33.3333333333% - 4.375rem / 3 * 2);
  }
  .flex-sp-col3.gap71 > * {
    width: calc(33.3333333333% - 4.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap72 > * {
    width: calc(33.3333333333% - 4.5rem / 3 * 2);
  }
  .flex-sp-col3.gap73 > * {
    width: calc(33.3333333333% - 4.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap74 > * {
    width: calc(33.3333333333% - 4.625rem / 3 * 2);
  }
  .flex-sp-col3.gap75 > * {
    width: calc(33.3333333333% - 4.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap76 > * {
    width: calc(33.3333333333% - 4.75rem / 3 * 2);
  }
  .flex-sp-col3.gap77 > * {
    width: calc(33.3333333333% - 4.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap78 > * {
    width: calc(33.3333333333% - 4.875rem / 3 * 2);
  }
  .flex-sp-col3.gap79 > * {
    width: calc(33.3333333333% - 4.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap80 > * {
    width: calc(33.3333333333% - 5rem / 3 * 2);
  }
  .flex-sp-col3.gap81 > * {
    width: calc(33.3333333333% - 5.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap82 > * {
    width: calc(33.3333333333% - 5.125rem / 3 * 2);
  }
  .flex-sp-col3.gap83 > * {
    width: calc(33.3333333333% - 5.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap84 > * {
    width: calc(33.3333333333% - 5.25rem / 3 * 2);
  }
  .flex-sp-col3.gap85 > * {
    width: calc(33.3333333333% - 5.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap86 > * {
    width: calc(33.3333333333% - 5.375rem / 3 * 2);
  }
  .flex-sp-col3.gap87 > * {
    width: calc(33.3333333333% - 5.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap88 > * {
    width: calc(33.3333333333% - 5.5rem / 3 * 2);
  }
  .flex-sp-col3.gap89 > * {
    width: calc(33.3333333333% - 5.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap90 > * {
    width: calc(33.3333333333% - 5.625rem / 3 * 2);
  }
  .flex-sp-col3.gap91 > * {
    width: calc(33.3333333333% - 5.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap92 > * {
    width: calc(33.3333333333% - 5.75rem / 3 * 2);
  }
  .flex-sp-col3.gap93 > * {
    width: calc(33.3333333333% - 5.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap94 > * {
    width: calc(33.3333333333% - 5.875rem / 3 * 2);
  }
  .flex-sp-col3.gap95 > * {
    width: calc(33.3333333333% - 5.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap96 > * {
    width: calc(33.3333333333% - 6rem / 3 * 2);
  }
  .flex-sp-col3.gap97 > * {
    width: calc(33.3333333333% - 6.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap98 > * {
    width: calc(33.3333333333% - 6.125rem / 3 * 2);
  }
  .flex-sp-col3.gap99 > * {
    width: calc(33.3333333333% - 6.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap100 > * {
    width: calc(33.3333333333% - 6.25rem / 3 * 2);
  }
  .flex-sp-col3.gap101 > * {
    width: calc(33.3333333333% - 6.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap102 > * {
    width: calc(33.3333333333% - 6.375rem / 3 * 2);
  }
  .flex-sp-col3.gap103 > * {
    width: calc(33.3333333333% - 6.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap104 > * {
    width: calc(33.3333333333% - 6.5rem / 3 * 2);
  }
  .flex-sp-col3.gap105 > * {
    width: calc(33.3333333333% - 6.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap106 > * {
    width: calc(33.3333333333% - 6.625rem / 3 * 2);
  }
  .flex-sp-col3.gap107 > * {
    width: calc(33.3333333333% - 6.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap108 > * {
    width: calc(33.3333333333% - 6.75rem / 3 * 2);
  }
  .flex-sp-col3.gap109 > * {
    width: calc(33.3333333333% - 6.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap110 > * {
    width: calc(33.3333333333% - 6.875rem / 3 * 2);
  }
  .flex-sp-col3.gap111 > * {
    width: calc(33.3333333333% - 6.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap112 > * {
    width: calc(33.3333333333% - 7rem / 3 * 2);
  }
  .flex-sp-col3.gap113 > * {
    width: calc(33.3333333333% - 7.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap114 > * {
    width: calc(33.3333333333% - 7.125rem / 3 * 2);
  }
  .flex-sp-col3.gap115 > * {
    width: calc(33.3333333333% - 7.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap116 > * {
    width: calc(33.3333333333% - 7.25rem / 3 * 2);
  }
  .flex-sp-col3.gap117 > * {
    width: calc(33.3333333333% - 7.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap118 > * {
    width: calc(33.3333333333% - 7.375rem / 3 * 2);
  }
  .flex-sp-col3.gap119 > * {
    width: calc(33.3333333333% - 7.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap120 > * {
    width: calc(33.3333333333% - 7.5rem / 3 * 2);
  }
  .flex-sp-col3.gap121 > * {
    width: calc(33.3333333333% - 7.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap122 > * {
    width: calc(33.3333333333% - 7.625rem / 3 * 2);
  }
  .flex-sp-col3.gap123 > * {
    width: calc(33.3333333333% - 7.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap124 > * {
    width: calc(33.3333333333% - 7.75rem / 3 * 2);
  }
  .flex-sp-col3.gap125 > * {
    width: calc(33.3333333333% - 7.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap126 > * {
    width: calc(33.3333333333% - 7.875rem / 3 * 2);
  }
  .flex-sp-col3.gap127 > * {
    width: calc(33.3333333333% - 7.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap128 > * {
    width: calc(33.3333333333% - 8rem / 3 * 2);
  }
  .flex-sp-col3.gap129 > * {
    width: calc(33.3333333333% - 8.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap130 > * {
    width: calc(33.3333333333% - 8.125rem / 3 * 2);
  }
  .flex-sp-col3.gap131 > * {
    width: calc(33.3333333333% - 8.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap132 > * {
    width: calc(33.3333333333% - 8.25rem / 3 * 2);
  }
  .flex-sp-col3.gap133 > * {
    width: calc(33.3333333333% - 8.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap134 > * {
    width: calc(33.3333333333% - 8.375rem / 3 * 2);
  }
  .flex-sp-col3.gap135 > * {
    width: calc(33.3333333333% - 8.4375rem / 3 * 2);
  }
  .flex-sp-col3.gap136 > * {
    width: calc(33.3333333333% - 8.5rem / 3 * 2);
  }
  .flex-sp-col3.gap137 > * {
    width: calc(33.3333333333% - 8.5625rem / 3 * 2);
  }
  .flex-sp-col3.gap138 > * {
    width: calc(33.3333333333% - 8.625rem / 3 * 2);
  }
  .flex-sp-col3.gap139 > * {
    width: calc(33.3333333333% - 8.6875rem / 3 * 2);
  }
  .flex-sp-col3.gap140 > * {
    width: calc(33.3333333333% - 8.75rem / 3 * 2);
  }
  .flex-sp-col3.gap141 > * {
    width: calc(33.3333333333% - 8.8125rem / 3 * 2);
  }
  .flex-sp-col3.gap142 > * {
    width: calc(33.3333333333% - 8.875rem / 3 * 2);
  }
  .flex-sp-col3.gap143 > * {
    width: calc(33.3333333333% - 8.9375rem / 3 * 2);
  }
  .flex-sp-col3.gap144 > * {
    width: calc(33.3333333333% - 9rem / 3 * 2);
  }
  .flex-sp-col3.gap145 > * {
    width: calc(33.3333333333% - 9.0625rem / 3 * 2);
  }
  .flex-sp-col3.gap146 > * {
    width: calc(33.3333333333% - 9.125rem / 3 * 2);
  }
  .flex-sp-col3.gap147 > * {
    width: calc(33.3333333333% - 9.1875rem / 3 * 2);
  }
  .flex-sp-col3.gap148 > * {
    width: calc(33.3333333333% - 9.25rem / 3 * 2);
  }
  .flex-sp-col3.gap149 > * {
    width: calc(33.3333333333% - 9.3125rem / 3 * 2);
  }
  .flex-sp-col3.gap150 > * {
    width: calc(33.3333333333% - 9.375rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp1 > * {
    width: calc(33.3333333333% - 0.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp2 > * {
    width: calc(33.3333333333% - 0.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp3 > * {
    width: calc(33.3333333333% - 0.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp4 > * {
    width: calc(33.3333333333% - 0.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp5 > * {
    width: calc(33.3333333333% - 0.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp6 > * {
    width: calc(33.3333333333% - 0.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp7 > * {
    width: calc(33.3333333333% - 0.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp8 > * {
    width: calc(33.3333333333% - 0.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp9 > * {
    width: calc(33.3333333333% - 0.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp10 > * {
    width: calc(33.3333333333% - 0.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp11 > * {
    width: calc(33.3333333333% - 0.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp12 > * {
    width: calc(33.3333333333% - 0.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp13 > * {
    width: calc(33.3333333333% - 0.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp14 > * {
    width: calc(33.3333333333% - 1rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp15 > * {
    width: calc(33.3333333333% - 1.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp16 > * {
    width: calc(33.3333333333% - 1.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp17 > * {
    width: calc(33.3333333333% - 1.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp18 > * {
    width: calc(33.3333333333% - 1.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp19 > * {
    width: calc(33.3333333333% - 1.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp20 > * {
    width: calc(33.3333333333% - 1.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp21 > * {
    width: calc(33.3333333333% - 1.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp22 > * {
    width: calc(33.3333333333% - 1.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp23 > * {
    width: calc(33.3333333333% - 1.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp24 > * {
    width: calc(33.3333333333% - 1.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp25 > * {
    width: calc(33.3333333333% - 1.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp26 > * {
    width: calc(33.3333333333% - 1.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp27 > * {
    width: calc(33.3333333333% - 1.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp28 > * {
    width: calc(33.3333333333% - 2rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp29 > * {
    width: calc(33.3333333333% - 2.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp30 > * {
    width: calc(33.3333333333% - 2.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp31 > * {
    width: calc(33.3333333333% - 2.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp32 > * {
    width: calc(33.3333333333% - 2.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp33 > * {
    width: calc(33.3333333333% - 2.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp34 > * {
    width: calc(33.3333333333% - 2.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp35 > * {
    width: calc(33.3333333333% - 2.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp36 > * {
    width: calc(33.3333333333% - 2.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp37 > * {
    width: calc(33.3333333333% - 2.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp38 > * {
    width: calc(33.3333333333% - 2.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp39 > * {
    width: calc(33.3333333333% - 2.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp40 > * {
    width: calc(33.3333333333% - 2.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp41 > * {
    width: calc(33.3333333333% - 2.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp42 > * {
    width: calc(33.3333333333% - 3rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp43 > * {
    width: calc(33.3333333333% - 3.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp44 > * {
    width: calc(33.3333333333% - 3.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp45 > * {
    width: calc(33.3333333333% - 3.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp46 > * {
    width: calc(33.3333333333% - 3.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp47 > * {
    width: calc(33.3333333333% - 3.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp48 > * {
    width: calc(33.3333333333% - 3.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp49 > * {
    width: calc(33.3333333333% - 3.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp50 > * {
    width: calc(33.3333333333% - 3.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp51 > * {
    width: calc(33.3333333333% - 3.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp52 > * {
    width: calc(33.3333333333% - 3.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp53 > * {
    width: calc(33.3333333333% - 3.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp54 > * {
    width: calc(33.3333333333% - 3.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp55 > * {
    width: calc(33.3333333333% - 3.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp56 > * {
    width: calc(33.3333333333% - 4rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp57 > * {
    width: calc(33.3333333333% - 4.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp58 > * {
    width: calc(33.3333333333% - 4.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp59 > * {
    width: calc(33.3333333333% - 4.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp60 > * {
    width: calc(33.3333333333% - 4.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp61 > * {
    width: calc(33.3333333333% - 4.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp62 > * {
    width: calc(33.3333333333% - 4.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp63 > * {
    width: calc(33.3333333333% - 4.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp64 > * {
    width: calc(33.3333333333% - 4.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp65 > * {
    width: calc(33.3333333333% - 4.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp66 > * {
    width: calc(33.3333333333% - 4.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp67 > * {
    width: calc(33.3333333333% - 4.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp68 > * {
    width: calc(33.3333333333% - 4.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp69 > * {
    width: calc(33.3333333333% - 4.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp70 > * {
    width: calc(33.3333333333% - 5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp71 > * {
    width: calc(33.3333333333% - 5.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp72 > * {
    width: calc(33.3333333333% - 5.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp73 > * {
    width: calc(33.3333333333% - 5.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp74 > * {
    width: calc(33.3333333333% - 5.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp75 > * {
    width: calc(33.3333333333% - 5.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp76 > * {
    width: calc(33.3333333333% - 5.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp77 > * {
    width: calc(33.3333333333% - 5.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp78 > * {
    width: calc(33.3333333333% - 5.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp79 > * {
    width: calc(33.3333333333% - 5.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp80 > * {
    width: calc(33.3333333333% - 5.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp81 > * {
    width: calc(33.3333333333% - 5.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp82 > * {
    width: calc(33.3333333333% - 5.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp83 > * {
    width: calc(33.3333333333% - 5.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp84 > * {
    width: calc(33.3333333333% - 6rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp85 > * {
    width: calc(33.3333333333% - 6.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp86 > * {
    width: calc(33.3333333333% - 6.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp87 > * {
    width: calc(33.3333333333% - 6.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp88 > * {
    width: calc(33.3333333333% - 6.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp89 > * {
    width: calc(33.3333333333% - 6.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp90 > * {
    width: calc(33.3333333333% - 6.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp91 > * {
    width: calc(33.3333333333% - 6.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp92 > * {
    width: calc(33.3333333333% - 6.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp93 > * {
    width: calc(33.3333333333% - 6.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp94 > * {
    width: calc(33.3333333333% - 6.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp95 > * {
    width: calc(33.3333333333% - 6.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp96 > * {
    width: calc(33.3333333333% - 6.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp97 > * {
    width: calc(33.3333333333% - 6.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp98 > * {
    width: calc(33.3333333333% - 7rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp99 > * {
    width: calc(33.3333333333% - 7.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp100 > * {
    width: calc(33.3333333333% - 7.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp101 > * {
    width: calc(33.3333333333% - 7.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp102 > * {
    width: calc(33.3333333333% - 7.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp103 > * {
    width: calc(33.3333333333% - 7.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp104 > * {
    width: calc(33.3333333333% - 7.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp105 > * {
    width: calc(33.3333333333% - 7.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp106 > * {
    width: calc(33.3333333333% - 7.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp107 > * {
    width: calc(33.3333333333% - 7.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp108 > * {
    width: calc(33.3333333333% - 7.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp109 > * {
    width: calc(33.3333333333% - 7.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp110 > * {
    width: calc(33.3333333333% - 7.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp111 > * {
    width: calc(33.3333333333% - 7.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp112 > * {
    width: calc(33.3333333333% - 8rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp113 > * {
    width: calc(33.3333333333% - 8.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp114 > * {
    width: calc(33.3333333333% - 8.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp115 > * {
    width: calc(33.3333333333% - 8.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp116 > * {
    width: calc(33.3333333333% - 8.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp117 > * {
    width: calc(33.3333333333% - 8.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp118 > * {
    width: calc(33.3333333333% - 8.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp119 > * {
    width: calc(33.3333333333% - 8.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp120 > * {
    width: calc(33.3333333333% - 8.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp121 > * {
    width: calc(33.3333333333% - 8.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp122 > * {
    width: calc(33.3333333333% - 8.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp123 > * {
    width: calc(33.3333333333% - 8.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp124 > * {
    width: calc(33.3333333333% - 8.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp125 > * {
    width: calc(33.3333333333% - 8.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp126 > * {
    width: calc(33.3333333333% - 9rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp127 > * {
    width: calc(33.3333333333% - 9.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp128 > * {
    width: calc(33.3333333333% - 9.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp129 > * {
    width: calc(33.3333333333% - 9.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp130 > * {
    width: calc(33.3333333333% - 9.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp131 > * {
    width: calc(33.3333333333% - 9.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp132 > * {
    width: calc(33.3333333333% - 9.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp133 > * {
    width: calc(33.3333333333% - 9.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp134 > * {
    width: calc(33.3333333333% - 9.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp135 > * {
    width: calc(33.3333333333% - 9.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp136 > * {
    width: calc(33.3333333333% - 9.7142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp137 > * {
    width: calc(33.3333333333% - 9.7857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp138 > * {
    width: calc(33.3333333333% - 9.8571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp139 > * {
    width: calc(33.3333333333% - 9.9285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp140 > * {
    width: calc(33.3333333333% - 10rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp141 > * {
    width: calc(33.3333333333% - 10.0714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp142 > * {
    width: calc(33.3333333333% - 10.1428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp143 > * {
    width: calc(33.3333333333% - 10.2142857143rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp144 > * {
    width: calc(33.3333333333% - 10.2857142857rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp145 > * {
    width: calc(33.3333333333% - 10.3571428571rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp146 > * {
    width: calc(33.3333333333% - 10.4285714286rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp147 > * {
    width: calc(33.3333333333% - 10.5rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp148 > * {
    width: calc(33.3333333333% - 10.5714285714rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp149 > * {
    width: calc(33.3333333333% - 10.6428571429rem / 3 * 2);
  }
  .flex-sp-col3.gap-sp150 > * {
    width: calc(33.3333333333% - 10.7142857143rem / 3 * 2);
  }
  .flex-sp-col4 {
    flex-wrap: wrap;
  }
  .flex-sp-col4 > * {
    width: 25%;
  }
  .flex-sp-col4.gap1 > * {
    width: calc(25% - 0.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap2 > * {
    width: calc(25% - 0.125rem / 4 * 3);
  }
  .flex-sp-col4.gap3 > * {
    width: calc(25% - 0.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap4 > * {
    width: calc(25% - 0.25rem / 4 * 3);
  }
  .flex-sp-col4.gap5 > * {
    width: calc(25% - 0.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap6 > * {
    width: calc(25% - 0.375rem / 4 * 3);
  }
  .flex-sp-col4.gap7 > * {
    width: calc(25% - 0.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap8 > * {
    width: calc(25% - 0.5rem / 4 * 3);
  }
  .flex-sp-col4.gap9 > * {
    width: calc(25% - 0.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap10 > * {
    width: calc(25% - 0.625rem / 4 * 3);
  }
  .flex-sp-col4.gap11 > * {
    width: calc(25% - 0.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap12 > * {
    width: calc(25% - 0.75rem / 4 * 3);
  }
  .flex-sp-col4.gap13 > * {
    width: calc(25% - 0.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap14 > * {
    width: calc(25% - 0.875rem / 4 * 3);
  }
  .flex-sp-col4.gap15 > * {
    width: calc(25% - 0.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap16 > * {
    width: calc(25% - 1rem / 4 * 3);
  }
  .flex-sp-col4.gap17 > * {
    width: calc(25% - 1.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap18 > * {
    width: calc(25% - 1.125rem / 4 * 3);
  }
  .flex-sp-col4.gap19 > * {
    width: calc(25% - 1.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap20 > * {
    width: calc(25% - 1.25rem / 4 * 3);
  }
  .flex-sp-col4.gap21 > * {
    width: calc(25% - 1.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap22 > * {
    width: calc(25% - 1.375rem / 4 * 3);
  }
  .flex-sp-col4.gap23 > * {
    width: calc(25% - 1.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap24 > * {
    width: calc(25% - 1.5rem / 4 * 3);
  }
  .flex-sp-col4.gap25 > * {
    width: calc(25% - 1.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap26 > * {
    width: calc(25% - 1.625rem / 4 * 3);
  }
  .flex-sp-col4.gap27 > * {
    width: calc(25% - 1.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap28 > * {
    width: calc(25% - 1.75rem / 4 * 3);
  }
  .flex-sp-col4.gap29 > * {
    width: calc(25% - 1.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap30 > * {
    width: calc(25% - 1.875rem / 4 * 3);
  }
  .flex-sp-col4.gap31 > * {
    width: calc(25% - 1.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap32 > * {
    width: calc(25% - 2rem / 4 * 3);
  }
  .flex-sp-col4.gap33 > * {
    width: calc(25% - 2.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap34 > * {
    width: calc(25% - 2.125rem / 4 * 3);
  }
  .flex-sp-col4.gap35 > * {
    width: calc(25% - 2.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap36 > * {
    width: calc(25% - 2.25rem / 4 * 3);
  }
  .flex-sp-col4.gap37 > * {
    width: calc(25% - 2.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap38 > * {
    width: calc(25% - 2.375rem / 4 * 3);
  }
  .flex-sp-col4.gap39 > * {
    width: calc(25% - 2.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap40 > * {
    width: calc(25% - 2.5rem / 4 * 3);
  }
  .flex-sp-col4.gap41 > * {
    width: calc(25% - 2.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap42 > * {
    width: calc(25% - 2.625rem / 4 * 3);
  }
  .flex-sp-col4.gap43 > * {
    width: calc(25% - 2.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap44 > * {
    width: calc(25% - 2.75rem / 4 * 3);
  }
  .flex-sp-col4.gap45 > * {
    width: calc(25% - 2.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap46 > * {
    width: calc(25% - 2.875rem / 4 * 3);
  }
  .flex-sp-col4.gap47 > * {
    width: calc(25% - 2.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap48 > * {
    width: calc(25% - 3rem / 4 * 3);
  }
  .flex-sp-col4.gap49 > * {
    width: calc(25% - 3.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap50 > * {
    width: calc(25% - 3.125rem / 4 * 3);
  }
  .flex-sp-col4.gap51 > * {
    width: calc(25% - 3.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap52 > * {
    width: calc(25% - 3.25rem / 4 * 3);
  }
  .flex-sp-col4.gap53 > * {
    width: calc(25% - 3.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap54 > * {
    width: calc(25% - 3.375rem / 4 * 3);
  }
  .flex-sp-col4.gap55 > * {
    width: calc(25% - 3.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap56 > * {
    width: calc(25% - 3.5rem / 4 * 3);
  }
  .flex-sp-col4.gap57 > * {
    width: calc(25% - 3.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap58 > * {
    width: calc(25% - 3.625rem / 4 * 3);
  }
  .flex-sp-col4.gap59 > * {
    width: calc(25% - 3.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap60 > * {
    width: calc(25% - 3.75rem / 4 * 3);
  }
  .flex-sp-col4.gap61 > * {
    width: calc(25% - 3.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap62 > * {
    width: calc(25% - 3.875rem / 4 * 3);
  }
  .flex-sp-col4.gap63 > * {
    width: calc(25% - 3.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap64 > * {
    width: calc(25% - 4rem / 4 * 3);
  }
  .flex-sp-col4.gap65 > * {
    width: calc(25% - 4.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap66 > * {
    width: calc(25% - 4.125rem / 4 * 3);
  }
  .flex-sp-col4.gap67 > * {
    width: calc(25% - 4.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap68 > * {
    width: calc(25% - 4.25rem / 4 * 3);
  }
  .flex-sp-col4.gap69 > * {
    width: calc(25% - 4.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap70 > * {
    width: calc(25% - 4.375rem / 4 * 3);
  }
  .flex-sp-col4.gap71 > * {
    width: calc(25% - 4.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap72 > * {
    width: calc(25% - 4.5rem / 4 * 3);
  }
  .flex-sp-col4.gap73 > * {
    width: calc(25% - 4.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap74 > * {
    width: calc(25% - 4.625rem / 4 * 3);
  }
  .flex-sp-col4.gap75 > * {
    width: calc(25% - 4.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap76 > * {
    width: calc(25% - 4.75rem / 4 * 3);
  }
  .flex-sp-col4.gap77 > * {
    width: calc(25% - 4.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap78 > * {
    width: calc(25% - 4.875rem / 4 * 3);
  }
  .flex-sp-col4.gap79 > * {
    width: calc(25% - 4.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap80 > * {
    width: calc(25% - 5rem / 4 * 3);
  }
  .flex-sp-col4.gap81 > * {
    width: calc(25% - 5.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap82 > * {
    width: calc(25% - 5.125rem / 4 * 3);
  }
  .flex-sp-col4.gap83 > * {
    width: calc(25% - 5.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap84 > * {
    width: calc(25% - 5.25rem / 4 * 3);
  }
  .flex-sp-col4.gap85 > * {
    width: calc(25% - 5.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap86 > * {
    width: calc(25% - 5.375rem / 4 * 3);
  }
  .flex-sp-col4.gap87 > * {
    width: calc(25% - 5.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap88 > * {
    width: calc(25% - 5.5rem / 4 * 3);
  }
  .flex-sp-col4.gap89 > * {
    width: calc(25% - 5.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap90 > * {
    width: calc(25% - 5.625rem / 4 * 3);
  }
  .flex-sp-col4.gap91 > * {
    width: calc(25% - 5.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap92 > * {
    width: calc(25% - 5.75rem / 4 * 3);
  }
  .flex-sp-col4.gap93 > * {
    width: calc(25% - 5.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap94 > * {
    width: calc(25% - 5.875rem / 4 * 3);
  }
  .flex-sp-col4.gap95 > * {
    width: calc(25% - 5.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap96 > * {
    width: calc(25% - 6rem / 4 * 3);
  }
  .flex-sp-col4.gap97 > * {
    width: calc(25% - 6.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap98 > * {
    width: calc(25% - 6.125rem / 4 * 3);
  }
  .flex-sp-col4.gap99 > * {
    width: calc(25% - 6.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap100 > * {
    width: calc(25% - 6.25rem / 4 * 3);
  }
  .flex-sp-col4.gap101 > * {
    width: calc(25% - 6.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap102 > * {
    width: calc(25% - 6.375rem / 4 * 3);
  }
  .flex-sp-col4.gap103 > * {
    width: calc(25% - 6.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap104 > * {
    width: calc(25% - 6.5rem / 4 * 3);
  }
  .flex-sp-col4.gap105 > * {
    width: calc(25% - 6.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap106 > * {
    width: calc(25% - 6.625rem / 4 * 3);
  }
  .flex-sp-col4.gap107 > * {
    width: calc(25% - 6.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap108 > * {
    width: calc(25% - 6.75rem / 4 * 3);
  }
  .flex-sp-col4.gap109 > * {
    width: calc(25% - 6.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap110 > * {
    width: calc(25% - 6.875rem / 4 * 3);
  }
  .flex-sp-col4.gap111 > * {
    width: calc(25% - 6.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap112 > * {
    width: calc(25% - 7rem / 4 * 3);
  }
  .flex-sp-col4.gap113 > * {
    width: calc(25% - 7.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap114 > * {
    width: calc(25% - 7.125rem / 4 * 3);
  }
  .flex-sp-col4.gap115 > * {
    width: calc(25% - 7.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap116 > * {
    width: calc(25% - 7.25rem / 4 * 3);
  }
  .flex-sp-col4.gap117 > * {
    width: calc(25% - 7.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap118 > * {
    width: calc(25% - 7.375rem / 4 * 3);
  }
  .flex-sp-col4.gap119 > * {
    width: calc(25% - 7.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap120 > * {
    width: calc(25% - 7.5rem / 4 * 3);
  }
  .flex-sp-col4.gap121 > * {
    width: calc(25% - 7.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap122 > * {
    width: calc(25% - 7.625rem / 4 * 3);
  }
  .flex-sp-col4.gap123 > * {
    width: calc(25% - 7.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap124 > * {
    width: calc(25% - 7.75rem / 4 * 3);
  }
  .flex-sp-col4.gap125 > * {
    width: calc(25% - 7.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap126 > * {
    width: calc(25% - 7.875rem / 4 * 3);
  }
  .flex-sp-col4.gap127 > * {
    width: calc(25% - 7.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap128 > * {
    width: calc(25% - 8rem / 4 * 3);
  }
  .flex-sp-col4.gap129 > * {
    width: calc(25% - 8.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap130 > * {
    width: calc(25% - 8.125rem / 4 * 3);
  }
  .flex-sp-col4.gap131 > * {
    width: calc(25% - 8.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap132 > * {
    width: calc(25% - 8.25rem / 4 * 3);
  }
  .flex-sp-col4.gap133 > * {
    width: calc(25% - 8.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap134 > * {
    width: calc(25% - 8.375rem / 4 * 3);
  }
  .flex-sp-col4.gap135 > * {
    width: calc(25% - 8.4375rem / 4 * 3);
  }
  .flex-sp-col4.gap136 > * {
    width: calc(25% - 8.5rem / 4 * 3);
  }
  .flex-sp-col4.gap137 > * {
    width: calc(25% - 8.5625rem / 4 * 3);
  }
  .flex-sp-col4.gap138 > * {
    width: calc(25% - 8.625rem / 4 * 3);
  }
  .flex-sp-col4.gap139 > * {
    width: calc(25% - 8.6875rem / 4 * 3);
  }
  .flex-sp-col4.gap140 > * {
    width: calc(25% - 8.75rem / 4 * 3);
  }
  .flex-sp-col4.gap141 > * {
    width: calc(25% - 8.8125rem / 4 * 3);
  }
  .flex-sp-col4.gap142 > * {
    width: calc(25% - 8.875rem / 4 * 3);
  }
  .flex-sp-col4.gap143 > * {
    width: calc(25% - 8.9375rem / 4 * 3);
  }
  .flex-sp-col4.gap144 > * {
    width: calc(25% - 9rem / 4 * 3);
  }
  .flex-sp-col4.gap145 > * {
    width: calc(25% - 9.0625rem / 4 * 3);
  }
  .flex-sp-col4.gap146 > * {
    width: calc(25% - 9.125rem / 4 * 3);
  }
  .flex-sp-col4.gap147 > * {
    width: calc(25% - 9.1875rem / 4 * 3);
  }
  .flex-sp-col4.gap148 > * {
    width: calc(25% - 9.25rem / 4 * 3);
  }
  .flex-sp-col4.gap149 > * {
    width: calc(25% - 9.3125rem / 4 * 3);
  }
  .flex-sp-col4.gap150 > * {
    width: calc(25% - 9.375rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp1 > * {
    width: calc(25% - 0.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp2 > * {
    width: calc(25% - 0.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp3 > * {
    width: calc(25% - 0.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp4 > * {
    width: calc(25% - 0.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp5 > * {
    width: calc(25% - 0.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp6 > * {
    width: calc(25% - 0.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp7 > * {
    width: calc(25% - 0.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp8 > * {
    width: calc(25% - 0.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp9 > * {
    width: calc(25% - 0.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp10 > * {
    width: calc(25% - 0.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp11 > * {
    width: calc(25% - 0.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp12 > * {
    width: calc(25% - 0.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp13 > * {
    width: calc(25% - 0.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp14 > * {
    width: calc(25% - 1rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp15 > * {
    width: calc(25% - 1.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp16 > * {
    width: calc(25% - 1.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp17 > * {
    width: calc(25% - 1.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp18 > * {
    width: calc(25% - 1.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp19 > * {
    width: calc(25% - 1.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp20 > * {
    width: calc(25% - 1.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp21 > * {
    width: calc(25% - 1.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp22 > * {
    width: calc(25% - 1.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp23 > * {
    width: calc(25% - 1.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp24 > * {
    width: calc(25% - 1.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp25 > * {
    width: calc(25% - 1.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp26 > * {
    width: calc(25% - 1.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp27 > * {
    width: calc(25% - 1.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp28 > * {
    width: calc(25% - 2rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp29 > * {
    width: calc(25% - 2.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp30 > * {
    width: calc(25% - 2.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp31 > * {
    width: calc(25% - 2.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp32 > * {
    width: calc(25% - 2.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp33 > * {
    width: calc(25% - 2.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp34 > * {
    width: calc(25% - 2.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp35 > * {
    width: calc(25% - 2.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp36 > * {
    width: calc(25% - 2.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp37 > * {
    width: calc(25% - 2.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp38 > * {
    width: calc(25% - 2.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp39 > * {
    width: calc(25% - 2.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp40 > * {
    width: calc(25% - 2.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp41 > * {
    width: calc(25% - 2.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp42 > * {
    width: calc(25% - 3rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp43 > * {
    width: calc(25% - 3.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp44 > * {
    width: calc(25% - 3.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp45 > * {
    width: calc(25% - 3.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp46 > * {
    width: calc(25% - 3.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp47 > * {
    width: calc(25% - 3.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp48 > * {
    width: calc(25% - 3.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp49 > * {
    width: calc(25% - 3.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp50 > * {
    width: calc(25% - 3.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp51 > * {
    width: calc(25% - 3.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp52 > * {
    width: calc(25% - 3.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp53 > * {
    width: calc(25% - 3.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp54 > * {
    width: calc(25% - 3.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp55 > * {
    width: calc(25% - 3.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp56 > * {
    width: calc(25% - 4rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp57 > * {
    width: calc(25% - 4.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp58 > * {
    width: calc(25% - 4.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp59 > * {
    width: calc(25% - 4.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp60 > * {
    width: calc(25% - 4.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp61 > * {
    width: calc(25% - 4.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp62 > * {
    width: calc(25% - 4.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp63 > * {
    width: calc(25% - 4.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp64 > * {
    width: calc(25% - 4.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp65 > * {
    width: calc(25% - 4.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp66 > * {
    width: calc(25% - 4.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp67 > * {
    width: calc(25% - 4.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp68 > * {
    width: calc(25% - 4.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp69 > * {
    width: calc(25% - 4.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp70 > * {
    width: calc(25% - 5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp71 > * {
    width: calc(25% - 5.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp72 > * {
    width: calc(25% - 5.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp73 > * {
    width: calc(25% - 5.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp74 > * {
    width: calc(25% - 5.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp75 > * {
    width: calc(25% - 5.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp76 > * {
    width: calc(25% - 5.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp77 > * {
    width: calc(25% - 5.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp78 > * {
    width: calc(25% - 5.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp79 > * {
    width: calc(25% - 5.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp80 > * {
    width: calc(25% - 5.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp81 > * {
    width: calc(25% - 5.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp82 > * {
    width: calc(25% - 5.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp83 > * {
    width: calc(25% - 5.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp84 > * {
    width: calc(25% - 6rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp85 > * {
    width: calc(25% - 6.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp86 > * {
    width: calc(25% - 6.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp87 > * {
    width: calc(25% - 6.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp88 > * {
    width: calc(25% - 6.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp89 > * {
    width: calc(25% - 6.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp90 > * {
    width: calc(25% - 6.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp91 > * {
    width: calc(25% - 6.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp92 > * {
    width: calc(25% - 6.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp93 > * {
    width: calc(25% - 6.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp94 > * {
    width: calc(25% - 6.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp95 > * {
    width: calc(25% - 6.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp96 > * {
    width: calc(25% - 6.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp97 > * {
    width: calc(25% - 6.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp98 > * {
    width: calc(25% - 7rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp99 > * {
    width: calc(25% - 7.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp100 > * {
    width: calc(25% - 7.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp101 > * {
    width: calc(25% - 7.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp102 > * {
    width: calc(25% - 7.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp103 > * {
    width: calc(25% - 7.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp104 > * {
    width: calc(25% - 7.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp105 > * {
    width: calc(25% - 7.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp106 > * {
    width: calc(25% - 7.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp107 > * {
    width: calc(25% - 7.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp108 > * {
    width: calc(25% - 7.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp109 > * {
    width: calc(25% - 7.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp110 > * {
    width: calc(25% - 7.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp111 > * {
    width: calc(25% - 7.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp112 > * {
    width: calc(25% - 8rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp113 > * {
    width: calc(25% - 8.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp114 > * {
    width: calc(25% - 8.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp115 > * {
    width: calc(25% - 8.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp116 > * {
    width: calc(25% - 8.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp117 > * {
    width: calc(25% - 8.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp118 > * {
    width: calc(25% - 8.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp119 > * {
    width: calc(25% - 8.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp120 > * {
    width: calc(25% - 8.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp121 > * {
    width: calc(25% - 8.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp122 > * {
    width: calc(25% - 8.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp123 > * {
    width: calc(25% - 8.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp124 > * {
    width: calc(25% - 8.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp125 > * {
    width: calc(25% - 8.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp126 > * {
    width: calc(25% - 9rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp127 > * {
    width: calc(25% - 9.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp128 > * {
    width: calc(25% - 9.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp129 > * {
    width: calc(25% - 9.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp130 > * {
    width: calc(25% - 9.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp131 > * {
    width: calc(25% - 9.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp132 > * {
    width: calc(25% - 9.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp133 > * {
    width: calc(25% - 9.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp134 > * {
    width: calc(25% - 9.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp135 > * {
    width: calc(25% - 9.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp136 > * {
    width: calc(25% - 9.7142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp137 > * {
    width: calc(25% - 9.7857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp138 > * {
    width: calc(25% - 9.8571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp139 > * {
    width: calc(25% - 9.9285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp140 > * {
    width: calc(25% - 10rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp141 > * {
    width: calc(25% - 10.0714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp142 > * {
    width: calc(25% - 10.1428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp143 > * {
    width: calc(25% - 10.2142857143rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp144 > * {
    width: calc(25% - 10.2857142857rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp145 > * {
    width: calc(25% - 10.3571428571rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp146 > * {
    width: calc(25% - 10.4285714286rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp147 > * {
    width: calc(25% - 10.5rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp148 > * {
    width: calc(25% - 10.5714285714rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp149 > * {
    width: calc(25% - 10.6428571429rem / 4 * 3);
  }
  .flex-sp-col4.gap-sp150 > * {
    width: calc(25% - 10.7142857143rem / 4 * 3);
  }
  .flex-sp-col5 {
    flex-wrap: wrap;
  }
  .flex-sp-col5 > * {
    width: 20%;
  }
  .flex-sp-col5.gap1 > * {
    width: calc(20% - 0.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap2 > * {
    width: calc(20% - 0.125rem / 5 * 4);
  }
  .flex-sp-col5.gap3 > * {
    width: calc(20% - 0.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap4 > * {
    width: calc(20% - 0.25rem / 5 * 4);
  }
  .flex-sp-col5.gap5 > * {
    width: calc(20% - 0.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap6 > * {
    width: calc(20% - 0.375rem / 5 * 4);
  }
  .flex-sp-col5.gap7 > * {
    width: calc(20% - 0.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap8 > * {
    width: calc(20% - 0.5rem / 5 * 4);
  }
  .flex-sp-col5.gap9 > * {
    width: calc(20% - 0.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap10 > * {
    width: calc(20% - 0.625rem / 5 * 4);
  }
  .flex-sp-col5.gap11 > * {
    width: calc(20% - 0.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap12 > * {
    width: calc(20% - 0.75rem / 5 * 4);
  }
  .flex-sp-col5.gap13 > * {
    width: calc(20% - 0.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap14 > * {
    width: calc(20% - 0.875rem / 5 * 4);
  }
  .flex-sp-col5.gap15 > * {
    width: calc(20% - 0.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap16 > * {
    width: calc(20% - 1rem / 5 * 4);
  }
  .flex-sp-col5.gap17 > * {
    width: calc(20% - 1.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap18 > * {
    width: calc(20% - 1.125rem / 5 * 4);
  }
  .flex-sp-col5.gap19 > * {
    width: calc(20% - 1.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap20 > * {
    width: calc(20% - 1.25rem / 5 * 4);
  }
  .flex-sp-col5.gap21 > * {
    width: calc(20% - 1.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap22 > * {
    width: calc(20% - 1.375rem / 5 * 4);
  }
  .flex-sp-col5.gap23 > * {
    width: calc(20% - 1.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap24 > * {
    width: calc(20% - 1.5rem / 5 * 4);
  }
  .flex-sp-col5.gap25 > * {
    width: calc(20% - 1.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap26 > * {
    width: calc(20% - 1.625rem / 5 * 4);
  }
  .flex-sp-col5.gap27 > * {
    width: calc(20% - 1.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap28 > * {
    width: calc(20% - 1.75rem / 5 * 4);
  }
  .flex-sp-col5.gap29 > * {
    width: calc(20% - 1.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap30 > * {
    width: calc(20% - 1.875rem / 5 * 4);
  }
  .flex-sp-col5.gap31 > * {
    width: calc(20% - 1.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap32 > * {
    width: calc(20% - 2rem / 5 * 4);
  }
  .flex-sp-col5.gap33 > * {
    width: calc(20% - 2.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap34 > * {
    width: calc(20% - 2.125rem / 5 * 4);
  }
  .flex-sp-col5.gap35 > * {
    width: calc(20% - 2.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap36 > * {
    width: calc(20% - 2.25rem / 5 * 4);
  }
  .flex-sp-col5.gap37 > * {
    width: calc(20% - 2.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap38 > * {
    width: calc(20% - 2.375rem / 5 * 4);
  }
  .flex-sp-col5.gap39 > * {
    width: calc(20% - 2.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap40 > * {
    width: calc(20% - 2.5rem / 5 * 4);
  }
  .flex-sp-col5.gap41 > * {
    width: calc(20% - 2.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap42 > * {
    width: calc(20% - 2.625rem / 5 * 4);
  }
  .flex-sp-col5.gap43 > * {
    width: calc(20% - 2.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap44 > * {
    width: calc(20% - 2.75rem / 5 * 4);
  }
  .flex-sp-col5.gap45 > * {
    width: calc(20% - 2.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap46 > * {
    width: calc(20% - 2.875rem / 5 * 4);
  }
  .flex-sp-col5.gap47 > * {
    width: calc(20% - 2.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap48 > * {
    width: calc(20% - 3rem / 5 * 4);
  }
  .flex-sp-col5.gap49 > * {
    width: calc(20% - 3.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap50 > * {
    width: calc(20% - 3.125rem / 5 * 4);
  }
  .flex-sp-col5.gap51 > * {
    width: calc(20% - 3.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap52 > * {
    width: calc(20% - 3.25rem / 5 * 4);
  }
  .flex-sp-col5.gap53 > * {
    width: calc(20% - 3.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap54 > * {
    width: calc(20% - 3.375rem / 5 * 4);
  }
  .flex-sp-col5.gap55 > * {
    width: calc(20% - 3.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap56 > * {
    width: calc(20% - 3.5rem / 5 * 4);
  }
  .flex-sp-col5.gap57 > * {
    width: calc(20% - 3.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap58 > * {
    width: calc(20% - 3.625rem / 5 * 4);
  }
  .flex-sp-col5.gap59 > * {
    width: calc(20% - 3.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap60 > * {
    width: calc(20% - 3.75rem / 5 * 4);
  }
  .flex-sp-col5.gap61 > * {
    width: calc(20% - 3.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap62 > * {
    width: calc(20% - 3.875rem / 5 * 4);
  }
  .flex-sp-col5.gap63 > * {
    width: calc(20% - 3.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap64 > * {
    width: calc(20% - 4rem / 5 * 4);
  }
  .flex-sp-col5.gap65 > * {
    width: calc(20% - 4.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap66 > * {
    width: calc(20% - 4.125rem / 5 * 4);
  }
  .flex-sp-col5.gap67 > * {
    width: calc(20% - 4.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap68 > * {
    width: calc(20% - 4.25rem / 5 * 4);
  }
  .flex-sp-col5.gap69 > * {
    width: calc(20% - 4.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap70 > * {
    width: calc(20% - 4.375rem / 5 * 4);
  }
  .flex-sp-col5.gap71 > * {
    width: calc(20% - 4.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap72 > * {
    width: calc(20% - 4.5rem / 5 * 4);
  }
  .flex-sp-col5.gap73 > * {
    width: calc(20% - 4.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap74 > * {
    width: calc(20% - 4.625rem / 5 * 4);
  }
  .flex-sp-col5.gap75 > * {
    width: calc(20% - 4.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap76 > * {
    width: calc(20% - 4.75rem / 5 * 4);
  }
  .flex-sp-col5.gap77 > * {
    width: calc(20% - 4.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap78 > * {
    width: calc(20% - 4.875rem / 5 * 4);
  }
  .flex-sp-col5.gap79 > * {
    width: calc(20% - 4.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap80 > * {
    width: calc(20% - 5rem / 5 * 4);
  }
  .flex-sp-col5.gap81 > * {
    width: calc(20% - 5.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap82 > * {
    width: calc(20% - 5.125rem / 5 * 4);
  }
  .flex-sp-col5.gap83 > * {
    width: calc(20% - 5.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap84 > * {
    width: calc(20% - 5.25rem / 5 * 4);
  }
  .flex-sp-col5.gap85 > * {
    width: calc(20% - 5.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap86 > * {
    width: calc(20% - 5.375rem / 5 * 4);
  }
  .flex-sp-col5.gap87 > * {
    width: calc(20% - 5.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap88 > * {
    width: calc(20% - 5.5rem / 5 * 4);
  }
  .flex-sp-col5.gap89 > * {
    width: calc(20% - 5.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap90 > * {
    width: calc(20% - 5.625rem / 5 * 4);
  }
  .flex-sp-col5.gap91 > * {
    width: calc(20% - 5.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap92 > * {
    width: calc(20% - 5.75rem / 5 * 4);
  }
  .flex-sp-col5.gap93 > * {
    width: calc(20% - 5.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap94 > * {
    width: calc(20% - 5.875rem / 5 * 4);
  }
  .flex-sp-col5.gap95 > * {
    width: calc(20% - 5.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap96 > * {
    width: calc(20% - 6rem / 5 * 4);
  }
  .flex-sp-col5.gap97 > * {
    width: calc(20% - 6.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap98 > * {
    width: calc(20% - 6.125rem / 5 * 4);
  }
  .flex-sp-col5.gap99 > * {
    width: calc(20% - 6.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap100 > * {
    width: calc(20% - 6.25rem / 5 * 4);
  }
  .flex-sp-col5.gap101 > * {
    width: calc(20% - 6.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap102 > * {
    width: calc(20% - 6.375rem / 5 * 4);
  }
  .flex-sp-col5.gap103 > * {
    width: calc(20% - 6.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap104 > * {
    width: calc(20% - 6.5rem / 5 * 4);
  }
  .flex-sp-col5.gap105 > * {
    width: calc(20% - 6.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap106 > * {
    width: calc(20% - 6.625rem / 5 * 4);
  }
  .flex-sp-col5.gap107 > * {
    width: calc(20% - 6.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap108 > * {
    width: calc(20% - 6.75rem / 5 * 4);
  }
  .flex-sp-col5.gap109 > * {
    width: calc(20% - 6.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap110 > * {
    width: calc(20% - 6.875rem / 5 * 4);
  }
  .flex-sp-col5.gap111 > * {
    width: calc(20% - 6.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap112 > * {
    width: calc(20% - 7rem / 5 * 4);
  }
  .flex-sp-col5.gap113 > * {
    width: calc(20% - 7.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap114 > * {
    width: calc(20% - 7.125rem / 5 * 4);
  }
  .flex-sp-col5.gap115 > * {
    width: calc(20% - 7.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap116 > * {
    width: calc(20% - 7.25rem / 5 * 4);
  }
  .flex-sp-col5.gap117 > * {
    width: calc(20% - 7.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap118 > * {
    width: calc(20% - 7.375rem / 5 * 4);
  }
  .flex-sp-col5.gap119 > * {
    width: calc(20% - 7.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap120 > * {
    width: calc(20% - 7.5rem / 5 * 4);
  }
  .flex-sp-col5.gap121 > * {
    width: calc(20% - 7.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap122 > * {
    width: calc(20% - 7.625rem / 5 * 4);
  }
  .flex-sp-col5.gap123 > * {
    width: calc(20% - 7.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap124 > * {
    width: calc(20% - 7.75rem / 5 * 4);
  }
  .flex-sp-col5.gap125 > * {
    width: calc(20% - 7.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap126 > * {
    width: calc(20% - 7.875rem / 5 * 4);
  }
  .flex-sp-col5.gap127 > * {
    width: calc(20% - 7.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap128 > * {
    width: calc(20% - 8rem / 5 * 4);
  }
  .flex-sp-col5.gap129 > * {
    width: calc(20% - 8.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap130 > * {
    width: calc(20% - 8.125rem / 5 * 4);
  }
  .flex-sp-col5.gap131 > * {
    width: calc(20% - 8.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap132 > * {
    width: calc(20% - 8.25rem / 5 * 4);
  }
  .flex-sp-col5.gap133 > * {
    width: calc(20% - 8.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap134 > * {
    width: calc(20% - 8.375rem / 5 * 4);
  }
  .flex-sp-col5.gap135 > * {
    width: calc(20% - 8.4375rem / 5 * 4);
  }
  .flex-sp-col5.gap136 > * {
    width: calc(20% - 8.5rem / 5 * 4);
  }
  .flex-sp-col5.gap137 > * {
    width: calc(20% - 8.5625rem / 5 * 4);
  }
  .flex-sp-col5.gap138 > * {
    width: calc(20% - 8.625rem / 5 * 4);
  }
  .flex-sp-col5.gap139 > * {
    width: calc(20% - 8.6875rem / 5 * 4);
  }
  .flex-sp-col5.gap140 > * {
    width: calc(20% - 8.75rem / 5 * 4);
  }
  .flex-sp-col5.gap141 > * {
    width: calc(20% - 8.8125rem / 5 * 4);
  }
  .flex-sp-col5.gap142 > * {
    width: calc(20% - 8.875rem / 5 * 4);
  }
  .flex-sp-col5.gap143 > * {
    width: calc(20% - 8.9375rem / 5 * 4);
  }
  .flex-sp-col5.gap144 > * {
    width: calc(20% - 9rem / 5 * 4);
  }
  .flex-sp-col5.gap145 > * {
    width: calc(20% - 9.0625rem / 5 * 4);
  }
  .flex-sp-col5.gap146 > * {
    width: calc(20% - 9.125rem / 5 * 4);
  }
  .flex-sp-col5.gap147 > * {
    width: calc(20% - 9.1875rem / 5 * 4);
  }
  .flex-sp-col5.gap148 > * {
    width: calc(20% - 9.25rem / 5 * 4);
  }
  .flex-sp-col5.gap149 > * {
    width: calc(20% - 9.3125rem / 5 * 4);
  }
  .flex-sp-col5.gap150 > * {
    width: calc(20% - 9.375rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp1 > * {
    width: calc(20% - 0.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp2 > * {
    width: calc(20% - 0.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp3 > * {
    width: calc(20% - 0.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp4 > * {
    width: calc(20% - 0.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp5 > * {
    width: calc(20% - 0.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp6 > * {
    width: calc(20% - 0.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp7 > * {
    width: calc(20% - 0.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp8 > * {
    width: calc(20% - 0.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp9 > * {
    width: calc(20% - 0.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp10 > * {
    width: calc(20% - 0.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp11 > * {
    width: calc(20% - 0.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp12 > * {
    width: calc(20% - 0.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp13 > * {
    width: calc(20% - 0.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp14 > * {
    width: calc(20% - 1rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp15 > * {
    width: calc(20% - 1.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp16 > * {
    width: calc(20% - 1.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp17 > * {
    width: calc(20% - 1.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp18 > * {
    width: calc(20% - 1.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp19 > * {
    width: calc(20% - 1.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp20 > * {
    width: calc(20% - 1.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp21 > * {
    width: calc(20% - 1.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp22 > * {
    width: calc(20% - 1.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp23 > * {
    width: calc(20% - 1.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp24 > * {
    width: calc(20% - 1.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp25 > * {
    width: calc(20% - 1.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp26 > * {
    width: calc(20% - 1.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp27 > * {
    width: calc(20% - 1.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp28 > * {
    width: calc(20% - 2rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp29 > * {
    width: calc(20% - 2.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp30 > * {
    width: calc(20% - 2.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp31 > * {
    width: calc(20% - 2.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp32 > * {
    width: calc(20% - 2.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp33 > * {
    width: calc(20% - 2.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp34 > * {
    width: calc(20% - 2.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp35 > * {
    width: calc(20% - 2.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp36 > * {
    width: calc(20% - 2.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp37 > * {
    width: calc(20% - 2.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp38 > * {
    width: calc(20% - 2.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp39 > * {
    width: calc(20% - 2.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp40 > * {
    width: calc(20% - 2.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp41 > * {
    width: calc(20% - 2.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp42 > * {
    width: calc(20% - 3rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp43 > * {
    width: calc(20% - 3.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp44 > * {
    width: calc(20% - 3.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp45 > * {
    width: calc(20% - 3.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp46 > * {
    width: calc(20% - 3.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp47 > * {
    width: calc(20% - 3.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp48 > * {
    width: calc(20% - 3.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp49 > * {
    width: calc(20% - 3.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp50 > * {
    width: calc(20% - 3.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp51 > * {
    width: calc(20% - 3.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp52 > * {
    width: calc(20% - 3.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp53 > * {
    width: calc(20% - 3.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp54 > * {
    width: calc(20% - 3.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp55 > * {
    width: calc(20% - 3.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp56 > * {
    width: calc(20% - 4rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp57 > * {
    width: calc(20% - 4.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp58 > * {
    width: calc(20% - 4.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp59 > * {
    width: calc(20% - 4.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp60 > * {
    width: calc(20% - 4.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp61 > * {
    width: calc(20% - 4.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp62 > * {
    width: calc(20% - 4.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp63 > * {
    width: calc(20% - 4.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp64 > * {
    width: calc(20% - 4.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp65 > * {
    width: calc(20% - 4.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp66 > * {
    width: calc(20% - 4.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp67 > * {
    width: calc(20% - 4.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp68 > * {
    width: calc(20% - 4.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp69 > * {
    width: calc(20% - 4.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp70 > * {
    width: calc(20% - 5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp71 > * {
    width: calc(20% - 5.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp72 > * {
    width: calc(20% - 5.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp73 > * {
    width: calc(20% - 5.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp74 > * {
    width: calc(20% - 5.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp75 > * {
    width: calc(20% - 5.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp76 > * {
    width: calc(20% - 5.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp77 > * {
    width: calc(20% - 5.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp78 > * {
    width: calc(20% - 5.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp79 > * {
    width: calc(20% - 5.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp80 > * {
    width: calc(20% - 5.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp81 > * {
    width: calc(20% - 5.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp82 > * {
    width: calc(20% - 5.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp83 > * {
    width: calc(20% - 5.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp84 > * {
    width: calc(20% - 6rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp85 > * {
    width: calc(20% - 6.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp86 > * {
    width: calc(20% - 6.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp87 > * {
    width: calc(20% - 6.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp88 > * {
    width: calc(20% - 6.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp89 > * {
    width: calc(20% - 6.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp90 > * {
    width: calc(20% - 6.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp91 > * {
    width: calc(20% - 6.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp92 > * {
    width: calc(20% - 6.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp93 > * {
    width: calc(20% - 6.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp94 > * {
    width: calc(20% - 6.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp95 > * {
    width: calc(20% - 6.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp96 > * {
    width: calc(20% - 6.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp97 > * {
    width: calc(20% - 6.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp98 > * {
    width: calc(20% - 7rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp99 > * {
    width: calc(20% - 7.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp100 > * {
    width: calc(20% - 7.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp101 > * {
    width: calc(20% - 7.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp102 > * {
    width: calc(20% - 7.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp103 > * {
    width: calc(20% - 7.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp104 > * {
    width: calc(20% - 7.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp105 > * {
    width: calc(20% - 7.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp106 > * {
    width: calc(20% - 7.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp107 > * {
    width: calc(20% - 7.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp108 > * {
    width: calc(20% - 7.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp109 > * {
    width: calc(20% - 7.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp110 > * {
    width: calc(20% - 7.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp111 > * {
    width: calc(20% - 7.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp112 > * {
    width: calc(20% - 8rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp113 > * {
    width: calc(20% - 8.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp114 > * {
    width: calc(20% - 8.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp115 > * {
    width: calc(20% - 8.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp116 > * {
    width: calc(20% - 8.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp117 > * {
    width: calc(20% - 8.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp118 > * {
    width: calc(20% - 8.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp119 > * {
    width: calc(20% - 8.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp120 > * {
    width: calc(20% - 8.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp121 > * {
    width: calc(20% - 8.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp122 > * {
    width: calc(20% - 8.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp123 > * {
    width: calc(20% - 8.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp124 > * {
    width: calc(20% - 8.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp125 > * {
    width: calc(20% - 8.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp126 > * {
    width: calc(20% - 9rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp127 > * {
    width: calc(20% - 9.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp128 > * {
    width: calc(20% - 9.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp129 > * {
    width: calc(20% - 9.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp130 > * {
    width: calc(20% - 9.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp131 > * {
    width: calc(20% - 9.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp132 > * {
    width: calc(20% - 9.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp133 > * {
    width: calc(20% - 9.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp134 > * {
    width: calc(20% - 9.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp135 > * {
    width: calc(20% - 9.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp136 > * {
    width: calc(20% - 9.7142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp137 > * {
    width: calc(20% - 9.7857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp138 > * {
    width: calc(20% - 9.8571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp139 > * {
    width: calc(20% - 9.9285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp140 > * {
    width: calc(20% - 10rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp141 > * {
    width: calc(20% - 10.0714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp142 > * {
    width: calc(20% - 10.1428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp143 > * {
    width: calc(20% - 10.2142857143rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp144 > * {
    width: calc(20% - 10.2857142857rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp145 > * {
    width: calc(20% - 10.3571428571rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp146 > * {
    width: calc(20% - 10.4285714286rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp147 > * {
    width: calc(20% - 10.5rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp148 > * {
    width: calc(20% - 10.5714285714rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp149 > * {
    width: calc(20% - 10.6428571429rem / 5 * 4);
  }
  .flex-sp-col5.gap-sp150 > * {
    width: calc(20% - 10.7142857143rem / 5 * 4);
  }
  .flex-sp-col6 {
    flex-wrap: wrap;
  }
  .flex-sp-col6 > * {
    width: 16.6666666667%;
  }
  .flex-sp-col6.gap1 > * {
    width: calc(16.6666666667% - 0.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap2 > * {
    width: calc(16.6666666667% - 0.125rem / 6 * 5);
  }
  .flex-sp-col6.gap3 > * {
    width: calc(16.6666666667% - 0.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap4 > * {
    width: calc(16.6666666667% - 0.25rem / 6 * 5);
  }
  .flex-sp-col6.gap5 > * {
    width: calc(16.6666666667% - 0.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap6 > * {
    width: calc(16.6666666667% - 0.375rem / 6 * 5);
  }
  .flex-sp-col6.gap7 > * {
    width: calc(16.6666666667% - 0.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap8 > * {
    width: calc(16.6666666667% - 0.5rem / 6 * 5);
  }
  .flex-sp-col6.gap9 > * {
    width: calc(16.6666666667% - 0.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap10 > * {
    width: calc(16.6666666667% - 0.625rem / 6 * 5);
  }
  .flex-sp-col6.gap11 > * {
    width: calc(16.6666666667% - 0.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap12 > * {
    width: calc(16.6666666667% - 0.75rem / 6 * 5);
  }
  .flex-sp-col6.gap13 > * {
    width: calc(16.6666666667% - 0.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap14 > * {
    width: calc(16.6666666667% - 0.875rem / 6 * 5);
  }
  .flex-sp-col6.gap15 > * {
    width: calc(16.6666666667% - 0.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap16 > * {
    width: calc(16.6666666667% - 1rem / 6 * 5);
  }
  .flex-sp-col6.gap17 > * {
    width: calc(16.6666666667% - 1.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap18 > * {
    width: calc(16.6666666667% - 1.125rem / 6 * 5);
  }
  .flex-sp-col6.gap19 > * {
    width: calc(16.6666666667% - 1.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap20 > * {
    width: calc(16.6666666667% - 1.25rem / 6 * 5);
  }
  .flex-sp-col6.gap21 > * {
    width: calc(16.6666666667% - 1.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap22 > * {
    width: calc(16.6666666667% - 1.375rem / 6 * 5);
  }
  .flex-sp-col6.gap23 > * {
    width: calc(16.6666666667% - 1.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap24 > * {
    width: calc(16.6666666667% - 1.5rem / 6 * 5);
  }
  .flex-sp-col6.gap25 > * {
    width: calc(16.6666666667% - 1.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap26 > * {
    width: calc(16.6666666667% - 1.625rem / 6 * 5);
  }
  .flex-sp-col6.gap27 > * {
    width: calc(16.6666666667% - 1.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap28 > * {
    width: calc(16.6666666667% - 1.75rem / 6 * 5);
  }
  .flex-sp-col6.gap29 > * {
    width: calc(16.6666666667% - 1.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap30 > * {
    width: calc(16.6666666667% - 1.875rem / 6 * 5);
  }
  .flex-sp-col6.gap31 > * {
    width: calc(16.6666666667% - 1.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap32 > * {
    width: calc(16.6666666667% - 2rem / 6 * 5);
  }
  .flex-sp-col6.gap33 > * {
    width: calc(16.6666666667% - 2.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap34 > * {
    width: calc(16.6666666667% - 2.125rem / 6 * 5);
  }
  .flex-sp-col6.gap35 > * {
    width: calc(16.6666666667% - 2.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap36 > * {
    width: calc(16.6666666667% - 2.25rem / 6 * 5);
  }
  .flex-sp-col6.gap37 > * {
    width: calc(16.6666666667% - 2.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap38 > * {
    width: calc(16.6666666667% - 2.375rem / 6 * 5);
  }
  .flex-sp-col6.gap39 > * {
    width: calc(16.6666666667% - 2.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap40 > * {
    width: calc(16.6666666667% - 2.5rem / 6 * 5);
  }
  .flex-sp-col6.gap41 > * {
    width: calc(16.6666666667% - 2.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap42 > * {
    width: calc(16.6666666667% - 2.625rem / 6 * 5);
  }
  .flex-sp-col6.gap43 > * {
    width: calc(16.6666666667% - 2.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap44 > * {
    width: calc(16.6666666667% - 2.75rem / 6 * 5);
  }
  .flex-sp-col6.gap45 > * {
    width: calc(16.6666666667% - 2.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap46 > * {
    width: calc(16.6666666667% - 2.875rem / 6 * 5);
  }
  .flex-sp-col6.gap47 > * {
    width: calc(16.6666666667% - 2.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap48 > * {
    width: calc(16.6666666667% - 3rem / 6 * 5);
  }
  .flex-sp-col6.gap49 > * {
    width: calc(16.6666666667% - 3.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap50 > * {
    width: calc(16.6666666667% - 3.125rem / 6 * 5);
  }
  .flex-sp-col6.gap51 > * {
    width: calc(16.6666666667% - 3.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap52 > * {
    width: calc(16.6666666667% - 3.25rem / 6 * 5);
  }
  .flex-sp-col6.gap53 > * {
    width: calc(16.6666666667% - 3.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap54 > * {
    width: calc(16.6666666667% - 3.375rem / 6 * 5);
  }
  .flex-sp-col6.gap55 > * {
    width: calc(16.6666666667% - 3.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap56 > * {
    width: calc(16.6666666667% - 3.5rem / 6 * 5);
  }
  .flex-sp-col6.gap57 > * {
    width: calc(16.6666666667% - 3.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap58 > * {
    width: calc(16.6666666667% - 3.625rem / 6 * 5);
  }
  .flex-sp-col6.gap59 > * {
    width: calc(16.6666666667% - 3.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap60 > * {
    width: calc(16.6666666667% - 3.75rem / 6 * 5);
  }
  .flex-sp-col6.gap61 > * {
    width: calc(16.6666666667% - 3.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap62 > * {
    width: calc(16.6666666667% - 3.875rem / 6 * 5);
  }
  .flex-sp-col6.gap63 > * {
    width: calc(16.6666666667% - 3.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap64 > * {
    width: calc(16.6666666667% - 4rem / 6 * 5);
  }
  .flex-sp-col6.gap65 > * {
    width: calc(16.6666666667% - 4.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap66 > * {
    width: calc(16.6666666667% - 4.125rem / 6 * 5);
  }
  .flex-sp-col6.gap67 > * {
    width: calc(16.6666666667% - 4.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap68 > * {
    width: calc(16.6666666667% - 4.25rem / 6 * 5);
  }
  .flex-sp-col6.gap69 > * {
    width: calc(16.6666666667% - 4.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap70 > * {
    width: calc(16.6666666667% - 4.375rem / 6 * 5);
  }
  .flex-sp-col6.gap71 > * {
    width: calc(16.6666666667% - 4.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap72 > * {
    width: calc(16.6666666667% - 4.5rem / 6 * 5);
  }
  .flex-sp-col6.gap73 > * {
    width: calc(16.6666666667% - 4.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap74 > * {
    width: calc(16.6666666667% - 4.625rem / 6 * 5);
  }
  .flex-sp-col6.gap75 > * {
    width: calc(16.6666666667% - 4.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap76 > * {
    width: calc(16.6666666667% - 4.75rem / 6 * 5);
  }
  .flex-sp-col6.gap77 > * {
    width: calc(16.6666666667% - 4.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap78 > * {
    width: calc(16.6666666667% - 4.875rem / 6 * 5);
  }
  .flex-sp-col6.gap79 > * {
    width: calc(16.6666666667% - 4.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap80 > * {
    width: calc(16.6666666667% - 5rem / 6 * 5);
  }
  .flex-sp-col6.gap81 > * {
    width: calc(16.6666666667% - 5.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap82 > * {
    width: calc(16.6666666667% - 5.125rem / 6 * 5);
  }
  .flex-sp-col6.gap83 > * {
    width: calc(16.6666666667% - 5.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap84 > * {
    width: calc(16.6666666667% - 5.25rem / 6 * 5);
  }
  .flex-sp-col6.gap85 > * {
    width: calc(16.6666666667% - 5.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap86 > * {
    width: calc(16.6666666667% - 5.375rem / 6 * 5);
  }
  .flex-sp-col6.gap87 > * {
    width: calc(16.6666666667% - 5.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap88 > * {
    width: calc(16.6666666667% - 5.5rem / 6 * 5);
  }
  .flex-sp-col6.gap89 > * {
    width: calc(16.6666666667% - 5.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap90 > * {
    width: calc(16.6666666667% - 5.625rem / 6 * 5);
  }
  .flex-sp-col6.gap91 > * {
    width: calc(16.6666666667% - 5.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap92 > * {
    width: calc(16.6666666667% - 5.75rem / 6 * 5);
  }
  .flex-sp-col6.gap93 > * {
    width: calc(16.6666666667% - 5.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap94 > * {
    width: calc(16.6666666667% - 5.875rem / 6 * 5);
  }
  .flex-sp-col6.gap95 > * {
    width: calc(16.6666666667% - 5.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap96 > * {
    width: calc(16.6666666667% - 6rem / 6 * 5);
  }
  .flex-sp-col6.gap97 > * {
    width: calc(16.6666666667% - 6.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap98 > * {
    width: calc(16.6666666667% - 6.125rem / 6 * 5);
  }
  .flex-sp-col6.gap99 > * {
    width: calc(16.6666666667% - 6.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap100 > * {
    width: calc(16.6666666667% - 6.25rem / 6 * 5);
  }
  .flex-sp-col6.gap101 > * {
    width: calc(16.6666666667% - 6.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap102 > * {
    width: calc(16.6666666667% - 6.375rem / 6 * 5);
  }
  .flex-sp-col6.gap103 > * {
    width: calc(16.6666666667% - 6.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap104 > * {
    width: calc(16.6666666667% - 6.5rem / 6 * 5);
  }
  .flex-sp-col6.gap105 > * {
    width: calc(16.6666666667% - 6.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap106 > * {
    width: calc(16.6666666667% - 6.625rem / 6 * 5);
  }
  .flex-sp-col6.gap107 > * {
    width: calc(16.6666666667% - 6.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap108 > * {
    width: calc(16.6666666667% - 6.75rem / 6 * 5);
  }
  .flex-sp-col6.gap109 > * {
    width: calc(16.6666666667% - 6.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap110 > * {
    width: calc(16.6666666667% - 6.875rem / 6 * 5);
  }
  .flex-sp-col6.gap111 > * {
    width: calc(16.6666666667% - 6.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap112 > * {
    width: calc(16.6666666667% - 7rem / 6 * 5);
  }
  .flex-sp-col6.gap113 > * {
    width: calc(16.6666666667% - 7.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap114 > * {
    width: calc(16.6666666667% - 7.125rem / 6 * 5);
  }
  .flex-sp-col6.gap115 > * {
    width: calc(16.6666666667% - 7.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap116 > * {
    width: calc(16.6666666667% - 7.25rem / 6 * 5);
  }
  .flex-sp-col6.gap117 > * {
    width: calc(16.6666666667% - 7.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap118 > * {
    width: calc(16.6666666667% - 7.375rem / 6 * 5);
  }
  .flex-sp-col6.gap119 > * {
    width: calc(16.6666666667% - 7.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap120 > * {
    width: calc(16.6666666667% - 7.5rem / 6 * 5);
  }
  .flex-sp-col6.gap121 > * {
    width: calc(16.6666666667% - 7.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap122 > * {
    width: calc(16.6666666667% - 7.625rem / 6 * 5);
  }
  .flex-sp-col6.gap123 > * {
    width: calc(16.6666666667% - 7.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap124 > * {
    width: calc(16.6666666667% - 7.75rem / 6 * 5);
  }
  .flex-sp-col6.gap125 > * {
    width: calc(16.6666666667% - 7.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap126 > * {
    width: calc(16.6666666667% - 7.875rem / 6 * 5);
  }
  .flex-sp-col6.gap127 > * {
    width: calc(16.6666666667% - 7.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap128 > * {
    width: calc(16.6666666667% - 8rem / 6 * 5);
  }
  .flex-sp-col6.gap129 > * {
    width: calc(16.6666666667% - 8.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap130 > * {
    width: calc(16.6666666667% - 8.125rem / 6 * 5);
  }
  .flex-sp-col6.gap131 > * {
    width: calc(16.6666666667% - 8.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap132 > * {
    width: calc(16.6666666667% - 8.25rem / 6 * 5);
  }
  .flex-sp-col6.gap133 > * {
    width: calc(16.6666666667% - 8.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap134 > * {
    width: calc(16.6666666667% - 8.375rem / 6 * 5);
  }
  .flex-sp-col6.gap135 > * {
    width: calc(16.6666666667% - 8.4375rem / 6 * 5);
  }
  .flex-sp-col6.gap136 > * {
    width: calc(16.6666666667% - 8.5rem / 6 * 5);
  }
  .flex-sp-col6.gap137 > * {
    width: calc(16.6666666667% - 8.5625rem / 6 * 5);
  }
  .flex-sp-col6.gap138 > * {
    width: calc(16.6666666667% - 8.625rem / 6 * 5);
  }
  .flex-sp-col6.gap139 > * {
    width: calc(16.6666666667% - 8.6875rem / 6 * 5);
  }
  .flex-sp-col6.gap140 > * {
    width: calc(16.6666666667% - 8.75rem / 6 * 5);
  }
  .flex-sp-col6.gap141 > * {
    width: calc(16.6666666667% - 8.8125rem / 6 * 5);
  }
  .flex-sp-col6.gap142 > * {
    width: calc(16.6666666667% - 8.875rem / 6 * 5);
  }
  .flex-sp-col6.gap143 > * {
    width: calc(16.6666666667% - 8.9375rem / 6 * 5);
  }
  .flex-sp-col6.gap144 > * {
    width: calc(16.6666666667% - 9rem / 6 * 5);
  }
  .flex-sp-col6.gap145 > * {
    width: calc(16.6666666667% - 9.0625rem / 6 * 5);
  }
  .flex-sp-col6.gap146 > * {
    width: calc(16.6666666667% - 9.125rem / 6 * 5);
  }
  .flex-sp-col6.gap147 > * {
    width: calc(16.6666666667% - 9.1875rem / 6 * 5);
  }
  .flex-sp-col6.gap148 > * {
    width: calc(16.6666666667% - 9.25rem / 6 * 5);
  }
  .flex-sp-col6.gap149 > * {
    width: calc(16.6666666667% - 9.3125rem / 6 * 5);
  }
  .flex-sp-col6.gap150 > * {
    width: calc(16.6666666667% - 9.375rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp1 > * {
    width: calc(16.6666666667% - 0.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp2 > * {
    width: calc(16.6666666667% - 0.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp3 > * {
    width: calc(16.6666666667% - 0.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp4 > * {
    width: calc(16.6666666667% - 0.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp5 > * {
    width: calc(16.6666666667% - 0.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp6 > * {
    width: calc(16.6666666667% - 0.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp7 > * {
    width: calc(16.6666666667% - 0.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp8 > * {
    width: calc(16.6666666667% - 0.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp9 > * {
    width: calc(16.6666666667% - 0.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp10 > * {
    width: calc(16.6666666667% - 0.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp11 > * {
    width: calc(16.6666666667% - 0.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp12 > * {
    width: calc(16.6666666667% - 0.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp13 > * {
    width: calc(16.6666666667% - 0.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp14 > * {
    width: calc(16.6666666667% - 1rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp15 > * {
    width: calc(16.6666666667% - 1.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp16 > * {
    width: calc(16.6666666667% - 1.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp17 > * {
    width: calc(16.6666666667% - 1.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp18 > * {
    width: calc(16.6666666667% - 1.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp19 > * {
    width: calc(16.6666666667% - 1.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp20 > * {
    width: calc(16.6666666667% - 1.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp21 > * {
    width: calc(16.6666666667% - 1.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp22 > * {
    width: calc(16.6666666667% - 1.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp23 > * {
    width: calc(16.6666666667% - 1.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp24 > * {
    width: calc(16.6666666667% - 1.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp25 > * {
    width: calc(16.6666666667% - 1.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp26 > * {
    width: calc(16.6666666667% - 1.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp27 > * {
    width: calc(16.6666666667% - 1.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp28 > * {
    width: calc(16.6666666667% - 2rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp29 > * {
    width: calc(16.6666666667% - 2.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp30 > * {
    width: calc(16.6666666667% - 2.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp31 > * {
    width: calc(16.6666666667% - 2.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp32 > * {
    width: calc(16.6666666667% - 2.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp33 > * {
    width: calc(16.6666666667% - 2.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp34 > * {
    width: calc(16.6666666667% - 2.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp35 > * {
    width: calc(16.6666666667% - 2.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp36 > * {
    width: calc(16.6666666667% - 2.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp37 > * {
    width: calc(16.6666666667% - 2.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp38 > * {
    width: calc(16.6666666667% - 2.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp39 > * {
    width: calc(16.6666666667% - 2.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp40 > * {
    width: calc(16.6666666667% - 2.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp41 > * {
    width: calc(16.6666666667% - 2.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp42 > * {
    width: calc(16.6666666667% - 3rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp43 > * {
    width: calc(16.6666666667% - 3.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp44 > * {
    width: calc(16.6666666667% - 3.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp45 > * {
    width: calc(16.6666666667% - 3.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp46 > * {
    width: calc(16.6666666667% - 3.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp47 > * {
    width: calc(16.6666666667% - 3.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp48 > * {
    width: calc(16.6666666667% - 3.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp49 > * {
    width: calc(16.6666666667% - 3.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp50 > * {
    width: calc(16.6666666667% - 3.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp51 > * {
    width: calc(16.6666666667% - 3.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp52 > * {
    width: calc(16.6666666667% - 3.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp53 > * {
    width: calc(16.6666666667% - 3.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp54 > * {
    width: calc(16.6666666667% - 3.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp55 > * {
    width: calc(16.6666666667% - 3.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp56 > * {
    width: calc(16.6666666667% - 4rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp57 > * {
    width: calc(16.6666666667% - 4.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp58 > * {
    width: calc(16.6666666667% - 4.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp59 > * {
    width: calc(16.6666666667% - 4.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp60 > * {
    width: calc(16.6666666667% - 4.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp61 > * {
    width: calc(16.6666666667% - 4.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp62 > * {
    width: calc(16.6666666667% - 4.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp63 > * {
    width: calc(16.6666666667% - 4.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp64 > * {
    width: calc(16.6666666667% - 4.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp65 > * {
    width: calc(16.6666666667% - 4.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp66 > * {
    width: calc(16.6666666667% - 4.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp67 > * {
    width: calc(16.6666666667% - 4.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp68 > * {
    width: calc(16.6666666667% - 4.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp69 > * {
    width: calc(16.6666666667% - 4.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp70 > * {
    width: calc(16.6666666667% - 5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp71 > * {
    width: calc(16.6666666667% - 5.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp72 > * {
    width: calc(16.6666666667% - 5.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp73 > * {
    width: calc(16.6666666667% - 5.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp74 > * {
    width: calc(16.6666666667% - 5.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp75 > * {
    width: calc(16.6666666667% - 5.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp76 > * {
    width: calc(16.6666666667% - 5.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp77 > * {
    width: calc(16.6666666667% - 5.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp78 > * {
    width: calc(16.6666666667% - 5.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp79 > * {
    width: calc(16.6666666667% - 5.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp80 > * {
    width: calc(16.6666666667% - 5.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp81 > * {
    width: calc(16.6666666667% - 5.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp82 > * {
    width: calc(16.6666666667% - 5.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp83 > * {
    width: calc(16.6666666667% - 5.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp84 > * {
    width: calc(16.6666666667% - 6rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp85 > * {
    width: calc(16.6666666667% - 6.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp86 > * {
    width: calc(16.6666666667% - 6.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp87 > * {
    width: calc(16.6666666667% - 6.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp88 > * {
    width: calc(16.6666666667% - 6.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp89 > * {
    width: calc(16.6666666667% - 6.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp90 > * {
    width: calc(16.6666666667% - 6.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp91 > * {
    width: calc(16.6666666667% - 6.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp92 > * {
    width: calc(16.6666666667% - 6.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp93 > * {
    width: calc(16.6666666667% - 6.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp94 > * {
    width: calc(16.6666666667% - 6.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp95 > * {
    width: calc(16.6666666667% - 6.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp96 > * {
    width: calc(16.6666666667% - 6.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp97 > * {
    width: calc(16.6666666667% - 6.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp98 > * {
    width: calc(16.6666666667% - 7rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp99 > * {
    width: calc(16.6666666667% - 7.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp100 > * {
    width: calc(16.6666666667% - 7.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp101 > * {
    width: calc(16.6666666667% - 7.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp102 > * {
    width: calc(16.6666666667% - 7.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp103 > * {
    width: calc(16.6666666667% - 7.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp104 > * {
    width: calc(16.6666666667% - 7.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp105 > * {
    width: calc(16.6666666667% - 7.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp106 > * {
    width: calc(16.6666666667% - 7.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp107 > * {
    width: calc(16.6666666667% - 7.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp108 > * {
    width: calc(16.6666666667% - 7.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp109 > * {
    width: calc(16.6666666667% - 7.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp110 > * {
    width: calc(16.6666666667% - 7.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp111 > * {
    width: calc(16.6666666667% - 7.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp112 > * {
    width: calc(16.6666666667% - 8rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp113 > * {
    width: calc(16.6666666667% - 8.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp114 > * {
    width: calc(16.6666666667% - 8.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp115 > * {
    width: calc(16.6666666667% - 8.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp116 > * {
    width: calc(16.6666666667% - 8.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp117 > * {
    width: calc(16.6666666667% - 8.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp118 > * {
    width: calc(16.6666666667% - 8.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp119 > * {
    width: calc(16.6666666667% - 8.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp120 > * {
    width: calc(16.6666666667% - 8.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp121 > * {
    width: calc(16.6666666667% - 8.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp122 > * {
    width: calc(16.6666666667% - 8.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp123 > * {
    width: calc(16.6666666667% - 8.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp124 > * {
    width: calc(16.6666666667% - 8.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp125 > * {
    width: calc(16.6666666667% - 8.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp126 > * {
    width: calc(16.6666666667% - 9rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp127 > * {
    width: calc(16.6666666667% - 9.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp128 > * {
    width: calc(16.6666666667% - 9.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp129 > * {
    width: calc(16.6666666667% - 9.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp130 > * {
    width: calc(16.6666666667% - 9.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp131 > * {
    width: calc(16.6666666667% - 9.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp132 > * {
    width: calc(16.6666666667% - 9.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp133 > * {
    width: calc(16.6666666667% - 9.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp134 > * {
    width: calc(16.6666666667% - 9.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp135 > * {
    width: calc(16.6666666667% - 9.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp136 > * {
    width: calc(16.6666666667% - 9.7142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp137 > * {
    width: calc(16.6666666667% - 9.7857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp138 > * {
    width: calc(16.6666666667% - 9.8571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp139 > * {
    width: calc(16.6666666667% - 9.9285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp140 > * {
    width: calc(16.6666666667% - 10rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp141 > * {
    width: calc(16.6666666667% - 10.0714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp142 > * {
    width: calc(16.6666666667% - 10.1428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp143 > * {
    width: calc(16.6666666667% - 10.2142857143rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp144 > * {
    width: calc(16.6666666667% - 10.2857142857rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp145 > * {
    width: calc(16.6666666667% - 10.3571428571rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp146 > * {
    width: calc(16.6666666667% - 10.4285714286rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp147 > * {
    width: calc(16.6666666667% - 10.5rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp148 > * {
    width: calc(16.6666666667% - 10.5714285714rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp149 > * {
    width: calc(16.6666666667% - 10.6428571429rem / 6 * 5);
  }
  .flex-sp-col6.gap-sp150 > * {
    width: calc(16.6666666667% - 10.7142857143rem / 6 * 5);
  }
  .flex-sp-col7 {
    flex-wrap: wrap;
  }
  .flex-sp-col7 > * {
    width: 14.2857142857%;
  }
  .flex-sp-col7.gap1 > * {
    width: calc(14.2857142857% - 0.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap2 > * {
    width: calc(14.2857142857% - 0.125rem / 7 * 6);
  }
  .flex-sp-col7.gap3 > * {
    width: calc(14.2857142857% - 0.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap4 > * {
    width: calc(14.2857142857% - 0.25rem / 7 * 6);
  }
  .flex-sp-col7.gap5 > * {
    width: calc(14.2857142857% - 0.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap6 > * {
    width: calc(14.2857142857% - 0.375rem / 7 * 6);
  }
  .flex-sp-col7.gap7 > * {
    width: calc(14.2857142857% - 0.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap8 > * {
    width: calc(14.2857142857% - 0.5rem / 7 * 6);
  }
  .flex-sp-col7.gap9 > * {
    width: calc(14.2857142857% - 0.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap10 > * {
    width: calc(14.2857142857% - 0.625rem / 7 * 6);
  }
  .flex-sp-col7.gap11 > * {
    width: calc(14.2857142857% - 0.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap12 > * {
    width: calc(14.2857142857% - 0.75rem / 7 * 6);
  }
  .flex-sp-col7.gap13 > * {
    width: calc(14.2857142857% - 0.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap14 > * {
    width: calc(14.2857142857% - 0.875rem / 7 * 6);
  }
  .flex-sp-col7.gap15 > * {
    width: calc(14.2857142857% - 0.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap16 > * {
    width: calc(14.2857142857% - 1rem / 7 * 6);
  }
  .flex-sp-col7.gap17 > * {
    width: calc(14.2857142857% - 1.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap18 > * {
    width: calc(14.2857142857% - 1.125rem / 7 * 6);
  }
  .flex-sp-col7.gap19 > * {
    width: calc(14.2857142857% - 1.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap20 > * {
    width: calc(14.2857142857% - 1.25rem / 7 * 6);
  }
  .flex-sp-col7.gap21 > * {
    width: calc(14.2857142857% - 1.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap22 > * {
    width: calc(14.2857142857% - 1.375rem / 7 * 6);
  }
  .flex-sp-col7.gap23 > * {
    width: calc(14.2857142857% - 1.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap24 > * {
    width: calc(14.2857142857% - 1.5rem / 7 * 6);
  }
  .flex-sp-col7.gap25 > * {
    width: calc(14.2857142857% - 1.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap26 > * {
    width: calc(14.2857142857% - 1.625rem / 7 * 6);
  }
  .flex-sp-col7.gap27 > * {
    width: calc(14.2857142857% - 1.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap28 > * {
    width: calc(14.2857142857% - 1.75rem / 7 * 6);
  }
  .flex-sp-col7.gap29 > * {
    width: calc(14.2857142857% - 1.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap30 > * {
    width: calc(14.2857142857% - 1.875rem / 7 * 6);
  }
  .flex-sp-col7.gap31 > * {
    width: calc(14.2857142857% - 1.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap32 > * {
    width: calc(14.2857142857% - 2rem / 7 * 6);
  }
  .flex-sp-col7.gap33 > * {
    width: calc(14.2857142857% - 2.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap34 > * {
    width: calc(14.2857142857% - 2.125rem / 7 * 6);
  }
  .flex-sp-col7.gap35 > * {
    width: calc(14.2857142857% - 2.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap36 > * {
    width: calc(14.2857142857% - 2.25rem / 7 * 6);
  }
  .flex-sp-col7.gap37 > * {
    width: calc(14.2857142857% - 2.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap38 > * {
    width: calc(14.2857142857% - 2.375rem / 7 * 6);
  }
  .flex-sp-col7.gap39 > * {
    width: calc(14.2857142857% - 2.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap40 > * {
    width: calc(14.2857142857% - 2.5rem / 7 * 6);
  }
  .flex-sp-col7.gap41 > * {
    width: calc(14.2857142857% - 2.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap42 > * {
    width: calc(14.2857142857% - 2.625rem / 7 * 6);
  }
  .flex-sp-col7.gap43 > * {
    width: calc(14.2857142857% - 2.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap44 > * {
    width: calc(14.2857142857% - 2.75rem / 7 * 6);
  }
  .flex-sp-col7.gap45 > * {
    width: calc(14.2857142857% - 2.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap46 > * {
    width: calc(14.2857142857% - 2.875rem / 7 * 6);
  }
  .flex-sp-col7.gap47 > * {
    width: calc(14.2857142857% - 2.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap48 > * {
    width: calc(14.2857142857% - 3rem / 7 * 6);
  }
  .flex-sp-col7.gap49 > * {
    width: calc(14.2857142857% - 3.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap50 > * {
    width: calc(14.2857142857% - 3.125rem / 7 * 6);
  }
  .flex-sp-col7.gap51 > * {
    width: calc(14.2857142857% - 3.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap52 > * {
    width: calc(14.2857142857% - 3.25rem / 7 * 6);
  }
  .flex-sp-col7.gap53 > * {
    width: calc(14.2857142857% - 3.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap54 > * {
    width: calc(14.2857142857% - 3.375rem / 7 * 6);
  }
  .flex-sp-col7.gap55 > * {
    width: calc(14.2857142857% - 3.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap56 > * {
    width: calc(14.2857142857% - 3.5rem / 7 * 6);
  }
  .flex-sp-col7.gap57 > * {
    width: calc(14.2857142857% - 3.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap58 > * {
    width: calc(14.2857142857% - 3.625rem / 7 * 6);
  }
  .flex-sp-col7.gap59 > * {
    width: calc(14.2857142857% - 3.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap60 > * {
    width: calc(14.2857142857% - 3.75rem / 7 * 6);
  }
  .flex-sp-col7.gap61 > * {
    width: calc(14.2857142857% - 3.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap62 > * {
    width: calc(14.2857142857% - 3.875rem / 7 * 6);
  }
  .flex-sp-col7.gap63 > * {
    width: calc(14.2857142857% - 3.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap64 > * {
    width: calc(14.2857142857% - 4rem / 7 * 6);
  }
  .flex-sp-col7.gap65 > * {
    width: calc(14.2857142857% - 4.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap66 > * {
    width: calc(14.2857142857% - 4.125rem / 7 * 6);
  }
  .flex-sp-col7.gap67 > * {
    width: calc(14.2857142857% - 4.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap68 > * {
    width: calc(14.2857142857% - 4.25rem / 7 * 6);
  }
  .flex-sp-col7.gap69 > * {
    width: calc(14.2857142857% - 4.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap70 > * {
    width: calc(14.2857142857% - 4.375rem / 7 * 6);
  }
  .flex-sp-col7.gap71 > * {
    width: calc(14.2857142857% - 4.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap72 > * {
    width: calc(14.2857142857% - 4.5rem / 7 * 6);
  }
  .flex-sp-col7.gap73 > * {
    width: calc(14.2857142857% - 4.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap74 > * {
    width: calc(14.2857142857% - 4.625rem / 7 * 6);
  }
  .flex-sp-col7.gap75 > * {
    width: calc(14.2857142857% - 4.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap76 > * {
    width: calc(14.2857142857% - 4.75rem / 7 * 6);
  }
  .flex-sp-col7.gap77 > * {
    width: calc(14.2857142857% - 4.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap78 > * {
    width: calc(14.2857142857% - 4.875rem / 7 * 6);
  }
  .flex-sp-col7.gap79 > * {
    width: calc(14.2857142857% - 4.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap80 > * {
    width: calc(14.2857142857% - 5rem / 7 * 6);
  }
  .flex-sp-col7.gap81 > * {
    width: calc(14.2857142857% - 5.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap82 > * {
    width: calc(14.2857142857% - 5.125rem / 7 * 6);
  }
  .flex-sp-col7.gap83 > * {
    width: calc(14.2857142857% - 5.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap84 > * {
    width: calc(14.2857142857% - 5.25rem / 7 * 6);
  }
  .flex-sp-col7.gap85 > * {
    width: calc(14.2857142857% - 5.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap86 > * {
    width: calc(14.2857142857% - 5.375rem / 7 * 6);
  }
  .flex-sp-col7.gap87 > * {
    width: calc(14.2857142857% - 5.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap88 > * {
    width: calc(14.2857142857% - 5.5rem / 7 * 6);
  }
  .flex-sp-col7.gap89 > * {
    width: calc(14.2857142857% - 5.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap90 > * {
    width: calc(14.2857142857% - 5.625rem / 7 * 6);
  }
  .flex-sp-col7.gap91 > * {
    width: calc(14.2857142857% - 5.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap92 > * {
    width: calc(14.2857142857% - 5.75rem / 7 * 6);
  }
  .flex-sp-col7.gap93 > * {
    width: calc(14.2857142857% - 5.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap94 > * {
    width: calc(14.2857142857% - 5.875rem / 7 * 6);
  }
  .flex-sp-col7.gap95 > * {
    width: calc(14.2857142857% - 5.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap96 > * {
    width: calc(14.2857142857% - 6rem / 7 * 6);
  }
  .flex-sp-col7.gap97 > * {
    width: calc(14.2857142857% - 6.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap98 > * {
    width: calc(14.2857142857% - 6.125rem / 7 * 6);
  }
  .flex-sp-col7.gap99 > * {
    width: calc(14.2857142857% - 6.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap100 > * {
    width: calc(14.2857142857% - 6.25rem / 7 * 6);
  }
  .flex-sp-col7.gap101 > * {
    width: calc(14.2857142857% - 6.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap102 > * {
    width: calc(14.2857142857% - 6.375rem / 7 * 6);
  }
  .flex-sp-col7.gap103 > * {
    width: calc(14.2857142857% - 6.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap104 > * {
    width: calc(14.2857142857% - 6.5rem / 7 * 6);
  }
  .flex-sp-col7.gap105 > * {
    width: calc(14.2857142857% - 6.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap106 > * {
    width: calc(14.2857142857% - 6.625rem / 7 * 6);
  }
  .flex-sp-col7.gap107 > * {
    width: calc(14.2857142857% - 6.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap108 > * {
    width: calc(14.2857142857% - 6.75rem / 7 * 6);
  }
  .flex-sp-col7.gap109 > * {
    width: calc(14.2857142857% - 6.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap110 > * {
    width: calc(14.2857142857% - 6.875rem / 7 * 6);
  }
  .flex-sp-col7.gap111 > * {
    width: calc(14.2857142857% - 6.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap112 > * {
    width: calc(14.2857142857% - 7rem / 7 * 6);
  }
  .flex-sp-col7.gap113 > * {
    width: calc(14.2857142857% - 7.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap114 > * {
    width: calc(14.2857142857% - 7.125rem / 7 * 6);
  }
  .flex-sp-col7.gap115 > * {
    width: calc(14.2857142857% - 7.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap116 > * {
    width: calc(14.2857142857% - 7.25rem / 7 * 6);
  }
  .flex-sp-col7.gap117 > * {
    width: calc(14.2857142857% - 7.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap118 > * {
    width: calc(14.2857142857% - 7.375rem / 7 * 6);
  }
  .flex-sp-col7.gap119 > * {
    width: calc(14.2857142857% - 7.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap120 > * {
    width: calc(14.2857142857% - 7.5rem / 7 * 6);
  }
  .flex-sp-col7.gap121 > * {
    width: calc(14.2857142857% - 7.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap122 > * {
    width: calc(14.2857142857% - 7.625rem / 7 * 6);
  }
  .flex-sp-col7.gap123 > * {
    width: calc(14.2857142857% - 7.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap124 > * {
    width: calc(14.2857142857% - 7.75rem / 7 * 6);
  }
  .flex-sp-col7.gap125 > * {
    width: calc(14.2857142857% - 7.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap126 > * {
    width: calc(14.2857142857% - 7.875rem / 7 * 6);
  }
  .flex-sp-col7.gap127 > * {
    width: calc(14.2857142857% - 7.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap128 > * {
    width: calc(14.2857142857% - 8rem / 7 * 6);
  }
  .flex-sp-col7.gap129 > * {
    width: calc(14.2857142857% - 8.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap130 > * {
    width: calc(14.2857142857% - 8.125rem / 7 * 6);
  }
  .flex-sp-col7.gap131 > * {
    width: calc(14.2857142857% - 8.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap132 > * {
    width: calc(14.2857142857% - 8.25rem / 7 * 6);
  }
  .flex-sp-col7.gap133 > * {
    width: calc(14.2857142857% - 8.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap134 > * {
    width: calc(14.2857142857% - 8.375rem / 7 * 6);
  }
  .flex-sp-col7.gap135 > * {
    width: calc(14.2857142857% - 8.4375rem / 7 * 6);
  }
  .flex-sp-col7.gap136 > * {
    width: calc(14.2857142857% - 8.5rem / 7 * 6);
  }
  .flex-sp-col7.gap137 > * {
    width: calc(14.2857142857% - 8.5625rem / 7 * 6);
  }
  .flex-sp-col7.gap138 > * {
    width: calc(14.2857142857% - 8.625rem / 7 * 6);
  }
  .flex-sp-col7.gap139 > * {
    width: calc(14.2857142857% - 8.6875rem / 7 * 6);
  }
  .flex-sp-col7.gap140 > * {
    width: calc(14.2857142857% - 8.75rem / 7 * 6);
  }
  .flex-sp-col7.gap141 > * {
    width: calc(14.2857142857% - 8.8125rem / 7 * 6);
  }
  .flex-sp-col7.gap142 > * {
    width: calc(14.2857142857% - 8.875rem / 7 * 6);
  }
  .flex-sp-col7.gap143 > * {
    width: calc(14.2857142857% - 8.9375rem / 7 * 6);
  }
  .flex-sp-col7.gap144 > * {
    width: calc(14.2857142857% - 9rem / 7 * 6);
  }
  .flex-sp-col7.gap145 > * {
    width: calc(14.2857142857% - 9.0625rem / 7 * 6);
  }
  .flex-sp-col7.gap146 > * {
    width: calc(14.2857142857% - 9.125rem / 7 * 6);
  }
  .flex-sp-col7.gap147 > * {
    width: calc(14.2857142857% - 9.1875rem / 7 * 6);
  }
  .flex-sp-col7.gap148 > * {
    width: calc(14.2857142857% - 9.25rem / 7 * 6);
  }
  .flex-sp-col7.gap149 > * {
    width: calc(14.2857142857% - 9.3125rem / 7 * 6);
  }
  .flex-sp-col7.gap150 > * {
    width: calc(14.2857142857% - 9.375rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp1 > * {
    width: calc(14.2857142857% - 0.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp2 > * {
    width: calc(14.2857142857% - 0.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp3 > * {
    width: calc(14.2857142857% - 0.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp4 > * {
    width: calc(14.2857142857% - 0.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp5 > * {
    width: calc(14.2857142857% - 0.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp6 > * {
    width: calc(14.2857142857% - 0.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp7 > * {
    width: calc(14.2857142857% - 0.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp8 > * {
    width: calc(14.2857142857% - 0.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp9 > * {
    width: calc(14.2857142857% - 0.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp10 > * {
    width: calc(14.2857142857% - 0.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp11 > * {
    width: calc(14.2857142857% - 0.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp12 > * {
    width: calc(14.2857142857% - 0.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp13 > * {
    width: calc(14.2857142857% - 0.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp14 > * {
    width: calc(14.2857142857% - 1rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp15 > * {
    width: calc(14.2857142857% - 1.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp16 > * {
    width: calc(14.2857142857% - 1.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp17 > * {
    width: calc(14.2857142857% - 1.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp18 > * {
    width: calc(14.2857142857% - 1.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp19 > * {
    width: calc(14.2857142857% - 1.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp20 > * {
    width: calc(14.2857142857% - 1.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp21 > * {
    width: calc(14.2857142857% - 1.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp22 > * {
    width: calc(14.2857142857% - 1.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp23 > * {
    width: calc(14.2857142857% - 1.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp24 > * {
    width: calc(14.2857142857% - 1.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp25 > * {
    width: calc(14.2857142857% - 1.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp26 > * {
    width: calc(14.2857142857% - 1.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp27 > * {
    width: calc(14.2857142857% - 1.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp28 > * {
    width: calc(14.2857142857% - 2rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp29 > * {
    width: calc(14.2857142857% - 2.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp30 > * {
    width: calc(14.2857142857% - 2.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp31 > * {
    width: calc(14.2857142857% - 2.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp32 > * {
    width: calc(14.2857142857% - 2.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp33 > * {
    width: calc(14.2857142857% - 2.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp34 > * {
    width: calc(14.2857142857% - 2.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp35 > * {
    width: calc(14.2857142857% - 2.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp36 > * {
    width: calc(14.2857142857% - 2.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp37 > * {
    width: calc(14.2857142857% - 2.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp38 > * {
    width: calc(14.2857142857% - 2.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp39 > * {
    width: calc(14.2857142857% - 2.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp40 > * {
    width: calc(14.2857142857% - 2.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp41 > * {
    width: calc(14.2857142857% - 2.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp42 > * {
    width: calc(14.2857142857% - 3rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp43 > * {
    width: calc(14.2857142857% - 3.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp44 > * {
    width: calc(14.2857142857% - 3.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp45 > * {
    width: calc(14.2857142857% - 3.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp46 > * {
    width: calc(14.2857142857% - 3.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp47 > * {
    width: calc(14.2857142857% - 3.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp48 > * {
    width: calc(14.2857142857% - 3.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp49 > * {
    width: calc(14.2857142857% - 3.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp50 > * {
    width: calc(14.2857142857% - 3.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp51 > * {
    width: calc(14.2857142857% - 3.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp52 > * {
    width: calc(14.2857142857% - 3.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp53 > * {
    width: calc(14.2857142857% - 3.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp54 > * {
    width: calc(14.2857142857% - 3.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp55 > * {
    width: calc(14.2857142857% - 3.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp56 > * {
    width: calc(14.2857142857% - 4rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp57 > * {
    width: calc(14.2857142857% - 4.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp58 > * {
    width: calc(14.2857142857% - 4.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp59 > * {
    width: calc(14.2857142857% - 4.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp60 > * {
    width: calc(14.2857142857% - 4.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp61 > * {
    width: calc(14.2857142857% - 4.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp62 > * {
    width: calc(14.2857142857% - 4.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp63 > * {
    width: calc(14.2857142857% - 4.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp64 > * {
    width: calc(14.2857142857% - 4.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp65 > * {
    width: calc(14.2857142857% - 4.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp66 > * {
    width: calc(14.2857142857% - 4.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp67 > * {
    width: calc(14.2857142857% - 4.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp68 > * {
    width: calc(14.2857142857% - 4.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp69 > * {
    width: calc(14.2857142857% - 4.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp70 > * {
    width: calc(14.2857142857% - 5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp71 > * {
    width: calc(14.2857142857% - 5.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp72 > * {
    width: calc(14.2857142857% - 5.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp73 > * {
    width: calc(14.2857142857% - 5.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp74 > * {
    width: calc(14.2857142857% - 5.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp75 > * {
    width: calc(14.2857142857% - 5.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp76 > * {
    width: calc(14.2857142857% - 5.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp77 > * {
    width: calc(14.2857142857% - 5.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp78 > * {
    width: calc(14.2857142857% - 5.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp79 > * {
    width: calc(14.2857142857% - 5.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp80 > * {
    width: calc(14.2857142857% - 5.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp81 > * {
    width: calc(14.2857142857% - 5.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp82 > * {
    width: calc(14.2857142857% - 5.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp83 > * {
    width: calc(14.2857142857% - 5.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp84 > * {
    width: calc(14.2857142857% - 6rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp85 > * {
    width: calc(14.2857142857% - 6.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp86 > * {
    width: calc(14.2857142857% - 6.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp87 > * {
    width: calc(14.2857142857% - 6.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp88 > * {
    width: calc(14.2857142857% - 6.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp89 > * {
    width: calc(14.2857142857% - 6.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp90 > * {
    width: calc(14.2857142857% - 6.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp91 > * {
    width: calc(14.2857142857% - 6.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp92 > * {
    width: calc(14.2857142857% - 6.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp93 > * {
    width: calc(14.2857142857% - 6.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp94 > * {
    width: calc(14.2857142857% - 6.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp95 > * {
    width: calc(14.2857142857% - 6.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp96 > * {
    width: calc(14.2857142857% - 6.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp97 > * {
    width: calc(14.2857142857% - 6.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp98 > * {
    width: calc(14.2857142857% - 7rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp99 > * {
    width: calc(14.2857142857% - 7.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp100 > * {
    width: calc(14.2857142857% - 7.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp101 > * {
    width: calc(14.2857142857% - 7.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp102 > * {
    width: calc(14.2857142857% - 7.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp103 > * {
    width: calc(14.2857142857% - 7.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp104 > * {
    width: calc(14.2857142857% - 7.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp105 > * {
    width: calc(14.2857142857% - 7.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp106 > * {
    width: calc(14.2857142857% - 7.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp107 > * {
    width: calc(14.2857142857% - 7.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp108 > * {
    width: calc(14.2857142857% - 7.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp109 > * {
    width: calc(14.2857142857% - 7.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp110 > * {
    width: calc(14.2857142857% - 7.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp111 > * {
    width: calc(14.2857142857% - 7.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp112 > * {
    width: calc(14.2857142857% - 8rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp113 > * {
    width: calc(14.2857142857% - 8.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp114 > * {
    width: calc(14.2857142857% - 8.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp115 > * {
    width: calc(14.2857142857% - 8.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp116 > * {
    width: calc(14.2857142857% - 8.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp117 > * {
    width: calc(14.2857142857% - 8.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp118 > * {
    width: calc(14.2857142857% - 8.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp119 > * {
    width: calc(14.2857142857% - 8.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp120 > * {
    width: calc(14.2857142857% - 8.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp121 > * {
    width: calc(14.2857142857% - 8.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp122 > * {
    width: calc(14.2857142857% - 8.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp123 > * {
    width: calc(14.2857142857% - 8.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp124 > * {
    width: calc(14.2857142857% - 8.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp125 > * {
    width: calc(14.2857142857% - 8.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp126 > * {
    width: calc(14.2857142857% - 9rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp127 > * {
    width: calc(14.2857142857% - 9.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp128 > * {
    width: calc(14.2857142857% - 9.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp129 > * {
    width: calc(14.2857142857% - 9.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp130 > * {
    width: calc(14.2857142857% - 9.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp131 > * {
    width: calc(14.2857142857% - 9.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp132 > * {
    width: calc(14.2857142857% - 9.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp133 > * {
    width: calc(14.2857142857% - 9.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp134 > * {
    width: calc(14.2857142857% - 9.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp135 > * {
    width: calc(14.2857142857% - 9.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp136 > * {
    width: calc(14.2857142857% - 9.7142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp137 > * {
    width: calc(14.2857142857% - 9.7857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp138 > * {
    width: calc(14.2857142857% - 9.8571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp139 > * {
    width: calc(14.2857142857% - 9.9285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp140 > * {
    width: calc(14.2857142857% - 10rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp141 > * {
    width: calc(14.2857142857% - 10.0714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp142 > * {
    width: calc(14.2857142857% - 10.1428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp143 > * {
    width: calc(14.2857142857% - 10.2142857143rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp144 > * {
    width: calc(14.2857142857% - 10.2857142857rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp145 > * {
    width: calc(14.2857142857% - 10.3571428571rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp146 > * {
    width: calc(14.2857142857% - 10.4285714286rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp147 > * {
    width: calc(14.2857142857% - 10.5rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp148 > * {
    width: calc(14.2857142857% - 10.5714285714rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp149 > * {
    width: calc(14.2857142857% - 10.6428571429rem / 7 * 6);
  }
  .flex-sp-col7.gap-sp150 > * {
    width: calc(14.2857142857% - 10.7142857143rem / 7 * 6);
  }
  .flex-sp-col8 {
    flex-wrap: wrap;
  }
  .flex-sp-col8 > * {
    width: 12.5%;
  }
  .flex-sp-col8.gap1 > * {
    width: calc(12.5% - 0.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap2 > * {
    width: calc(12.5% - 0.125rem / 8 * 7);
  }
  .flex-sp-col8.gap3 > * {
    width: calc(12.5% - 0.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap4 > * {
    width: calc(12.5% - 0.25rem / 8 * 7);
  }
  .flex-sp-col8.gap5 > * {
    width: calc(12.5% - 0.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap6 > * {
    width: calc(12.5% - 0.375rem / 8 * 7);
  }
  .flex-sp-col8.gap7 > * {
    width: calc(12.5% - 0.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap8 > * {
    width: calc(12.5% - 0.5rem / 8 * 7);
  }
  .flex-sp-col8.gap9 > * {
    width: calc(12.5% - 0.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap10 > * {
    width: calc(12.5% - 0.625rem / 8 * 7);
  }
  .flex-sp-col8.gap11 > * {
    width: calc(12.5% - 0.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap12 > * {
    width: calc(12.5% - 0.75rem / 8 * 7);
  }
  .flex-sp-col8.gap13 > * {
    width: calc(12.5% - 0.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap14 > * {
    width: calc(12.5% - 0.875rem / 8 * 7);
  }
  .flex-sp-col8.gap15 > * {
    width: calc(12.5% - 0.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap16 > * {
    width: calc(12.5% - 1rem / 8 * 7);
  }
  .flex-sp-col8.gap17 > * {
    width: calc(12.5% - 1.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap18 > * {
    width: calc(12.5% - 1.125rem / 8 * 7);
  }
  .flex-sp-col8.gap19 > * {
    width: calc(12.5% - 1.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap20 > * {
    width: calc(12.5% - 1.25rem / 8 * 7);
  }
  .flex-sp-col8.gap21 > * {
    width: calc(12.5% - 1.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap22 > * {
    width: calc(12.5% - 1.375rem / 8 * 7);
  }
  .flex-sp-col8.gap23 > * {
    width: calc(12.5% - 1.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap24 > * {
    width: calc(12.5% - 1.5rem / 8 * 7);
  }
  .flex-sp-col8.gap25 > * {
    width: calc(12.5% - 1.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap26 > * {
    width: calc(12.5% - 1.625rem / 8 * 7);
  }
  .flex-sp-col8.gap27 > * {
    width: calc(12.5% - 1.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap28 > * {
    width: calc(12.5% - 1.75rem / 8 * 7);
  }
  .flex-sp-col8.gap29 > * {
    width: calc(12.5% - 1.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap30 > * {
    width: calc(12.5% - 1.875rem / 8 * 7);
  }
  .flex-sp-col8.gap31 > * {
    width: calc(12.5% - 1.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap32 > * {
    width: calc(12.5% - 2rem / 8 * 7);
  }
  .flex-sp-col8.gap33 > * {
    width: calc(12.5% - 2.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap34 > * {
    width: calc(12.5% - 2.125rem / 8 * 7);
  }
  .flex-sp-col8.gap35 > * {
    width: calc(12.5% - 2.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap36 > * {
    width: calc(12.5% - 2.25rem / 8 * 7);
  }
  .flex-sp-col8.gap37 > * {
    width: calc(12.5% - 2.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap38 > * {
    width: calc(12.5% - 2.375rem / 8 * 7);
  }
  .flex-sp-col8.gap39 > * {
    width: calc(12.5% - 2.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap40 > * {
    width: calc(12.5% - 2.5rem / 8 * 7);
  }
  .flex-sp-col8.gap41 > * {
    width: calc(12.5% - 2.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap42 > * {
    width: calc(12.5% - 2.625rem / 8 * 7);
  }
  .flex-sp-col8.gap43 > * {
    width: calc(12.5% - 2.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap44 > * {
    width: calc(12.5% - 2.75rem / 8 * 7);
  }
  .flex-sp-col8.gap45 > * {
    width: calc(12.5% - 2.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap46 > * {
    width: calc(12.5% - 2.875rem / 8 * 7);
  }
  .flex-sp-col8.gap47 > * {
    width: calc(12.5% - 2.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap48 > * {
    width: calc(12.5% - 3rem / 8 * 7);
  }
  .flex-sp-col8.gap49 > * {
    width: calc(12.5% - 3.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap50 > * {
    width: calc(12.5% - 3.125rem / 8 * 7);
  }
  .flex-sp-col8.gap51 > * {
    width: calc(12.5% - 3.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap52 > * {
    width: calc(12.5% - 3.25rem / 8 * 7);
  }
  .flex-sp-col8.gap53 > * {
    width: calc(12.5% - 3.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap54 > * {
    width: calc(12.5% - 3.375rem / 8 * 7);
  }
  .flex-sp-col8.gap55 > * {
    width: calc(12.5% - 3.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap56 > * {
    width: calc(12.5% - 3.5rem / 8 * 7);
  }
  .flex-sp-col8.gap57 > * {
    width: calc(12.5% - 3.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap58 > * {
    width: calc(12.5% - 3.625rem / 8 * 7);
  }
  .flex-sp-col8.gap59 > * {
    width: calc(12.5% - 3.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap60 > * {
    width: calc(12.5% - 3.75rem / 8 * 7);
  }
  .flex-sp-col8.gap61 > * {
    width: calc(12.5% - 3.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap62 > * {
    width: calc(12.5% - 3.875rem / 8 * 7);
  }
  .flex-sp-col8.gap63 > * {
    width: calc(12.5% - 3.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap64 > * {
    width: calc(12.5% - 4rem / 8 * 7);
  }
  .flex-sp-col8.gap65 > * {
    width: calc(12.5% - 4.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap66 > * {
    width: calc(12.5% - 4.125rem / 8 * 7);
  }
  .flex-sp-col8.gap67 > * {
    width: calc(12.5% - 4.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap68 > * {
    width: calc(12.5% - 4.25rem / 8 * 7);
  }
  .flex-sp-col8.gap69 > * {
    width: calc(12.5% - 4.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap70 > * {
    width: calc(12.5% - 4.375rem / 8 * 7);
  }
  .flex-sp-col8.gap71 > * {
    width: calc(12.5% - 4.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap72 > * {
    width: calc(12.5% - 4.5rem / 8 * 7);
  }
  .flex-sp-col8.gap73 > * {
    width: calc(12.5% - 4.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap74 > * {
    width: calc(12.5% - 4.625rem / 8 * 7);
  }
  .flex-sp-col8.gap75 > * {
    width: calc(12.5% - 4.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap76 > * {
    width: calc(12.5% - 4.75rem / 8 * 7);
  }
  .flex-sp-col8.gap77 > * {
    width: calc(12.5% - 4.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap78 > * {
    width: calc(12.5% - 4.875rem / 8 * 7);
  }
  .flex-sp-col8.gap79 > * {
    width: calc(12.5% - 4.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap80 > * {
    width: calc(12.5% - 5rem / 8 * 7);
  }
  .flex-sp-col8.gap81 > * {
    width: calc(12.5% - 5.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap82 > * {
    width: calc(12.5% - 5.125rem / 8 * 7);
  }
  .flex-sp-col8.gap83 > * {
    width: calc(12.5% - 5.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap84 > * {
    width: calc(12.5% - 5.25rem / 8 * 7);
  }
  .flex-sp-col8.gap85 > * {
    width: calc(12.5% - 5.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap86 > * {
    width: calc(12.5% - 5.375rem / 8 * 7);
  }
  .flex-sp-col8.gap87 > * {
    width: calc(12.5% - 5.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap88 > * {
    width: calc(12.5% - 5.5rem / 8 * 7);
  }
  .flex-sp-col8.gap89 > * {
    width: calc(12.5% - 5.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap90 > * {
    width: calc(12.5% - 5.625rem / 8 * 7);
  }
  .flex-sp-col8.gap91 > * {
    width: calc(12.5% - 5.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap92 > * {
    width: calc(12.5% - 5.75rem / 8 * 7);
  }
  .flex-sp-col8.gap93 > * {
    width: calc(12.5% - 5.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap94 > * {
    width: calc(12.5% - 5.875rem / 8 * 7);
  }
  .flex-sp-col8.gap95 > * {
    width: calc(12.5% - 5.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap96 > * {
    width: calc(12.5% - 6rem / 8 * 7);
  }
  .flex-sp-col8.gap97 > * {
    width: calc(12.5% - 6.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap98 > * {
    width: calc(12.5% - 6.125rem / 8 * 7);
  }
  .flex-sp-col8.gap99 > * {
    width: calc(12.5% - 6.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap100 > * {
    width: calc(12.5% - 6.25rem / 8 * 7);
  }
  .flex-sp-col8.gap101 > * {
    width: calc(12.5% - 6.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap102 > * {
    width: calc(12.5% - 6.375rem / 8 * 7);
  }
  .flex-sp-col8.gap103 > * {
    width: calc(12.5% - 6.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap104 > * {
    width: calc(12.5% - 6.5rem / 8 * 7);
  }
  .flex-sp-col8.gap105 > * {
    width: calc(12.5% - 6.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap106 > * {
    width: calc(12.5% - 6.625rem / 8 * 7);
  }
  .flex-sp-col8.gap107 > * {
    width: calc(12.5% - 6.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap108 > * {
    width: calc(12.5% - 6.75rem / 8 * 7);
  }
  .flex-sp-col8.gap109 > * {
    width: calc(12.5% - 6.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap110 > * {
    width: calc(12.5% - 6.875rem / 8 * 7);
  }
  .flex-sp-col8.gap111 > * {
    width: calc(12.5% - 6.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap112 > * {
    width: calc(12.5% - 7rem / 8 * 7);
  }
  .flex-sp-col8.gap113 > * {
    width: calc(12.5% - 7.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap114 > * {
    width: calc(12.5% - 7.125rem / 8 * 7);
  }
  .flex-sp-col8.gap115 > * {
    width: calc(12.5% - 7.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap116 > * {
    width: calc(12.5% - 7.25rem / 8 * 7);
  }
  .flex-sp-col8.gap117 > * {
    width: calc(12.5% - 7.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap118 > * {
    width: calc(12.5% - 7.375rem / 8 * 7);
  }
  .flex-sp-col8.gap119 > * {
    width: calc(12.5% - 7.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap120 > * {
    width: calc(12.5% - 7.5rem / 8 * 7);
  }
  .flex-sp-col8.gap121 > * {
    width: calc(12.5% - 7.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap122 > * {
    width: calc(12.5% - 7.625rem / 8 * 7);
  }
  .flex-sp-col8.gap123 > * {
    width: calc(12.5% - 7.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap124 > * {
    width: calc(12.5% - 7.75rem / 8 * 7);
  }
  .flex-sp-col8.gap125 > * {
    width: calc(12.5% - 7.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap126 > * {
    width: calc(12.5% - 7.875rem / 8 * 7);
  }
  .flex-sp-col8.gap127 > * {
    width: calc(12.5% - 7.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap128 > * {
    width: calc(12.5% - 8rem / 8 * 7);
  }
  .flex-sp-col8.gap129 > * {
    width: calc(12.5% - 8.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap130 > * {
    width: calc(12.5% - 8.125rem / 8 * 7);
  }
  .flex-sp-col8.gap131 > * {
    width: calc(12.5% - 8.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap132 > * {
    width: calc(12.5% - 8.25rem / 8 * 7);
  }
  .flex-sp-col8.gap133 > * {
    width: calc(12.5% - 8.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap134 > * {
    width: calc(12.5% - 8.375rem / 8 * 7);
  }
  .flex-sp-col8.gap135 > * {
    width: calc(12.5% - 8.4375rem / 8 * 7);
  }
  .flex-sp-col8.gap136 > * {
    width: calc(12.5% - 8.5rem / 8 * 7);
  }
  .flex-sp-col8.gap137 > * {
    width: calc(12.5% - 8.5625rem / 8 * 7);
  }
  .flex-sp-col8.gap138 > * {
    width: calc(12.5% - 8.625rem / 8 * 7);
  }
  .flex-sp-col8.gap139 > * {
    width: calc(12.5% - 8.6875rem / 8 * 7);
  }
  .flex-sp-col8.gap140 > * {
    width: calc(12.5% - 8.75rem / 8 * 7);
  }
  .flex-sp-col8.gap141 > * {
    width: calc(12.5% - 8.8125rem / 8 * 7);
  }
  .flex-sp-col8.gap142 > * {
    width: calc(12.5% - 8.875rem / 8 * 7);
  }
  .flex-sp-col8.gap143 > * {
    width: calc(12.5% - 8.9375rem / 8 * 7);
  }
  .flex-sp-col8.gap144 > * {
    width: calc(12.5% - 9rem / 8 * 7);
  }
  .flex-sp-col8.gap145 > * {
    width: calc(12.5% - 9.0625rem / 8 * 7);
  }
  .flex-sp-col8.gap146 > * {
    width: calc(12.5% - 9.125rem / 8 * 7);
  }
  .flex-sp-col8.gap147 > * {
    width: calc(12.5% - 9.1875rem / 8 * 7);
  }
  .flex-sp-col8.gap148 > * {
    width: calc(12.5% - 9.25rem / 8 * 7);
  }
  .flex-sp-col8.gap149 > * {
    width: calc(12.5% - 9.3125rem / 8 * 7);
  }
  .flex-sp-col8.gap150 > * {
    width: calc(12.5% - 9.375rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp1 > * {
    width: calc(12.5% - 0.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp2 > * {
    width: calc(12.5% - 0.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp3 > * {
    width: calc(12.5% - 0.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp4 > * {
    width: calc(12.5% - 0.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp5 > * {
    width: calc(12.5% - 0.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp6 > * {
    width: calc(12.5% - 0.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp7 > * {
    width: calc(12.5% - 0.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp8 > * {
    width: calc(12.5% - 0.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp9 > * {
    width: calc(12.5% - 0.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp10 > * {
    width: calc(12.5% - 0.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp11 > * {
    width: calc(12.5% - 0.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp12 > * {
    width: calc(12.5% - 0.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp13 > * {
    width: calc(12.5% - 0.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp14 > * {
    width: calc(12.5% - 1rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp15 > * {
    width: calc(12.5% - 1.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp16 > * {
    width: calc(12.5% - 1.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp17 > * {
    width: calc(12.5% - 1.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp18 > * {
    width: calc(12.5% - 1.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp19 > * {
    width: calc(12.5% - 1.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp20 > * {
    width: calc(12.5% - 1.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp21 > * {
    width: calc(12.5% - 1.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp22 > * {
    width: calc(12.5% - 1.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp23 > * {
    width: calc(12.5% - 1.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp24 > * {
    width: calc(12.5% - 1.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp25 > * {
    width: calc(12.5% - 1.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp26 > * {
    width: calc(12.5% - 1.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp27 > * {
    width: calc(12.5% - 1.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp28 > * {
    width: calc(12.5% - 2rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp29 > * {
    width: calc(12.5% - 2.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp30 > * {
    width: calc(12.5% - 2.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp31 > * {
    width: calc(12.5% - 2.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp32 > * {
    width: calc(12.5% - 2.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp33 > * {
    width: calc(12.5% - 2.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp34 > * {
    width: calc(12.5% - 2.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp35 > * {
    width: calc(12.5% - 2.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp36 > * {
    width: calc(12.5% - 2.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp37 > * {
    width: calc(12.5% - 2.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp38 > * {
    width: calc(12.5% - 2.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp39 > * {
    width: calc(12.5% - 2.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp40 > * {
    width: calc(12.5% - 2.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp41 > * {
    width: calc(12.5% - 2.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp42 > * {
    width: calc(12.5% - 3rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp43 > * {
    width: calc(12.5% - 3.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp44 > * {
    width: calc(12.5% - 3.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp45 > * {
    width: calc(12.5% - 3.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp46 > * {
    width: calc(12.5% - 3.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp47 > * {
    width: calc(12.5% - 3.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp48 > * {
    width: calc(12.5% - 3.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp49 > * {
    width: calc(12.5% - 3.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp50 > * {
    width: calc(12.5% - 3.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp51 > * {
    width: calc(12.5% - 3.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp52 > * {
    width: calc(12.5% - 3.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp53 > * {
    width: calc(12.5% - 3.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp54 > * {
    width: calc(12.5% - 3.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp55 > * {
    width: calc(12.5% - 3.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp56 > * {
    width: calc(12.5% - 4rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp57 > * {
    width: calc(12.5% - 4.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp58 > * {
    width: calc(12.5% - 4.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp59 > * {
    width: calc(12.5% - 4.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp60 > * {
    width: calc(12.5% - 4.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp61 > * {
    width: calc(12.5% - 4.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp62 > * {
    width: calc(12.5% - 4.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp63 > * {
    width: calc(12.5% - 4.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp64 > * {
    width: calc(12.5% - 4.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp65 > * {
    width: calc(12.5% - 4.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp66 > * {
    width: calc(12.5% - 4.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp67 > * {
    width: calc(12.5% - 4.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp68 > * {
    width: calc(12.5% - 4.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp69 > * {
    width: calc(12.5% - 4.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp70 > * {
    width: calc(12.5% - 5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp71 > * {
    width: calc(12.5% - 5.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp72 > * {
    width: calc(12.5% - 5.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp73 > * {
    width: calc(12.5% - 5.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp74 > * {
    width: calc(12.5% - 5.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp75 > * {
    width: calc(12.5% - 5.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp76 > * {
    width: calc(12.5% - 5.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp77 > * {
    width: calc(12.5% - 5.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp78 > * {
    width: calc(12.5% - 5.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp79 > * {
    width: calc(12.5% - 5.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp80 > * {
    width: calc(12.5% - 5.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp81 > * {
    width: calc(12.5% - 5.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp82 > * {
    width: calc(12.5% - 5.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp83 > * {
    width: calc(12.5% - 5.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp84 > * {
    width: calc(12.5% - 6rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp85 > * {
    width: calc(12.5% - 6.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp86 > * {
    width: calc(12.5% - 6.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp87 > * {
    width: calc(12.5% - 6.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp88 > * {
    width: calc(12.5% - 6.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp89 > * {
    width: calc(12.5% - 6.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp90 > * {
    width: calc(12.5% - 6.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp91 > * {
    width: calc(12.5% - 6.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp92 > * {
    width: calc(12.5% - 6.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp93 > * {
    width: calc(12.5% - 6.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp94 > * {
    width: calc(12.5% - 6.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp95 > * {
    width: calc(12.5% - 6.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp96 > * {
    width: calc(12.5% - 6.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp97 > * {
    width: calc(12.5% - 6.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp98 > * {
    width: calc(12.5% - 7rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp99 > * {
    width: calc(12.5% - 7.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp100 > * {
    width: calc(12.5% - 7.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp101 > * {
    width: calc(12.5% - 7.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp102 > * {
    width: calc(12.5% - 7.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp103 > * {
    width: calc(12.5% - 7.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp104 > * {
    width: calc(12.5% - 7.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp105 > * {
    width: calc(12.5% - 7.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp106 > * {
    width: calc(12.5% - 7.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp107 > * {
    width: calc(12.5% - 7.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp108 > * {
    width: calc(12.5% - 7.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp109 > * {
    width: calc(12.5% - 7.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp110 > * {
    width: calc(12.5% - 7.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp111 > * {
    width: calc(12.5% - 7.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp112 > * {
    width: calc(12.5% - 8rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp113 > * {
    width: calc(12.5% - 8.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp114 > * {
    width: calc(12.5% - 8.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp115 > * {
    width: calc(12.5% - 8.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp116 > * {
    width: calc(12.5% - 8.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp117 > * {
    width: calc(12.5% - 8.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp118 > * {
    width: calc(12.5% - 8.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp119 > * {
    width: calc(12.5% - 8.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp120 > * {
    width: calc(12.5% - 8.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp121 > * {
    width: calc(12.5% - 8.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp122 > * {
    width: calc(12.5% - 8.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp123 > * {
    width: calc(12.5% - 8.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp124 > * {
    width: calc(12.5% - 8.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp125 > * {
    width: calc(12.5% - 8.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp126 > * {
    width: calc(12.5% - 9rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp127 > * {
    width: calc(12.5% - 9.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp128 > * {
    width: calc(12.5% - 9.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp129 > * {
    width: calc(12.5% - 9.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp130 > * {
    width: calc(12.5% - 9.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp131 > * {
    width: calc(12.5% - 9.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp132 > * {
    width: calc(12.5% - 9.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp133 > * {
    width: calc(12.5% - 9.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp134 > * {
    width: calc(12.5% - 9.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp135 > * {
    width: calc(12.5% - 9.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp136 > * {
    width: calc(12.5% - 9.7142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp137 > * {
    width: calc(12.5% - 9.7857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp138 > * {
    width: calc(12.5% - 9.8571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp139 > * {
    width: calc(12.5% - 9.9285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp140 > * {
    width: calc(12.5% - 10rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp141 > * {
    width: calc(12.5% - 10.0714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp142 > * {
    width: calc(12.5% - 10.1428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp143 > * {
    width: calc(12.5% - 10.2142857143rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp144 > * {
    width: calc(12.5% - 10.2857142857rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp145 > * {
    width: calc(12.5% - 10.3571428571rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp146 > * {
    width: calc(12.5% - 10.4285714286rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp147 > * {
    width: calc(12.5% - 10.5rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp148 > * {
    width: calc(12.5% - 10.5714285714rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp149 > * {
    width: calc(12.5% - 10.6428571429rem / 8 * 7);
  }
  .flex-sp-col8.gap-sp150 > * {
    width: calc(12.5% - 10.7142857143rem / 8 * 7);
  }
  .flex-sp-block[class*=gap] {
    gap: 1.7142857143rem;
  }
  .flex-sp-block > * {
    width: 100% !important;
  }
}
/* ##############################################################################

    FOOTER

############################################################################## */
.footer {
  background-color: var(--bg-primary);
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/footer_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .footer {
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/footer_bg_sp.png);
  }
}

.footer .inner.section_pdg {
  padding-bottom: 5.75rem;
}
@media screen and (max-width: 47.9375em) {
  .footer .inner.section_pdg {
    padding-top: 5.2857142857rem;
  }
}

/* footer_top
********************************************** */
/* ---fnav --- */
.fnav--menu a {
  display: block;
  padding: 0.5em 1em;
}
.fnav--menu a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu a {
    position: relative;
    padding: 0.5em;
  }
  .fnav--menu a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.5em;
    margin: auto;
    width: 0.4285714286rem;
    height: 0.4285714286rem;
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
  }
}

.fnav--menu .sub-menu {
  font-size: max(13px, 0.9375rem);
  line-height: 1;
  opacity: 0.75;
}

/* ---sns_area --- */
.sns_area a {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
.sns_area a:hover {
  opacity: 0.7;
}

.sns_area svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: 0.5rem 0;
}

@media print, screen and (min-width: 48em) {
  .footer_btm:has(.privacy) > .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 47.9375em) {
  .privacy {
    margin-bottom: 0.5em;
  }
}

.privacy a:hover {
  opacity: 0.7;
}

.copyright {
  gap: 1em;
  justify-content: center;
}
@media screen and (max-width: 47.9375em) {
  .copyright {
    gap: 0.5em;
  }
}

.pbl a,
.pbl img {
  display: block;
}

.pbl a {
  opacity: 0.5;
}
.pbl a:hover {
  opacity: 1;
}

/* ##############################################################################

    スタンダード用

############################################################################## */
/* footer-simple
*************************************************** */
.footer-simple {
  padding: 0.5rem 0;
}

@media print, screen and (min-width: 48em) {
  .footer-simple:has(.privacy) > .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

/* footer-01
  *************************************************** */
.footer-01 {
  position: relative;
  overflow: hidden;
}
.footer-01 > * {
  font-family: var(--font-secondary);
}
.footer-01 .inner {
  max-width: 85rem;
}
.footer-01 .footer--logo {
  margin-bottom: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .footer--logo {
    margin-bottom: 1rem;
  }
}
.footer-01 .footer--logo span {
  top: calc(-1em - 0.4375rem);
  left: 0;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  color: var(--bg-main-hvr);
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .footer--logo img {
    max-width: 80%;
  }
}
.footer-01 .footer--company {
  color: #9e6c00;
}
.footer-01 .footer--address {
  margin-bottom: 8.75rem;
  font-size: max(13px, 0.9375rem);
}
@media print, screen and (min-width: 120.0625em) {
  .footer-01 .footer--address {
    margin-bottom: 11.25rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .footer--address {
    margin-bottom: 4.6875rem;
  }
}
.footer-01 .footer--address-company {
  position: relative;
  margin-bottom: 1.5rem;
  line-height: 1.5;
  color: #9e6c00;
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .footer--address-company {
    margin-bottom: 0.8571428571rem;
  }
}
.footer-01 .footer--address-company::before {
  content: "";
  position: absolute;
  bottom: -0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 1px;
  background-color: #c48400;
  border-radius: 0.5px;
  opacity: 0.25;
}
.footer-01 .footer--address-address {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-size: 0.9375rem;
  color: rgba(158, 108, 0, 0.75);
  line-height: 2.4;
  letter-spacing: 0.1em;
}
.footer-01 .footer--address-address svg {
  fill: var(--clr-orange);
}
.footer-01 .footer--address-tel a {
  color: #9e6c00;
  font-size: 0.9375rem;
  font-family: var(--font-en);
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.64;
}
.footer-01 .footer--address-tel a svg {
  width: 0.75rem;
  aspect-ratio: 12/16;
}
.footer-01 .fnav {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .fnav {
    margin-bottom: 1rem;
  }
}
.footer-01 .footer--cta {
  margin-bottom: 3rem;
}
.footer-01 .footer--cta a {
  margin-inline: auto;
  text-align: center;
}
.footer-01 .fnav--menu {
  justify-content: space-between;
  line-height: 1;
}
@media print, screen and (min-width: 48em) {
  .footer-01 .fnav--menu {
    gap: 1.5em;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .fnav--menu {
    padding-top: 2.8571428571rem;
  }
}
.footer-01 .fnav--menu a {
  padding: 1.2em 0;
  color: var(--clr-wht);
  letter-spacing: 0.1em;
  font-size: 1.125rem;
}
@media print, screen and (min-width: 48em) {
  .footer-01 .fnav--menu a {
    margin-block: -1em;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .fnav--menu a {
    padding: 0.5em 0;
  }
}
.footer-01 .fnav--menu .sub-menu a {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.75);
}
@media screen and (max-width: 47.9375em) {
  .footer-01 .fnav--menu .sub-menu a {
    padding-left: 1em;
    font-size: 1.1428571429rem;
  }
}
.footer-01 .sns_area ul {
  justify-content: center;
}
.footer-01 .copyright {
  justify-content: flex-start;
  margin-top: 1rem;
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.25);
}
.footer-01 .copyright small {
  font-size: 0.75rem;
}
.footer-01 .privacy a {
  font-size: 0.8125rem;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.5);
}

.grid {
  display: grid;
}

[class*=grid-] {
  display: grid;
}

.grid-col1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-col2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-col3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-col4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-col5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-col6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-col7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-col8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-rgt1 {
  grid-template-columns: auto 0.0625rem;
}

.grid-rgt2 {
  grid-template-columns: auto 0.125rem;
}

.grid-rgt3 {
  grid-template-columns: auto 0.1875rem;
}

.grid-rgt4 {
  grid-template-columns: auto 0.25rem;
}

.grid-rgt5 {
  grid-template-columns: auto 0.3125rem;
}

.grid-rgt6 {
  grid-template-columns: auto 0.375rem;
}

.grid-rgt7 {
  grid-template-columns: auto 0.4375rem;
}

.grid-rgt8 {
  grid-template-columns: auto 0.5rem;
}

.grid-rgt9 {
  grid-template-columns: auto 0.5625rem;
}

.grid-rgt10 {
  grid-template-columns: auto 0.625rem;
}

.grid-rgt11 {
  grid-template-columns: auto 0.6875rem;
}

.grid-rgt12 {
  grid-template-columns: auto 0.75rem;
}

.grid-rgt13 {
  grid-template-columns: auto 0.8125rem;
}

.grid-rgt14 {
  grid-template-columns: auto 0.875rem;
}

.grid-rgt15 {
  grid-template-columns: auto 0.9375rem;
}

.grid-rgt16 {
  grid-template-columns: auto 1rem;
}

.grid-rgt17 {
  grid-template-columns: auto 1.0625rem;
}

.grid-rgt18 {
  grid-template-columns: auto 1.125rem;
}

.grid-rgt19 {
  grid-template-columns: auto 1.1875rem;
}

.grid-rgt20 {
  grid-template-columns: auto 1.25rem;
}

.grid-rgt21 {
  grid-template-columns: auto 1.3125rem;
}

.grid-rgt22 {
  grid-template-columns: auto 1.375rem;
}

.grid-rgt23 {
  grid-template-columns: auto 1.4375rem;
}

.grid-rgt24 {
  grid-template-columns: auto 1.5rem;
}

.grid-rgt25 {
  grid-template-columns: auto 1.5625rem;
}

.grid-rgt26 {
  grid-template-columns: auto 1.625rem;
}

.grid-rgt27 {
  grid-template-columns: auto 1.6875rem;
}

.grid-rgt28 {
  grid-template-columns: auto 1.75rem;
}

.grid-rgt29 {
  grid-template-columns: auto 1.8125rem;
}

.grid-rgt30 {
  grid-template-columns: auto 1.875rem;
}

.grid-rgt31 {
  grid-template-columns: auto 1.9375rem;
}

.grid-rgt32 {
  grid-template-columns: auto 2rem;
}

.grid-rgt33 {
  grid-template-columns: auto 2.0625rem;
}

.grid-rgt34 {
  grid-template-columns: auto 2.125rem;
}

.grid-rgt35 {
  grid-template-columns: auto 2.1875rem;
}

.grid-rgt36 {
  grid-template-columns: auto 2.25rem;
}

.grid-rgt37 {
  grid-template-columns: auto 2.3125rem;
}

.grid-rgt38 {
  grid-template-columns: auto 2.375rem;
}

.grid-rgt39 {
  grid-template-columns: auto 2.4375rem;
}

.grid-rgt40 {
  grid-template-columns: auto 2.5rem;
}

.grid-rgt41 {
  grid-template-columns: auto 2.5625rem;
}

.grid-rgt42 {
  grid-template-columns: auto 2.625rem;
}

.grid-rgt43 {
  grid-template-columns: auto 2.6875rem;
}

.grid-rgt44 {
  grid-template-columns: auto 2.75rem;
}

.grid-rgt45 {
  grid-template-columns: auto 2.8125rem;
}

.grid-rgt46 {
  grid-template-columns: auto 2.875rem;
}

.grid-rgt47 {
  grid-template-columns: auto 2.9375rem;
}

.grid-rgt48 {
  grid-template-columns: auto 3rem;
}

.grid-rgt49 {
  grid-template-columns: auto 3.0625rem;
}

.grid-rgt50 {
  grid-template-columns: auto 3.125rem;
}

.grid-rgt51 {
  grid-template-columns: auto 3.1875rem;
}

.grid-rgt52 {
  grid-template-columns: auto 3.25rem;
}

.grid-rgt53 {
  grid-template-columns: auto 3.3125rem;
}

.grid-rgt54 {
  grid-template-columns: auto 3.375rem;
}

.grid-rgt55 {
  grid-template-columns: auto 3.4375rem;
}

.grid-rgt56 {
  grid-template-columns: auto 3.5rem;
}

.grid-rgt57 {
  grid-template-columns: auto 3.5625rem;
}

.grid-rgt58 {
  grid-template-columns: auto 3.625rem;
}

.grid-rgt59 {
  grid-template-columns: auto 3.6875rem;
}

.grid-rgt60 {
  grid-template-columns: auto 3.75rem;
}

.grid-rgt61 {
  grid-template-columns: auto 3.8125rem;
}

.grid-rgt62 {
  grid-template-columns: auto 3.875rem;
}

.grid-rgt63 {
  grid-template-columns: auto 3.9375rem;
}

.grid-rgt64 {
  grid-template-columns: auto 4rem;
}

.grid-rgt65 {
  grid-template-columns: auto 4.0625rem;
}

.grid-rgt66 {
  grid-template-columns: auto 4.125rem;
}

.grid-rgt67 {
  grid-template-columns: auto 4.1875rem;
}

.grid-rgt68 {
  grid-template-columns: auto 4.25rem;
}

.grid-rgt69 {
  grid-template-columns: auto 4.3125rem;
}

.grid-rgt70 {
  grid-template-columns: auto 4.375rem;
}

.grid-rgt71 {
  grid-template-columns: auto 4.4375rem;
}

.grid-rgt72 {
  grid-template-columns: auto 4.5rem;
}

.grid-rgt73 {
  grid-template-columns: auto 4.5625rem;
}

.grid-rgt74 {
  grid-template-columns: auto 4.625rem;
}

.grid-rgt75 {
  grid-template-columns: auto 4.6875rem;
}

.grid-rgt76 {
  grid-template-columns: auto 4.75rem;
}

.grid-rgt77 {
  grid-template-columns: auto 4.8125rem;
}

.grid-rgt78 {
  grid-template-columns: auto 4.875rem;
}

.grid-rgt79 {
  grid-template-columns: auto 4.9375rem;
}

.grid-rgt80 {
  grid-template-columns: auto 5rem;
}

.grid-rgt81 {
  grid-template-columns: auto 5.0625rem;
}

.grid-rgt82 {
  grid-template-columns: auto 5.125rem;
}

.grid-rgt83 {
  grid-template-columns: auto 5.1875rem;
}

.grid-rgt84 {
  grid-template-columns: auto 5.25rem;
}

.grid-rgt85 {
  grid-template-columns: auto 5.3125rem;
}

.grid-rgt86 {
  grid-template-columns: auto 5.375rem;
}

.grid-rgt87 {
  grid-template-columns: auto 5.4375rem;
}

.grid-rgt88 {
  grid-template-columns: auto 5.5rem;
}

.grid-rgt89 {
  grid-template-columns: auto 5.5625rem;
}

.grid-rgt90 {
  grid-template-columns: auto 5.625rem;
}

.grid-rgt91 {
  grid-template-columns: auto 5.6875rem;
}

.grid-rgt92 {
  grid-template-columns: auto 5.75rem;
}

.grid-rgt93 {
  grid-template-columns: auto 5.8125rem;
}

.grid-rgt94 {
  grid-template-columns: auto 5.875rem;
}

.grid-rgt95 {
  grid-template-columns: auto 5.9375rem;
}

.grid-rgt96 {
  grid-template-columns: auto 6rem;
}

.grid-rgt97 {
  grid-template-columns: auto 6.0625rem;
}

.grid-rgt98 {
  grid-template-columns: auto 6.125rem;
}

.grid-rgt99 {
  grid-template-columns: auto 6.1875rem;
}

.grid-rgt100 {
  grid-template-columns: auto 6.25rem;
}

.grid-rgt101 {
  grid-template-columns: auto 6.3125rem;
}

.grid-rgt102 {
  grid-template-columns: auto 6.375rem;
}

.grid-rgt103 {
  grid-template-columns: auto 6.4375rem;
}

.grid-rgt104 {
  grid-template-columns: auto 6.5rem;
}

.grid-rgt105 {
  grid-template-columns: auto 6.5625rem;
}

.grid-rgt106 {
  grid-template-columns: auto 6.625rem;
}

.grid-rgt107 {
  grid-template-columns: auto 6.6875rem;
}

.grid-rgt108 {
  grid-template-columns: auto 6.75rem;
}

.grid-rgt109 {
  grid-template-columns: auto 6.8125rem;
}

.grid-rgt110 {
  grid-template-columns: auto 6.875rem;
}

.grid-rgt111 {
  grid-template-columns: auto 6.9375rem;
}

.grid-rgt112 {
  grid-template-columns: auto 7rem;
}

.grid-rgt113 {
  grid-template-columns: auto 7.0625rem;
}

.grid-rgt114 {
  grid-template-columns: auto 7.125rem;
}

.grid-rgt115 {
  grid-template-columns: auto 7.1875rem;
}

.grid-rgt116 {
  grid-template-columns: auto 7.25rem;
}

.grid-rgt117 {
  grid-template-columns: auto 7.3125rem;
}

.grid-rgt118 {
  grid-template-columns: auto 7.375rem;
}

.grid-rgt119 {
  grid-template-columns: auto 7.4375rem;
}

.grid-rgt120 {
  grid-template-columns: auto 7.5rem;
}

.grid-rgt121 {
  grid-template-columns: auto 7.5625rem;
}

.grid-rgt122 {
  grid-template-columns: auto 7.625rem;
}

.grid-rgt123 {
  grid-template-columns: auto 7.6875rem;
}

.grid-rgt124 {
  grid-template-columns: auto 7.75rem;
}

.grid-rgt125 {
  grid-template-columns: auto 7.8125rem;
}

.grid-rgt126 {
  grid-template-columns: auto 7.875rem;
}

.grid-rgt127 {
  grid-template-columns: auto 7.9375rem;
}

.grid-rgt128 {
  grid-template-columns: auto 8rem;
}

.grid-rgt129 {
  grid-template-columns: auto 8.0625rem;
}

.grid-rgt130 {
  grid-template-columns: auto 8.125rem;
}

.grid-rgt131 {
  grid-template-columns: auto 8.1875rem;
}

.grid-rgt132 {
  grid-template-columns: auto 8.25rem;
}

.grid-rgt133 {
  grid-template-columns: auto 8.3125rem;
}

.grid-rgt134 {
  grid-template-columns: auto 8.375rem;
}

.grid-rgt135 {
  grid-template-columns: auto 8.4375rem;
}

.grid-rgt136 {
  grid-template-columns: auto 8.5rem;
}

.grid-rgt137 {
  grid-template-columns: auto 8.5625rem;
}

.grid-rgt138 {
  grid-template-columns: auto 8.625rem;
}

.grid-rgt139 {
  grid-template-columns: auto 8.6875rem;
}

.grid-rgt140 {
  grid-template-columns: auto 8.75rem;
}

.grid-rgt141 {
  grid-template-columns: auto 8.8125rem;
}

.grid-rgt142 {
  grid-template-columns: auto 8.875rem;
}

.grid-rgt143 {
  grid-template-columns: auto 8.9375rem;
}

.grid-rgt144 {
  grid-template-columns: auto 9rem;
}

.grid-rgt145 {
  grid-template-columns: auto 9.0625rem;
}

.grid-rgt146 {
  grid-template-columns: auto 9.125rem;
}

.grid-rgt147 {
  grid-template-columns: auto 9.1875rem;
}

.grid-rgt148 {
  grid-template-columns: auto 9.25rem;
}

.grid-rgt149 {
  grid-template-columns: auto 9.3125rem;
}

.grid-rgt150 {
  grid-template-columns: auto 9.375rem;
}

.grid-rgt151 {
  grid-template-columns: auto 9.4375rem;
}

.grid-rgt152 {
  grid-template-columns: auto 9.5rem;
}

.grid-rgt153 {
  grid-template-columns: auto 9.5625rem;
}

.grid-rgt154 {
  grid-template-columns: auto 9.625rem;
}

.grid-rgt155 {
  grid-template-columns: auto 9.6875rem;
}

.grid-rgt156 {
  grid-template-columns: auto 9.75rem;
}

.grid-rgt157 {
  grid-template-columns: auto 9.8125rem;
}

.grid-rgt158 {
  grid-template-columns: auto 9.875rem;
}

.grid-rgt159 {
  grid-template-columns: auto 9.9375rem;
}

.grid-rgt160 {
  grid-template-columns: auto 10rem;
}

.grid-rgt161 {
  grid-template-columns: auto 10.0625rem;
}

.grid-rgt162 {
  grid-template-columns: auto 10.125rem;
}

.grid-rgt163 {
  grid-template-columns: auto 10.1875rem;
}

.grid-rgt164 {
  grid-template-columns: auto 10.25rem;
}

.grid-rgt165 {
  grid-template-columns: auto 10.3125rem;
}

.grid-rgt166 {
  grid-template-columns: auto 10.375rem;
}

.grid-rgt167 {
  grid-template-columns: auto 10.4375rem;
}

.grid-rgt168 {
  grid-template-columns: auto 10.5rem;
}

.grid-rgt169 {
  grid-template-columns: auto 10.5625rem;
}

.grid-rgt170 {
  grid-template-columns: auto 10.625rem;
}

.grid-rgt171 {
  grid-template-columns: auto 10.6875rem;
}

.grid-rgt172 {
  grid-template-columns: auto 10.75rem;
}

.grid-rgt173 {
  grid-template-columns: auto 10.8125rem;
}

.grid-rgt174 {
  grid-template-columns: auto 10.875rem;
}

.grid-rgt175 {
  grid-template-columns: auto 10.9375rem;
}

.grid-rgt176 {
  grid-template-columns: auto 11rem;
}

.grid-rgt177 {
  grid-template-columns: auto 11.0625rem;
}

.grid-rgt178 {
  grid-template-columns: auto 11.125rem;
}

.grid-rgt179 {
  grid-template-columns: auto 11.1875rem;
}

.grid-rgt180 {
  grid-template-columns: auto 11.25rem;
}

.grid-rgt181 {
  grid-template-columns: auto 11.3125rem;
}

.grid-rgt182 {
  grid-template-columns: auto 11.375rem;
}

.grid-rgt183 {
  grid-template-columns: auto 11.4375rem;
}

.grid-rgt184 {
  grid-template-columns: auto 11.5rem;
}

.grid-rgt185 {
  grid-template-columns: auto 11.5625rem;
}

.grid-rgt186 {
  grid-template-columns: auto 11.625rem;
}

.grid-rgt187 {
  grid-template-columns: auto 11.6875rem;
}

.grid-rgt188 {
  grid-template-columns: auto 11.75rem;
}

.grid-rgt189 {
  grid-template-columns: auto 11.8125rem;
}

.grid-rgt190 {
  grid-template-columns: auto 11.875rem;
}

.grid-rgt191 {
  grid-template-columns: auto 11.9375rem;
}

.grid-rgt192 {
  grid-template-columns: auto 12rem;
}

.grid-rgt193 {
  grid-template-columns: auto 12.0625rem;
}

.grid-rgt194 {
  grid-template-columns: auto 12.125rem;
}

.grid-rgt195 {
  grid-template-columns: auto 12.1875rem;
}

.grid-rgt196 {
  grid-template-columns: auto 12.25rem;
}

.grid-rgt197 {
  grid-template-columns: auto 12.3125rem;
}

.grid-rgt198 {
  grid-template-columns: auto 12.375rem;
}

.grid-rgt199 {
  grid-template-columns: auto 12.4375rem;
}

.grid-rgt200 {
  grid-template-columns: auto 12.5rem;
}

.grid-rgt201 {
  grid-template-columns: auto 12.5625rem;
}

.grid-rgt202 {
  grid-template-columns: auto 12.625rem;
}

.grid-rgt203 {
  grid-template-columns: auto 12.6875rem;
}

.grid-rgt204 {
  grid-template-columns: auto 12.75rem;
}

.grid-rgt205 {
  grid-template-columns: auto 12.8125rem;
}

.grid-rgt206 {
  grid-template-columns: auto 12.875rem;
}

.grid-rgt207 {
  grid-template-columns: auto 12.9375rem;
}

.grid-rgt208 {
  grid-template-columns: auto 13rem;
}

.grid-rgt209 {
  grid-template-columns: auto 13.0625rem;
}

.grid-rgt210 {
  grid-template-columns: auto 13.125rem;
}

.grid-rgt211 {
  grid-template-columns: auto 13.1875rem;
}

.grid-rgt212 {
  grid-template-columns: auto 13.25rem;
}

.grid-rgt213 {
  grid-template-columns: auto 13.3125rem;
}

.grid-rgt214 {
  grid-template-columns: auto 13.375rem;
}

.grid-rgt215 {
  grid-template-columns: auto 13.4375rem;
}

.grid-rgt216 {
  grid-template-columns: auto 13.5rem;
}

.grid-rgt217 {
  grid-template-columns: auto 13.5625rem;
}

.grid-rgt218 {
  grid-template-columns: auto 13.625rem;
}

.grid-rgt219 {
  grid-template-columns: auto 13.6875rem;
}

.grid-rgt220 {
  grid-template-columns: auto 13.75rem;
}

.grid-rgt221 {
  grid-template-columns: auto 13.8125rem;
}

.grid-rgt222 {
  grid-template-columns: auto 13.875rem;
}

.grid-rgt223 {
  grid-template-columns: auto 13.9375rem;
}

.grid-rgt224 {
  grid-template-columns: auto 14rem;
}

.grid-rgt225 {
  grid-template-columns: auto 14.0625rem;
}

.grid-rgt226 {
  grid-template-columns: auto 14.125rem;
}

.grid-rgt227 {
  grid-template-columns: auto 14.1875rem;
}

.grid-rgt228 {
  grid-template-columns: auto 14.25rem;
}

.grid-rgt229 {
  grid-template-columns: auto 14.3125rem;
}

.grid-rgt230 {
  grid-template-columns: auto 14.375rem;
}

.grid-rgt231 {
  grid-template-columns: auto 14.4375rem;
}

.grid-rgt232 {
  grid-template-columns: auto 14.5rem;
}

.grid-rgt233 {
  grid-template-columns: auto 14.5625rem;
}

.grid-rgt234 {
  grid-template-columns: auto 14.625rem;
}

.grid-rgt235 {
  grid-template-columns: auto 14.6875rem;
}

.grid-rgt236 {
  grid-template-columns: auto 14.75rem;
}

.grid-rgt237 {
  grid-template-columns: auto 14.8125rem;
}

.grid-rgt238 {
  grid-template-columns: auto 14.875rem;
}

.grid-rgt239 {
  grid-template-columns: auto 14.9375rem;
}

.grid-rgt240 {
  grid-template-columns: auto 15rem;
}

.grid-rgt241 {
  grid-template-columns: auto 15.0625rem;
}

.grid-rgt242 {
  grid-template-columns: auto 15.125rem;
}

.grid-rgt243 {
  grid-template-columns: auto 15.1875rem;
}

.grid-rgt244 {
  grid-template-columns: auto 15.25rem;
}

.grid-rgt245 {
  grid-template-columns: auto 15.3125rem;
}

.grid-rgt246 {
  grid-template-columns: auto 15.375rem;
}

.grid-rgt247 {
  grid-template-columns: auto 15.4375rem;
}

.grid-rgt248 {
  grid-template-columns: auto 15.5rem;
}

.grid-rgt249 {
  grid-template-columns: auto 15.5625rem;
}

.grid-rgt250 {
  grid-template-columns: auto 15.625rem;
}

.grid-rgt251 {
  grid-template-columns: auto 15.6875rem;
}

.grid-rgt252 {
  grid-template-columns: auto 15.75rem;
}

.grid-rgt253 {
  grid-template-columns: auto 15.8125rem;
}

.grid-rgt254 {
  grid-template-columns: auto 15.875rem;
}

.grid-rgt255 {
  grid-template-columns: auto 15.9375rem;
}

.grid-rgt256 {
  grid-template-columns: auto 16rem;
}

.grid-rgt257 {
  grid-template-columns: auto 16.0625rem;
}

.grid-rgt258 {
  grid-template-columns: auto 16.125rem;
}

.grid-rgt259 {
  grid-template-columns: auto 16.1875rem;
}

.grid-rgt260 {
  grid-template-columns: auto 16.25rem;
}

.grid-rgt261 {
  grid-template-columns: auto 16.3125rem;
}

.grid-rgt262 {
  grid-template-columns: auto 16.375rem;
}

.grid-rgt263 {
  grid-template-columns: auto 16.4375rem;
}

.grid-rgt264 {
  grid-template-columns: auto 16.5rem;
}

.grid-rgt265 {
  grid-template-columns: auto 16.5625rem;
}

.grid-rgt266 {
  grid-template-columns: auto 16.625rem;
}

.grid-rgt267 {
  grid-template-columns: auto 16.6875rem;
}

.grid-rgt268 {
  grid-template-columns: auto 16.75rem;
}

.grid-rgt269 {
  grid-template-columns: auto 16.8125rem;
}

.grid-rgt270 {
  grid-template-columns: auto 16.875rem;
}

.grid-rgt271 {
  grid-template-columns: auto 16.9375rem;
}

.grid-rgt272 {
  grid-template-columns: auto 17rem;
}

.grid-rgt273 {
  grid-template-columns: auto 17.0625rem;
}

.grid-rgt274 {
  grid-template-columns: auto 17.125rem;
}

.grid-rgt275 {
  grid-template-columns: auto 17.1875rem;
}

.grid-rgt276 {
  grid-template-columns: auto 17.25rem;
}

.grid-rgt277 {
  grid-template-columns: auto 17.3125rem;
}

.grid-rgt278 {
  grid-template-columns: auto 17.375rem;
}

.grid-rgt279 {
  grid-template-columns: auto 17.4375rem;
}

.grid-rgt280 {
  grid-template-columns: auto 17.5rem;
}

.grid-rgt281 {
  grid-template-columns: auto 17.5625rem;
}

.grid-rgt282 {
  grid-template-columns: auto 17.625rem;
}

.grid-rgt283 {
  grid-template-columns: auto 17.6875rem;
}

.grid-rgt284 {
  grid-template-columns: auto 17.75rem;
}

.grid-rgt285 {
  grid-template-columns: auto 17.8125rem;
}

.grid-rgt286 {
  grid-template-columns: auto 17.875rem;
}

.grid-rgt287 {
  grid-template-columns: auto 17.9375rem;
}

.grid-rgt288 {
  grid-template-columns: auto 18rem;
}

.grid-rgt289 {
  grid-template-columns: auto 18.0625rem;
}

.grid-rgt290 {
  grid-template-columns: auto 18.125rem;
}

.grid-rgt291 {
  grid-template-columns: auto 18.1875rem;
}

.grid-rgt292 {
  grid-template-columns: auto 18.25rem;
}

.grid-rgt293 {
  grid-template-columns: auto 18.3125rem;
}

.grid-rgt294 {
  grid-template-columns: auto 18.375rem;
}

.grid-rgt295 {
  grid-template-columns: auto 18.4375rem;
}

.grid-rgt296 {
  grid-template-columns: auto 18.5rem;
}

.grid-rgt297 {
  grid-template-columns: auto 18.5625rem;
}

.grid-rgt298 {
  grid-template-columns: auto 18.625rem;
}

.grid-rgt299 {
  grid-template-columns: auto 18.6875rem;
}

.grid-rgt300 {
  grid-template-columns: auto 18.75rem;
}

.grid-rgt301 {
  grid-template-columns: auto 18.8125rem;
}

.grid-rgt302 {
  grid-template-columns: auto 18.875rem;
}

.grid-rgt303 {
  grid-template-columns: auto 18.9375rem;
}

.grid-rgt304 {
  grid-template-columns: auto 19rem;
}

.grid-rgt305 {
  grid-template-columns: auto 19.0625rem;
}

.grid-rgt306 {
  grid-template-columns: auto 19.125rem;
}

.grid-rgt307 {
  grid-template-columns: auto 19.1875rem;
}

.grid-rgt308 {
  grid-template-columns: auto 19.25rem;
}

.grid-rgt309 {
  grid-template-columns: auto 19.3125rem;
}

.grid-rgt310 {
  grid-template-columns: auto 19.375rem;
}

.grid-rgt311 {
  grid-template-columns: auto 19.4375rem;
}

.grid-rgt312 {
  grid-template-columns: auto 19.5rem;
}

.grid-rgt313 {
  grid-template-columns: auto 19.5625rem;
}

.grid-rgt314 {
  grid-template-columns: auto 19.625rem;
}

.grid-rgt315 {
  grid-template-columns: auto 19.6875rem;
}

.grid-rgt316 {
  grid-template-columns: auto 19.75rem;
}

.grid-rgt317 {
  grid-template-columns: auto 19.8125rem;
}

.grid-rgt318 {
  grid-template-columns: auto 19.875rem;
}

.grid-rgt319 {
  grid-template-columns: auto 19.9375rem;
}

.grid-rgt320 {
  grid-template-columns: auto 20rem;
}

.grid-rgt321 {
  grid-template-columns: auto 20.0625rem;
}

.grid-rgt322 {
  grid-template-columns: auto 20.125rem;
}

.grid-rgt323 {
  grid-template-columns: auto 20.1875rem;
}

.grid-rgt324 {
  grid-template-columns: auto 20.25rem;
}

.grid-rgt325 {
  grid-template-columns: auto 20.3125rem;
}

.grid-rgt326 {
  grid-template-columns: auto 20.375rem;
}

.grid-rgt327 {
  grid-template-columns: auto 20.4375rem;
}

.grid-rgt328 {
  grid-template-columns: auto 20.5rem;
}

.grid-rgt329 {
  grid-template-columns: auto 20.5625rem;
}

.grid-rgt330 {
  grid-template-columns: auto 20.625rem;
}

.grid-rgt331 {
  grid-template-columns: auto 20.6875rem;
}

.grid-rgt332 {
  grid-template-columns: auto 20.75rem;
}

.grid-rgt333 {
  grid-template-columns: auto 20.8125rem;
}

.grid-rgt334 {
  grid-template-columns: auto 20.875rem;
}

.grid-rgt335 {
  grid-template-columns: auto 20.9375rem;
}

.grid-rgt336 {
  grid-template-columns: auto 21rem;
}

.grid-rgt337 {
  grid-template-columns: auto 21.0625rem;
}

.grid-rgt338 {
  grid-template-columns: auto 21.125rem;
}

.grid-rgt339 {
  grid-template-columns: auto 21.1875rem;
}

.grid-rgt340 {
  grid-template-columns: auto 21.25rem;
}

.grid-rgt341 {
  grid-template-columns: auto 21.3125rem;
}

.grid-rgt342 {
  grid-template-columns: auto 21.375rem;
}

.grid-rgt343 {
  grid-template-columns: auto 21.4375rem;
}

.grid-rgt344 {
  grid-template-columns: auto 21.5rem;
}

.grid-rgt345 {
  grid-template-columns: auto 21.5625rem;
}

.grid-rgt346 {
  grid-template-columns: auto 21.625rem;
}

.grid-rgt347 {
  grid-template-columns: auto 21.6875rem;
}

.grid-rgt348 {
  grid-template-columns: auto 21.75rem;
}

.grid-rgt349 {
  grid-template-columns: auto 21.8125rem;
}

.grid-rgt350 {
  grid-template-columns: auto 21.875rem;
}

.grid-rgt351 {
  grid-template-columns: auto 21.9375rem;
}

.grid-rgt352 {
  grid-template-columns: auto 22rem;
}

.grid-rgt353 {
  grid-template-columns: auto 22.0625rem;
}

.grid-rgt354 {
  grid-template-columns: auto 22.125rem;
}

.grid-rgt355 {
  grid-template-columns: auto 22.1875rem;
}

.grid-rgt356 {
  grid-template-columns: auto 22.25rem;
}

.grid-rgt357 {
  grid-template-columns: auto 22.3125rem;
}

.grid-rgt358 {
  grid-template-columns: auto 22.375rem;
}

.grid-rgt359 {
  grid-template-columns: auto 22.4375rem;
}

.grid-rgt360 {
  grid-template-columns: auto 22.5rem;
}

.grid-rgt361 {
  grid-template-columns: auto 22.5625rem;
}

.grid-rgt362 {
  grid-template-columns: auto 22.625rem;
}

.grid-rgt363 {
  grid-template-columns: auto 22.6875rem;
}

.grid-rgt364 {
  grid-template-columns: auto 22.75rem;
}

.grid-rgt365 {
  grid-template-columns: auto 22.8125rem;
}

.grid-rgt366 {
  grid-template-columns: auto 22.875rem;
}

.grid-rgt367 {
  grid-template-columns: auto 22.9375rem;
}

.grid-rgt368 {
  grid-template-columns: auto 23rem;
}

.grid-rgt369 {
  grid-template-columns: auto 23.0625rem;
}

.grid-rgt370 {
  grid-template-columns: auto 23.125rem;
}

.grid-rgt371 {
  grid-template-columns: auto 23.1875rem;
}

.grid-rgt372 {
  grid-template-columns: auto 23.25rem;
}

.grid-rgt373 {
  grid-template-columns: auto 23.3125rem;
}

.grid-rgt374 {
  grid-template-columns: auto 23.375rem;
}

.grid-rgt375 {
  grid-template-columns: auto 23.4375rem;
}

.grid-rgt376 {
  grid-template-columns: auto 23.5rem;
}

.grid-rgt377 {
  grid-template-columns: auto 23.5625rem;
}

.grid-rgt378 {
  grid-template-columns: auto 23.625rem;
}

.grid-rgt379 {
  grid-template-columns: auto 23.6875rem;
}

.grid-rgt380 {
  grid-template-columns: auto 23.75rem;
}

.grid-rgt381 {
  grid-template-columns: auto 23.8125rem;
}

.grid-rgt382 {
  grid-template-columns: auto 23.875rem;
}

.grid-rgt383 {
  grid-template-columns: auto 23.9375rem;
}

.grid-rgt384 {
  grid-template-columns: auto 24rem;
}

.grid-rgt385 {
  grid-template-columns: auto 24.0625rem;
}

.grid-rgt386 {
  grid-template-columns: auto 24.125rem;
}

.grid-rgt387 {
  grid-template-columns: auto 24.1875rem;
}

.grid-rgt388 {
  grid-template-columns: auto 24.25rem;
}

.grid-rgt389 {
  grid-template-columns: auto 24.3125rem;
}

.grid-rgt390 {
  grid-template-columns: auto 24.375rem;
}

.grid-rgt391 {
  grid-template-columns: auto 24.4375rem;
}

.grid-rgt392 {
  grid-template-columns: auto 24.5rem;
}

.grid-rgt393 {
  grid-template-columns: auto 24.5625rem;
}

.grid-rgt394 {
  grid-template-columns: auto 24.625rem;
}

.grid-rgt395 {
  grid-template-columns: auto 24.6875rem;
}

.grid-rgt396 {
  grid-template-columns: auto 24.75rem;
}

.grid-rgt397 {
  grid-template-columns: auto 24.8125rem;
}

.grid-rgt398 {
  grid-template-columns: auto 24.875rem;
}

.grid-rgt399 {
  grid-template-columns: auto 24.9375rem;
}

.grid-rgt400 {
  grid-template-columns: auto 25rem;
}

.grid-rgt401 {
  grid-template-columns: auto 25.0625rem;
}

.grid-rgt402 {
  grid-template-columns: auto 25.125rem;
}

.grid-rgt403 {
  grid-template-columns: auto 25.1875rem;
}

.grid-rgt404 {
  grid-template-columns: auto 25.25rem;
}

.grid-rgt405 {
  grid-template-columns: auto 25.3125rem;
}

.grid-rgt406 {
  grid-template-columns: auto 25.375rem;
}

.grid-rgt407 {
  grid-template-columns: auto 25.4375rem;
}

.grid-rgt408 {
  grid-template-columns: auto 25.5rem;
}

.grid-rgt409 {
  grid-template-columns: auto 25.5625rem;
}

.grid-rgt410 {
  grid-template-columns: auto 25.625rem;
}

.grid-rgt411 {
  grid-template-columns: auto 25.6875rem;
}

.grid-rgt412 {
  grid-template-columns: auto 25.75rem;
}

.grid-rgt413 {
  grid-template-columns: auto 25.8125rem;
}

.grid-rgt414 {
  grid-template-columns: auto 25.875rem;
}

.grid-rgt415 {
  grid-template-columns: auto 25.9375rem;
}

.grid-rgt416 {
  grid-template-columns: auto 26rem;
}

.grid-rgt417 {
  grid-template-columns: auto 26.0625rem;
}

.grid-rgt418 {
  grid-template-columns: auto 26.125rem;
}

.grid-rgt419 {
  grid-template-columns: auto 26.1875rem;
}

.grid-rgt420 {
  grid-template-columns: auto 26.25rem;
}

.grid-rgt421 {
  grid-template-columns: auto 26.3125rem;
}

.grid-rgt422 {
  grid-template-columns: auto 26.375rem;
}

.grid-rgt423 {
  grid-template-columns: auto 26.4375rem;
}

.grid-rgt424 {
  grid-template-columns: auto 26.5rem;
}

.grid-rgt425 {
  grid-template-columns: auto 26.5625rem;
}

.grid-rgt426 {
  grid-template-columns: auto 26.625rem;
}

.grid-rgt427 {
  grid-template-columns: auto 26.6875rem;
}

.grid-rgt428 {
  grid-template-columns: auto 26.75rem;
}

.grid-rgt429 {
  grid-template-columns: auto 26.8125rem;
}

.grid-rgt430 {
  grid-template-columns: auto 26.875rem;
}

.grid-rgt431 {
  grid-template-columns: auto 26.9375rem;
}

.grid-rgt432 {
  grid-template-columns: auto 27rem;
}

.grid-rgt433 {
  grid-template-columns: auto 27.0625rem;
}

.grid-rgt434 {
  grid-template-columns: auto 27.125rem;
}

.grid-rgt435 {
  grid-template-columns: auto 27.1875rem;
}

.grid-rgt436 {
  grid-template-columns: auto 27.25rem;
}

.grid-rgt437 {
  grid-template-columns: auto 27.3125rem;
}

.grid-rgt438 {
  grid-template-columns: auto 27.375rem;
}

.grid-rgt439 {
  grid-template-columns: auto 27.4375rem;
}

.grid-rgt440 {
  grid-template-columns: auto 27.5rem;
}

.grid-rgt441 {
  grid-template-columns: auto 27.5625rem;
}

.grid-rgt442 {
  grid-template-columns: auto 27.625rem;
}

.grid-rgt443 {
  grid-template-columns: auto 27.6875rem;
}

.grid-rgt444 {
  grid-template-columns: auto 27.75rem;
}

.grid-rgt445 {
  grid-template-columns: auto 27.8125rem;
}

.grid-rgt446 {
  grid-template-columns: auto 27.875rem;
}

.grid-rgt447 {
  grid-template-columns: auto 27.9375rem;
}

.grid-rgt448 {
  grid-template-columns: auto 28rem;
}

.grid-rgt449 {
  grid-template-columns: auto 28.0625rem;
}

.grid-rgt450 {
  grid-template-columns: auto 28.125rem;
}

.grid-rgt451 {
  grid-template-columns: auto 28.1875rem;
}

.grid-rgt452 {
  grid-template-columns: auto 28.25rem;
}

.grid-rgt453 {
  grid-template-columns: auto 28.3125rem;
}

.grid-rgt454 {
  grid-template-columns: auto 28.375rem;
}

.grid-rgt455 {
  grid-template-columns: auto 28.4375rem;
}

.grid-rgt456 {
  grid-template-columns: auto 28.5rem;
}

.grid-rgt457 {
  grid-template-columns: auto 28.5625rem;
}

.grid-rgt458 {
  grid-template-columns: auto 28.625rem;
}

.grid-rgt459 {
  grid-template-columns: auto 28.6875rem;
}

.grid-rgt460 {
  grid-template-columns: auto 28.75rem;
}

.grid-rgt461 {
  grid-template-columns: auto 28.8125rem;
}

.grid-rgt462 {
  grid-template-columns: auto 28.875rem;
}

.grid-rgt463 {
  grid-template-columns: auto 28.9375rem;
}

.grid-rgt464 {
  grid-template-columns: auto 29rem;
}

.grid-rgt465 {
  grid-template-columns: auto 29.0625rem;
}

.grid-rgt466 {
  grid-template-columns: auto 29.125rem;
}

.grid-rgt467 {
  grid-template-columns: auto 29.1875rem;
}

.grid-rgt468 {
  grid-template-columns: auto 29.25rem;
}

.grid-rgt469 {
  grid-template-columns: auto 29.3125rem;
}

.grid-rgt470 {
  grid-template-columns: auto 29.375rem;
}

.grid-rgt471 {
  grid-template-columns: auto 29.4375rem;
}

.grid-rgt472 {
  grid-template-columns: auto 29.5rem;
}

.grid-rgt473 {
  grid-template-columns: auto 29.5625rem;
}

.grid-rgt474 {
  grid-template-columns: auto 29.625rem;
}

.grid-rgt475 {
  grid-template-columns: auto 29.6875rem;
}

.grid-rgt476 {
  grid-template-columns: auto 29.75rem;
}

.grid-rgt477 {
  grid-template-columns: auto 29.8125rem;
}

.grid-rgt478 {
  grid-template-columns: auto 29.875rem;
}

.grid-rgt479 {
  grid-template-columns: auto 29.9375rem;
}

.grid-rgt480 {
  grid-template-columns: auto 30rem;
}

.grid-rgt481 {
  grid-template-columns: auto 30.0625rem;
}

.grid-rgt482 {
  grid-template-columns: auto 30.125rem;
}

.grid-rgt483 {
  grid-template-columns: auto 30.1875rem;
}

.grid-rgt484 {
  grid-template-columns: auto 30.25rem;
}

.grid-rgt485 {
  grid-template-columns: auto 30.3125rem;
}

.grid-rgt486 {
  grid-template-columns: auto 30.375rem;
}

.grid-rgt487 {
  grid-template-columns: auto 30.4375rem;
}

.grid-rgt488 {
  grid-template-columns: auto 30.5rem;
}

.grid-rgt489 {
  grid-template-columns: auto 30.5625rem;
}

.grid-rgt490 {
  grid-template-columns: auto 30.625rem;
}

.grid-rgt491 {
  grid-template-columns: auto 30.6875rem;
}

.grid-rgt492 {
  grid-template-columns: auto 30.75rem;
}

.grid-rgt493 {
  grid-template-columns: auto 30.8125rem;
}

.grid-rgt494 {
  grid-template-columns: auto 30.875rem;
}

.grid-rgt495 {
  grid-template-columns: auto 30.9375rem;
}

.grid-rgt496 {
  grid-template-columns: auto 31rem;
}

.grid-rgt497 {
  grid-template-columns: auto 31.0625rem;
}

.grid-rgt498 {
  grid-template-columns: auto 31.125rem;
}

.grid-rgt499 {
  grid-template-columns: auto 31.1875rem;
}

.grid-rgt500 {
  grid-template-columns: auto 31.25rem;
}

.grid-rgt501 {
  grid-template-columns: auto 31.3125rem;
}

.grid-rgt502 {
  grid-template-columns: auto 31.375rem;
}

.grid-rgt503 {
  grid-template-columns: auto 31.4375rem;
}

.grid-rgt504 {
  grid-template-columns: auto 31.5rem;
}

.grid-rgt505 {
  grid-template-columns: auto 31.5625rem;
}

.grid-rgt506 {
  grid-template-columns: auto 31.625rem;
}

.grid-rgt507 {
  grid-template-columns: auto 31.6875rem;
}

.grid-rgt508 {
  grid-template-columns: auto 31.75rem;
}

.grid-rgt509 {
  grid-template-columns: auto 31.8125rem;
}

.grid-rgt510 {
  grid-template-columns: auto 31.875rem;
}

.grid-rgt511 {
  grid-template-columns: auto 31.9375rem;
}

.grid-rgt512 {
  grid-template-columns: auto 32rem;
}

.grid-rgt513 {
  grid-template-columns: auto 32.0625rem;
}

.grid-rgt514 {
  grid-template-columns: auto 32.125rem;
}

.grid-rgt515 {
  grid-template-columns: auto 32.1875rem;
}

.grid-rgt516 {
  grid-template-columns: auto 32.25rem;
}

.grid-rgt517 {
  grid-template-columns: auto 32.3125rem;
}

.grid-rgt518 {
  grid-template-columns: auto 32.375rem;
}

.grid-rgt519 {
  grid-template-columns: auto 32.4375rem;
}

.grid-rgt520 {
  grid-template-columns: auto 32.5rem;
}

.grid-rgt521 {
  grid-template-columns: auto 32.5625rem;
}

.grid-rgt522 {
  grid-template-columns: auto 32.625rem;
}

.grid-rgt523 {
  grid-template-columns: auto 32.6875rem;
}

.grid-rgt524 {
  grid-template-columns: auto 32.75rem;
}

.grid-rgt525 {
  grid-template-columns: auto 32.8125rem;
}

.grid-rgt526 {
  grid-template-columns: auto 32.875rem;
}

.grid-rgt527 {
  grid-template-columns: auto 32.9375rem;
}

.grid-rgt528 {
  grid-template-columns: auto 33rem;
}

.grid-rgt529 {
  grid-template-columns: auto 33.0625rem;
}

.grid-rgt530 {
  grid-template-columns: auto 33.125rem;
}

.grid-rgt531 {
  grid-template-columns: auto 33.1875rem;
}

.grid-rgt532 {
  grid-template-columns: auto 33.25rem;
}

.grid-rgt533 {
  grid-template-columns: auto 33.3125rem;
}

.grid-rgt534 {
  grid-template-columns: auto 33.375rem;
}

.grid-rgt535 {
  grid-template-columns: auto 33.4375rem;
}

.grid-rgt536 {
  grid-template-columns: auto 33.5rem;
}

.grid-rgt537 {
  grid-template-columns: auto 33.5625rem;
}

.grid-rgt538 {
  grid-template-columns: auto 33.625rem;
}

.grid-rgt539 {
  grid-template-columns: auto 33.6875rem;
}

.grid-rgt540 {
  grid-template-columns: auto 33.75rem;
}

.grid-rgt541 {
  grid-template-columns: auto 33.8125rem;
}

.grid-rgt542 {
  grid-template-columns: auto 33.875rem;
}

.grid-rgt543 {
  grid-template-columns: auto 33.9375rem;
}

.grid-rgt544 {
  grid-template-columns: auto 34rem;
}

.grid-rgt545 {
  grid-template-columns: auto 34.0625rem;
}

.grid-rgt546 {
  grid-template-columns: auto 34.125rem;
}

.grid-rgt547 {
  grid-template-columns: auto 34.1875rem;
}

.grid-rgt548 {
  grid-template-columns: auto 34.25rem;
}

.grid-rgt549 {
  grid-template-columns: auto 34.3125rem;
}

.grid-rgt550 {
  grid-template-columns: auto 34.375rem;
}

.grid-rgt551 {
  grid-template-columns: auto 34.4375rem;
}

.grid-rgt552 {
  grid-template-columns: auto 34.5rem;
}

.grid-rgt553 {
  grid-template-columns: auto 34.5625rem;
}

.grid-rgt554 {
  grid-template-columns: auto 34.625rem;
}

.grid-rgt555 {
  grid-template-columns: auto 34.6875rem;
}

.grid-rgt556 {
  grid-template-columns: auto 34.75rem;
}

.grid-rgt557 {
  grid-template-columns: auto 34.8125rem;
}

.grid-rgt558 {
  grid-template-columns: auto 34.875rem;
}

.grid-rgt559 {
  grid-template-columns: auto 34.9375rem;
}

.grid-rgt560 {
  grid-template-columns: auto 35rem;
}

.grid-rgt561 {
  grid-template-columns: auto 35.0625rem;
}

.grid-rgt562 {
  grid-template-columns: auto 35.125rem;
}

.grid-rgt563 {
  grid-template-columns: auto 35.1875rem;
}

.grid-rgt564 {
  grid-template-columns: auto 35.25rem;
}

.grid-rgt565 {
  grid-template-columns: auto 35.3125rem;
}

.grid-rgt566 {
  grid-template-columns: auto 35.375rem;
}

.grid-rgt567 {
  grid-template-columns: auto 35.4375rem;
}

.grid-rgt568 {
  grid-template-columns: auto 35.5rem;
}

.grid-rgt569 {
  grid-template-columns: auto 35.5625rem;
}

.grid-rgt570 {
  grid-template-columns: auto 35.625rem;
}

.grid-rgt571 {
  grid-template-columns: auto 35.6875rem;
}

.grid-rgt572 {
  grid-template-columns: auto 35.75rem;
}

.grid-rgt573 {
  grid-template-columns: auto 35.8125rem;
}

.grid-rgt574 {
  grid-template-columns: auto 35.875rem;
}

.grid-rgt575 {
  grid-template-columns: auto 35.9375rem;
}

.grid-rgt576 {
  grid-template-columns: auto 36rem;
}

.grid-rgt577 {
  grid-template-columns: auto 36.0625rem;
}

.grid-rgt578 {
  grid-template-columns: auto 36.125rem;
}

.grid-rgt579 {
  grid-template-columns: auto 36.1875rem;
}

.grid-rgt580 {
  grid-template-columns: auto 36.25rem;
}

.grid-rgt581 {
  grid-template-columns: auto 36.3125rem;
}

.grid-rgt582 {
  grid-template-columns: auto 36.375rem;
}

.grid-rgt583 {
  grid-template-columns: auto 36.4375rem;
}

.grid-rgt584 {
  grid-template-columns: auto 36.5rem;
}

.grid-rgt585 {
  grid-template-columns: auto 36.5625rem;
}

.grid-rgt586 {
  grid-template-columns: auto 36.625rem;
}

.grid-rgt587 {
  grid-template-columns: auto 36.6875rem;
}

.grid-rgt588 {
  grid-template-columns: auto 36.75rem;
}

.grid-rgt589 {
  grid-template-columns: auto 36.8125rem;
}

.grid-rgt590 {
  grid-template-columns: auto 36.875rem;
}

.grid-rgt591 {
  grid-template-columns: auto 36.9375rem;
}

.grid-rgt592 {
  grid-template-columns: auto 37rem;
}

.grid-rgt593 {
  grid-template-columns: auto 37.0625rem;
}

.grid-rgt594 {
  grid-template-columns: auto 37.125rem;
}

.grid-rgt595 {
  grid-template-columns: auto 37.1875rem;
}

.grid-rgt596 {
  grid-template-columns: auto 37.25rem;
}

.grid-rgt597 {
  grid-template-columns: auto 37.3125rem;
}

.grid-rgt598 {
  grid-template-columns: auto 37.375rem;
}

.grid-rgt599 {
  grid-template-columns: auto 37.4375rem;
}

.grid-rgt600 {
  grid-template-columns: auto 37.5rem;
}

.grid-lft1 {
  grid-template-columns: 0.0625rem auto;
}

.grid-lft2 {
  grid-template-columns: 0.125rem auto;
}

.grid-lft3 {
  grid-template-columns: 0.1875rem auto;
}

.grid-lft4 {
  grid-template-columns: 0.25rem auto;
}

.grid-lft5 {
  grid-template-columns: 0.3125rem auto;
}

.grid-lft6 {
  grid-template-columns: 0.375rem auto;
}

.grid-lft7 {
  grid-template-columns: 0.4375rem auto;
}

.grid-lft8 {
  grid-template-columns: 0.5rem auto;
}

.grid-lft9 {
  grid-template-columns: 0.5625rem auto;
}

.grid-lft10 {
  grid-template-columns: 0.625rem auto;
}

.grid-lft11 {
  grid-template-columns: 0.6875rem auto;
}

.grid-lft12 {
  grid-template-columns: 0.75rem auto;
}

.grid-lft13 {
  grid-template-columns: 0.8125rem auto;
}

.grid-lft14 {
  grid-template-columns: 0.875rem auto;
}

.grid-lft15 {
  grid-template-columns: 0.9375rem auto;
}

.grid-lft16 {
  grid-template-columns: 1rem auto;
}

.grid-lft17 {
  grid-template-columns: 1.0625rem auto;
}

.grid-lft18 {
  grid-template-columns: 1.125rem auto;
}

.grid-lft19 {
  grid-template-columns: 1.1875rem auto;
}

.grid-lft20 {
  grid-template-columns: 1.25rem auto;
}

.grid-lft21 {
  grid-template-columns: 1.3125rem auto;
}

.grid-lft22 {
  grid-template-columns: 1.375rem auto;
}

.grid-lft23 {
  grid-template-columns: 1.4375rem auto;
}

.grid-lft24 {
  grid-template-columns: 1.5rem auto;
}

.grid-lft25 {
  grid-template-columns: 1.5625rem auto;
}

.grid-lft26 {
  grid-template-columns: 1.625rem auto;
}

.grid-lft27 {
  grid-template-columns: 1.6875rem auto;
}

.grid-lft28 {
  grid-template-columns: 1.75rem auto;
}

.grid-lft29 {
  grid-template-columns: 1.8125rem auto;
}

.grid-lft30 {
  grid-template-columns: 1.875rem auto;
}

.grid-lft31 {
  grid-template-columns: 1.9375rem auto;
}

.grid-lft32 {
  grid-template-columns: 2rem auto;
}

.grid-lft33 {
  grid-template-columns: 2.0625rem auto;
}

.grid-lft34 {
  grid-template-columns: 2.125rem auto;
}

.grid-lft35 {
  grid-template-columns: 2.1875rem auto;
}

.grid-lft36 {
  grid-template-columns: 2.25rem auto;
}

.grid-lft37 {
  grid-template-columns: 2.3125rem auto;
}

.grid-lft38 {
  grid-template-columns: 2.375rem auto;
}

.grid-lft39 {
  grid-template-columns: 2.4375rem auto;
}

.grid-lft40 {
  grid-template-columns: 2.5rem auto;
}

.grid-lft41 {
  grid-template-columns: 2.5625rem auto;
}

.grid-lft42 {
  grid-template-columns: 2.625rem auto;
}

.grid-lft43 {
  grid-template-columns: 2.6875rem auto;
}

.grid-lft44 {
  grid-template-columns: 2.75rem auto;
}

.grid-lft45 {
  grid-template-columns: 2.8125rem auto;
}

.grid-lft46 {
  grid-template-columns: 2.875rem auto;
}

.grid-lft47 {
  grid-template-columns: 2.9375rem auto;
}

.grid-lft48 {
  grid-template-columns: 3rem auto;
}

.grid-lft49 {
  grid-template-columns: 3.0625rem auto;
}

.grid-lft50 {
  grid-template-columns: 3.125rem auto;
}

.grid-lft51 {
  grid-template-columns: 3.1875rem auto;
}

.grid-lft52 {
  grid-template-columns: 3.25rem auto;
}

.grid-lft53 {
  grid-template-columns: 3.3125rem auto;
}

.grid-lft54 {
  grid-template-columns: 3.375rem auto;
}

.grid-lft55 {
  grid-template-columns: 3.4375rem auto;
}

.grid-lft56 {
  grid-template-columns: 3.5rem auto;
}

.grid-lft57 {
  grid-template-columns: 3.5625rem auto;
}

.grid-lft58 {
  grid-template-columns: 3.625rem auto;
}

.grid-lft59 {
  grid-template-columns: 3.6875rem auto;
}

.grid-lft60 {
  grid-template-columns: 3.75rem auto;
}

.grid-lft61 {
  grid-template-columns: 3.8125rem auto;
}

.grid-lft62 {
  grid-template-columns: 3.875rem auto;
}

.grid-lft63 {
  grid-template-columns: 3.9375rem auto;
}

.grid-lft64 {
  grid-template-columns: 4rem auto;
}

.grid-lft65 {
  grid-template-columns: 4.0625rem auto;
}

.grid-lft66 {
  grid-template-columns: 4.125rem auto;
}

.grid-lft67 {
  grid-template-columns: 4.1875rem auto;
}

.grid-lft68 {
  grid-template-columns: 4.25rem auto;
}

.grid-lft69 {
  grid-template-columns: 4.3125rem auto;
}

.grid-lft70 {
  grid-template-columns: 4.375rem auto;
}

.grid-lft71 {
  grid-template-columns: 4.4375rem auto;
}

.grid-lft72 {
  grid-template-columns: 4.5rem auto;
}

.grid-lft73 {
  grid-template-columns: 4.5625rem auto;
}

.grid-lft74 {
  grid-template-columns: 4.625rem auto;
}

.grid-lft75 {
  grid-template-columns: 4.6875rem auto;
}

.grid-lft76 {
  grid-template-columns: 4.75rem auto;
}

.grid-lft77 {
  grid-template-columns: 4.8125rem auto;
}

.grid-lft78 {
  grid-template-columns: 4.875rem auto;
}

.grid-lft79 {
  grid-template-columns: 4.9375rem auto;
}

.grid-lft80 {
  grid-template-columns: 5rem auto;
}

.grid-lft81 {
  grid-template-columns: 5.0625rem auto;
}

.grid-lft82 {
  grid-template-columns: 5.125rem auto;
}

.grid-lft83 {
  grid-template-columns: 5.1875rem auto;
}

.grid-lft84 {
  grid-template-columns: 5.25rem auto;
}

.grid-lft85 {
  grid-template-columns: 5.3125rem auto;
}

.grid-lft86 {
  grid-template-columns: 5.375rem auto;
}

.grid-lft87 {
  grid-template-columns: 5.4375rem auto;
}

.grid-lft88 {
  grid-template-columns: 5.5rem auto;
}

.grid-lft89 {
  grid-template-columns: 5.5625rem auto;
}

.grid-lft90 {
  grid-template-columns: 5.625rem auto;
}

.grid-lft91 {
  grid-template-columns: 5.6875rem auto;
}

.grid-lft92 {
  grid-template-columns: 5.75rem auto;
}

.grid-lft93 {
  grid-template-columns: 5.8125rem auto;
}

.grid-lft94 {
  grid-template-columns: 5.875rem auto;
}

.grid-lft95 {
  grid-template-columns: 5.9375rem auto;
}

.grid-lft96 {
  grid-template-columns: 6rem auto;
}

.grid-lft97 {
  grid-template-columns: 6.0625rem auto;
}

.grid-lft98 {
  grid-template-columns: 6.125rem auto;
}

.grid-lft99 {
  grid-template-columns: 6.1875rem auto;
}

.grid-lft100 {
  grid-template-columns: 6.25rem auto;
}

.grid-lft101 {
  grid-template-columns: 6.3125rem auto;
}

.grid-lft102 {
  grid-template-columns: 6.375rem auto;
}

.grid-lft103 {
  grid-template-columns: 6.4375rem auto;
}

.grid-lft104 {
  grid-template-columns: 6.5rem auto;
}

.grid-lft105 {
  grid-template-columns: 6.5625rem auto;
}

.grid-lft106 {
  grid-template-columns: 6.625rem auto;
}

.grid-lft107 {
  grid-template-columns: 6.6875rem auto;
}

.grid-lft108 {
  grid-template-columns: 6.75rem auto;
}

.grid-lft109 {
  grid-template-columns: 6.8125rem auto;
}

.grid-lft110 {
  grid-template-columns: 6.875rem auto;
}

.grid-lft111 {
  grid-template-columns: 6.9375rem auto;
}

.grid-lft112 {
  grid-template-columns: 7rem auto;
}

.grid-lft113 {
  grid-template-columns: 7.0625rem auto;
}

.grid-lft114 {
  grid-template-columns: 7.125rem auto;
}

.grid-lft115 {
  grid-template-columns: 7.1875rem auto;
}

.grid-lft116 {
  grid-template-columns: 7.25rem auto;
}

.grid-lft117 {
  grid-template-columns: 7.3125rem auto;
}

.grid-lft118 {
  grid-template-columns: 7.375rem auto;
}

.grid-lft119 {
  grid-template-columns: 7.4375rem auto;
}

.grid-lft120 {
  grid-template-columns: 7.5rem auto;
}

.grid-lft121 {
  grid-template-columns: 7.5625rem auto;
}

.grid-lft122 {
  grid-template-columns: 7.625rem auto;
}

.grid-lft123 {
  grid-template-columns: 7.6875rem auto;
}

.grid-lft124 {
  grid-template-columns: 7.75rem auto;
}

.grid-lft125 {
  grid-template-columns: 7.8125rem auto;
}

.grid-lft126 {
  grid-template-columns: 7.875rem auto;
}

.grid-lft127 {
  grid-template-columns: 7.9375rem auto;
}

.grid-lft128 {
  grid-template-columns: 8rem auto;
}

.grid-lft129 {
  grid-template-columns: 8.0625rem auto;
}

.grid-lft130 {
  grid-template-columns: 8.125rem auto;
}

.grid-lft131 {
  grid-template-columns: 8.1875rem auto;
}

.grid-lft132 {
  grid-template-columns: 8.25rem auto;
}

.grid-lft133 {
  grid-template-columns: 8.3125rem auto;
}

.grid-lft134 {
  grid-template-columns: 8.375rem auto;
}

.grid-lft135 {
  grid-template-columns: 8.4375rem auto;
}

.grid-lft136 {
  grid-template-columns: 8.5rem auto;
}

.grid-lft137 {
  grid-template-columns: 8.5625rem auto;
}

.grid-lft138 {
  grid-template-columns: 8.625rem auto;
}

.grid-lft139 {
  grid-template-columns: 8.6875rem auto;
}

.grid-lft140 {
  grid-template-columns: 8.75rem auto;
}

.grid-lft141 {
  grid-template-columns: 8.8125rem auto;
}

.grid-lft142 {
  grid-template-columns: 8.875rem auto;
}

.grid-lft143 {
  grid-template-columns: 8.9375rem auto;
}

.grid-lft144 {
  grid-template-columns: 9rem auto;
}

.grid-lft145 {
  grid-template-columns: 9.0625rem auto;
}

.grid-lft146 {
  grid-template-columns: 9.125rem auto;
}

.grid-lft147 {
  grid-template-columns: 9.1875rem auto;
}

.grid-lft148 {
  grid-template-columns: 9.25rem auto;
}

.grid-lft149 {
  grid-template-columns: 9.3125rem auto;
}

.grid-lft150 {
  grid-template-columns: 9.375rem auto;
}

.grid-lft151 {
  grid-template-columns: 9.4375rem auto;
}

.grid-lft152 {
  grid-template-columns: 9.5rem auto;
}

.grid-lft153 {
  grid-template-columns: 9.5625rem auto;
}

.grid-lft154 {
  grid-template-columns: 9.625rem auto;
}

.grid-lft155 {
  grid-template-columns: 9.6875rem auto;
}

.grid-lft156 {
  grid-template-columns: 9.75rem auto;
}

.grid-lft157 {
  grid-template-columns: 9.8125rem auto;
}

.grid-lft158 {
  grid-template-columns: 9.875rem auto;
}

.grid-lft159 {
  grid-template-columns: 9.9375rem auto;
}

.grid-lft160 {
  grid-template-columns: 10rem auto;
}

.grid-lft161 {
  grid-template-columns: 10.0625rem auto;
}

.grid-lft162 {
  grid-template-columns: 10.125rem auto;
}

.grid-lft163 {
  grid-template-columns: 10.1875rem auto;
}

.grid-lft164 {
  grid-template-columns: 10.25rem auto;
}

.grid-lft165 {
  grid-template-columns: 10.3125rem auto;
}

.grid-lft166 {
  grid-template-columns: 10.375rem auto;
}

.grid-lft167 {
  grid-template-columns: 10.4375rem auto;
}

.grid-lft168 {
  grid-template-columns: 10.5rem auto;
}

.grid-lft169 {
  grid-template-columns: 10.5625rem auto;
}

.grid-lft170 {
  grid-template-columns: 10.625rem auto;
}

.grid-lft171 {
  grid-template-columns: 10.6875rem auto;
}

.grid-lft172 {
  grid-template-columns: 10.75rem auto;
}

.grid-lft173 {
  grid-template-columns: 10.8125rem auto;
}

.grid-lft174 {
  grid-template-columns: 10.875rem auto;
}

.grid-lft175 {
  grid-template-columns: 10.9375rem auto;
}

.grid-lft176 {
  grid-template-columns: 11rem auto;
}

.grid-lft177 {
  grid-template-columns: 11.0625rem auto;
}

.grid-lft178 {
  grid-template-columns: 11.125rem auto;
}

.grid-lft179 {
  grid-template-columns: 11.1875rem auto;
}

.grid-lft180 {
  grid-template-columns: 11.25rem auto;
}

.grid-lft181 {
  grid-template-columns: 11.3125rem auto;
}

.grid-lft182 {
  grid-template-columns: 11.375rem auto;
}

.grid-lft183 {
  grid-template-columns: 11.4375rem auto;
}

.grid-lft184 {
  grid-template-columns: 11.5rem auto;
}

.grid-lft185 {
  grid-template-columns: 11.5625rem auto;
}

.grid-lft186 {
  grid-template-columns: 11.625rem auto;
}

.grid-lft187 {
  grid-template-columns: 11.6875rem auto;
}

.grid-lft188 {
  grid-template-columns: 11.75rem auto;
}

.grid-lft189 {
  grid-template-columns: 11.8125rem auto;
}

.grid-lft190 {
  grid-template-columns: 11.875rem auto;
}

.grid-lft191 {
  grid-template-columns: 11.9375rem auto;
}

.grid-lft192 {
  grid-template-columns: 12rem auto;
}

.grid-lft193 {
  grid-template-columns: 12.0625rem auto;
}

.grid-lft194 {
  grid-template-columns: 12.125rem auto;
}

.grid-lft195 {
  grid-template-columns: 12.1875rem auto;
}

.grid-lft196 {
  grid-template-columns: 12.25rem auto;
}

.grid-lft197 {
  grid-template-columns: 12.3125rem auto;
}

.grid-lft198 {
  grid-template-columns: 12.375rem auto;
}

.grid-lft199 {
  grid-template-columns: 12.4375rem auto;
}

.grid-lft200 {
  grid-template-columns: 12.5rem auto;
}

.grid-lft201 {
  grid-template-columns: 12.5625rem auto;
}

.grid-lft202 {
  grid-template-columns: 12.625rem auto;
}

.grid-lft203 {
  grid-template-columns: 12.6875rem auto;
}

.grid-lft204 {
  grid-template-columns: 12.75rem auto;
}

.grid-lft205 {
  grid-template-columns: 12.8125rem auto;
}

.grid-lft206 {
  grid-template-columns: 12.875rem auto;
}

.grid-lft207 {
  grid-template-columns: 12.9375rem auto;
}

.grid-lft208 {
  grid-template-columns: 13rem auto;
}

.grid-lft209 {
  grid-template-columns: 13.0625rem auto;
}

.grid-lft210 {
  grid-template-columns: 13.125rem auto;
}

.grid-lft211 {
  grid-template-columns: 13.1875rem auto;
}

.grid-lft212 {
  grid-template-columns: 13.25rem auto;
}

.grid-lft213 {
  grid-template-columns: 13.3125rem auto;
}

.grid-lft214 {
  grid-template-columns: 13.375rem auto;
}

.grid-lft215 {
  grid-template-columns: 13.4375rem auto;
}

.grid-lft216 {
  grid-template-columns: 13.5rem auto;
}

.grid-lft217 {
  grid-template-columns: 13.5625rem auto;
}

.grid-lft218 {
  grid-template-columns: 13.625rem auto;
}

.grid-lft219 {
  grid-template-columns: 13.6875rem auto;
}

.grid-lft220 {
  grid-template-columns: 13.75rem auto;
}

.grid-lft221 {
  grid-template-columns: 13.8125rem auto;
}

.grid-lft222 {
  grid-template-columns: 13.875rem auto;
}

.grid-lft223 {
  grid-template-columns: 13.9375rem auto;
}

.grid-lft224 {
  grid-template-columns: 14rem auto;
}

.grid-lft225 {
  grid-template-columns: 14.0625rem auto;
}

.grid-lft226 {
  grid-template-columns: 14.125rem auto;
}

.grid-lft227 {
  grid-template-columns: 14.1875rem auto;
}

.grid-lft228 {
  grid-template-columns: 14.25rem auto;
}

.grid-lft229 {
  grid-template-columns: 14.3125rem auto;
}

.grid-lft230 {
  grid-template-columns: 14.375rem auto;
}

.grid-lft231 {
  grid-template-columns: 14.4375rem auto;
}

.grid-lft232 {
  grid-template-columns: 14.5rem auto;
}

.grid-lft233 {
  grid-template-columns: 14.5625rem auto;
}

.grid-lft234 {
  grid-template-columns: 14.625rem auto;
}

.grid-lft235 {
  grid-template-columns: 14.6875rem auto;
}

.grid-lft236 {
  grid-template-columns: 14.75rem auto;
}

.grid-lft237 {
  grid-template-columns: 14.8125rem auto;
}

.grid-lft238 {
  grid-template-columns: 14.875rem auto;
}

.grid-lft239 {
  grid-template-columns: 14.9375rem auto;
}

.grid-lft240 {
  grid-template-columns: 15rem auto;
}

.grid-lft241 {
  grid-template-columns: 15.0625rem auto;
}

.grid-lft242 {
  grid-template-columns: 15.125rem auto;
}

.grid-lft243 {
  grid-template-columns: 15.1875rem auto;
}

.grid-lft244 {
  grid-template-columns: 15.25rem auto;
}

.grid-lft245 {
  grid-template-columns: 15.3125rem auto;
}

.grid-lft246 {
  grid-template-columns: 15.375rem auto;
}

.grid-lft247 {
  grid-template-columns: 15.4375rem auto;
}

.grid-lft248 {
  grid-template-columns: 15.5rem auto;
}

.grid-lft249 {
  grid-template-columns: 15.5625rem auto;
}

.grid-lft250 {
  grid-template-columns: 15.625rem auto;
}

.grid-lft251 {
  grid-template-columns: 15.6875rem auto;
}

.grid-lft252 {
  grid-template-columns: 15.75rem auto;
}

.grid-lft253 {
  grid-template-columns: 15.8125rem auto;
}

.grid-lft254 {
  grid-template-columns: 15.875rem auto;
}

.grid-lft255 {
  grid-template-columns: 15.9375rem auto;
}

.grid-lft256 {
  grid-template-columns: 16rem auto;
}

.grid-lft257 {
  grid-template-columns: 16.0625rem auto;
}

.grid-lft258 {
  grid-template-columns: 16.125rem auto;
}

.grid-lft259 {
  grid-template-columns: 16.1875rem auto;
}

.grid-lft260 {
  grid-template-columns: 16.25rem auto;
}

.grid-lft261 {
  grid-template-columns: 16.3125rem auto;
}

.grid-lft262 {
  grid-template-columns: 16.375rem auto;
}

.grid-lft263 {
  grid-template-columns: 16.4375rem auto;
}

.grid-lft264 {
  grid-template-columns: 16.5rem auto;
}

.grid-lft265 {
  grid-template-columns: 16.5625rem auto;
}

.grid-lft266 {
  grid-template-columns: 16.625rem auto;
}

.grid-lft267 {
  grid-template-columns: 16.6875rem auto;
}

.grid-lft268 {
  grid-template-columns: 16.75rem auto;
}

.grid-lft269 {
  grid-template-columns: 16.8125rem auto;
}

.grid-lft270 {
  grid-template-columns: 16.875rem auto;
}

.grid-lft271 {
  grid-template-columns: 16.9375rem auto;
}

.grid-lft272 {
  grid-template-columns: 17rem auto;
}

.grid-lft273 {
  grid-template-columns: 17.0625rem auto;
}

.grid-lft274 {
  grid-template-columns: 17.125rem auto;
}

.grid-lft275 {
  grid-template-columns: 17.1875rem auto;
}

.grid-lft276 {
  grid-template-columns: 17.25rem auto;
}

.grid-lft277 {
  grid-template-columns: 17.3125rem auto;
}

.grid-lft278 {
  grid-template-columns: 17.375rem auto;
}

.grid-lft279 {
  grid-template-columns: 17.4375rem auto;
}

.grid-lft280 {
  grid-template-columns: 17.5rem auto;
}

.grid-lft281 {
  grid-template-columns: 17.5625rem auto;
}

.grid-lft282 {
  grid-template-columns: 17.625rem auto;
}

.grid-lft283 {
  grid-template-columns: 17.6875rem auto;
}

.grid-lft284 {
  grid-template-columns: 17.75rem auto;
}

.grid-lft285 {
  grid-template-columns: 17.8125rem auto;
}

.grid-lft286 {
  grid-template-columns: 17.875rem auto;
}

.grid-lft287 {
  grid-template-columns: 17.9375rem auto;
}

.grid-lft288 {
  grid-template-columns: 18rem auto;
}

.grid-lft289 {
  grid-template-columns: 18.0625rem auto;
}

.grid-lft290 {
  grid-template-columns: 18.125rem auto;
}

.grid-lft291 {
  grid-template-columns: 18.1875rem auto;
}

.grid-lft292 {
  grid-template-columns: 18.25rem auto;
}

.grid-lft293 {
  grid-template-columns: 18.3125rem auto;
}

.grid-lft294 {
  grid-template-columns: 18.375rem auto;
}

.grid-lft295 {
  grid-template-columns: 18.4375rem auto;
}

.grid-lft296 {
  grid-template-columns: 18.5rem auto;
}

.grid-lft297 {
  grid-template-columns: 18.5625rem auto;
}

.grid-lft298 {
  grid-template-columns: 18.625rem auto;
}

.grid-lft299 {
  grid-template-columns: 18.6875rem auto;
}

.grid-lft300 {
  grid-template-columns: 18.75rem auto;
}

.grid-lft301 {
  grid-template-columns: 18.8125rem auto;
}

.grid-lft302 {
  grid-template-columns: 18.875rem auto;
}

.grid-lft303 {
  grid-template-columns: 18.9375rem auto;
}

.grid-lft304 {
  grid-template-columns: 19rem auto;
}

.grid-lft305 {
  grid-template-columns: 19.0625rem auto;
}

.grid-lft306 {
  grid-template-columns: 19.125rem auto;
}

.grid-lft307 {
  grid-template-columns: 19.1875rem auto;
}

.grid-lft308 {
  grid-template-columns: 19.25rem auto;
}

.grid-lft309 {
  grid-template-columns: 19.3125rem auto;
}

.grid-lft310 {
  grid-template-columns: 19.375rem auto;
}

.grid-lft311 {
  grid-template-columns: 19.4375rem auto;
}

.grid-lft312 {
  grid-template-columns: 19.5rem auto;
}

.grid-lft313 {
  grid-template-columns: 19.5625rem auto;
}

.grid-lft314 {
  grid-template-columns: 19.625rem auto;
}

.grid-lft315 {
  grid-template-columns: 19.6875rem auto;
}

.grid-lft316 {
  grid-template-columns: 19.75rem auto;
}

.grid-lft317 {
  grid-template-columns: 19.8125rem auto;
}

.grid-lft318 {
  grid-template-columns: 19.875rem auto;
}

.grid-lft319 {
  grid-template-columns: 19.9375rem auto;
}

.grid-lft320 {
  grid-template-columns: 20rem auto;
}

.grid-lft321 {
  grid-template-columns: 20.0625rem auto;
}

.grid-lft322 {
  grid-template-columns: 20.125rem auto;
}

.grid-lft323 {
  grid-template-columns: 20.1875rem auto;
}

.grid-lft324 {
  grid-template-columns: 20.25rem auto;
}

.grid-lft325 {
  grid-template-columns: 20.3125rem auto;
}

.grid-lft326 {
  grid-template-columns: 20.375rem auto;
}

.grid-lft327 {
  grid-template-columns: 20.4375rem auto;
}

.grid-lft328 {
  grid-template-columns: 20.5rem auto;
}

.grid-lft329 {
  grid-template-columns: 20.5625rem auto;
}

.grid-lft330 {
  grid-template-columns: 20.625rem auto;
}

.grid-lft331 {
  grid-template-columns: 20.6875rem auto;
}

.grid-lft332 {
  grid-template-columns: 20.75rem auto;
}

.grid-lft333 {
  grid-template-columns: 20.8125rem auto;
}

.grid-lft334 {
  grid-template-columns: 20.875rem auto;
}

.grid-lft335 {
  grid-template-columns: 20.9375rem auto;
}

.grid-lft336 {
  grid-template-columns: 21rem auto;
}

.grid-lft337 {
  grid-template-columns: 21.0625rem auto;
}

.grid-lft338 {
  grid-template-columns: 21.125rem auto;
}

.grid-lft339 {
  grid-template-columns: 21.1875rem auto;
}

.grid-lft340 {
  grid-template-columns: 21.25rem auto;
}

.grid-lft341 {
  grid-template-columns: 21.3125rem auto;
}

.grid-lft342 {
  grid-template-columns: 21.375rem auto;
}

.grid-lft343 {
  grid-template-columns: 21.4375rem auto;
}

.grid-lft344 {
  grid-template-columns: 21.5rem auto;
}

.grid-lft345 {
  grid-template-columns: 21.5625rem auto;
}

.grid-lft346 {
  grid-template-columns: 21.625rem auto;
}

.grid-lft347 {
  grid-template-columns: 21.6875rem auto;
}

.grid-lft348 {
  grid-template-columns: 21.75rem auto;
}

.grid-lft349 {
  grid-template-columns: 21.8125rem auto;
}

.grid-lft350 {
  grid-template-columns: 21.875rem auto;
}

.grid-lft351 {
  grid-template-columns: 21.9375rem auto;
}

.grid-lft352 {
  grid-template-columns: 22rem auto;
}

.grid-lft353 {
  grid-template-columns: 22.0625rem auto;
}

.grid-lft354 {
  grid-template-columns: 22.125rem auto;
}

.grid-lft355 {
  grid-template-columns: 22.1875rem auto;
}

.grid-lft356 {
  grid-template-columns: 22.25rem auto;
}

.grid-lft357 {
  grid-template-columns: 22.3125rem auto;
}

.grid-lft358 {
  grid-template-columns: 22.375rem auto;
}

.grid-lft359 {
  grid-template-columns: 22.4375rem auto;
}

.grid-lft360 {
  grid-template-columns: 22.5rem auto;
}

.grid-lft361 {
  grid-template-columns: 22.5625rem auto;
}

.grid-lft362 {
  grid-template-columns: 22.625rem auto;
}

.grid-lft363 {
  grid-template-columns: 22.6875rem auto;
}

.grid-lft364 {
  grid-template-columns: 22.75rem auto;
}

.grid-lft365 {
  grid-template-columns: 22.8125rem auto;
}

.grid-lft366 {
  grid-template-columns: 22.875rem auto;
}

.grid-lft367 {
  grid-template-columns: 22.9375rem auto;
}

.grid-lft368 {
  grid-template-columns: 23rem auto;
}

.grid-lft369 {
  grid-template-columns: 23.0625rem auto;
}

.grid-lft370 {
  grid-template-columns: 23.125rem auto;
}

.grid-lft371 {
  grid-template-columns: 23.1875rem auto;
}

.grid-lft372 {
  grid-template-columns: 23.25rem auto;
}

.grid-lft373 {
  grid-template-columns: 23.3125rem auto;
}

.grid-lft374 {
  grid-template-columns: 23.375rem auto;
}

.grid-lft375 {
  grid-template-columns: 23.4375rem auto;
}

.grid-lft376 {
  grid-template-columns: 23.5rem auto;
}

.grid-lft377 {
  grid-template-columns: 23.5625rem auto;
}

.grid-lft378 {
  grid-template-columns: 23.625rem auto;
}

.grid-lft379 {
  grid-template-columns: 23.6875rem auto;
}

.grid-lft380 {
  grid-template-columns: 23.75rem auto;
}

.grid-lft381 {
  grid-template-columns: 23.8125rem auto;
}

.grid-lft382 {
  grid-template-columns: 23.875rem auto;
}

.grid-lft383 {
  grid-template-columns: 23.9375rem auto;
}

.grid-lft384 {
  grid-template-columns: 24rem auto;
}

.grid-lft385 {
  grid-template-columns: 24.0625rem auto;
}

.grid-lft386 {
  grid-template-columns: 24.125rem auto;
}

.grid-lft387 {
  grid-template-columns: 24.1875rem auto;
}

.grid-lft388 {
  grid-template-columns: 24.25rem auto;
}

.grid-lft389 {
  grid-template-columns: 24.3125rem auto;
}

.grid-lft390 {
  grid-template-columns: 24.375rem auto;
}

.grid-lft391 {
  grid-template-columns: 24.4375rem auto;
}

.grid-lft392 {
  grid-template-columns: 24.5rem auto;
}

.grid-lft393 {
  grid-template-columns: 24.5625rem auto;
}

.grid-lft394 {
  grid-template-columns: 24.625rem auto;
}

.grid-lft395 {
  grid-template-columns: 24.6875rem auto;
}

.grid-lft396 {
  grid-template-columns: 24.75rem auto;
}

.grid-lft397 {
  grid-template-columns: 24.8125rem auto;
}

.grid-lft398 {
  grid-template-columns: 24.875rem auto;
}

.grid-lft399 {
  grid-template-columns: 24.9375rem auto;
}

.grid-lft400 {
  grid-template-columns: 25rem auto;
}

.grid-lft401 {
  grid-template-columns: 25.0625rem auto;
}

.grid-lft402 {
  grid-template-columns: 25.125rem auto;
}

.grid-lft403 {
  grid-template-columns: 25.1875rem auto;
}

.grid-lft404 {
  grid-template-columns: 25.25rem auto;
}

.grid-lft405 {
  grid-template-columns: 25.3125rem auto;
}

.grid-lft406 {
  grid-template-columns: 25.375rem auto;
}

.grid-lft407 {
  grid-template-columns: 25.4375rem auto;
}

.grid-lft408 {
  grid-template-columns: 25.5rem auto;
}

.grid-lft409 {
  grid-template-columns: 25.5625rem auto;
}

.grid-lft410 {
  grid-template-columns: 25.625rem auto;
}

.grid-lft411 {
  grid-template-columns: 25.6875rem auto;
}

.grid-lft412 {
  grid-template-columns: 25.75rem auto;
}

.grid-lft413 {
  grid-template-columns: 25.8125rem auto;
}

.grid-lft414 {
  grid-template-columns: 25.875rem auto;
}

.grid-lft415 {
  grid-template-columns: 25.9375rem auto;
}

.grid-lft416 {
  grid-template-columns: 26rem auto;
}

.grid-lft417 {
  grid-template-columns: 26.0625rem auto;
}

.grid-lft418 {
  grid-template-columns: 26.125rem auto;
}

.grid-lft419 {
  grid-template-columns: 26.1875rem auto;
}

.grid-lft420 {
  grid-template-columns: 26.25rem auto;
}

.grid-lft421 {
  grid-template-columns: 26.3125rem auto;
}

.grid-lft422 {
  grid-template-columns: 26.375rem auto;
}

.grid-lft423 {
  grid-template-columns: 26.4375rem auto;
}

.grid-lft424 {
  grid-template-columns: 26.5rem auto;
}

.grid-lft425 {
  grid-template-columns: 26.5625rem auto;
}

.grid-lft426 {
  grid-template-columns: 26.625rem auto;
}

.grid-lft427 {
  grid-template-columns: 26.6875rem auto;
}

.grid-lft428 {
  grid-template-columns: 26.75rem auto;
}

.grid-lft429 {
  grid-template-columns: 26.8125rem auto;
}

.grid-lft430 {
  grid-template-columns: 26.875rem auto;
}

.grid-lft431 {
  grid-template-columns: 26.9375rem auto;
}

.grid-lft432 {
  grid-template-columns: 27rem auto;
}

.grid-lft433 {
  grid-template-columns: 27.0625rem auto;
}

.grid-lft434 {
  grid-template-columns: 27.125rem auto;
}

.grid-lft435 {
  grid-template-columns: 27.1875rem auto;
}

.grid-lft436 {
  grid-template-columns: 27.25rem auto;
}

.grid-lft437 {
  grid-template-columns: 27.3125rem auto;
}

.grid-lft438 {
  grid-template-columns: 27.375rem auto;
}

.grid-lft439 {
  grid-template-columns: 27.4375rem auto;
}

.grid-lft440 {
  grid-template-columns: 27.5rem auto;
}

.grid-lft441 {
  grid-template-columns: 27.5625rem auto;
}

.grid-lft442 {
  grid-template-columns: 27.625rem auto;
}

.grid-lft443 {
  grid-template-columns: 27.6875rem auto;
}

.grid-lft444 {
  grid-template-columns: 27.75rem auto;
}

.grid-lft445 {
  grid-template-columns: 27.8125rem auto;
}

.grid-lft446 {
  grid-template-columns: 27.875rem auto;
}

.grid-lft447 {
  grid-template-columns: 27.9375rem auto;
}

.grid-lft448 {
  grid-template-columns: 28rem auto;
}

.grid-lft449 {
  grid-template-columns: 28.0625rem auto;
}

.grid-lft450 {
  grid-template-columns: 28.125rem auto;
}

.grid-lft451 {
  grid-template-columns: 28.1875rem auto;
}

.grid-lft452 {
  grid-template-columns: 28.25rem auto;
}

.grid-lft453 {
  grid-template-columns: 28.3125rem auto;
}

.grid-lft454 {
  grid-template-columns: 28.375rem auto;
}

.grid-lft455 {
  grid-template-columns: 28.4375rem auto;
}

.grid-lft456 {
  grid-template-columns: 28.5rem auto;
}

.grid-lft457 {
  grid-template-columns: 28.5625rem auto;
}

.grid-lft458 {
  grid-template-columns: 28.625rem auto;
}

.grid-lft459 {
  grid-template-columns: 28.6875rem auto;
}

.grid-lft460 {
  grid-template-columns: 28.75rem auto;
}

.grid-lft461 {
  grid-template-columns: 28.8125rem auto;
}

.grid-lft462 {
  grid-template-columns: 28.875rem auto;
}

.grid-lft463 {
  grid-template-columns: 28.9375rem auto;
}

.grid-lft464 {
  grid-template-columns: 29rem auto;
}

.grid-lft465 {
  grid-template-columns: 29.0625rem auto;
}

.grid-lft466 {
  grid-template-columns: 29.125rem auto;
}

.grid-lft467 {
  grid-template-columns: 29.1875rem auto;
}

.grid-lft468 {
  grid-template-columns: 29.25rem auto;
}

.grid-lft469 {
  grid-template-columns: 29.3125rem auto;
}

.grid-lft470 {
  grid-template-columns: 29.375rem auto;
}

.grid-lft471 {
  grid-template-columns: 29.4375rem auto;
}

.grid-lft472 {
  grid-template-columns: 29.5rem auto;
}

.grid-lft473 {
  grid-template-columns: 29.5625rem auto;
}

.grid-lft474 {
  grid-template-columns: 29.625rem auto;
}

.grid-lft475 {
  grid-template-columns: 29.6875rem auto;
}

.grid-lft476 {
  grid-template-columns: 29.75rem auto;
}

.grid-lft477 {
  grid-template-columns: 29.8125rem auto;
}

.grid-lft478 {
  grid-template-columns: 29.875rem auto;
}

.grid-lft479 {
  grid-template-columns: 29.9375rem auto;
}

.grid-lft480 {
  grid-template-columns: 30rem auto;
}

.grid-lft481 {
  grid-template-columns: 30.0625rem auto;
}

.grid-lft482 {
  grid-template-columns: 30.125rem auto;
}

.grid-lft483 {
  grid-template-columns: 30.1875rem auto;
}

.grid-lft484 {
  grid-template-columns: 30.25rem auto;
}

.grid-lft485 {
  grid-template-columns: 30.3125rem auto;
}

.grid-lft486 {
  grid-template-columns: 30.375rem auto;
}

.grid-lft487 {
  grid-template-columns: 30.4375rem auto;
}

.grid-lft488 {
  grid-template-columns: 30.5rem auto;
}

.grid-lft489 {
  grid-template-columns: 30.5625rem auto;
}

.grid-lft490 {
  grid-template-columns: 30.625rem auto;
}

.grid-lft491 {
  grid-template-columns: 30.6875rem auto;
}

.grid-lft492 {
  grid-template-columns: 30.75rem auto;
}

.grid-lft493 {
  grid-template-columns: 30.8125rem auto;
}

.grid-lft494 {
  grid-template-columns: 30.875rem auto;
}

.grid-lft495 {
  grid-template-columns: 30.9375rem auto;
}

.grid-lft496 {
  grid-template-columns: 31rem auto;
}

.grid-lft497 {
  grid-template-columns: 31.0625rem auto;
}

.grid-lft498 {
  grid-template-columns: 31.125rem auto;
}

.grid-lft499 {
  grid-template-columns: 31.1875rem auto;
}

.grid-lft500 {
  grid-template-columns: 31.25rem auto;
}

.grid-lft501 {
  grid-template-columns: 31.3125rem auto;
}

.grid-lft502 {
  grid-template-columns: 31.375rem auto;
}

.grid-lft503 {
  grid-template-columns: 31.4375rem auto;
}

.grid-lft504 {
  grid-template-columns: 31.5rem auto;
}

.grid-lft505 {
  grid-template-columns: 31.5625rem auto;
}

.grid-lft506 {
  grid-template-columns: 31.625rem auto;
}

.grid-lft507 {
  grid-template-columns: 31.6875rem auto;
}

.grid-lft508 {
  grid-template-columns: 31.75rem auto;
}

.grid-lft509 {
  grid-template-columns: 31.8125rem auto;
}

.grid-lft510 {
  grid-template-columns: 31.875rem auto;
}

.grid-lft511 {
  grid-template-columns: 31.9375rem auto;
}

.grid-lft512 {
  grid-template-columns: 32rem auto;
}

.grid-lft513 {
  grid-template-columns: 32.0625rem auto;
}

.grid-lft514 {
  grid-template-columns: 32.125rem auto;
}

.grid-lft515 {
  grid-template-columns: 32.1875rem auto;
}

.grid-lft516 {
  grid-template-columns: 32.25rem auto;
}

.grid-lft517 {
  grid-template-columns: 32.3125rem auto;
}

.grid-lft518 {
  grid-template-columns: 32.375rem auto;
}

.grid-lft519 {
  grid-template-columns: 32.4375rem auto;
}

.grid-lft520 {
  grid-template-columns: 32.5rem auto;
}

.grid-lft521 {
  grid-template-columns: 32.5625rem auto;
}

.grid-lft522 {
  grid-template-columns: 32.625rem auto;
}

.grid-lft523 {
  grid-template-columns: 32.6875rem auto;
}

.grid-lft524 {
  grid-template-columns: 32.75rem auto;
}

.grid-lft525 {
  grid-template-columns: 32.8125rem auto;
}

.grid-lft526 {
  grid-template-columns: 32.875rem auto;
}

.grid-lft527 {
  grid-template-columns: 32.9375rem auto;
}

.grid-lft528 {
  grid-template-columns: 33rem auto;
}

.grid-lft529 {
  grid-template-columns: 33.0625rem auto;
}

.grid-lft530 {
  grid-template-columns: 33.125rem auto;
}

.grid-lft531 {
  grid-template-columns: 33.1875rem auto;
}

.grid-lft532 {
  grid-template-columns: 33.25rem auto;
}

.grid-lft533 {
  grid-template-columns: 33.3125rem auto;
}

.grid-lft534 {
  grid-template-columns: 33.375rem auto;
}

.grid-lft535 {
  grid-template-columns: 33.4375rem auto;
}

.grid-lft536 {
  grid-template-columns: 33.5rem auto;
}

.grid-lft537 {
  grid-template-columns: 33.5625rem auto;
}

.grid-lft538 {
  grid-template-columns: 33.625rem auto;
}

.grid-lft539 {
  grid-template-columns: 33.6875rem auto;
}

.grid-lft540 {
  grid-template-columns: 33.75rem auto;
}

.grid-lft541 {
  grid-template-columns: 33.8125rem auto;
}

.grid-lft542 {
  grid-template-columns: 33.875rem auto;
}

.grid-lft543 {
  grid-template-columns: 33.9375rem auto;
}

.grid-lft544 {
  grid-template-columns: 34rem auto;
}

.grid-lft545 {
  grid-template-columns: 34.0625rem auto;
}

.grid-lft546 {
  grid-template-columns: 34.125rem auto;
}

.grid-lft547 {
  grid-template-columns: 34.1875rem auto;
}

.grid-lft548 {
  grid-template-columns: 34.25rem auto;
}

.grid-lft549 {
  grid-template-columns: 34.3125rem auto;
}

.grid-lft550 {
  grid-template-columns: 34.375rem auto;
}

.grid-lft551 {
  grid-template-columns: 34.4375rem auto;
}

.grid-lft552 {
  grid-template-columns: 34.5rem auto;
}

.grid-lft553 {
  grid-template-columns: 34.5625rem auto;
}

.grid-lft554 {
  grid-template-columns: 34.625rem auto;
}

.grid-lft555 {
  grid-template-columns: 34.6875rem auto;
}

.grid-lft556 {
  grid-template-columns: 34.75rem auto;
}

.grid-lft557 {
  grid-template-columns: 34.8125rem auto;
}

.grid-lft558 {
  grid-template-columns: 34.875rem auto;
}

.grid-lft559 {
  grid-template-columns: 34.9375rem auto;
}

.grid-lft560 {
  grid-template-columns: 35rem auto;
}

.grid-lft561 {
  grid-template-columns: 35.0625rem auto;
}

.grid-lft562 {
  grid-template-columns: 35.125rem auto;
}

.grid-lft563 {
  grid-template-columns: 35.1875rem auto;
}

.grid-lft564 {
  grid-template-columns: 35.25rem auto;
}

.grid-lft565 {
  grid-template-columns: 35.3125rem auto;
}

.grid-lft566 {
  grid-template-columns: 35.375rem auto;
}

.grid-lft567 {
  grid-template-columns: 35.4375rem auto;
}

.grid-lft568 {
  grid-template-columns: 35.5rem auto;
}

.grid-lft569 {
  grid-template-columns: 35.5625rem auto;
}

.grid-lft570 {
  grid-template-columns: 35.625rem auto;
}

.grid-lft571 {
  grid-template-columns: 35.6875rem auto;
}

.grid-lft572 {
  grid-template-columns: 35.75rem auto;
}

.grid-lft573 {
  grid-template-columns: 35.8125rem auto;
}

.grid-lft574 {
  grid-template-columns: 35.875rem auto;
}

.grid-lft575 {
  grid-template-columns: 35.9375rem auto;
}

.grid-lft576 {
  grid-template-columns: 36rem auto;
}

.grid-lft577 {
  grid-template-columns: 36.0625rem auto;
}

.grid-lft578 {
  grid-template-columns: 36.125rem auto;
}

.grid-lft579 {
  grid-template-columns: 36.1875rem auto;
}

.grid-lft580 {
  grid-template-columns: 36.25rem auto;
}

.grid-lft581 {
  grid-template-columns: 36.3125rem auto;
}

.grid-lft582 {
  grid-template-columns: 36.375rem auto;
}

.grid-lft583 {
  grid-template-columns: 36.4375rem auto;
}

.grid-lft584 {
  grid-template-columns: 36.5rem auto;
}

.grid-lft585 {
  grid-template-columns: 36.5625rem auto;
}

.grid-lft586 {
  grid-template-columns: 36.625rem auto;
}

.grid-lft587 {
  grid-template-columns: 36.6875rem auto;
}

.grid-lft588 {
  grid-template-columns: 36.75rem auto;
}

.grid-lft589 {
  grid-template-columns: 36.8125rem auto;
}

.grid-lft590 {
  grid-template-columns: 36.875rem auto;
}

.grid-lft591 {
  grid-template-columns: 36.9375rem auto;
}

.grid-lft592 {
  grid-template-columns: 37rem auto;
}

.grid-lft593 {
  grid-template-columns: 37.0625rem auto;
}

.grid-lft594 {
  grid-template-columns: 37.125rem auto;
}

.grid-lft595 {
  grid-template-columns: 37.1875rem auto;
}

.grid-lft596 {
  grid-template-columns: 37.25rem auto;
}

.grid-lft597 {
  grid-template-columns: 37.3125rem auto;
}

.grid-lft598 {
  grid-template-columns: 37.375rem auto;
}

.grid-lft599 {
  grid-template-columns: 37.4375rem auto;
}

.grid-lft600 {
  grid-template-columns: 37.5rem auto;
}

.gap1 {
  gap: 0.0625rem;
}

.gap2 {
  gap: 0.125rem;
}

.gap3 {
  gap: 0.1875rem;
}

.gap4 {
  gap: 0.25rem;
}

.gap5 {
  gap: 0.3125rem;
}

.gap6 {
  gap: 0.375rem;
}

.gap7 {
  gap: 0.4375rem;
}

.gap8 {
  gap: 0.5rem;
}

.gap9 {
  gap: 0.5625rem;
}

.gap10 {
  gap: 0.625rem;
}

.gap11 {
  gap: 0.6875rem;
}

.gap12 {
  gap: 0.75rem;
}

.gap13 {
  gap: 0.8125rem;
}

.gap14 {
  gap: 0.875rem;
}

.gap15 {
  gap: 0.9375rem;
}

.gap16 {
  gap: 1rem;
}

.gap17 {
  gap: 1.0625rem;
}

.gap18 {
  gap: 1.125rem;
}

.gap19 {
  gap: 1.1875rem;
}

.gap20 {
  gap: 1.25rem;
}

.gap21 {
  gap: 1.3125rem;
}

.gap22 {
  gap: 1.375rem;
}

.gap23 {
  gap: 1.4375rem;
}

.gap24 {
  gap: 1.5rem;
}

.gap25 {
  gap: 1.5625rem;
}

.gap26 {
  gap: 1.625rem;
}

.gap27 {
  gap: 1.6875rem;
}

.gap28 {
  gap: 1.75rem;
}

.gap29 {
  gap: 1.8125rem;
}

.gap30 {
  gap: 1.875rem;
}

.gap31 {
  gap: 1.9375rem;
}

.gap32 {
  gap: 2rem;
}

.gap33 {
  gap: 2.0625rem;
}

.gap34 {
  gap: 2.125rem;
}

.gap35 {
  gap: 2.1875rem;
}

.gap36 {
  gap: 2.25rem;
}

.gap37 {
  gap: 2.3125rem;
}

.gap38 {
  gap: 2.375rem;
}

.gap39 {
  gap: 2.4375rem;
}

.gap40 {
  gap: 2.5rem;
}

.gap41 {
  gap: 2.5625rem;
}

.gap42 {
  gap: 2.625rem;
}

.gap43 {
  gap: 2.6875rem;
}

.gap44 {
  gap: 2.75rem;
}

.gap45 {
  gap: 2.8125rem;
}

.gap46 {
  gap: 2.875rem;
}

.gap47 {
  gap: 2.9375rem;
}

.gap48 {
  gap: 3rem;
}

.gap49 {
  gap: 3.0625rem;
}

.gap50 {
  gap: 3.125rem;
}

.gap51 {
  gap: 3.1875rem;
}

.gap52 {
  gap: 3.25rem;
}

.gap53 {
  gap: 3.3125rem;
}

.gap54 {
  gap: 3.375rem;
}

.gap55 {
  gap: 3.4375rem;
}

.gap56 {
  gap: 3.5rem;
}

.gap57 {
  gap: 3.5625rem;
}

.gap58 {
  gap: 3.625rem;
}

.gap59 {
  gap: 3.6875rem;
}

.gap60 {
  gap: 3.75rem;
}

.gap61 {
  gap: 3.8125rem;
}

.gap62 {
  gap: 3.875rem;
}

.gap63 {
  gap: 3.9375rem;
}

.gap64 {
  gap: 4rem;
}

.gap65 {
  gap: 4.0625rem;
}

.gap66 {
  gap: 4.125rem;
}

.gap67 {
  gap: 4.1875rem;
}

.gap68 {
  gap: 4.25rem;
}

.gap69 {
  gap: 4.3125rem;
}

.gap70 {
  gap: 4.375rem;
}

.gap71 {
  gap: 4.4375rem;
}

.gap72 {
  gap: 4.5rem;
}

.gap73 {
  gap: 4.5625rem;
}

.gap74 {
  gap: 4.625rem;
}

.gap75 {
  gap: 4.6875rem;
}

.gap76 {
  gap: 4.75rem;
}

.gap77 {
  gap: 4.8125rem;
}

.gap78 {
  gap: 4.875rem;
}

.gap79 {
  gap: 4.9375rem;
}

.gap80 {
  gap: 5rem;
}

.gap81 {
  gap: 5.0625rem;
}

.gap82 {
  gap: 5.125rem;
}

.gap83 {
  gap: 5.1875rem;
}

.gap84 {
  gap: 5.25rem;
}

.gap85 {
  gap: 5.3125rem;
}

.gap86 {
  gap: 5.375rem;
}

.gap87 {
  gap: 5.4375rem;
}

.gap88 {
  gap: 5.5rem;
}

.gap89 {
  gap: 5.5625rem;
}

.gap90 {
  gap: 5.625rem;
}

.gap91 {
  gap: 5.6875rem;
}

.gap92 {
  gap: 5.75rem;
}

.gap93 {
  gap: 5.8125rem;
}

.gap94 {
  gap: 5.875rem;
}

.gap95 {
  gap: 5.9375rem;
}

.gap96 {
  gap: 6rem;
}

.gap97 {
  gap: 6.0625rem;
}

.gap98 {
  gap: 6.125rem;
}

.gap99 {
  gap: 6.1875rem;
}

.gap100 {
  gap: 6.25rem;
}

.gap101 {
  gap: 6.3125rem;
}

.gap102 {
  gap: 6.375rem;
}

.gap103 {
  gap: 6.4375rem;
}

.gap104 {
  gap: 6.5rem;
}

.gap105 {
  gap: 6.5625rem;
}

.gap106 {
  gap: 6.625rem;
}

.gap107 {
  gap: 6.6875rem;
}

.gap108 {
  gap: 6.75rem;
}

.gap109 {
  gap: 6.8125rem;
}

.gap110 {
  gap: 6.875rem;
}

.gap111 {
  gap: 6.9375rem;
}

.gap112 {
  gap: 7rem;
}

.gap113 {
  gap: 7.0625rem;
}

.gap114 {
  gap: 7.125rem;
}

.gap115 {
  gap: 7.1875rem;
}

.gap116 {
  gap: 7.25rem;
}

.gap117 {
  gap: 7.3125rem;
}

.gap118 {
  gap: 7.375rem;
}

.gap119 {
  gap: 7.4375rem;
}

.gap120 {
  gap: 7.5rem;
}

.gap121 {
  gap: 7.5625rem;
}

.gap122 {
  gap: 7.625rem;
}

.gap123 {
  gap: 7.6875rem;
}

.gap124 {
  gap: 7.75rem;
}

.gap125 {
  gap: 7.8125rem;
}

.gap126 {
  gap: 7.875rem;
}

.gap127 {
  gap: 7.9375rem;
}

.gap128 {
  gap: 8rem;
}

.gap129 {
  gap: 8.0625rem;
}

.gap130 {
  gap: 8.125rem;
}

.gap131 {
  gap: 8.1875rem;
}

.gap132 {
  gap: 8.25rem;
}

.gap133 {
  gap: 8.3125rem;
}

.gap134 {
  gap: 8.375rem;
}

.gap135 {
  gap: 8.4375rem;
}

.gap136 {
  gap: 8.5rem;
}

.gap137 {
  gap: 8.5625rem;
}

.gap138 {
  gap: 8.625rem;
}

.gap139 {
  gap: 8.6875rem;
}

.gap140 {
  gap: 8.75rem;
}

.gap141 {
  gap: 8.8125rem;
}

.gap142 {
  gap: 8.875rem;
}

.gap143 {
  gap: 8.9375rem;
}

.gap144 {
  gap: 9rem;
}

.gap145 {
  gap: 9.0625rem;
}

.gap146 {
  gap: 9.125rem;
}

.gap147 {
  gap: 9.1875rem;
}

.gap148 {
  gap: 9.25rem;
}

.gap149 {
  gap: 9.3125rem;
}

.gap150 {
  gap: 9.375rem;
}

@media screen and (max-width: 47.9375em) {
  .grid-sp-block {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-sp-block[class*=gap] {
    gap: 1.7142857143rem;
  }
  .grid-sp-col1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .grid-sp-col2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .grid-sp-col3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid-sp-col4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .grid-sp-col5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .grid-sp-col6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .grid-sp-col7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .grid-sp-col8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .gap-sp0 {
    gap: 0rem !important;
  }
  .gap-sp1 {
    gap: 0.0714285714rem !important;
  }
  .gap-sp2 {
    gap: 0.1428571429rem !important;
  }
  .gap-sp3 {
    gap: 0.2142857143rem !important;
  }
  .gap-sp4 {
    gap: 0.2857142857rem !important;
  }
  .gap-sp5 {
    gap: 0.3571428571rem !important;
  }
  .gap-sp6 {
    gap: 0.4285714286rem !important;
  }
  .gap-sp7 {
    gap: 0.5rem !important;
  }
  .gap-sp8 {
    gap: 0.5714285714rem !important;
  }
  .gap-sp9 {
    gap: 0.6428571429rem !important;
  }
  .gap-sp10 {
    gap: 0.7142857143rem !important;
  }
  .gap-sp11 {
    gap: 0.7857142857rem !important;
  }
  .gap-sp12 {
    gap: 0.8571428571rem !important;
  }
  .gap-sp13 {
    gap: 0.9285714286rem !important;
  }
  .gap-sp14 {
    gap: 1rem !important;
  }
  .gap-sp15 {
    gap: 1.0714285714rem !important;
  }
  .gap-sp16 {
    gap: 1.1428571429rem !important;
  }
  .gap-sp17 {
    gap: 1.2142857143rem !important;
  }
  .gap-sp18 {
    gap: 1.2857142857rem !important;
  }
  .gap-sp19 {
    gap: 1.3571428571rem !important;
  }
  .gap-sp20 {
    gap: 1.4285714286rem !important;
  }
  .gap-sp21 {
    gap: 1.5rem !important;
  }
  .gap-sp22 {
    gap: 1.5714285714rem !important;
  }
  .gap-sp23 {
    gap: 1.6428571429rem !important;
  }
  .gap-sp24 {
    gap: 1.7142857143rem !important;
  }
  .gap-sp25 {
    gap: 1.7857142857rem !important;
  }
  .gap-sp26 {
    gap: 1.8571428571rem !important;
  }
  .gap-sp27 {
    gap: 1.9285714286rem !important;
  }
  .gap-sp28 {
    gap: 2rem !important;
  }
  .gap-sp29 {
    gap: 2.0714285714rem !important;
  }
  .gap-sp30 {
    gap: 2.1428571429rem !important;
  }
  .gap-sp31 {
    gap: 2.2142857143rem !important;
  }
  .gap-sp32 {
    gap: 2.2857142857rem !important;
  }
  .gap-sp33 {
    gap: 2.3571428571rem !important;
  }
  .gap-sp34 {
    gap: 2.4285714286rem !important;
  }
  .gap-sp35 {
    gap: 2.5rem !important;
  }
  .gap-sp36 {
    gap: 2.5714285714rem !important;
  }
  .gap-sp37 {
    gap: 2.6428571429rem !important;
  }
  .gap-sp38 {
    gap: 2.7142857143rem !important;
  }
  .gap-sp39 {
    gap: 2.7857142857rem !important;
  }
  .gap-sp40 {
    gap: 2.8571428571rem !important;
  }
  .gap-sp41 {
    gap: 2.9285714286rem !important;
  }
  .gap-sp42 {
    gap: 3rem !important;
  }
  .gap-sp43 {
    gap: 3.0714285714rem !important;
  }
  .gap-sp44 {
    gap: 3.1428571429rem !important;
  }
  .gap-sp45 {
    gap: 3.2142857143rem !important;
  }
  .gap-sp46 {
    gap: 3.2857142857rem !important;
  }
  .gap-sp47 {
    gap: 3.3571428571rem !important;
  }
  .gap-sp48 {
    gap: 3.4285714286rem !important;
  }
  .gap-sp49 {
    gap: 3.5rem !important;
  }
  .gap-sp50 {
    gap: 3.5714285714rem !important;
  }
  .gap-sp51 {
    gap: 3.6428571429rem !important;
  }
  .gap-sp52 {
    gap: 3.7142857143rem !important;
  }
  .gap-sp53 {
    gap: 3.7857142857rem !important;
  }
  .gap-sp54 {
    gap: 3.8571428571rem !important;
  }
  .gap-sp55 {
    gap: 3.9285714286rem !important;
  }
  .gap-sp56 {
    gap: 4rem !important;
  }
  .gap-sp57 {
    gap: 4.0714285714rem !important;
  }
  .gap-sp58 {
    gap: 4.1428571429rem !important;
  }
  .gap-sp59 {
    gap: 4.2142857143rem !important;
  }
  .gap-sp60 {
    gap: 4.2857142857rem !important;
  }
  .gap-sp61 {
    gap: 4.3571428571rem !important;
  }
  .gap-sp62 {
    gap: 4.4285714286rem !important;
  }
  .gap-sp63 {
    gap: 4.5rem !important;
  }
  .gap-sp64 {
    gap: 4.5714285714rem !important;
  }
  .gap-sp65 {
    gap: 4.6428571429rem !important;
  }
  .gap-sp66 {
    gap: 4.7142857143rem !important;
  }
  .gap-sp67 {
    gap: 4.7857142857rem !important;
  }
  .gap-sp68 {
    gap: 4.8571428571rem !important;
  }
  .gap-sp69 {
    gap: 4.9285714286rem !important;
  }
  .gap-sp70 {
    gap: 5rem !important;
  }
  .gap-sp71 {
    gap: 5.0714285714rem !important;
  }
  .gap-sp72 {
    gap: 5.1428571429rem !important;
  }
  .gap-sp73 {
    gap: 5.2142857143rem !important;
  }
  .gap-sp74 {
    gap: 5.2857142857rem !important;
  }
  .gap-sp75 {
    gap: 5.3571428571rem !important;
  }
  .gap-sp76 {
    gap: 5.4285714286rem !important;
  }
  .gap-sp77 {
    gap: 5.5rem !important;
  }
  .gap-sp78 {
    gap: 5.5714285714rem !important;
  }
  .gap-sp79 {
    gap: 5.6428571429rem !important;
  }
  .gap-sp80 {
    gap: 5.7142857143rem !important;
  }
  .gap-sp81 {
    gap: 5.7857142857rem !important;
  }
  .gap-sp82 {
    gap: 5.8571428571rem !important;
  }
  .gap-sp83 {
    gap: 5.9285714286rem !important;
  }
  .gap-sp84 {
    gap: 6rem !important;
  }
  .gap-sp85 {
    gap: 6.0714285714rem !important;
  }
  .gap-sp86 {
    gap: 6.1428571429rem !important;
  }
  .gap-sp87 {
    gap: 6.2142857143rem !important;
  }
  .gap-sp88 {
    gap: 6.2857142857rem !important;
  }
  .gap-sp89 {
    gap: 6.3571428571rem !important;
  }
  .gap-sp90 {
    gap: 6.4285714286rem !important;
  }
  .gap-sp91 {
    gap: 6.5rem !important;
  }
  .gap-sp92 {
    gap: 6.5714285714rem !important;
  }
  .gap-sp93 {
    gap: 6.6428571429rem !important;
  }
  .gap-sp94 {
    gap: 6.7142857143rem !important;
  }
  .gap-sp95 {
    gap: 6.7857142857rem !important;
  }
  .gap-sp96 {
    gap: 6.8571428571rem !important;
  }
  .gap-sp97 {
    gap: 6.9285714286rem !important;
  }
  .gap-sp98 {
    gap: 7rem !important;
  }
  .gap-sp99 {
    gap: 7.0714285714rem !important;
  }
  .gap-sp100 {
    gap: 7.1428571429rem !important;
  }
  .gap-sp101 {
    gap: 7.2142857143rem !important;
  }
  .gap-sp102 {
    gap: 7.2857142857rem !important;
  }
  .gap-sp103 {
    gap: 7.3571428571rem !important;
  }
  .gap-sp104 {
    gap: 7.4285714286rem !important;
  }
  .gap-sp105 {
    gap: 7.5rem !important;
  }
  .gap-sp106 {
    gap: 7.5714285714rem !important;
  }
  .gap-sp107 {
    gap: 7.6428571429rem !important;
  }
  .gap-sp108 {
    gap: 7.7142857143rem !important;
  }
  .gap-sp109 {
    gap: 7.7857142857rem !important;
  }
  .gap-sp110 {
    gap: 7.8571428571rem !important;
  }
  .gap-sp111 {
    gap: 7.9285714286rem !important;
  }
  .gap-sp112 {
    gap: 8rem !important;
  }
  .gap-sp113 {
    gap: 8.0714285714rem !important;
  }
  .gap-sp114 {
    gap: 8.1428571429rem !important;
  }
  .gap-sp115 {
    gap: 8.2142857143rem !important;
  }
  .gap-sp116 {
    gap: 8.2857142857rem !important;
  }
  .gap-sp117 {
    gap: 8.3571428571rem !important;
  }
  .gap-sp118 {
    gap: 8.4285714286rem !important;
  }
  .gap-sp119 {
    gap: 8.5rem !important;
  }
  .gap-sp120 {
    gap: 8.5714285714rem !important;
  }
  .gap-sp121 {
    gap: 8.6428571429rem !important;
  }
  .gap-sp122 {
    gap: 8.7142857143rem !important;
  }
  .gap-sp123 {
    gap: 8.7857142857rem !important;
  }
  .gap-sp124 {
    gap: 8.8571428571rem !important;
  }
  .gap-sp125 {
    gap: 8.9285714286rem !important;
  }
  .gap-sp126 {
    gap: 9rem !important;
  }
  .gap-sp127 {
    gap: 9.0714285714rem !important;
  }
  .gap-sp128 {
    gap: 9.1428571429rem !important;
  }
  .gap-sp129 {
    gap: 9.2142857143rem !important;
  }
  .gap-sp130 {
    gap: 9.2857142857rem !important;
  }
  .gap-sp131 {
    gap: 9.3571428571rem !important;
  }
  .gap-sp132 {
    gap: 9.4285714286rem !important;
  }
  .gap-sp133 {
    gap: 9.5rem !important;
  }
  .gap-sp134 {
    gap: 9.5714285714rem !important;
  }
  .gap-sp135 {
    gap: 9.6428571429rem !important;
  }
  .gap-sp136 {
    gap: 9.7142857143rem !important;
  }
  .gap-sp137 {
    gap: 9.7857142857rem !important;
  }
  .gap-sp138 {
    gap: 9.8571428571rem !important;
  }
  .gap-sp139 {
    gap: 9.9285714286rem !important;
  }
  .gap-sp140 {
    gap: 10rem !important;
  }
  .gap-sp141 {
    gap: 10.0714285714rem !important;
  }
  .gap-sp142 {
    gap: 10.1428571429rem !important;
  }
  .gap-sp143 {
    gap: 10.2142857143rem !important;
  }
  .gap-sp144 {
    gap: 10.2857142857rem !important;
  }
  .gap-sp145 {
    gap: 10.3571428571rem !important;
  }
  .gap-sp146 {
    gap: 10.4285714286rem !important;
  }
  .gap-sp147 {
    gap: 10.5rem !important;
  }
  .gap-sp148 {
    gap: 10.5714285714rem !important;
  }
  .gap-sp149 {
    gap: 10.6428571429rem !important;
  }
  .gap-sp150 {
    gap: 10.7142857143rem !important;
  }
}
/* ##############################################################################

    HEADER

############################################################################## */
/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 8.125rem;
  background-color: var(--bg-primary);
  z-index: 9999;
  transition: var(--transit-default);
}
.header > .inner {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-left: 5rem;
  padding-right: 5rem;
}
@media screen and (max-width: 67.5em) {
  .header {
    height: 6rem;
  }
  .header > .inner {
    padding-left: 1.875rem;
  }
}

.page-contact-simple .header {
  position: absolute;
}

@media print, screen and (min-width: 67.5625em) {
  .header-sm {
    background-color: var(--bg-wht);
    height: 7.125rem;
  }
}

/* --- logo -- */
.header--logo {
  transition: var(--transit-default);
}
@media print, screen and (min-width: 67.5625em) {
  .header--logo {
    transform: translateY(10px);
  }
}
@media screen and (max-width: 67.5em) {
  .header--logo {
    padding-top: 0.5714285714rem;
    transform: translateY(4px);
  }
}

.header--logo a,
.footer--logo a {
  display: inline-block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

@media print, screen and (min-width: 67.5625em) {
  .header--logo img {
    width: 7.5rem !important;
  }
}
@media screen and (max-width: 67.5em) {
  .header--logo img {
    width: auto;
    max-height: 4rem;
  }
}

.header--logo span {
  top: calc(-1em - 0.4375rem);
  left: 0;
  font-size: 0.8125rem;
  font-family: var(--font-secondary);
  letter-spacing: 0.1em;
  color: var(--clr-link);
  white-space: nowrap;
}
@media screen and (max-width: 67.5em) {
  .header--logo span {
    top: calc(-0.5em - 0.4375rem);
  }
}

/* gnav
********************************************** */
@media print, screen and (min-width: 67.5625em) {
  .gnav,
  .gnav ul,
  .gnav li,
  .gnav a {
    height: 100%;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav {
    display: flex;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav {
    position: fixed;
    top: 6rem;
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - 6rem);
    margin: 0;
    padding: 1.5rem 7%;
    opacity: 0;
    pointer-events: none;
    overflow: auto;
    background-color: var(--bg-primary);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
  }
  .gnav.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0);
  }
}

.gnav a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.0625rem;
  font-family: var(--font-secondary);
  letter-spacing: 0.1em;
}
@media screen and (max-width: 67.5em) {
  .gnav a {
    justify-content: flex-start;
  }
}

/* --- menu --- */
@media screen and (max-width: 67.5em) {
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu li:not(:last-child) {
    border-bottom: 1px dotted var(--clr-main);
  }
}

.gnav--link {
  padding: 0 1.5rem;
}
@media screen and (max-width: 67.5em) {
  .gnav--link {
    display: block;
    padding: 1.125rem 1rem;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--link {
    text-align: center;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.7142857143rem;
    margin: auto;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 1px solid var(--clr-main);
    border-right: 1px solid var(--clr-main);
    transform: rotate(45deg);
  }
}

.gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
.gnav--menu > li.current-menu-item > .gnav--link {
  color: var(--clr-orange);
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}

.head_sub_nav--wrap {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 100;
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    box-shadow: 0 0 4px #bcbcbc;
    border-radius: 0.625rem;
    opacity: 1;
    pointer-events: auto;
  }
}

@media screen and (max-width: 67.5em) {
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}

@media screen and (max-width: 67.5em) {
  .head_sub_nav {
    position: relative;
  }
}

.head_sub_nav a {
  display: block;
  padding: 1rem 1.5rem;
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a {
    background-color: var(--bg-wht);
  }
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a:hover {
    color: var(--clr-main);
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav_sub_toggle {
    display: none;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav_sub_toggle {
    position: absolute;
    top: 0.6em;
    right: -0.5rem;
    width: 2.8571428571rem;
    height: 2.8571428571rem;
    transition: var(--transit-default);
    color: var(--clr-wht);
    background-color: var(--clr-orange);
    border-radius: 50vw;
    cursor: pointer;
  }
  .gnav_sub_toggle::before, .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0.6875rem;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
}

/* ---btn --- */
@media print, screen and (min-width: 67.5625em) {
  .gnav_btn,
  .tel_btn {
    display: none;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav_btn,
  .tel_btn {
    position: fixed;
    top: 0;
    width: 3.8571428571rem;
    height: 3.8571428571rem;
    margin-top: 0.8571428571rem;
    margin-right: 0.8571428571rem;
    cursor: pointer;
    z-index: 9999;
    border-radius: 50vw;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav_btn {
    right: 0;
    transition: var(--transit-default);
    background-color: var(--bg-main-hvr);
  }
}

@media screen and (max-width: 67.5em) {
  .gnav_btn--lines {
    position: relative;
    width: 1.7142857143rem;
    height: 1.1428571429rem;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-wht);
    transition: var(--transit-default);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
}

@media screen and (max-width: 67.5em) {
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(0.5rem) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-0.5rem) rotate(45deg);
  }
}

@media screen and (max-width: 67.5em) {
  .tel_btn {
    right: 4.5714285714rem;
  }
}
@media screen and (max-width: 67.5em) {
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 67.5em) {
  .tel_btn svg {
    width: 3.8571428571rem;
    height: 3.8571428571rem;
    fill: var(--clr-main);
  }
}

.gnav--cta {
  text-align: center;
}

@media screen and (max-width: 67.5em) {
  .gnav--cta li:not(:last-child) {
    margin-bottom: 1.1428571429rem;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--tel,
  .gnav--contact {
    margin-left: 0.5rem;
  }
}

.gnav--tel a {
  display: inline-flex !important;
  align-items: flex-end;
}

.gnav--tel_phone {
  width: 2.5rem;
  aspect-ratio: 1/1;
  border-radius: 50vw;
}
.gnav--tel_phone svg {
  fill: var(--clr-wht);
  width: 100%;
}

.header-01 .tel_btn path,
.header-01 .gnav--tel_phone path {
  fill: #30cf6f;
}

.gnav--tel .tel_txt {
  font-size: 1rem;
  letter-spacing: 0.1em;
}

.gnav--tel .tel {
  font-family: var(--font-en);
  font-weight: bold;
  font-size: 1.6875rem;
  line-height: 1;
  letter-spacing: 0.1em;
}

.gnav--tel .num {
  font-size: max(22px, 1.5rem);
}

.gnav--tel .hours {
  font-size: max(10px, 0.875rem);
}

.gnav--contact a {
  padding: 1.5rem;
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--contact a {
    border-radius: 0;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--contact a {
    padding: 0.7142857143rem 1.1428571429rem;
    margin-left: auto;
    margin-right: auto;
  }
}

/* header 01
********************************************** */
@media print, screen and (min-width: 67.5625em) {
  .header-01 .gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
  .header-01 .gnav--menu > li.current-menu-item > .gnav--link {
    color: var(--clr-orange);
  }
}
@media print, screen and (min-width: 67.5625em) {
  .header-01 .head_sub_nav--wrap {
    width: max-content;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .header-01 .head_sub_nav li:first-child a {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .header-01 .head_sub_nav li:last-child a {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
.header-01 .head_sub_nav a {
  line-height: 1.5;
}
@media print, screen and (min-width: 67.5625em) {
  .header-01 .head_sub_nav a {
    background-color: var(--clr-beige);
  }
}
.header-01 .head_sub_nav a:hover {
  color: var(--clr-orange);
}

.header-01.header-sm {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
}

/* header 02
********************************************** */
@media print, screen and (min-width: 67.5625em) {
  .header-02 {
    padding-right: 2rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
  }
  .header-02 .gnav--menu > li:hover > .gnav--link:not([href="javascript:void(0);"]),
  .header-02 .gnav--menu > li.current-menu-item > .gnav--link {
    color: var(--clr-main);
    background-color: var(--bg-off_wht);
  }
}
.header-02 .head_sub_nav--wrap {
  left: 50%;
  translate: -50% 0;
  min-width: 15.5rem;
}
.header-02 .head_sub_nav {
  position: relative;
  min-width: 15.5rem;
  background-color: var(--bg-wht);
}
@media print, screen and (min-width: 67.5625em) {
  .header-02 .head_sub_nav {
    padding: 1.25rem;
    margin-top: 1.25rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.15);
  }
}
@media screen and (max-width: 47.9375em) {
  .header-02 .head_sub_nav {
    padding: 0.7142857143rem 1.1428571429rem;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .header-02 .head_sub_nav::before {
    content: "";
    position: absolute;
    top: -0.75rem;
    left: calc(50% - 1.5rem / 2);
    width: 1.5rem;
    height: 0.75rem;
    background-color: var(--bg-wht);
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }
}
.header-02 .head_sub_nav a {
  position: relative;
  padding: 0.625rem;
  text-align: left;
  line-height: 1.5;
}
@media screen and (max-width: 47.9375em) {
  .header-02 .head_sub_nav a {
    padding: 1em 1.5em;
  }
}
.header-02 .head_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  transition: var(--transit-default);
}
.header-02 .gnav--contact a {
  gap: 0.5rem;
}
@media print, screen and (min-width: 67.5625em) {
  .header-02 .gnav--contact a {
    height: 130%;
    border-radius: 0 0 1.5rem 1.5rem;
    flex-direction: column;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .header-02 .icon-mail {
    width: 2rem;
  }
}

/* ########################################################################

 スタンダード用TOP

######################################################################## */
.standard {
  background-color: var(--bg-primary);
  overflow: hidden;
  /* title
  ********************************************** */
  /* hero
  ********************************************** */
  /* 新着情報
  ********************************************** */
  /* home_intro
  ********************************************** */
  /* スライド
  ********************************************** */
  /* わたしたちの想い（ABOUT US）
  ********************************************** */
  /* サービス紹介(service)
  ********************************************** */
  /* すばるの特徴（FEATURE）
  ********************************************** */
  /* 施設紹介（FACILITY）
  ********************************************** */
  /* ご相談からの流れ（FLOW）
  ********************************************** */
  /* 採用情報（recruit）
  ********************************************** */
  /* 働く先輩社員の声（VOICE）
  ********************************************** */
}
.standard .ttl-03 {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .ttl-03 {
    font-size: 1.7142857143rem;
  }
}
.standard .hero {
  position: relative;
  height: 42.125rem;
  margin-bottom: 4.625rem;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .standard .hero {
    height: 34.5714285714rem;
    margin-bottom: 1.5714285714rem;
  }
}
.standard .hero_slides {
  position: relative;
}
.standard .hero_slide {
  position: absolute;
  top: 0;
  right: 7.5vw;
  width: min(60.625vw, 1164px);
  aspect-ratio: 1164/674;
  border-radius: 3.125rem;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .standard .hero_slide {
    right: 0;
    z-index: 0;
    width: 83.8164251208vw;
    aspect-ratio: 2/2.7;
    border-radius: 1.7142857143rem 0 0 1.7142857143rem;
  }
}
.standard .hero--cont {
  position: absolute;
  left: 14.75rem;
  top: 2.0625rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .hero--cont {
    top: 1.7142857143rem;
    left: 0.5714285714rem;
  }
}
.standard .hero--catch {
  font-size: 3rem;
  line-height: 1.6666666667;
}
@media screen and (max-width: 47.9375em) {
  .standard .hero--catch {
    display: flex;
    justify-content: center;
    font-size: 2.2857142857rem;
    line-height: 1.3;
  }
}
.standard .hero--catch_txt {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-font-feature-settings: "pkna";
  font-feature-settings: "pkna";
  text-orientation: mixed;
  font-size: 2.875rem;
  letter-spacing: 0.26em;
  line-height: 2.11;
  color: var(--clr-brown);
}
@media screen and (max-width: 47.9375em) {
  .standard .hero--catch_txt {
    font-size: 1.7142857143rem;
    font-family: var(--font-secondary-b);
  }
}
.standard .hero--catch_txt span.txt-deco {
  display: inline-block;
  width: 2em;
  background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line-tate.svg);
  background-repeat: repeat-y;
  background-size: 4px;
  background-position: top 2px right;
  font-feature-settings: "pkna";
}
.standard .hero--catch_txt span.txt-mgn.txt-hgt-01 {
  margin-top: -0.6em;
}
.standard .hero--catch_txt span.txt-hgt-01 {
  display: inline-block;
  height: 6.5em;
  white-space: nowrap;
  font-feature-settings: "pkna";
  text-orientation: mixed;
}
@media screen and (max-width: 47.9375em) {
  .standard .hero--catch_txt span.txt-hgt-01 {
    height: 6.4em;
  }
}
.standard .hero--catch_txt span.txt-hgt-02 {
  display: inline-block;
  height: 11.5em;
  white-space: nowrap;
  font-feature-settings: "pkna";
  text-orientation: mixed;
}
.standard .hero_slide-sp {
  display: none !important;
}
.standard .hero_slides,
.standard .hero_slide .slick-list,
.standard .hero_slide .slick-track,
.standard .hero_slide .slick-slider,
.standard .hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.standard .hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}
.standard .home_news {
  position: relative;
  margin-bottom: 30.9375vw;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news {
    margin-bottom: 72.4637681159vw;
  }
}
.standard .home_news::before {
  content: "";
  position: absolute;
  top: 92%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  aspect-ratio: 1920/879;
  background-image: url(/wp/wp-content/themes/standard_sass/images/fv_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news::before {
    top: 59%;
    background-image: url(/wp/wp-content/themes/standard_sass/images/fv_sp_bg.png);
    aspect-ratio: 414/696;
  }
}
.standard .home_news .inner {
  position: relative;
  z-index: 1;
  display: flex;
  gap: 1rem;
  padding: 2.5rem 8.75rem;
  background-color: var(--bg-wht);
  border-radius: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news .inner {
    flex-direction: column;
    padding: 2.4285714286rem 1.7142857143rem;
  }
}
.standard .home_news .inner + .home_news {
  margin-top: 6.25rem;
}
.standard .home_news--line {
  width: 5px;
  height: 8.75rem;
  border-right: 5px dotted var(--clr-beige-02);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news--line {
    width: 100%;
    height: 5px;
    border-right: none;
    border-bottom: 5px dotted var(--clr-beige-02);
  }
}
.standard .home_news .posts-news {
  flex: 1;
}
.standard .posts-news--ttl {
  display: block;
  position: relative;
  margin-right: 2.5rem;
  padding: 0;
  line-height: 1.2;
}
@media screen and (max-width: 47.9375em) {
  .standard .posts-news--ttl {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: 1.1428571429rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news--ttl .ttl-02 {
    margin-bottom: 0.5rem;
  }
}
.standard .home_news--ttl .ttl-01-sub {
  margin-bottom: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news--ttl .ttl-01-sub {
    margin-bottom: 0;
  }
}
.standard .ttl-02 {
  margin-bottom: 1rem;
}
.standard .ttl-01-sub {
  margin-bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .ttl-01-sub {
    margin-bottom: 2.2857142857rem;
  }
}
@media screen and (min-width: 48em) {
  .standard .home_news--cont {
    width: 56rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news--ttl {
    text-align: center;
  }
  .standard .home_news--ttl .ttl-01-sub::after {
    margin-inline: auto;
  }
  .standard .home_news .btn-arrow {
    text-align: center;
  }
}
.standard .home_news .posts-news .post--txt,
.standard .home_news .posts-blog .post--txt {
  -webkit-line-clamp: 1;
  opacity: 0.5;
  pointer-events: none;
}
.standard .home_news .posts-news .post--info {
  grid-template-columns: 7rem 7rem auto;
  align-items: center;
}
@media screen and (max-width: 100em) {
  .standard .home_news .posts-news .post--info {
    grid-template-columns: 10rem 8rem auto;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news .posts-news .post--info {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "date tag" "text text";
    row-gap: 0.5714285714rem;
  }
}
.standard .home_news .posts-news .post--date {
  font-size: max(14px, 1rem);
  font-family: var(--font-en);
  font-weight: bold;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news .posts-news .post--date {
    grid-area: date;
  }
}
.standard .home_news .posts-news .cat_list {
  z-index: 110;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news .posts-news .cat_list {
    grid-area: tag;
    margin-left: -1.4285714286rem;
  }
}
.standard .home_news .posts-news .cat_list a {
  font-size: 0.875rem;
  letter-spacing: 0.18em;
  line-height: 1.71;
  border-radius: 1rem;
  color: var(--bg-cta);
  background-color: var(--bg-wht);
  border: 2px solid var(--bg-cta);
}
@media (hover: hover) {
  .standard .home_news .posts-news .cat_list a:hover {
    background-color: var(--bg-cta);
    color: var(--clr-wht);
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_news .posts-news .post--ttl {
    grid-area: text;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .hero_slide::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url(/wp/wp-content/themes/standard_sass/images/moya.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .standard .hero_slide-pc {
    display: none !important;
  }
  .standard .hero_slide-sp {
    display: block !important;
  }
}
.standard .home_intro {
  margin-bottom: 8.5rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_intro {
    margin-bottom: 3.8571428571rem;
  }
}
.standard .home_intro--txt {
  font-family: var(--font-secondary);
  font-size: 1.5rem;
  line-height: 1.92;
  letter-spacing: 0.16em;
  color: var(--bg-main);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_intro--txt {
    font-size: 1.1428571429rem;
  }
}
.standard .lps_parts--slide.slide-flow .slick-slide {
  margin-right: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .lps_parts--slide.slide-flow .slick-slide {
    width: 60vw;
  }
}
.standard .home_about {
  padding-bottom: 7.5rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_about {
    padding-bottom: 5.5rem;
  }
}
.standard .home_about .img_text--child {
  width: 44.5rem;
  aspect-ratio: 712/437;
}
.standard .home_about .img_text--child .mce-content-body p {
  font-size: 1.125rem;
  line-height: 1.94;
  letter-spacing: 0.18em;
  text-align: justify;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_about .img_text--child .mce-content-body p {
    font-size: 1rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_about .lps_parts--img_text .img_text--txt {
    padding-top: 6rem;
  }
}
.standard .home_about .ttl-03 {
  line-height: 2;
  letter-spacing: 0.16em;
  color: var(--clr-main);
}
.standard .home_service {
  padding-top: 7.5rem;
  padding-bottom: 9.5rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_service {
    padding-top: 5.5rem;
  }
}
@media print, screen and (min-width: 48em) {
  .standard .home_service--block.top {
    grid-template: " home support" 32rem/70rem auto;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_service--block.top {
    grid-template-areas: "home" "support";
    grid-template-columns: 1fr;
  }
}
@media print, screen and (min-width: 48em) {
  .standard .home_service--block.bottom {
    grid-template: " short day" 23.75rem/1fr 1fr;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_service--block.bottom {
    grid-template-areas: "short" "day";
    grid-template-columns: 1fr;
  }
}
.standard .home_service--contents-home {
  grid-area: home;
}
.standard .home_service--contents-support {
  grid-area: support;
}
.standard .home_service--contents-short {
  grid-area: short;
}
.standard .home_service--contents-day {
  grid-area: day;
}
.standard .home_service--head {
  padding: 2.125rem;
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 2rem;
  letter-spacing: 0.1em;
  line-height: 1.41;
  border-radius: 2.5rem 2.5rem 0 0;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_service--head {
    padding: 1.1428571429rem;
    font-family: var(--font-secondary-b);
    font-size: 1.7142857143rem;
  }
}
.standard .home_service--body {
  height: 100%;
  position: relative;
  padding: 3rem 4rem 4rem;
}
.standard .home_service--body .ttl-03.dotted span {
  height: 1.5em;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_service--body {
    padding: 2.7142857143rem 1.7142857143rem;
  }
}
.standard .home_service--contents {
  position: relative;
  border-radius: 2.5rem;
  overflow: hidden;
}
.standard .home_service--contents-home .home_service--head {
  background-color: var(--clr-yellow-08);
  color: var(--clr-brown-02);
}
.standard .home_service--contents-home .home_service--body {
  background-color: var(--clr-yellow-07);
}
@media (hover: hover) {
  .standard .home_service--contents-support:hover .btn a {
    background-color: var(--clr-purple-03);
    border-color: var(--clr-purple-03);
    color: var(--clr-purple-04);
  }
  .standard .home_service--contents-support:hover svg {
    fill: var(--clr-purple-04);
  }
}
.standard .home_service--contents-support .home_service--head {
  background-color: var(--clr-purple-03);
  color: var(--clr-purple-04);
}
.standard .home_service--contents-support .home_service--body {
  background-color: var(--clr-purple-02);
}
@media print, screen and (min-width: 48em) {
  .standard .home_service--contents-support .home_service--body_ttl.ttl-03 {
    margin-bottom: 3.3em;
  }
}
@media (hover: hover) {
  .standard .home_service--contents-short:hover .btn a {
    background-color: var(--clr-green-02);
    border-color: var(--clr-green-02);
    color: var(--clr-green-10);
  }
  .standard .home_service--contents-short:hover svg {
    fill: var(--clr-green-10);
  }
}
.standard .home_service--contents-short .home_service--head {
  background-color: var(--clr-green-02);
  color: var(--clr-green-10);
}
.standard .home_service--contents-short .home_service--body {
  background-color: var(--clr-green);
}
@media (hover: hover) {
  .standard .home_service--contents-day:hover .btn a {
    background-color: var(--clr-pink-02);
    border-color: var(--clr-pink-02);
    color: var(--clr-pink-03);
  }
  .standard .home_service--contents-day:hover svg {
    fill: var(--clr-pink-03);
  }
}
.standard .home_service--contents-day .home_service--head {
  background-color: var(--clr-pink-02);
  color: var(--clr-pink-03);
}
.standard .home_service--contents-day .home_service--body {
  background-color: var(--clr-pink);
}
.standard .home_service--body_ttl.ttl-03 {
  margin-bottom: 1.3em;
  font-size: 2.75rem;
  line-height: 1.02;
  letter-spacing: 0.1em;
  color: var(--clr-wht);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_service--body_ttl.ttl-03 {
    margin-bottom: 0.8em;
    font-size: 2.2857142857rem;
  }
}
.standard .home_service--box {
  position: relative;
  z-index: 2;
  background-color: var(--clr-beige-03);
  border-radius: 1rem;
  padding: 1.0625rem 3.75rem 1.75rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_service--box {
    padding: 1rem 2rem 1.5rem;
  }
}
@media (hover: hover) {
  .standard .home_service--box:hover .btn a {
    background-color: var(--clr-yellow-07);
    border-color: var(--clr-yellow-07);
  }
}
.standard .home_service--box_ttl,
.standard .home_service--box_txt {
  text-align: center;
  font-family: var(--font-secondary);
}
.standard .home_service--box_ttl {
  font-size: 1.625rem;
  line-height: 1.73;
  letter-spacing: 0.12em;
  color: var(--clr-yellow);
}
.standard .home_service--box_txt {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.16em;
  color: var(--clr-brown-02);
}
.standard .home_feature {
  position: relative;
  padding-top: 17.5rem;
  padding-bottom: 40rem;
  background-image: url("/wp/wp-content/themes/standard_sass/images/feature_bk.png");
  background-repeat: no-repeat;
  background-size: cover;
}
@media print, screen and (min-width: 125.0625em) {
  .standard .home_feature {
    padding-bottom: 65rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_feature {
    padding-top: 0;
    padding-bottom: 29rem;
    background-image: url(/wp/wp-content/themes/standard_sass/images/feature_bk_sp_02.png);
  }
}
@media screen and (max-width: 47.9375em) and (min-width: 26em) {
  .standard .home_feature {
    background-size: cover;
  }
}
@media screen and (max-width: 25.875em) {
  .standard .home_feature {
    background-size: contain;
  }
}
.standard .home_feature > .inner {
  position: relative;
  z-index: 10;
  transform: translateY(13.125rem);
}
.standard .home_feature .ttl-01-sub {
  color: rgba(255, 255, 255, 0.42);
}
.standard .home_feature .lps_parts:not(:last-child) {
  margin-bottom: 11.25rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_feature .lps_parts:not(:last-child) {
    margin-bottom: 6.4285714286rem;
  }
}
.standard .home_feature .lps_parts--img_text .inner {
  width: 100%;
}
.standard .home_feature .lps_parts--img_text {
  counter-reset: number;
}
@media print, screen and (min-width: 48em) {
  .standard .home_feature .lps_parts--img_text {
    margin-inline: calc(50% - 50vw);
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_feature .lps_parts--img_text {
    margin-bottom: 1.7142857143rem;
  }
}
.standard .home_feature .lps_parts--img_text .ttl-03 {
  font-size: 2rem;
  line-height: 2;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_feature .lps_parts--img_text .ttl-03 {
    font-size: 1.7142857143rem;
  }
}
.standard .home_feature .lps_parts--img_text .ttl-03::before {
  counter-increment: number;
  content: "0" counter(number);
  position: absolute;
  right: 0;
  bottom: 2.5rem;
  z-index: -1;
  color: var(--clr-yellow-02);
  font-size: 13.5rem;
  font-family: var(--font-en);
  line-height: 0.42;
}
@media print, screen and (min-width: 48em) {
  .standard .home_feature .lps_parts--img_text .ttl-03::before {
    letter-spacing: -15px;
  }
}
@media screen and (min-width: 48em) and (max-width: 85.375em) {
  .standard .home_feature .lps_parts--img_text .ttl-03::before {
    letter-spacing: -8px;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_feature .lps_parts--img_text .ttl-03::before {
    right: -2.4154589372vw;
    font-size: 6.1428571429rem;
  }
}
.standard .home_feature .lps_parts--img_text .lps_parts + .lps_parts {
  margin-top: 0;
}
.standard .home_feature .lps_parts--img_text .img_text--img {
  aspect-ratio: 869/507;
}
@media print, screen and (min-width: 48em) {
  .standard .home_feature .lps_parts--img_text .img_text--img {
    min-width: 54.3125rem;
  }
}
@media print, screen and (min-width: 48em) {
  .standard .home_feature .lps_parts--img_text .inner:not(.flex-reverse) .img_text--txt {
    padding-left: 7.5rem;
  }
}
@media print, screen and (min-width: 48em) {
  .standard .home_feature .lps_parts--img_text .inner.flex-reverse .img_text--txt {
    padding-right: 7.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_feature .lps_parts--img_text .img_text--txt {
    padding-top: 1.1428571429rem;
    padding-bottom: 1.7142857143rem;
  }
}
.standard .home_feature .lps_parts--img_text .img_text--txt p {
  font-size: 1.125rem;
  line-height: 2;
  letter-spacing: 0.16em;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_feature .lps_parts--img_text .img_text--txt p {
    font-size: 1rem;
  }
}
.standard .home_feature .lps_parts--img_text .box {
  padding: 2rem 3rem;
  border-radius: 2.5rem;
  background-color: var(--clr-yellow-10);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_feature .lps_parts--img_text .box {
    padding: 3.7142857143rem 3.7142857143rem;
  }
}
.standard .home_feature .lps_parts--img_text .box p.bold {
  margin-bottom: 0;
  font-family: var(--font-secondary-b);
  color: var(--clr-yellow);
}
.standard .home_feature .lps_parts--img_text .box-previous {
  padding-bottom: 1rem;
  background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line-wh.svg);
  background-repeat: repeat-x;
  background-size: 13px;
  background-position: bottom left;
}
.standard .home_feature .lps_parts--img_text .box-unit {
  padding-top: 1rem;
}
.standard .home_feature .lps_parts--img_text .img {
  overflow: hidden;
}
.standard .home_facility .lps_parts:not(:last-child) {
  margin-bottom: 0;
}
.standard .home_facility .lps_parts {
  z-index: inherit;
}
.standard .home_facility .lps_parts--slide {
  position: relative;
}
.standard .home_facility .lps_parts--slide .slick-list {
  margin-bottom: 0;
}
.standard .home_facility .lps_parts--slide .slick-arrow::after {
  border-width: 3px;
  border-color: var(--clr-wht);
  transition: var(--transit-default);
}
.standard .home_facility .lps_parts--slide .slick-prev,
.standard .home_facility .lps_parts--slide .slick-next {
  z-index: 500;
}
.standard .home_facility .lps_parts--slide .slick-prev {
  left: 11px;
}
.standard .home_facility .lps_parts--slide .slick-next {
  right: 11px;
}
.standard .home_facility .lps_parts--slide .slick-slide img {
  border-radius: 2.5rem 2.5rem 0 0;
  transition: var(--transit-default);
}
.standard .home_facility .lps_parts--slide .slick-dots {
  position: absolute;
  top: calc(100% + 2rem);
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
}
.standard .home_facility .lps_parts--slide .slick-dots li {
  background-color: var(--bg-wht);
}
.standard .home_facility--card {
  max-width: 43rem;
}
@media (hover: hover) {
  .standard .home_facility--card:hover .home_facility--block.orange {
    background-color: rgba(252, 169, 25, 0.8);
  }
  .standard .home_facility--card:hover .home_facility--contents.orange {
    background-color: rgba(252, 169, 25, 0.8);
  }
  .standard .home_facility--card:hover .home_facility--block.blue {
    background-color: rgba(81, 167, 221, 0.8);
  }
  .standard .home_facility--card:hover .home_facility--contents.blue {
    background-color: rgba(81, 167, 221, 0.8);
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_facility--wrapper.flex-sp-block[class*=gap] {
    gap: 11.7142857143rem;
  }
}
@media (hover: hover) {
  .standard .home_facility--card.orange .slick-arrow:hover::after {
    border-color: var(--clr-yellow-07);
  }
}
@media (hover: hover) {
  .standard .home_facility--card.blue .slick-arrow:hover::after {
    border-color: var(--clr-blue);
  }
}
.standard .home_facility--card {
  overflow: hidden;
  border-radius: 2.5rem;
}
.standard .home_facility--card.orange:hover .btn-fan_shape {
  background-color: var(--clr-yellow-03);
}
.standard .home_facility--card.blue:hover .btn-fan_shape {
  background-color: var(--clr-blue-04);
}
.standard .home_facility--card .lps_parts--slide .slick-dots li.slick-active {
  background-color: var(--clr-yellow-04);
}
.standard .home_facility--block {
  padding: 4.5rem 5.75rem 4.375rem 5.75rem;
  border-radius: 0 0 2.5rem 2.5rem;
  transition: var(--transit-default);
}
@media print, screen and (min-width: 48em) {
  .standard .home_facility--block {
    min-height: 28.3125rem;
  }
}
@media screen and (min-width: 48em) and (max-width: 85.375em) {
  .standard .home_facility--block {
    min-height: 30.3125rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_facility--block {
    padding: 3.5714285714rem 2.4285714286rem 5.1428571429rem;
  }
}
.standard .home_facility--block.orange {
  background-color: var(--clr-yellow-07);
}
.standard .home_facility--block.blue {
  background-color: var(--clr-blue-03);
}
.standard .home_facility--block .ttl-01-sub {
  margin-bottom: -1.5rem;
  text-align: left;
  color: rgba(255, 255, 255, 0.42);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_facility--block .ttl-01-sub {
    margin-bottom: -0.8571428571rem;
  }
}
.standard .home_facility--block .ttl-03 {
  margin-bottom: 0;
  letter-spacing: 0.12em;
  color: var(--clr-wht);
  white-space: nowrap;
}
.standard .home_facility--block .ttl-03.dotted > span.wh-42 {
  height: 2.5em;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_facility--block .ttl-03.dotted > span.wh-42 {
    font-size: 1.2857142857rem;
    height: 2.8em;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_facility--block .ttl-03.dotted > span.wh-42 > span {
    font-size: 1.7142857143rem;
  }
}
.standard .home_facility--map {
  margin-bottom: 1.3125rem;
  font-family: var(--font-secondary);
  font-size: 1rem;
  line-height: 2.375;
  color: var(--clr-wht);
  letter-spacing: 0.16em;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_facility--map {
    font-size: 0.8571428571rem;
  }
}
.standard .home_facility--map svg {
  fill: var(--bg-wht);
  opacity: 0.42;
}
.standard .home_facility--contents {
  padding: 1.0625rem 4.75rem;
  border-radius: 1rem;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_facility--contents {
    padding: 1rem 2rem;
  }
}
.standard .home_facility--contents.orange {
  background-color: var(--clr-yellow-03);
}
.standard .home_facility--contents.orange::before {
  background-color: var(--clr-yellow-09);
}
.standard .home_facility--contents.blue {
  background-color: var(--clr-blue-04);
}
.standard .home_facility--contents.blue::before {
  background-color: var(--clr-blue-03);
}
.standard .home_facility--contents li {
  position: relative;
  line-height: 1.7777777778;
  font-family: var(--font-secondary);
  font-size: 1.125rem;
  color: var(--clr-wht);
  letter-spacing: 0.16em;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_facility--contents li {
    font-size: 0.8571428571rem;
  }
}
.standard .home_facility--contents li::before {
  content: "";
  position: absolute;
  top: 0.6em;
  left: -1rem;
  width: 0.5rem;
  aspect-ratio: 1/1;
  background-color: var(--clr-yellow-09);
  border-radius: 50vw;
}
.standard .home_flow {
  padding-top: 12rem;
  padding-bottom: 11rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_flow {
    padding-bottom: 6rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_flow--wrapper {
    gap: 9.1428571429rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_flow--block {
    flex-direction: column;
    gap: 9.2857142857rem;
  }
}
.standard .home_flow--block .home_flow--item:not(:last-child) {
  position: relative;
}
.standard .home_flow--block .home_flow--item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(100% - -3.25rem);
  transform: translateY(-50%);
  width: 1.8125rem;
  aspect-ratio: 29/33;
  background-image: url(/wp/wp-content/themes/standard_sass/images/flow-arrow.png);
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_flow--block .home_flow--item:not(:last-child)::before {
    top: calc(100% + 4.625rem);
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_flow--block-01 .home_flow--item:last-child {
    position: relative;
  }
  .standard .home_flow--block-01 .home_flow--item:last-child::before {
    content: "";
    position: absolute;
    top: calc(100% + 4.625rem);
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
    width: 1.8125rem;
    aspect-ratio: 29/33;
    background-image: url(/wp/wp-content/themes/standard_sass/images/flow-arrow.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
}
@media print, screen and (min-width: 48em) {
  .standard .home_flow--block-02 .home_flow--item:first-child::after {
    content: "";
    position: absolute;
    top: 50%;
    right: calc(100% - -3.25rem);
    transform: translateY(-50%);
    width: 1.8125rem;
    aspect-ratio: 29/33;
    background-image: url(/wp/wp-content/themes/standard_sass/images/flow-arrow.png);
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.standard .home_flow--item {
  position: relative;
  aspect-ratio: 328/220;
  background-image: url(/wp/wp-content/themes/standard_sass/images/flow-box.png);
  background-repeat: no-repeat;
  background-size: contain;
}
@media print, screen and (min-width: 48em) {
  .standard .home_flow--item {
    min-width: 20.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .standard .home_flow--item {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
.standard .home_flow--num {
  position: absolute;
  top: 0.6875rem;
  left: 0;
  font-size: 3.375rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.11;
  color: var(--clr-yellow-05);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_flow--num {
    top: 0.6428571429rem;
    font-size: 4.2857142857rem;
    letter-spacing: 0.05em;
  }
}
.standard .home_flow--icon {
  transform: translateX(1.5rem);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_flow--icon img {
    max-width: 80%;
  }
}
.standard .home_flow--txt {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-family: var(--font-secondary);
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  line-height: 2.5;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_flow--txt {
    font-size: 1.4285714286rem;
  }
}
.standard .home_recruit {
  padding-top: 16.5rem;
  padding-bottom: 11rem;
  background-color: var(--clr-coral);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_recruit {
    padding-top: 8.5714285714rem;
    padding-bottom: 15rem;
  }
}
.standard .home_recruit::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 1920/172;
  background-image: url(/wp/wp-content/themes/standard_sass/images/recruit_section-separator.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_recruit::before {
    top: -1px;
  }
}
.standard .home_recruit .ttl-01,
.standard .home_recruit--txt {
  color: var(--clr-wht);
}
.standard .home_recruit .ttl-01-sub {
  margin-bottom: 2rem;
  color: rgba(254, 254, 254, 0.5);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_recruit .ttl-01-sub {
    margin-bottom: 1.1428571429rem;
  }
}
@media (hover: hover) {
  .standard .home_recruit .btn a:hover {
    background-color: var(--clr-coral-03);
    border-color: var(--clr-coral-03);
    color: var(--clr-wht);
  }
  .standard .home_recruit .btn a:hover svg {
    fill: var(--clr-wht);
  }
}
.standard .home_voice--inner {
  position: relative;
  z-index: 1;
  padding: 6.125rem 4.0625rem;
  background-color: var(--clr-coral-02);
  border-radius: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_voice--inner {
    padding: 3.5rem 1.7142857143rem;
  }
}
.standard .home_voice--inner .ttl-01 {
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_voice--inner .ttl-01,
  .standard .home_voice--inner .ttl-01-sub {
    text-align: center;
  }
}
@media print, screen and (min-width: 48em) {
  .standard .home_voice--inner .ttl-01::before {
    left: 0;
    transform: inherit;
  }
}
.standard .home_voice--inner .ttl-01-sub {
  color: rgba(255, 255, 255, 0.5);
}
.standard .home_voice--card {
  position: relative;
  overflow: hidden;
  border-bottom-right-radius: 2.5rem;
}
@media (hover: hover) {
  .standard .home_voice--card:hover .btn-fan_shape {
    background-color: var(--clr-coral-03);
  }
  .standard .home_voice--card:hover .home_voice--txt {
    color: var(--clr-coral-03);
  }
}
.standard .home_voice--imgarea {
  width: 100%;
  aspect-ratio: 544/258;
  overflow: hidden;
}
.standard .home_voice--imgarea img {
  border-radius: 2.5rem 2.5rem 0 0;
}
.standard .home_voice--body {
  aspect-ratio: 544/278;
  padding: 2.5rem 5rem 4.5rem;
  background-color: var(--bg-wht);
  transition: var(--transit-default);
  border-radius: 0 0 2.5rem 2.5rem;
  width: 100%;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_voice--body {
    padding: 1.1428571429rem 1.7142857143rem 3.4285714286rem;
  }
}
.standard .home_voice--tag {
  margin-bottom: 0.9375rem;
  padding: 0.875rem 3rem;
  background-color: rgba(255, 171, 141, 0.22);
  border-radius: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_voice--tag {
    margin-bottom: 0.8571428571rem;
  }
}
.standard .home_voice--tag-ocuupation,
.standard .home_voice--tag-new {
  color: var(--clr-coral-03);
  font-family: var(--font-secondary);
  font-size: 1.125rem;
  line-height: 1.7666666667;
  letter-spacing: 0.16em;
}
@media screen and (max-width: 47.9375em) {
  .standard .home_voice--tag-ocuupation,
  .standard .home_voice--tag-new {
    font-size: 0.8571428571rem;
  }
}
.standard .home_voice--txt {
  font-family: var(--font-secondary);
  font-size: 1.625rem;
  line-height: 1.6923076923;
  letter-spacing: 0.1em;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .standard .home_voice--txt {
    font-size: 1.1428571429rem;
  }
}
@media (hover: hover) {
  .standard .home_voice .btn a:hover {
    background-color: var(--clr-coral-03);
    border-color: var(--clr-coral-03);
    color: var(--clr-wht);
  }
  .standard .home_voice .btn a:hover svg {
    fill: var(--clr-wht);
  }
}

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.page_ttl--bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.page_ttl-jp {
  font-size: 3rem;
  color: var(--clr-body);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-jp {
    font-size: 1.7142857143rem;
  }
}

.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: max(14px, 1rem);
  color: var(--clr-body);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-en {
    font-size: 1rem;
  }
}

/* --- page_ttl（タイトル画像なし） --- */
.page_ttl:not(.page_ttl-has_bg) {
  padding-top: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl:not(.page_ttl-has_bg) {
    padding-top: 1.7142857143rem;
  }
}
.page_ttl:not(.page_ttl-has_bg) .breadcrumbs {
  text-align: center;
}

/* --- page_ttl（タイトル画像あり） --- */
.page_ttl-has_bg {
  height: 30rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-has_bg {
    height: 14.2857142857rem;
  }
}
.page_ttl-has_bg > .inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page_ttl-has_bg .breadcrumbs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.page_ttl-has_bg .breadcrumbs span span:not(:last-child)::after {
  background-color: var(--bg-wht);
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
  font-family: var(--font-secondary);
}
.ttl-01:where(:not([class*=mgn-btm]):not(:last-child)),
.ttl-02:where(:not([class*=mgn-btm]):not(:last-child)),
.ttl-03:where(:not([class*=mgn-btm]):not(:last-child)),
.ttl-04:where(:not([class*=mgn-btm]):not(:last-child)) {
  margin-bottom: 1em;
}

.ttl-01 {
  margin-top: 2.5rem;
  font-size: 3rem;
  color: var(--clr-main);
  letter-spacing: 0.1em;
  line-height: 1.25;
}
.ttl-01::before {
  content: "";
  position: absolute;
  bottom: calc(100% + 1.1875rem);
  left: 50%;
  transform: translateX(-50%);
  width: 4.5rem;
  aspect-ratio: 213/150;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/flower.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 {
    font-family: var(--font-secondary-b);
    font-size: 2rem;
  }
}
.ttl-01 + .ttl-01-sub {
  margin-top: -1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 + .ttl-01-sub {
    margin-top: -1.1428571429rem;
  }
}
.ttl-01.txt-wh + .ttl-01-sub {
  color: var(--clr-wht);
}
.ttl-01.txt-wh + .ttl-01-sub::after {
  background-color: var(--bg-wht);
}

.ttl-01-sub {
  display: block;
  color: var(--clr-gray);
  font-size: max(14px, 1rem);
  font-family: var(--font-en);
  letter-spacing: 0.12em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01-sub {
    font-size: max(11px, 0.8125rem);
  }
}
.ttl-01-sub:where(:not([class*=mgn-btm]):not(:last-child)) {
  margin-bottom: 3em;
}

.ttl-02 {
  font-size: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    font-family: var(--font-secondary-b);
    font-size: 1.7142857143rem;
  }
}

.ttl-03 {
  font-size: max(22px, 1.5rem);
  color: var(--clr-brown);
}
@media screen and (max-width: 47.9375em) {
  .ttl-03 {
    font-family: var(--font-secondary-b);
    font-size: 1.4285714286rem;
  }
}
.ttl-03.txt-wh.dotted {
  text-decoration-color: var(--clr-wht-42);
}
.ttl-03.dotted {
  line-height: 1.94;
}
.ttl-03.dotted > span {
  display: inline-block;
  height: 2em;
  background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line.svg);
  background-repeat: repeat-x;
  background-size: 13px;
  background-position: bottom left;
}
.ttl-03.dotted > span.wh {
  background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line-wh.svg);
}
.ttl-03.dotted > span.wh-42 {
  background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line-wh-42.svg);
}

.ttl-03-sub {
  display: block;
  color: var(--clr-gray);
  font-size: max(14px, 1rem);
  font-family: var(--font-en);
  letter-spacing: 0.12em;
}
@media screen and (max-width: 47.9375em) {
  .ttl-03-sub {
    font-size: max(11px, 0.8125rem);
  }
}
.ttl-03-sub.txt-wh, .ttl-03-sub.txt-wh * {
  color: var(--clr-wht-42);
}

.lps_parts--img_text .ttl-03 {
  text-decoration: underline dotted var(--clr-brown);
  text-decoration-thickness: 0.2em;
  text-underline-offset: 0.3em;
  line-height: 1.94;
}

.ttl-04 {
  font-size: max(22px, 1.5rem);
}
@media screen and (max-width: 47.9375em) {
  .ttl-04 {
    font-size: 1.4285714286rem;
  }
}

/* anchor_link
********************************************** */
@media screen and (max-width: 47.9375em) {
  .anchor_link--list {
    flex-wrap: inherit;
    white-space: nowrap;
    overflow: auto;
  }
}

.anchor_link--list li {
  min-height: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list li {
    width: auto;
    min-height: 4.5714285714rem;
  }
  .anchor_link--list li:first-child {
    margin-left: auto;
  }
  .anchor_link--list li:last-child {
    margin-right: auto;
  }
  .anchor_link--list li:first-child a {
    padding-left: 0;
  }
}

.anchor_link--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em 2.5em;
  font-family: var(--font-secondary);
  line-height: var(--line-height-hdr);
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list a {
    padding: 1em 1.5em;
  }
}
.anchor_link--list a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.375rem;
  background-color: var(--clr-body);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list a::before {
    right: 0;
  }
}
.anchor_link--list a:hover {
  color: var(--clr-main);
}
.anchor_link--list a:hover::before {
  background-color: var(--bg-main);
}

/* ##############################################################################

    ARCHIVE

############################################################################## */
.main_side {
  display: grid;
  grid-template-columns: auto 16.5rem;
  gap: 5rem;
}
@media screen and (max-width: 67.5em) {
  .main_side {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2.8571428571rem;
  }
}

/* main_column
**************************************** */
/* --- post --- */
.post {
  position: relative;
  border-radius: 2.5rem;
}

.post--img {
  background-color: var(--bg-wht);
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
}

.post--img .img-contain {
  max-width: 80%;
}

.post--img .img-radius {
  border-top-left-radius: 2.5rem;
  border-top-right-radius: 2.5rem;
}

.post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main);
}
.post--link:hover svg {
  fill: var(--clr-main);
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 100;
}

@media screen and (max-width: 47.9375em) {
  .post--info.flex .post--date {
    margin-bottom: 0.5714285714rem;
  }
}

@media print, screen and (min-width: 48em) {
  .post--info.flex .post--date + .cat_list {
    margin-left: auto;
  }
}

.cat_list {
  position: relative;
  z-index: 300;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 0.125rem;
}

.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: max(11px, 0.8125rem);
  background-color: var(--clr-main);
  color: var(--clr-wht);
  padding-inline: 1em;
  border-radius: 0.25rem;
}
@media (hover: hover) {
  .cat_list a:hover {
    background-color: var(--clr-orange);
  }
}

/* side_column
**************************************** */
.side_section:not(:last-child) {
  margin-bottom: 4rem;
}

.side--ttl {
  letter-spacing: 0.2em;
  line-height: 1;
  margin-bottom: 1rem;
}

.side--ttl span {
  display: block;
  opacity: 0.5;
  font-family: var(--font-en);
  letter-spacing: 0.15em;
  line-height: 1;
  margin-top: 0.5rem;
}

/* --- list --- */
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 1rem;
}

.posts-side .post--img {
  margin-right: 1rem;
  width: 4rem;
}

.posts-side .txtarea {
  flex: 1;
}

.posts-side .post--date {
  margin-bottom: 0;
}

.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  transition: var(--transit-default);
}
.archive_list--ttl::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--transit-default);
}
.archive_list--ttl.active::after {
  transform: rotate(315deg);
}
@media (hover: hover) {
  .archive_list--ttl:hover {
    color: var(--clr-orange);
  }
}

.archive_month {
  display: none;
}

@media (hover: hover) {
  .archive_month li a:hover {
    color: var(--clr-orange);
  }
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}

.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 1.5rem;
}

.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid var(--clr-beige);
}
.archive-pulldown .archive_list a:first-child {
  border-radius: 0.5rem 0.5rem 0 0;
}
.archive-pulldown .archive_list a:last-child {
  border-radius: 0 0 0.5rem 0.5rem;
}
.archive-pulldown .archive_list a:hover {
  background-color: var(--clr-beige);
}

.archive-pulldown .archive_list--label {
  margin-right: 0.5rem;
}

.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  color: inherit;
  font-family: var(--font-primary);
  font-size: max(14px, 1rem);
  line-height: var(--line-height-default);
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: var(--transit-default);
}

.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}

.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: var(--transit-default);
}

.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* blog
**************************************** */
.posts-blog .post {
  padding-bottom: 3rem;
  padding-top: 3rem;
  border-bottom: 1px solid #e5e5e5;
}
.posts-blog .post:first-child {
  border-top: 1px solid #e5e5e5;
}

.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}

.posts-blog .post--ttl {
  font-size: max(16px, 1.125rem);
}

.posts-blog .post--img {
  width: 17.5rem;
  margin-right: 2.5rem;
}

@media screen and (max-width: 47.9375em) {
  .posts-blog .post {
    display: block;
    padding-top: 1.7142857143rem;
    padding-bottom: 1.7142857143rem;
  }
  .posts-blog .post--img {
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.1428571429rem;
    text-align: center;
  }
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}
/* recruit-guide
********************************************** */
.archive-recruit-guide .container {
  background-color: var(--clr-beige);
}

.archive-recruit-guide .post.no_recruit {
  pointer-events: none;
  opacity: 0.5;
}

.posts-recruit-guide .post {
  box-shadow: 0px 0px 15px 2px var(--clr-beige-02);
}
@media (hover: hover) {
  .posts-recruit-guide .post:hover .post--img img {
    scale: 1.1;
  }
  .posts-recruit-guide .post:hover .post--ttl a {
    color: var(--clr-orange);
  }
}

.posts-recruit-guide .post--txtarea {
  width: 100%;
  height: 100%;
  padding: 1.5rem 2.5rem 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .posts-recruit-guide .post--txtarea {
    padding: 1.7142857143rem;
  }
}

.posts-recruit-guide .post--txtarea {
  flex: 1;
  background-color: var(--clr-wht);
  border-bottom-right-radius: 2.5rem;
  border-bottom-left-radius: 2.5rem;
}

.posts-recruit-guide .post_recruit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  background-color: var(--clr-brown);
  color: var(--clr-wht);
  border-bottom-left-radius: 0.5rem;
}
@media screen and (max-width: 47.9375em) {
  .posts-recruit-guide .post_recruit {
    font-size: 0.8571428571rem;
  }
}

.posts-recruit-guide .post--img {
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .posts-recruit-guide .post--img {
    aspect-ratio: 16/9;
  }
}

.posts-recruit-guide .post--img img {
  transition: var(--transit-default);
}

.posts-recruit-guide .post--ttl {
  max-width: 80%;
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .posts-recruit-guide .post--ttl {
    font-size: 1.2142857143rem;
  }
}

/* news
**************************************** */
.archive-news .container {
  background-color: var(--clr-beige);
}

/* --- news--archive --- */
.news--archive {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #111;
}

.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}

.news--archive a {
  color: var(--clr-wht);
}

@media screen and (max-width: 47.9375em) {
  .arc_news--cat--container.flex.gap120 {
    gap: 3.1428571429rem;
  }
}

.arc_news--cat--wrap {
  margin-bottom: 2rem;
  padding: 1em 2em;
  background-color: var(--clr-wht-42);
  border-radius: 1rem;
  box-shadow: 0 0 3px var(--clr-beige-02);
}

.arc_news--cat--list a {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0.1em;
  text-decoration: underline dotted var(--clr-brown);
  text-decoration-thickness: 0.15em;
  text-underline-offset: 0.4em;
}
@media (hover: hover) {
  .arc_news--cat--list a:hover {
    color: var(--clr-orange);
    text-decoration-color: var(--clr-orange);
  }
}

.news_category-update .arc_news--cat--list .update,
.news_category-public-relations .arc_news--cat--list .public-relations,
.news_category-disclosure .arc_news--cat--list .disclosure {
  pointer-events: none;
}

.news_category-update .arc_news--cat--list .public-relations,
.news_category-update .arc_news--cat--list .disclosure {
  opacity: 0.5;
}
@media (hover: hover) {
  .news_category-update .arc_news--cat--list .public-relations:hover,
  .news_category-update .arc_news--cat--list .disclosure:hover {
    opacity: 1;
  }
}

.news_category-public-relations .arc_news--cat--list .update,
.news_category-public-relations .arc_news--cat--list .disclosure {
  opacity: 0.5;
}
@media (hover: hover) {
  .news_category-public-relations .arc_news--cat--list .update:hover,
  .news_category-public-relations .arc_news--cat--list .disclosure:hover {
    opacity: 1;
  }
}

.news_category-disclosure .arc_news--cat--list .public-relations,
.news_category-disclosure .arc_news--cat--list .update {
  opacity: 0.5;
}
@media (hover: hover) {
  .news_category-disclosure .arc_news--cat--list .public-relations:hover,
  .news_category-disclosure .arc_news--cat--list .update:hover {
    opacity: 1;
  }
}

/* --- posts-news --- */
.posts-news .post {
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: 0;
}
@media screen and (max-width: 47.9375em) {
  .posts-news .post {
    display: block;
  }
}
@media (hover: hover) {
  .posts-news .post:hover .post--img img {
    scale: 1.1;
  }
  .posts-news .post:hover .post--ttl a {
    color: var(--clr-orange);
  }
}

.news--info .post--img,
.news--info .post--img img {
  border-radius: 1rem;
}

@media screen and (max-width: 47.9375em) {
  .post--info.mgn-btm8 {
    margin-bottom: 1rem;
  }
}

.posts-news .post--img {
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
}
@media print, screen and (min-width: 48em) {
  .posts-news .post--img {
    max-width: 9.375rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .posts-news .post--img {
    aspect-ratio: 16/9;
    margin-bottom: 1.1428571429rem;
  }
}

.posts-news .post--img img {
  transition: var(--transit-default);
}

@media print, screen and (min-width: 48em) {
  .posts-news .post--info {
    margin-right: 2rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .posts-news .post--info {
    margin-bottom: 0.5714285714rem;
  }
}

.posts-news .post--ttl {
  font-size: max(16px, 1.125rem);
}

.posts-news .post--info.flex .post--date + .cat_list {
  margin-left: 1rem;
}

/* ##############################################################################

    PAGE

############################################################################## */
/* related_page
********************************************** */
.related_page--list {
  border-radius: 0.625rem;
  overflow: hidden;
}
@media print, screen and (min-width: 48em) {
  .related_page--list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33.3333333333%, 1fr));
  }
}

.related_page--list li:nth-child(9n-1) a::before, .related_page--list li:nth-child(9n-3) a::before, .related_page--list li:nth-child(9n-8) a::before {
  filter: brightness(1.1);
}
.related_page--list li:nth-child(9n-2) a::before, .related_page--list li:nth-child(9n-4) a::before, .related_page--list li:nth-child(9n-6) a::before {
  filter: brightness(0.9);
}

.related_page--list a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 5.5rem 4rem 4rem;
  line-height: var(--line-height-hdr);
  color: var(--clr-wht);
}
@media print, screen and (min-width: 48em) {
  .related_page--list a {
    height: 17.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .related_page--list a {
    padding: 1.7142857143rem 2.2857142857rem 1.7142857143rem 1.7142857143rem;
  }
}
.related_page--list a::before, .related_page--list a::after {
  content: "";
  position: absolute;
}
.related_page--list a::before {
  inset: 0;
  pointer-events: none;
  z-index: 0;
  background-color: var(--clr-yellow);
}
.related_page--list a::after {
  top: 0;
  bottom: 0;
  right: 4rem;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
}
@media screen and (max-width: 47.9375em) {
  .related_page--list a::after {
    right: 1.7142857143rem;
    width: 0.8571428571rem;
    height: 0.8571428571rem;
  }
}
.related_page--list a:hover {
  opacity: 0.7;
}

.related_page--list .font-jp {
  margin-bottom: 0.25em;
  z-index: 2;
}
@media screen and (max-width: 47.9375em) {
  .related_page--list .font-jp {
    font-size: 1.2857142857rem;
  }
}

.related_page--list .font-en {
  opacity: 0.5;
  text-transform: uppercase;
  font-weight: 600;
}
@media screen and (max-width: 47.9375em) {
  .related_page--list .font-en {
    font-size: 0.7142857143rem;
  }
}

.related_page--bg {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  opacity: 0.2;
  z-index: 0;
}

/* ##############################################################################

    SEARCH

############################################################################## */
#search-list .section_pdg {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

#search-list .ttl-01 {
  margin: 4.375rem 0 0;
}

/* ##############################################################################

    SINGLE

############################################################################## */
/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: 2rem;
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}

div#ez-toc-container {
  padding: 1.5rem;
  background-color: #fafafa;
}

.ez-toc-title-container {
  margin-bottom: 0.625rem;
}

div#ez-toc-container p.ez-toc-title {
  color: var(--clr-body);
  font-weight: bold;
  font-size: max(18px, 1.25rem);
}

#ez-toc-container.counter-hierarchy ul {
  font-size: max(15px, 1.0625rem);
}

#ez-toc-container.counter-hierarchy ul li {
  margin-top: 0.5rem;
}

#ez-toc-container.counter-hierarchy ul ul {
  margin-left: 1rem;
}

#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: 1rem;
}
#ez-toc-container.counter-hierarchy ul ul a::before {
  content: "";
  position: absolute;
  top: 0.625rem;
  left: 0;
  width: 0.5rem;
  height: 0;
  border-bottom: 1px solid;
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name=post_password] {
  padding: 0.3125rem;
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type=submit] {
  padding: 0.25rem 0.375rem;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
}

#pass input[type=submit]:hover {
  opacity: 0.7;
}

/* share
**************************************** */
.share--list a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  padding: 0.5em;
  line-height: 1;
  font-size: max(10px, 0.75rem);
  font-weight: bold;
  border: 1px solid #ddd;
}

.share--list li.x a {
  color: #000;
}
.share--list li.facebook a {
  color: #2477F2;
}
.share--list li.line a {
  color: #27C754;
}
.share--list li.pocket a {
  color: #EF4056;
}
.share--list li.linkedin a {
  color: #2867B2;
}
.share--list li.hatena a {
  color: #29A4DE;
}

.share--list img {
  margin-right: 0.625rem;
}

.share--list a:hover {
  opacity: 0.5;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: clamp(3.5416666667px, 0.2083333333vw, 0.25rem);
  white-space: nowrap;
}

.wp-pagenavi > * {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wp-pagenavi .pages {
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi a {
  position: relative;
  padding: 0 1em;
  height: clamp(35.4166666667px, 2.0833333333vw, 2.5rem);
  background-color: var(--bg-main);
  color: var(--clr-wht);
  border-radius: 0.5rem;
}
.wp-pagenavi a:hover {
  background-color: var(--bg-main-hvr);
}

.archive .wp-pagenavi > *:not(.pages) {
  width: clamp(35.4166666667px, 2.0833333333vw, 2.5rem);
  aspect-ratio: 1/1;
}

.wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
  aspect-ratio: 1/1;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5em;
  aspect-ratio: 1/1;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=prev]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=next]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -0.375rem;
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -0.375rem;
}

/* single-news
********************************************** */
.single-news .container {
  background-color: var(--clr-beige);
}

.post--pdf.btn {
  margin-bottom: 2.5rem;
}

@media (hover: hover) {
  .cat-item a:hover {
    color: var(--clr-orange);
  }
}

/* single-recruit-guide
********************************************** */
.single-recruit-guide .container {
  background-color: var(--clr-beige);
}

@media screen and (max-width: 47.9375em) {
  .recruit-guide--info .ttl-02.mgn-btm64 {
    text-align: center;
  }
}

.recruit-guide--wrapper {
  padding: 2.5rem 3.5rem;
  background-color: var(--clr-wht);
  border-radius: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .recruit-guide--wrapper {
    padding: 1.7142857143rem 1.1428571429rem;
  }
}

.recruit-guide_table {
  width: 100%;
}
.recruit-guide_table a:not([href*=tel]) {
  text-decoration: underline;
  transition: var(--transit-default);
}
@media (hover: hover) {
  .recruit-guide_table a:not([href*=tel]):hover {
    color: var(--clr-orange);
  }
}

.recruit-guide_table:not(:last-child) {
  margin-bottom: 3.375rem;
}
@media screen and (max-width: 47.9375em) {
  .recruit-guide_table:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}

.recruit-guide_table th {
  vertical-align: middle;
}

.single-recruit-guide .btn a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 2.25rem;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  transition: var(--transit-default);
}

.single-recruit-guide .btn:not(:last-child) {
  margin-bottom: 4rem;
}

/* sitemap
*************************************************** */
.page-sitemap .lps_sec:nth-child(odd) {
  background-color: var(--clr-beige);
}

@media print, screen and (min-width: 48em) {
  .sitemap--menu {
    column-count: 2;
    gap: 5%;
  }
}

.sitemap--menu > li {
  break-inside: avoid;
}

.sitemap--menu > li > a {
  font-size: max(16px, 1.125rem);
  border-bottom: 1px solid #D3D3D3;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.sitemap--menu a,
.sitemap--menu span {
  display: block;
}

.sitemap--menu a {
  position: relative;
}
@media (hover: hover) {
  .sitemap--menu a:hover {
    color: var(--clr-orange);
  }
}

.sitemap_sub_nav--blc {
  padding-left: 1em;
}
.sitemap_sub_nav--blc:not(:last-child) {
  margin-bottom: 1em;
}

.sitemap--tax {
  border-bottom: 1px solid #eee;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.sitemap_sub_nav a {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1.25em;
}
.sitemap_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0.375rem;
  height: 0.375rem;
  color: var(--clr-main);
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
}

/* lps_parts--button
********************************************** */
@media print, screen and (min-width: 48em) {
  .lps_parts--button {
    width: fit-content;
    min-width: min(60rem, 90%);
    margin-left: auto;
    margin-right: auto;
  }
}

/* lps_parts--column
********************************************** */
.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}

.lps_parts--column .img {
  flex-shrink: 0;
}

.lps_parts--column .img + .text {
  padding-top: 1.5rem;
}

.lps_parts--column .text {
  flex: 1;
}

.lps_parts--column .bg-wh .text {
  padding: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column .bg-wh .text {
    padding: 2rem;
  }
}

.lps_parts--column .lps_parts--child:not(.grid-col1) table {
  width: 100%;
}

.lps_parts--column .grid-col1 .box {
  padding: 2.5rem;
}

/* 白背景 */
.lps_parts--column .column_item.bg-wh {
  border-radius: 2.5rem;
}
.lps_parts--column .column_item.bg-wh .img img {
  border-radius: 2.5rem 2.5rem 0 0;
}

/* 白背景なし */
.lps_parts--column .column_item:not(.bg-wh) .img img {
  border-radius: 2.5rem;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column {
    margin-top: 1.7142857143rem;
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .flex-sp-block .column_item:not(:last-child) {
    margin-bottom: 2.5rem;
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column .grid-col1 .box {
    padding: 1.7142857143rem;
  }
}
/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 10;
  padding: 1rem;
  border-radius: 50%;
  border: 1px solid #999;
}
@media screen and (max-width: 47.9375em) {
  .post-edit-link {
    display: none;
  }
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}

/* lps_parts--faq
********************************************** */
/* --- 共通 --- */
.lps_parts--faq {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}
.lps_parts--faq .ttl-03 {
  font-size: max(22px, 1.5rem);
  margin-bottom: 0 !important;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03 {
    font-size: max(18px, 1.25rem);
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .text {
    display: flex;
    gap: 4.1666666667%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .img {
    width: 39.0625%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .mce-content-body {
    margin-bottom: 0 !important;
    flex: 1;
  }
}

/* --- 通常 --- */
.lps_parts--faq:not(.faq-toggle) .faq_item {
  border-radius: 1.25rem;
  background-color: var(--bg-wht);
}
.lps_parts--faq:not(.faq-toggle) .faq_item:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq:not(.faq-toggle) .faq_item:not(:last-child) {
    margin-bottom: 1.1428571429rem;
  }
}
.lps_parts--faq:not(.faq-toggle) .ttl-03,
.lps_parts--faq:not(.faq-toggle) .text {
  padding: 1.75rem 2rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq:not(.faq-toggle) .ttl-03,
  .lps_parts--faq:not(.faq-toggle) .text {
    padding: 1.7142857143rem;
  }
}
.lps_parts--faq:not(.faq-toggle) .ttl-03 {
  background-color: var(--clr-main);
  color: var(--clr-wht);
  border-top: 2px solid var(--clr-main);
  border-left: 2px solid var(--clr-main);
  border-right: 2px solid var(--clr-main);
  border-radius: 1.25rem 1.25rem 0 0;
}
.lps_parts--faq:not(.faq-toggle) .text {
  border-bottom: 2px solid var(--clr-main);
  border-left: 2px solid var(--clr-main);
  border-right: 2px solid var(--clr-main);
  border-radius: 0 0 1.25rem 1.25rem;
}

/* --- 開閉式 --- */
.lps_parts--faq.faq-toggle .faq_item {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 2.5rem 1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .faq_item {
    padding: 1.7142857143rem 0;
  }
}
.lps_parts--faq.faq-toggle .faq_item:not(:first-child) {
  border-top: 0;
}
.lps_parts--faq.faq-toggle .ttl-03 {
  cursor: pointer;
  padding-right: 3.5rem;
}
.lps_parts--faq.faq-toggle .ttl-03:not(:last-child) {
  margin-bottom: 1.5em;
}
.lps_parts--faq.faq-toggle .ttl-03::before {
  content: "";
  position: absolute;
  inset: -2.5rem -1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .ttl-03::before {
    inset: -1.7142857143rem;
  }
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 3rem;
  height: 3rem;
  color: var(--clr-wht);
  background-color: var(--bg-main);
  border-radius: 50%;
  transition: var(--transit-default);
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle::before, .lps_parts--faq.faq-toggle .ttl-03 .toggle::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 0.75rem;
  height: 2px;
  border-bottom: 2px solid;
  transition: var(--transit-default);
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle::after {
  transform: rotate(90deg);
}
.lps_parts--faq.faq-toggle .ttl-03:hover .toggle {
  opacity: 0.5;
}
.lps_parts--faq.faq-toggle .ttl-03.active .toggle {
  transform: rotate(180deg);
}
.lps_parts--faq.faq-toggle .ttl-03.active .toggle::after {
  opacity: 0;
}
.lps_parts--faq.faq-toggle .text {
  padding-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .text {
    padding-top: 1.4285714286rem;
  }
}

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}
.lps_parts--flow .flow_item {
  position: relative;
  padding-left: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item {
    padding-left: 4rem;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child) {
    padding-bottom: 2.2857142857rem;
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  left: 1.875rem;
  width: 0.1875rem;
  border-right: 0.1875rem solid var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child)::before {
    left: 1.2857142857rem;
  }
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: var(--clr-wht);
}
.lps_parts--flow .ttl-03 {
  color: var(--clr-main);
}
.lps_parts--flow .ttl-03::before {
  position: absolute;
  border-radius: 50%;
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: var(--clr-wht);
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .text {
    display: flex;
    gap: 4.1666666667%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .img {
    width: 39.0625%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .img:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}
.lps_parts--flow .mce-content-body {
  margin-bottom: 0 !important;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .mce-content-body {
    flex: 1;
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) .flow_item:not(:last-child)::before {
  top: 2.5rem;
  bottom: 0.25rem;
}
.lps_parts--flow:not(.flow-num) .ttl-03::before {
  content: "";
  top: 0.375rem;
  left: -4.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25rem solid var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow:not(.flow-num) .ttl-03::before {
    left: -3.2857142857rem;
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num .flow_item {
  counter-increment: number;
}
.lps_parts--flow.flow-num .flow_item:not(:last-child)::before {
  top: 4rem;
  bottom: 1.25rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .flow_item:not(:last-child)::before {
    top: 3.2857142857rem;
    bottom: 1.1428571429rem;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow.flow-num .ttl-03 {
    font-size: 2rem;
  }
}
.lps_parts--flow.flow-num .ttl-03::before {
  content: counter(number);
  top: -0.625rem;
  left: -6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  text-align: center;
  color: var(--clr-wht);
  background-color: var(--clr-main);
  font-family: var(--font-en);
  font-size: max(22px, 1.5rem);
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .ttl-03::before {
    top: -0.2857142857rem;
    left: -4rem;
    width: 2.8571428571rem;
    height: 2.8571428571rem;
  }
}
.lps_parts--flow.flow-num .txt-wh .ttl-03::after {
  color: var(--clr-main);
  background-color: var(--bg-wht);
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}

.icon-new_tab {
  width: 0.875rem;
  height: 0.875rem;
}

.icon-pdf {
  width: 1.6875rem;
  height: 0.75rem;
}

.lps_sec {
  position: relative;
}
.lps_sec:nth-child(odd) .bg-wh {
  background-color: var(--bg-off_wht);
}
.lps_sec:nth-child(even) {
  background-color: var(--bg-off_wht);
}

.lps_sec.section_pdg {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_sec.section_pdg {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}
.lps_parts:not(:last-child) {
  margin-bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts:not(:last-child) {
    margin-bottom: 2.8571428571rem;
  }
}

.lps_parts iframe {
  width: 100%;
}

.lps_parts .text:not(:last-child) {
  margin-bottom: 1.5rem;
}

.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}

.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}

.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts .btn {
    text-align: center;
  }
}
/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .bg-wh .img_text--child:only-child,
.lps_parts--img_text .text,
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}

.lps_parts--img_text .img_text--child .img {
  height: 100%;
}

.lps_parts--img_text .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .img_text--txt {
    padding-top: 2rem;
  }
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5.3333333333%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 1.7142857143rem;
  }
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner:not(.flex-reverse) .img_text--txt {
    padding-left: 5.3333333333%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner.flex-reverse .img_text--txt {
    padding-right: 5.3333333333%;
  }
}

/* --- L --- */
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    min-height: 37.9775rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    width: 86% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
    padding-left: 10.989010989%;
    padding-right: 0;
  }
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
    padding-left: 0;
    padding-right: 10.989010989%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2.8571428571rem;
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .ttl-03 {
    font-size: 1.7142857143rem;
  }
}

/* 白背景 */
.lps_parts--img_text .lps_parts--child.bg-wh:not(.inner-lg) {
  border-radius: 2.5rem;
}
.lps_parts--img_text .lps_parts--child.bg-wh:not(.inner-lg) .img_text--img img {
  border-radius: 2.5rem 0 0 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .lps_parts--child.bg-wh:not(.inner-lg) .img_text--img img {
    border-radius: 2.5rem 2.5rem 0 0;
  }
}
@media screen and (min-width: 48em) {
  .lps_parts--img_text .lps_parts--child.bg-wh:not(.inner-lg).flex-reverse .img_text--img img {
    border-radius: 0 2.5rem 2.5rem 0;
  }
}

/* 白背景なし */
.lps_parts--img_text .lps_parts--child:not(.bg-wh) .img_text--img img {
  border-radius: 2.5rem;
}

/* レイアウトL
   ※Lも全部角丸にしたかったら削除！ */
.lps_parts--img_text .lps_parts--child.inner-lg .img_text--img img {
  border-radius: 0;
}

@media screen and (min-width: 48em) {
  .lps_parts--img_text .lps_parts--child.inner-lg:where(:not(.bg-wh)) .img_text--img img {
    border-radius: 0 2.5rem 2.5rem 0;
  }
  .lps_parts--img_text .lps_parts--child.inner-lg:where(:not(.bg-wh)).flex-reverse .img_text--img img {
    border-radius: 2.5rem 0 0 2.5rem;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .img_text--txt {
    display: flex;
    align-items: center;
  }
  .lps_parts--img_text .inner-lg .img_text--img:only-child {
    position: relative;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .bg-wh + .bg-wh {
    margin-top: -2.8571428571rem;
  }
}
/* lps_parts--slide
********************************************** */
.lps_parts--slide {
  /* --- textのみ --- */
  /* --- 1枚だけ --- */
}
.lps_parts--slide .slick-track {
  display: flex;
}
.lps_parts--slide .slick-slide {
  height: auto !important;
}
.lps_parts--slide .slick-slide img {
  border-radius: 2.5rem;
}
.lps_parts--slide .slick-list {
  margin-bottom: 2rem;
}
.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 100%;
  text-decoration: none;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow {
    width: 2.2857142857rem;
  }
}
.lps_parts--slide .slick-arrow::after {
  content: "";
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  transform: rotate(45deg);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow::after {
    width: 1.1428571429rem;
    height: 1.1428571429rem;
  }
}
.lps_parts--slide .slick-prev {
  left: -3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-prev {
    left: 0;
  }
}
.lps_parts--slide .slick-prev::after {
  margin-right: -20%;
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.lps_parts--slide .slick-next {
  right: -3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-next {
    right: 0;
  }
}
.lps_parts--slide .slick-next::after {
  margin-left: -20%;
  border-top: 2px solid;
  border-right: 2px solid;
}
.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 0.25rem;
  }
}
.lps_parts--slide .slick-dots li {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default);
  margin: 0.25rem;
}
.lps_parts--slide .slick-dots li.slick-active {
  background-color: var(--clr-body);
}
.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}
.lps_parts--slide .img + .text {
  padding-top: 2rem;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--slide .ttl-03 {
    font-size: 2rem;
  }
}
.lps_parts--slide .text:only-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 5rem;
  background-color: var(--bg-off_wht);
  height: 100%;
  border-radius: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .text:only-child {
    padding: 1.7142857143rem 2.8571428571rem;
  }
}
.lps_parts--slide:has(.slick-slide:only-child) .slick-dots {
  display: none;
}

/* slide-center
********************************************** */
.lps_parts--slide.slide-center {
  /* --- textのみ --- */
}
.lps_parts--slide.slide-center .slick-slide {
  width: 75rem;
  margin: 0 1.875rem;
  border-radius: 2.5rem;
}
@media screen and (max-width: 70em) {
  .lps_parts--slide.slide-center .slick-slide {
    width: 62.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .slick-slide {
    width: 22.8571428571rem;
    margin: 0 1.1428571429rem;
  }
}
.lps_parts--slide.slide-center .slick-slide .img {
  aspect-ratio: 16/9;
}
.lps_parts--slide.slide-center .slick-slide .text {
  border-radius: 2.5rem;
}
.lps_parts--slide.slide-center .slick-prev {
  left: calc(50% - 41.25rem);
}
@media screen and (max-width: 70em) {
  .lps_parts--slide.slide-center .slick-prev {
    left: calc(50% - 35rem);
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .slick-prev {
    left: calc(50% - 13.7142857143rem);
  }
}
.lps_parts--slide.slide-center .slick-next {
  right: calc(50% - 41.25rem);
}
@media screen and (max-width: 70em) {
  .lps_parts--slide.slide-center .slick-next {
    right: calc(50% - 35rem);
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .slick-next {
    right: calc(50% - 13.7142857143rem);
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .text:only-child {
    padding: 1.7142857143rem;
  }
}

/* slide-fit
********************************************** */
.lps_parts--slide.slide-fit {
  /* --- textのみ --- */
}
.lps_parts--slide.slide-fit .slick-track {
  background-color: var(--bg-off_wht);
}
.lps_parts--slide.slide-fit .slick-slide {
  position: relative;
}
.lps_parts--slide.slide-fit .slick-slide img {
  border-radius: 0;
}
.lps_parts--slide.slide-fit .slick-prev {
  left: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .slick-prev {
    left: 1.1428571429rem;
  }
}
.lps_parts--slide.slide-fit .slick-next {
  right: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .slick-next {
    right: 1.1428571429rem;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--slide.slide-fit .img {
    aspect-ratio: 1920/800;
  }
}
.lps_parts--slide.slide-fit .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 15rem);
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .text {
    width: calc(100% - 8.5714285714rem);
  }
}
.lps_parts--slide.slide-fit .img + .text,
.lps_parts--slide.slide-fit .slick-arrow {
  color: var(--clr-wht);
}
.lps_parts--slide.slide-fit .img + .text {
  position: absolute;
  inset: 0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .img + .text {
    padding-top: 2.8571428571rem;
    padding-bottom: 2.8571428571rem;
  }
}
.lps_parts--slide.slide-fit:has(.img + .text) .slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit:has(.img + .text) .slick-dots {
    bottom: 2.8571428571rem;
  }
}
.lps_parts--slide.slide-fit:has(.text:only-child) .slick-arrow {
  color: var(--clr-body);
}
.lps_parts--slide.slide-fit .text:only-child {
  padding-left: 0;
  padding-right: 0;
}

/* 背景色調整
********************************************** */
.lps_sec:nth-child(even) .lps_parts--slide .text:only-child,
.lps_sec:nth-child(even) .lps_parts--slide.slide-fit .slick-track,
.lps_sec[style*=background-color] .lps_parts--slide .text:only-child,
.lps_sec[style*=background-color] .lps_parts--slide.slide-fit .slick-track {
  background-color: var(--bg-wht);
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}
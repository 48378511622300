@use "../abstracts" as *;

/* お問い合わせ
********************************************** */
  .cta {
    position: relative;
    padding-top: rem(80);
    padding-bottom: rem(192);
    background-color: var(--bg-primary);
    @include mq(sp) {
      padding-bottom: sprem(100);
    }
  }
  .subpage .cta,
  .subpage .footer {
    background-color: var(--bg-wht);
  }
  .cta--ttl_box > .flex.gap40 {
    @include mq(sp) {
      flex-direction: column;
      gap: sprem(21);
    }
  }
  .cta .cta--container {
    border-radius: rem(40);
  }
  .cta .cta_box {
    padding: rem(101) rem(120) rem(101);
    overflow: hidden;
    @include mq(sp) {
      gap: 0;
      padding: sprem(32) sprem(32) sprem(48);
    }
  }
  .cta_box .ttl-03 {
    font-size: rem(32);
    letter-spacing: .1em;
    @include mq(sp) {
      font-size: sprem(26);
    }
  }
  .cta_box:first-child .ttl-03.txt-wh.dotted {
    @include mq(sp) {
      margin-bottom: 0;
    }
  }
  .cta_box-contact {
    background-color: var(--clr-pink);
    border-top-left-radius: rem(40);
    @include mq(sp, min, ps) {
      border-bottom-left-radius: rem(40);
    }
    @include mq(sp) {
      border-top-right-radius: rem(40);
    }
  }
  .cta_box-recruit {
    border-bottom-right-radius: rem(40);
    background-color: var(--clr-green-13);
    cursor: pointer;
    @include mq(sp, min, ps) {
      border-top-right-radius: rem(40);
    }
    @include mq(sp) {
      border-bottom-left-radius: rem(40);
    }
    @media (hover: hover) {
      &:hover .btn a {
        color: var(--bg-cta);
        background-color: transparent;
        &::before {
          border-color: var(--bg-cta);
        }
      }
    }
  }
  .cta--ttl .ttl-01 {
    margin-top: 0;
  }
  .cta--ttl .ttl-01::before {
    content: none;
  }
  .cta--ttl .ttl-01-sub {
    margin-bottom: 0;
    text-align: left;
    @include mq(sp) {
      text-align: center;
    }
  }
  .cta_line {
    width: 5px;
    height: rem(140);
    @include mq(sp, min, ps) {
      border-right: 5px dotted var(--clr-beige-02);
    }
    @include mq(sp) {
      width: 100%;
      height: 5px;
      border-bottom: 5px dotted var(--clr-beige-02);
    }

  }
  .cta--txt {
    font-family: var(--font-secondary);
  }
  .cta--container .cta_tel {
    align-items: center;
  }
  .cta--container .cta_tel .num {
    font-size: rem(31);
    font-weight: bold;
  }
/* cta_box
**************************************** */
.cta_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include mq(sp) {
    align-items: center;
    gap: sprem(10);
  }
}

/* cta_tel
**************************************** */
.cta_tel a {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: rem(12);
  line-height: 1.4;
  border-radius: rem(20);
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: calc-fz(20);
}
.cta_tel .tel_txt {
  text-align: center;
  font-size: rem(16);
}
.cta_tel .num {
  font-size: rem(28);
}
.cta_tel .hours {
  padding-left: 1em;
  font-size: calc-fz(13);
  font-family: var(--font-secondary);
}

/* cta_contact
**************************************** */
.cta_contact a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: rem(4);
}

@use "sass:math";
@use '../abstracts' as *;

// 単位は㎳（ミリ秒）
$transMax: 4000; // 最大
$transStep: 100; // ステップ
@for $i from 0 through ceil(math.div($transMax, $transStep)) {
  $val: $i * $transStep;
  .trans-delay#{$val} {
    transition-delay: #{$val}ms;
  }
}
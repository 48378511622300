@use "../abstracts" as *;
/* ########################################################################

 スタンダード用TOP

######################################################################## */
.standard {
  background-color: var(--bg-primary);
  overflow: hidden;

/* title
********************************************** */
  .ttl-03 {
    font-size: rem(32);
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
/* hero
********************************************** */
  .hero {
    position: relative;
    height: rem(674);
    margin-bottom: rem(74);
    z-index: 1;
    @include mq(sp) {
      height: sprem(484);
      margin-bottom: sprem(22);
    }
  }
  .hero_slides {
    position: relative;
  }
  .hero_slide {
    position: absolute;
    top: 0;
    right: vw(144);
    width: min(vw(1164), 1164px);
    aspect-ratio: 1164/674;
    border-radius: rem(50);
    overflow: hidden;
    @include mq(sp) {
      right: 0;
      z-index: 0;
      width: spvw(347);
      aspect-ratio: 2/2.7;
      border-radius: sprem(24) 0 0 sprem(24);
    }

  }
  .hero--cont {
    position: absolute;
    left: rem(236);
    top: rem(33);
    @include mq(sp) {
      top: sprem(24);
      left: sprem(8);
    }
  }
  .hero--catch {
    font-size: rem(48);
    @include lh(48, 80);
    @include mq(sp) {
      display: flex;
      justify-content: center;
      font-size: sprem(32);
      line-height: 1.3;
    }
  }
  .hero--catch_txt {
    @include write-v;
    font-size: rem(46);
    letter-spacing: .26em;
    line-height: 2.11;
    color: var(--clr-brown);
    @include mq(sp) {
      font-size: sprem(24);
      font-family: var(--font-secondary-b)
    }
  }
  .hero--catch_txt span.txt-deco {
    display: inline-block;
    width: 2em;
    background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line-tate.svg);
    background-repeat: repeat-y;
    background-size: 4px;
    background-position: top 2px right;
    font-feature-settings: "pkna";
  }
  .hero--catch_txt span.txt-mgn.txt-hgt-01 {
    margin-top: -0.6em;
  }
  .hero--catch_txt span.txt-hgt-01 {
    display: inline-block;
    height: 6.5em;
    white-space: nowrap;
    font-feature-settings: "pkna";
    text-orientation: mixed;
    @include mq(sp) {
      height: 6.4em;
    }
  }
  .hero--catch_txt span.txt-hgt-02 {
    display: inline-block;
    height: 11.5em;
    white-space: nowrap;
    font-feature-settings: "pkna";
    text-orientation: mixed;
  }
  .hero_slide-sp {
    display: none !important;
  }
  .hero_slides,
  .hero_slide .slick-list,
  .hero_slide .slick-track,
  .hero_slide .slick-slider,
  .hero_slide .slick-slide {
    width: 100%;
    height: 100% !important;
  }
  .hero_slide .slick-list {
    position: relative;
    overflow: hidden;
  }

  /* 新着情報
  ********************************************** */
  .home_news {
    position: relative;
    margin-bottom: vw(594);
    @include mq(sp) {
      margin-bottom: spvw(300);
    }
    &::before {
      content: "";
      position: absolute;
      top: 92%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: vw(1920);
      aspect-ratio: 1920/879;
      background-image: url(/wp/wp-content/themes/standard_sass/images/fv_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      @include mq(sp) {
        top: 59%;
        background-image: url(/wp/wp-content/themes/standard_sass/images/fv_sp_bg.png);
        aspect-ratio: 414/696;
      }
    }
  }
  .home_news .inner {
    position: relative;
    z-index: 1;
    display: flex;
    gap: rem(16);
    padding: rem(40) rem(140);
    background-color: var(--bg-wht);
    border-radius: rem(40);
    @include mq(sp) {
      flex-direction: column;
      padding: sprem(34) sprem(24);
    }
    + .home_news {
      margin-top: rem(100);
    }
  }
  .home_news--line {
    width: 5px;
    height: rem(140);
    border-right: 5px dotted var(--clr-beige-02);
    @include mq(sp) {
      width: 100%;
      height: 5px;
      border-right: none;
      border-bottom: 5px dotted var(--clr-beige-02);
    }
  }
  .home_news .posts-news {
    flex: 1;
  }
  .posts-news--ttl {
    display: block;
    position: relative;
    margin-right: rem(40);
    padding: 0;
    line-height: 1.2;
    @include mq(sp) {
      width: auto;
      float: none;
      height: auto;
      margin-bottom: sprem(16);
    }
  }
  .home_news--ttl {
    .ttl-02 {
      @include mq(sp) {
        margin-bottom: .5rem;
      }
    }
    .ttl-01-sub {
      margin-bottom: rem(32);
      @include mq(sp) {
        margin-bottom: 0;
      }
    }
  }
  .ttl-02 {
    margin-bottom: rem(16);
  }
  .ttl-01-sub {
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(32);
    }
  }
  @include mq(sp, min) {
    .home_news--cont {
      width: rem(896);
    }
  }
  @include mq(sp) {
    .home_news--ttl {
      text-align: center;
    }
    .home_news--ttl .ttl-01-sub::after {
      margin-inline: auto;
    }
    .home_news .btn-arrow {
      text-align: center;
    }
  }

  .home_news {
    .posts-news .post--txt,
    .posts-blog .post--txt {
      -webkit-line-clamp: 1;
      opacity: 0.5;
      pointer-events: none;
    }
    .posts-news .post--info {
      grid-template-columns: 7rem 7rem auto;
      align-items: center;
      @include mq(1600) {
        grid-template-columns: 10rem 8rem auto;
      }
      @include mq(sp) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        "date tag"
        "text text";
        row-gap: sprem(8);
      }
    }
    .posts-news .post--date {
      font-size: calc-fz(16);
      font-family: var(--font-en);
      font-weight: bold;
      letter-spacing: .1em;
      @include mq(sp) {
        grid-area: date;
      }
    }
    .posts-news .cat_list {
      z-index: 110;
      @include mq(sp) {
        grid-area: tag;
        margin-left: sprem(-20);
      }
    }
    .posts-news .cat_list a {
      font-size: rem(14);
      letter-spacing: .18em;
      line-height: 1.71;
      border-radius: rem(16);
      color: var(--bg-cta);
      background-color: var(--bg-wht);
      border: 2px solid var(--bg-cta);
      @media(hover: hover) {
        &:hover {
          background-color: var(--bg-cta);
          color: var(--clr-wht);
        }
      }
    }
    .posts-news .post--ttl {
      @include mq(sp) {
        grid-area: text;
      }
    }
  }
  @include mq(sp) {
    .hero_slide {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-image: url(/wp/wp-content/themes/standard_sass/images/moya.png);
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .hero_slide-pc {
      display: none !important;
    }
    .hero_slide-sp {
      display: block !important;
    }
  }

  /* home_intro
  ********************************************** */
  .home_intro {
    margin-bottom: rem(136);
    @include mq(sp) {
      margin-bottom: sprem(54);
    }
  }
  .home_intro--txt {
    font-family: var(--font-secondary);
    font-size: rem(24);
    line-height: 1.92;
    letter-spacing: .16em;
    color: var(--bg-main);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }

  /* スライド
  ********************************************** */
  .lps_parts--slide.slide-flow .slick-slide {
    margin-right: rem(24);
    @include mq(sp) {
      width: 60vw;
    }
  }

  /* わたしたちの想い（ABOUT US）
  ********************************************** */
  .home_about {
    padding-bottom: 7.5rem;
    @include mq(sp) {
      padding-bottom: 5.5rem;
    }
    .img_text--child {
      width: rem(712);
      aspect-ratio: 712/437;
    }
    .img_text--child .mce-content-body p {
      font-size: rem(18);
      line-height: 1.94;
      letter-spacing: .18em;
      text-align: justify;
      @include mq(sp) {
        font-size: sprem(14);
      }
    }
    .lps_parts--img_text .img_text--txt {
      @include mq(sp) {
        padding-top: sprem(84);
      }
    }
  }
  .home_about .ttl-03 {
    line-height: 2;
    letter-spacing: .16em;
    color: var(--clr-main);
  }

  /* サービス紹介(service)
  ********************************************** */
  .home_service {
    padding-top: 7.5rem;
    padding-bottom: 9.5rem;
    @include mq(sp) {
      padding-top: 5.5rem;
    }
  }
  .home_service--block.top {
    @include mq(sp, min, ps) {
      grid-template: 
      ' home support' rem(512) /
      rem(1120) auto ;
    }
    @include mq(sp) {
      grid-template-areas: 
      'home'
      'support';
      grid-template-columns: 1fr;
    }
  }
  .home_service--block.bottom {
    @include mq(sp, min, ps) {
      grid-template: 
      ' short day' rem(380) /
      1fr 1fr;
    }
    @include mq(sp) {
      grid-template-areas: 
      'short'
      'day';
      grid-template-columns: 1fr;
    }
  }
  $areas: home, support, short, day;

  @each $area in $areas {
    .home_service--contents-#{$area} {
      grid-area: #{$area};
    }
  }
  .home_service--head {
    padding: rem(34);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: rem(32);
    letter-spacing: .1em;
    line-height: 1.41;
    border-radius: rem(40) rem(40) 0 0;
    @include mq(sp) {
      padding: sprem(16);
      font-family: var(--font-secondary-b);
      font-size: sprem(24);
    }
  }
  .home_service--body {
    height: 100%;
    position: relative;
    padding: rem(48) rem(64) rem(64);
    .ttl-03.dotted span {
      height: 1.5em;
    }
    @include mq(sp) {
      padding: sprem(38) sprem(24);
    }
  }
  .home_service--contents {
    position: relative;
    border-radius: rem(40);
    overflow: hidden;
  }
  .home_service--contents-home {
    .home_service--head {
      background-color: var(--clr-yellow-08);
      color: var(--clr-brown-02);
    }
    .home_service--body {
      background-color: var(--clr-yellow-07);
    }
  }
  .home_service--contents-support {
    @media(hover: hover) {
      &:hover .btn a {
        background-color: var(--clr-purple-03);
        border-color: var(--clr-purple-03);
        color: var(--clr-purple-04);
      }
      &:hover svg {
        fill: var(--clr-purple-04);
      }
    }
    .home_service--head {
      background-color: var(--clr-purple-03);
      color: var(--clr-purple-04);
    }
    .home_service--body {
      background-color: var(--clr-purple-02);
    }
    .home_service--body_ttl.ttl-03 {
      @include mq(sp, min, ps) {
        margin-bottom: 3.3em;
      }
    }
  }
  .home_service--contents-short {
    @media(hover: hover) {
      &:hover .btn a {
        background-color: var(--clr-green-02);
        border-color: var(--clr-green-02);
        color: var(--clr-green-10);
      }
      &:hover svg {
        fill: var(--clr-green-10);
      }
    }
    .home_service--head {
      background-color: var(--clr-green-02);
      color: var(--clr-green-10);
    }
    .home_service--body {
      background-color: var(--clr-green);
    }
  }
  .home_service--contents-day {
    @media(hover: hover) {
      &:hover .btn a {
        background-color: var(--clr-pink-02);
        border-color: var(--clr-pink-02);
        color: var(--clr-pink-03);
      }
      &:hover svg {
        fill: var(--clr-pink-03);
      }
    }
    .home_service--head {
      background-color: var(--clr-pink-02);
      color: var(--clr-pink-03);
    }
    .home_service--body {
      background-color: var(--clr-pink);
    }
  }
  .home_service--body_ttl.ttl-03 {
    margin-bottom: 1.3em;
    font-size: rem(44);
    line-height: 1.02;
    letter-spacing: .1em;
    color: var(--clr-wht);
    @include mq(sp) {
      margin-bottom: .8em;
      font-size: sprem(32);
    }
  }
  .home_service--box {
    position: relative;
    z-index: 2;
    background-color: var(--clr-beige-03);
    border-radius: rem(16);
    padding: rem(17) rem(60) rem(28);
    @include mq(sp) {
      padding: sprem(14) rem(32) rem(24);
    }
    @media(hover: hover) {
      &:hover .btn a {
        background-color: var(--clr-yellow-07);
        border-color: var(--clr-yellow-07);
      }
    }
  }
  .home_service--box_ttl,
  .home_service--box_txt {
    text-align: center;
    font-family: var(--font-secondary);
  }
  .home_service--box_ttl {
    font-size: rem(26);
    line-height: 1.73;
    letter-spacing: .12em;
    color: var(--clr-yellow);
  }
  .home_service--box_txt {
    font-size: rem(16);
    line-height: 1.5;
    letter-spacing: .16em;
    color: var(--clr-brown-02);
  }

  /* すばるの特徴（FEATURE）
  ********************************************** */
  .home_feature {
    position: relative;
    padding-top: rem(280);
    padding-bottom: rem(640);
    background-image: url("/wp/wp-content/themes/standard_sass/images/feature_bk.png");
    background-repeat: no-repeat;
    background-size: cover;
    @include mq(2000, min, ps) {
      padding-bottom: 65rem;
    }
    @include mq(sp) {
      padding-top: 0;
      padding-bottom: 29rem;
      background-image: url(/wp/wp-content/themes/standard_sass/images/feature_bk_sp_02.png);
      @include mq(415, min, ps) {
        background-size: cover;
      }
    }
    @include mq(414) {
      background-size: contain;
    }
    & > .inner {
      position: relative;
      z-index: 10;
      transform: translateY(rem(210));
    }
    .ttl-01-sub {
      color: rgba(255, 255, 255, 0.42);
    }
    .lps_parts:not(:last-child) {
      margin-bottom: rem(180);
      @include mq(sp) {
        margin-bottom: sprem(90);
      }
    }
    .lps_parts--img_text .inner {
      width: 100%;
    }
    .lps_parts--img_text {
      counter-reset: number;
      @include mq(sp, min, ps) {
        margin-inline: calc(50% - 50vw);
      }
      @include mq(sp) {
        margin-bottom: sprem(24);
      }
      .ttl-03 {
        font-size: rem(32);
        @include lh(32, 64);
        @include mq(sp) {
          font-size: sprem(24);
        }
      }
      .ttl-03::before {
        counter-increment: number;
        content: "0" counter(number);
        position: absolute;
        right: 0;
        bottom: rem(40);
        z-index: -1;
        color: var(--clr-yellow-02);
        font-size: rem(216);
        font-family: var(--font-en);
        line-height: .42;
        @include mq(sp, min, ps) {
          letter-spacing: -15px;
          @include mq(pc) {
            letter-spacing: -8px;
          }
        }
        @include mq(sp) {
          right: spvw(-10);
          font-size: sprem(86);
        }
      }
      .lps_parts + .lps_parts {
        margin-top: 0;
      }
      .img_text--img {
        @include mq(sp, min, ps) {
          min-width: rem(869);
        }
        aspect-ratio: 869/507;
      }
      .inner:not(.flex-reverse) .img_text--txt {
        @include mq(sp, min, ps) {
          padding-left: rem(120);
        }
      }
      .inner.flex-reverse .img_text--txt {
        @include mq(sp, min, ps) {
          padding-right: rem(120);
        }
      }
      .img_text--txt {
        @include mq(sp) {
          padding-top: sprem(16);
          padding-bottom: sprem(24);
        }
      }
      .img_text--txt p {
        font-size: rem(18);
        line-height: 2;
        letter-spacing: .16em;
        @include mq(sp) {
          font-size: sprem(14);
        }
      }
      .box {
        padding: rem(32) rem(48);
        border-radius: rem(40);
        background-color: var(--clr-yellow-10);
        @include mq(sp) {
          padding: sprem(52) sprem(52);
        }
      }
      .box p.bold {
        margin-bottom: 0;
        font-family: var(--font-secondary-b);
        color: var(--clr-yellow);
      }
      .box-previous {
        padding-bottom: rem(16);
        background-image: url(/wp/wp-content/themes/standard_sass/images/dot_line-wh.svg);
        background-repeat: repeat-x;
        background-size: 13px;
        background-position: bottom left;
      }
      .box-unit {
        padding-top: rem(16);
      }
    }
    .lps_parts--img_text .img {
      overflow: hidden;
    }
  }


  /* 施設紹介（FACILITY）
  ********************************************** */
  .home_facility {
    .lps_parts:not(:last-child) {
      margin-bottom: 0;
    }
    .lps_parts {
      z-index: inherit;
    }
    .lps_parts--slide {
      position: relative;
    }
    .lps_parts--slide .slick-list {
      margin-bottom: 0;
    }
    .lps_parts--slide .slick-arrow::after {
      border-width: 3px;
      border-color: var(--clr-wht);
      transition: var(--transit-default);
    }
    .lps_parts--slide .slick-prev,
    .lps_parts--slide .slick-next {
      z-index: 500;
    }
    .lps_parts--slide .slick-prev {
      left: 11px;
    }
    .lps_parts--slide .slick-next {
      right: 11px;
    }
    .lps_parts--slide .slick-slide img {
      border-radius: rem(40) rem(40) 0 0;
      transition: var(--transit-default);
    }
    .lps_parts--slide .slick-dots {
      position: absolute;
      top: calc(100% + rem(32));
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
    }
    .lps_parts--slide .slick-dots li {
      background-color: var(--bg-wht);
    }
  }
  .home_facility--card {
    max-width: rem(688);
    @media(hover: hover) {
      &:hover .home_facility--block.orange {
        background-color: rgba(252,169,25, .8);
      }
      &:hover .home_facility--contents.orange {
        background-color: rgba(252,169,25, .8);
      }
      &:hover .home_facility--block.blue {
        background-color: rgba(81,167,221, .8);
      }
      &:hover .home_facility--contents.blue {
        background-color: rgba(81,167,221, .8);
      }
    }
  }
  .home_facility--wrapper.flex-sp-block[class*=gap] {
    @include mq(sp) {
      gap: sprem(164);
    }
  }
  .home_facility--card.orange .slick-arrow {
    @media(hover: hover) {
      &:hover::after {
        border-color: var(--clr-yellow-07);
      }
    }
  }
  .home_facility--card.blue .slick-arrow {
    @media(hover: hover) {
      &:hover::after {
        border-color: var(--clr-blue);
      }
    }
  }
  
  .home_facility--card {
    overflow: hidden;
    border-radius: rem(40);
  }
  .home_facility--card.orange {
    &:hover .btn-fan_shape {
      background-color: var(--clr-yellow-03);
    }
  }
  .home_facility--card.blue {
    &:hover .btn-fan_shape {
      background-color: var(--clr-blue-04);
    }
  }
  .home_facility--card .lps_parts--slide .slick-dots li.slick-active {
    background-color: var(--clr-yellow-04);
  }
  .home_facility--block {
    padding: rem(72) rem(92) rem(70) rem(92);
    border-radius: 0 0 rem(40) rem(40);
    transition: var(--transit-default);
    @include mq(sp, min, ps) {
      min-height: rem(453);
      @include mq(pc) {
        min-height: 30.3125rem;
      }
    }
    @include mq(sp) {
      padding: sprem(50) sprem(34) sprem(72);
    }
  }
  .home_facility--block.orange {
    background-color: var(--clr-yellow-07);
  }
  .home_facility--block.blue {
    background-color: var(--clr-blue-03);
  }
  .home_facility--block .ttl-01-sub {
    margin-bottom: rem(-24);
    text-align: left;
    color: rgba(255, 255, 255, 0.42);
    @include mq(sp) {
      margin-bottom: sprem(-12);
    }
  }
  .home_facility--block .ttl-03 {
    margin-bottom: 0;
    letter-spacing: .12em;
    color: var(--clr-wht);
    white-space: nowrap;
  }
  .home_facility--block .ttl-03.dotted > span.wh-42 {
    height: 2.5em;
    @include mq(sp) {
      font-size: sprem(18);
      height: 2.8em;
    }
    & > span {
      @include mq(sp) {
        font-size: sprem(24);
      }
    }
  }
  .home_facility--map {
    margin-bottom: rem(21);
    font-family: var(--font-secondary);
    font-size: rem(16);
    @include lh(16, 38);
    color: var(--clr-wht);
    letter-spacing: .16em;
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
  .home_facility--map svg {
    fill: var(--bg-wht);
    opacity: .42;
  }
  .home_facility--contents {
    padding: rem(17) rem(76);
    border-radius: rem(16);
    transition: var(--transit-default);
    @include mq(sp) {
      padding: sprem(14) sprem(28);
    }
  }
  .home_facility--contents.orange {
    background-color: var(--clr-yellow-03);
    &::before {
      background-color: var(--clr-yellow-09);
    }
  }
  .home_facility--contents.blue {
    background-color: var(--clr-blue-04);
    &::before {
      background-color: var(--clr-blue-03);
    }
  }
  .home_facility--contents li {
    position: relative;
    @include lh(18, 32);
    font-family: var(--font-secondary);
    font-size: rem(18);
    color: var(--clr-wht);
    letter-spacing: .16em;
    @include mq(sp) {
      font-size: sprem(12);
    }
    &::before {
      content: "";
      position: absolute;
      top: .6em;
      left: rem(-16);
      width: rem(8);
      aspect-ratio: 1/1;
      background-color: var(--clr-yellow-09);
      border-radius: 50vw;
    }
  }

  /* ご相談からの流れ（FLOW）
  ********************************************** */
  .home_flow {
    padding-top: 12rem;
    padding-bottom: 11rem;
    @include mq(sp) {
      padding-bottom: 6rem;
    }
  }
  .home_flow--wrapper {
    @include mq(sp) {
      gap: sprem(128);
    }
  }
  .home_flow--block {
    @include mq(sp) {
      flex-direction: column;
      gap: sprem(130);
    }
  }
  .home_flow--block .home_flow--item:not(:last-child) {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: calc(100% - rem(-52));
      transform: translateY(-50%);
      width: rem(29);
      aspect-ratio: 29/33;
      background-image: url(/wp/wp-content/themes/standard_sass/images/flow-arrow.png);
      background-repeat: no-repeat;
      background-size: contain;
      @include mq(sp) {
        top: calc(100% + rem(74));
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
      }
    }
  }
  .home_flow--block-01 .home_flow--item:last-child {
    @include mq(sp) {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: calc(100% + rem(74));
        left: 50%;
        transform: translateX(-50%) rotate(90deg);
        width: rem(29);
        aspect-ratio: 29/33;
        background-image: url(/wp/wp-content/themes/standard_sass/images/flow-arrow.png);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .home_flow--block-02 .home_flow--item:first-child {
    @include mq(sp, min, ps) {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: calc(100% - rem(-52));
        transform: translateY(-50%);
        width: rem(29);
        aspect-ratio: 29/33;
        background-image: url(/wp/wp-content/themes/standard_sass/images/flow-arrow.png);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
  .home_flow--item {
    position: relative;
    aspect-ratio: 328/220;
    background-image: url(/wp/wp-content/themes/standard_sass/images/flow-box.png);
    background-repeat: no-repeat;
    background-size: contain;
    @include mq(sp, min, ps) {
      min-width: rem(328);
    }
    @include mq(sp) {
      width: 90%;
      @include auto-margin;
    }
  }
  .home_flow--num {
    position: absolute;
    top: rem(11);
    left: 0;
    font-size: rem(54);
    font-weight: bold;
    letter-spacing: .1em;
    line-height: 1.11;
    color: var(--clr-yellow-05);
    @include mq(sp) {
      top: sprem(9);
      font-size: sprem(60);
      letter-spacing: .05em;
    }
  }
  .home_flow--icon {
    transform: translateX(rem(24));
  }
  .home_flow--icon img {
    @include mq(sp) {
      max-width: 80%;
    }
  }
  .home_flow--txt {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--font-secondary);
    font-size: rem(24);
    letter-spacing: .1em;
    line-height: 2.5;
    white-space: nowrap;
    @include mq(sp) {
      font-size: sprem(20);
    }
  }

  /* 採用情報（recruit）
  ********************************************** */
  .home_recruit {
    padding-top: rem(264);
    padding-bottom: rem(176);
    background-color: var(--clr-coral);
    @include mq(sp) {
      padding-top: sprem(120);
      padding-bottom: sprem(210);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      aspect-ratio: 1920 / 172;
      background-image: url(/wp/wp-content/themes/standard_sass/images/recruit_section-separator.svg);
      background-repeat: no-repeat;
      background-size: cover;
      @include mq(sp) {
        top: -1px;
      }
    }
  }
  .home_recruit .ttl-01,
  .home_recruit--txt {
    color: var(--clr-wht);
  }
  .home_recruit .ttl-01-sub {
    margin-bottom: rem(32);
    color: rgba(254, 254, 254, 0.5);
    @include mq(sp) {
      margin-bottom: sprem(16);
    }
  }
  .home_recruit .btn a {
    @media(hover: hover) {
      &:hover {
        background-color: var(--clr-coral-03);
        border-color: var(--clr-coral-03);
        color: var(--clr-wht);
      }
      &:hover svg {
        fill: var(--clr-wht);
      }
    }
  }
  /* 働く先輩社員の声（VOICE）
  ********************************************** */
  .home_voice--inner {
    position: relative;
    z-index: 1;
    padding: rem(98) rem(65);
    background-color: var(--clr-coral-02);
    border-radius: rem(40);
    @include mq(sp) {
      padding: sprem(49) sprem(24);
    }
  }
  .home_voice--inner .ttl-01 {
    white-space: nowrap;
  }
  .home_voice--inner .ttl-01,
  .home_voice--inner .ttl-01-sub {
    @include mq(sp) {
      text-align: center;
    }
  }
  .home_voice--inner .ttl-01::before {
    @include mq(sp, min, ps) {
      left: 0;
      transform: inherit;
    }
  }
  .home_voice--inner .ttl-01-sub {
    color: rgba(255, 255, 255, 0.5);
  }
  .home_voice--card {
    position: relative;
    overflow: hidden;
    border-bottom-right-radius: rem(40);
    @media(hover: hover) {
      &:hover .btn-fan_shape {
        background-color: var(--clr-coral-03);
      }
      &:hover .home_voice--txt {
        color: var(--clr-coral-03);
      }
    }
  }
  .home_voice--imgarea {
    width: 100%;
    aspect-ratio: 544/258;
    overflow: hidden;
  }
  .home_voice--imgarea img {
    border-radius: rem(40) rem(40) 0 0;
  }
  .home_voice--body {
    aspect-ratio: 544/278;
    padding: rem(40) rem(80) rem(72);
    background-color: var(--bg-wht);
    transition: var(--transit-default);
    border-radius: 0 0 rem(40) rem(40);
    width: 100%;
    @include mq(sp) {
      padding: sprem(16) sprem(24) sprem(48);
    }
  }
  .home_voice--tag {
    margin-bottom: rem(15);
    padding: rem(14) rem(48);
    background-color: rgba(#ffab8d, .22);
    border-radius: rem(16);
    @include mq(sp) {
      margin-bottom: sprem(12);
    }
  }
  .home_voice--tag-ocuupation,
  .home_voice--tag-new {
    color: var(--clr-coral-03);
    font-family: var(--font-secondary);
    font-size: rem(18);
    @include lh(18, 31.8);
    letter-spacing: .16em;
    @include mq(sp) {
      font-size: sprem(12);
    }
  }
  .home_voice--txt {
    font-family: var(--font-secondary);
    font-size: rem(26);
    @include lh(26, 44);
    letter-spacing: .1em;
    transition: var(--transit-default);
    @include mq(sp) {
      font-size: sprem(16);
    }
  }
  .home_voice .btn a {
    @media(hover: hover) {
      &:hover {
        background-color: var(--clr-coral-03);
        border-color: var(--clr-coral-03);
        color: var(--clr-wht);
      }
      &:hover svg {
        fill: var(--clr-wht);
      }
    }
  }
}
